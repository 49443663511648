import React, { useState, useEffect } from "react";
import PairCard from "./PairCard";
import "../css/pairs.css";
import "../css/flag.css";
import PairsCongratsMsg from "./PairsCongratsMsg";
import HeaderApp from "./HeaderApp";
import Footer from "./Footer";

const cardImages = [
  { src: require("../images/cardImages/Dog1.png"), isMatched: false },
  { src: require("../images/cardImages/Dog2.png"), isMatched: false },
  { src: require("../images/cardImages/Dog3.png"), isMatched: false },
  { src: require("../images/cardImages/Dog4.png"), isMatched: false },
  { src: require("../images/cardImages/Dog5.png"), isMatched: false },
  { src: require("../images/cardImages/Dog6.png"), isMatched: false },
  { src: require("../images/cardImages/Dog7.png"), isMatched: false },
  { src: require("../images/cardImages/Dog8.png"), isMatched: false },
  { src: require("../images/cardImages/Dog9.png"), isMatched: false },
  { src: require("../images/cardImages/Dog10.png"), isMatched: false },
  { src: require("../images/cardImages/Dog11.png"), isMatched: false },
  { src: require("../images/cardImages/Dog12.png"), isMatched: false },
  { src: require("../images/cardImages/Dog13.png"), isMatched: false },
  { src: require("../images/cardImages/Dog14.png"), isMatched: false },
  { src: require("../images/cardImages/Dog15.png"), isMatched: false },
  { src: require("../images/cardImages/Dog16.png"), isMatched: false },
  { src: require("../images/cardImages/Dog17.png"), isMatched: false },
  { src: require("../images/cardImages/Dog18.png"), isMatched: false },
  { src: require("../images/cardImages/Dog19.png"), isMatched: false },
  { src: require("../images/cardImages/Dog20.png"), isMatched: false },
];

function Pairs() {
  const [cards, setCards] = useState([]);
  const [correctGuesses, setCorrectGuesses] = useState(0);
  const [turns, setTurns] = useState(0);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [diff, setDiff] = useState("easy");
  //shuffle cards
  function shuffleCards() {
    let diffCards = [];
    if (diff === "easy") {
      diffCards = cardImages.slice(0, 6);
    } else if (diff === "medium") {
      diffCards = cardImages.slice(0, 12);
    } else if (diff === "hard") {
      diffCards = cardImages;
    }
    const shuffledCards = [...diffCards, ...diffCards]
      .sort(() => Math.random() - 0.5)
      .map((c) => ({ ...c, id: Math.random() }));
    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffledCards);
    setTurns(0);
    setCorrectGuesses(shuffleCards.length);
  }

  function handleChoice(card) {
    if (!card.isClicked) {
      card.isClicked = true;
      choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
    }
  }

  function resetChoicesAndIncreaseTurn() {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurns((turns) => turns + 1);
    setDisabled(false);
    for (let i = 0; i < cards.length; i++) {
      setCards((cards) => {
        return cards.map((card) => {
          return { ...card, isClicked: false };
        });
      });
    }
  }
  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setCards((cards) => {
          return cards.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, isMatched: true };
            } else {
              return card;
            }
          });
        });
        setCorrectGuesses((guesses) => guesses - 2);
        resetChoicesAndIncreaseTurn();
      } else {
        setTimeout(() => resetChoicesAndIncreaseTurn(), 1500);
      }
    }
  }, [choiceOne, choiceTwo]);

  useEffect(() => {
    shuffleCards();
  }, [diff]);

  useEffect(() => {
    if (turns > 1 && Math.abs(correctGuesses) === cards.length) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [turns]);

  return (
    <div className="hero push-footer">
      <HeaderApp />
      {/* <p>{turns}</p> */}
      <PairsCongratsMsg
        cards={cards}
        correctGuesses={correctGuesses}
        turns={turns}
        shuffleCards={shuffleCards}
        diff={diff}
        setDiff={setDiff}
      />
      <div className="pairs-btn-container">
        <button
          className="pairs-easy-btn"
          onClick={
            diff === "easy" ? () => shuffleCards() : () => setDiff("easy")
          }
        >
          easy
        </button>
        <button
          className="pairs-medium-btn"
          onClick={
            diff === "medium" ? () => shuffleCards() : () => setDiff("medium")
          }
        >
          medium
        </button>
        <button
          className="pairs-hard-btn"
          onClick={
            diff === "hard" ? () => shuffleCards() : () => setDiff("hard")
          }
        >
          hard
        </button>
      </div>
      <div
        className={
          diff === "easy"
            ? "card-grid-easy"
            : diff === "medium"
            ? "card-grid-medium"
            : "card-grid-hard"
        }
      >
        {cards.map((c) => (
          <PairCard
            key={c.id}
            src={c.src}
            card={c}
            handleChoice={handleChoice}
            flipped={c === choiceOne || c === choiceTwo || c.isMatched}
            disabled={disabled}
          />
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default Pairs;
