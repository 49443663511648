import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import SubLearnChoice from "../../SubLearnChoice";
import { geographyCategories } from "../../categories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../../css/style.css";
import "../../css/peeps.css";
import FooterLearning from "../FooterLearning";

function SetLearnChoices(choice) {
  return (
    <SubLearnChoice key={choice.id} name={choice.name} path={choice.path} />
  );
}

function LearnGeography() {
  return (
    <div className="hero push-footer">
      <Header />
      {/* <AsideNav /> */}
      <section className="drop-section-content">
        <div className="menu-page-container">
          <div className="category-flex-container">
            <div className="sub-menu-page-title-div">
              <h1 className="menu-page-title">LEARN - GEOGRAPHY</h1>
            </div>
            {geographyCategories.map(SetLearnChoices)}
            <Link to="../learn" className="answer-orange learn-go-back">
              <FontAwesomeIcon
                className="fontawesome-margin-right"
                icon={faArrowLeft}
              />
              GO BACK
            </Link>
          </div>
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../../images/peep-small19.png")}
            alt="cartoon character with spiky blond hair and black top"
            className="peep-learning"
          />
        </div>
      </section>
      <FooterLearning />
    </div>
  );
}

export default LearnGeography;
