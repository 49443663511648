import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { inventors } from "../../LearnSpecificCategoryDetails";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnInventors() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={inventors.mainCategory}
          subCategory={inventors.subCategory}
          iconLrg={inventors.iconLrg}
          iconSml={inventors.iconSml}
          bigPeep={inventors.bigPeep}
          intro={inventors.intro}
        />
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Discoveries</h2>
        </div>
        <div className="table-container">
          <table className="table inventions-table">
            <thead>
              <tr>
                <th>Discovery</th>
                <th>Discovered By</th>
                <th>Nationality</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Discovery">Gravity</td>
                <td data-label="Discovered By">Sir Isaac Newton</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1687</td>
              </tr>
              <tr>
                <td data-label="Discovery">X-ray</td>
                <td data-label="Discovered By">Wilhelm Roentgen</td>
                <td data-label="Nationality">German</td>
                <td data-label="Year">1895</td>
              </tr>
              <tr>
                <td data-label="Discovery">Quantum Mechanics</td>
                <td data-label="Discovered By">
                  Werner Heisenberg, Max Born & Pascual Jordan
                </td>
                <td data-label="Nationality">German</td>
                <td data-label="Year">1924</td>
              </tr>
              <tr>
                <td data-label="Discovery">Penicillin</td>
                <td data-label="Discovered By">Alexander Fleming</td>
                <td data-label="Nationality">Scottish</td>
                <td data-label="Year">1928</td>
              </tr>
              <tr>
                <td data-label="Discovery">Nuclear Fission</td>
                <td data-label="Discovered By">
                  Otto Hahn, Fritz Strassmann, Lise Meitner & Otto Robert Frisch
                </td>
                <td data-label="Nationality">
                  German, German, Austrian-Swedish & Austrian-British
                  respectively
                </td>
                <td data-label="Year">1928</td>
              </tr>
              <tr>
                <td data-label="Discovery">Double-helix structure of DNA</td>
                <td data-label="Discovered By">Francis Crick & James Watson</td>
                <td data-label="Nationality">
                  British & American respectively
                </td>
                <td data-label="Year">1953</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Inventions</h2>
        </div>
        <div className="table-container">
          <table className="table inventions-table">
            <thead>
              <tr>
                <th>Invention</th>
                <th>Invented By</th>
                <th>Nationality</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Invention">Printing Press</td>
                <td data-label="Invented By">Johannes Gutenburg</td>
                <td data-label="Nationality">German</td>
                <td data-label="Year">1440</td>
              </tr>
              <tr>
                <td data-label="Invention">Thermometer (Water Thermoscope)</td>
                <td data-label="Invented By">Galileo Galilei</td>
                <td data-label="Nationality">Italian</td>
                <td data-label="Year">1593</td>
              </tr>
              <tr>
                <td data-label="Invention">Telescope</td>
                <td data-label="Invented By">
                  Hans Lippershey & Zacharias Janssen NOT GALILEO
                </td>
                <td data-label="Nationality">
                  German-Dutch & Dutch respectively
                </td>
                <td data-label="Year">1608</td>
              </tr>
              <tr>
                <td data-label="Invention">Compound Microscope</td>
                <td data-label="Invented By">Robert Hooke</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1665</td>
              </tr>
              <tr>
                <td data-label="Invention">Diving Bell</td>
                <td data-label="Invented By">Edmund Halley</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1691</td>
              </tr>
              <tr>
                <td data-label="Invention">Steam Engine</td>
                <td data-label="Invented By">Thomas Newcomen</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1698</td>
              </tr>
              <tr>
                <td data-label="Invention">Mercury Thermometer</td>
                <td data-label="Invented By">Gabriel Farenheit</td>
                <td data-label="Nationality">Dutch - Polish - German</td>
                <td data-label="Year">1714</td>
              </tr>
              <tr>
                <td data-label="Invention">Centrigrade Scale</td>
                <td data-label="Invented By">Anders Celcius</td>
                <td data-label="Nationality">Swedish</td>
                <td data-label="Year">1742</td>
              </tr>
              <tr>
                <td data-label="Invention">The Spinning Jenny</td>
                <td data-label="Invented By">James Hargreaves</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1764</td>
              </tr>
              <tr>
                <td data-label="Invention">Vaccination</td>
                <td data-label="Invented By">Edward Jenner</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1796</td>
              </tr>
              <tr>
                <td data-label="Invention">Battery</td>
                <td data-label="Invented By">Alessandro Volta</td>
                <td data-label="Nationality">Italian</td>
                <td data-label="Year">1799</td>
              </tr>
              <tr>
                <td data-label="Invention">Railway Engine</td>
                <td data-label="Invented By">George Stephenson</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1814</td>
              </tr>
              <tr>
                <td data-label="Invention">Camera</td>
                <td data-label="Invented By">Nicéphore Niépce</td>
                <td data-label="Nationality">French</td>
                <td data-label="Year">1816</td>
              </tr>
              <tr>
                <td data-label="Invention">Electromagnetic Induction</td>
                <td data-label="Invented By">Michael Faraday</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1830</td>
              </tr>
              <tr>
                <td data-label="Invention">Telegraph</td>
                <td data-label="Invented By">Samuel Morse</td>
                <td data-label="Nationality">American</td>
                <td data-label="Year">1830</td>
              </tr>
              <tr>
                <td data-label="Invention">Anesthesia</td>
                <td data-label="Invented By">Horace Wells</td>
                <td data-label="Nationality">American</td>
                <td data-label="Year">1844</td>
              </tr>
              <tr>
                <td data-label="Invention">Diesel Engine</td>
                <td data-label="Invented By">Rudolf Diesel</td>
                <td data-label="Nationality">French-German</td>
                <td data-label="Year">1858</td>
              </tr>
              <tr>
                <td data-label="Invention">Internal Combustion Engine</td>
                <td data-label="Invented By">Étienne Lenoir</td>
                <td data-label="Nationality">Belgian-French</td>
                <td data-label="Year">1860</td>
              </tr>
              <tr>
                <td data-label="Invention">Pasteurization</td>
                <td data-label="Invented By">Louis Pasteur</td>
                <td data-label="Nationality">French</td>
                <td data-label="Year">1863</td>
              </tr>
              <tr>
                <td data-label="Invention">Dynamite</td>
                <td data-label="Invented By">Alfred Nobel</td>
                <td data-label="Invented By">Swedish</td>
                <td data-label="Year">1867</td>
              </tr>
              <tr>
                <td data-label="Invention">Telephone</td>
                <td data-label="Invented By">Alexander Graham Bell</td>
                <td data-label="Nationality">Scottish</td>
                <td data-label="Year">1876–1877</td>
              </tr>
              <tr>
                <td data-label="Invention">Fluorescent Lighting</td>
                <td data-label="Invented By">Nikola Tesla</td>
                <td data-label="Nationality">Serbian-American</td>
                <td data-label="Year">1876–1877</td>
              </tr>
              <tr>
                <td data-label="Invention">Typewriter</td>
                <td data-label="Invented By">Christopher Latham Sholes</td>
                <td data-label="Nationality">American</td>
                <td data-label="Year">1878</td>
              </tr>
              <tr>
                <td data-label="Invention">Electric Lightbulb</td>
                <td data-label="Invented By">Thomas Edison</td>
                <td data-label="Nationality">American</td>
                <td data-label="Year">1879</td>
              </tr>
              <tr>
                <td data-label="Invention">The Difference Engine</td>
                <td data-label="Invented By">Charles Babbage</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1882</td>
              </tr>
              <tr>
                <td data-label="Invention">Automobile</td>
                <td data-label="Invented By">Karl Benz</td>
                <td data-label="Nationality">German</td>
                <td data-label="Year">1885</td>
              </tr>
              <tr>
                <td data-label="Invention">Induction Motor</td>
                <td data-label="Invented By">Nikola Tesla</td>
                <td data-label="Nationality">Serbian-American</td>
                <td data-label="Year">1885</td>
              </tr>
              <tr>
                <td data-label="Invention">Transformer</td>
                <td data-label="Invented By">Michael Faraday</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1885</td>
              </tr>
              <tr>
                <td data-label="Invention">Pneumatic Tyres</td>
                <td data-label="Invented By">John Boyd Dunlop</td>
                <td data-label="Nationality">Scottish</td>
                <td data-label="Year">1888</td>
              </tr>
              <tr>
                <td data-label="Discovery">Radio</td>
                <td data-label="Discovered By">Guglielmo Marconi</td>
                <td data-label="Nationality">Italian</td>
                <td data-label="Year">1895</td>
              </tr>
              <tr>
                <td data-label="Invention">Cathode Ray Tube</td>
                <td data-label="Invented By">Ferdinand Braun</td>
                <td data-label="Nationality">German</td>
                <td data-label="Year">1897</td>
              </tr>
              <tr>
                <td data-label="Invention">Airplane</td>
                <td data-label="Invented By">
                  Wright Brothers (Wilbur and Orville)
                </td>
                <td data-label="Nationality">American</td>
                <td data-label="Year">1903</td>
              </tr>
              <tr>
                <td data-label="Invention">Television (Mechanical)</td>
                <td data-label="Invented By">John Logie Baird</td>
                <td data-label="Nationality">Scottish</td>
                <td data-label="Year">1926</td>
              </tr>
              <tr>
                <td data-label="Invention">Television (Electronic)</td>
                <td data-label="Invented By">Philo Taylor Farnsworth</td>
                <td data-label="Nationality">American</td>
                <td data-label="Year">1927</td>
              </tr>
              <tr>
                <td data-label="Invention">Cat's Eyes</td>
                <td data-label="Invented By">Percy Shaw</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1934</td>
              </tr>
              <tr>
                <td data-label="Invention">
                  Radar (radio detection and ranging)
                </td>
                <td data-label="Invented By">
                  Sir Robert Alexander Watson-Watt
                </td>
                <td data-label="Nationality">Scottish</td>
                <td data-label="Year">1939</td>
              </tr>
              <tr>
                <td data-label="Invention">Nuclear Reactor</td>
                <td data-label="Invented By">Enrico Fermi</td>
                <td data-label="Nationality">Italian</td>
                <td data-label="Year">1942</td>
              </tr>
              <tr>
                <td data-label="Invention">Atomic Bomb</td>
                <td data-label="Invented By">
                  Robert Oppenheimer, Edward Teller et al
                </td>
                <td data-label="Nationality">
                  American-German & Hungarian-American respectively
                </td>
                <td data-label="Year">1945</td>
              </tr>
              <tr>
                <td data-label="Invention">Laser</td>
                <td data-label="Invented By">Theodore H. Maiman</td>
                <td data-label="Nationality">American</td>
                <td data-label="Year">1969</td>
              </tr>
              <tr>
                <td data-label="Invention">The World Wide Web</td>
                <td data-label="Invented By">Tim Berners-Lee</td>
                <td data-label="Nationality">English</td>
                <td data-label="Year">1989</td>
              </tr>
            </tbody>
          </table>
        </div>

        <LearnSpecificCategoryBottom
          importantFacts={inventors.importantFacts}
          classicQuestions={inventors.classicQuestions}
          exampleQuestions={inventors.exampleQuestions}
          thoughtsTipsIdeas={inventors.thoughtsTipsAndIdeas}
          // smallPeep1={inventors.smallPeep1}
          smallPeep2={inventors.smallPeep2}
          disclaimer={inventors.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnInventors;
