import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import { footballEuros } from "../../LearnSpecificCategoryDetails";
import { topFivesSport } from "../../topFivesInfo";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/top5.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive key={topFive.key} col2={topFive.col2} col1={topFive.col1} />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}

function LearnSportEuros() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={footballEuros.mainCategory}
          subCategory={footballEuros.subCategory}
          iconLrg={footballEuros.iconLrg}
          iconSml={footballEuros.iconSml}
          bigPeep={footballEuros.bigPeep}
          intro={footballEuros.intro}
        />
        <div className="top5s-container">
          <div className="top5TwoCols">
            <h2>Last 5 Euros Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topEuros.lastEurosWinnersHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.football.topEuros.lastEurosWinners.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 Euros Hosts</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topEuros.lastEurosHostsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.football.topEuros.lastEurosHosts.map(ArrangeTopFive)}
          </div>
          <div className="top5TwoCols">
            <h2>Top 10 Euros Wins</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topEuros.mostEurosWinsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.football.topEuros.mostEurosWins.map(ArrangeTopFive)}
          </div>
        </div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Number</th>
                <th>Year</th>
                <th>Host</th>
                <th>Winner</th>
                <th>Runner Up</th>
                <th>Top Goalscorer(s) /Goals/ Country</th>
                <th>Number Of Teams</th>
                <th>Result In The Final</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Number">1</td>
                <td data-label="Year">1960</td>
                <td data-label="Host">France</td>
                <td data-label="Winner">Soviet Union</td>
                <td data-label="Runner Up">Yugoslavia</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  François Heutte /2/ France &amp; Viktor Ponedelnik /2/ Soviet
                  Union &amp; Valentin Ivanov /2/ Soviet Union &amp; Dražan
                  Jerković /2/ Yugoslavia &amp; Milan Galić /2/ Yugoslavia
                </td>
                <td data-label="Number Of Teams">4</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">2</td>
                <td data-label="Year">1964</td>
                <td data-label="Host">Spain</td>
                <td data-label="Winner">Spain</td>
                <td data-label="Runner Up">Soviet Union</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Jesús María Pereda /2/ Spain &amp; Ferenc Bene /2/ Hungary
                  &amp; Deszö Novák /2/ Hungary
                </td>
                <td data-label="Number Of Teams">4</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">3</td>
                <td data-label="Year">1968</td>
                <td data-label="Host">Italy</td>
                <td data-label="Winner">Italy</td>
                <td data-label="Runner Up">Yugoslavia</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Dragan Džajić /2/ Yugoslavia
                </td>
                <td data-label="Number Of Teams">4</td>
                <td data-label="Result In The Final">2-0 (replay)</td>
              </tr>
              <tr>
                <td data-label="Number">4</td>
                <td data-label="Year">1972</td>
                <td data-label="Host">Belgium</td>
                <td data-label="Winner">West Germany</td>
                <td data-label="Runner Up">Soviet Union</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Gerd Müller /4/ West Germany
                </td>
                <td data-label="Number Of Teams">4</td>
                <td data-label="Result In The Final">3-0</td>
              </tr>
              <tr>
                <td data-label="Number">5</td>
                <td data-label="Year">1976</td>
                <td data-label="Host">Yugoslavia</td>
                <td data-label="Winner">Czechoslovakia</td>
                <td data-label="Runner Up">West Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Dieter Müller /4/ West Germany
                </td>
                <td data-label="Number Of Teams">4</td>
                <td data-label="Result In The Final">2-2 (penalties)</td>
              </tr>
              <tr>
                <td data-label="Number">6</td>
                <td data-label="Year">1980</td>
                <td data-label="Host">Italy</td>
                <td data-label="Winner">West Germany</td>
                <td data-label="Runner Up">Belgium</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Klaus Allofs /3/ West Germany
                </td>
                <td>8</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">7</td>
                <td data-label="Year">1984</td>
                <td data-label="Host">France</td>
                <td data-label="Winner">France</td>
                <td data-label="Runner Up">Spain</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Michel Platini /9/ France
                </td>
                <td data-label="Number Of Teams">8</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">8</td>
                <td data-label="Year">1988</td>
                <td data-label="Host">West Germany</td>
                <td data-label="Winner">Netherlands</td>
                <td data-label="Runner Up">Soviet Union</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Marco van Basten /5/ Netherlands
                </td>
                <td data-label="Number Of Teams">8</td>
                <td data-label="Result In The Final">2-0</td>
              </tr>
              <tr>
                <td data-label="Number">9</td>
                <td data-label="Year">1992</td>
                <td data-label="Host">Sweden</td>
                <td data-label="Winner">Denmark</td>
                <td data-label="Runner Up">Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Henrik Larsen /3/ Denmark &amp; Karl-Heinz Riedle /3/ Germany
                  &amp; Dennis Bergkamp /3/ Netherlands &amp; Tomas Brolin /3/
                  Sweden
                </td>
                <td data-label="Number Of Teams">8</td>
                <td data-label="Result In The Final">2-0</td>
              </tr>
              <tr>
                <td data-label="Number">10</td>
                <td data-label="Year">1996</td>
                <td data-label="Host">England</td>
                <td data-label="Winner">Germany</td>
                <td data-label="Runner Up">Czech Republic</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Alan Shearer /5/ England
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">11</td>
                <td data-label="Year">2000</td>
                <td data-label="Host">Belgium/ Netherlands</td>
                <td data-label="Winner">France</td>
                <td data-label="Runner Up">Italy</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Patrick Kluivert /5/ Netherlands &amp; Savo Milošević /5/
                  Yugoslavia
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">12</td>
                <td data-label="Year">2004</td>
                <td data-label="Host">Portugal</td>
                <td data-label="Winner">Greece</td>
                <td data-label="Runner Up">Portugal</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Milan Baroš /5/ Czech Republic
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">1-0</td>
              </tr>
              <tr>
                <td data-label="Number">13</td>
                <td data-label="Year">2008</td>
                <td data-label="Host">Austria/ Switzerland</td>
                <td data-label="Winner">Spain</td>
                <td data-label="Runner Up">Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  David Villa /4/ Spain
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">1-0</td>
              </tr>
              <tr>
                <td data-label="Number">14</td>
                <td data-label="Year">2012</td>
                <td data-label="Host">Poland/ Ukraine</td>
                <td data-label="Winner">Spain</td>
                <td data-label="Runner Up">Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Fernando Torres /3/ Spain &amp; Alan Dzagoev /3/ Russia &amp;
                  Mario Gomez /3/ Germany &amp; Mario Mandžukić /3/ Croatia
                  &amp; Mario Balotelli /3/ Italy &amp; Cristiano Ronaldo /3/
                  Portugal
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">4-0</td>
              </tr>
              <tr>
                <td data-label="Number">15</td>
                <td data-label="Year">2016</td>
                <td data-label="Host">France</td>
                <td data-label="Winner">Portugal</td>
                <td data-label="Runner Up">France</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Antoine Griezmann /6/ France
                </td>
                <td data-label="Number Of Teams">24</td>
                <td data-label="Result In The Final">1-0</td>
              </tr>
              <tr>
                <td data-label="Number">16</td>
                <td data-label="Year">2020 (2021)</td>
                <td data-label="Host">Europe</td>
                <td data-label="Winner">Italy</td>
                <td data-label="Runner Up">England</td>
                <td data-label="Top Goalscorer(s) /Goals/ Country">
                  Cristiano Ronaldo /5/ Portugal &amp; Patrik Schick /5/ Czech
                  Republic
                </td>
                <td data-label="Number Of Teams">24</td>
                <td data-label="Result In The Final">1-1 (penalties)</td>
              </tr>
            </tbody>
          </table>
        </div>

        <LearnSpecificCategoryBottom
          importantFacts={footballEuros.importantFacts}
          classicQuestions={footballEuros.classicQuestions}
          exampleQuestions={footballEuros.exampleQuestions}
          thoughtsTipsIdeas={footballEuros.thoughtsTipsAndIdeas}
          smallPeep1={footballEuros.smallPeep1}
          smallPeep2={footballEuros.smallPeep2}
          disclaimer={footballEuros.disclaimer}
        />
        <AsideNav />
      </section>

      <Footer />
    </div>
  );
}

export default LearnSportEuros;
