import React from "react";
import "../../css/style.css";
import "../../css/peeps.css";

function LearnSpecificCategoryTop(props) {
  return (
    <div>
      <h2 className="category-title">{props.mainCategory}</h2>
      <div className="container">
        <div className="flex-container first-flex">
          {/* <div> */}
          <img
            className="peep-big peep-flip"
            src={props.bigPeep}
            alt="cartoon character looking at page title"
          />
          <div>
            <p className="body-text">{props.intro}</p>
            <p className="body-text">{props.intro2}</p>
            <p className="body-text">{props.introt2}</p>

            {props.intro3 ? <p className="mnemonic">{props.intro3}</p> : ""}
            <p className="body-text">{props.intro4}</p>
          </div>
        </div>
        <p className="body-text">{props.introt3}</p>
        <p className="body-text">{props.introt4}</p>
        <p className="body-text">{props.introt5}</p>
      </div>
      {/* </div> */}
    </div>
  );
}

export default LearnSpecificCategoryTop;
