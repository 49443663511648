const classicQuestions = {
  intro:
    "There are some classic questions that come up over and over again at quizzes. If you can learn them they are a good way of getting extra points",
  sportQuestions: [
    {
      id: "cqsportq1",
      question: "Which team won the first FA Cup?",
      answer: "Wanderers",
    },
    {
      id: "cqsportq2",
      question: "Who won the first FIFA World Cup?",
      answer: "Uruguay",
    },
    {
      id: "cqsportq3",
      question:
        "What was the name of the dog who found the stolen FIFA World Cup trophy in 1966?",
      answer: "Pickles",
    },
    {
      id: "cqsportq4",
      question: "Three strikes in a row in Ten-Pin Bowling is known as a what?",
      answer: "Turkey",
    },
    {
      id: "cqsportq5",
      question: "The Stanley Cup is contested in which sport?",
      answer: "Ice Hockey",
    },
  ],
  britainQuestions: [
    {
      id: "cqbritainq1",
      question: "In which county is Leeds castle?",
      answer: "Kent",
    },
    {
      id: "cqbritainq2",
      question: "Which UK monarch has reigned for the longest time?",
      answer: "Queen Elizabeth II",
    },
    {
      id: "cqbritainq3",
      question: "Who is the only UK prime minister to be assassinated?",
      answer: "Spencer Percival",
    },
    {
      id: "cqbritainq4",
      question: "What is the name of the highest mountain in the UK?",
      answer: "Ben Nevis",
    },
    {
      id: "cqbritainq5",
      question:
        "What is the name of the most southerly point on the UK mainland?",
      answer: "Lizard Point",
    },
  ],
  geographyQuestions: [
    {
      id: "cqgeographyq1",
      question: "What is the capital city of Australia?",
      answer: "Canberra",
    },
    {
      id: "cqgeographyq2",
      question:
        "8 out of 10 of the tallest mountains in the world are located in which country?",
      answer: "Nepal",
    },
    {
      id: "cqgeographyq3",
      question: "In which city are the Spanish Steps located?",
      answer: "Rome",
    },
    {
      id: "cqgeographyq4",
      question: "Which country changed its name from Ceylon in 1972?",
      answer: "Sri Lanka",
    },
    {
      id: "cqgeographyq5",
      question: "What is the smallest country in the world?",
      answer: "Vatican City",
    },
    {
      id: "cqgeographyq6",
      question:
        "Over which city does the statue of Christ the Redeemer overlook?",
      answer: "Rio De Janeiro",
    },
  ],
  historyQuestions: [
    {
      id: "cqhistoryq1",
      question: "In which country was Che Guevara born?",
      answer: "Argentina",
    },
    {
      id: "cqhistoryq2",
      question: "In which year did the Titanic sink?",
      answer: "1912",
    },
    {
      id: "cqhistoryq3",
      question: "How long was The Hundred Years' War?",
      answer: "116 Years",
    },
    {
      id: "cqhistoryq4",
      question: "In which year did decimalisation happen in the UK?",
      answer: "1971",
    },
    {
      id: "cqhistoryq5",
      question: "In which year did man first walk on the moon?",
      answer: "1969",
    },
    {
      id: "cqhistoryq6",
      question:
        "What was the number of the Apollo mission that first landed men on the moon?",
      answer: "11",
    },
    {
      id: "cqhistoryq7",
      question: "In what year did the Great Fire of London start?",
      answer: "1666",
    },
    {
      id: "cqhistoryq8",
      question: "In which year was the Magna Carta signed?",
      answer: "1215",
    },
    {
      id: "cqhistoryq9",
      question: "Where was the Magna Carta signed?",
      answer: "Runnymede (minus points for 'at the bottom'!)",
    },
    {
      id: "cqhistoryq10",
      question:
        "In what year was the Falklands War between The UK and Argentina?",
      answer: "1982",
    },
    {
      id: "cqhistoryq11",
      question: "What was Admiral Nelson's first name?",
      answer: "Horatio",
    },
    {
      id: "cqhistoryq12",
      question: "Who was British Prime Minister at the start of World War II?",
      answer: "Neville Chamberlain",
    },
    {
      id: "cqhistoryq13",
      question:
        "The invasion of which country by Adolf Hitler in 1939 sparked Britain and France to declare war?",
      answer: "Poland",
    },
  ],
  generalQuestions: [
    {
      id: "cqgeneralq1",
      question: "What do all of the numbers on a roulette wheel add up to?",
      answer: "666",
    },
    {
      id: "cqgeneralq2",
      question: "Which entertainer's real name was John Eric Bartholomew?",
      answer: "Eric Morecambe",
    },
    {
      id: "cqgeneralq3",
      question: "Jack Dorsey founded which social media company in 2006?",
      answer: "Twitter",
    },
    {
      id: "cqgeneralq4",
      question: "What colour is an aircraft's black box device?",
      answer: "Orange",
    },
    {
      id: "cqgeneralq5",
      question: "Where would you find the Sea of Tranquility?",
      answer: "The Moon",
    },
    {
      id: "cqgeneralq6",
      question: "In the UK, on what day is All Saints' Day?",
      answer: "1st November",
    },
  ],
  tvQuestions: [
    {
      id: "cqtvq1",
      question: "Which pop-rock group performed the Friends theme tune?",
      answer: "The Rembrandts",
    },
  ],
  movieQuestions: [
    {
      id: "cqmovieq1",
      question:
        "What are the last words of Charles Foster Kane in Citizen Kane?",
      answer: "Rosebud",
    },
    {
      id: "cqmovieq2",
      question:
        "What was the name of the character played by Sigourney Weaver in the Alien film series?",
      answer: "Ripley",
    },
    {
      id: "cqmovieq3",
      question: "Who played the title role in the 1972 film The Godfather?",
      answer: "Marlon Brando",
    },
    {
      id: "cqmovieq4",
      question:
        "Norma Jean Mortenson was the birth name of which American actress?",
      answer: "Marilyn Monroe",
    },
  ],
  literatureQuestions: [
    {
      id: "cqliteratureq1",
      question: "Eric Arthur Blair was the real name of which author?",
      answer: "George Orwell",
    },
    {
      id: "cqliteratureq2",
      question:
        "Charles Lutwidge Dodgson was the real name of which British author?",
      answer: "Lewis Carroll",
    },
    {
      id: "cqliteratureq3",
      question: "What was the name of the parrot in Treasure Island?",
      answer: "Captain Flint",
    },
  ],
  natureQuestions: [
    {
      id: "cqnatureq1",
      question: "What is the world's fastest land animal?",
      answer: "Cheetah",
    },
    {
      id: "cqlnatureq2",
      question: "What is the largest land mammal?",
      answer: "Elephant (African bush elephant)",
    },
    {
      id: "cqnatureq3",
      question: "What is the largest fish in the ocean?",
      answer: "Whale Shark",
    },
    {
      id: "cqnatureq4",
      question:
        "Which weather phenomenon derives from the Spanish for 'little boy'?",
      answer: "El Nino",
    },
    {
      id: "cqnatureq5",
      question:
        "Which animal is responsible for the most human deaths worldwide?",
      answer: "Mosquitos",
    },
    {
      id: "cqnatureq6",
      question: "From which flower do we get the spice saffron?",
      answer: "Crocus",
    },
  ],
  musicQuestions: [
    {
      id: "cqmusicq1",
      question: "What were British band The Beatles originally called?",
      answer: "The Quarrymen",
    },
    {
      id: "cqmusicq2",
      question: "Reginald Dwight is the birth name of which singer?",
      answer: "Elton John",
    },
    {
      id: "cqmusicq3",
      question: "Who's real name is George O'Dowd?",
      answer: "Boy George",
    },
  ],
  scienceQuestions: [
    {
      id: "cqscienceq1",
      question: "Diamond is a solid form of which chemical element?",
      answer: "Carbon",
    },
    {
      id: "cqscienceq2",
      question: "What is the most common human blood type?",
      answer: "O positive",
    },
    {
      id: "cqscienceq3",
      question: "Au is the chemical symbol for which metal?",
      answer: "Gold",
    },
    {
      id: "cqscienceq4",
      question: "How many bones in are there in the adult human body?",
      answer: "206",
    },
    {
      id: "cqscienceq5",
      question:
        "What is the most common chemical element in the Earth's atmosphere?",
      answer: "Nitrogen",
    },
  ],
  mythologyQuestions: [
    {
      id: "cqmythologyq1",
      question: "Who is the Roman equivalent of the Greek God Zeus?",
      answer: "Jupiter",
    },
    {
      id: "cqmythologyq2",
      question: "In Greek mythology, who fell in love with his own reflection?",
      answer: "Narcissus",
    },
    {
      id: "cqmythologyq3",
      question: "In Greek mythology, Medusa was what type of mythical figure?",
      answer: "Gorgon",
    },
  ],
  foodAndDrinkQuestions: [
    {
      id: "cqfoodanddrinkq1",
      question:
        "What type of pasta has a name meaning 'little worms' in Italian?",
      answer: "Vermicelli",
    },
  ],
  artQuestions: [
    {
      id: "cqartq1",
      question: "The Scream is a famous painting by which artist?",
      answer: "Edvard Munch",
    },
  ],
  videogamesQuestions: [
    {
      id: "cqvideogamesq1",
      question: "What are the names of the 4 ghosts in Pacman?",
      answer: "Inky, Blinky, Pinky and Clyde",
    },
  ],
};

export { classicQuestions };
