import React from "react";

function FlagAndCountry(props) {
  return (
    <div className="flag-and-country-container">
      <div>{props.flag}</div>
      <h4>{props.name}</h4>
    </div>
  );
}

export default FlagAndCountry;
