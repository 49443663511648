import React from "react";
import Artist from "./Artist";
import Header from "../Header";
import Footer from "../Footer";
import { artists } from "../../LearnSpecificCategoryDetailsFour";
import AsideNav from "../AsideNav";

function arrangeArtists(artist) {
  return (
    <Artist
      key={artist.id}
      id={artist.id}
      artist={artist.artistName}
      flag={artist.flag}
      lifespan={artist.lifespan}
      birthplace={artist.birthplace}
      artMovement={artist.artMovement}
      famousPieces={artist.famousPieces}
      notes={artist.notes}
    />
  );
}

function LearnArtists() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Artists</h1>
        <div className="container">
          <h2 className="category-subtitle centre">British Artists</h2>
          <div className="arrange-artists">
            {artists.britishArtists.map(arrangeArtists)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Dutch Artists</h2>
          <div className="arrange-artists">
            {artists.dutchArtists.map(arrangeArtists)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">French Artists</h2>
          <div className="arrange-artists">
            {artists.frenchArtists.map(arrangeArtists)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Italian Artists</h2>
          <div className="arrange-artists">
            {artists.italianArtists.map(arrangeArtists)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Spanish Artists</h2>
          <div className="arrange-artists">
            {artists.spanishArtists.map(arrangeArtists)}
          </div>

          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">US Artists</h2>
          <div className="arrange-artists">
            {artists.americanArtists.map(arrangeArtists)}
          </div>
        </div>
      </section>
      <AsideNav />
      <Footer />
    </div>
  );
}

export default LearnArtists;
