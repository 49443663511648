import React from "react";
import "../css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="footer-wrapper">
      <div className="footer">
        Waddauno is on the following social media
        <div className="footer-social-wrapper">
          <FontAwesomeIcon
            size="2x"
            className="footer-social"
            icon={faFacebook}
          />
          <FontAwesomeIcon
            size="2x"
            className="footer-social"
            icon={faTwitter}
          />
          <FontAwesomeIcon
            size="2x"
            className="footer-social"
            icon={faInstagram}
          />
          <a
            href="https://www.youtube.com/channel/UChqhBqUeYpJRs2TWMSsiERg"
            target="_blank"
          >
            <FontAwesomeIcon
              size="2x"
              className="footer-social"
              icon={faYoutube}
            />
          </a>
        </div>
      </div>
      <div className="footer-banner">WADDAUNO - What do you know?</div>
    </footer>
  );
}

export default Footer;
