import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import AsideNav from "../AsideNav";
import flagsAll from "../../FlagsJson";
import FlagCountryAndCapital from "../FlagCountryAndCapital";

function ArrangeCapitals(cap) {
  return (
    <FlagCountryAndCapital
      key={cap.code}
      flag={cap.svg}
      name={cap.name}
      capital={cap.capital}
    />
  );
}

function LearnCapital() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Capital Cities</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Capital cities of the world along with
                their country and its flag. You can see how well you've
                remembered them using the capital cities quiz.
              </p>
              <Link to="../practice/capitalcities-quiz" className="next">
                Go to capital cities quiz
              </Link>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small20.png")}
              alt="cartoon character with black fluffy hair and beard"
            />
          </div>

          <div className="alter-ego-list-container">
            {flagsAll.filter((f) => f.capital).map(ArrangeCapitals)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnCapital;
