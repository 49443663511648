import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import "../QuizLogo.svg";
import "../css/style.css";
import "../css/header.css";
import logo from "../LogoWithTextForWebsite.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { navCategories } from "../categories";

function Header() {
  const navRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  function handleClick() {
    setMenuOpen(!menuOpen);
  }
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuOpen]);

  if (window.innerWidth <= 1080) {
    let prevScrollpos = window.scrollY;
    window.onscroll = function () {
      let currentScrollPos = window.scrollY;
      if (prevScrollpos <= 50 || currentScrollPos <= 50) {
        navRef.current.style.top = "0";
      } else if (prevScrollpos > currentScrollPos) {
        navRef.current.style.top = "0";
      } else {
        navRef.current.style.top = "-200px";
      }
      prevScrollpos = currentScrollPos;
    };
  }

  // // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  // let vh = window.innerHeight * 0.01;
  // // Then we set the value in the --vh custom property to the root of the document
  // document.documentElement.style.setProperty("--vh", `${vh}px`);

  // // We listen to the resize event
  // window.addEventListener("resize", () => {
  //   // We execute the same script as before
  //   let vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty("--vh", `${vh}px`);
  // });

  return (
    <nav ref={navRef} className="nav-for-apps">
      <Link to="/">
        <img className="nav-logo" src={logo} alt="Waddauno logo" />
      </Link>
      <ul className={menuOpen ? "nav-links active" : "nav-links"}>
        {navCategories.map((nC) => (
          <li key={nC.id} className="nav-link">
            <Link className="nav-link-link" to={nC.path}>
              {nC.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className="nav-menu-icon" onClick={handleClick}>
        {menuOpen ? (
          <FontAwesomeIcon className="nav-close" icon={faTimes} size="2x" />
        ) : (
          <FontAwesomeIcon className="nav-hamburger" icon={faBars} size="2x" />
        )}
      </div>
    </nav>
  );
}

export default Header;
