import React, { useState } from "react";
import Covered2 from "./Covered2";
import "./css/covered.css";

function Toggler2(props) {
  const [isRevealed, setIsRevealed] = useState(false);
  const toggle = () => {
    setIsRevealed(!isRevealed);
  };

  return (
    <div>
      <div className="no-select" onClick={toggle}>
        {!isRevealed ? (
          <Covered2 />
        ) : (
          <div className="first-line-answers">
            <p className="first-line-book">
              {props.book}
              <span className="first-line-year">({props.year})</span>
            </p>
            <p className="first-line-author">by {props.author}</p>
            {/* <a href={props.linkToBuy} target="_blank"> */}
            <img className="book-img" src={props.bookImg} />
            {/* </a> */}
            <div className="first-line-diagonal-line"></div>
            <span className="first-line-close-answer">X</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Toggler2;
