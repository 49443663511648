import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/style.css";
import "../css/peeps.css";

function Contact() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Get In Touch</h1>
        <div className="container">
          <p className="body-text">
            Your feedback and suggestions are much appreciated, please use the
            form below to get in touch:
          </p>
          <br />
          {/* <form action="https://formsubmit.co/your@email.com" method="POST">
            <input type="text" name="name" required />
            <input type="email" name="email" required />
            <button type="submit">Send</button>
          </form> */}
          <form
            className="contact-form"
            action="https://formsubmit.co/contact@waddauno.com"
            method="POST"
          >
            <label htmlFor="name">Name</label>
            <input
              className="input"
              type="text"
              id="name"
              name="name"
              placeholder="Your name.."
              required
            />
            <input
              type="hidden"
              name="_subject"
              value="new message from 'contact us' page"
            />
            <input
              type="hidden"
              name="_next"
              value="http://waddauno.com/thankyou"
            />

            <label htmlFor="email">Email</label>
            <input
              className="input"
              type="email"
              id="email"
              name="email"
              placeholder="Your email address.."
              required
            />

            <label htmlFor="msg">Message</label>
            <textarea
              className="input"
              type="textarea"
              id="msg"
              name="message"
              placeholder="Your message.."
              rows="6"
              cols="50"
              required
            ></textarea>
            <button className="submit" type="submit" value="Submit">
              Submit
            </button>
            {/* <input className="submit" type="submit" value="Submit" /> */}
          </form>
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-small1.png")}
            className="peep-learning-right"
            alt="smiling cartoon character"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
