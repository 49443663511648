import React from "react";
import "../../css/top5.css";

function LearnTopFiveOneCol(props) {
  return (
    <div className="top5flex">
      <h2 className={"top5-one-col"}>{props.col1}</h2>
    </div>
  );
}

export default LearnTopFiveOneCol;
