import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "../css/style.css";

function Thankyou() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <div className="thankyou-flex">
          <h1 className="thankyou-form-submit">
            Thank you, your form was submitted successfully!
          </h1>
          <Link className="next" to="../home">
            Back to home page
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Thankyou;
