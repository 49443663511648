import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import LearnTopFiveThreeCols from "./LearnTopFiveThreeCols";
import LearnTopFiveThreeColsHeading from "./LearnTopFiveThreeColsHeading";
import LearnTopFiveFourCols from "./LearnTopFiveFourCols";
import LearnTopFiveHeadingFourCols from "./LearnTopFiveHeadingFourCols";
import { topFivesSport } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive
      key={topFive.key}
      col1={topFive.col1}
      col2={topFive.col2}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}
function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col2country={topFive.col2country}
      col3country={topFive.col3country}
      col4={topFive.col4}
    />
  );
}
function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveFourCols(topFive) {
  return (
    <LearnTopFiveFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
      col5={topFive.col5}
    />
  );
}
function ArrangeTopFiveFourColsHeading(topFive) {
  return (
    <LearnTopFiveHeadingFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
    />
  );
}

function LearnFootballRecords() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Football Records</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Current records in football (soccer), Please note that these are
                dynamic facts and may change in the future as records are
                broken. If you notice any that are out of date, feel free to let
                us know on the contact us page. All stats listed are updated at
                the end of each month, and are correct as of 31/10/2022.
              </p>
              <p className="body-text">
                For records relating to players, if a player is still active,
                i.e., not retired, they are{" "}
                <span className="blue-text">highlighted blue</span>.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small18.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">World Cup Finals</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 6 Most Appearances at Finals
              </h2>
              <div className="sidebar-line"></div>

              {topFivesSport.football.footballPlayersAndRecords.Top6MostAppearancesinWorldCupFinalsMatchesHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top6MostAppearancesinWorldCupFinalsMatches.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Goalscorers at Finals
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5GoalscorersatWorldCupFinalsHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5GoalscorersatWorldCupFinals.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 6 Most World Cup Wins
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top6WorldCupWinningCountriesHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top6WorldCupWinningCountries.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Countries by Games Played in Finals
              </h2>
              <div className="sidebar-line"></div>
              {/* <div className="football-record-thinner"> */}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostGamesPlayedatWorldCupFinalsHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostGamesPlayedatWorldCupFinals.map(
                ArrangeTopFive
              )}
              {/* </div> */}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">International records</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped Argentinian Players
              </h2>
              <div className="sidebar-line"></div>

              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedArgentinianPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedArgentinianPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For Argentina
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForArgentinaHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForArgentina.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped Brazilian Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedBrazilianPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedBrazilPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For Brazil
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForBrazilHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForBrazil.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped English Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedEnglandPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedEnglandPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For England
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForEnglandHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForEngland.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped France Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedFrancePlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedFrancePlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For France
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForFranceHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForFrance.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped German Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedGermanyPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedGermanyPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For Germany
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForGermanyHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForGermany.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped Italian Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedItalianPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedItalianPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For Italy
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForItalyHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForItaly.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped Dutch Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedNetherlandsPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedNetherlandsPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 6 Most International Goals For Netherlands
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForNetherlandsHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForNetherlands.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped Portugese Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedPortugalPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedPortugalPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For Portugal
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForPortugalHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForPortugal.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped Spanish Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedSpainPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedSpainPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For Spain
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForSpainHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForSpain.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Capped Uruguayan Players
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedUruguayPlayersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostCappedUruguayPlayers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most International Goals For Uruguay
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForUruguayHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostInternationalGoalsForUruguay.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">General</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Largest Football Stadiums in Europe
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5LargestFootballStadiumsinEuropeHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5LargestFootballStadiumsinEurope.map(
                ArrangeTopFiveFourCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Players With Most Champions League Appearances
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top6PlayersWithMostChampionsLeagueAppearancesHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top6PlayersWithMostChampionsLeagueAppearances.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Goalscorers in the Champions League
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5GoalscorersintheChampionsLeagueHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5GoalscorersintheChampionsLeague.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Successful Champions League Teams
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5MostSuccessfulChampionsLeagueTeamsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5MostSuccessfulChampionsLeagueTeams.map(
                ArrangeTopFiveFourCols
              )}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">England</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Top Flight League Titles
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5TopFlightLeagueTitlesHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5TopFlightLeagueTitles.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Premier League Goalscorers
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5PremierLeagueGoalscorersHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5PremierLeagueGoalscorers.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Top Flight English League Goalscorers
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5TopFlightEnglishLeagueGoalscorersHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5TopFlightEnglishLeagueGoalscorers.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Top 5 FA Cup Wins</h2>
              <div className="sidebar-line"></div>

              {topFivesSport.football.footballPlayersAndRecords.Top5FACupWinsHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5FACupWins.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Top 5 League Cup Wins</h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Top5LeagueCupWinsHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Top5LeagueCupWins.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Last 5 League Cup Sponsors
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.footballPlayersAndRecords.Last5LeagueCupSponsorsHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.footballPlayersAndRecords.Last5LeagueCupSponsors.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Premier League Appearances
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.premMostAppsHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.premierLeague.premMostApps.map(
                ArrangeTopFive
              )}
            </div>

            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Premier League Assists
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.premMostAssistsHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.premierLeague.premMostAssists.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Youngest Premier League Appearance
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.youngestPremPlayerAppHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.premierLeague.youngestPremPlayerApp.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Youngest Premier League Goal Scorer
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.youngestPremPlayerGoalHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.premierLeague.youngestPremPlayerGoal.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Oldest Premier League Appearance
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.oldestPremPlayerAppsHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.premierLeague.oldestPremPlayerApps.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Oldest Premier League Goal Scorer
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.oldestPremPlayerGoalHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesSport.football.premierLeague.oldestPremPlayerGoal.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 7 Most Premier League Red Cards
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.premierLeagueMostRedCardsHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.premierLeague.premierLeagueMostRedCards.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Premier League Yellow Cards
              </h2>
              <div className="sidebar-line"></div>
              {topFivesSport.football.premierLeague.premierLeagueMostYellowCardsHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesSport.football.premierLeague.premierLeagueMostYellowCards.map(
                ArrangeTopFive
              )}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnFootballRecords;
