import React from "react";
import { Link } from "react-router-dom";

function PairsCongratsMsg(props) {
  return (
    <div
      className={
        props.turns > 1 && Math.abs(props.correctGuesses) === props.cards.length
          ? "fade-in-congrats"
          : "hidden-congrats"
      }
    >
      {props.turns > 1 &&
        Math.abs(props.correctGuesses) === props.cards.length && (
          <div className="congrats-msg-cover-bg">
            <div className="pairs-congrats-msg">
              <p>Play Again?</p>
              <div className="wordsearch-congrats-msg-buttons-container">
                <button
                  className="pairs-easy-btn"
                  onClick={
                    props.diff === "easy"
                      ? () => props.shuffleCards()
                      : () => props.setDiff("easy")
                  }
                >
                  easy
                </button>
                <button
                  className="pairs-medium-btn"
                  onClick={
                    props.diff === "medium"
                      ? () => props.shuffleCards()
                      : () => props.setDiff("medium")
                  }
                >
                  medium
                </button>
                <button
                  className="pairs-hard-btn"
                  onClick={
                    props.diff === "hard"
                      ? () => props.shuffleCards()
                      : () => props.setDiff("hard")
                  }
                >
                  hard
                </button>
              </div>
              <Link to="../puzzles-and-games" className="pairs-quit-btn">
                Different Game
              </Link>
            </div>
          </div>
        )}
    </div>
  );
}

export default PairsCongratsMsg;
