import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AfricanCountry from "../AfricanCountry";
import { africanCountries } from "../LearnSpecificCategoryDetailsFive";
import "../css/us-states-map.css";
import "../css/african-countries.css";

function arrangeAfricanCountries(ac) {
  return (
    <AfricanCountry
      key={ac.id}
      countryName={ac.countryName}
      capitalCity={ac.capitalCity}
      countryFlag={ac.countryFlag}
    />
  );
}

function AfricanCountries() {
  const [countryNames, setCountryNames] = useState([
    "algeria",
    "angola",
    "benin",
    "botswana",
    "burkina faso",
    "burundi",
    "cameroon",
    "central african republic",
    "chad",
    "democratic republic of the congo",
    "djibouti",
    "egypt",
    "eswatini",
    "equatorial guinea",
    "eritrea",
    "ethiopia",
    "gabon",
    "gambia",
    "ghana",
    "guinea",
    "guinea-bissau",
    "cote d'ivoire",
    "kenya",
    "lesotho",
    "liberia",
    "libya",
    "madagascar",
    "malawi",
    "mali",
    "mauritania",
    "morocco",
    "mozambique",
    "namibia",
    "niger",
    "nigeria",
    "republic of the congo",
    "rwanda",
    "senegal",
    "sierra leone",
    "somalia",
    "south africa",
    "sudan",
    "south sudan",
    "tanzania",
    "togo",
    "tunisia",
    "uganda",
    "western sahara",
    "zambia",
    "zimbabwe",
  ]);
  const [answers, setAnswers] = useState([]);
  const [correctGuesses, setCorrectGuesses] = useState([]);
  const [missed, setMissed] = [];
  const [answer, setAnswer] = useState("");
  const [count, setCount] = useState(0);
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [giveUp, setGiveUp] = useState(false);
  const inputRef = useRef();
  const correctAnswerMapRef = useRef();

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn]);

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      correctAnswerMapRef.current.classList.remove("correct-answer-map");
    }, 1500);
  }, [count]);

  useEffect(() => {
    if (!correctGuesses.includes("Cote D'ivoire")) {
      if (answer.toLowerCase() === "ivory coast") {
        setAnswer("cote d'ivoire");
      }
    }
  }, [answer]);

  function focus() {
    inputRef.current && inputRef.current.focus();
  }

  function handleChange(e) {
    if (!giveUp) {
      setAnswer(e.target.value.trim());
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    inputRef.current.value = "";
    if (!giveUp) {
      checkAnswer();
      focus();
    }
  }

  function handleKeyDown(e) {
    if (e.code === "Enter" && !giveUp) {
      handleSubmit(e);
    }
  }

  function giveUpAndRevealAnswers() {
    setGiveUp(true);
    inputRef.current.disabled = true;
    for (let x = 0; x < countryNames.length; x++) {
      if (
        !answers.includes(
          countryNames[x]
            .toLocaleLowerCase()
            .split(" ")
            .join("-")
            .split("'")
            .join("-")
        )
      ) {
        setTimerOn(false);
        setAnswers((current) => [
          ...current,
          countryNames[x]
            .toLocaleLowerCase()
            .split(" ")
            .join("-")
            .split("'")
            .join("-"),
        ]);
      }
    }
  }

  function playAgain() {
    inputRef.current.disabled = false;
    inputRef.current.value = "";
    setAnswers([]);
    setCount(0);
    setGiveUp(false);
    setTime(0);
    setTimerOn(false);
    setCorrectGuesses([]);
    focus();
  }

  function checkAnswer() {
    let answerLower = answer.toLowerCase().split(" ");
    for (let x = 0; x < answerLower.length; x++) {
      answerLower[x] =
        answerLower[x].charAt(0).toUpperCase() + answerLower[x].substring(1);
    }
    let answerCapitalized = answerLower.join(" ");
    if (
      countryNames.includes(answer.toLowerCase()) &&
      !answers.includes(answer.toLowerCase().split(" ").join("-")) &&
      !correctGuesses.includes(answerCapitalized)
    ) {
      setAnswers((current) => [
        ...current,
        answer.toLowerCase().split(" ").join("-").split("'").join("-"),
      ]);
      correctAnswerMapRef.current.classList.add("correct-answer-map");
      if (!correctGuesses.includes(answerCapitalized)) {
        setCount((count) => count + 1);
      }
      if (!correctGuesses.includes(answerCapitalized)) {
        setCorrectGuesses((current) => [...current, answerCapitalized]);
      }
    }
    if (!timerOn) {
      setTimerOn(true);
    }
    if (count >= 49) {
      setTimerOn(false);
    }
  }
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <div className="us-map-game-flex">
          <div className="your-category us-states-count">Correct: {count}</div>
          <div className="us-states-timer">
            <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          </div>
          <form className="us-states-form" onKeyDown={handleKeyDown}>
            <input
              type="text"
              ref={inputRef}
              className="us-states-input"
              onChange={handleChange}
            />
            <button onClick={handleSubmit} className="us-states-btn">
              Submit
            </button>
          </form>
        </div>
        <div className="africa-map-container">
          <div ref={correctAnswerMapRef} className="div-hidden">
            {correctGuesses[count - 1]}
          </div>
          <img
            className="africa-map"
            src={require("../images/africa/AfricaMap1.jpg")}
          />
          {answers &&
            answers.map((a) => (
              <img
                key={a}
                className={`african-country-${a}`}
                src={require(`../images/africa/${a}.png`)}
              />
            ))}
        </div>
        <div className="container">
          <div className="us-states-buttons-container">
            <button
              className="us-states-give-up-btn"
              onClick={giveUpAndRevealAnswers}
            >
              Give Up
            </button>
            <button className="us-states-play-again-btn" onClick={playAgain}>
              Play Again
            </button>
          </div>
        </div>
      </section>
      {answers.length >= 50 || giveUp ? (
        <section className="us-states-results">
          <div className="count-result-container">
            <span>YOU GOT</span>
            <span className="count-result">
              {count !== 50 ? count : "ALL 50"}
            </span>
            <span>COUNTRIES IN</span>
          </div>
          <div className="us-states-timer">
            <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          </div>
        </section>
      ) : (
        ""
      )}

      {correctGuesses.length ? <div className="line-even-margin"></div> : ""}
      {correctGuesses.length ? (
        <>
          <p className="state-cards-missed-correct">Correct:</p>
          <div className="state-cards-container">
            {africanCountries
              .filter((x) => correctGuesses.includes(x.countryName))
              .sort()
              .map(arrangeAfricanCountries)}
          </div>
        </>
      ) : (
        ""
      )}
      {giveUp && <div className="line-even-margin"></div>}
      {giveUp && (
        <>
          <p className="state-cards-missed-correct">Missed:</p>
          <div className="state-cards-container">
            {africanCountries
              .filter((x) => !correctGuesses.includes(x.countryName))
              .sort()
              .map(arrangeAfricanCountries)}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default AfricanCountries;
