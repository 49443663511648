import React from "react";
import Film from "./Film";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/film.css";
import "../../css/artists.css";

import {
  notableFilmsStarting1928,
  pre1930sFilms,
} from "../../LearnSpecificCategoryDetailsFour";

function ArrangeFilms(y) {
  return (
    <Film
      key={y.id}
      id={y.id}
      year={y.year}
      films={y.films}
      bestPictureWinner={y.bestPictureWinner}
      bestPictureWinner2={y.bestPictureWinner2}
    />
  );
}

function LearnFilmsByDecade() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Best Films By Decade</h1>
        <div className="container">
          <p className="body-text">
            This is not an exhaustive list, because you'll be here forever if
            you try and watch every single film ever made! So these are the
            highest rated films each year according to IMDB along with the film
            which received the Oscar for Best Picture.
          </p>
          <p className="body-text">
            We've made a spreadsheet containing these films, if you'd like a
            copy click the button below, and then go to 'file', 'make a copy',
            and fill it in as you watch them!
          </p>
          <div className="film-spreadsheet-button-container">
            <a
              className="art-list"
              href="https://docs.google.com/spreadsheets/d/1_FsVtd-0iimYlvCQezX1j5oWaiFBZnO_9XZ_EhwCMqA/edit?usp=sharing"
            >
              Film Spreadsheet
            </a>
          </div>

          <h2 className="category-subtitle">Notable pre 1930s films</h2>
          <div className="arrange-single-film-container">
            {pre1930sFilms.map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <p className="small-text">
            Please note: The film listed as Best Picture Winner would receive
            the award in the following year. eg. All Quiet on the Western Front
            is a film from 1930 so is listed with that year, but won the award
            in 1931.
          </p>
          <h2 className="category-subtitle">Films of the 1930s</h2>

          <div className="arrange-artists">
            {notableFilmsStarting1928["1930s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 1940s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["1940s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 1950s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["1950s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 1960s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["1960s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 1970s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["1970s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 1980s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["1980s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 1990s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["1990s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 2000s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["2000s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 2010s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["2010s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Films of the 2020s</h2>
          <div className="arrange-artists">
            {notableFilmsStarting1928["2020s"].map(ArrangeFilms)}
          </div>
          <div className="line-subtitle"></div>
        </div>
      </section>
      <AsideNav />
      <Footer />
    </div>
  );
}

export default LearnFilmsByDecade;
