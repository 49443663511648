import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/style.css";
import { mainCategories } from "../categories";
import LearnChoice from "../LearnChoice";

function SetLearnChoices(choice) {
  return (
    <LearnChoice
      key={choice.id}
      name={choice.name}
      path={choice.path}
      bgImg={choice.bgImg}
    />
  );
}

function Learn() {
  return (
    <div className="learning-page-hero push-footer">
      <Header />

      <section className="drop-section-content">
        <div className="menu-page-container">
          <div className="category-flex-container">
            <div className="menu-page-title-div">
              <h1 className="menu-page-title">LEARN</h1>
              <p className="menu-page-explanation">
                Just enough to know everything
              </p>
            </div>
            {mainCategories.map(SetLearnChoices)}
          </div>
        </div>
      </section>
      <img
        src={require("../images/thinker.png")}
        alt="The Thinker bronze statue by Auguste Rodin"
        className="thinker thinker1"
      />
      <img
        src={require("../images/thinker.png")}
        alt="The Thinker bronze statue by Auguste Rodin"
        className="thinker thinker2"
      />
      <Footer />
    </div>
  );
}

export default Learn;
