import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveThreeColsNarrowLeft from "./LearnTopFiveThreeColsNarrowLeft";
import LearnTopFiveThreeColsNarrowLeftHeading from "./LearnTopFiveThreeColsNarrowLeftHeading";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import LearnTopFiveHeadingFourCols from "./LearnTopFiveHeadingFourCols";
import LearnTopFiveFourCols from "./LearnTopFiveFourCols";
import { britishEntertainmentAwards } from "../../LearnSpecificCategoryDetailsTwo";
import { topFivesEntertainment } from "../../topFivesInfo";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/top5.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive key={topFive.key} col2={topFive.col2} col1={topFive.col1} />
  );
}

function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}

function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeColsNarrowLeft
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}

function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsNarrowLeftHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveFourCols(topFive) {
  return (
    <LearnTopFiveFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
    />
  );
}

function ArrangeTopFiveFourColsHeading(topFive) {
  return (
    <LearnTopFiveHeadingFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
    />
  );
}

function LearnBritishEntertainmentAwards() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={britishEntertainmentAwards.mainCategory}
          subCategory={britishEntertainmentAwards.subCategory}
          iconLrg={britishEntertainmentAwards.iconLrg}
          iconSml={britishEntertainmentAwards.iconSml}
          bigPeep={britishEntertainmentAwards.bigPeep}
          intro={britishEntertainmentAwards.intro}
        />
        <div className="top5s-container">
          <div className="top5TwoCols">
            <h2>Last 5 BAFTA TV Leading Actress Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.baftaTV.lastFiveLeadingActressHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.baftaTV.lastFiveLeadingActress.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 BAFTA TV Leading Actor Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.baftaTV.lastFiveLeadingActorHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.baftaTV.lastFiveLeadingActor.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 BAFTA TV Best Drama Series</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.baftaTV.lastFiveDramaSeriesHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.baftaTV.lastFiveDramaSeries.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 BAFTA TV Best Entertainment Performance</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.baftaTV.lastFiveEntertainmentPerformanceHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.baftaTV.lastFiveEntertainmentPerformance.map(
              ArrangeTopFive
            )}
          </div>
        </div>
        <div className="container">
          <h2 className="category-subtitle">Mercury Prize</h2>
          <p className="body-text">
            The Mercury Prize was invented as an alternative to the Brit Awards
            and to promote the best UK and Irish music, it stands as the musical
            equivalent to the Booker Prize and Turner Prize, for literature and
            art respectively.
            <br />
            In 2022 the Mercury prize awards ceremony was postponed for the
            first time in its history, after the death of Queen Elizabeth II, it
            has been rescheduled to take place on 18th October 2022.
          </p>
        </div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Year</th>
                <th>Edition of the Award</th>
                <th>Winning Band/Artist</th>
                <th>Winning Album name</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Year">1992</td>
                <td data-label="Edition of the Award">1st</td>
                <td data-label="Winning Band/Artist">Primal Scream</td>
                <td data-label="Winning Album name">Screamadelica</td>
              </tr>
              <tr>
                <td data-label="Year">1993</td>
                <td data-label="Edition of the Award">2nd</td>
                <td data-label="Winning Band/Artist">Suede</td>
                <td data-label="Winning Album name">Suede</td>
              </tr>
              <tr>
                <td data-label="Year">1994</td>
                <td data-label="Edition of the Award">3rd</td>
                <td data-label="Winning Band/Artist">M People</td>
                <td data-label="Winning Album name">Elegant Slumming</td>
              </tr>
              <tr>
                <td data-label="Year">1995</td>
                <td data-label="Edition of the Award">4th</td>
                <td data-label="Winning Band/Artist">Portishead</td>
                <td data-label="Winning Album name">Dummy</td>
              </tr>
              <tr>
                <td data-label="Year">1996</td>
                <td data-label="Edition of the Award">5th</td>
                <td data-label="Winning Band/Artist">Pulp</td>
                <td data-label="Winning Album name">Different Class</td>
              </tr>
              <tr>
                <td data-label="Year">1997</td>
                <td data-label="Edition of the Award">6th</td>
                <td data-label="Winning Band/Artist">Roni Size & Reprazent</td>
                <td data-label="Winning Album name">New Forms</td>
              </tr>
              <tr>
                <td data-label="Year">1998</td>
                <td data-label="Edition of the Award">7th</td>
                <td data-label="Winning Band/Artist">Gomez</td>
                <td data-label="Winning Album name">Bring It On</td>
              </tr>
              <tr>
                <td data-label="Year">1999</td>
                <td data-label="Edition of the Award">8th</td>
                <td data-label="Winning Band/Artist">Talvin Singh</td>
                <td data-label="Winning Album name">Ok</td>
              </tr>
              <tr>
                <td data-label="Year">2000</td>
                <td data-label="Edition of the Award">9th</td>
                <td data-label="Winning Band/Artist">Badly Drawn Boy</td>
                <td data-label="Winning Album name">
                  The Hour of Bewilderbeast
                </td>
              </tr>
              <tr>
                <td data-label="Year">2001</td>
                <td data-label="Edition of the Award">10th</td>
                <td data-label="Winning Band/Artist">PJ Harvey</td>
                <td data-label="Winning Album name">
                  Stories from the City, Stories from the Sea
                </td>
              </tr>
              <tr>
                <td data-label="Year">2002</td>
                <td data-label="Edition of the Award">11th</td>
                <td data-label="Winning Band/Artist">Ms Dynamite</td>
                <td data-label="Winning Album name">A Little Deeper</td>
              </tr>
              <tr>
                <td data-label="Year">2003</td>
                <td data-label="Edition of the Award">12th</td>
                <td data-label="Winning Band/Artist">Dizzee Rascal</td>
                <td data-label="Winning Album name">Boy in da Corner</td>
              </tr>
              <tr>
                <td data-label="Year">2004</td>
                <td data-label="Edition of the Award">13th</td>
                <td data-label="Winning Band/Artist">Franz Ferdinand</td>
                <td data-label="Winning Album name">Franz Ferdinand</td>
              </tr>
              <tr>
                <td data-label="Year">2005</td>
                <td data-label="Edition of the Award">14th</td>
                <td data-label="Winning Band/Artist">
                  Antony and the Johnsons
                </td>
                <td data-label="Winning Album name">I Am a Bird Now</td>
              </tr>
              <tr>
                <td data-label="Year">2006</td>
                <td data-label="Edition of the Award">15th</td>
                <td data-label="Winning Band/Artist">Arctic Monkeys</td>
                <td data-label="Winning Album name">
                  Whatever People Say I Am, That's What I'm Not
                </td>
              </tr>
              <tr>
                <td data-label="Year">2007</td>
                <td data-label="Edition of the Award">16th</td>
                <td data-label="Winning Band/Artist">Klaxons</td>
                <td data-label="Winning Album name">
                  Myths of the Near Future
                </td>
              </tr>
              <tr>
                <td data-label="Year">2008</td>
                <td data-label="Edition of the Award">17th</td>
                <td data-label="Winning Band/Artist">Elbow</td>
                <td data-label="Winning Album name">The Seldom Seen Kid</td>
              </tr>
              <tr>
                <td data-label="Year">2009</td>
                <td data-label="Edition of the Award">18th</td>
                <td data-label="Winning Band/Artist">Speech Debelle</td>
                <td data-label="Winning Album name">Speech Therapy</td>
              </tr>
              <tr>
                <td data-label="Year">2010</td>
                <td data-label="Edition of the Award">19th</td>
                <td data-label="Winning Band/Artist">The xx</td>
                <td data-label="Winning Album name">xx</td>
              </tr>
              <tr>
                <td data-label="Year">2011</td>
                <td data-label="Edition of the Award">20th</td>
                <td data-label="Winning Band/Artist">PJ Harvey</td>
                <td data-label="Winning Album name">Let England Shake</td>
              </tr>
              <tr>
                <td data-label="Year">2012</td>
                <td data-label="Edition of the Award">21st</td>
                <td data-label="Winning Band/Artist">alt-J</td>
                <td data-label="Winning Album name">An Awesome Wave</td>
              </tr>
              <tr>
                <td data-label="Year">2013</td>
                <td data-label="Edition of the Award">22nd</td>
                <td data-label="Winning Band/Artist">James Blake</td>
                <td data-label="Winning Album name">Overgrown</td>
              </tr>
              <tr>
                <td data-label="Year">2014</td>
                <td data-label="Edition of the Award">23rd</td>
                <td data-label="Winning Band/Artist">Young Fathers</td>
                <td data-label="Winning Album name">Dead</td>
              </tr>
              <tr>
                <td data-label="Year">2015</td>
                <td data-label="Edition of the Award">24th</td>
                <td data-label="Winning Band/Artist">Benjamin Clementine</td>
                <td data-label="Winning Album name">At Least for Now</td>
              </tr>
              <tr>
                <td data-label="Year">2016</td>
                <td data-label="Edition of the Award">25th</td>
                <td data-label="Winning Band/Artist">Skepta</td>
                <td data-label="Winning Album name">Konnichiwa</td>
              </tr>
              <tr>
                <td data-label="Year">2017</td>
                <td data-label="Edition of the Award">26th</td>
                <td data-label="Winning Band/Artist">Sampha</td>
                <td data-label="Winning Album name">Process</td>
              </tr>
              <tr>
                <td data-label="Year">2018</td>
                <td data-label="Edition of the Award">27th</td>
                <td data-label="Winning Band/Artist">Wolf Alice</td>
                <td data-label="Winning Album name">Visions of a Life</td>
              </tr>
              <tr>
                <td data-label="Year">2019</td>
                <td data-label="Edition of the Award">28th</td>
                <td data-label="Winning Band/Artist">Dave</td>
                <td data-label="Winning Album name">Psychodrama</td>
              </tr>
              <tr>
                <td data-label="Year">2020</td>
                <td data-label="Edition of the Award">29th</td>
                <td data-label="Winning Band/Artist">
                  Michael Kiwanuka – Kiwanuka
                </td>
                <td data-label="Winning Album name">Kiwanuka</td>
              </tr>
              <tr>
                <td data-label="Year">2021</td>
                <td data-label="Edition of the Award">30th</td>
                <td data-label="Winning Band/Artist">Arlo Parks</td>
                <td data-label="Winning Album name">Collapsed in Sunbeams</td>
              </tr>
              <tr>
                <td data-label="Year">2022</td>
                <td data-label="Edition of the Award">31st</td>
                <td data-label="Winning Band/Artist">Little Simz</td>
                <td data-label="Winning Album name">Sometimes I Might Be Introvert</td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={britishEntertainmentAwards.importantFacts}
          classicQuestions={britishEntertainmentAwards.classicQuestions}
          exampleQuestions={britishEntertainmentAwards.exampleQuestions}
          thoughtsTipsIdeas={britishEntertainmentAwards.thoughtsTipsAndIdeas}
          smallPeep1={britishEntertainmentAwards.smallPeep1}
          smallPeep2={britishEntertainmentAwards.smallPeep2}
          disclaimer={britishEntertainmentAwards.disclaimer}
        />
        <AsideNav />
      </section>

      <div className="top5s-container">
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Mercury Prize Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesEntertainment.mercuryPrizeHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}

          {topFivesEntertainment.mercuryPrize.map(ArrangeTopFiveThreeCols)}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Brit Awards Album of the Year</h2>
          <div className="sidebar-line"></div>
          {topFivesEntertainment.britAwardsAlbumOfYearHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}

          {topFivesEntertainment.britAwardsAlbumOfYear.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols top5FourCols">
          <h2>Last 5 BAFTA Film Awards</h2>
          <div className="sidebar-line"></div>
          {topFivesEntertainment.baftaFilm.lastFiveBaftaFilmHeadings.map(
            ArrangeTopFiveFourColsHeading
          )}

          {topFivesEntertainment.baftaFilm.lastFiveBaftaFilm.map(
            ArrangeTopFiveFourCols
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnBritishEntertainmentAwards;
