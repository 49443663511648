import React, { useState, useRef, useEffect } from "react";
import Footer from "./Footer";
import "../css/style.css";
import "../css/flashcard.css";
import "../css/peeps.css";
import data from "../data";
import HeaderApp from "./HeaderApp";

function Flashcard() {
  const startButtonRef = useRef();
  const pauseButtonRef = useRef();
  const questionRef = useRef();
  const answerRef = useRef();
  const flipCardInnerRef = useRef();
  const myBarRef = useRef();

  const [q, setQ] = useState({});
  const [questionList, setQuestionList] = useState(data);
  const [timerOn, setTimerOn] = useState(false);
  const [time, setTime] = useState(10000);
  const [width, setWidth] = useState(100);

  const [paused, setPaused] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [n, setN] = useState(0);
  const [numOfQs, setNumOgQs] = useState(data.length);

  useEffect(() => {
    startButtonRef.current.addEventListener("click", startTime);

    // cleanup this component
    // return () => {
    //   startButtonRef.current.removeEventListener("click", startTime);
    //   pauseButtonRef.current.removeEventListener("click", pauseTime);
    // };
  }, []);

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn]);

  useEffect(() => {
    move();
  });

  useEffect(() => {
    if (paused) setTimerOn(false);
    if (!paused) setTimerOn(true);
  }, [paused]);

  function move() {
    setWidth(time / 100);
    myBarRef.current.style.width = width + "%";
  }

  function startTime() {
    setTimerOn(true);
    setPaused(false);
    setGameStarted(true);
    showQuestion();
  }

  function pauseTime() {
    setPaused((paused) => !paused);
  }

  function showAnswer() {
    flipCardInnerRef.current.classList.remove("flip-the-card");
    questionRef.current.classList.remove("fade-in");
  }

  function showQuestion() {
    setQ(questionList[n]);
    flipCardInnerRef.current.classList.add("flip-the-card");
    questionRef.current.classList.add("fade-in");

    if (n > numOfQs) {
      flipCardInnerRef.current.classList.remove("flip-the-card");
    }
  }

  if (time === 0) {
    showAnswer();
    // setN((n) => n + 1);
  }

  if (time === -3000) {
    setTime(10000);
    showQuestion();
  }

  //randomise the questions (using Fisher-Yates Algorithm)
  for (let i = data.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = data[i];
    data[i] = data[j];
    data[j] = temp;
  }

  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section>
        <h1 className="category-title">Quickfire Questions</h1>
        <div className="container flashcard-intro-container">
          <p className="body-text">
            Quickfire Quiz Questions from our database. <br />
            No input required - the cards simply flip to reveal the answer
            (pause if you need more time).
          </p>
        </div>
        <div className="flashcard-container">
          <div className="flip-card">
            <div
              ref={flipCardInnerRef}
              className="flip-card-inner flip-the-card"
            >
              <div className="flip-card-front">
                <h2 ref={questionRef} className="flashcard-question">
                  {q.answer}
                </h2>
              </div>
              <div className="flip-card-back">
                <h2 ref={answerRef} className="flashcard-answer">
                  {q.question}
                </h2>
              </div>
            </div>
          </div>
          <div id="myProgress">
            <div className="my-bar" ref={myBarRef} id="myBar"></div>
          </div>
          <div className="buttons">
            {!gameStarted ? (
              <button ref={startButtonRef} className="start" id="start">
                START
              </button>
            ) : (
              ""
            )}

            {gameStarted ? (
              <button
                onClick={pauseTime}
                ref={pauseButtonRef}
                className="start"
                id="start"
              >
                {paused ? "RESUME" : "PAUSE"}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flashcard-peeps-div">
          <img
            src={require("../images/peep-standing2.png")}
            alt="cartoon man looking at quiz question and answer"
            className="peep-fixed-position-right peep-flip"
          />
          <img
            src={require("../images/peep-standing3.png")}
            alt="cartoon woman looking at quiz question and answer"
            className="peep-fixed-position-left"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Flashcard;
