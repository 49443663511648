import React from "react";
import "../css/QuestionBankQandA.css";
import "../css/questionbank.css";
import "../css/covered.css";
import "../css/artists.css";
import "../css/firstLine.css";
import "../css/coins.css";

function OldMoneyNotes(props) {
  return (
    <div className="first-line-container">
      <p className="coin-name">{props.moneyName}</p>
      <p className="first-line-book">{props.moneyDetails}</p>
      <div className="coin-img-container">
        {/* <a href={props.linkToBuy} target="_blank"> */}
        <img className="note-img" src={props.moneyImg} />
        {/* </a> */}
      </div>
    </div>
  );
}

export default OldMoneyNotes;
