import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { theBible } from "../../LearnSpecificCategoryDetails";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/not-tables.css";

function LearnTheBible() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={theBible.mainCategory}
          subCategory={theBible.subCategory}
          iconLrg={theBible.iconLrg}
          iconSml={theBible.iconSml}
          bigPeep={theBible.bigPeep}
          intro={theBible.intro}
          intro2={theBible.intro2}
          intro3={theBible.intro3}
          intro4={theBible.intro4}
        />
        <div className="container">
          <h2 className="category-subtitle">Books of the Old Testament</h2>

          <div className="table-flex-1">
            {/* <div> */}
            <div className="table-flex-row">
              <p>1</p>
              <p>Genesis</p>
            </div>
            <div className="table-flex-row">
              <p>2</p>
              <p>Exodus</p>
            </div>
            <div className="table-flex-row">
              <p>3</p>
              <p>Leviticus</p>
            </div>
            <div className="table-flex-row">
              <p>4</p>
              <p>Numbers</p>
            </div>
            <div className="table-flex-row">
              <p>5</p>
              <p>Deuteronomy</p>
            </div>
            <div className="table-flex-row">
              <p>6</p>
              <p>Joshua</p>
            </div>
            <div className="table-flex-row">
              <p>7</p>
              <p>Judges</p>
            </div>
            <div className="table-flex-row">
              <p>8</p>
              <p>Ruth</p>
            </div>
            <div className="table-flex-row">
              <p>9</p>
              <p>1 Samuel</p>
            </div>
            <div className="table-flex-row">
              <p>10</p>
              <p>2 Samuel</p>
            </div>
            <div className="table-flex-row">
              <p>11</p>
              <p>1 Kings</p>
            </div>
            <div className="table-flex-row">
              <p>12</p>
              <p>2 Kings</p>
            </div>
            <div className="table-flex-row">
              <p>13</p>
              <p>1 Chronicles</p>
            </div>
            <div className="table-flex-row">
              <p>14</p>
              <p>2 Chronicles</p>
            </div>
            <div className="table-flex-row">
              <p>15</p>
              <p>Ezra</p>
            </div>
            <div className="table-flex-row">
              <p>16</p>
              <p>Nehemiah</p>
            </div>
            <div className="table-flex-row">
              <p>17</p>
              <p>Esther</p>
            </div>
            <div className="table-flex-row">
              <p>18</p>
              <p>Job</p>
            </div>
            <div className="table-flex-row">
              <p>19</p>
              <p>Psalms</p>
            </div>
            <div className="table-flex-row">
              <p>20</p>
              <p>Proverbs</p>
            </div>
            <div className="table-flex-row">
              <p>21</p>
              <p>Ecclesiastes</p>
            </div>
            <div className="table-flex-row">
              <p>22</p>
              <p>Song of Solomon</p>
            </div>
            <div className="table-flex-row">
              <p>23</p>
              <p>Isaiah</p>
            </div>
            <div className="table-flex-row">
              <p>24</p>
              <p>Jeremiah</p>
            </div>
            <div className="table-flex-row">
              <p>25</p>
              <p>Lamentations</p>
            </div>
            <div className="table-flex-row">
              <p>26</p>
              <p>Ezekiel</p>
            </div>
            <div className="table-flex-row">
              <p>27</p>
              <p>Daniel</p>
            </div>
            <div className="table-flex-row">
              <p>28</p>
              <p>Hosea</p>
            </div>
            <div className="table-flex-row">
              <p>29</p>
              <p>Joel</p>
            </div>
            <div className="table-flex-row">
              <p>30</p>
              <p>Amos</p>
            </div>
            <div className="table-flex-row">
              <p>31</p>
              <p>Obadiah</p>
            </div>
            <div className="table-flex-row">
              <p>32</p>
              <p>Jonah</p>
            </div>
            <div className="table-flex-row">
              <p>33</p>
              <p>Micah</p>
            </div>
            <div className="table-flex-row">
              <p>34</p>
              <p>Nahum</p>
            </div>
            <div className="table-flex-row">
              <p>35</p>
              <p>Habakkuk</p>
            </div>
            <div className="table-flex-row">
              <p>36</p>
              <p>Zephaniah</p>
            </div>
            <div className="table-flex-row">
              <p>37</p>
              <p>Haggai</p>
            </div>
            <div className="table-flex-row">
              <p>38</p>
              <p>Zechariah</p>
            </div>
            <div className="table-flex-row">
              <p>39</p>
              <p>Malachi</p>
            </div>
          </div>

          <h2 className="category-subtitle">Books of the New Testament</h2>
          <div className="table-flex-2">
            <div className="table-flex-row">
              <p>40</p>
              <p>Matthew</p>
            </div>
            <div className="table-flex-row">
              <p>41</p>
              <p>Mark</p>
            </div>
            <div className="table-flex-row">
              <p>42</p>
              <p>Luke</p>
            </div>
            <div className="table-flex-row">
              <p>43</p>
              <p>John</p>
            </div>
            <div className="table-flex-row">
              <p>44</p>
              <p>Acts</p>
            </div>
            <div className="table-flex-row">
              <p>45</p>
              <p>Romans</p>
            </div>
            <div className="table-flex-row">
              <p>46</p>
              <p>1 Corinthians</p>
            </div>
            <div className="table-flex-row">
              <p>47</p>
              <p>2 Corinthians</p>
            </div>
            <div className="table-flex-row">
              <p>48</p>
              <p>Galatians</p>
            </div>
            <div className="table-flex-row">
              <p>49</p>
              <p>Ephesians</p>
            </div>
            <div className="table-flex-row">
              <p>50</p>
              <p>Philippians</p>
            </div>
            <div className="table-flex-row">
              <p>51</p>
              <p>Colossians</p>
            </div>
            <div className="table-flex-row">
              <p>52</p>
              <p>1 Thessalonians</p>
            </div>
            <div className="table-flex-row">
              <p>53</p>
              <p>2 Thessalonians</p>
            </div>
            <div className="table-flex-row">
              <p>54</p>
              <p>1 Timothy</p>
            </div>
            <div className="table-flex-row">
              <p>55</p>
              <p>2 Timothy</p>
            </div>
            <div className="table-flex-row">
              <p>56</p>
              <p>Titus</p>
            </div>
            <div className="table-flex-row">
              <p>57</p>
              <p>Philemon</p>
            </div>
            <div className="table-flex-row">
              <p>58</p>
              <p>Hebrews</p>
            </div>
            <div className="table-flex-row">
              <p>59</p>
              <p>James</p>
            </div>
            <div className="table-flex-row">
              <p>60</p>
              <p>1 Peter</p>
            </div>
            <div className="table-flex-row">
              <p>61</p>
              <p>2 Peter</p>
            </div>
            <div className="table-flex-row">
              <p>62</p>
              <p>1 John</p>
            </div>
            <div className="table-flex-row">
              <p>63</p>
              <p>2 John</p>
            </div>
            <div className="table-flex-row">
              <p>64</p>
              <p>3 John</p>
            </div>
            <div className="table-flex-row">
              <p>65</p>
              <p>Jude</p>
            </div>
            <div className="table-flex-row">
              <p>66</p>
              <p>Revelation</p>
            </div>
          </div>
          <h2 className="category-subtitle">
            The 10 Plagues of Egypt in order (Exodus)
          </h2>
          <div className="table-flex-3">
            <div className="table-flex-row-2">
              <p>1</p>
              <p>Water to Blood</p>
            </div>
            <div className="table-flex-row-2">
              <p>2</p>
              <p>Frogs</p>
            </div>
            <div className="table-flex-row-2">
              <p>3</p>
              <p>Gnats</p>
            </div>
            <div className="table-flex-row-2">
              <p>4</p>
              <p>Flies</p>
            </div>
            <div className="table-flex-row-2">
              <p>5</p>
              <p>Livestock</p>
            </div>
            <div className="table-flex-row-2">
              <p>6</p>
              <p>Boils</p>
            </div>
            <div className="table-flex-row-2">
              <p>7</p>
              <p>Hail</p>
            </div>
            <div className="table-flex-row-2">
              <p>8</p>
              <p>Locust</p>
            </div>
            <div className="table-flex-row-2">
              <p>9</p>
              <p>Darkness</p>
            </div>
            <div className="table-flex-row-2">
              <p>10</p>
              <p>Death of the Firstborn</p>
            </div>
          </div>
          <p className="body-text">
            Here is a mnemonic to remember the plagues:
            <br />
            <br />
            <span className="mnemonic">
              What Funny Granny Fries Livers But Hates Livers Done Dryly
            </span>
            <br />
            <br />
            The 'Gnats' translation in the 3rd plague is ambiguous and could be
            Lice or Fleas. <br />
            Also, Flies in the 4th plague could be wild animals, hornets or
            mosquitoes
          </p>
          <h2 className="category-subtitle">The 10 Commandments (Exodus)</h2>
          <div className="table-flex-4">
            <div className="table-flex-row-commandment">
              <p>1</p>
              <p>You shall have no other gods before Me</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>2</p>
              <p>You shall make no idols</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>3</p>
              <p>You shall not take the name of the Lord your God in vain</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>4</p>
              <p>Keep the Sabbath day holy</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>5</p>
              <p>Honour your father and your mother</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>6</p>
              <p>You shall not murder</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>7</p>
              <p>You shall not commit adultery</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>8</p>
              <p>You shall not steal</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>9</p>
              <p>You shall not bear false witness against your neighbour</p>
            </div>
            <div className="table-flex-row-commandment">
              <p>10</p>
              <p>You shall not covet your neighbour's wife or house</p>
            </div>
          </div>
          <p className="body-text">
            This is an effort to simplify the Ten Commandments from
            <a
              className="wikipedia-link"
              href="https://en.wikipedia.org/wiki/Ten_Commandments"
              target="_blank"
            >
              this wikipedia table
            </a>
            . <br />
            As you can see there is some ambiguity based on which Bible is used.
            Questions on ordering would be a bit mean in a quiz for this reason.
            You may, however, get asked to list all 10 of them.
          </p>
          <p className="body-text">
            If anyone finds a good way of remembering these, please let me know
            through the 'contact' page.
          </p>
          <h2 className="category-subtitle">
            5 Oldest people in the Bible (they lived a long time!)
          </h2>
          <div className="table-flex-5">
            <div className="table-flex-row-4">
              <p>#</p>
              <p>Name</p>
              <p>Age</p>
            </div>
            <div className="table-flex-row-4">
              <p>1</p>
              <p>Methuselah</p>
              <p>969</p>
            </div>
            <div className="table-flex-row-4">
              <p>2</p>
              <p>Jared</p>
              <p>962</p>
            </div>
            <div className="table-flex-row-4">
              <p>3</p>
              <p>Noah</p>
              <p>950</p>
            </div>
            <div className="table-flex-row-4">
              <p>4</p>
              <p>Adam</p>
              <p>930</p>
            </div>
            <div className="table-flex-row-4">
              <p>5</p>
              <p>Seth</p>
              <p>912</p>
            </div>
          </div>

          <p className="body-text">
            There is a tree in California that is nearly 5000 years old named
            the Methuselah Tree, it is the oldest known living tree. The
            Prometheus tree, which was the oldest verified tree, was cut down in
            1964. They are both Great Basin bristlecone pine trees.
          </p>
        </div>

        <LearnSpecificCategoryBottom
          importantFacts={theBible.importantFacts}
          classicQuestions={theBible.classicQuestions}
          exampleQuestions={theBible.exampleQuestions}
          thoughtsTipsIdeas={theBible.thoughtsTipsAndIdeas}
          smallPeep1={theBible.smallPeep1}
          smallPeep2={theBible.smallPeep2}
          disclaimer={theBible.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnTheBible;
