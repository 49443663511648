import "./css/us-states.css";

function Attributions(props) {
  return (
    // <div className="us-state-card-2">
    <div className="attribution">
      {props.ccArtist !== null && (
        <div>
          <p className="attribution-heading">{props.imageAtt}</p>
          {props.ccFileName && <p>File: {props.ccFileName}</p>}
          <p>Attribution: {props.ccArtist}</p>
          <a className="attribution-link" href={props.ccLink} target="_blank">
            {props.ccNum}
          </a>
        </div>
      )}
    </div>
  );
}

export default Attributions;
