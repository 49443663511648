import React from "react";
import "../css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function FooterLearning() {
  const peepsChoices = [
    require("../images/peep-small1.png"),
    require("../images/peep-small2.png"),
    require("../images/peep-small3.png"),
    require("../images/peep-small4.png"),
    require("../images/peep-small5.png"),
    require("../images/peep-small6.png"),
    require("../images/peep-small7.png"),
    require("../images/peep-small8.png"),
    require("../images/peep-small9.png"),
    require("../images/peep-small10.png"),
    require("../images/peep-small11.png"),
    require("../images/peep-small12.png"),
    require("../images/peep-small13.png"),
    require("../images/peep-small14.png"),
    require("../images/peep-small15.png"),
    require("../images/peep-small16.png"),
    require("../images/peep-small17.png"),
    require("../images/peep-small18.png"),
    require("../images/peep-small19.png"),
    require("../images/peep-small10.png"),
    require("../images/peep-small21.png"),
    require("../images/peep-small22.png"),
    require("../images/peep-small23.png"),
    require("../images/peep-small24.png"),
    require("../images/peep-small25.png"),
    require("../images/peep-small26.png"),
    require("../images/peep-small27.png"),
    require("../images/peep-small28.png"),
    require("../images/peep-small29.png"),
    require("../images/peep-small30.png"),
  ];
  return (
    <footer className="footer-wrapper">
      <div className="footer">
        Waddauno is on the following social media
        <div className="footer-social-wrapper">
          <FontAwesomeIcon
            size="2x"
            className="footer-social"
            icon={faFacebook}
          />
          <FontAwesomeIcon
            size="2x"
            className="footer-social"
            icon={faTwitter}
          />
          <FontAwesomeIcon
            size="2x"
            className="footer-social"
            icon={faInstagram}
          />
          <a
            href="https://www.youtube.com/channel/UChqhBqUeYpJRs2TWMSsiERg"
            target="_blank"
          >
            <FontAwesomeIcon
              size="2x"
              className="footer-social"
              icon={faYoutube}
            />
          </a>

          <img
            src={peepsChoices[Math.floor(Math.random() * peepsChoices.length)]}
            alt="cartoon character woman standing"
            className="peep-footer"
          />
        </div>
      </div>
      <div className="footer-banner">WADDAUNO - What do you know?</div>
    </footer>
  );
}

export default FooterLearning;
