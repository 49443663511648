import WrappedWordsearch from "./WrappedWordsearch";
import HeaderApp from "../HeaderApp";
import "../../css/wordsearch.css";
import "../../css/style.css";
import Footer from "../Footer";

function Wordsearch() {
  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section>
        <WrappedWordsearch />
      </section>
      <Footer />
    </div>
  );
}

export default Wordsearch;
