import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
// import { americanPresidents } from "../LearnSpecificCategoryDetails";
import { americanPresidents } from "../../LearnSpecificCategoryDetails";
import { historyAndCivilisation } from "../../topFivesInfo";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive key={topFive.key} col2={topFive.col2} col1={topFive.col1} />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}

function LearnAmericanPresidents() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={americanPresidents.mainCategory}
          subCategory={americanPresidents.subCategory}
          iconLrg={americanPresidents.iconLrg}
          iconSml={americanPresidents.iconSml}
          bigPeep={americanPresidents.bigPeep}
          intro={americanPresidents.intro}
        />

        <div className="top5s-container">
          <div className="top5TwoCols">
            <h2>First 5 Presidents</h2>
            <div className="sidebar-line"></div>
            {historyAndCivilisation.americanPolitics.firstFivePresidentsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {historyAndCivilisation.americanPolitics.firstFivePresidents.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Top 5 Shortest Serving Presidents</h2>
            <div className="sidebar-line"></div>
            {historyAndCivilisation.americanPolitics.shortestServingPresidentsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {historyAndCivilisation.americanPolitics.shortestServingPresidents.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 Presidents</h2>
            <div className="sidebar-line"></div>
            {historyAndCivilisation.americanPolitics.lastFivePresidentsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {historyAndCivilisation.americanPolitics.lastFivePresidents.map(
              ArrangeTopFive
            )}
          </div>
        </div>
        <div className="table-container">
          <table className="table president-table">
            <thead>
              <tr>
                <th>Number</th>
                <th>Name</th>
                <th>Term Start</th>
                <th className="president-table-notes">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Number">1</td>
                <td data-label="Name">George Washington</td>
                <td data-label="Term Start">1789</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">2</td>
                <td data-label="Name">John Adams</td>
                <td data-label="Term Start">1797</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">3</td>
                <td data-label="Name">Thomas Jefferson</td>
                <td data-label="Term Start">1801</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">4</td>
                <td data-label="Name">James Madison</td>
                <td data-label="Term Start">1809</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">5</td>
                <td data-label="Name">James Monroe</td>
                <td data-label="Term Start">1817</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">6</td>
                <td data-label="Name">John Quincy Adams</td>
                <td data-label="Term Start">1825</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">7</td>
                <td data-label="Name">Andrew Jackson</td>
                <td data-label="Term Start">1829</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">8</td>
                <td data-label="Name">Martin Van Buren</td>
                <td data-label="Term Start">1837</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">9</td>
                <td data-label="Name">William Henry Harrison</td>
                <td data-label="Term Start">1841</td>
                <td data-label="Notes">
                  Shortest Presidency at 31 days. Died in office
                </td>
              </tr>
              <tr>
                <td data-label="Number">10</td>
                <td data-label="Name">John Tyler</td>
                <td data-label="Term Start">1841</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">11</td>
                <td data-label="Name">James K. Polk</td>
                <td data-label="Term Start">1845</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">12</td>
                <td data-label="Name">Zachary Taylor</td>
                <td data-label="Term Start">1849</td>
                <td data-label="Notes">Died in office in 1850</td>
              </tr>
              <tr>
                <td data-label="Number">13</td>
                <td data-label="Name">Millard Fillmore</td>
                <td data-label="Term Start">1850</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">14</td>
                <td data-label="Name">Franklin Pierce</td>
                <td data-label="Term Start">1853</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">15</td>
                <td data-label="Name">James Buchanan</td>
                <td data-label="Term Start">1857</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">16</td>
                <td data-label="Name">Abraham Lincoln</td>
                <td data-label="Term Start">1861</td>
                <td data-label="Notes">
                  Assassinated 1865 by John Wilkes Booth
                </td>
              </tr>
              <tr>
                <td data-label="Number">17</td>
                <td data-label="Name">Andrew Johnson</td>
                <td data-label="Term Start">1865</td>
                <td data-label="Notes">First president impeached in 1868</td>
              </tr>
              <tr>
                <td data-label="Number">18</td>
                <td data-label="Name">Ulysses S. Grant</td>
                <td data-label="Term Start">1869</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">19</td>
                <td data-label="Name">Rutherford B. Hayes</td>
                <td data-label="Term Start">1877</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">20</td>
                <td data-label="Name">James A. Garfield</td>
                <td data-label="Term Start">1881</td>
                <td data-label="Notes">
                  Assassinated 1881 by Charles J. Guiteau
                </td>
              </tr>
              <tr>
                <td data-label="Number">21</td>
                <td data-label="Name">Chester A. Arthur</td>
                <td data-label="Term Start">1881</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">22</td>
                <td data-label="Name">Grover Cleveland</td>
                <td>1885</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">23</td>
                <td data-label="Name">Benjamin Harrison</td>
                <td data-label="Term Start">1889</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">24</td>
                <td data-label="Name">Grover Cleveland</td>
                <td data-label="Term Start">1893</td>
                <td data-label="Notes">
                  The only president to serve 2 non-consecutive terms (22nd and
                  24th president)
                </td>
              </tr>
              <tr>
                <td data-label="Number">25</td>
                <td data-label="Name">William McKinley</td>
                <td data-label="Term Start">1897</td>
                <td data-label="Notes">Assassinated 1901 by Leon Czolgosz</td>
              </tr>
              <tr>
                <td data-label="Number">26</td>
                <td data-label="Name">Theodore Roosevelt</td>
                <td data-label="Term Start">1901</td>
                <td data-label="Notes">
                  Youngest President (age 42). Not elected - succeeded office
                  after William Mckinley's assassination
                </td>
              </tr>
              <tr>
                <td data-label="Number">27</td>
                <td data-label="Name">William Howard Taft</td>
                <td data-label="Term Start">1909</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">28</td>
                <td data-label="Name">Woodrow Wilson</td>
                <td data-label="Term Start">1913</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">29</td>
                <td data-label="Name">Warren G Harding</td>
                <td data-label="Term Start">1921</td>
                <td data-label="Notes">Died in office 1923</td>
              </tr>
              <tr>
                <td data-label="Number">30</td>
                <td data-label="Name">Calvin Coolidge</td>
                <td data-label="Term Start">1923</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">31</td>
                <td data-label="Name">Herbert Hoover</td>
                <td data-label="Term Start">1929</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">32</td>
                <td data-label="Name">Franklin D. Roosevelt</td>
                <td data-label="Term Start">1933</td>
                <td data-label="Notes">
                  Died in office shortly before the end of WW2 in 1945. The
                  longest serving president (12 years)
                </td>
              </tr>
              <tr>
                <td data-label="Number">33</td>
                <td data-label="Name">Harry S. Truman</td>
                <td data-label="Term Start">1945</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">34</td>
                <td data-label="Name">Dwight D. Eisenhower</td>
                <td data-label="Term Start">1953</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">35</td>
                <td data-label="Name">John F. Kennedy</td>
                <td data-label="Term Start">1961</td>
                <td data-label="Notes">
                  Assassinated 1963 by Lee Harvey Oswald. Youngest ELECTED
                  President (age 43)
                </td>
              </tr>
              <tr>
                <td data-label="Number">36</td>
                <td data-label="Name">Lyndon B. Johnson</td>
                <td data-label="Term Start">1963</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">37</td>
                <td data-label="Name">Richard Nixon</td>
                <td data-label="Term Start">1969</td>
                <td data-label="Notes">
                  The first (and of 2022 only) president to resign from office.
                  Nixon resigned following the Watergate scandal (1974)
                </td>
              </tr>
              <tr>
                <td data-label="Number">38</td>
                <td data-label="Name">Gerald Ford</td>
                <td data-label="Term Start">1974</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">39</td>
                <td data-label="Name">Jimmy Carter</td>
                <td data-label="Term Start">1977</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">40</td>
                <td data-label="Name">Ronald Reagan</td>
                <td data-label="Term Start">1981</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">41</td>
                <td data-label="Name">George Bush</td>
                <td data-label="Term Start">1989</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">42</td>
                <td data-label="Name">Bill Clinton</td>
                <td data-label="Term Start">1993</td>
                <td data-label="Notes">
                  Second president ever to be impeached, Clinton was impeached
                  in 1998
                </td>
              </tr>
              <tr>
                <td data-label="Number">43</td>
                <td data-label="Name">George W. Bush</td>
                <td data-label="Term Start">2001</td>
                <td data-label="Notes"></td>
              </tr>
              <tr>
                <td data-label="Number">44</td>
                <td data-label="Name">Barack Obama</td>
                <td data-label="Term Start">2009</td>
                <td data-label="Notes">First black president</td>
              </tr>
              <tr>
                <td data-label="Number">45</td>
                <td data-label="Name">Donald Trump</td>
                <td data-label="Term Start">2017</td>
                <td data-label="Notes">Impeached twice</td>
              </tr>
              <tr>
                <td data-label="Number">46</td>
                <td data-label="Name">Joe Biden</td>
                <td data-label="Term Start">2021</td>

                <td data-label="Notes"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={americanPresidents.importantFacts}
          classicQuestions={americanPresidents.classicQuestions}
          exampleQuestions={americanPresidents.exampleQuestions}
          thoughtsTipsIdeas={americanPresidents.thoughtsTipsAndIdeas}
          // smallPeep1={americanPresidents.smallPeep1}
          smallPeep2={americanPresidents.smallPeep2}
          disclaimer={americanPresidents.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnAmericanPresidents;
