import React from "react";
import "../css/flag.css";

function Capital(props) {
  return (
    <section className="flags">
      <h5 className="times-wrong-text">
        {props.numMistakes > 0 && `Times wrong: ${props.numMistakes}`}
      </h5>
      <section>
        <h4 className="flags-country">{props.country}</h4>
        <h4 className="flags-country">{props.capital}</h4>
      </section>

      {/* <h2>{props.country}</h2> */}
      <div className="flag">{props.flag}</div>
    </section>
  );
}

export default Capital;
