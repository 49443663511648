import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/not-tables.css";

function LearnAnnversaries() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Traditional Anniversary Gifts</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                A couples' wedding anniversary is traditionally marked with
                certain gifts at certain milestones, beginning annually in the
                early years of marriage and commonly stretching to every five
                years following the 15th anniversary year.
              </p>
              <p className="body-text">
                The traditional gifts listed below are those given in the UK,
                the US often has a different traditional gift - especially in
                the earlier years. There is also a 'modern gift' equivalent, but
                those are unlikely to be used for quiz questions so have not
                been included.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small31.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">UK Anniversary year & gift</h2>
          <div className="table-flex-7">
            <div className="table-flex-row-5">
              <p>1</p>
              <p>Paper</p>
            </div>
            <div className="table-flex-row-5">
              <p>2</p>
              <p>Cotton</p>
            </div>
            <div className="table-flex-row-5">
              <p>3</p>
              <p>Leather</p>
            </div>
            <div className="table-flex-row-5">
              <p>4</p>
              <p>Linen</p>
            </div>
            <div className="table-flex-row-5">
              <p>5</p>
              <p>Wood</p>
            </div>
            <div className="table-flex-row-5">
              <p>6</p>
              <p>Sugar</p>
            </div>
            <div className="table-flex-row-5">
              <p>7</p>
              <p>Wool</p>
            </div>
            <div className="table-flex-row-5">
              <p>8</p>
              <p>Salt</p>
            </div>
            <div className="table-flex-row-5">
              <p>9</p>
              <p>Copper</p>
            </div>
            <div className="table-flex-row-5">
              <p>10</p>
              <p>Tin</p>
            </div>
            <div className="table-flex-row-5">
              <p>11</p>
              <p>Steel</p>
            </div>
            <div className="table-flex-row-5">
              <p>12</p>
              <p>Silk</p>
            </div>
            <div className="table-flex-row-5">
              <p>13</p>
              <p>Lace</p>
            </div>
            <div className="table-flex-row-5">
              <p>14</p>
              <p>Ivory</p>
            </div>
            <div className="table-flex-row-5">
              <p>15</p>
              <p>Crystal</p>
            </div>
            <div className="table-flex-row-5">
              <p>20</p>
              <p>China</p>
            </div>
            <div className="table-flex-row-5">
              <p>25</p>
              <p>Silver</p>
            </div>
            <div className="table-flex-row-5">
              <p>30</p>
              <p>Pearl</p>
            </div>
            <div className="table-flex-row-5">
              <p>35</p>
              <p>Coral</p>
            </div>
            <div className="table-flex-row-5">
              <p>40</p>
              <p>Ruby</p>
            </div>
            <div className="table-flex-row-5">
              <p>45</p>
              <p>Sapphire</p>
            </div>
            <div className="table-flex-row-5">
              <p>50</p>
              <p>Gold</p>
            </div>
            <div className="table-flex-row-5">
              <p>55</p>
              <p>Emerald</p>
            </div>
            <div className="table-flex-row-5">
              <p>60</p>
              <p>Diamond</p>
            </div>
            <div className="table-flex-row-5">
              <p>65</p>
              <p>Blue Sapphire</p>
            </div>
            <div className="table-flex-row-5">
              <p>70</p>
              <p>Platinum</p>
            </div>
            <div className="table-flex-row-5">
              <p>75</p>
              <p>Diamond & Gold</p>
            </div>
            <div className="table-flex-row-5">
              <p>80</p>
              <p>Oak</p>
            </div>
            <div className="table-flex-row-5">
              <p>85</p>
              <p>Wine</p>
            </div>
            <div className="table-flex-row-5">
              <p>90</p>
              <p>Granite</p>
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnAnnversaries;

// crystal 15
// china/porcelain 20
// Silver 25
// PEARL 30
// coral 35
// Ruby 40
// sapphire 45
// Gold 50
// emerald 55
// diamond 60
// blur sapphire 65
// Platinum 70
// diamond,gold 75
// oak 80
// moonstone / wine 85
// granite 90
