import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Alterego from "./Alterego";
import { alterEgos } from "../../LearnSpecificCategoryDetailsTwo";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeAlterEgos(ego) {
  return <Alterego key={ego.id} original={ego.original} new={ego.new} />;
}

function LearnAlterEgos() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Alter Egos</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Famous singers/bands/actors etc names (either birth name or the
                original name of their act) and what name they are called now or
                at the height of their fame. <br />
                The original name is written in white and its complimentary
                alter ego is written in orange.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small11.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Band Names</h2>
          <div className="alter-ego-list-container">
            {alterEgos.originalBandNames.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Music Artists</h2>
          <div className="alter-ego-list-container">
            {alterEgos.musicalArtists.map(ArrangeAlterEgos)}
            <div className="line-subtitle"></div>
          </div>
          <h2 className="category-subtitle centre">Actors & Actresses</h2>
          <div className="alter-ego-list-container">
            {alterEgos.actorsActresses.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Authors</h2>
          <div className="alter-ego-list-container">
            {alterEgos.authors.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Superheroes & Villains</h2>
          <div className="alter-ego-list-container">
            {alterEgos.superheroesVillains.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Comedians</h2>
          <div className="alter-ego-list-container">
            {alterEgos.comedians.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Other</h2>
          <div className="alter-ego-list-container">
            {alterEgos.other.map(ArrangeAlterEgos)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnAlterEgos;
