import React, { useState, useRef, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import AsideNav from "../AsideNav";
import {
  englishCounties,
  englishCountiesAttributionsArms,
  englishCountiesAttributionsFlags,
} from "../../LearnSpecificCategoryDetailsFive";
import "../../css/us-states-map.css";
import "../../css/english-counties.css";
import EnglishCounty from "../../EnglishCounty";
import Attributions from "../../Attributions";

function arrangeEnglishCounties(eC) {
  return (
    <EnglishCounty
      key={eC.id}
      countyName={eC.countyName}
      countyTown={eC.countyTown}
      largestTown={eC.largestTown}
      countyFlag={eC.countyFlag}
      countyArms={eC.countyArms}
    />
  );
}

function arrangeAttributions(ec) {
  return (
    <Attributions
      key={ec.id}
      countyName={ec.countyName}
      imageAtt={ec.imageAtt}
      ccLink={ec.ccLink}
      ccArtist={ec.ccArtist}
      ccNum={ec.ccNum}
      ccFileName={ec.ccFileName}
    />
  );
}

function LearnEnglishCounties() {
  const [countyNames, setCountyNames] = useState([
    "bedfordshire",
    "berkshire",
    "bristol",
    "buckinghamshire",
    "cambridgeshire",
    "cheshire",
    "cornwall",
    "cumbria",
    "derbyshire",
    "devon",
    "dorset",
    "durham",
    "east riding of yorkshire",
    "east sussex",
    "essex",
    "gloucestershire",
    "greater london",
    "greater manchester",
    "hampshire",
    "herefordshire",
    "hertfordshire",
    "isle of wight",
    "kent",
    "lancashire",
    "leicestershire",
    "lincolnshire",
    "merseyside",
    "norfolk",
    "north yorkshire",
    "northamptonshire",
    "northumberland",
    "nottinghamshire",
    "oxfordshire",
    "rutland",
    "shropshire",
    "somerset",
    "south yorkshire",
    "staffordshire",
    "suffolk",
    "surrey",
    "tyne and wear",
    "warwickshire",
    "west midlands",
    "west sussex",
    "west yorkshire",
    "wiltshire",
    "worcestershire",
  ]);
  const [answers, setAnswers] = useState([]);
  const [correctGuesses, setCorrectGuesses] = useState([]);
  const [missed, setMissed] = [];
  const [answer, setAnswer] = useState("");
  const [count, setCount] = useState(0);
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [giveUp, setGiveUp] = useState(false);
  const inputRef = useRef();
  const correctAnswerMapRef = useRef();

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn]);

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      correctAnswerMapRef.current.classList.remove("correct-answer-map");
    }, 1500);
  }, [count]);

  useEffect(() => {
    if (!correctGuesses.includes("Bedfordshire")) {
      if (answer.toLowerCase() === "beds") {
        setAnswer("bedfordshire");
      }
    }
    if (!correctGuesses.includes("Berkshire")) {
      if (answer.toLowerCase() === "berks") {
        setAnswer("berkshire");
      }
    }
    if (!correctGuesses.includes("Buckinghamshire")) {
      if (answer.toLowerCase() === "bucks") {
        setAnswer("buckinghamshire");
      }
    }
    if (!correctGuesses.includes("Cambridgeshire")) {
      if (answer.toLowerCase() === "cambs") {
        setAnswer("cambridgeshire");
      }
    }
    if (!correctGuesses.includes("Cheshire")) {
      if (answer.toLowerCase() === "ches") {
        setAnswer("Cheshire");
      }
    }
    if (!correctGuesses.includes("Cheshire")) {
      if (answer.toLowerCase() === "ches") {
        setAnswer("Cheshire");
      }
    }
    if (!correctGuesses.includes("Derbyshire")) {
      if (answer.toLowerCase() === "derbys") {
        setAnswer("derbyshire");
      }
    }
    if (!correctGuesses.includes("East Riding of Yorkshire")) {
      if (answer.toLowerCase() === "east riding") {
        setAnswer("east riding of yorkshire");
      }
    }
    if (!correctGuesses.includes("Gloucestershire")) {
      if (answer.toLowerCase() === "gloucs") {
        setAnswer("gloucestershire");
      }
    }
    if (!correctGuesses.includes("Hampshire")) {
      if (answer.toLowerCase() === "hants") {
        setAnswer("hampshire");
      }
    }
    if (!correctGuesses.includes("Herefordshire")) {
      if (
        answer.toLowerCase() === "herefords" ||
        answer.toLowerCase() === "heref"
      ) {
        setAnswer("herefordshire");
      }
    }
    if (!correctGuesses.includes("Hertfordshire")) {
      if (answer.toLowerCase() === "herts") {
        setAnswer("hertfordshire");
      }
    }
    if (!correctGuesses.includes("Lancashire")) {
      if (answer.toLowerCase() === "lancs") {
        setAnswer("lancashire");
      }
    }
    if (!correctGuesses.includes("Leicestershire")) {
      if (answer.toLowerCase() === "leics") {
        setAnswer("leicestershire");
      }
    }
    if (!correctGuesses.includes("Lincolnshire")) {
      if (answer.toLowerCase() === "lincs") {
        setAnswer("lincolnshire");
      }
    }
    if (!correctGuesses.includes("North Yorkshire")) {
      if (answer.toLowerCase() === "north yorks") {
        setAnswer("north yorkshire");
      }
    }
    if (!correctGuesses.includes("Northamptonshire")) {
      if (answer.toLowerCase() === "northants") {
        setAnswer("northamptonshire");
      }
    }
    if (!correctGuesses.includes("Nottinghamshire")) {
      if (answer.toLowerCase() === "notts") {
        setAnswer("nottinghamshire");
      }
    }
    if (!correctGuesses.includes("Oxfordshire")) {
      if (
        answer.toLowerCase() === "oxfords" ||
        answer.toLowerCase() === "oxon"
      ) {
        setAnswer("oxfordshire");
      }
    }
    if (!correctGuesses.includes("Shropshire")) {
      if (
        answer.toLowerCase() === "shrops" ||
        answer.toLowerCase() === "salop"
      ) {
        setAnswer("shropshire");
      }
    }
    if (!correctGuesses.includes("South Yorkshire")) {
      if (answer.toLowerCase() === "south yorks") {
        setAnswer("south yorkshire");
      }
    }
    if (!correctGuesses.includes("Staffordshire")) {
      if (answer.toLowerCase() === "staffs") {
        setAnswer("staffordshire");
      }
    }
    if (!correctGuesses.includes("Tyne and Wear")) {
      if (answer.toLowerCase() === "tyne & wear") {
        setAnswer("tyne and wear");
      }
    }
    if (!correctGuesses.includes("Warwickshire")) {
      if (answer.toLowerCase() === "warks") {
        setAnswer("warwickshire");
      }
    }
    if (!correctGuesses.includes("West Midlands")) {
      if (answer.toLowerCase() === "west mids") {
        setAnswer("west midlands");
      }
    }
    if (!correctGuesses.includes("West Yorkshire")) {
      if (answer.toLowerCase() === "west yorks") {
        setAnswer("west yorkshire");
      }
    }
    if (!correctGuesses.includes("Wiltshire")) {
      if (answer.toLowerCase() === "wilts") {
        setAnswer("wiltshire");
      }
    }
    if (!correctGuesses.includes("Worcestershire")) {
      if (answer.toLowerCase() === "worcs") {
        setAnswer("worcestershire");
      }
    }
  }, [answer]);

  function focus() {
    inputRef.current && inputRef.current.focus();
  }

  function handleChange(e) {
    if (!giveUp) {
      setAnswer(e.target.value.trim());
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    inputRef.current.value = "";
    if (!giveUp) {
      checkAnswer();
      focus();
    }
  }

  function handleKeyDown(e) {
    if (e.code === "Enter" && !giveUp) {
      handleSubmit(e);
    }
  }

  function giveUpAndRevealAnswers() {
    setGiveUp(true);
    inputRef.current.disabled = true;
    for (let x = 0; x < countyNames.length; x++) {
      if (
        !answers.includes(
          countyNames[x].toLocaleLowerCase().split(" ").join("-")
        )
      ) {
        setTimerOn(false);
        setAnswers((current) => [
          ...current,
          countyNames[x].toLocaleLowerCase().split(" ").join("-"),
        ]);
      }
    }
  }

  function playAgain() {
    inputRef.current.disabled = false;
    inputRef.current.value = "";
    setAnswers([]);
    setCount(0);
    setGiveUp(false);
    setTime(0);
    setTimerOn(false);
    setCorrectGuesses([]);
    focus();
  }

  function checkAnswer() {
    let answerLower = answer.toLowerCase().split(" ");
    for (let x = 0; x < answerLower.length; x++) {
      answerLower[x] =
        answerLower[x].charAt(0).toUpperCase() + answerLower[x].substring(1);
    }
    let answerCapitalized = answerLower.join(" ");
    if (
      countyNames.includes(answer.toLowerCase()) &&
      !answers.includes(answer.toLowerCase().split(" ").join("-")) &&
      !correctGuesses.includes(answerCapitalized)
    ) {
      setAnswers((current) => [
        ...current,
        answer.toLowerCase().split(" ").join("-"),
      ]);
      correctAnswerMapRef.current.classList.add("correct-answer-map");
      if (!correctGuesses.includes(answerCapitalized)) {
        setCount((count) => count + 1);
      }
      if (!correctGuesses.includes(answerCapitalized)) {
        setCorrectGuesses((current) => [...current, answerCapitalized]);
      }
    }
    if (!timerOn) {
      setTimerOn(true);
    }
    if (count >= 46) {
      setTimerOn(false);
    }
  }

  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <div className="us-map-game-flex">
          <div className="your-category us-states-count">Correct: {count}</div>
          <div className="us-states-timer">
            <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          </div>
          <form className="us-states-form" onKeyDown={handleKeyDown}>
            <input
              type="text"
              ref={inputRef}
              className="us-states-input"
              onChange={handleChange}
            />
            <button onClick={handleSubmit} className="us-states-btn">
              Submit
            </button>
          </form>
        </div>
        <div className="english-counties-game-container">
          <div ref={correctAnswerMapRef} className="div-hidden">
            {correctGuesses[count - 1]}
          </div>
          <img
            className="english-counties-map"
            src={require("../../images/map/mapOfUK.jpg")}
          />
          {answers &&
            answers.map((a) => (
              <img
                key={a}
                className={`english-county-${a}`}
                src={require(`../../images/map/${a}.png`)}
              />
            ))}
        </div>
        <div className="small-reference-txt centre-text">
          Image adapted from https://www.visitnorthwest.com/counties/
        </div>
        <div className="container">
          <div className="us-states-buttons-container">
            <button
              className="us-states-give-up-btn"
              onClick={giveUpAndRevealAnswers}
            >
              Give Up
            </button>
            <button className="us-states-play-again-btn" onClick={playAgain}>
              Play Again
            </button>
          </div>
        </div>
      </section>
      {answers.length >= 47 || giveUp ? (
        <section className="us-states-results">
          <div className="count-result-container">
            <span>YOU GOT</span>
            <span className="count-result">
              {count !== 47 ? count : "ALL 47"}
            </span>
            <span>COUNTIES IN</span>
          </div>
          <div className="us-states-timer">
            <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          </div>
        </section>
      ) : (
        ""
      )}
      {correctGuesses.length ? <div className="line-even-margin"></div> : ""}
      {correctGuesses.length ? (
        <>
          <p className="state-cards-missed-correct">Correct:</p>
          <div className="state-cards-container">
            {englishCounties
              .filter((x) => correctGuesses.includes(x.countyName))
              .sort()
              .map(arrangeEnglishCounties)}
          </div>
          {!giveUp ? (
            <div className="attributions-outer-container">
              <p className="container-third-subtitle">IMAGE ATTRIBUTIONS:</p>
              <div className="attributions-container">
                {englishCountiesAttributionsFlags
                  .filter((x) => correctGuesses.includes(x.countyName))
                  .sort()
                  .map(arrangeAttributions)}
                {englishCountiesAttributionsArms
                  .filter((x) => correctGuesses.includes(x.countyName))
                  .sort()
                  .map(arrangeAttributions)}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {giveUp && <div className="line-even-margin"></div>}
      {giveUp && (
        <>
          <p className="state-cards-missed-correct">Missed:</p>
          <div className="state-cards-container">
            {englishCounties
              .filter((x) => !correctGuesses.includes(x.countyName))
              .sort()
              .map(arrangeEnglishCounties)}
          </div>
        </>
      )}
      {giveUp && (
        <>
          <div className="attributions-outer-container">
            <p className="container-third-subtitle">IMAGE ATTRIBUTIONS:</p>
            <div className="attributions-container">
              {englishCountiesAttributionsFlags.map(arrangeAttributions)}
              {englishCountiesAttributionsArms.map(arrangeAttributions)}
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default LearnEnglishCounties;
