import React from "react";
import "../../css/top5.css";

function LearnTopFiveHeadingFourCols(props) {
  return (
    <div className="top5flex top5heading">
      <h2 className="top5-four-cols">{props.col1}</h2>
      <h2 className="top5-four-cols">{props.col2}</h2>
      <h2 className="top5-four-cols">{props.col3}</h2>
      <h2 className="top5-four-cols">{props.col4}</h2>
    </div>
  );
}
export default LearnTopFiveHeadingFourCols;
