import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/polygons.css";

function LearnPolygons() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Regular Polygons</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The word polygon comes from the Greek word polygonum, poly
                meaning 'many' and gonum meaning 'angles'. The naming
                conventions for these shapes also (in general) have Greek
                prefixes - after 99 the names change a little from the Greek.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small31.png")}
              alt="cartoon character with black vest"
            />
          </div>
          <p className="body-text">
            A question about polygons is an easy one for a quiz master to add to
            a general knowledge round, so you will find them from time to time
            in quizzes. <br />
            If you are asked how many sides you would find on a hendecagon, for
            example, you can use what you learn below and say that it has 11
            sides.
          </p>
          <p className="body-text">
            It is acceptable to write polygons over 12 in a more simple form eg
            '16-gon' rather than 'Hexakaidecagon', which is harder to remember
            and less likely to be known by others. However, the full names, or
            how to combine and form names for polygons based on number of sides
            at will, can be found below. <br />
            After all, a quiz master who asks the name for a 30 sided shape is
            going to want the answer 'triacontagon', you're not getting points
            for writing '30-gon'!
          </p>
          <div className="line-subtitle"></div>
          <div className="polygon-flex-container">
            <div className="polygon-flex polygon-flex-titles">
              <p className="polygon-sides-num">Num of sides</p>
              <p className="polygon-name">Name</p>
              <p className="polygon-name-alt">Alternative Name</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">1</p>
              <p className="polygon-name">henagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">2</p>
              <p className="polygon-name">digon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">3</p>
              <p className="polygon-name">trigon</p>
              <p className="polygon-name-alt">triangle</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">4</p>
              <p className="polygon-name">tetragon</p>
              <p className="polygon-name-alt">quadrilateral</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">5</p>
              <p className="polygon-name">pentagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">6</p>
              <p className="polygon-name">hexagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">7</p>
              <p className="polygon-name">heptagon</p>
              <p className="polygon-name-alt">septagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">8</p>
              <p className="polygon-name">octagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">9</p>
              <p className="polygon-name">enneagon</p>
              <p className="polygon-name-alt">nonagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">10</p>
              <p className="polygon-name">decagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">11</p>
              <p className="polygon-name">hendecagon</p>
              <p className="polygon-name-alt">undecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">12</p>
              <p className="polygon-name">dodecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">13</p>
              <p className="polygon-name">tridecagon</p>
              <p className="polygon-name-alt">triskaidecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">14</p>
              <p className="polygon-name">tetradecagon</p>
              <p className="polygon-name-alt">tetrakaidecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">15</p>
              <p className="polygon-name">pentadecagon</p>
              <p className="polygon-name-alt">pentakaidecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">16</p>
              <p className="polygon-name">hexadecagon</p>
              <p className="polygon-name-alt">hexakaidecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">17</p>
              <p className="polygon-name">heptadecagon</p>
              <p className="polygon-name-alt">heptakaidecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">18</p>
              <p className="polygon-name">octadecagon</p>
              <p className="polygon-name-alt">octakaidecagon</p>
            </div>
            <div className="polygon-flex">
              <p className="polygon-sides-num">19</p>
              <p className="polygon-name">enneadecagon</p>
              <p className="polygon-name-alt">enneakaidecagon</p>
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="polygon-flex-container">
            <div className="polygon-flex-2-cols polygon-flex-titles">
              <p className="polygon-sides-num">Number of Sides</p>
              <p className="polygon-name">Name</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">20</p>
              <p className="polygon-name">icosagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">30</p>
              <p className="polygon-name">triacontagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">40</p>
              <p className="polygon-name">tetracontagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">50</p>
              <p className="polygon-name">pentacontagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">60</p>
              <p className="polygon-name">hexacontagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">70</p>
              <p className="polygon-name">heptacontagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">80</p>
              <p className="polygon-name">octacontagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">90</p>
              <p className="polygon-name">enneacontagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">100</p>
              <p className="polygon-name">hectagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">1,000</p>
              <p className="polygon-name">chiliagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">10,000</p>
              <p className="polygon-name">myriagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">1,000,000</p>
              <p className="polygon-name">megaagon</p>
            </div>
            <div className="polygon-flex-2-cols">
              <p className="polygon-sides-num">
                10<sup className="superscript">100</sup>
              </p>
              <p className="polygon-name">googolgon</p>
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div>
            <p className="body-text">
              To get the name for a polygon with a specific number of sides from
              20 up to 99, this method (below) can help:
            </p>

            <div className="make-polygons-flex make-polygons-titles">
              <p className="make-polygons-title">TENS</p>
              <p className="make-polygons-title">BEGINNING</p>
              <p className="make-polygons-title">UNITS</p>
              <p className="make-polygons-title">ENDING</p>
            </div>
            <div className="make-polygons-flex">
              <div className="make-polygons-numbers">
                <p>20</p>
                <p>30</p>
                <p>40</p>
                <p>50</p>
                <p>60</p>
                <p>70</p>
                <p>80</p>
                <p>90</p>
              </div>
              <div>
                <p>Icosi</p>
                <p>Triaconta</p>
                <p>Tetraconta</p>
                <p>Pentaconta</p>
                <p>Hexaconta</p>
                <p>Heptaconta</p>
                <p>Octaconta</p>
                <p>Enneaconta</p>
              </div>
              <div className="make-polygons-numbers">
                <p>+ 1</p>
                <p>+ 2</p>
                <p>+ 3</p>
                <p>+ 4</p>
                <p>+ 5</p>
                <p>+ 6</p>
                <p>+ 7</p>
                <p>+ 8</p>
                <p>+ 9</p>
              </div>
              <div>
                <p>henagon</p>
                <p>digon</p>
                <p>trigon</p>
                <p>tetragon</p>
                <p>pentagon</p>
                <p>hexagon</p>
                <p>heptagon</p>
                <p>ocogon</p>
                <p>enneagon</p>
              </div>
            </div>
            <p className="body-text">
              So to put this into practice, an 84 sided polygon is an
              Octacontatetragon.
            </p>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnPolygons;
