import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import SportingNickname from "./SportingNickname";
import { footballTeamNicknames } from "../../LearnSpecificCategoryDetailsTwo";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeSportingNicknames(sn) {
  return (
    <SportingNickname
      key={sn.id}
      realname={sn.realname}
      nickname={sn.nickname}
    />
  );
}

function LearnFootballTeamNicknames() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        {/* <TitleContainer title={alterEgo.title} image={alterEgo.image} /> */}
        <h1 className="category-title">Football Team Nicknames</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The most commonly used nicknames for the teams in the English Football League (EFL) teams, and some
                teams from The Bundesliga, La Liga, Ligue 1, and Serie A.<br />
                The teams are sorted into the division they were competing in at
                the start of the 2022/23 season.
                <br />
                Their official team name is written in white and their nickname
                is written in orange.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small24.png")}
              alt="cartoon character with black hair and black tshirt"
            />
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">The Premiership</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.premiership.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">The Championship</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.championship.map(ArrangeSportingNicknames)}
            <div className="line-subtitle"></div>
          </div>
          <h2 className="category-subtitle centre">League One</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.leagueOne.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">League Two</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.leagueTwo.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">The Bundesliga - Germany</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.overSeasTeamsGermany.map(
              ArrangeSportingNicknames
            )}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">La Liga - Spain</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.overSeasTeamsSpain.map(
              ArrangeSportingNicknames
            )}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Ligue 1 - France</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.overSeasTeamsFrance.map(
              ArrangeSportingNicknames
            )}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Serie A - Italy</h2>
          <div className="alter-ego-list-container">
            {footballTeamNicknames.overSeasTeamsItaly.map(
              ArrangeSportingNicknames
            )}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnFootballTeamNicknames;
