import React from "react";
import "../css/button.css";

const LocationComponent = ({ onDataReceived }) => {
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        onDataReceived({ latitude, longitude });
      }, showError);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
      default:
        console.log("An unknown error occurred.");
    }
  };

  return (
    <div className="button-flex">
      <button
        className="centered-button"
        id="get-location-btn"
        onClick={getLocation}
      >
        Use My Location
      </button>
    </div>
  );
};

export default LocationComponent;
