import React from "react";
import "../css/flag.css";

function Flag(props) {
  return (
    <div className="flags">
      <h5 className="times-wrong-text">
        {props.numMistakes > 0 && `Times wrong: ${props.numMistakes}`}
      </h5>
      <h4 className="flags-country">{props.country}</h4>

      {/* <h2>{props.country}</h2> */}
      <div className="flag">{props.flag}</div>
    </div>
  );
}

export default Flag;
