import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import LearnTopFiveThreeCols from "./LearnTopFiveThreeCols";
import LearnTopFiveThreeColsHeading from "./LearnTopFiveThreeColsHeading";
import { TVmoviesvideogames } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive
      key={topFive.key}
      col1={topFive.col1}
      col2={topFive.col2}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}
function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col2country={topFive.col2country}
      col3country={topFive.col3country}
      col4={topFive.col4}
    />
  );
}
function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}

function LearnTopTVFilmGames() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Top Films, Video Games & TV</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Highest grossing films, best selling video games and longest
                running TV shows.
              </p>
              <p className="body-text">All correct as of August 2022.</p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small15.png")}
              alt="cartoon character with spiky orange hair and shades"
            />
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Film</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Grossing Films of All Time
              </h2>
              <div className="sidebar-line"></div>
              {TVmoviesvideogames.cinemaTopGrossingHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {TVmoviesvideogames.cinemaTopGrossing.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Video Games</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Selling Video Games of All Time
              </h2>
              <div className="sidebar-line"></div>
              {TVmoviesvideogames.videoGamesTopSellingHeadings.map(
                ArrangeTopFiveHeading
              )}
              {TVmoviesvideogames.videoGamesTopSelling.map(ArrangeTopFive)}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">TV</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Longest Running English Language TV Shows
              </h2>
              <div className="sidebar-line"></div>
              {TVmoviesvideogames.TVlongestRunningHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {TVmoviesvideogames.TVlongestRunning.map(ArrangeTopFiveThreeCols)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 longest running British soaps by number of episodes
              </h2>
              <div className="sidebar-line"></div>
              {TVmoviesvideogames.ukSoapsByNumOfEpsHeading.map(
                ArrangeTopFiveHeading
              )}
              {TVmoviesvideogames.ukSoapsByNumOfEps.map(ArrangeTopFive)}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnTopTVFilmGames;
