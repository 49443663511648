import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";

import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/not-tables.css";

function LearnOccupationalSurnames() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Occupational Surnames</h1>

        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Surnames often have meanings behind them, and some of them
                relate to the job done by the person with that name.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small2.png")}
              alt="cartoon character with black hair"
            />
          </div>

          <div className="line-even-margin"></div>
          <div className="table-flex-4">
            <div className="table-flex-row-3">
              <p className="row-subject">Ackerman</p>
              <p className="row-explanation">
                A farmer who does not own the farm they work on
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Arkwright</p>
              <p className="row-explanation">A chest, box, cabinet maker</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Bailey</p>
              <p className="row-explanation">A steward or official</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Barber</p>
              <p className="row-explanation">
                A person who cuts hair and beards
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Barker</p>
              <p className="row-explanation">
                (Disputed) A tanner / someone who removes valuable bark from
                trees (eg cinnamon) or a person who attempts to attract patrons
                to entertainment events
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Beadle</p>
              <p className="row-explanation">
                A ceremonial official of a church/college
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Boarman</p>
              <p className="row-explanation">A Bowmaker</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Bond</p>
              <p className="row-explanation">A peasant farmer or husbandman</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Chandler</p>
              <p className="row-explanation">Maker or seller of candles</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Challoner</p>
              <p className="row-explanation">Maker or seller of blankets</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Chapman</p>
              <p className="row-explanation">A peddler or tradesman</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Clark</p>
              <p className="row-explanation">A secretary or cleric</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Collier</p>
              <p className="row-explanation">A coal miner</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Colt</p>
              <p className="row-explanation">
                Someone who works with horses or asses
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Cooper</p>
              <p className="row-explanation">
                A craftsman who makes barrels and other similar vessels
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Coward</p>
              <p className="row-explanation">Someone who herds cows/a cowboy</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Cutbush</p>
              <p className="row-explanation">Someone who cuts firewood</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Cutler</p>
              <p className="row-explanation">A maker of knives</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Dempster</p>
              <p className="row-explanation">A Judge</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Dexter</p>
              <p className="row-explanation">A dyer of cloth</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Dome</p>
              <p className="row-explanation">A Judge</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Draper</p>
              <p className="row-explanation">A cloth Merchant</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Elman</p>
              <p className="row-explanation">A maker or seller of oil</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Farrier</p>
              <p className="row-explanation">A horse shoe maker and fitter</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Fletcher</p>
              <p className="row-explanation">
                A maker of arrows (somtimes bows and arrows)
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Foreman</p>
              <p className="row-explanation">Manager of a farm</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Fowler</p>
              <p className="row-explanation">A hunter of birds</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Fuller</p>
              <p className="row-explanation">
                Someone who treats woolen cloth to make it thicker (a process
                known as fulling)
              </p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Gage</p>
              <p className="row-explanation">A moneylender or usurer</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Hodder</p>
              <p className="row-explanation">A maker or seller of hoods</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Homer</p>
              <p className="row-explanation">A helmet maker</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Jagger</p>
              <p className="row-explanation">A carter / carrier / pedlar</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Kellogg</p>
              <p className="row-explanation">A butcher (from kill hog)</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Kempster</p>
              <p className="row-explanation">A wool comber</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Kidman</p>
              <p className="row-explanation">A goat herder</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Larimer / Lorimer</p>
              <p className="row-explanation">
                A maker or seller of bits, spurs and other metal mountings for
                saddles and bridles
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Leech</p>
              <p className="row-explanation">A physician/Doctor</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Lister</p>
              <p className="row-explanation">A textile dyer</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Mason</p>
              <p className="row-explanation">A stonemason</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Mercer</p>
              <p className="row-explanation">A textile merchant</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Miller</p>
              <p className="row-explanation">
                A person who owns or operates a flour mill
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Milner</p>
              <p className="row-explanation">Equivalent of Miller</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Napier</p>
              <p className="row-explanation">
                A person who sells or makes table linen or a servant in charge
                of the linen in a household
              </p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Page</p>
              <p className="row-explanation">A servant</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Parker</p>
              <p className="row-explanation">A gamekeeper</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Poynter</p>
              <p className="row-explanation">A maker of cord</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Proctor</p>
              <p className="row-explanation">A steward</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Reeve</p>
              <p className="row-explanation">A bailiff</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Root</p>
              <p className="row-explanation">A guitar player</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Sexton</p>
              <p className="row-explanation">A churchwarden</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Spencer</p>
              <p className="row-explanation">
                A butler or steward that worked in a dispencier or "pantry"
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Stoddard</p>
              <p className="row-explanation">
                (Disputed) A standard bearer/A breeder of horses (stud-herd)
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Sumpter</p>
              <p className="row-explanation">
                A packhorseman, someone who carries items on horseback
              </p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Tanner</p>
              <p className="row-explanation">
                A person who tans animal hides, or converts them to leather
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Tapper</p>
              <p className="row-explanation">An Innkeeper</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Tinker</p>
              <p className="row-explanation">
                A person who mends pots and pans (A tinsmith)
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Travis</p>
              <p className="row-explanation">
                A toll collector at a bridge or river (from traverse)
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Tucker</p>
              <p className="row-explanation">Equivalent to Fuller</p>
            </div>
            <div className="line-even-margin"></div>
            <div className="table-flex-row-3">
              <p className="row-subject">Waite</p>
              <p className="row-explanation">A watchman</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Walker</p>
              <p className="row-explanation">
                A person who walked on cloth in order to thicken it
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Wayne</p>
              <p className="row-explanation">
                A cartwright/wainwright. A wagon or cart maker
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Whitcher</p>
              <p className="row-explanation">A maker of chests</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Wiles</p>
              <p className="row-explanation">A trapper</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Wright</p>
              <p className="row-explanation">
                A maker of machinery. Often used in combinations (Wainright,
                Cheesewright, Shipwright)
              </p>
            </div>
          </div>
        </div>
      </section>
      <AsideNav />
      <Footer />
    </div>
  );
}

export default LearnOccupationalSurnames;
