import ao from "./flags-svg/ao.svg";
import bf from "./flags-svg/bf.svg";
import bi from "./flags-svg/bi.svg";
import bj from "./flags-svg/bj.svg";
import bw from "./flags-svg/bw.svg";
import cd from "./flags-svg/cd.svg";
import cf from "./flags-svg/cf.svg";
import cg from "./flags-svg/cg.svg";
import ci from "./flags-svg/ci.svg";
import cm from "./flags-svg/cm.svg";
import dj from "./flags-svg/dj.svg";
import dz from "./flags-svg/dz.svg";
import eg from "./flags-svg/eg.svg";
import eh from "./flags-svg/eh.svg";
import er from "./flags-svg/er.svg";
import et from "./flags-svg/et.svg";
import ga from "./flags-svg/ga.svg";
import gh from "./flags-svg/gh.svg";
import gm from "./flags-svg/gm.svg";
import gn from "./flags-svg/gn.svg";
import gq from "./flags-svg/gq.svg";
import gw from "./flags-svg/gw.svg";
import ke from "./flags-svg/ke.svg";
import lr from "./flags-svg/lr.svg";
import ls from "./flags-svg/ls.svg";
import ly from "./flags-svg/ly.svg";
import ma from "./flags-svg/ma.svg";
import mg from "./flags-svg/mg.svg";
import ml from "./flags-svg/ml.svg";
import mr from "./flags-svg/mr.svg";
import mw from "./flags-svg/mw.svg";
import mz from "./flags-svg/mz.svg";
import na from "./flags-svg/na.svg";
import ne from "./flags-svg/ne.svg";
import ng from "./flags-svg/ng.svg";
import rw from "./flags-svg/rw.svg";
import sd from "./flags-svg/sd.svg";
import sl from "./flags-svg/sl.svg";
import sn from "./flags-svg/sn.svg";
import so from "./flags-svg/so.svg";
import ss from "./flags-svg/ss.svg";
import sz from "./flags-svg/sz.svg";
import td from "./flags-svg/td.svg";
import tg from "./flags-svg/tg.svg";
import tn from "./flags-svg/tn.svg";
import tz from "./flags-svg/tz.svg";
import ug from "./flags-svg/ug.svg";
import za from "./flags-svg/za.svg";
import zm from "./flags-svg/zm.svg";
import zw from "./flags-svg/zw.svg";

const eurovision = {
  mainCategory: "The Eurovision Song Contest",
  bigPeep: require("./images/peep-big11.png"),
  intro:
    "The Eurovision Song Contest began in 1956, and has taken place annually ever since (with the exception of 2020 due to the Covid-19 Pandemic). The first ever Eurovision took place in Lugano, Switzerland with seven countries competing. Generally the competition takes place in May.",

  smallPeep2: require("./images/peep-small26.png"),
  // smallPeep1: require("./images/peep-small3.png"),
  // disclaimer:
  //   "Note * after a statement is a disclaimer that this information could change as new records are broken and may not have yet been updated. All facts are true as of 2022",
  importantFacts: [
    "The countries known as the 'Big Five' (France, Germany, Italy, Spain and the United Kingdom) automatically qualify for the competition each year, as they significantly fund the competition more than the other countries do, along with the host country.",
  ],
  classicQuestions: [
    {
      id: "Eurovision-cQ1",
      question:
        "Who won Eurovision for Sweden in 1974 with their song Waterloo?",
      answer: "ABBA",
    },
    {
      id: "Eurovision-cQ2",
      question: "Which contry has had the most Eurovision wins?",
      answer: "Ireland (with 7)",
    },
    {
      id: "Eurovision-cQ3",
      question:
        "Which iconic Austrian bearded singer won in 2014 with the entry 'Rise Like a Phoenix'?",
      answer: "Conchita Wurst",
    },
    {
      id: "Eurovision-cQ4",
      question: "Which country will host the contest in 2023?",
      answer: "The UK (on behalf of Ukraine due to the Russian invasion)",
    },
  ],
  exampleQuestions: [
    {
      id: "Eurovision-eQ1",
      question: "What was the winning song for Finish rock band Lordi in 2006?",
      answer: "Hard Rock Hallelujah",
    },
    {
      id: "Eurovision-eQ2",
      question: "How many countries jointly won at the 1969 competition?",
      answer:
        "4 (France, Netherlands, Spain and the UK each receiving 18 points)",
    },
    {
      id: "Eurovision-eQ3",
      question:
        "Who is the only performer to have won more than once at Eurovision?",
      answer:
        "Johnny Logan (he won twice as a performer and once as the writer of a song)",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "Eurovision-tTAI1",
      text: "As with other competitions that have spanned many years, only the absolute super-fans will remember all the winners and hosts. It is a good idea to learn particularly unusual acts (there are many quirky acts every year so you're really looking for something extra special here!), or multiple wins and terrible failures (uk!).",
    },
  ],
};

const grandNational = {
  mainCategory: "The Grand National",
  bigPeep: require("./images/peep-big6.png"),
  intro:
    "The Grand National is an annual National Hunt horse race held at Aintree racecourse on the outskirts of Liverpool, England.",
  intro2:
    "It began in 1839 and has taken place every year since with the exceptions of 1916-1918 due to World War 1, 1941-1945 due to World War 2 and 2020 because of the COVID-19 pandemic.",

  smallPeep2: require("./images/peep-small14.png"),
  // smallPeep1: require("./images/peep-small3.png"),
  // disclaimer:
  //   "Note * after a statement is a disclaimer that this information could change as new records are broken and may not have yet been updated. All facts are true as of 2022",
  importantFacts: [
    "The Grand National race is 4 miles, 2 ½ furlongs long",
    "Red Rum, the most famous Grand National horse, is buried at the winning post at Aintree racecourse",
    "George Stevens is the jockey who has had the most wins at the Grand National, with 5 - his first win in 1856 and his last in 1870",
    "The fastest ever finish at the Grand National, as of 2022, was in 1990 by the horse Mr Frisk in a time of 8 minutes and 47.8 seconds",
    "The largest field of horses to take part was 66, in 1929",
    "Since 1984 the maximum number of horses allowed to compete has been 40",
    "In 1928 only 2 horses & jockeys finished the race the fewest on recored, Tipperary Tim was the 100/1 winner, and Billy Barton who had been remounted by his jockey after he fell. Remounting during a race was banned in 2009 for the health and safety of the horse and rider",
  ],
  classicQuestions: [
    {
      id: "grandNational-cQ1",
      question: "How many fences are on the Grand National course?",
      answer: "16",
    },
    {
      id: "grandNational-cQ2",
      question:
        "How many fences does the winning horse need to jump in the Grand National?",
      answer: "30",
    },
    {
      id: "grandNational-cQ3",
      question: "At which racecourse in England is the Grand National run?",
      answer: "Aintree",
    },
    {
      id: "grandNational-cQ4",
      question: "On the outskirts of which city is the Grand National held?",
      answer: "Liverpool",
    },
    {
      id: "grandNational-cQ5",
      question:
        "What name is given to the tallest fence jumped at the Grand National?",
      answer: "The Chair",
    },
    {
      id: "grandNational-cQ6",
      question: "Who was the first female jockey to win the Grand National?",
      answer: "Rachael Blackmore (Minella Times, 2021)",
    },
  ],
  exampleQuestions: [
    {
      id: "grandNational-eQ1",
      question: "What was the name of horse that won the first Grand National?",
      answer: "Lottery",
    },
    {
      id: "grandNational-eQ2",
      question:
        "In which year was the Grand National race declared void, due to multiple false starts?",
      answer: "1993",
    },
    {
      id: "grandNational-eQ3",
      question:
        "What is the name of the only horse to have won the Grand National 3 times?",
      answer: "Red Rum",
    },
    {
      id: "grandNational-eQ4",
      question: "In which 3 years did Red Rum win the Grand National",
      answer: "1973, 1974 & 1977",
    },
    {
      id: "grandNational-eQ5",
      question:
        "What was the name of the first horse to win the Grand National twice?",
      answer: "Abd-El-Kader (1850 & 1851)",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "grandNational-tTAI1",
      text: "You won't need to remember all of these horses and jockeys, the best thing to do is take a look through the horse names and see if anything resembles something that could be an answer to another question. eg Which former England goalkeeper with the nickname 'Safe Hands' shares his surname with the name of the winner of the 1882 Grand National? Answer: David Seaman.",
    },
    {
      id: "grandNational-tTAI2",
      text: "Other things to look out for are things like, which horse was the first to win the race? (Lottery), then who was first to win twice and 3 times. And then the same with jockeys.",
    },
    {
      id: "grandNational-tTAI3",
      text: "After that anything from 1990 may be considered fair game at a tougher pub quiz. Along with a supplementary clue eg which year 2000 Grand National winning horse shares its name with a 1973 Oscar nominated film starring Steve McQueen & Dustin Hoffman? Answer: Papillon.",
    },
    {
      id: "grandNational-tTAI4",
      text: "It's always a good idea to at least make a mental note of which horse most recently won the Grand National (2022: Noble Yeats)",
    },
  ],
};

const formerPlaceNames = {
  countries: [
    {
      id: "fpn-c1",
      now: "Bangladesh",
      previous: "East Pakistan",
    },
    {
      id: "fpn-c2",
      now: "Belize",
      previous: "British Hondoras",
    },
    {
      id: "fpn-c3",
      now: "Benin",
      previous: "Republic of Dahomey",
    },
    {
      id: "fpn-c4",
      now: "Bolivia",
      previous: "Upper Peru",
    },
    {
      id: "fpn-c5",
      now: "Botswana",
      previous: "Bechuanaland",
    },
    {
      id: "fpn-c6",
      now: "Brazil",
      previous: "Terra de Santa Cruz",
    },
    {
      id: "fpn-c7",
      now: "Burkina Faso",
      previous: "Republic of Upper Volta",
    },
    {
      id: "fpn-c8",
      now: "Cambodia",
      previous: "Khmer Republic",
      other: "Kampuchea",
    },
    {
      id: "fpn-c9",
      now: "Central African Republic",
      previous: "Ubangi-Shari",
    },
    {
      id: "fpn-c10",
      now: "Colombia",
      previous: "New Granada",
    },
    {
      id: "fpn-c11",
      now: "Democratic Republic of the Congo",
      previous: "Zaire",
    },
    {
      id: "fpn-c12",
      now: "Djibouti",
      previous: "Afars and Issas",
    },
    {
      id: "fpn-c13",
      now: "Equatorial Guinea",
      previous: "Spanish Guinea",
    },
    {
      id: "fpn-c14",
      now: "Eswatini",
      previous: "Swaziland",
    },
    {
      id: "fpn-c15",
      now: "Ethiopia",
      previous: "Abyssinia",
    },
    {
      id: "fpn-c16",
      now: "Ghana",
      previous: "Gold Coast",
    },
    {
      id: "fpn-c17",
      now: "Guinea-Bissau",
      previous: "Portuguese Guinea",
    },
    {
      id: "fpn-c18",
      now: "Indonesia",
      previous: "Dutch East Indies",
    },
    {
      id: "fpn-c19",
      now: "Iran",
      previous: "Persia",
    },
    {
      id: "fpn-c20",
      now: "Kiribati",
      previous: "Gilbert Island",
    },
    {
      id: "fpn-c21",
      now: "Lesotho",
      previous: "Basutoland",
    },
    {
      id: "fpn-c22",
      now: "Malawi",
      previous: "Nyasaland",
    },
    {
      id: "fpn-c23",
      now: "Mali",
      previous: "Franch Sudan",
    },
    {
      id: "fpn-c24",
      now: "Mexico",
      previous: "New Spain",
    },
    {
      id: "fpn-c25",
      now: "Myanmar",
      previous: "Burma",
    },
    {
      id: "fpn-c26",
      now: "Namibia",
      previous: "Southwest Africa",
    },
    {
      id: "fpn-c27",
      now: "Philippines",
      previous: "Spanish East Indies",
    },
    {
      id: "fpn-c28",
      now: "Saudi Arabia",
      previous: "Kingdom of Hejaz and Nejd",
    },
    {
      id: "fpn-c29",
      now: "Singapore",
      previous: "Temasik",
    },
    {
      id: "fpn-c30",
      now: "Sri Lanka",
      previous: "Ceylon",
    },
    {
      id: "fpn-c31",
      now: "Suriname",
      previous: "Dutch Guiana",
    },
    {
      id: "fpn-c32",
      now: "Tanzania",
      previous: "United Republic of Tanganyika and Zanzibar",
    },
    {
      id: "fpn-c33",
      now: "Thailand",
      previous: "Siam",
    },
    {
      id: "fpn-c34",
      now: "Timor-Leste",
      previous: "East Timor",
    },
    {
      id: "fpn-c35",
      now: "Tuvalu",
      previous: "Ellice Islands",
    },
    {
      id: "fpn-c36",
      now: "Vanuatu",
      previous: "New Hebrides",
    },
    {
      id: "fpn-c37",
      now: "Zambia",
      previous: "Northern Rhodesia",
    },
    {
      id: "fpn-c38",
      now: "Zimbabwe",
      previous: "Rhodesia (Southern Rhodesia)",
    },
  ],
  cities: [
    {
      id: "fpn-city1",
      now: "Beijing (China)",
      previous: "Peking",
    },
    {
      id: "fpn-city2",
      now: "Bratislava (Slovakia)",
      previous: "Pressburg or Pozsony",
    },
    {
      id: "fpn-city3",
      now: "Chennai (India)",
      previous: "Madras",
    },
    {
      id: "fpn-city4",
      now: "Gdansk (Poland)",
      previous: "Danzig",
    },
    {
      id: "fpn-city5",
      now: "Harare (Zimbabwe)",
      previous: "Salisbury",
    },
    {
      id: "fpn-city6",
      now: "Ho Chi Minh City (Vietnam)",
      previous: "Saigon",
    },
    {
      id: "fpn-city7",
      now: "Istanbul (Turkey)",
      previous: "Byzantium",
      other: "Constantinople",
    },
    {
      id: "fpn-city8",
      now: "Kinshasa (Democratic Republic of the Congo)",
      previous: "Leopoldville",
    },
    {
      id: "fpn-city9",
      now: "Jakarta (Indonesia)",
      previous: "Sunda Kelapa",
      other: "Batavia",
    },
    {
      id: "fpn-city10",
      now: "Kaliningrad (Russia)",
      previous: "Konigsberg",
    },
    {
      id: "fpn-city11",
      now: "Mumbai (India)",
      previous: "Bombay",
    },
    {
      id: "fpn-city12",
      now: "New York (USA)",
      previous: "New Amsterdam",
    },
    {
      id: "fpn-city13",
      now: "Oslo (Norway)",
      previous: "Christiania",
    },
    {
      id: "fpn-city14",
      now: "Ottowa (Canada)",
      previous: "Bytown",
    },
    {
      id: "fpn-city15",
      now: "Pogdorica (Montenegro)",
      previous: "Titograd",
    },
    {
      id: "fpn-city16",
      now: "Saint Petersburg (Russia)",
      previous: "(Originally Saint Petersburg) Petrograd",
      other: "Leningrad",
    },
    {
      id: "fpn-city17",
      now: "Tallinn (Estonia)",
      previous: "Reval",
    },
    {
      id: "fpn-city18",
      now: "Tokyo (Japan)",
      previous: "Edo",
    },
    {
      id: "fpn-city19",
      now: "Toronto (Canada)",
      previous: "York",
    },
    {
      id: "fpn-city20",
      now: "Volgograd (Russia)",
      previous: "Tsaritsyn",
      other: "Stalingrad",
    },
  ],
};

const americanStates = [
  {
    id: "AL",
    stateCode: "AL",
    stateName: "Alabama",
    stateCapital: "Montgomery",
    mostPopulousCity: "Huntsville",
    stateFlag: require("./images/americanStateFlags/alabamaFlag.png"),
  },
  {
    id: "AK",
    stateCode: "AK",
    stateName: "Alaska",
    stateCapital: "Juneau",
    mostPopulousCity: "Anchorage",
    stateFlag: require("./images/americanStateFlags/alaskaFlag.png"),
  },
  {
    id: "AZ",
    stateCode: "AZ",
    stateName: "Arizona",
    stateCapital: "Phoenix",
    mostPopulousCity: "Phoenix",
    stateFlag: require("./images/americanStateFlags/arizonaFlag.png"),
  },
  {
    id: "AR",
    stateCode: "AR",
    stateName: "Arkansas",
    stateCapital: "Little Rock",
    mostPopulousCity: "Little Rock",
    stateFlag: require("./images/americanStateFlags/arkansasFlag.png"),
  },
  {
    id: "CA",
    stateCode: "CA",
    stateName: "California",
    stateCapital: "Sacramento",
    mostPopulousCity: "Los Angeles",
    stateFlag: require("./images/americanStateFlags/californiaFlag.png"),
  },
  {
    id: "CO",
    stateCode: "CO",
    stateName: "Colorado",
    stateCapital: "Denver",
    mostPopulousCity: "Denver",
    stateFlag: require("./images/americanStateFlags/coloradoFlag.png"),
  },
  {
    id: "CT",
    stateCode: "CT",
    stateName: "Connecticut",
    stateCapital: "Hartford",
    mostPopulousCity: "Bridgeport",
    stateFlag: require("./images/americanStateFlags/connecticutFlag.png"),
  },
  {
    id: "DE",
    stateCode: "DE",
    stateName: "Delaware",
    stateCapital: "Dover",
    mostPopulousCity: "Wilmington",
    stateFlag: require("./images/americanStateFlags/delawareFlag.png"),
  },
  {
    id: "FL",
    stateCode: "FL",
    stateName: "Florida",
    stateCapital: "Tallahassee",
    mostPopulousCity: "Jacksonville",
    stateFlag: require("./images/americanStateFlags/floridaFlag.png"),
  },
  {
    id: "GA",
    stateCode: "GA",
    stateName: "Georgia",
    stateCapital: "Atlanta",
    mostPopulousCity: "Atlanta",
    stateFlag: require("./images/americanStateFlags/georgiaFlag.png"),
  },
  {
    id: "HI",
    stateCode: "HI",
    stateName: "Hawaii",
    stateCapital: "Honolulu",
    mostPopulousCity: "Honolulu",
    stateFlag: require("./images/americanStateFlags/hawaiiFlag.png"),
  },
  {
    id: "ID",
    stateCode: "ID",
    stateName: "Idaho",
    stateCapital: "Boise",
    mostPopulousCity: "Boise",
    stateFlag: require("./images/americanStateFlags/idahoFlag.png"),
  },
  {
    id: "IL",
    stateCode: "IL",
    stateName: "Illinois",
    stateCapital: "Springfield",
    mostPopulousCity: "Chicago",
    stateFlag: require("./images/americanStateFlags/illinoisFlag.png"),
  },
  {
    id: "IN",
    stateCode: "IN",
    stateName: "Indiana",
    stateCapital: "Indianapolis",
    mostPopulousCity: "Indianapolis",
    stateFlag: require("./images/americanStateFlags/indianaFlag.png"),
  },
  {
    id: "IA",
    stateCode: "IA",
    stateName: "Iowa",
    stateCapital: "Des Moines",
    mostPopulousCity: "Des Moines",
    stateFlag: require("./images/americanStateFlags/iowaFlag.png"),
  },
  {
    id: "KS",
    stateCode: "KS",
    stateName: "Kansas",
    stateCapital: "Topeka",
    mostPopulousCity: "Wichita",
    stateFlag: require("./images/americanStateFlags/kansasFlag.png"),
  },
  {
    id: "KY",
    stateCode: "KY",
    stateName: "Kentucky",
    stateCapital: "Frankfort",
    mostPopulousCity: "Louisville",
    stateFlag: require("./images/americanStateFlags/kentuckyFlag.png"),
  },
  {
    id: "LA",
    stateCode: "LA",
    stateName: "Louisiana",
    stateCapital: "Baton Rouge",
    mostPopulousCity: "New Orleans",
    stateFlag: require("./images/americanStateFlags/louisianaFlag.png"),
  },
  {
    id: "ME",
    stateCode: "ME",
    stateName: "Maine",
    stateCapital: "Augusta",
    mostPopulousCity: "Portland",
    stateFlag: require("./images/americanStateFlags/maineFlag.png"),
  },
  {
    id: "MD",
    stateCode: "MD",
    stateName: "Maryland",
    stateCapital: "Annapolis",
    mostPopulousCity: "Baltimore",
    stateFlag: require("./images/americanStateFlags/marylandFlag.png"),
  },
  {
    id: "MA",
    stateCode: "MA",
    stateName: "Massachusetts",
    stateCapital: "Boston",
    mostPopulousCity: "Boston",
    stateFlag: require("./images/americanStateFlags/massachusettsFlag.png"),
  },
  {
    id: "MI",
    stateCode: "MI",
    stateName: "Michigan",
    stateCapital: "Lansing",
    mostPopulousCity: "Detroit",
    stateFlag: require("./images/americanStateFlags/michiganFlag.png"),
  },
  {
    id: "MN",
    stateCode: "MN",
    stateName: "Minnesota",
    stateCapital: "Saint Paul",
    mostPopulousCity: "Minneapolis",
    stateFlag: require("./images/americanStateFlags/minnesotaFlag.png"),
  },
  {
    id: "MS",
    stateCode: "MS",
    stateName: "Mississippi",
    stateCapital: "Jackson",
    mostPopulousCity: "Jackson",
    stateFlag: require("./images/americanStateFlags/mississippiFlag.png"),
  },
  {
    id: "MO",
    stateCode: "MO",
    stateName: "Missouri",
    stateCapital: "Jefferson City",
    mostPopulousCity: "Kansas City",
    stateFlag: require("./images/americanStateFlags/missouriFlag.png"),
  },
  {
    id: "MT",
    stateCode: "MT",
    stateName: "Montana",
    stateCapital: "Helena",
    mostPopulousCity: "Billings",
    stateFlag: require("./images/americanStateFlags/montanaFlag.png"),
  },
  {
    id: "NE",
    stateCode: "NE",
    stateName: "Nebraska",
    stateCapital: "Lincoln",
    mostPopulousCity: "Omaha",
    stateFlag: require("./images/americanStateFlags/nebraskaFlag.png"),
  },
  {
    id: "NV",
    stateCode: "NV",
    stateName: "Nevada",
    stateCapital: "Carson City",
    mostPopulousCity: "Las Vegas",
    stateFlag: require("./images/americanStateFlags/nevadaFlag.png"),
  },
  {
    id: "NH",
    stateCode: "NH",
    stateName: "New Hampshire",
    stateCapital: "Concord",
    mostPopulousCity: "MAnchester",
    stateFlag: require("./images/americanStateFlags/new-hampshireFlag.png"),
  },
  {
    id: "NJ",
    stateCode: "NJ",
    stateName: "New Jersey",
    stateCapital: "Trenton",
    mostPopulousCity: "Newark",
    stateFlag: require("./images/americanStateFlags/new-jerseyFlag.png"),
  },
  {
    id: "NM",
    stateCode: "NM",
    stateName: "New Mexico",
    stateCapital: "Santa Fe",
    mostPopulousCity: "Albuquerque",
    stateFlag: require("./images/americanStateFlags/new-mexicoFlag.png"),
  },
  {
    id: "NY",
    stateCode: "NY",
    stateName: "New York",
    stateCapital: "Albany",
    mostPopulousCity: "New York City",
    stateFlag: require("./images/americanStateFlags/new-yorkFlag.png"),
  },
  {
    id: "NC",
    stateCode: "NC",
    stateName: "North Carolina",
    stateCapital: "Raleigh",
    mostPopulousCity: "Charlotte",
    stateFlag: require("./images/americanStateFlags/north-carolinaFlag.png"),
  },
  {
    id: "ND",
    stateCode: "ND",
    stateName: "North Dakota",
    stateCapital: "Bismarck",
    mostPopulousCity: "Fargo",
    stateFlag: require("./images/americanStateFlags/north-dakotaFlag.png"),
  },
  {
    id: "OH",
    stateCode: "OH",
    stateName: "Ohio",
    stateCapital: "Columbus",
    mostPopulousCity: "Columbus",
    stateFlag: require("./images/americanStateFlags/ohioFlag.png"),
  },
  {
    id: "OK",
    stateCode: "OK",
    stateName: "Oklahoma",
    stateCapital: "Oklahoma City",
    mostPopulousCity: "Oklahoma City",
    stateFlag: require("./images/americanStateFlags/oklahomaFlag.png"),
  },
  {
    id: "OR",
    stateCode: "OR",
    stateName: "Oregon",
    stateCapital: "Salem",
    mostPopulousCity: "Portland",
    stateFlag: require("./images/americanStateFlags/oregonFlag.png"),
  },
  {
    id: "PA",
    stateCode: "PA",
    stateName: "Pennsylvania",
    stateCapital: "Harrisburg",
    mostPopulousCity: "Philadelphia",
    stateFlag: require("./images/americanStateFlags/pennsylvaniaFlag.png"),
  },
  {
    id: "RI",
    stateCode: "RI",
    stateName: "Rhode Island",
    stateCapital: "Providence",
    mostPopulousCity: "Providence",
    stateFlag: require("./images/americanStateFlags/rhode-islandFlag.png"),
  },
  {
    id: "SC",
    stateCode: "SC",
    stateName: "South Carolina",
    stateCapital: "Columbia",
    mostPopulousCity: "Charleston",
    stateFlag: require("./images/americanStateFlags/south-carolinaFlag.png"),
  },
  {
    id: "SD",
    stateCode: "SD",
    stateName: "South Dakota",
    stateCapital: "Pierre",
    mostPopulousCity: "Sioux Falls",
    stateFlag: require("./images/americanStateFlags/south-dakotaFlag.png"),
  },
  {
    id: "TE",
    stateCode: "TE",
    stateName: "Tennessee",
    stateCapital: "Nashville",
    mostPopulousCity: "Nashville",
    stateFlag: require("./images/americanStateFlags/tennesseeFlag.png"),
  },
  {
    id: "TX",
    stateCode: "TX",
    stateName: "Texas",
    stateCapital: "Austin",
    mostPopulousCity: "Houston",
    stateFlag: require("./images/americanStateFlags/texasFlag.png"),
  },
  {
    id: "UT",
    stateCode: "UT",
    stateName: "Utah",
    stateCapital: "Salt Lake City",
    mostPopulousCity: "Salt Lake City",
    stateFlag: require("./images/americanStateFlags/utahFlag.png"),
  },
  {
    id: "VT",
    stateCode: "VT",
    stateName: "Vermont",
    stateCapital: "Montpelier",
    mostPopulousCity: "Burlington",
    stateFlag: require("./images/americanStateFlags/vermontFlag.png"),
  },
  {
    id: "VA",
    stateCode: "VA",
    stateName: "Virginia",
    stateCapital: "Richmond",
    mostPopulousCity: "Virginia Beach",
    stateFlag: require("./images/americanStateFlags/virginiaFlag.png"),
  },
  {
    id: "WA",
    stateCode: "WA",
    stateName: "Washington",
    stateCapital: "Olympia",
    mostPopulousCity: "Seattle",
    stateFlag: require("./images/americanStateFlags/washingtonFlag.png"),
  },
  {
    id: "WV",
    stateCode: "WV",
    stateName: "West Virginia",
    stateCapital: "Charleston",
    mostPopulousCity: "Charleston",
    stateFlag: require("./images/americanStateFlags/west-virginiaFlag.png"),
  },
  {
    id: "WI",
    stateCode: "WI",
    stateName: "Wisconsin",
    stateCapital: "Madison",
    mostPopulousCity: "Milwaukee",
    stateFlag: require("./images/americanStateFlags/wisconsinFlag.png"),
  },
  {
    id: "WY",
    stateCode: "WY",
    stateName: "Wyoming",
    stateCapital: "Cheyenne",
    mostPopulousCity: "Cheyenne",
    stateFlag: require("./images/americanStateFlags/wyomingFlag.png"),
  },
];

const englishCounties = [
  {
    id: "Bedfordshire",
    countyName: "Bedfordshire",
    countyTown: "Bedford",
    countyFlag: require("./images/countyFlags/BedfordshireFlag.png"),
  },
  {
    id: "Berkshire",
    countyName: "Berkshire",
    countyTown: "Reading",
    countyFlag: require("./images/countyFlags/BerkshireFlag.png"),
  },
  {
    id: "Bristol",
    countyName: "Bristol",
    countyTown: "Bristol",
    countyArms: require("./images/countyFlags/BristolCityArms.png"),
  },
  {
    id: "Buckinghamshire",
    countyName: "Buckinghamshire",
    countyTown: "Aylesbury",
    countyFlag: require("./images/countyFlags/BuckinghamshireFlag.png"),
  },
  {
    id: "Cambridgeshire",
    countyName: "Cambridgeshire",
    countyTown: "Cambridge",
    countyFlag: require("./images/countyFlags/CambridgeshireFlag.png"),
    countyArms: require("./images/countyFlags/CambridgeshireArms.png"),
  },
  {
    id: "Cheshire",
    countyName: "Cheshire",
    countyTown: "Chester",
    countyFlag: require("./images/countyFlags/CheshireFlag.png"),
  },
  {
    id: "Cornwall",
    countyName: "Cornwall",
    countyTown: "Truro",
    countyFlag: require("./images/countyFlags/CornwallFlag.png"),
  },
  {
    id: "Cumbria",
    countyName: "Cumbria",
    countyTown: "Carlisle",
    countyFlag: require("./images/countyFlags/CumbriaFlag.jpg"),
    countyArms: require("./images/countyFlags/CumbriaArms.png"),
  },
  {
    id: "Derbyshire",
    countyName: "Derbyshire",
    countyTown: "Matlock",
    countyFlag: require("./images/countyFlags/DerbyshireFlag.png"),
    countyArms: require("./images/countyFlags/DerbyshireArms.png"),
  },
  {
    id: "Devon",
    countyName: "Devon",
    countyTown: "Exeter",
    countyFlag: require("./images/countyFlags/DevonFlag.png"),
    countyArms: require("./images/countyFlags/DevonArms.png"),
  },
  {
    id: "Dorset",
    countyName: "Dorset",
    countyTown: "Dorchester",
    countyFlag: require("./images/countyFlags/DorsetFlag.png"),
  },
  {
    id: "Durham",
    countyName: "Durham",
    countyTown: "Durham",
    countyFlag: require("./images/countyFlags/DurhamFlag.png"),
  },
  {
    id: "East Riding Of Yorkshire",
    countyName: "East Riding Of Yorkshire",
    countyTown: "Beverley",
    countyFlag: require("./images/countyFlags/EastRidingFlag.jpg"),
    countyArms: require("./images/countyFlags/EastRidingArms.png"),
  },
  {
    id: "East Sussex",
    countyName: "East Sussex",
    countyTown: "Lewes",
    countyFlag: require("./images/countyFlags/EastSussexFlag.png"),
  },
  {
    id: "Essex",
    countyName: "Essex",
    countyTown: "Chelmsford",
    countyFlag: require("./images/countyFlags/EssexFlag.png"),
  },
  {
    id: "Gloucestershire",
    countyName: "Gloucestershire",
    countyTown: "Gloucester",
    countyFlag: require("./images/countyFlags/GloucestershireFlag.png"),
    countyArms: require("./images/countyFlags/GloucestershireArms.png"),
  },
  {
    id: "Greater London",
    countyName: "Greater London",
    countyTown: "Newham",
    countyFlag: require("./images/countyFlags/GreaterLondonFlag.png"),
  },
  {
    id: "Greater Manchester",
    countyName: "Greater Manchester",
    countyTown: "Manchester",
    countyFlag: require("./images/countyFlags/GreaterManchesterFlag.png"),
  },
  {
    id: "Hampshire",
    countyName: "Hampshire",
    countyTown: "Winchester",
    countyFlag: require("./images/countyFlags/HampshireFlag.png"),
    countyArms: require("./images/countyFlags/HampshireArms.png"),
  },
  {
    id: "Herefordshire",
    countyName: "Herefordshire",
    countyTown: "Hereford",
    countyFlag: require("./images/countyFlags/HerefordshireFlag.png"),
    countyArms: require("./images/countyFlags/HerefordshireArms.png"),
  },
  {
    id: "Hertfordshire",
    countyName: "Hertfordshire",
    countyTown: "Hertford",
    countyFlag: require("./images/countyFlags/HertfordshireFlag.png"),
    countyArms: require("./images/countyFlags/HertfordshireArms.png"),
  },
  {
    id: "Isle of Wight",
    countyName: "Isle of Wight",
    countyTown: "Newport",
    countyFlag: require("./images/countyFlags/IsleOfWightFlag.png"),
    countyArms: require("./images/countyFlags/IsleOfWightArms.png"),
  },
  {
    id: "Kent",
    countyName: "Kent",
    countyTown: "Maidstone",
    countyFlag: require("./images/countyFlags/KentFlag.png"),
    countyArms: require("./images/countyFlags/KentArms.png"),
  },
  {
    id: "Lancashire",
    countyName: "Lancashire",
    countyTown: "Lancaster",
    countyFlag: require("./images/countyFlags/LancashireFlag.png"),
    countyArms: require("./images/countyFlags/LancashireArms.png"),
  },
  {
    id: "Leicestershire",
    countyName: "Leicestershire",
    countyTown: "Leicester",
    countyFlag: require("./images/countyFlags/LeicestershireFlag.png"),
    countyArms: require("./images/countyFlags/LeicestershireArms.png"),
  },
  {
    id: "Lincolnshire",
    countyName: "Lincolnshire",
    countyTown: "Lincoln",
    countyFlag: require("./images/countyFlags/LincolnshireFlag.png"),
    countyArms: require("./images/countyFlags/LincolnshireArms.png"),
  },
  {
    id: "Merseyside",
    countyName: "Merseyside",
    countyTown: "Liverpool",
    countyFlag: require("./images/countyFlags/MerseysideFlag.jpg"),
    countyArms: require("./images/countyFlags/MerseysideArms.png"),
  },
  {
    id: "Norfolk",
    countyName: "Norfolk",
    countyTown: "Norwich",
    countyFlag: require("./images/countyFlags/NorfolkFlag.png"),
    countyArms: require("./images/countyFlags/NorfolkArms.png"),
  },
  {
    id: "North Yorkshire",
    countyName: "North Yorkshire",
    countyTown: "Northallerton",
    countyArms: require("./images/countyFlags/NorthYorkshireArms.png"),
  },
  {
    id: "Northamptonshire",
    countyName: "Northamptonshire",
    countyTown: "Northampton",
    countyFlag: require("./images/countyFlags/NorthamptonshireFlag.png"),
  },
  {
    id: "Northumberland",
    countyName: "Northumberland",
    countyTown: "Morpeth",
    countyFlag: require("./images/countyFlags/NorthumberlandFlag.png"),
    countyArms: require("./images/countyFlags/NorthumberlandArms.png"),
  },
  {
    id: "Nottinghamshire",
    countyName: "Nottinghamshire",
    countyTown: "Nottingham",
    countyFlag: require("./images/countyFlags/NottinghamshireFlag.png"),
    countyArms: require("./images/countyFlags/NottinghamshireArms.png"),
  },
  {
    id: "Oxfordshire",
    countyName: "Oxfordshire",
    countyTown: "Oxford",
    countyFlag: require("./images/countyFlags/OxfordshireFlag.png"),
    countyArms: require("./images/countyFlags/OxfordshireArms.png"),
  },
  {
    id: "Rutland",
    countyName: "Rutland",
    countyTown: "Oakham",
    countyFlag: require("./images/countyFlags/RutlandFlag.png"),
    countyArms: require("./images/countyFlags/RutlandArms.png"),
  },
  {
    id: "Shropshire",
    countyName: "Shropshire",
    countyTown: "Shrewsbury",
    countyFlag: require("./images/countyFlags/ShropshireFlag.png"),
    countyArms: require("./images/countyFlags/ShropshireArms.png"),
  },
  {
    id: "Somerset",
    countyName: "Somerset",
    countyTown: "Taunton",
    countyFlag: require("./images/countyFlags/SomersetFlag.png"),
    countyArms: require("./images/countyFlags/SomersetArms.png"),
  },
  {
    id: "South Yorkshire",
    countyName: "South Yorkshire",
    countyTown: "Barnsley",
    countyArms: require("./images/countyFlags/SouthYorkshireArms.png"),
  },
  {
    id: "Staffordshire",
    countyName: "Staffordshire",
    countyTown: "Stafford",
    countyFlag: require("./images/countyFlags/StaffordshireFlag.png"),
    countyArms: require("./images/countyFlags/StaffordshireArms.png"),
  },
  {
    id: "Suffolk",
    countyName: "Suffolk",
    countyTown: "Ipswich",
    countyFlag: require("./images/countyFlags/SuffolkFlag.png"),
    countyArms: require("./images/countyFlags/SuffolkArms.png"),
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
  },
  {
    id: "Surrey",
    countyName: "Surrey",
    countyTown: "Guildford",
    countyFlag: require("./images/countyFlags/SurreyFlag.jpg"),
    countyArms: require("./images/countyFlags/SurreyArms.png"),
  },
  {
    id: "Tyne and Wear",
    countyName: "Tyne And Wear",
    countyTown: "Newcastle-upon-Tyne",
  },
  {
    id: "Warwickshire",
    countyName: "Warwickshire",
    countyTown: "Warwick",
    countyFlag: require("./images/countyFlags/WarwickshireFlag.png"),
    countyArms: require("./images/countyFlags/WarwickshireArms.png"),
  },
  {
    id: "West Midlands",
    countyName: "West Midlands",
    countyTown: "Birmingham",
  },
  {
    id: "West Sussex",
    countyName: "West Sussex",
    countyTown: "Chichester",
    countyFlag: require("./images/countyFlags/WestSussexFlag.png"),
  },
  {
    id: "West Yorkshire",
    countyName: "West Yorkshire",
    countyTown: "Wakefield",
  },
  {
    id: "Wiltshire",
    countyName: "Wiltshire",
    countyTown: "Trowbridge",
    countyFlag: require("./images/countyFlags/WiltshireFlag.png"),
  },
  {
    id: "Worcestershire",
    countyName: "Worcestershire",
    countyTown: "Worcester",
    countyFlag: require("./images/countyFlags/WorcestershireFlag.jpg"),
    countyArms: require("./images/countyFlags/WorcestershireArms.png"),
  },
];
const englishCountiesAttributionsFlags = [
  {
    id: "BuckinghamshireFlag",
    countyName: "Buckinghamshire",
    imageAtt: "Buckinghamshire Flag",
    ccArtist: "The Flag Institute",
  },
  {
    id: "CheshireFlag",
    countyName: "Cheshire",
    imageAtt: "Cheshire Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/3.0/",
    ccArtist: "Vexilo",
    ccNum: "CC BY-SA 3.0",
    ccFileName: "Flag of Cheshire.svg",
  },
  {
    id: "CumbriaFlag",
    countyName: "Cumbria",
    imageAtt: "Cumbria Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "CiberWiki",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Cumbria-flag-chevrons-600x360.jpg",
  },
  {
    id: "DurhamFlag",
    countyName: "Durham",
    imageAtt: "Durham Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/3.0/",
    ccArtist: "Martin23230",
    ccNum: "CC BY-SA 3.0",
    ccFileName: "Flag of County Durham.svg",
  },
  {
    id: "HerefordshireFlag",
    countyName: "Herefordshire",
    imageAtt: "Herefordshire Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Vexilo",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "County Flag Of Herefordshire.svg",
  },
  {
    id: "OxfordshireFlag",
    countyName: "Oxfordshire",
    imageAtt: "Oxfordshire Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Vexilo",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Flag of Oxfordshire.svg",
  },
  {
    id: "ShropshireFlag",
    countyName: "Shropshire",
    imageAtt: "Shropshire Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/3.0/",
    ccArtist: "Hogweard",
    ccNum: "CC BY-SA 3.0",
    ccFileName: "Flag of Shropshire.svg",
  },
  {
    id: "SuffolkFlags",
    countyName: "Suffolk",
    imageAtt: "Suffolk Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Vexilo",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "County Flag of Suffolk.svg",
  },
  {
    id: "WarwickshireFlag",
    countyName: "Warwickshire",
    imageAtt: "Warwickshire Flag",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Vexilo / Hogweard",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Flag of Warwickshire.svg",
  },
];
const englishCountiesAttributionsArms = [
  {
    id: "BristolCoatofArms",
    countyName: "Bristol",
    imageAtt: "Bristol Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Bristol City Council.svg",
  },
  {
    id: "CambridgeshireArms",
    countyName: "Cambridgeshire",
    imageAtt: "Cambridgeshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Cambridgeshire County Council.svg",
  },
  {
    id: "CumbriaArms",
    countyName: "Cumbria",
    imageAtt: "Cumbria Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Cumbria County Council.svg",
  },
  {
    id: "DerbyshireArms",
    countyName: "Derbyshire",
    imageAtt: "Derbyshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Derbyshire County Council.svg",
  },
  {
    id: "DevonArms",
    countyName: "Devon",
    imageAtt: "Devon Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "SouthHamsian",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Coat of Arms of Devon County Council.svg",
  },

  {
    id: "EastRidingOfYorkshireArms",
    countyName: "East Riding of Yorkshire",
    imageAtt: "East Riding Of Yorkshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of East Riding of Yorkshire Council.svg",
  },

  {
    id: "GloucestershireArms",
    countyName: "Gloucestershire",
    imageAtt: "Gloucestershire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Gloucestershire County Council.svg",
  },
  {
    id: "HampshireArms",
    countyName: "Hampshire",
    imageAtt: "Hampshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Coat of arms of Hampshire County Council, England.svg",
  },
  {
    id: "HerefordshireArms",
    countyName: "Herefordshire",
    imageAtt: "Herefordshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Herefordshire County Council.svg",
  },
  {
    id: "HertfordshireArms",
    countyName: "Hertfordshire",
    imageAtt: "Hertfordshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Hertfordshire County Council.svg",
  },
  {
    id: "IsleofWightArms",
    countyName: "Isle of Wight",
    imageAtt: "Isle of Wight Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Isle of Wight Council.svg",
  },
  {
    id: "KentArms",
    countyNamee: "Kent",
    imageAtt: "Kent Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Kent County Council.svg",
  },
  {
    id: "LancashireArms",
    countyName: "Lancashire",
    imageAtt: "Lancashire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Lancashire County Council.svg",
  },
  {
    id: "LeicestershireArms",
    countyName: "Leicestershire",
    imageAtt: "Leicestershire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Leicestershire County Council.svg",
  },
  {
    id: "LincolnshireArms",
    countyName: "Lincolnshire",
    imageAtt: "Lincolnshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Lincolnshire County Council.svg",
  },
  {
    id: "MerseysideArms",
    countyName: "Merseyside",
    imageAtt: "Merseyside Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "IndysNotHere",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of the Merseyside Metropolitan County Council.svg",
  },

  {
    id: "NorthYorkshireArms",
    countyName: "North Yorkshire",
    imageAtt: "North Yorkshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of North Yorkshire County Council.svg",
  },
  {
    id: "NorthumberlandArms",
    countyName: "Northumberland",
    imageAtt: "Northumberland Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Northumberland County Council.svg",
  },
  {
    id: "NottinghamshireArms",
    countyName: "Nottinghamshire",
    imageAtt: "Nottinghamshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Nottinghamshire County Council.svg",
  },
  {
    id: "OxfordshireArms",
    countyName: "Oxfordshire",
    imageAtt: "Oxfordshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Oxfordshire County Council.svg",
  },
  {
    id: "RutlandArms",
    countyName: "Rutland",
    imageAtt: "Rutland Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Rutland County Council.svg",
  },
  {
    id: "ShropshireArms",
    countyName: "Shropshire",
    imageAtt: "Shropshire Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Shropshire County Council.svg",
  },
  {
    id: "SomersetArms",
    countyName: "Somerset",
    imageAtt: "Somerset Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Somerset County Council.svg",
  },
  {
    id: "SouthYorkshireCoatofArms",
    countyName: "South Yorkshire",
    imageAtt: "South Yorkshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of South Yorkshire Metropolitan County Council.svg",
  },
  {
    id: "StaffordshireArms",
    countyName: "Staffordshire",
    imageAtt: "Staffordshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Staffordshire County Council.svg",
  },
  {
    id: "SuffolkArms",
    countyName: "Suffolk",
    imageAtt: "Suffolk Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/3.0/",
    ccArtist: "Fvasconcellos",
    ccNum: "CC BY-SA 3.0",
    ccFileName: "Arms of Suffolk.svg",
  },
  {
    id: "WarwickshireArms",
    countyName: "Warwixkshire",
    imageAtt: "Warwickshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Warwickshire County Council.svg",
  },
  {
    id: "WiltshireArms",
    countyName: "Wiltshire",
    imageAtt: "Wiltshire Coat of Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Wiltshire County Council.svg",
  },
  {
    id: "WorcestershireArms",
    countyName: "Worcestershire",
    imageAtt: "Worcestershire Arms",
    ccLink: "https://creativecommons.org/licenses/by-sa/4.0/",
    ccArtist: "Fenn-O-maniC",
    ccNum: "CC BY-SA 4.0",
    ccFileName: "Arms of Worcestershire County Council.svg",
  },
];

const africanCountries = [
  {
    id: "algeria",
    countryName: "Algeria",
    capitalCity: "Algiers",
    countryFlag: <img src={dz} />,
  },
  {
    id: "angola",
    countryName: "Angola",
    capitalCity: "Luanda",
    countryFlag: <img src={ao} />,
  },
  {
    id: "benin",
    countryName: "Benin",
    capitalCity: "Porto-Novo",
    countryFlag: <img src={bj} />,
  },
  {
    id: "botswana",
    countryName: "Botswana",
    capitalCity: "Gaborone",
    countryFlag: <img src={bw} />,
  },
  {
    id: "burkinaFaso",
    countryName: "Burkina Faso",
    capitalCity: "Ouagadougou",
    countryFlag: <img src={bf} />,
  },
  {
    id: "burundi",
    countryName: "Burundi",
    capitalCity: "Gitega",
    countryFlag: <img src={bi} />,
  },
  {
    id: "cameroon",
    countryName: "Cameroon",
    capitalCity: "Yaoundé",
    countryFlag: <img src={cm} />,
  },
  {
    id: "centralAfricanRepublic",
    countryName: "Central African Republic",
    capitalCity: "Bangui",
    countryFlag: <img src={cf} />,
  },
  {
    id: "chad",
    countryName: "Chad",
    capitalCity: "N'Djamena",
    countryFlag: <img src={td} />,
  },
  {
    id: "cote-d'ivore",
    countryName: "Cote D'ivoire",
    capitalCity: "Yamoussoukro",
    countryFlag: <img src={ci} />,
  },
  {
    id: "democraticRepublicOfCongo",
    countryName: "Democratic Republic Of The Congo",
    capitalCity: "Kinshasa",
    countryFlag: <img src={cd} />,
  },
  {
    id: "djibouti",
    countryName: "Djibouti",
    capitalCity: "Djibouti city",
    countryFlag: <img src={dj} />,
  },
  {
    id: "egypt",
    countryName: "Egypt",
    capitalCity: "Cairo",
    countryFlag: <img src={eg} />,
  },
  {
    id: "eswatini",
    countryName: "Eswatini",
    capitalCity: "Mbabane / Lobamba",
    countryFlag: <img src={sz} />,
  },
  {
    id: "equatorialGuinea",
    countryName: "Equatorial Guinea",
    capitalCity: "Malabo",
    countryFlag: <img src={gq} />,
  },
  {
    id: "eritrea",
    countryName: "Eritrea",
    capitalCity: "Asmara",
    countryFlag: <img src={er} />,
  },
  {
    id: "ethiopia",
    countryName: "Ethiopia",
    capitalCity: "Addis Ababa",
    countryFlag: <img src={et} />,
  },
  {
    id: "gabon",
    countryName: "Gabon",
    capitalCity: "Libreville",
    countryFlag: <img src={ga} />,
  },
  {
    id: "gambia",
    countryName: "Gambia",
    capitalCity: "Banjul",
    countryFlag: <img src={gm} />,
  },
  {
    id: "ghana",
    countryName: "Ghana",
    capitalCity: "Accra",
    countryFlag: <img src={gh} />,
  },
  {
    id: "guinea",
    countryName: "Guinea",
    capitalCity: "Conakry",
    countryFlag: <img src={gn} />,
  },
  {
    id: "guinea-bissau",
    countryName: "Guinea-Bissau",
    capitalCity: "Bissau",
    countryFlag: <img src={gw} />,
  },

  {
    id: "kenya",
    countryName: "Kenya",
    capitalCity: "Nairobi",
    countryFlag: <img src={ke} />,
  },
  {
    id: "lesotha",
    countryName: "Lesotho",
    capitalCity: "Maseru",
    countryFlag: <img src={ls} />,
  },
  {
    id: "liberia",
    countryName: "Liberia",
    capitalCity: "Monrovia",
    countryFlag: <img src={lr} />,
  },
  {
    id: "libya",
    countryName: "Libya",
    capitalCity: "Tripoli",
    countryFlag: <img src={ly} />,
  },
  {
    id: "madagascar",
    countryName: "Madagascar",
    capitalCity: "Antananarivo",
    countryFlag: <img src={mg} />,
  },
  {
    id: "malawi",
    countryName: "Malawi",
    capitalCity: "Lilongwe",
    countryFlag: <img src={mw} />,
  },
  {
    id: "mali",
    countryName: "Mali",
    capitalCity: "Bamako",
    countryFlag: <img src={ml} />,
  },
  {
    id: "mauritania",
    countryName: "Mauritania",
    capitalCity: "Nouakchott",
    countryFlag: <img src={mr} />,
  },
  {
    id: "morocco",
    countryName: "Morocco",
    capitalCity: "Rabat",
    countryFlag: <img src={ma} />,
  },
  {
    id: "mozambique",
    countryName: "Mozambique",
    capitalCity: "Maputo",
    countryFlag: <img src={mz} />,
  },
  {
    id: "namibia",
    countryName: "Namibia",
    capitalCity: "Windhoek",
    countryFlag: <img src={na} />,
  },
  {
    id: "niger",
    countryName: "Niger",
    capitalCity: "Niamey",
    countryFlag: <img src={ne} />,
  },
  {
    id: "nigeria",
    countryName: "Nigeria",
    capitalCity: "Abuja",
    countryFlag: <img src={ng} />,
  },
  {
    id: "republicOfCongo",
    countryName: "Republic Of The Congo",
    capitalCity: "Brazzaville",
    countryFlag: <img src={cg} />,
  },
  {
    id: "rwanda",
    countryName: "Rwanda",
    capitalCity: "Kigali",
    countryFlag: <img src={rw} />,
  },
  {
    id: "senegal",
    countryName: "Senegal",
    capitalCity: "Dakar",
    countryFlag: <img src={sn} />,
  },
  {
    id: "sierraLeone",
    countryName: "Sierra Leone",
    capitalCity: "Freetown",
    countryFlag: <img src={sl} />,
  },
  {
    id: "somalia",
    countryName: "Somalia",
    capitalCity: "Mogadishu",
    countryFlag: <img src={so} />,
  },
  {
    id: "southAfrica",
    countryName: "South Africa",
    capitalCity: "Cape Town / Pretoria / Bloemfontein",
    countryFlag: <img src={za} />,
  },
  {
    id: "sudan",
    countryName: "Sudan",
    capitalCity: "Khartoum",
    countryFlag: <img src={sd} />,
  },
  {
    id: "southSudan",
    countryName: "South Sudan",
    capitalCity: "Juba",
    countryFlag: <img src={ss} />,
  },
  {
    id: "tanzania",
    countryName: "Tanzania",
    capitalCity: "Dodoma",
    countryFlag: <img src={tz} />,
  },
  {
    id: "togo",
    countryName: "Togo",
    capitalCity: "Lomé",
    countryFlag: <img src={tg} />,
  },
  {
    id: "tunisia",
    countryName: "Tunisia",
    capitalCity: "Tunis",
    countryFlag: <img src={tn} />,
  },
  {
    id: "uganda",
    countryName: "Uganda",
    capitalCity: "Kampala",
    countryFlag: <img src={ug} />,
  },
  {
    id: "westernSahara",
    countryName: "Western Sahara",
    capitalCity: "Laayoune",
    countryFlag: <img src={eh} />,
  },
  {
    id: "zambia",
    countryName: "Zambia",
    capitalCity: "Lusaka",
    countryFlag: <img src={zm} />,
  },
  {
    id: "zimbabwe",
    countryName: "Zimbabwe",
    capitalCity: "Harare",
    countryFlag: <img src={zw} />,
  },
];
export {
  eurovision,
  grandNational,
  formerPlaceNames,
  americanStates,
  englishCounties,
  africanCountries,
  englishCountiesAttributionsArms,
  englishCountiesAttributionsFlags,
};
