import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import LearnTopFiveThreeCols from "./LearnTopFiveThreeCols";
import LearnTopFiveThreeColsHeading from "./LearnTopFiveThreeColsHeading";
import { topFiveSocialMedia } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive
      key={topFive.key}
      col1={topFive.col1}
      col2={topFive.col2}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}
function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col2country={topFive.col2country}
      col3country={topFive.col3country}
      col4={topFive.col4}
    />
  );
}
function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}

function LearnSocialMedia() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Social Media</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Social media is a huge part of the modern world, the most
                followed accounts, watched videos and listened to songs are
                listed below.
              </p>
              <p className="body-text">
                These lists are currently correct as of July 2022, and will be
                updated regularly.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small22.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Watched Youtube Videos
              </h2>
              <div className="sidebar-line"></div>
              {topFiveSocialMedia.mostWatchedYoutubeHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFiveSocialMedia.mostWatchedYoutube.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Twitter Followers
              </h2>
              <div className="sidebar-line"></div>
              {topFiveSocialMedia.mostTwitterFollowersHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFiveSocialMedia.mostTwitterFollowers.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most TikTok Followers
              </h2>
              <div className="sidebar-line"></div>
              {topFiveSocialMedia.mostTikTokFollowersHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFiveSocialMedia.mostTikTokFollowers.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Instagram Followers
              </h2>
              <div className="sidebar-line"></div>
              {topFiveSocialMedia.mostInstagramFollowersHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFiveSocialMedia.mostInstagramFollowers.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Streamed Song on Spotify
              </h2>
              <div className="sidebar-line"></div>
              {topFiveSocialMedia.moststreamedOnSpotifyJuly1st2022Headings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFiveSocialMedia.moststreamedOnSpotifyJuly1st2022.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnSocialMedia;
