import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Alterego from "./Alterego";
import { cocktails } from "../../LearnSpecificCategoryDetailsTwo";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeAlterEgos(c) {
  return <Alterego key={c.id} original={c.cocktail} new={c.ingredients} />;
}

function LearnCocktails() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Cocktails</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Some popular cocktails and their ingredients. There are hundreds
                of cocktails so they have not all been included, but those
                listed are probably the best known ones.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small8.png")}
              alt="cartoon character with blonde hair and orange glasses"
            />
          </div>
          <div className="line-subtitle"></div>

          <div className="alter-ego-list-container">
            {cocktails.map(ArrangeAlterEgos)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnCocktails;
