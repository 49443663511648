import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import { foodAndDrink } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive
      key={topFive.key}
      col1={topFive.col1}
      col2={topFive.col2}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}

function LearnFoodAndDrinkProducers() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">
          The World's Biggest Food and Drink Producers
        </h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The biggest producers of food and drink in the world.
              </p>
              <p className="body-text">All correct as of August 2022.</p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small29.png")}
              alt="cartoon character with black hoody and orange glasses"
            />
          </div>
          <div className="line-subtitle"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 coffee bean producers
              </h2>
              <div className="sidebar-line"></div>
              {foodAndDrink.coffeeProducersHeadings.map(ArrangeTopFiveHeading)}
              {foodAndDrink.coffeeProducers.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Top 5 tea producers</h2>
              <div className="sidebar-line"></div>
              {foodAndDrink.teaProducersHeadings.map(ArrangeTopFiveHeading)}
              {foodAndDrink.teaProducers.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 chocolate exporters by dollar value
              </h2>
              <div className="sidebar-line"></div>
              {foodAndDrink.chocolateProducersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {foodAndDrink.chocolateProducers.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 chocolate consumers kg per capita
              </h2>
              <div className="sidebar-line"></div>
              {foodAndDrink.chocolateConsumersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {foodAndDrink.chocolateConsumers.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 cocoa bean producers
              </h2>
              <div className="sidebar-line"></div>
              {foodAndDrink.cocoaBeanProducersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {foodAndDrink.cocoaBeanProducers.map(ArrangeTopFive)}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnFoodAndDrinkProducers;
