import React from "react";

// import "antd/dist/antd.variable.min.css";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Find from "./pages/Find";
import Learn from "./pages/Learn";
import LearnSport from "./pages/Learning/LearnSport";
import LearnArtAndLiterature from "./pages/Learning/LearnArtAndLiterature";
import LearnSportFootballWorldCup from "./pages/Learning/LearnSportFootballWorldCup";
import LearnSportWimbledon from "./pages/Learning/LearnSportWimbledon";
import LearnArtAndLiteratureShakespeare from "./pages/Learning/LearnArtAndLiteratureShakespeare";
import ScrollToTop from "./ScrollToTop";
import { Route, Routes } from "react-router-dom";
import Practice from "./pages/Practice";
import YourQuizNight from "./pages/YourQuizNight";
import Flashcard from "./pages/Flashcard";
import QuestionBank from "./pages/QuestionBank";
import LearnFilmAndTV from "./pages/Learning/LearnFilmAndTV";
import LearnFilmAndTVJamesBond from "./pages/Learning/LearnFilmAndTVJamesBond";
import LearnAlterEgos from "./pages/Learning/LearnAlterEgos";
import LearnClassicQuestions from "./pages/Learning/LearnClassicQuestions";
import FlagFlashcard from "./pages/FlagFlashcard";
import UnderConstruction from "./pages/UnderConstruction";
import CapitalCitiesFlashcard from "./pages/CapitalCitiesFlashcard";
import Hangman from "./pages/Hangman";
import Thankyou from "./pages/Thankyou";
import GeneralQuizzing from "./pages/GeneralQuizzing";
import Shop from "./pages/Shop";
import PubsDisplay from "./pages/PubsDisplay";
import LearnTwelveDaysOfChristmas from "./pages/Learning/LearnTwelveDaysOfChristmas";
import LearnAmericanPresidents from "./pages/Learning/LearnAmericanPresidents";
import LearnHistoryAndCivilization from "./pages/Learning/LearnHistoryAndCivilization";
import LearnTheBible from "./pages/Learning/LearnTheBible";
import LearnSportingNicknames from "./pages/Learning/LearnSportingNicknames";
import LearnFootballTeamNicknames from "./pages/Learning/LearnFootballTeamNicknames";
import LearnPeriodicTable from "./pages/Learning/LearnPeriodicTable";
import LearnScienceAndNature from "./pages/Learning/LearnScienceAndNature";
import LearnGeography from "./pages/Learning/LearnGeography";
import LearnFlags from "./pages/Learning/LearnFlags";
import LearnCapital from "./pages/Learning/LearnCapital";
import LearnImportantDates from "./pages/Learning/LearnImportantDates";
import LearnLatin from "./pages/Learning/LearnLatin";
import LearnAcronyms from "./pages/Learning/LearnAcronyms";
import PuzzlesAndGames from "./pages/PuzzlesAndGames";
import Wordsearches from "./pages/Wordsearch/Wordsearches";
import Wordsearch from "./pages/Wordsearch/Wordsearch";
import WordsearchEUCaps from "./pages/Wordsearch/WordsearchEUCaps";
import WordsearchAfricanCs from "./pages/Wordsearch/WordsearchAfricanCs";
import WordsearchUSPs from "./pages/Wordsearch/WordseachUSPs";
import WordsearchShakespeareCs from "./pages/Wordsearch/WordsearchShakespeareCs";
import WordsearchPTElements from "./pages/Wordsearch/WordsearchPTElements";
import WordsearchWCWinners from "./pages/Wordsearch/WordsearchWCWinners";
import WordsearchStates from "./pages/Wordsearch/WordsearchStates";
import WordsearchJBVillains from "./pages/Wordsearch/WordsearchJBVillains";
import WordsearchLatinWsAndPs from "./pages/Wordsearch/WordsearchLatinWsAndPs";
import LearnKingAndQueens from "./pages/Learning/LearnKingAndQueens";
import LearnInventors from "./pages/Learning/LearnInventors";
import Minesweeeper from "./pages/Minesweeper";
import Pairs from "./pages/Pairs";
import Wordle from "./pages/Wordle";
import WordleSix from "./pages/WordleSix";
import WordleSeven from "./pages/WordleSeven";
import WordleMenu from "./pages/WordleMenu";
import LearnVitamins from "./pages/Learning/LearnVitamins";
import LearnSportEuros from "./pages/Learning/LearnSportEuros";
import LearnFootballGrounds from "./pages/Learning/LearnFootballGrounds";
import LearnCocktails from "./pages/Learning/LearnCocktails";
import LearnEntertainmentAwards from "./pages/Learning/LearnEntertainmentAwards";
import LearnOlogys from "./pages/Learning/LearnOlogys";
import LearnBritishEntertainmentAwards from "./pages/Learning/LearnBritishEntertainmentAwards";
import LearnArtists from "./pages/Learning/LearnArtists";
import LearnFilmsByDecade from "./pages/Learning/LearnFilmsByDecade";
import LearnMohsHardness from "./pages/Learning/LearnMohsHardness";
import LearnFootballRecords from "./pages/Learning/LearnFootballRecords";
import LearnGeographyRecords from "./pages/Learning/LearnGeographyRecords";
import LearnNatureRecords from "./pages/Learning/LearnNatureRecords";
import LearnPolitics from "./pages/Learning/LearnPolitics";
import LearnSocialMedia from "./pages/Learning/LearnSocialMedia";
import LearnTopTVFilmGames from "./pages/Learning/LearnTopTVFilmGames";
import LearnFoodAndDrinkProducers from "./pages/Learning/LearnFoodAndDrinkProducers";
import LearnLanguageRecords from "./pages/Learning/LearnLanguageRecords";
import FirstLinesGame from "./pages/FirstLinesGame";
import LearnArtAndLiteratureDickens from "./pages/Learning/LearnArtAndLiteratureDickens";
import LearnEurovision from "./pages/Learning/LearnEurovision";
import LearnGrandNational from "./pages/Learning/LearnGrandNational";
import LearnFormerPlaceNames from "./pages/Learning/LearnFormerPlaceNames";
import LearnOccupationalSurnames from "./pages/Learning/LearnOccupationalSurnames";
import LearnHobbyAndJobWords from "./pages/Learning/LearnHobbyAndJobWords";
import USStates from "./pages/USStates";
import LearnUSStates from "./pages/Learning/LearnUSStates";
import LearnEnglishCounties from "./pages/Learning/LearnEnglishCounties";
import EnglishCounties from "./pages/EnglishCounties";
import AfricanCountries from "./pages/AfricanCountries";
import LearnAfricanCountries from "./pages/Learning/LearnAfricanCountries";
import LearnPolygons from "./pages/Learning/LearnPolygons";
import LearnAnnversaries from "./pages/Learning/LearnAnniversaries";
import LearnPreDecimalMoney from "./pages/Learning/LearnPreDecimalMoney";
import LearnMilitaryRanks from "./pages/Learning/LearnMilitaryRanks";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        {/* home page and other navs */}
        {/* <Route exact path="/" element={<UnderConstruction />} /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/learn" element={<Learn />} />
        <Route exact path="/practice" element={<Practice />} />
        <Route exact path="/find-a-quiz-near-me" element={<Find />} />
        <Route exact path="/puzzles-and-games" element={<PuzzlesAndGames />} />
        <Route exact path="/shop" element={<Shop />} />
        {/* <Route exact path="/pubs-display" element={<PubsDisplay />} /> */}
        <Route exact path="/your-quiz-night" element={<YourQuizNight />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/about-us" element={<About />} />
        {/* <Route exact path="/in-the-news-june" element={<InTheNewsJune />} />
        <Route exact path="/in-the-news-april" element={<InTheNewsApril />} />
        <Route exact path="/in-the-news-may" element={<InTheNewsMay />} /> */}
        {/* learn page menu */}
        {/* general quizzing */}
        <Route
          exact
          path="/learn/general-quizzing"
          element={<GeneralQuizzing />}
        />
        <Route
          exact
          path="/learn/general-quizzing/alter-egos"
          element={<LearnAlterEgos />}
        />
        <Route
          exact
          path="/learn/general-quizzing/classic-questions"
          element={<LearnClassicQuestions />}
        />
        <Route
          exact
          path="/learn/general-quizzing/twelve-days-of-christmas"
          element={<LearnTwelveDaysOfChristmas />}
        />
        <Route
          exact
          path="/learn/general-quizzing/acronyms"
          element={<LearnAcronyms />}
        />
        <Route
          exact
          path="/learn/general-quizzing/cocktails"
          element={<LearnCocktails />}
        />
        <Route
          exact
          path="/learn/general-quizzing/ologies"
          element={<LearnOlogys />}
        />
        <Route
          exact
          path="/learn/general-quizzing/social-media"
          element={<LearnSocialMedia />}
        />
        <Route
          exact
          path="/learn/general-quizzing/food-and-drink-producers"
          element={<LearnFoodAndDrinkProducers />}
        />
        <Route
          exact
          path="/learn/general-quizzing/hobby-and-job-words"
          element={<LearnHobbyAndJobWords />}
        />
        <Route
          exact
          path="/learn/general-quizzing/polygons"
          element={<LearnPolygons />}
        />
        <Route
          exact
          path="/learn/general-quizzing/traditional-anniversary-gifts"
          element={<LearnAnnversaries />}
        />
        <Route
          exact
          path="/learn/general-quizzing/pre-decimalisation-money"
          element={<LearnPreDecimalMoney />}
        />
        <Route
          exact
          path="/learn/general-quizzing/military-ranks"
          element={<LearnMilitaryRanks />}
        />

        {/* art & literature */}
        <Route
          exact
          path="/learn/art-and-literature"
          element={<LearnArtAndLiterature />}
        />
        <Route
          exact
          path="/learn/art-and-literature/shakespeare"
          element={<LearnArtAndLiteratureShakespeare />}
        />
        <Route
          exact
          path="/learn/art-and-literature/artists"
          element={<LearnArtists />}
        />
        <Route
          exact
          path="/learn/art-and-literature/opening-lines"
          element={<FirstLinesGame />}
        />
        <Route
          exact
          path="/learn/art-and-literature/dickens"
          element={<LearnArtAndLiteratureDickens />}
        />
        {/* entertainment */}
        <Route exact path="/learn/entertainment" element={<LearnFilmAndTV />} />
        <Route
          exact
          path="/learn/entertainment/james-bond"
          element={<LearnFilmAndTVJamesBond />}
        />
        <Route
          exact
          path="/learn/entertainment/american-entertainment-awards"
          element={<LearnEntertainmentAwards />}
        />
        <Route
          exact
          path="/learn/entertainment/british-entertainment-awards"
          element={<LearnBritishEntertainmentAwards />}
        />
        <Route
          exact
          path="/learn/entertainment/films-by-decade"
          element={<LearnFilmsByDecade />}
        />
        <Route
          exact
          path="/learn/entertainment/top-film-games-tv"
          element={<LearnTopTVFilmGames />}
        />
        <Route
          exact
          path="/learn/entertainment/eurovision"
          element={<LearnEurovision />}
        />
        {/* geography */}
        <Route exact path="/learn/geography" element={<LearnGeography />} />
        <Route exact path="/learn/geography/flags" element={<LearnFlags />} />
        <Route
          exact
          path="/learn/geography/capital-cities"
          element={<LearnCapital />}
        />
        <Route
          exact
          path="/learn/geography/records"
          element={<LearnGeographyRecords />}
        />
        <Route
          exact
          path="/learn/geography/former-place-names"
          element={<LearnFormerPlaceNames />}
        />
        <Route
          exact
          path="/learn/geography/us-states"
          element={<LearnUSStates />}
        />
        <Route
          exact
          path="/learn/geography/english-counties"
          element={<EnglishCounties />}
        />
        <Route
          exact
          path="/learn/geography/african-countries"
          element={<LearnAfricanCountries />}
        />

        {/* history & civilization */}
        <Route
          exact
          path="/learn/history-and-civilization"
          element={<LearnHistoryAndCivilization />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/american-presidents"
          element={<LearnAmericanPresidents />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/the-bible"
          element={<LearnTheBible />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/important-historical-events"
          element={<LearnImportantDates />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/latin-words-and-phrases"
          element={<LearnLatin />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/kings-and-queens"
          element={<LearnKingAndQueens />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/discoveries-and-inventions"
          element={<LearnInventors />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/uk-politics"
          element={<LearnPolitics />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/language-records"
          element={<LearnLanguageRecords />}
        />
        <Route
          exact
          path="/learn/history-and-civilization/occupational-surnames"
          element={<LearnOccupationalSurnames />}
        />
        {/* sport & games */}
        <Route exact path="/learn/sport-and-games" element={<LearnSport />} />
        <Route
          exact
          path="/learn/sport-and-games/football-world-cup"
          element={<LearnSportFootballWorldCup />}
        />
        <Route
          exact
          path="/learn/sport-and-games/football-euros"
          element={<LearnSportEuros />}
        />
        <Route
          exact
          path="/learn/sport-and-games/football-home-grounds"
          element={<LearnFootballGrounds />}
        />
        <Route
          exact
          path="/learn/sport-and-games/sporting-nicknames"
          element={<LearnSportingNicknames />}
        />
        <Route
          exact
          path="/learn/sport-and-games/football-team-nicknames"
          element={<LearnFootballTeamNicknames />}
        />
        <Route
          exact
          path="/learn/sport-and-games/tennis"
          element={<LearnSportWimbledon />}
        />
        <Route
          exact
          path="/learn/sport-and-games/football-records"
          element={<LearnFootballRecords />}
        />
        <Route
          exact
          path="/learn/sport-and-games/grand-national"
          element={<LearnGrandNational />}
        />
        {/* science & nature */}
        <Route
          exact
          path="/learn/science-and-nature"
          element={<LearnScienceAndNature />}
        />
        <Route
          exact
          path="/learn/science-and-nature/the-periodic-table"
          element={<LearnPeriodicTable />}
        />
        <Route
          exact
          path="/learn/science-and-nature/vitamins-and-minerals"
          element={<LearnVitamins />}
        />
        <Route
          exact
          path="/learn/science-and-nature/mohs-hardness-scale"
          element={<LearnMohsHardness />}
        />
        <Route
          exact
          path="/learn/science-and-nature/nature-records"
          element={<LearnNatureRecords />}
        />
        {/* practice */}
        <Route
          exact
          path="/practice/quickfire-questions"
          element={<Flashcard />}
        />
        <Route exact path="/practice/flags-quiz" element={<FlagFlashcard />} />
        <Route
          exact
          path="/practice/capitalcities-quiz"
          element={<CapitalCitiesFlashcard />}
        />
        <Route
          exact
          path="/practice/question-bank"
          element={<QuestionBank />}
        />
        <Route exact path="/practice/us-states-quiz" element={<USStates />} />
        <Route
          exact
          path="/practice/english-counties-quiz"
          element={<LearnEnglishCounties />}
        />
        <Route
          exact
          path="/practice/african-countries-quiz"
          element={<AfricanCountries />}
        />

        {/* page for form submits */}
        <Route exact path="/thankyou" element={<Thankyou />} />

        {/* puzzles & games */}
        <Route
          exact
          path="/puzzles-and-games/wordsearches"
          element={<Wordsearches />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/numbers"
          element={<Wordsearch />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/european-capital-cities"
          element={<WordsearchEUCaps />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/african-countries"
          element={<WordsearchAfricanCs />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/us-presidents"
          element={<WordsearchUSPs />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/shakespeare-characters"
          element={<WordsearchShakespeareCs />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/periodic-table"
          element={<WordsearchPTElements />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/world-cup"
          element={<WordsearchWCWinners />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/us-states"
          element={<WordsearchStates />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/bond-villains"
          element={<WordsearchJBVillains />}
        />
        <Route
          exact
          path="/puzzles-and-games/wordsearches/latin-words-and-phrases"
          element={<WordsearchLatinWsAndPs />}
        />
        <Route exact path="/puzzles-and-games/hangman" element={<Hangman />} />
        <Route exact path="/puzzles-and-games/pairs" element={<Pairs />} />
        <Route
          exact
          path="/puzzles-and-games/word-guess"
          element={<WordleMenu />}
        />
        <Route
          exact
          path="/puzzles-and-games/five-letter-word-guess"
          element={<Wordle />}
        />
        <Route
          exact
          path="/puzzles-and-games/six-letter-word-guess"
          element={<WordleSix />}
        />
        <Route
          exact
          path="/puzzles-and-games/seven-letter-word-guess"
          element={<WordleSeven />}
        />
        <Route
          exact
          path="/puzzles-and-games/minesweeper"
          element={<Minesweeeper />}
        />
      </Routes>
    </div>
  );
}

export default App;
