import React from "react";
import "./css/covered.css";
import "./css/questionbank.css";

function Covered() {
  return (
    <div>
      <div className="covered">click to reveal</div>
    </div>
  );
}

export default Covered;
