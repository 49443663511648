import WordsearchShakespeareCharacters from "./WordsearchShakespeareCharacters";
import HeaderApp from "../HeaderApp";
import "../../css/wordsearch.css";
import "../../css/style.css";
import Footer from "../Footer";

function WordsearchShakespeareCs() {
  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section>
        <WordsearchShakespeareCharacters />
      </section>
      <Footer />
    </div>
  );
}

export default WordsearchShakespeareCs;
