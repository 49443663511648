const sportingNicknames = {
  americanFootball: [
    {
      id: "sn-af1",
      realname: "Elroy Hirsch",
      nickname: "Crazylegs",
    },
    {
      id: "sn-af2",
      realname: "William Perry",
      nickname: "The Refrigerator",
    },
    {
      id: "sn-af3",
      realname: "O. J. Simpson",
      nickname: "The Juice",
    },
  ],
  baseball: [
    {
      id: "sn-base1",
      realname: "Lawrence Berra",
      nickname: "Yogi",
    },
    {
      id: "sn-base2",
      realname: "Joe DiMaggio",
      nickname: "The Yankee Clipper/Joltin' Joe",
    },
    { id: "sn-base3", realname: "Joe Jackson", nickname: "Shoeless" },
    {
      id: "sn-base4",
      realname: "George Herman Ruth",
      nickname: "Babe/The Great Bambino/The Sultan of Swat + about 20 more",
    },
  ],
  basketball: [
    {
      id: "sn-basket1",
      realname: "Earvin Johnson",
      nickname: "Magic",
    },
    {
      id: "sn-basket2",
      realname: "Michael Jordan",
      nickname: "Air",
    },
  ],
  boxing: [
    {
      id: "sn-box1",
      realname: "Nicola Adams",
      nickname: "The Lioness",
    },
    {
      id: "sn-box2",
      realname: "Muhammad Ali",
      nickname: "The Greatest/The Louisville Lip",
    },
    {
      id: "sn-box3",
      realname: "Nigel Benn",
      nickname: "The Dark Destroyer",
    },
    {
      id: "sn-box4",
      realname: "Joe Calzaghe",
      nickname: "The Pride of Wales",
    },
    {
      id: "sn-box5",
      realname: "Oscar De La Hoya",
      nickname: "The Golden Boy",
    },
    {
      id: "sn-box6",
      realname: "Roberto Duran",
      nickname: "Hands Of Stone (Manos De Piedra)",
    },
    {
      id: "sn-box7",
      realname: "Eric Esch",
      nickname: "Butterbean",
    },
    {
      id: "sn-box8",
      realname: "Joe Frazier",
      nickname: "Smokin'",
    },
    { id: "sn-box9", realname: "Tyson Fury", nickname: "The Gypsy King" },
    {
      id: "sn-box10",
      realname: "Arturo Gatti",
      nickname: "Thunder",
    },
    { id: "sn-box11", realname: "Marvin Hagler", nickname: "Marvelous" },
    { id: "sn-box12", realname: "Naseem Hamed", nickname: "Prince" },
    {
      id: "sn-box13",
      realname: "Thomas Hearns & Ricky Hatton",
      nickname: "The Hitman",
    },
    {
      id: "sn-box14",
      realname: "Evander Holyfield",
      nickname: "The Real Deal",
    },
    {
      id: "sn-box15",
      realname: "Bernard Hopkins",
      nickname: "The Executioner/ B-Hop",
    },
    { id: "sn-box16", realname: "Jake Lamotta", nickname: "The Raging Bull" },
    {
      id: "sn-box17",
      realname: "Juan Lazcano",
      nickname: "The Hispanic Causin' Panic",
    },
    { id: "sn-box18", realname: "Vasiliy Lomachenko", nickname: "The Matrix" },
    { id: "sn-box19", realname: "Joe Louis", nickname: "The Brown Bomber" },
    { id: "sn-box20", realname: "Floyd Mayweather", nickname: "Money" },
    {
      id: "sn-box21",
      realname: "Barry McGuigan",
      nickname: "The Clones Cyclone",
    },
    { id: "sn-box22", realname: "Erik Morales", nickname: "El Terrible" },
    {
      id: "sn-box23",
      realname: "Ray Robinson & Ray Leonard",
      nickname: "Sugar",
    },
    { id: "sn-box24", realname: "Jermain Taylor", nickname: "Bad Intentions" },
    { id: "sn-box25", realname: "James Toney", nickname: "Lights Out" },
    { id: "sn-box26", realname: "Mike Tyson", nickname: "Iron/Kid Dynamite" },

    { id: "sn-box27", realname: "Pernell Whitaker", nickname: "Sweet Pea" },
    { id: "sn-box28", realname: "Ronald Wright", nickname: "Winky" },
  ],
  cricket: [
    { id: "sn-cricket1", realname: "Ian Botham", nickname: "Beefy" },
    { id: "sn-cricket2", realname: "Rahul Dravid", nickname: "The Wall" },
    { id: "sn-cricket3", realname: "Andrew Flintoff", nickname: "Freddie" },
    { id: "sn-cricket4", realname: "Allan Lamb", nickname: "Lambie/Legga" },
    { id: "sn-cricket5", realname: "Lasith Malinga", nickname: "Slinga" },
    {
      id: "sn-cricket6",
      realname: "Sachin Tendulkar",
      nickname: "Little Master/ Master Blaster/ God of Cricket",
    },
  ],
  darts: [
    {
      id: "sn-darts1",
      realname: "Martin Adams",
      nickname: "Wolfie",
    },
    {
      id: "sn-darts2",
      realname: "Gary Anderson",
      nickname: "The Flying Scotsman",
    },
    { id: "sn-darts3", realname: "Raymond van Barneveld", nickname: "Barney" },
    {
      id: "sn-darts4",
      realname: "Eric Bristow",
      nickname: "The Crafty Cockney",
    },
    { id: "sn-darts5", realname: "Andy Fordham", nickname: "The Viking" },
    {
      id: "sn-darts6",
      realname: "Michael van Gerwen",
      nickname: "Mighty Mike/MVG",
    },
    { id: "sn-darts7", realname: "Adrian Lewis", nickname: "Jackpot" },
    {
      id: "sn-darts8",
      realname: "John Lowe",
      nickname: "Old Stoneface",
    },
    { id: "sn-darts9", realname: "John Part", nickname: "Darth Maple" },
    { id: "sn-darts10", realname: "Phil Taylor", nickname: "The Power" },
    { id: "sn-darts11", realname: "James Wade", nickname: "The Machine" },
    {
      id: "sn-darts12",
      realname: "Peter Wright",
      nickname: "Snakebite",
    },
  ],
  football: [
    { id: "sn-fball1", realname: "Tony Adams", nickname: "Donkey/ Mr Arsenal" },
    { id: "sn-fball2", realname: "Sergio Aguero", nickname: "Kun" },
    {
      id: "sn-fball3",
      realname: "Nicolas Anelka",
      nickname: "The Incredible Sulk",
    },
    { id: "sn-fball4", realname: "Darren Anderton", nickname: "Sicknote" },
    { id: "sn-fball5", realname: "Cesar Azpilicueta", nickname: "Dave" },
    {
      id: "sn-fball6",
      realname: "Roberto Baggio",
      nickname: "The Divine Ponytail",
    },
    { id: "sn-fball7", realname: "Julio Baptita", nickname: "The Beast" },
    {
      id: "sn-fball8",
      realname: "Marco Van Basten",
      nickname: "The Swan of Utrecht",
    },
    { id: "sn-fball9", realname: "Gabriel Batistuta", nickname: "Batigol" },
    { id: "sn-fball10", realname: "David Beckham", nickname: "Golden Balls" },
    {
      id: "sn-fball11",
      realname: "Dennis Bergkamp",
      nickname: "The Non-Flying Dutchman/ The Iceman/ The Menace",
    },
    { id: "sn-fball12", realname: "George Best", nickname: "The Fifth Beatle" },
    { id: "sn-fball13", realname: "Eric Cantona", nickname: "The King" },
    { id: "sn-fball14", realname: "Jack Charlton", nickname: "The Giraffe" },
    { id: "sn-fball15", realname: "Stan Collymore", nickname: "Stan The Man" },
    { id: "sn-fball16", realname: "Papa Bouba Diop", nickname: "The Wardrobe" },
    {
      id: "sn-fball17",
      realname: "Eusebio",
      nickname: "The Black Panther/ The Black Pearl/ O Rei",
    },
    { id: "sn-fball18", realname: "Sir Alex Ferguson", nickname: "Fergie" },
    {
      id: "sn-fball19",
      realname: "Duncan Ferguson",
      nickname: "Duncan Disorderly/ Big Dunc",
    },
    { id: "sn-fball20", realname: "Paul Gascoigne", nickname: "Gazza" },
    {
      id: "sn-fball21",
      realname: "Andoni Goikoetxea",
      nickname: "The Butcher of Bilbao",
    },
    { id: "sn-fball22", realname: "Ruud Gullit", nickname: "The Black Tulip" },
    { id: "sn-fball23", realname: "Fitz Hall", nickname: "One Size" },
    { id: "sn-fball24", realname: "Ron Harris", nickname: "Chopper" },
    { id: "sn-fball25", realname: "Thierry Henry", nickname: "Titi" },
    {
      id: "sn-fball26",
      realname: "Javier Hernandez",
      nickname: "Chicharito/ Little Pea",
    },
    {
      id: "sn-fball27",
      realname: "Paul Ince",
      nickname: "The Guv'nor",
    },
    {
      id: "sn-fball28",
      realname: "Kevin Keegan",
      nickname: "Mighty Mouse (Machtig Maus)",
    },
    {
      id: "sn-fball29",
      realname: "Stanley Matthews",
      nickname: "The Wizard of the Dribble",
    },
    {
      id: "sn-fball30",
      realname: "Steve McClaren",
      nickname: "Wally with the Brolly",
    },
    { id: "sn-fball31", realname: "Per Mertesacker", nickname: "BFG" },
    {
      id: "sn-fball32",
      realname: "Leo Messi",
      nickname: "La Pulga (The Flea)",
    },
    {
      id: "sn-fball33",
      realname: "Jose Mourinho",
      nickname: "The Special One",
    },
    {
      id: "sn-fball34",
      realname: "Edson Arantes do Nascimento",
      nickname: "Pele",
    },
    { id: "sn-fball35", realname: "Ray Parlour", nickname: "The Romford Pele" },
    { id: "sn-fball36", realname: "Stuart Pearce", nickname: "Psycho" },
    { id: "sn-fball37", realname: "David Platt", nickname: "Tintin" },
    {
      id: "sn-fball38",
      realname: "Carlos Puyol",
      nickname: "Tarzan/ Lionheart",
    },
    { id: "sn-fball39", realname: "Robinho", nickname: "Little Robin" },
    { id: "sn-fball40", realname: "David Rocastle", nickname: "Rocky" },
    { id: "sn-fball41", realname: "Cristiano Ronaldo", nickname: "CR7" },
    {
      id: "sn-fball42",
      realname: "Ronaldo",
      nickname: "El Fenomeno (The Phenomenon)/ R9",
    },
    {
      id: "sn-fball43",
      realname: "Tomas Rosicky",
      nickname: "The Little Mozart",
    },
    {
      id: "sn-fball44",
      realname: "Neil Ruddock",
      nickname: "Razor",
    },
    {
      id: "sn-fball45",
      realname: "Peter Schmeichel",
      nickname: "The Great Dane",
    },
    {
      id: "sn-fball46",
      realname: "Gilberto Silva",
      nickname: "The Invisible Wall",
    },
    {
      id: "sn-fball47",
      realname: "Emile Smith-Rowe",
      nickname: "The Croydon De Bruyne",
    },
    {
      id: "sn-fball48",
      realname: "Ole Gunnar Solskjaer",
      nickname: "The Baby-faced Assassin",
    },
    {
      id: "sn-fball49",
      realname: "Givanildo Vieira De Souza",
      nickname: "Hulk",
    },
    {
      id: "sn-fball50",
      realname: "Massimo Taibi",
      nickname: "The Bilnd Venetian",
    },
    { id: "sn-fball51", realname: "John Terry", nickname: "JT" },
    {
      id: "sn-fball52",
      realname: "Fernando Torres",
      nickname: "El Nino",
    },
    {
      id: "sn-fball53",
      realname: "Patrick Viera",
      nickname: "Paddy Long Legs",
    },
    {
      id: "sn-fball54",
      realname: "Neil Warnock",
      nickname: "Colin",
    },
    {
      id: "sn-fball55",
      realname: "Arsene Wenger",
      nickname: "La Professeur",
    },
    {
      id: "sn-fball56",
      realname: "Zinedine Zidane",
      nickname: "Zizou",
    },
  ],
  trackAndField: [
    {
      id: "sn-taf1",
      realname: "Florence Delorez Griffith Joyner",
      nickname: "Flo-Jo",
    },
  ],
  mma: [
    { id: "sn-mma1", realname: "Quinton Jackson", nickname: "Rampage" },
    { id: "sn-mma2", realname: "Jon Jones", nickname: "Bones" },
    { id: "sn-mma3", realname: "Anderson Silva", nickname: "The Spider" },
  ],
  iceHockey: [
    {
      id: "sn-ih1",
      realname: "Wayne Gretzky",
      nickname: "The Great One",
    },
    {
      id: "sn-ih2",
      realname: "Gordie Howe",
      nickname: "Mr Hockey",
    },
    {
      id: "sn-ih3",
      realname: "Mario Lemieux",
      nickname: "Super",
    },
    {
      id: "sn-ih4",
      realname: "Maurice Richard",
      nickname: "The Rocket",
    },
  ],
  golf: [
    {
      id: "sn-gold1",
      realname: "Phil Mickelson",
      nickname: "Big Phil",
    },
    {
      id: "sn-golf2",
      realname: "Colin Montgomerie",
      nickname: "Mrs Doubtfire/ Monty",
    },
    {
      id: "sn-golf3",
      realname: "Jack Nicklaus",
      nickname: "The Golden Bear",
    },
    {
      id: "sn-golf4",
      realname: "Eldrick Woods",
      nickname: "Tiger",
    },
  ],
  snooker: [
    {
      id: "sn-snooker1",
      realname: "Marco Fu",
      nickname: "Hong Kong Fuey",
    },
    {
      id: "sn-snooker2",
      realname: "Dave Gilbert",
      nickname: "The Angry Farmer",
    },
    {
      id: "sn-snooker3",
      realname: "Alex Higgins",
      nickname: "The Hurricane",
    },
    {
      id: "sn-snooker4",
      realname: "John Higgins",
      nickname: "The Wizard of Wishaw",
    },
    {
      id: "sn-snooker5",
      realname: "Ronnie O'Sullivan",
      nickname: "The Rocket",
    },
    {
      id: "sn-snooker6",
      realname: "Ray Reardon",
      nickname: "Dracula",
    },
    {
      id: "sn-snooker7",
      realname: "Jimmy White",
      nickname: "The Whirlwind",
    },
  ],
};

const kingsAndQueens = {
  monarchsOfEngland: {
    houseOfWessex: {
      kingsAndQueensName: "House of Wessex",
      kingsAndQueensmonarchs: [
        "Alfred the Great (871-899)",
        "Edward the Elder (899-924)",
        "AEthelstan (924-939)",
        "Edmund the Magnificent (939-946)",
        "Eadred (946-955)",
        "Eadwig (955-959)",
        "Edgar the Peaceable (959-975)",
        "Edward the Martyr (975-978)",
        "AEthelred II the Unready (978-1016)",
        "Edmund II Ironside (1016)",
      ],
    },
    danishLine: {
      kingsAndQueensName: "Danish Line",
      kingsAndQueensmonarchs: [
        "Sweyn Forkbeard (1013)",
        "Canute (Cnut the Great) (1016-1035)",
        "Harold I Harefoot (1035-1040)",
        "Harthacnut (1040-1042)",
      ],
    },
    houseOfWessexRestored: {
      kingsAndQueensName: "House of Wessex, Restored",
      kingsAndQueensmonarchs: [
        "Edward the Confessor (1042-1066)",
        "Harold II Godwinson (1066)",
      ],
    },
    normanLine: {
      kingsAndQueensName: "Norman Line",
      kingsAndQueensmonarchs: [
        "William I the Conqueror (1066-1087)",
        "William II Rufus (1087-1100)",
        "Henry I Beauclerc (1100-1135)",
        "Stephen (1135-1154)",
        "Empress Matilda (1141) (Never formally declared Queen of England)",
      ],
    },
    plantagenetAnjouLine: {
      kingsAndQueensName: "Plantagenet/Anjou Line",
      kingsAndQueensmonarchs: [
        "Henry II Curtmantle (1154-1189)",
        "Richard I the Lionheart (1189-1199)",
        "John Lackland (1199-1216)",
        "Louis the Lion (Louis VIII of France) (Disputed) (1216-1217)",
        "Henry III (1216-1272)",
        "Edward I Longshanks (1272-1307)",
        "Edward II (1307 - deposed 1327)",
        "Edward III (1327-1377)",
        "Richard II (1377 - deposed 1399)",
      ],
    },
    lancasterLine: {
      kingsAndQueensName: "Lancaster Line",
      kingsAndQueensmonarchs: [
        "Henry IV Bolingbroke (1399-1413)",
        "Henry V (1413-1422)",
        "Henry VI (1422 - deposed 1461, 1470-1471)",
      ],
    },
    yorkLine: {
      kingsAndQueensName: "York Line",
      kingsAndQueensmonarchs: [
        "Edward IV (1461-1470, 1471-1483)",
        "Edward V (1483)",
        "Richard III Crookback (1483-1485)",
      ],
    },
    houseOfTudor: {
      kingsAndQueensName: "House of Tudor",
      kingsAndQueensmonarchs: [
        "Henry VII Tudor (1485-1509)",
        "Henry VIII (1509-1547)",
        "Edward VI (1547-1553)",
        "Lady Jane Grey (1553) (only 9 days)",
        "Mary I (Bloody Mary) (1553-1558)",
        "Philip (Jure Uxoris) (1554-1558) - This meant he could rule with his wife Mary I only until her death, then he lost his claim to the throne",
        "Elizabeth I (1558-1603)",
      ],
    },
  },
  monarchsOfGreatBritain: {
    houseofStuart: {
      kingsAndQueensName: "House of Stuart",
      kingsAndQueensmonarchs: ["James I (1603-1625)", "Charles I (1625-1649)"],
    },
    theCommonwealth: {
      kingsAndQueensName: "The Commonwealth",
      kingsAndQueensmonarchs: [
        "Oliver Cromwell (1649-1658)",
        "Richard Cromwell (1658-1659)",
      ],
    },
    houseOfStuartRestored: {
      kingsAndQueensName: "House of Stuart, Restored",
      kingsAndQueensmonarchs: [
        "Charles II (1660-1685)",
        "James II (1685-1688)",
      ],
    },
    houseOfOrangeAndStuart: {
      kingsAndQueensName: "House of Orange and Stuart",
      kingsAndQueensmonarchs: ["William III (1689-1702) & Mary II (1689-1694)"],
    },
    houseOfStuart: {
      kingsAndQueensName: "House of Stuart",
      kingsAndQueensmonarchs: ["Anne (1702-1714)"],
    },
  },
  britishMonarchsAfterUnionEandS: {
    houseOfBrunswick: {
      kingsAndQueensName: "House of Brunswick, Hanover Line",
      kingsAndQueensmonarchs: [
        "George I (1714-1727)",
        "George II (1727-1760)",
        "George III (1760-1820)",
        "George IV (1820-1830)",
        "William IV (1830-1837)",
        "Victoria (1837-1901)",
      ],
    },
    houseOfSaxeCoburgGotha: {
      kingsAndQueensName: "House of Saxe-Coburg-Gotha",
      kingsAndQueensmonarchs: ["Edward VII (1901-1910)"],
    },
    houseOfWindsor: {
      kingsAndQueensName: "House of Windsor",
      kingsAndQueensmonarchs: [
        "George V (1910-1936)",
        "Edward VIII (1936 - abdicated 1936)",
        "George VI (1936-1952)",
        "Elizabeth II (1952-2022)",
        "Charles III (2022 - present)",
      ],
    },
  },
};

const ologys = [
  {
    id: "Anthropology",
    ology: "Anthropology",
    studyOf: "Humans",
  },
  {
    id: "Apiology",
    ology: "Apiology",
    studyOf: "Bees",
  },
  {
    id: "Arachnology",
    ology: "Arachnology",
    studyOf: "Arachnids (spider, scorpions etc)",
  },
  {
    id: "Archeology",
    ology: "Archeology",
    studyOf: "Human activity through material remains",
  },
  {
    id: "Audiology",
    ology: "Audiology",
    studyOf: "Hearing",
  },
  {
    id: "Bacteriolog",
    ology: "Bacteriology",
    studyOf: "Bacteria",
  },
  {
    id: "Biology",
    ology: "Biology",
    studyOf: "Living things",
  },
  {
    id: "Cardiology",
    ology: "Cardiology",
    studyOf: "The Heart",
  },
  {
    id: "Climatology",
    ology: "Climatology",
    studyOf: "Climate",
  },
  {
    id: "Coleopterology",
    ology: "Coleopterology",
    studyOf: "Beetles",
  },
  {
    id: "Craniology",
    ology: "Craniology",
    studyOf: "The skull",
  },
  {
    id: "Criminology",
    ology: "Criminology",
    studyOf: "Crime",
  },
  {
    id: "Cynology",
    ology: "Cynology",
    studyOf: "Dogs",
  },
  {
    id: "Dendrochronology",
    ology: "Dendrochronology",
    studyOf: "The age of trees and their rings",
  },
  {
    id: "Dendrology",
    ology: "Dendrology",
    studyOf: "Trees",
  },
  {
    id: "Dermatology",
    ology: "Dermatology",
    studyOf: "The Skin",
  },
  {
    id: "Dipterology",
    ology: "Dipterology",
    studyOf: "Flies",
  },
  {
    id: "Ecology",
    ology: "Ecology",
    studyOf: "The relationships between living organisms and their environment",
  },
  {
    id: "Entomology",
    ology: "Entomology",
    studyOf: "Insects",
  },
  {
    id: "Epidemiology",
    ology: "Epidemiology",
    studyOf: "The origin and spread of diseases",
  },
  {
    id: "Felinology",
    ology: "Felinology",
    studyOf: "Cats",
  },
  {
    id: "Formicology",
    ology: "Formicology",
    studyOf: "Ants",
  },
  {
    id: "Geology",
    ology: "Geology",
    studyOf: "The Earth",
  },
  {
    id: "Gerontology",
    ology: "Gerontology",
    studyOf: "Old age",
  },
  {
    id: "Gynecology",
    ology: "Gynecology",
    studyOf: "Medicine relating to women",
  },
  {
    id: "Hematology",
    ology: "Hematology",
    studyOf: "Blood",
  },
  {
    id: "Heliology",
    ology: "Heliology",
    studyOf: "The Sun",
  },
  {
    id: "Hepatology",
    ology: "Hepatology",
    studyOf: "The liver",
  },
  {
    id: "Hippology",
    ology: "Hippology",
    studyOf: "Horses",
  },
  {
    id: "Histology",
    ology: "Histology",
    studyOf: "Living tissues",
  },
  {
    id: "Hydrology",
    ology: "Hydrology",
    studyOf: "Water",
  },
  {
    id: "Ichthyology",
    ology: "Ichthyology",
    studyOf: "Fish",
  },
  {
    id: "Immunology",
    ology: "Immunology",
    studyOf: "The immune system",
  },
  {
    id: "Lepidopterology",
    ology: "Lepidopterology",
    studyOf: "Butterflies and moths",
  },
  {
    id: "Lithology",
    ology: "Lithology",
    studyOf: "Rocks",
  },
  {
    id: "Meteorology",
    ology: "Meteorology",
    studyOf: "Weather",
  },
  {
    id: "Microbiology",
    ology: "Microbiology",
    studyOf: "Micro-organisms",
  },
  {
    id: "Mycology",
    ology: "Mycology",
    studyOf: "Fungi",
  },
  {
    id: "Nephology",
    ology: "Nephology",
    studyOf: "Clouds",
  },
  {
    id: "Nephrology",
    ology: "Nephrology",
    studyOf: "The kidneys",
  },
  {
    id: "Neurology",
    ology: "Neurology",
    studyOf: "Nerves",
  },
  {
    id: "Odontology",
    ology: "Odontology",
    studyOf: "The teeth",
  },
  {
    id: "Oncology",
    ology: "Oncology",
    studyOf: "Cancer",
  },
  {
    id: "oology",
    ology: "Oology",
    studyOf: "Eggs",
  },
  {
    id: "Ophthalmology",
    ology: "Ophthalmology",
    studyOf: "The eyes",
  },
  {
    id: "Ornithology",
    ology: "Ornithology",
    studyOf: "Birds",
  },
  {
    id: "Otology",
    ology: "Otology",
    studyOf: "The ear",
  },
  {
    id: "Paleontology",
    ology: "Paleontology",
    studyOf: "Fossils of ancient life",
  },
  {
    id: "Parasitology",
    ology: "Parasitology",
    studyOf: "Parasites",
  },
  {
    id: "Pathology",
    ology: "Pathology",
    studyOf: "Illness",
  },
  {
    id: "Pharmacology",
    ology: "Pharmacology",
    studyOf: "Drugs",
  },
  {
    id: "Phlebology",
    ology: "Phlebology",
    studyOf: "The venous system",
  },
  {
    id: "Physiology",
    ology: "Physiology",
    studyOf: "The functions of living organisms",
  },
  {
    id: "Phytology",
    ology: "Phytology",
    studyOf: "Plants (botany)",
  },
  {
    id: "Psychology",
    ology: "Psychology",
    studyOf: "Mental processes in living creatures",
  },
  {
    id: "Radiology",
    ology: "Radiology",
    studyOf: "Rays - usually ionizing radiation",
  },
  {
    id: "Rhinology",
    ology: "Rhinology",
    studyOf: "The nose",
  },
  {
    id: "Seismology",
    ology: "Seismology",
    studyOf: "Earthquakes",
  },
  {
    id: "Selenology",
    ology: "Selenology",
    studyOf: "The moon",
  },
  {
    id: "Sociology",
    ology: "Sociology",
    studyOf: "Society",
  },
  {
    id: "Somnology",
    ology: "Somnology",
    studyOf: "Sleep",
  },
  {
    id: "Toxicology",
    ology: "Toxicology",
    studyOf: "Poisons",
  },
  {
    id: "Virology",
    ology: "Virology",
    studyOf: "Viruses",
  },
  {
    id: "Vexillology",
    ology: "Vexillology",
    studyOf: "Flags",
  },
  {
    id: "Xylology",
    ology: "Xylology",
    studyOf: "Wood",
  },
  {
    id: "Zoology",
    ology: "Zoology",
    studyOf: "Animals",
  },
];

export { sportingNicknames, kingsAndQueens, ologys };
