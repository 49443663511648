const topFivesGeography = {
  topRiversHeadings: [
    {
      key: "topRivers",
      col1: "Continent",
      col2: "1st",
      col3: "2nd",
      col4: "3rd",
    },
  ],
  topRivers: [
    {
      key: "topRiversAfrica",
      col1: "Africa",
      col2: "Nile",
      col3: "Congo",
      col4: "Niger",
    },
    {
      key: "topRiversAsia",
      col1: "Asia",
      col2: "Yangtze",
      col3: "Yenisei",
      col4: "Yellow River",
    },
    {
      key: "topRiversAustralia",
      col1: "Australia",
      col2: "Murray",
      col3: "Upper Murray",
      col4: "Murrumbidgee",
    },
    {
      key: "topRiversEurope",
      col1: "Europe",
      col2: "Volga",
      col3: "Danube",
      col4: "Ural",
    },
    {
      key: "topRiversNorthAmerica",
      col1: "North America",
      col2: "Missouri",
      col3: "Mississippi",
      col4: "Yukon",
    },
    {
      key: "topRiversSouthAmerica",
      col1: "South America",
      col2: "Amazon",
      col3: "Paraná",
      col4: "Madeira",
    },
  ],
  topRiversWorldHeadings: [
    {
      key: "topRiversWorld",
      col1: "Rank",
      col2: "Name",
    },
  ],
  topRiversWorld: [
    {
      key: "topRivers1stNile",
      col1: "1st",
      col2: "Nile",
    },
    {
      key: "topRivers2ndAmazon",
      col1: "2nd",
      col2: "Amazon",
    },
    {
      key: "topRivers3rdYangtze",
      col1: "3rd",
      col2: "Yangtze",
    },
    {
      key: "topRivers4thMissouri",
      col1: "4th",
      col2: "Missouri",
    },
    {
      key: "topRivers5thYenisei",
      col1: "5th",
      col2: "Yenisei",
    },
  ],
  topMountains: {
    topMountainsHeadings: [
      {
        key: "topMountains",
        col1: "Name",
        col2: "Height (m)",
        col3: "Country",
        col4: "Mountain Range",
      },
    ],
    topMountainsAntarctica: [
      {
        key: "vinsonantarctica1",
        col1: "Vinson",
        col2: "4892",
        col3: "n/a",
        col4: "Ellsworth Mountains",
      },
      {
        key: "erebusAntarctica2",
        col1: "Erebus",
        col2: "3794",
        col3: "Ross Island",
        col4: "n/a",
      },
      {
        key: "SipleAntarctica3",
        col1: "Siple",
        col2: "3110",
        col3: "Siple Island",
        col4: "n/a",
      },
    ],
    topMountainsAfrica: [
      {
        key: "kilimanjaroAfrica1",
        col1: "Kilimanjaro",
        col2: "5895",
        col3: "Tanzania",
        col4: "n/a",
      },
      {
        key: "kenyaAfrica2",
        col1: "Kenya",
        col2: "5199",
        col3: "Kenya",
        col4: "n/a",
      },
      {
        key: "StanleyAfrica3",
        col1: "Stanley",
        col2: "5109",
        col3: "Uganda & Democratic Republic of Congo",
        col4: "Rwenzori",
      },
    ],
    topMountainsAsia: [
      {
        key: "EverestAsia1",
        col1: "Everest",
        col2: "8848",
        col3: "China & Nepal",
        col4: "Himalayas",
      },
      {
        key: "K2Asia2",
        col1: "K2",
        col2: "8611",
        col3: "China & Pakistan",
        col4: "Himalayas",
      },
      {
        key: "KangchenjungaAsia3",
        col1: "Kangchenjunga",
        col2: "8586",
        col3: "India & Nepal",
        col4: "Himalayas",
      },
    ],
    topMountainsAustralia: [
      {
        key: "KosciuszkoAus1",
        col1: "Kosciuszko",
        col2: "2228",
        col3: "Australia",
        col4: "Snowy Mountains - Austalian Alps",
      },
      {
        key: "TownsendAus2",
        col1: "Townsend",
        col2: "2209",
        col3: "Australia",
        col4: "Snowy Mountains - Austalian Alps",
      },
      {
        key: "TwynamAus3",
        col1: "Twynam",
        col2: "2195",
        col3: "Australia",
        col4: "Snowy Mountains - Austalian Alps",
      },
    ],
    topMountainsEurope: [
      {
        key: "ElbrusEurope1",
        col1: "Elbrus",
        col2: "5642",
        col3: "Russia",
        col4: "Caucasus",
      },
      {
        key: "Dykh-TauEurope2",
        col1: "Dykh-Tau",
        col2: "5205",
        col3: "Russia",
        col4: "Caucasus",
      },
      {
        key: "ShkharaEurope3",
        col1: "Shkhara",
        col2: "5201",
        col3: "Georgia",
        col4: "Caucasus",
      },
    ],
    topMountainsNorthAmerica: [
      {
        key: "DenaliNA1",
        col1: "Denali",
        col2: "6194",
        col3: "Alaska",
        col4: "Alaska Range",
      },
      {
        key: "LoganNA2",
        col1: "Logan",
        col2: "5959",
        col3: "Canada",
        col4: "Saint Elias",
      },
      {
        key: "OrizabaNA3",
        col1: "Orizaba",
        col2: "5636",
        col3: "Mexico",
        col4: "Sierra Nevada",
      },
    ],
    topMountainsSouthAmerica: [
      {
        key: "AconaguaSA1",
        col1: "Aconagua",
        col2: "6961",
        col3: "Argentina",
        col4: "Andes",
      },
      {
        key: "OjosdelSaladoSA2",
        col1: "Ojos del Salado",
        col2: "6893",
        col3: "Argentina / Chile",
        col4: "Andes",
      },
      {
        key: "Monte PissisSA1",
        col1: "Monte Pissis",
        col2: "6793",
        col3: "Argentina",
        col4: "Andes",
      },
    ],
    topMountainsWorldHeadings: [
      {
        key: "topMountainsWorld",
        col1: "Rank",
        col2: "Name",
      },
    ],
    topMountainsWorld: [
      {
        key: "topMountains1stEverest",
        col1: "1st",
        col2: "Everest",
      },
      {
        key: "topMountains2ndK2",
        col1: "2nd",
        col2: "K2",
      },
      {
        key: "topMountains3rdKangchenjunga",
        col1: "3rd",
        col2: "Kangchenjunga",
      },
      {
        key: "topMountains4thLhotse",
        col1: "4th",
        col2: "Lhotse",
      },

      {
        key: "topMountains5thMakalu",
        col1: "5th",
        col2: "Makalu",
      },
    ],
  },
  topLakes: {
    topLakesWorldHeadings: [
      {
        key: "topLakesWorld",
        col1: "Lake Name",
        col2: "Surface Area (km2)",
        col3: "Continent",
      },
    ],
    topLakesWorld: [
      {
        key: "CaspianSea",
        col1: "Caspian Sea",
        col2: "371000",
        col3: "Europe/Asia",
      },
      {
        key: "Superior",
        col1: "Superior",
        col2: "82100",
        col3: "North America",
      },
      {
        key: "Victoria",
        col1: "Victoria",
        col2: "68870",
        col3: "Africa",
      },
      {
        key: "Huron",
        col1: "Huron",
        col2: "59600",
        col3: "North America",
      },
      {
        key: "Michigan",
        col1: "Michigan",
        col2: "58000",
        col3: "North America",
      },
    ],
  },
  topAnimals: {
    fastestAnimalHeadings: [
      { key: "FastestAnimals", col1: "Animal", col2: "Speed (mph)" },
    ],
    fastestLand: [
      {
        key: "Cheetah68",
        col1: "Cheetah",
        col2: "68-75",
      },
      {
        key: "Pronghorn61",
        col1: "Pronghorn",
        col2: "61",
      },
      {
        key: "Springbok55",
        col1: "Springbok",
        col2: "55",
      },
      {
        key: "QuarterHorse54",
        col1: "Quarter Horse",
        col2: "54",
      },
      {
        key: "Wildebeast50",
        col1: "Wildebeast",
        col2: "50",
      },
    ],
    fastestFlying: [
      {
        key: "PeregrineFalcon242",
        col1: "Peregrine Falcon",
        col2: "242",
      },
      {
        key: "GoldenEagle150",
        col1: "Golden Eagle",
        col2: "150-200",
      },
      {
        key: "White-throatedneedletail105",
        col1: "White-throated needletail",
        col2: "105",
      },
      {
        key: "Eurasianhobby100",
        col1: "Eurasian hobby",
        col2: "100",
      },
      {
        key: "Frigatebird95",
        col1: "Frigatebird",
        col2: "95",
      },
    ],
  },
  topHeaviest: {
    heaviestAnimalHeadings: [
      { key: "heaviestAnimals", col1: "Animal", col2: "Mass (tonnes)" },
    ],
    heaviestWorld: [
      {
        key: "BlueWhale110",
        col1: "Blue whale",
        col2: "110",
      },
      {
        key: "NorthPacificrightwhale60",
        col1: "North Pacific right whale",
        col2: "60",
      },
      {
        key: "Southernrightwhale58",
        col1: "Southern right whale",
        col2: "58",
      },
      {
        key: "Finwhale57",
        col1: "Fin whale",
        col2: "57",
      },
      {
        key: "BowheadWhale54.5",
        col1: "Bowhead whale",
        col2: "54.5",
      },
    ],
    heaviestLand: [
      {
        key: "AfricanBushElephant4.9",
        col1: "African Bush Elephant",
        col2: "4.9",
      },
      {
        key: "Asianelephant4.15",
        col1: "Asian elephant",
        col2: "4.15",
      },
      {
        key: "Africanforestelephant2.7",
        col1: "African forest elephant",
        col2: "2.7",
      },
      {
        key: "Whiterhinoceros2",
        col1: "White rhinoceros",
        col2: "2",
      },
      {
        key: "Indianrhinoceros2",
        col1: "Indian rhinoceros",
        col2: "1.9",
      },
    ],
    heaviestFish: [
      {
        key: "WhaleShark21.5",
        col1: "Whale Shark",
        col2: "21.5",
      },
      {
        key: "BaskingShark4.2",
        col1: "Basking Shark",
        col2: "4.2",
      },
      {
        key: "GreatWhiteShark3.34",
        col1: "Great White Shark",
        col2: "3.34",
      },
      {
        key: "TigerShark3.11",
        col1: "Tiger Shark",
        col2: "3.11",
      },
      {
        key: "GiantOceanMantaRay3",
        col1: "Giant Ocean Manta Ray",
        col2: "3",
      },
    ],
  },
  topCities: {
    cityPopulatedHeadings: [
      {
        key: "densleyPopulated",
        col1: "City",
        col2: "Country",
        col3: "Persons Per SqKm",
      },
    ],
    densleyPopulated: [
      {
        key: "Manila1",
        col1: "Manila",
        col2: "Phillipines",
        col3: 46178,
      },
      {
        key: "Pateros2",
        col1: "Pateros",
        col2: "Phillipines",
        col3: 36447,
      },
      {
        key: "Mandaluyong3",
        col1: "Mandaluyong",
        col2: "Phillipines",
        col3: 34925,
      },
      {
        key: "Baghdad4",
        col1: "Baghdad",
        col2: "Iraq",
        col3: 32874,
      },
      {
        key: "Mumbai5",
        col1: "Mumbai",
        col2: "India",
        col3: 32303,
      },
    ],
    cityPopulationHeadings: [
      {
        key: "densleyPopulated",
        col1: "City",
        col2: "Country",
        col3: "Population",
      },
    ],
    populationSize: [
      {
        key: "Tokyo1",
        col1: "Tokyo",
        col2: "Japan",
        col3: "37.4m",
      },
      {
        key: "Delhi2",
        col1: "Delhi",
        col2: "India",
        col3: "29.4m",
      },
      {
        key: "Shanghai3",
        col1: "Shanghai",
        col2: "China",
        col3: "26.3m",
      },
      {
        key: "SaoPaulo4",
        col1: "Sao Paulo",
        col2: "Brazil",
        col3: "21.8m",
      },
      {
        key: "MexicoCity5",
        col1: "Mexico City",
        col2: "Mexico",
        col3: "21.7m",
      },
    ],
    largestAreaHeadings: [
      {
        key: "largestArea",
        col1: "City",
        col2: "Country",
        col3: "Land Area (SqMiles)",
      },
    ],
    largestArea: [
      {
        key: "NewYork1",
        col1: "New York - Newark",
        col2: "USA",
        col3: 4669,
      },
      {
        key: "Boston2",
        col1: "Boston - Providence",
        col2: "USA",
        col3: 3683,
      },
      {
        key: "Tokyo2",
        col1: "Tokyo",
        col2: "Japan",
        col3: 3178,
      },
      {
        key: "Atlanta4",
        col1: "Atlanta",
        col2: "USA",
        col3: 2857,
      },
      {
        key: "Chicago3",
        col1: "Chicago",
        col2: "USA",
        col3: 2705,
      },
    ],
    newestStatesHeadings: [
      {
        key: "newestStates",
        col1: "State",
        col2: "Number",
      },
    ],
    newestStates: [
      {
        key: "Hawaii1",
        col1: "Hawaii",
        col2: 50,
      },
      {
        key: "Alaska2",
        col1: "Alaska",
        col2: 49,
      },
      {
        key: "Arizona3",
        col1: "Arizona",
        col2: 48,
      },
      {
        key: "NewMexico4",
        col1: "New Mexico",
        col2: 47,
      },
      {
        key: "Oklahoma5",
        col1: "Oklahoma",
        col2: 46,
      },
    ],
  },
};

const topFivesSport = {
  football: {
    topWorldCup: {
      lastWorldCupWinnersHeadings: [
        { key: "lastWorldCupWinners", col2: "Winner", col1: "Year" },
      ],
      lastWorldCupWinners: [
        { key: "Argentina2022", col2: "Argentina", col1: "2022" },
        { key: "France2018", col2: "France", col1: "2018" },
        { key: "Germany2014", col2: "Germany", col1: "2014" },
        { key: "Spain2010", col2: "Spain", col1: "2010" },
        { key: "Italy2006", col2: "Italy", col1: "2006" },
      ],
      lastWorldCupHostsHeadings: [
        { key: "lastWorldCupHosts", col2: "Host", col1: "Year" },
      ],
      lastWorldCupHosts: [
        { key: "Qatar2022", col2: "Qatar", col1: "2022" },
        { key: "Russia2018", col2: "Russia", col1: "2018" },
        { key: "Brazil2014", col2: "Brazil", col1: "2014" },
        { key: "SouthAfrica2010", col2: "South Africa", col1: "2010" },
        { key: "Germany2006", col2: "Germany", col1: "2006" },
      ],
      mostWorldCupWinsHeadings: [
        { key: "mostWorldCupWins", col2: "Wins", col1: "Country" },
      ],
      mostWorldCupWins: [
        {
          key: "Brazil5Times",
          col1: "Brazil",
          col2: 5,
        },
        { key: "Germany4Times", col1: "Germany", col2: 4 },
        { key: "Italy4Times", col1: "Italy", col2: 4 },
        { key: "Argentina3Times", col1: "Argentina", col2: 3 },
        { key: "FranceTwice", col1: "France", col2: 2 },
        { key: "UruguayTwice", col1: "Uruguay", col2: 2 },
      ],
      youngestWorldCupAppHeadings: [
        {
          key: "youngestWorldCupApp",
          col1: "Name",
          col2: "Age",
          col3: "Country",
        },
      ],
      youngestWorldCupApp: [
        {
          key: "NormanWhitesideYoung",
          col1: "Norman Whiteside",
          col2: "17y 40d",
          col3: "Northern Ireland",
        },
        {
          key: "SamuelEtooYoung",
          col1: "Samuel Eto'o",
          col2: "17y 98d",
          col3: "Cameroon",
        },
        {
          key: "FemiOpatunmiYoung",
          col1: "Femi Opabunmi",
          col2: "17y 100d",
          col3: "Nigeria",
        },
        {
          key: "SalomonOlembeYoung",
          col1: "Salomon Olembé",
          col2: "17y 184d",
          col3: "Cameroon",
        },
        {
          key: "PeleYoung",
          col1: "Pelé",
          col2: "17y 234d",
          col3: "Brazil",
        },
      ],
      oldestWorldCupAppHeadings: [
        {
          key: "oldestWorldCupApp",
          col1: "Name",
          col2: "Age",
          col3: "Country",
        },
      ],
      oldestWorldCupApp: [
        {
          key: "EssamEl-HadaryOld",
          col1: "Essam El-Hadary",
          col2: "45y 161d",
          col3: "Egypt",
        },
        {
          key: "FarydMondragónOld",
          col1: "Faryd Mondragón",
          col2: "43y 3d",
          col3: "Colombia",
        },
        {
          key: "Roger Milla",
          col1: "Roger Milla",
          col2: "42y 39d",
          col3: "Cameroon",
        },
        {
          key: "Pat Jennings",
          col1: "Pat Jennings",
          col2: "41y",
          col3: "Northern Ireland",
        },
        {
          key: "Peter Shilton",
          col1: "Peter Shilton",
          col2: "40y 292d",
          col3: "England",
        },
      ],
      youngestWorldCupScorerHeadings: [
        {
          key: "youngestWorldCupScorer",
          col1: "Name",
          col2: "Age",
          col3: "Country",
        },
      ],
      youngestWorldCupScorer: [
        {
          key: "PeleYoungWCScorer",
          col1: "Pelé",
          col2: "17y 234d",
          col3: "Brazil",
        },
        {
          key: "ManuelRosasYoungWCScorer",
          col1: "Manuel Rosas",
          col2: "18y 94d",
          col3: "Mexico",
        },
        {
          key: "GaviYoungWCScorer",
          col1: "Gavi",
          col2: "18y 110d",
          col3: "Spain",
        },
        {
          key: "MichaelOwenYoungWCScorer",
          col1: "Michael Owen",
          col2: "18y 190d",
          col3: "England",
        },
        {
          key: "NicolaeKovacsYoungWCScorer",
          col1: "Nicolae Kovacs",
          col2: "18y 198d",
          col3: "Romania",
        },
      ],
      oldestWorldCupScorerHeadings: [
        {
          key: "oldestWorldCupScorer",
          col1: "Name",
          col2: "Age",
          col3: "Country",
        },
      ],
      oldestWorldCupScorer: [
        {
          key: "RogerMillaOldWCScorer",
          col1: "Roger Milla",
          col2: "42y 39d",
          col3: "Cameroon",
        },
        {
          key: "PepeOldWCScorer",
          col1: "Pepe",
          col2: "39y 283d",
          col3: "Portugal",
        },
        {
          key: "ChristianoRonaldoOldWCScorer",
          col1: "Christiano Ronaldo",
          col2: "37y 295d",
          col3: "Portugal",
        },
        {
          key: "GunnarGrenOldWCScorer",
          col1: "Gunnar Gren",
          col2: "37y 236d",
          col3: "Sweden",
        },
        {
          key: "CuauhtemocBlancoOldWCScorer",
          col1: "Cuauhtémoc Blanco",
          col2: "37y 151d",
          col3: "Mexico",
        },
      ],
    },
    topEuros: {
      lastEurosWinnersHeadings: [
        { key: "lastEurosWinners", col1: "Year", col2: "Country" },
      ],
      lastEurosWinners: [
        { key: "ItalyEuro2020Winner", col2: "Italy", col1: 2020 },
        { key: "PortugalEuro2016Winner", col2: "Portugal", col1: 2016 },
        { key: "SpainEuro2012Winner", col2: "Spain", col1: 2012 },
        { key: "SpainEuro2008Winner", col2: "Spain", col1: 2008 },
        { key: "GreeceEuro2004Winner", col2: "Greece", col1: 2004 },
      ],

      lastEurosHostsHeadings: [
        { key: "lastEurosHosts", col2: "Country", col1: "Year" },
      ],
      lastEurosHosts: [
        { key: "EuropeEuro2020Host", col2: "Europe", col1: 2020 },
        { key: "FranceEuro2016Winner", col2: "France", col1: 2016 },
        {
          key: "PolandUkraineEuro2012Winner",
          col2: "Poland / Ukraine",
          col1: 2012,
        },
        {
          key: "AustriaSwitzerlandEuro2008Winner",
          col2: "Austria / Switzerland",
          col1: 2008,
        },
        { key: "PortugalEuro2004Winner", col2: "Portugal", col1: 2004 },
      ],
      mostEurosWinsHeadings: [
        {
          key: "mostEurosWins",
          col1: "Country",
          col2: "Wins",
        },
      ],
      mostEurosWins: [
        {
          key: "GermanyEuro3Times",
          col1: "Germany",
          col2: 3,
        },
        { key: "SpainEuro3Times", col1: "Spain", col2: 3 },
        { key: "ItalyEuroTwice", col1: "Italy", col2: 2 },
        { key: "FranceEurosTwice", col1: "France", col2: 2 },
        { key: "RussiaEurosOnce", col1: "Russia", col2: 1 },
        { key: "CzechEurosOnce", col1: "Czech Republic", col2: 1 },
        { key: "PortugalEurosOnce", col1: "Portugal", col2: 1 },
        { key: "NetherlandsEurosOnce", col1: "Netherlands", col2: 1 },
        { key: "DenmarkEurosOnce", col1: "Denmark", col2: 1 },
        { key: "GreeceEurosOnce", col1: "Greece", col2: 1 },
      ],
    },
    fACup: {
      lastFACupWinnersHeadings: [
        { key: "lastFACupWinners", col2: "Winner", col1: "Season" },
      ],
      lastFACupWinners: [
        { key: "ManCityFACupWin", col2: "Manchester City", col1: "2022-2023" },
        { key: "LiverPoolFACupWin", col2: "Liverpool", col1: "2021-2022" },
        {
          key: "LeicesterCityFACupWin",
          col2: "Leicester City",
          col1: "2020-2021",
        },
        { key: "ArsenalFACupWin", col2: "Arsenal", col1: "2019-2020" },
        {
          key: "ManchesterCityFACupWin",
          col2: "Manchester City",
          col1: "2018-2019",
        },
      ],
      mostFACupWinsHeadings: [
        {
          key: "mostFACupWins",
          col1: "Team",
          col2: "Wins",
        },
      ],
      mostFACupWins: [
        {
          key: "ArsenalFA14",
          col1: "Arsenal",
          col2: 14,
        },
        { key: "ManUFA12", col1: "Manchester United", col2: 12 },
        { key: "ChelseaFA8", col1: "Chelsea", col2: 8 },
        { key: "LiverpoolFA8", col1: "Liverpool", col2: 8 },
        { key: "SpursFA8", col1: "Tottenham Hotspur", col2: 8 },
      ],
    },
    footballAwards: {
      ballonDorLastWinnersHeadings: [
        { key: "ballonDorLastWinners", col2: "Winner", col1: "Year" },
      ],
      ballonDorLastWinners: [
        { key: "KarimBenzemaballonDor2022", col2: "Karim Benzema", col1: 2022 },
        { key: "LionelMessiballonDor2021", col2: "Lionel Messi", col1: 2021 },
        { key: "LionelMessiballonDor2019", col2: "Lionel Messi", col1: 2019 },
        { key: "LukaModricballonDor2018", col2: "Luka Modric", col1: 2018 },
        {
          key: "CristianoRonaldoballonDor2017",
          col2: "Cristiano Ronaldo",
          col1: 2017,
        },
      ],
      ballonDorMostWinsHeadings: [
        {
          key: "ballonDorMostWins",
          col1: "Player",
          col2: "Wins",
        },
      ],
      ballonDorMostWins: [
        {
          key: "MessiBallon7",
          col1: "Lionel Messi",
          col2: 7,
        },
        { key: "CRonaldoBallon5", col1: "Cristiano Ronaldo", col2: 5 },
        { key: "PlatiniBallon3", col1: "Michel Platini", col2: 3 },
        { key: "CruyffBallon3", col1: "Johan Cruyff", col2: 3 },
        { key: "BastenBallon3", col1: "Marco van Basten", col2: 3 },
      ],
      goldenBootPremiershipLastWinnersHeadings: [
        {
          key: "goldenBootPremiershipLastWinners",
          col2: "Winner(s)",
          col1: "Season",
          col3: "Goals",
        },
      ],
      goldenBootPremiershipLastWinners: [
        {
          key: "ErlingHaalandPremGB202223",
          col2: "Erling Haaland",
          col1: "2022-23",
          col3: 36,
        },
        {
          key: "SonHeung-minMohamedSalahPremGB202122",
          col2: "Son Heung-min & Mohamed Salah",
          col1: "2021-22",
          col3: 23,
        },
        {
          key: "HarryKanePremGB202021",
          col2: "Harry Kane",
          col1: "2020-21",
          col3: 23,
        },
        {
          key: "JamieVardyPremGB202122",
          col2: "Jamie Vardy",
          col1: "2019-20",
          col3: 23,
        },
        {
          key: "Pierre-EmerickAubameyangSadioManéMohamedSalahGB202122",
          col2: "Pierre-Emerick Aubameyang, Sadio Mané & Mohamed Salah",
          col1: "2018-19",
          col3: 22,
        },
      ],
      goldenBootWorldCupLastWinnersHeadings: [
        {
          key: "goldenBootWorldCupLastWinners",
          col2: "Winner",
          col1: "Year",
          col3: "Goals",
          col4: "Country",
        },
      ],
      goldenBootWorldCupLastWinners: [
        {
          key: "KylianMbappeGBWC2022",
          col2: "Kylian Mbappe",
          col1: "2022",
          col3: 8,
          col4: "France",
        },
        {
          key: "HarryKaneGBWC2018",
          col2: "Harry Kane",
          col1: "2018",
          col3: 6,
          col4: "England",
        },
        {
          key: "JamesRodríguezGBWC2014",
          col2: "James Rodríguez",
          col1: "2014",
          col3: 6,
          col4: "Colombia",
        },
        {
          key: "Thomas MüllerGBWC2010",
          col2: "Thomas Müller",
          col1: "2010",
          col3: 5,
          col4: "Germany",
        },
        // after 2010 called golden boot, 1982 - 2010 golden shoe, before 1982 top goalscorer
        {
          key: "MiroslavKloseGBWC2006",
          col2: "Miroslav Klose",
          col1: "2006",
          col3: 5,
          col4: "Germany",
        },
      ],
      goldenBootWorldCupTopWinnersHeadings: [
        {
          key: "goldenBootWorldCupTopWinners",
          col2: "Winner",
          col4: "Country",
          col3: "Goals",
          col1: "Year",
        },
      ],
      goldenBootWorldCupTopWinners: [
        {
          key: "JustFontaineGBoot13",
          col2: "Just Fontaine",
          col4: "France",
          col3: 13,
          col1: 1958,
        },
        {
          key: "SandorKocsisGBoot11",
          col2: "Sandor Kocsis",
          col4: "Hungary",
          col3: 11,
          col1: 1954,
        },
        {
          key: "GerdMullerGBoot10",
          col2: "Gerd Muller",
          col4: "West Germany",
          col3: 10,
          col1: 1970,
        },
        {
          key: "EusebioGBoot9",
          col2: "Eusebio",
          col4: "Portugal",
          col3: 9,
          col1: 1966,
        },
        {
          key: "RonaldoGBoot8",
          col2: "Ronaldo",
          col4: "Brazil",
          col3: 8,
          col1: 2002,
        },
        {
          key: "AdemirGBoot8",
          col2: "Ademir",
          col4: "Brazil",
          col3: 8,
          col1: 1950,
        },
        {
          key: "GuillermoStábile8",
          col2: "Guillermo Stábile",
          col4: "Uruguay",
          col3: 8,
          col1: 1930,
        },
      ],
    },
    championsLeague: {
      lastChampionsLeagueWinnersHeadings: [
        {
          key: "lastChampionsLeagueWinners",
          col1: "Season",
          col2: "Winner",
        },
      ],
      lastChampionsLeagueWinners: [
        {
          key: "ManCityChampL2023",
          col1: "2022-23",
          col2: "Manchester City",
        },
        {
          key: "RealMadridChampL2022",
          col1: "2021-22",
          col2: "Real Madrid",
        },
        {
          key: "ChelseaChampL2021",
          col1: "2020-21",
          col2: "Chelsea",
        },
        {
          key: "BayernMunichChampL2020",
          col1: "2019-20",
          col2: "Bayern Munich",
        },
        {
          key: "LiverpoolChampL2019",
          col1: "2018-19",
          col2: "Liverpool",
        },
      ],
      championsLeagueMostWinsHeadings: [
        {
          key: "championsLeagueMostWins",
          col1: "Team",
          col2: "Wins",
        },
      ],
      championsLeagueMostWins: [
        {
          key: "RealMAdridChampsLeague14",
          col1: "Real Madrid",
          col2: 14,
        },
        { key: "ACMilanChampsLeague7", col1: "AC Milan", col2: 7 },
        {
          key: "BayernMunichChampsLeague6",
          col1: "Bayern Munich",
          col2: 6,
        },
        { key: "LiverpoolChampsLeague6", col1: "Liverpool", col2: 6 },
        { key: "BarcelonaChampsLeague5", col1: "Barcelona", col2: 5 },
      ],
    },
    premierLeague: {
      premMostAppsHeadings: [
        {
          key: "premMostApps",
          col1: "Player",
          col2: "Appearances",
          col3: "Retired?",
        },
      ],
      premMostApps: [
        {
          key: "GarethBarry653",
          col1: "Gareth Barry",
          col2: 653,
          col3: "Yes",
        },
        { key: "RyanGiggs632", col1: "Ryan Giggs", col2: 632, col3: "Yes" },
        { key: "JamesMilner619", col1: "James Milner", col2: 619, col3: "No" },
        {
          key: "FrankLampard609",
          col1: "Frank Lampard",
          col2: 609,
          col3: "Yes",
        },
        { key: "DavidJames572", col1: "David James", col2: 572, col3: "Yes" },
      ],
      premMostGoalsHeadings: [
        {
          key: "premMostGoals",
          col1: "Player",
          col2: "Goals",
          col3: "Retired?",
        },
      ],
      premMostGoals: [
        {
          key: "AlanShearer260",
          col1: "Alan Shearer",
          col2: 260,
          col3: "Yes",
        },
        { key: "HarryKane213", col1: "Harry Kane", col2: 213, col3: "No" },
        { key: "WayneRooney208", col1: "Wayne Rooney", col2: 208, col3: "Yes" },
        { key: "AndrewCole187", col1: "Andrew Cole", col2: 187, col3: "Yes" },
        {
          key: "SergioAguero184",
          col1: "Sergio Aguera",
          col2: 184,
          col3: "Yes",
        },
      ],
      premMostAssistsHeadings: [
        {
          key: "premMostAssists",
          col1: "Player",
          col2: "Assists",
        },
      ],
      premMostAssists: [
        {
          key: "RyanGiggsAssists",
          col1: "Ryan Giggs",
          col2: 162,
        },
        { key: "CescFabregasAssists", col1: "Cesc Fabregas", col2: 111 },
        { key: "WayneRooneyAssists", col1: "Wayne Rooney", col2: 103 },
        { key: "FrankLampardAssists", col1: "Frank Lampbard", col2: 102 },
        { key: "KevinDeBruyneAssists", col1: "Kevin De Bruyne", col2: 102 },
      ],
      youngestPremPlayerAppHeadings: [
        {
          key: "youngestPremPlayerApp",
          col1: "Player",
          col2: "Age",
          col3: "Team",
          col4: "Retired?",
        },
      ],
      youngestPremPlayerApp: [
        {
          key: "EthanNwaneriYoungPremApp",
          col1: "Ethan Nwaneri",
          col2: "15y 181d",
          col3: "Arsenal",
          col4: "No",
        },
        {
          key: "HarveyElliotYoungPremApp",
          col1: "Harvey Elliot",
          col2: "16y 30d",
          col3: "Fulham",
          col4: "No",
        },
        {
          key: "MatthewBriggsYoungPremApp",
          col1: "Matthew Briggs",
          col2: "16y 68d",
          col3: "Fulham",
          col4: "No",
        },
        {
          key: "IsaiahBrownYougPremApp",
          col1: "Isaiah Brown",
          col2: "16y 117d",
          col3: "West Brom",
          col4: "No",
        },
        {
          key: "AaronLennonYoungPremApp",
          col1: "Aaron Lennon",
          col2: "16y 129d",
          col3: "Leeds",
          col4: "No",
        },
      ],
      youngestPremPlayerGoalHeadings: [
        {
          key: "youngestPremPlayerGoal",
          col1: "Player",
          col2: "Age",
          col3: "Team",
          col4: "Retired?",
        },
      ],
      youngestPremPlayerGoal: [
        {
          key: "JamesVaughanYoungPremGoal",
          col1: "James Vaughan",
          col2: "16y 270d",
          col3: "Everton",
          col4: "Yes",
        },
        {
          key: "JamesMilnerYoungPremGoal",
          col1: "James Milner",
          col2: "16y 356d",
          col3: "Leeds",
          col4: "No",
        },
        {
          key: "WayneRooneyYougPremGoal",
          col1: "Wayne Rooney",
          col2: "16y 360d",
          col3: "Everton",
          col4: "Yes",
        },
        {
          key: "CescFabregasYoungPremGoal",
          col1: "Cesc Fabregas",
          col2: "17y 114d",
          col3: "Arsenal",
          col4: "No",
        },
        {
          key: "MichaelOwenYoungPremGoal",
          col1: "Michael Owen",
          col2: "17y 144d",
          col3: "Liverpool",
          col4: "Yes",
        },
      ],
      oldestPremPlayerAppsHeadings: [
        {
          key: "oldestPremPlayerApps",
          col1: "Player",
          col2: "Age",
        },
      ],
      oldestPremPlayerApps: [
        {
          key: "JohnBurridgeOldPremApp",
          col1: "John Burridge",
          col2: "43y 163d",
        },
        {
          key: "AlecChamberlainOldPremApp",
          col1: "Alec Chamberlain",
          col2: "42y 328d",
        },
        {
          key: "SteveOgrizovicOldPremApp",
          col1: "Steve Ogrizovic",
          col2: "42y 236d",
        },
        {
          key: "BradFriedelOldPremApp",
          col1: "Brad Friedel",
          col2: "42y 176d",
        },
        {
          key: "MarkSchwarzerOldPremApp",
          col1: "Mark Schwarzer",
          col2: "42y 159d",
        },
      ],
      oldestPremPlayerGoalHeadings: [
        {
          key: "oldestPremPlayerGoal",
          col1: "Player",
          col2: "Age",
          col3: "Team",
        },
      ],
      oldestPremPlayerGoal: [
        {
          key: "TeddySheringhamOldPremGoal",
          col1: "Teddy Sheringham",
          col2: "40y 268d",
          col3: "West Ham",
        },
        {
          key: "DeanWindassOldPremGoal",
          col1: "Dean Windass",
          col2: "39y 236d",
          col3: "Hull City",
        },
        {
          key: "RyanGiggsOldPremGoal",
          col1: "Ryan Giggs",
          col2: "39y 87d",
          col3: "Manchested Utd",
        },
        {
          key: "StuartPearceOldPremGoal",
          col1: "Stuart Pearce",
          col2: "38y 216d",
          col3: "West Ham",
        },
        {
          key: "GrahamAlexanderOldPremGoal",
          col1: "Graham Alexander",
          col2: "38y 183d",
          col3: "Burnley",
        },
      ],
      premierLeagueMostRedCardsHeadings: [
        {
          key: "premierLeagueMostRedCards",
          col1: "Player",
          col2: "Red Cards",
        },
      ],
      premierLeagueMostRedCards: [
        {
          key: "RichardDunneReds8",
          col1: "Richard Dunne",
          col2: 8,
        },
        {
          key: "DuncanFergusonReds8",
          col1: "Duncan Ferguson",
          col2: 8,
        },
        {
          key: "PatrickVieraReds8",
          col1: "Patrick Viera",
          col2: 8,
        },
        {
          key: "LeeCattermoleReds7",
          col1: "Lee Cattermole",
          col2: 7,
        },
        {
          key: "VinnieJonesReds7",
          col1: "Vinnie Jones",
          col2: 7,
        },
        {
          key: "RoyKeaneReds7",
          col1: "Roy Keane",
          col2: 7,
        },
        {
          key: "AlanSmithReds7",
          col1: "Alan Smith",
          col2: 7,
        },
      ],
      premierLeagueMostYellowCardsHeadings: [
        {
          key: "premierLeagueMostYellowCards",
          col1: "Player",
          col2: "Yellow Cards",
        },
      ],
      premierLeagueMostYellowCards: [
        {
          key: "GarethBarry123",
          col1: "Gareth Barry",
          col2: 123,
        },
        {
          key: "Wayne Rooney102",
          col1: "Wayne Rooney",
          col2: 102,
        },
        {
          key: "Lee Bowyer99",
          col1: "Lee Bowyer",
          col2: 99,
        },
        {
          key: "Kevin Davies99",
          col1: "Kevin Davies",
          col2: 99,
        },
        {
          key: "Paul Scholes97",
          col1: "Paul Scholes",
          col2: 97,
        },
      ],
      topFiveMostPremWinsHeadings: [
        {
          key: "topFiveMostPremWins",
          col1: "Team",
          col2: "Wins",
        },
      ],
      topFiveMostPremWins: [
        {
          key: "ManchesterUnitedpremwins20",
          col1: "Manchester United",
          col2: 20,
        },
        {
          key: "Liverpoolpremwins19",
          col1: "Liverpool",
          col2: 19,
        },
        {
          key: "Arsenalpremwins13",
          col1: "Arsenal",
          col2: 13,
        },
        {
          key: "Evertonpremwins9",
          col1: "Everton",
          col2: 9,
        },
        {
          key: "ManCitypremwins8",
          col1: "Manchester City",
          col2: 8,
        },
      ],
      topFiveMostPremierLeagueWinsHeadings: [
        {
          key: "topFiveMostPremierLeagueWins",
          col1: "Team",
          col2: "Wins",
        },
      ],
      topFiveMostPremierLeagueWins: [
        {
          key: "ManchesterUnitedPremierLeagueWins13",
          col1: "Manchester United",
          col2: 13,
        },
        {
          key: "ManchesterCityPremierLeagueWins7",
          col1: "Manchester City",
          col2: 7,
        },
        {
          key: "ChelseaPremierLeagueWins5",
          col1: "Chelsea",
          col2: 5,
        },
        {
          key: "ArsenalPremierLeagueWins3",
          col1: "Arsenal",
          col2: 3,
        },
        {
          key: "BlackburnRoversPremierLeagueWins1",
          col1: "Blackburn Rovers",
          col2: 1,
        },
      ],
      lastFiveYearsWinsHeadings: [
        {
          key: "lastFiveYearsWins",
          col2: "Team",
          col1: "Season",
        },
      ],
      lastFiveYearsWins: [
        {
          key: "ManCitypremwins2022-23",
          col2: "Manchester City",
          col1: "2022-23",
        },
        {
          key: "ManCitypremwins2021-22",
          col2: "Manchester City",
          col1: "2021-22",
        },
        {
          key: "ManCitypremwins2020-21",
          col2: "Manchester City",
          col1: "2020-21",
        },
        {
          key: "Liverpoolpremwins2019-20",
          col2: "Liverpool",
          col1: "2019-20",
        },
        {
          key: "ManCitypremwins2018-19",
          col2: "Manchester City",
          col1: "2018-19",
        },
      ],
    },
    internationalCaps: {
      englandCapsHeadings: [
        {
          key: "englandCaps",
          col1: "Player",
          col2: "Caps",
        },
      ],
      englandCaps: [
        {
          key: "PeterShilton125",
          col1: "Peter Shilton",
          col2: 125,
        },
        {
          key: "WayneRooney120",
          col1: "Wayne Rooney",
          col2: 120,
        },
        {
          key: "David Beckham115",
          col1: "David Beckham",
          col2: 115,
        },
        {
          key: "StephenGerrard114",
          col1: "Stephen Gerrard",
          col2: 114,
        },
        {
          key: "BobbyMoore108",
          col1: "Bobby Moore",
          col2: 108,
        },
      ],
      worldInternationalCapsByFIFAStatsHeadings: [
        {
          key: "worldInternationalCapsByFIFAStats",
          col1: "Player",
          col2: "Caps",
          col3: "Country",
        },
      ],
      worldInternationalCapsByFIFAStats: [
        {
          key: "BaderAl-Mutawa196",
          col1: "Bader Al-Mutawa",
          col2: 196,
          col3: "Kuwait",
        },
        {
          key: "SohChinAnn195",
          col1: "Soh Chin Ann",
          col2: 195,
          col3: "Malaysia",
        },
        {
          key: "CristianoRonaldo189",
          col1: "Cristiano Ronaldo",
          col2: 191,
          col3: "Portugal",
        },
        {
          key: "AhmedHassan184",
          col1: "Ahmed Hassan",
          col2: 184,
          col3: "Egypt",
        },
        {
          key: "AhmedMubarak183",
          col1: "Ahmed Mubarak",
          col2: 183,
          col3: "Oman",
        },
      ],
    },
    footballPlayersAndRecords: {
      Top6MostAppearancesinWorldCupFinalsMatchesHeadings: [
        {
          key: "Top6MostAppearancesinWorldCupFinalsMatches",
          col1: "Name",
          col2: "Countries",
          col3: "Appearances",
        },
      ],
      Top6MostAppearancesinWorldCupFinalsMatches: [
        {
          key: "LotharMatthausGER25",
          col1: "Lothar Matthaus",
          col2: "Germany",
          col3: 25,
        },
        {
          key: "MiroslavKloseGER24",
          col1: "Miroslav Klose",
          col2: "Germany",
          col3: 24,
        },
        {
          key: "PaoloMaldiniITA23",
          col1: "Paolo Maldini",
          col2: "Italy",
          col3: 23,
        },
        {
          key: "DiegoMaradonaARG21",
          col1: "Diego Maradona",
          col2: "Argentina",
          col3: 21,
        },
        {
          key: "UweSeelerGER21",
          col1: "Uwe Seeler",
          col2: "Germany",
          col3: 21,
        },
        {
          key: "WladyslawZmudaPOL21",
          col1: "Wladyslaw Zmuda",
          col2: "Poland",
          col3: 21,
        },
      ],
      Top5GoalscorersatWorldCupFinalsHeadings: [
        {
          key: "Top5GoalscorersatWorldCupFinals",
          col1: "Name",
          col2: "Countries",
          col3: "Goals",
        },
      ],
      Top5GoalscorersatWorldCupFinals: [
        {
          key: "MiroslavKloseGER16",
          col1: "Miroslav Klose",
          col2: "Germany",
          col3: 16,
        },
        {
          key: "RonaldoBRA15",
          col1: "Ronaldo",
          col2: "Brazil",
          col3: 15,
        },
        {
          key: "GerdMullerGER14",
          col1: "Gerd Muller",
          col2: "Germany",
          col3: 14,
        },
        {
          key: "JustFontaineFRA13",
          col1: "Just Fontaine",
          col2: "France",
          col3: 13,
        },
        {
          key: "PeleBRA11",
          col1: "Pele",
          col2: "Brazil",
          col3: 11,
        },
      ],
      Top6WorldCupWinningCountriesHeadings: [
        {
          key: "Top6WorldCupWinningCountries",
          col1: "Country",
          col2: "Wins",
          col3: "Runners Up",
        },
      ],
      Top6WorldCupWinningCountries: [
        {
          key: "Brazil52",
          col1: "Brazil",
          col2: 5,
          col3: 2,
        },
        {
          key: "Germany44",
          col1: "Germany",
          col2: 4,
          col3: 4,
        },
        {
          key: "Italy42",
          col1: "Italy",
          col2: 4,
          col3: 2,
        },
        {
          key: "Argentina23",
          col1: "Argentina",
          col2: 2,
          col3: 3,
        },
        {
          key: "France21",
          col1: "France",
          col2: 2,
          col3: 1,
        },
        {
          key: "Uruguay20",
          col1: "Uruguay",
          col2: 2,
          col3: 0,
        },
      ],
      Top5MostGamesPlayedatWorldCupFinalsHeadings: [
        {
          key: "Top5MostGamesPlayedatWorldCupFinals",
          col1: "Country",
          col2: "Games Played",
        },
      ],
      Top5MostGamesPlayedatWorldCupFinals: [
        {
          key: "Brazil109",
          col1: "Brazil",
          col2: 109,
        },
        {
          key: "Germany109",
          col1: "Germany",
          col2: 109,
        },
        {
          key: "Italy83",
          col1: "Italy",
          col2: 83,
        },
        {
          key: "Argentina81",
          col1: "Argentina",
          col2: 81,
        },
        {
          key: "England69",
          col1: "England",
          col2: 69,
        },
      ],
      Top5MostCappedArgentinianPlayersHeadings: [
        {
          key: "Top5MostCappedArgentinianPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedArgentinianPlayers: [
        {
          key: "LionelMessi162No",
          col1: "Lionel Messi",
          col2: 164,
          col3: "No",
        },
        {
          key: "JavierMascherano147Yes",
          col1: "Javier Mascherano",
          col2: 147,
          col3: "Yes",
        },
        {
          key: "JavierZanetti145Yes",
          col1: "Javier Zanetti",
          col2: 145,
          col3: "Yes",
        },
        {
          key: "AngelDiMaria122No",
          col1: "Angel Di Maria",
          col2: 123,
          col3: "No",
        },
        {
          key: "RobertoAyala115Yes",
          col1: "Roberto Ayala",
          col2: 115,
          col3: "Yes",
        },
      ],
      Top5MostInternationalGoalsForArgentinaHeadings: [
        {
          key: "Top5MostInternationalGoalsForArgentina",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForArgentina: [
        {
          key: "LionelMessi86162No",
          col1: "Lionel Messi",
          col2: 90,
          col3: 164,
          col4: "No",
        },
        {
          key: "GabrielBatistuta5678Yes",
          col1: "Gabriel Batistuta",
          col2: 56,
          col3: 78,
          col4: "Yes",
        },
        {
          key: "SergioAguero41101Yes",
          col1: "Sergio Aguero",
          col2: 41,
          col3: 101,
          col4: "Yes",
        },
        {
          key: "HernanCrespo3564Yes",
          col1: "Hernan Crespo",
          col2: 35,
          col3: 64,
          col4: "Yes",
        },
        {
          key: "DiegoMaradona3491Yes",
          col1: "Diego Maradona",
          col2: 34,
          col3: 91,
          col4: "Yes",
        },
      ],
      Top5MostCappedBrazilianPlayersHeadings: [
        {
          key: "Top5MostCappedBrazilianPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedBrazilPlayers: [
        {
          key: "Cafu142Yes",
          col1: "Cafu",
          col2: 142,
          col3: "Yes",
        },
        {
          key: "Roberto Carlos125Yes",
          col1: "Roberto Carlos",
          col2: 125,
          col3: "Yes",
        },
        {
          key: "Dani Alves122No",
          col1: "Dani Alves",
          col2: 124,
          col3: "No",
        },
        {
          key: "Neymar117No",
          col1: "Neymar",
          col2: 121,
          col3: "No",
        },
        {
          key: "ThiagoSilva115No",
          col1: "Thiago Silva",
          col2: 109,
          col3: "No",
        },
      ],
      Top5MostInternationalGoalsForBrazilHeadings: [
        {
          key: "Top5MostInternationalGoalsForBrazil",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForBrazil: [
        {
          key: "Pele7792Yes",
          col1: "Pele",
          col2: 77,
          col3: 92,
          col4: "Yes",
        },
        {
          key: "Neymar71117No",
          col1: "Neymar",
          col2: 75,
          col3: 121,
          col4: "No",
        },
        {
          key: "Ronaldo6298Yess",
          col1: "Ronaldo",
          col2: 62,
          col3: 98,
          col4: "Yes",
        },
        {
          key: "Romario5570Yes",
          col1: "Romario",
          col2: 55,
          col3: 70,
          col4: "Yes",
        },
        {
          key: "Zico4871Yes",
          col1: "Zico",
          col2: 48,
          col3: 71,
          col4: "Yes",
        },
      ],
      Top5MostCappedEnglandPlayersHeadings: [
        {
          key: "Top5MostCappedEnglandPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedEnglandPlayers: [
        {
          key: "PeterShilton125Yes",
          col1: "Peter Shilton",
          col2: 125,
          col3: "Yes",
        },
        {
          key: "WayneRooney120Yes",
          col1: "Wayne Rooney",
          col2: 120,
          col3: "Yes",
        },
        {
          key: "DavidBeckham115Yes",
          col1: "David Beckham",
          col2: 115,
          col3: "Yes",
        },
        {
          key: "StevenGerrard114Yes",
          col1: "Steven Gerrard",
          col2: 114,
          col3: "Yes",
        },
        {
          key: "Bobby Moore108Yes",
          col1: "Bobby Moore",
          col2: 108,
          col3: "Yes",
        },
      ],
      Top5MostInternationalGoalsForEnglandHeadings: [
        {
          key: "Top5MostInternationalGoalsForEngland",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForEngland: [
        {
          key: "WayneRooney53120Yes",
          col1: "Wayne Rooney",
          col2: 53,
          col3: 120,
          col4: "Yes",
        },
        {
          key: "HarryKane5073No",
          col1: "Harry Kane",
          col2: 51,
          col3: 75,
          col4: "No",
        },
        {
          key: "BobbyCharlton49106Yes",
          col1: "Bobby Charlton",
          col2: 49,
          col3: 106,
          col4: "Yes",
        },
        {
          key: "GaryLineker4880Yes",
          col1: "Gary Lineker",
          col2: 48,
          col3: 80,
          col4: "Yes",
        },
        {
          key: "JimmyGreaves4457Yes",
          col1: "Jimmy Greaves",
          col2: 44,
          col3: 57,
          col4: "Yes",
        },
      ],
      Top5MostCappedFrancePlayersHeadings: [
        {
          key: "Top5MostCappedFrancePlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedFrancePlayers: [
        {
          key: "LillianThuram142Yes",
          col1: "Lillian Thuram",
          col2: 142,
          col3: "Yes",
        },
        {
          key: "HugoLloris139No",
          col1: "Hugo Lloris",
          col2: 139,
          col3: "No",
        },
        {
          key: "ThierryHenry123Yes",
          col1: "Thierry Henry",
          col2: 123,
          col3: "Yes",
        },
        {
          key: "MarcelDesailly116Yes",
          col1: "Marcel Desailly",
          col2: 116,
          col3: "Yes",
        },
        {
          key: "OlivierGiroud112No",
          col1: "Olivier Giroud",
          col2: 114,
          col3: "No",
        },
      ],
      Top5MostInternationalGoalsForFranceHeadings: [
        {
          key: "Top5MostInternationalGoalsForFrance",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForFrance: [
        {
          key: "ThierryHenry51123Yes",
          col1: "Thierry Henry",
          col2: 51,
          col3: 123,
          col4: "Yes",
        },
        {
          key: "OlivierGiroud48112No",
          col1: "Olivier Giroud",
          col2: 49,
          col3: 114,
          col4: "No",
        },
        {
          key: "AntioneGriezmann42108No",
          col1: "Antione Griezmann",
          col2: 42,
          col3: 110,
          col4: "No",
        },
        {
          key: "MichelPlatini4172Yes",
          col1: "Michel Platini",
          col2: 41,
          col3: 72,
          col4: "Yes",
        },
        {
          key: "KarimBenzema3797No",
          col1: "Karim Benzema",
          col2: 37,
          col3: 97,
          col4: "No",
        },
      ],
      Top5MostCappedGermanyPlayersHeadings: [
        {
          key: "Top5MostCappedGermanPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedGermanyPlayers: [
        {
          key: "LotharMatthaus150Yes",
          col1: "Lothar Matthaus",
          col2: 150,
          col3: "Yes",
        },
        {
          key: "MiroslavKlose137Yes",
          col1: "Miroslav Klose",
          col2: 137,
          col3: "Yes",
        },
        {
          key: "LukasPodolski130Yes",
          col1: "Lukas Podolski",
          col2: 130,
          col3: "Yes",
        },
        {
          key: "BastienSchweinsteiger121Yes",
          col1: "Bastien Schweinsteiger",
          col2: 121,
          col3: "Yes",
        },
        {
          key: "ThomasMuller116No",
          col1: "Thomas Muller",
          col2: 118,
          col3: "No",
        },
      ],
      Top5MostInternationalGoalsForGermanyHeadings: [
        {
          key: "Top5MostInternationalGoalsForGermany",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForGermany: [
        {
          key: "MiroslavKlose71137Yes",
          col1: "Miroslav Klose",
          col2: 71,
          col3: 137,
          col4: "Yes",
        },
        {
          key: "GerdMuller6862Yes",
          col1: "Gerd Muller",
          col2: 68,
          col3: 62,
          col4: "Yes",
        },
        {
          key: "LukasPodolski49130Yes",
          col1: "Lukas Podolski",
          col2: 49,
          col3: 130,
          col4: "Yes",
        },
        {
          key: "RudiVoller4790Yes",
          col1: "Rudi Voller",
          col2: 47,
          col3: 90,
          col4: "Yes",
        },
        {
          key: "JurgenKlinsmann47108Yes",
          col1: "Jurgen Klinsmann",
          col2: 47,
          col3: 108,
          col4: "Yes",
        },
      ],
      Top5MostCappedItalianPlayersHeadings: [
        {
          key: "Top5MostCappedItalianPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedItalianPlayers: [
        {
          key: "GianluigiBuffon176Yes",
          col1: "Gianluigi Buffon",
          col2: 176,
          col3: "Yes",
        },
        {
          key: "FabioCannavaro136Yes",
          col1: "Fabio Cannavaro",
          col2: 136,
          col3: "Yes",
        },
        {
          key: "PaoloMaldini126Yes",
          col1: "Paolo Maldini",
          col2: 126,
          col3: "Yes",
        },
        {
          key: "DanielleDeRossi117Yes",
          col1: "Danielle De Rossi",
          col2: 117,
          col3: "Yes",
        },
        {
          key: "GiorgioChiellini117Yes",
          col1: "Giorgio Chiellini",
          col2: 117,
          col3: "Yes",
        },
      ],
      Top5MostInternationalGoalsForItalyHeadings: [
        {
          key: "Top5MostInternationalGoalsForItaly",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForItaly: [
        {
          key: "Luigi'Gigi'Riva3542Yes",
          col1: "Luigi 'Gigi' Riva",
          col2: 35,
          col3: 42,
          col4: "Yes",
        },
        {
          key: "GiuseppeMeazza3353Yes",
          col1: "Giuseppe Meazza",
          col2: 33,
          col3: 53,
          col4: "Yes",
        },
        {
          key: "SilvioPiola3034Yes",
          col1: "Silvio Piola",
          col2: 30,
          col3: 34,
          col4: "Yes",
        },
        {
          key: "RobertoBaggio2756Yes",
          col1: "Roberto Baggio",
          col2: 27,
          col3: 56,
          col4: "Yes",
        },
        {
          key: "AlessandroDelPiero2791Yes",
          col1: "Alessandro Del Piero",
          col2: 27,
          col3: 91,
          col4: "Yes",
        },
      ],
      Top5MostCappedNetherlandsPlayersHeadings: [
        {
          key: "Top5MostCappedNetherlandsPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedNetherlandsPlayers: [
        {
          key: "WesleySneijder134Yes",
          col1: "Wesley Sneijder",
          col2: 134,
          col3: "Yes",
        },
        {
          key: "EdwinvanderSar130Yes",
          col1: "Edwin van der Sar",
          col2: 130,
          col3: "Yes",
        },
        {
          key: "FrankdeBoer112Yes",
          col1: "Frank de Boer",
          col2: 112,
          col3: "Yes",
        },
        {
          key: "RafaelvanderVaart109Yes",
          col1: "Rafael van der Vaart",
          col2: 109,
          col3: "Yes",
        },
        {
          key: "GiovannivanBronckhorst106Yes",
          col1: "Giovanni van Bronckhorst",
          col2: 106,
          col3: "Yes",
        },
      ],
      Top5MostInternationalGoalsForNetherlandsHeadings: [
        {
          key: "Top5MostInternationalGoalsForNetherlands",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForNetherlands: [
        {
          key: "RobinvanPersie50102Yes",
          col1: "Robin van Persie",
          col2: 50,
          col3: 102,
          col4: "Yes",
        },
        {
          key: "MemphisDepay4277No",
          col1: "Memphis Depay",
          col2: 42,
          col3: 81,
          col4: "No",
        },
        {
          key: "Klaas-JanHuntelaar4276Yes",
          col1: "Klaas-Jan Huntelaar",
          col2: 42,
          col3: 76,
          col4: "Yes",
        },
        {
          key: "PatrickKluivert4079Yes",
          col1: "Patrick Kluivert",
          col2: 40,
          col3: 79,
          col4: "Yes",
        },
        {
          key: "DennisBergkamp3779Yes",
          col1: "Dennis Bergkamp",
          col2: 37,
          col3: 79,
          col4: "Yes",
        },
        {
          key: "ArjenRobben3796Yes",
          col1: "Arjen Robben",
          col2: 37,
          col3: 96,
          col4: "Yes",
        },
      ],
      Top5MostCappedPortugalPlayersHeadings: [
        {
          key: "Top5MostCappedPortugalPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedPortugalPlayers: [
        {
          key: "CristianoRonaldo187No",
          col1: "Cristiano Ronaldo",
          col2: 191,
          col3: "No",
        },
        {
          key: "JoaoMoutinho145No",
          col1: "Joao Moutinho",
          col2: 146,
          col3: "No",
        },
        {
          key: "LuisFigo127Yes",
          col1: "Luis Figo",
          col2: 127,
          col3: "Yes",
        },
        {
          key: "Pepe125No",
          col1: "Pepe",
          col2: 128,
          col3: "No",
        },
        {
          key: "Nani112Yes",
          col1: "Nani",
          col2: 112,
          col3: "Yes",
        },
      ],
      Top5MostInternationalGoalsForPortugalHeadings: [
        {
          key: "Top5MostInternationalGoalsForPortugal",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForPortugal: [
        {
          key: "CristianoRonaldo115187No",
          col1: "Cristiano Ronaldo",
          col2: 117,
          col3: 191,
          col4: "No",
        },
        {
          key: "Pauleta4788Yes",
          col1: "Pauleta",
          col2: 47,
          col3: 88,
          col4: "Yes",
        },
        {
          key: "Eusebio4164Yes",
          col1: "Eusebio",
          col2: 41,
          col3: 64,
          col4: "Yes",
        },
        {
          key: "LuisFigo32127Yes",
          col1: "Luis Figo",
          col2: 32,
          col3: 127,
          col4: "Yes",
        },
        {
          key: "NunoGomes2979Yes",
          col1: "Nuno Gomes",
          col2: 29,
          col3: 79,
          col4: "Yes",
        },
      ],
      Top5MostCappedSpainPlayersHeadings: [
        {
          key: "Top5MostCappedSpainPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedSpainPlayers: [
        {
          key: "SergioRamos180No",
          col1: "Sergio Ramos",
          col2: 180,
          col3: "No",
        },
        {
          key: "IkerCasillas167Yes",
          col1: "Iker Casillas",
          col2: 167,
          col3: "Yes",
        },
        {
          key: "SergioBusquets137No",
          col1: "Sergio Busquets",
          col2: 139,
          col3: "No",
        },
        {
          key: "Xavi133Yes",
          col1: "Xavi",
          col2: 133,
          col3: "Yes",
        },
        {
          key: "AndresIniesta131Yes",
          col1: "Andres Iniesta",
          col2: 131,
          col3: "Yes",
        },
      ],
      Top5MostInternationalGoalsForSpainHeadings: [
        {
          key: "Top5MostInternationalGoalsForSpain",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForSpain: [
        {
          key: "DavidVilla5998Yes",
          col1: "David Villa",
          col2: 59,
          col3: 98,
          col4: "Yes",
        },
        {
          key: "Raul44102Yes",
          col1: "Raul",
          col2: 44,
          col3: 102,
          col4: "Yes",
        },
        {
          key: "FernandoTorres38110Yes",
          col1: "Fernando Torres",
          col2: 38,
          col3: 110,
          col4: "Yes",
        },
        {
          key: "DavidSilva35125Yes",
          col1: "David Silva",
          col2: 35,
          col3: 125,
          col4: "Yes",
        },
        {
          key: "FernandoHierro2989Yes",
          col1: "Fernando Hierro",
          col2: 29,
          col3: 89,
          col4: "Yes",
        },
      ],
      Top5MostCappedUruguayPlayersHeadings: [
        {
          key: "Top5MostCappedUruguayPlayers",
          col1: "Name",
          col2: "Caps",
          col3: "Retired?",
        },
      ],
      Top5MostCappedUruguayPlayers: [
        {
          key: "DiegoGodin159No",
          col1: "Diego Godin",
          col2: 159,
          col3: "No",
        },
        {
          key: "LuisSuarez132No",
          col1: "Luis Suarez",
          col2: 134,
          col3: "No",
        },
        {
          key: "EdinsonCavani132No",
          col1: "Edinson Cavani",
          col2: 133,
          col3: "No",
        },
        {
          key: "FernandoMuslera132No",
          col1: "Fernando Muslera",
          col2: 133,
          col3: "No",
        },
        {
          key: "MaxiPereira125Yes",
          col1: "Maxi Pereira",
          col2: 125,
          col3: "Yes",
        },
      ],
      Top5MostInternationalGoalsForUruguayHeadings: [
        {
          key: "Top5MostInternationalGoalsForUruguay",
          col1: "Name",
          col2: "Goals",
          col3: "Caps",
          col4: "Retired?",
        },
      ],
      Top5MostInternationalGoalsForUruguay: [
        {
          key: "LuisSuarez68132No",
          col1: "Luis Suarez",
          col2: 68,
          col3: 134,
          col4: "No",
        },
        {
          key: "EdinsonCavani56132No",
          col1: "Edinson Cavani",
          col2: 58,
          col3: 133,
          col4: "No",
        },
        {
          key: "DiegoForlan36112Yes",
          col1: "Diego Forlan",
          col2: 36,
          col3: 112,
          col4: "Yes",
        },
        {
          key: "HectorScarone3151Yes",
          col1: "Hector Scarone",
          col2: 31,
          col3: 51,
          col4: "Yes",
        },
        {
          key: "AngelRomano2870Yes",
          col1: "Angel Romano",
          col2: 28,
          col3: 70,
          col4: "Yes",
        },
      ],
      Top5LargestFootballStadiumsinEuropeHeadings: [
        {
          key: "Top5LargestFootballStadiumsinEurope",
          col1: "Name",
          col2: "Seating Capacity",
          col3: "City",
          col4: "Country",
        },
      ],
      Top5LargestFootballStadiumsinEurope: [
        {
          key: "CampNou99354BarcelonaSpain",
          col1: "Camp Nou",
          col2: 99354,
          col3: "Barcelona",
          col4: "Spain",
        },
        {
          key: "WembleyStadium90000LondonEngland",
          col1: "Wembley Stadium",
          col2: 90000,
          col3: "London",
          col4: "England",
        },
        {
          key: "SignalIdunaPark81359DortmundGermany",
          col1: "Signal Iduna Park",
          col2: 81359,
          col3: "Dortmund",
          col4: "Germany",
        },
        {
          key: "EstadioSantiagoBernabeu81044MadridSpain",
          col1: "Estadio Santiago Bernabeu",
          col2: 81044,
          col3: "Madrid",
          col4: "Spain",
        },
        {
          key: "LuzhnikiStadium81000MoscowRussia",
          col1: "Luzhniki Stadium",
          col2: 81000,
          col3: "Moscow",
          col4: "Russia",
        },
      ],
      Top6PlayersWithMostChampionsLeagueAppearancesHeadings: [
        {
          key: "Top6PlayersWithMostChampionsLeagueAppearances",
          col1: "Name",
          col2: "Nation",
          col3: "Appearances",
          col4: "Retired?",
        },
      ],
      Top6PlayersWithMostChampionsLeagueAppearances: [
        {
          key: "CristianoRonaldoPortugal183No",
          col1: "Cristiano Ronaldo",
          col2: "Portugal",
          col3: 187,
          col4: "No",
        },
        {
          key: "IkerCasillasSpain177Yes",
          col1: "Iker Casillas",
          col2: "Spain",
          col3: 181,
          col4: "Yes",
        },
        {
          key: "LionelMessiArgentina156No",
          col1: "Lionel Messi",
          col2: "Argentina",
          col3: 160,
          col4: "No",
        },
        {
          key: "XaviSpain151Yes",
          col1: "Xavi",
          col2: "Spain",
          col3: 157,
          col4: "Yes",
        },
        {
          key: "RyanGiggsWales151Yes",
          col1: "Ryan Giggs",
          col2: "Wales",
          col3: 151,
          col4: "Yes",
        },
      ],
      Top5GoalscorersintheChampionsLeagueHeadings: [
        {
          key: "Top5GoalscorersintheChampionsLeague",
          col1: "Name",
          col2: "Goals",
          col3: "Appearances",
          col4: "Retired?",
        },
      ],
      Top5GoalscorersintheChampionsLeague: [
        {
          key: "CristianoRonaldoPortugal140183No",
          col1: "Cristiano Ronaldo",
          col2: 141,
          col3: 187,
          col4: "No",
        },
        {
          key: "LionelMessiArgentina125126No",
          col1: "Lionel Messi",
          col2: 129,
          col3: 160,
          col4: "No",
        },
        {
          key: "RobertLewandowskiPoland86106No",
          col1: "Robert Lewandowski",
          col2: 91,
          col3: 111,
          col4: "No",
        },
        {
          key: "KarimBenzemaFrance86142No",
          col1: "Karim Benzema",
          col2: 86,
          col3: 145,
          col4: "No",
        },
        {
          key: "RaulSpain71142Yes",
          col1: "Raul",
          col2: 71,
          col3: 144,
          col4: "Yes",
        },
      ],
      Top5MostSuccessfulChampionsLeagueTeamsHeadings: [
        {
          key: "Top5MostSuccessfulChampionsLeagueTeams",
          col1: "Team",
          col2: "Country",
          col3: "Wins",
          col4: "Runners Up",
        },
      ],
      Top5MostSuccessfulChampionsLeagueTeams: [
        {
          key: "RealMadridSpain143",
          col1: "Real Madrid",
          col2: "Spain",
          col3: 14,
          col4: 3,
        },
        {
          key: "ACMilanItaly74",
          col1: "AC Milan",
          col2: "Italy",
          col3: 7,
          col4: 4,
        },
        {
          key: "BayernMunichGermany65",
          col1: "Bayern Munich",
          col2: "Germany",
          col3: 6,
          col4: 5,
        },
        {
          key: "LiverpoolEngland64",
          col1: "Liverpool",
          col2: "England",
          col3: 6,
          col4: 4,
        },
        {
          key: "BarcelonaSpain53",
          col1: "Barcelona",
          col2: "Spain",
          col3: 5,
          col4: 3,
        },
      ],
      Top5TopFlightLeagueTitlesHeadings: [
        {
          key: "Top5TopFlightLeagueTitles",
          col1: "Club",
          col2: "Wins",
          col3: "Runners Up",
        },
      ],
      Top5TopFlightLeagueTitles: [
        {
          key: "ManchesterUnited2017",
          col1: "Manchester United",
          col2: 20,
          col3: 17,
        },
        {
          key: "Liverpool1915",
          col1: "Liverpool",
          col2: 19,
          col3: 15,
        },
        {
          key: "Arsenal139",
          col1: "Arsenal",
          col2: 13,
          col3: 9,
        },
        {
          key: "Everton97",
          col1: "Everton",
          col2: 9,
          col3: 7,
        },
        {
          key: "ManchesterCity86",
          col1: "Manchester City",
          col2: 8,
          col3: 6,
        },
      ],
      Top5PremierLeagueGoalscorersHeadings: [
        {
          key: "Top5PremierLeagueGoalscorers",
          col1: "Name",
          col2: "Goals",
          col3: "Appearances",
          col4: "Retired?",
        },
      ],
      Top5PremierLeagueGoalscorers: [
        {
          key: "AlanShearer260441Yes",
          col1: "Alan Shearer",
          col2: 260,
          col3: 441,
          col4: "Yes",
        },
        {
          key: "WayneRooney208491Yes",
          col1: "Wayne Rooney",
          col2: 208,
          col3: 491,
          col4: "Yes",
        },
        {
          key: "HarryKane187285No",
          col1: "Harry Kane",
          col2: 193,
          col3: 295,
          col4: "No",
        },
        {
          key: "AndyCole187414Yes",
          col1: "Andy Cole",
          col2: 187,
          col3: 414,
          col4: "Yes",
        },
        {
          key: "SergioAguero184275Yes",
          col1: "Sergio Aguero",
          col2: 184,
          col3: 275,
          col4: "Yes",
        },
      ],
      Top5TopFlightEnglishLeagueGoalscorersHeadings: [
        {
          key: "Top5TopFlightEnglishLeagueGoalscorers",
          col1: "Name",
          col2: "Goals",
          col3: "Appearances",
          col4: "Retired?",
        },
      ],
      Top5TopFlightEnglishLeagueGoalscorers: [
        {
          key: "JimmyGreaves357516Yes",
          col1: "Jimmy Greaves",
          col2: 357,
          col3: 516,
          col4: "Yes",
        },
        {
          key: "AlanShearer283559Yes",
          col1: "Alan Shearer",
          col2: 283,
          col3: 559,
          col4: "Yes",
        },
        {
          key: "GordonHodgson272415Yes",
          col1: "Gordon Hodgson",
          col2: 272,
          col3: 415,
          col4: "Yes",
        },
        {
          key: "DixieDean259296Yes",
          col1: "Dixie Dean",
          col2: 259,
          col3: 296,
          col4: "Yes",
        },
        {
          key: "NatLofthouse253452Yes",
          col1: "Nat Lofthouse",
          col2: 253,
          col3: 452,
          col4: "Yes",
        },
      ],
      Top5FACupWinsHeadings: [
        {
          key: "Top5FACupWins",
          col1: "Team",
          col2: "Wins",
          col3: "Runners Up",
        },
      ],
      Top5FACupWins: [
        {
          key: "Arsenal147",
          col1: "Arsenal",
          col2: 14,
          col3: 7,
        },
        {
          key: "ManchesterUnited128",
          col1: "Manchester United",
          col2: 12,
          col3: 8,
        },
        {
          key: "Chelsea88",
          col1: "Chelsea",
          col2: 8,
          col3: 8,
        },
        {
          key: "Liverpool87",
          col1: "Liverpool",
          col2: 8,
          col3: 7,
        },
        {
          key: "TottenhamHotspur81",
          col1: "Tottenham Hotspur",
          col2: 8,
          col3: 1,
        },
      ],
      Top5LeagueCupWinsHeadings: [
        {
          key: "Top5LeagueCupWins",
          col1: "Team",
          col2: "Wins",
          col3: "Runners Up",
        },
      ],
      Top5LeagueCupWins: [
        {
          key: "Liverpool94",
          col1: "Liverpool",
          col2: 9,
          col3: 4,
        },
        {
          key: "ManchesterCity81",
          col1: "Manchester City",
          col2: 8,
          col3: 1,
        },
        {
          key: "AstonVilla54",
          col1: "Aston Villa",
          col2: 5,
          col3: 4,
        },
        {
          key: "Chelsea54",
          col1: "Chelsea",
          col2: 5,
          col3: 4,
        },
        {
          key: "ManchesterUnited54",
          col1: "Manchester United",
          col2: 5,
          col3: 4,
        },
      ],
      Last5LeagueCupSponsorsHeadings: [
        {
          key: "Last5LeagueCupSponsors",
          col1: "Sponser",
          col2: "Name",
          col3: "Period",
        },
      ],
      Last5LeagueCupSponsors: [
        {
          key: "CarabaoTheCarabaoCup2017-2024",
          col1: "Carabao",
          col2: "The Carabao Cup",
          col3: "2017-2024",
        },
        {
          key: "CapitalOneTheCapitalOneCup2012-2016",
          col1: "Capital One",
          col2: "The Capital One Cup",
          col3: "2012-2016",
        },
        {
          key: "MolsonCoors/CarlingTheCarlingCup2003-2012",
          col1: "Molson Coors/Carling",
          col2: "The Carling Cup",
          col3: "2003-2012",
        },
        {
          key: "Worthington'sTheWorthingtonCup1998-2003",
          col1: "Worthington's",
          col2: "The Worthington Cup",
          col3: "1998-2003",
        },
        {
          key: "Coca-ColaTheCoca-ColaCup1992-1998",
          col1: "Coca-Cola",
          col2: "The Coca-Cola Cup",
          col3: "1992-1998",
        },
      ],
    },
  },
  tennis: {
    wimbledonMensSinglesWinnersHeadings: [
      {
        key: "wimbledonMensSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    wimbledonMensSinglesWinners: [
      {
        key: "Djokovic2022",
        col1: 2022,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "Djokovic2021",
        col1: 2021,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "Djokovic2019",
        col1: 2019,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "Djokovic2018",
        col1: 2018,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "Federer2017",
        col1: 2017,
        col2: "Roger Federer",
        col3: "Switzerland",
      },
    ],
    wimbledonLadiesSinglesWinnersHeadings: [
      {
        key: "wimbledonLadiesSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    wimbledonLadiesSinglesWinners: [
      {
        key: "ElenaRybakina2022",
        col1: 2022,
        col2: "Elena Rybakina",
        col3: "Kazakhstan",
      },
      {
        key: "AshleighBarty2021",
        col1: 2021,
        col2: "Ashleigh Barty",
        col3: "Australia",
      },
      {
        key: "SimonaHalep2019",
        col1: 2019,
        col2: "Simona Halep",
        col3: "Romania",
      },
      {
        key: "AngeliqueKerber2018",
        col1: 2018,
        col2: "Angelique Kerber",
        col3: "Germany",
      },
      {
        key: "GarbiñeMuguruza2017",
        col1: 2017,
        col2: "Garbiñe Muguruza",
        col3: "Spain",
      },
    ],
    wimbledonMensDoublesWinnersHeadings: [
      {
        key: "wimbledonMensDoublesWinners",
        col1: "Year",
        col2: "Player 1",
        col3: "Player 2",
      },
    ],
    wimbledonMensDoublesWinners: [
      {
        key: "EbdenPurcell2022",
        col1: 2022,
        col2: "Matthew Ebden",
        col2country: "(Australia)",
        col3: "Max Purcell",
        col3country: "(Australia)",
      },
      {
        key: "MekticPavic2021",
        col1: 2021,
        col2: "Nikola Mektic",
        col2country: "(Croatia)",
        col3: "Mate Pavic",
        col3country: "(Croatia)",
      },
      {
        key: "CabalFarah2019",
        col1: 2019,
        col2: "Juan Sebastián Cabal",
        col2country: "(Colombia)",
        col3: "Robert Farah",
        col3country: "(Colombia)",
      },
      {
        key: "BryanSock2018",
        col1: 2018,
        col2: "Mike Bryan",
        col2country: "(United States)",
        col3: "Jack Sock",
        col3country: "(United States)",
      },
      {
        key: "KubotMelo2017",
        col1: 2017,
        col2: "Łukasz Kubot",
        col2country: "(Poland)",
        col3: "Marcelo Melo",
        col3country: "(Brazil)",
      },
    ],
    wimbledonLadiesDoublesWinnersHeadings: [
      {
        key: "wimbledonLadiesDoublesWinners",
        col1: "Year",
        col2: "Player 1",
        col3: "Player 2",
      },
    ],
    wimbledonLadiesDoublesWinners: [
      {
        key: "KrejcikovaSiniakova2022",
        col1: 2022,
        col2: "Barbora Krejcikova",
        col2country: "(Czech Republic)",
        col3: "Katerina Siniakova",
        col3country: "(Czech Republic)",
      },
      {
        key: "Su-weiMertens2021",
        col1: 2021,
        col2: "Hsieh Su-wei",
        col2country: "(Chinese Taipei)",
        col3: "Elise Mertens",
        col3country: "(Germany)",
      },
      {
        key: "Su-weiStrýcová2019",
        col1: 2019,
        col2: "Hsieh Su-wei",
        col2country: "(Chinese Taipei)",
        col3: "Barbora Strýcová",
        col3country: "(Czech Republic)",
      },
      {
        key: "KrejčíkováSiniaková2018",
        col1: 2018,
        col2: "Barbora Krejčíková",
        col2country: "(Czech Republic)",
        col3: "Kateřina Siniaková",
        col3country: "(Czech Republic)",
      },
      {
        key: "MakarovaVesnina2017",
        col1: 2017,
        col2: "Ekaterina Makarova",
        col2country: "(Russia)",
        col3: "Elena Vesnina",
        col3country: "(Russia)",
      },
    ],
    wimbledonMixedDoublesWinnersHeadings: [
      {
        key: "wimbledonMixedDoublesWinners",
        col1: "Year",
        col2: "Player 1",
        col3: "Player 2",
      },
    ],
    wimbledonMixedDoublesWinners: [
      {
        key: "KrawczykSkupski2022",
        col1: 2022,
        col2: "Desirae Krawczyk",
        col2country: "(USA)",
        col3: "Neal Skupski",
        col3country: "(UK)",
      },
      {
        key: "KrawczykSkupski2021",
        col1: 2021,
        col2: "Desirae Krawczyk",
        col2country: "(USA)",
        col3: "Neal Skupski",
        col3country: "(UK)",
      },
      {
        key: "ChanDodig2019",
        col1: 2019,
        col2: "Latisha Chan",
        col2country: "(Chinese Taipei)",
        col3: "Ivan Dodig",
        col3country: "(Croatia)",
      },
      {
        key: "MelicharPeya2018",
        col1: 2018,
        col2: "Nicole Melichar",
        col2country: "(Czech Republic)",
        col3: "Alexander Peya",
        col3country: "(Austria)",
      },
      {
        key: "HingisMurray2017",
        col1: 2017,
        col2: "Martina Hingis",
        col2country: "(Switzerland)",
        col3: "Jamie Murray",
        col3country: "(UK)",
      },
    ],
    totalMensOpenEraSinglesWinsHeadings: [
      {
        key: "totalMensOpenEraSinglesWins",
        col1: "Country",
        col2: "Wins",
      },
    ],
    totalMensOpenEraSinglesWins: [
      {
        key: "USA15opens",
        col1: "USA",
        col2: 15,
      },
      {
        key: "Swiss8opens",
        col1: "Switzerland",
        col2: 8,
      },
      {
        key: "Sweden7opens",
        col1: "Sweden",
        col2: 7,
      },
      {
        key: "Serbia6opens",
        col1: "Serbia",
        col2: 7,
      },
      {
        key: "Australia6opens",
        col1: "Australia",
        col2: 6,
      },
    ],
    totalLadiesOpenEraSinglesWinsHeadings: [
      {
        key: "totalLadiesOpenEraSinglesWins",
        col1: "Country",
        col2: "Wins",
      },
    ],
    totalLadiesOpenEraSinglesWins: [
      {
        key: "USA29opens",
        col1: "USA",
        col2: 29,
      },
      {
        key: "Germany8opens",
        col1: "Germany",
        col2: 8,
      },
      {
        key: "Australia4opens",
        col1: "Australia",
        col2: 4,
      },
      {
        key: "Czech3opens",
        col1: "Czech Republic",
        col2: 3,
      },
      {
        key: "GreatBritain2opens",
        col1: "Great Britain",
        col2: 2,
      },
    ],
    totalMensAmateurEraSinglesWinsHeadings: [
      {
        key: "totalMensAmateurEraSinglesWins",
        col1: "Country",
        col2: "Wins",
      },
    ],
    totalMensAmateurEraSinglesWins: [
      {
        key: "Britain35amateurs",
        col1: "Great Britain",
        col2: 35,
      },
      {
        key: "USA18amateurs",
        col1: "USA",
        col2: 18,
      },
      {
        key: "Australia15amateurs",
        col1: "Australia",
        col2: 15,
      },
      {
        key: "France7amateurs",
        col1: "France",
        col2: 7,
      },
      {
        key: "NewZealand4amateurs",
        col1: "New Zealand",
        col2: 4,
      },
    ],
    totalLadiesAmateurEraSinglesWinsHeadings: [
      {
        key: "totalLadiesAmateurEraSinglesWins",
        col1: "Country",
        col2: "Wins",
      },
    ],
    totalLadiesAmateurEraSinglesWins: [
      {
        key: "UK34amateurs",
        col1: "UK",
        col2: 34,
      },
      {
        key: "USA28amateurs",
        col1: "USA",
        col2: 28,
      },
      {
        key: "France6amateurs",
        col1: "France",
        col2: 6,
      },
      {
        key: "Brazil3amateurs",
        col1: "Brazil",
        col2: 3,
      },
      {
        key: "Australia2amateurs",
        col1: "Australia",
        col2: 2,
      },
    ],
    AustralianOpenRecordsHeadings: [
      {
        key: "AustralianOpenRecords",
        col1: "Record",
        col2: "Player",
        col3: "Count",
      },
    ],
    AustralianOpenRecords: [
      {
        key: "AUSDjokovic9",
        col1: "Most Mens Singles Titles",
        col2: "Novak Djokovic (Serbia)",
        col3: 9,
      },
      {
        key: "AUSSerenaWilliams7",
        col1: "Most Womens Singles Titles",
        col2: "Serena Williams (US)",
        col3: 7,
      },
      {
        key: "AUSDoublesBryan6",
        col1: "Most Mens Doubles Titles",
        col2: "Bob Bryan (US), Mike Bryan (US)",
        col3: 6,
      },
      {
        key: "AUSNavratilova8",
        col1: "Most Womens Doubles Titles",
        col2: "Martina Navratilova (US)",
        col3: 8,
      },
      {
        key: "AUSKrejčíková3",
        col1: "Most Mixed Doubles Titles (Woman)",
        col2: "Barbora Krejčíková (Czechia)",
        col3: 3,
      },
      {
        key: "AUSPughPaesNestor3",
        col1: "Most Mixed Doubles Titles (Man)",
        col2: "Jim Pugh (US), Leander Paes (India), Daniel Nestor (Canada)",
        col3: 3,
      },
    ],
    usOpenMensSinglesWinnersHeadings: [
      {
        key: "usOpenMensSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    usOpenMensSinglesWinners: [
      {
        key: "CarlosAlcaraz2022",
        col1: 2022,
        col2: "Carlos Alcaraz",
        col3: "Spain",
      },
      {
        key: "DaniilMedvedev2021",
        col1: 2021,
        col2: "Daniil Medvedev",
        col3: "Russia",
      },
      {
        key: "DominicThiem2020",
        col1: 2020,
        col2: "Dominic Thiem",
        col3: "Austria",
      },
      {
        key: "RafaelNadal2019",
        col1: 2019,
        col2: "Rafael Nadal",
        col3: "Spain",
      },
      {
        key: "NovakDjokovic2018",
        col1: 2018,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
    ],
    usOpenWomensSinglesWinnersHeadings: [
      {
        key: "usOpenWomensSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    usOpenWomensSinglesWinners: [
      {
        key: "IgaSwiatek2022",
        col1: 2022,
        col2: "Iga Swiatek",
        col3: "Poland",
      },
      {
        key: "EmmaRaducanu2021",
        col1: 2021,
        col2: "Emma Raducanu",
        col3: "UK",
      },
      {
        key: "NaomiOsaka2020",
        col1: 2020,
        col2: "Naomi Osaka",
        col3: "Japan",
      },
      {
        key: "BiancaAndreescu2019",
        col1: 2019,
        col2: "Bianca Andreescu",
        col3: "Canada",
      },
      {
        key: "NaomiOsaka2018",
        col1: 2018,
        col2: "Naomi Osaka",
        col3: "Japan",
      },
    ],
    frenchOpenMensSinglesWinnersHeadings: [
      {
        key: "frenchOpenMensSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    frenchOpenMensSinglesWinners: [
      {
        key: "RafaelNadal2022frenchO",
        col1: 2022,
        col2: "Rafael Nadal",
        col3: "Spain",
      },
      {
        key: "NovakDjokovic2021frenchO",
        col1: 2021,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "RafaelNadal2020french0",
        col1: 2020,
        col2: "Rafael Nadal",
        col3: "Spain",
      },
      {
        key: "RafaelNadal2019french0",
        col1: 2019,
        col2: "Rafael Nadal",
        col3: "Spain",
      },
      {
        key: "RafaelNadal2018french0",
        col1: 2018,
        col2: "Rafael Nadal",
        col3: "Spain",
      },
    ],
    frenchOpenWomensSinglesWinnersHeadings: [
      {
        key: "frenchOpenWomensSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    frenchOpenWomensSinglesWinners: [
      {
        key: "IgaSwiatek2022frenchO",
        col1: 2022,
        col2: "Iga Swiatek",
        col3: "Poland",
      },
      {
        key: "BarboraKrejcikov2021frenchO",
        col1: 2021,
        col2: "Barbora Krejcikova",
        col3: "Czechia",
      },
      {
        key: "IgaSwiatek2020frenchO",
        col1: 2020,
        col2: "Iga Swiatek",
        col3: "Poland",
      },
      {
        key: "AshleighBarty2019frenchO",
        col1: 2019,
        col2: "Ashleigh Barty",
        col3: "Australia",
      },
      {
        key: "SimonaHalep2018frenchO",
        col1: 2018,
        col2: "Simona Halep",
        col3: "Romania",
      },
    ],
    australianOpenMensSinglesWinnersHeadings: [
      {
        key: "australianOpenMensSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    australianOpenMensSinglesWinners: [
      {
        key: "RafaelNadal2022ausO",
        col1: 2022,
        col2: "Rafael Nadal",
        col3: "Spain",
      },
      {
        key: "NovakDjokovic2021ausO",
        col1: 2021,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "NovakDjokovic2020ausO",
        col1: 2020,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "NovakDjokovic2019ausO",
        col1: 2019,
        col2: "Novak Djokovic",
        col3: "Serbia",
      },
      {
        key: "RogerFederer2018aus0",
        col1: 2018,
        col2: "Roger Federer",
        col3: "Switzerland",
      },
    ],
    australianOpenWomensSinglesWinnersHeadings: [
      {
        key: "australianOpenWomensSinglesWinners",
        col1: "Year",
        col2: "Player",
        col3: "Country",
      },
    ],
    australianOpenWomensSinglesWinners: [
      {
        key: "AshleighBarty2022ausO",
        col1: 2022,
        col2: "Ashleigh Bart",
        col3: "Australia",
      },
      {
        key: "NaomiOsaka2021ausO",
        col1: 2021,
        col2: "Naomi Osaka",
        col3: "Japan",
      },
      {
        key: "SofiaKenin2020ausO",
        col1: 2020,
        col2: "Sofia Kenin",
        col3: "USA",
      },
      {
        key: "NaomiOsaka2019ausO",
        col1: 2019,
        col2: "Naomi Osaka",
        col3: "Japan",
      },
      {
        key: "CarolineWozniacki2018ausO",
        col1: 2018,
        col2: "Caroline Wozniacki",
        col3: "Denmark",
      },
    ],
  },
  iceHockey: {
    stanleyCupWinsHeadings: [
      {
        key: "stanleyCupWins",
        col1: "Year",
        col2: "Team",
      },
    ],
    stanleyCupWins: [
      {
        key: "CAStanley2022",
        col1: 2022,
        col2: "Colorado Avalanche",
      },
      {
        key: "TBLStanley2021",
        col1: 2021,
        col2: "Tampa Bay Lightning",
      },
      {
        key: "TBLtanley2020",
        col1: 2020,
        col2: "Tampa Bay Lightning",
      },
      {
        key: "SLBStanley2019",
        col1: 2019,
        col2: "St. Louis Blues",
      },
      {
        key: "WCStanley2018",
        col1: 2018,
        col2: "Washington Capitals",
      },
    ],
  },
  basketball: {
    NBAFinalsWinnerHeadings: [
      {
        key: "NBAFinalsWinner",
        col1: "Year",
        col2: "Team",
      },
    ],
    NBAFinalsWinner: [
      {
        key: "GSWNBA2022",
        col1: 2022,
        col2: "Golden State Warriors",
      },
      {
        key: "MBNBA2021",
        col1: 2021,
        col2: "Milwaukee Bucks",
      },
      {
        key: "LALNBA2020",
        col1: 2020,
        col2: "Los Angeles Lakers",
      },
      {
        key: "TRNBA2019",
        col1: 2019,
        col2: "Toronto Raptors",
      },
      {
        key: "GSWNBA2018",
        col1: 2018,
        col2: "Golden State Warriors",
      },
    ],
  },
  baseball: {
    MLBWorldSeriesFinalsWinnerHeadings: [
      {
        key: "MLBWorldSeriesFinalsWinner",
        col1: "Year",
        col2: "Team",
      },
    ],
    MLBWorldSeriesFinalsWinner: [
      {
        key: "ABWS2021",
        col1: 2021,
        col2: "Atlanta Braves",
      },
      {
        key: "LADWS2020",
        col1: 2020,
        col2: "Los Angeles Dodgers",
      },
      {
        key: "WNWS2019",
        col1: 2019,
        col2: "Washington Nationals",
      },
      {
        key: "BRSWS2018",
        col1: 2018,
        col2: "Boston Red Sox",
      },
      {
        key: "HAWS2017",
        col1: 2017,
        col2: "Houston Astros",
      },
    ],
  },
  americanFootball: {
    lastFiveSuperbowlsHeadings: [
      {
        key: "lastFiveSuperbowls",
        col1: "Year",
        col2: "Team",
      },
    ],
    lastFiveSuperbowls: [
      {
        key: "2022LARamsSuperBowl",
        col1: 2022,
        col2: "Las Angeles Rams",
      },
      {
        key: "2021TBBuccaneersSuperBowl",
        col1: 2021,
        col2: "Tampa Bay Buccaneers",
      },
      {
        key: "2020KCCheifssuperBowl",
        col1: 2020,
        col2: "Kansas City Cheifs",
      },
      {
        key: "2019NEPatriotsSuperBowl",
        col1: 2019,
        col2: "New England Patriots",
      },
      {
        key: "2018PhilEaglesSuperBowl",
        col1: 2018,
        col2: "Philadelphia Eagles",
      },
    ],
    topSixSuperbowlHeadings: [
      { key: "topSixSuperbowl", col2: "Wins", col1: "Team" },
    ],
    topSixSuperbowl: [
      { key: "PittsburghSteelers,6", col2: 6, col1: "Pittsburgh Steelers" },
      { key: "NewEnglandPatriots,6", col2: 6, col1: "New England Patriots" },
      { key: "DallasCowboys,5", col2: 5, col1: "Dallas Cowboys" },
      { key: "San Francisco 49ers,5", col2: 5, col1: "San Francisco 49ers" },
      { key: "Green Bay Packers,4", col2: 4, col1: "Green Bay Packers" },
      { key: "New York Giants,4", col2: 4, col1: "New York Giants" },
    ],
  },
  horseRacing: {
    grandNationalHeadings: [
      {
        key: "grandNational",
        col1: "Year",
        col2: "Horse",
      },
    ],
    grandNational: [
      {
        key: "NobleYeats2022",
        col1: 2022,
        col2: "Noble Yeats",
      },
      {
        key: "MinellaTimes2021",
        col1: 2021,
        col2: "Minella Times",
      },
      {
        key: "TigerRoll2019",
        col1: 2019,
        col2: "Tiger Roll",
      },
      {
        key: "TigerRoll2018",
        col1: 2018,
        col2: "Tiger Roll",
      },
      {
        key: "OneForArthur2017",
        col1: 2017,
        col2: "One For Arthur",
      },
    ],
  },
  darts: {
    pdcChampsLastFiveWinnersHeadings: [
      {
        key: "pdcChampsLastFiveWinners",
        col1: "Year",
        col2: "Winner",
      },
    ],
    pdcChampsLastFiveWinners: [
      {
        key: "PeterWright2022",
        col1: 2022,
        col2: "Peter Wright",
      },
      {
        key: "Gerwyn Price2021",
        col1: 2021,
        col2: "Gerwyn Price",
      },
      {
        key: "PeterWright2020",
        col1: 2020,
        col2: "Peter Wright",
      },
      {
        key: "MichaelvanGerwen2019",
        col1: 2019,
        col2: "Michael van Gerwen",
      },
      {
        key: "RobCross2018",
        col1: 2018,
        col2: "Rob Cross",
      },
    ],
    pdcmostWinsHeadings: [
      {
        key: "pdcmostWins",
        col2: "Wins",
        col1: "Winner",
      },
    ],
    pdcmostWins: [
      {
        key: "PhilTaylor14",
        col2: 14,
        col1: "Phil Taylor",
      },
      {
        key: "MichaelvanGerwen3",
        col2: 3,
        col1: "Michael van Gerwen",
      },
      {
        key: "GaryAnderson2",
        col2: 2,
        col1: "Gary Anderson",
      },
      {
        key: "AdrianLewis2",
        col2: 2,
        col1: "Adrian Lewis",
      },
      {
        key: "JohnPart2",
        col2: 2,
        col1: "John Part",
      },
      {
        key: "PeterWright2",
        col2: 2,
        col1: "Peter Wright",
      },
    ],
  },
  snooker: {
    lastFiveWorldChampsHeadings: [
      {
        key: "lastFiveWorldChamps",
        col1: "Year",
        col2: "Winner",
      },
    ],
    lastFiveWorldChamps: [
      {
        key: "RonnieO'Sullivan2022",
        col1: 2022,
        col2: "Ronnie O'Sullivan",
      },
      {
        key: "MarkSelby2021",
        col1: 2021,
        col2: "Mark Selby",
      },
      {
        key: "RonnieO'Sullivan2020",
        col1: 2020,
        col2: "Ronnie O'Sullivan",
      },
      {
        key: "JuddTrump2019",
        col1: 2019,
        col2: "Judd Trump",
      },
      {
        key: "MarkWilliams2018",
        col1: 2018,
        col2: "Mark Williams",
      },
    ],
    snookerMostWinsHeadings: [
      {
        key: "snookerMostWins",
        col2: "Wins",
        col1: "Winner",
      },
    ],
    snookerMostWins: [
      {
        key: "JoeDavis15",
        col2: 15,
        col1: "Joe Davis",
      },
      {
        key: "FredDavis8",
        col2: 8,
        col1: "Fred Davis",
      },
      {
        key: "JohnPulman8",
        col2: 8,
        col1: "John Pulman",
      },
      {
        key: "StephenHendry7",
        col2: 7,
        col1: "Stephen Hendry",
      },
      {
        key: "Ronnie O'Sullivan7",
        col2: 7,
        col1: "Ronnie O'Sullivan",
      },
    ],
  },
  summerOlympics: {
    lastfiveHostsHeadings: [
      {
        key: "lastfiveHosts",
        col1: "Year",
        col2: "Host",
      },
    ],
    lastfiveHosts: [
      {
        key: "TokyoSO2020",
        col1: 2020,
        col2: "Tokyo",
      },
      {
        key: "RioDJSO2016",
        col1: 2016,
        col2: "Rio de Janeiro",
      },
      {
        key: "LondonSO2012",
        col1: 2012,
        col2: "London",
      },
      {
        key: "BeijingSO2008",
        col1: 2008,
        col2: "Beijing",
      },
      {
        key: "TokyoSO2004",
        col1: 2004,
        col2: "Athens",
      },
    ],
    lastOlympicsGoldMedalsHeadings: [
      {
        key: "lastOlympicsGoldMedals",
        col2: "Golds",
        col1: "Country",
      },
    ],
    lastOlympicsGoldMedals: [
      {
        key: "USAgoldsSO",
        col2: 39,
        col1: "USA",
      },
      {
        key: "ChinagoldsSO",
        col2: 38,
        col1: "China",
      },
      {
        key: "JapangoldsSO",
        col2: 27,
        col1: "Japan",
      },
      {
        key: "GreatBritSO",
        col2: 22,
        col1: "Great Britain",
      },
      {
        key: "RussianOlympicCommitteegoldsSO",
        col2: 20,
        col1: "Russian Olympic Committee",
      },
    ],
    alltimeOlympicsGoldMedalsHeadings: [
      {
        key: "alltimeOlympicsGoldMedals",
        col2: 1060,
        col1: "USA",
      },
    ],
    alltimeOlympicsGoldMedals: [
      {
        key: "USAgoldsAlltimeSO",
        col2: 1060,
        col1: "USA",
      },
      {
        key: "SovietUniongoldsAlltimeSO",
        col2: 395,
        col1: "Soviet Union (defunct nation)",
      },
      {
        key: "GreatBritaingoldsAlltimeSO",
        col2: 285,
        col1: "Great Britain",
      },
      {
        key: "ChinagoldsAllTimeSO",
        col2: 262,
        col1: "China",
      },
      {
        key: "FrancegoldsAlltimeSO",
        col2: 223,
        col1: "France",
      },
    ],
  },
  winterOlympics: {
    allTimeGoldsHeadings: [
      {
        key: "allTimeGolds",
        col2: "Golds",
        col1: "Country",
      },
    ],
    allTimeGolds: [
      {
        key: "NorwaygoldsAlltimeWO",
        col2: 148,
        col1: "Norway",
      },
      {
        key: "USAgoldsAlltimeWO",
        col2: 113,
        col1: "USA",
      },
      {
        key: "GermanygoldsAlltimeWO",
        col2: 102,
        col1: "Germany",
      },
      {
        key: "SovietgoldsAlltimeWO",
        col2: 78,
        col1: "Soviet Union (defunct nation)",
      },
      {
        key: "CanadagoldsAlltimeWO",
        col2: 77,
        col1: "Canada",
      },
    ],
    lastWOgoldsHeadings: [
      {
        key: "lastWOgolds",
        col2: "Golds",
        col1: "Country",
      },
    ],
    lastWOgolds: [
      {
        key: "NorwaygoldsWO",
        col2: 14,
        col1: "Norway",
      },
      {
        key: "GermanygoldsWO",
        col2: 14,
        col1: "Germany",
      },
      {
        key: "CanadagoldsWO",
        col2: 11,
        col1: "Canada",
      },
      {
        key: "USAgoldsWO",
        col2: 9,
        col1: "USA",
      },
      {
        key: "NetherlandsgoldsWO",
        col2: 8,
        col1: "Netherlands",
      },
    ],
    lastfiveHostsHeadings: [
      {
        key: "lastfiveHosts",
        col1: "Year",
        host: "Host",
      },
    ],
    lastfiveHosts: [
      {
        key: "BeijingWO2022",
        col1: 2022,
        col2: "Beijing",
      },
      {
        key: "PyeongchangWO2018",
        col1: 2018,
        col2: "Pyeongchang",
      },
      {
        key: "SochiWO2014",
        col1: 2014,
        col2: "Sochi",
      },
      {
        key: "VancouverWO2010",
        col1: 2010,
        col2: "Vancouver",
      },
      {
        key: "TurinWO2006",
        col1: 2006,
        col2: "Turin",
      },
    ],
  },
  britishSportsAwards: {
    sportsPersonalityLastFiveHeadings: [
      {
        key: "sportsPersonalityLastFive",
        col1: "Year",
        col2: "winner",
      },
    ],
    sportsPersonalityLastFive: [
      {
        key: "EmmaRaducanuSPOTY2021",
        col1: 2021,
        col2: "Emma Raducanu",
      },
      {
        key: "LewisHamiltonSPOTY2020",
        col1: 2020,
        col2: "Lewis Hamilton",
      },
      {
        key: "BenStokesSPOTY2019",
        col1: 2019,
        col2: "Ben Stokes",
      },
      {
        key: "GeraintThomasSPOTY2018",
        col1: 2018,
        col2: "Geraint Thomas",
      },
      {
        key: "MoFarahSPOTY2017",
        col1: 2017,
        col2: "Mo Farah",
      },
    ],
    sportsPersonalityMostWinsHeadings: [
      {
        key: "sportsPersonalityMostWins",
        col2: "Wins",
        col1: "Winner",
      },
    ],
    sportsPersonalityMostWins: [
      {
        key: "AndyMurraySPOTY",
        col2: 3,
        col1: "Andy Murray",
      },
      {
        key: "LewisHamiltonSPOTY",
        col2: 2,
        col1: "Lewis Hamilton",
      },
      {
        key: "NigelMansellSPOTY",
        col2: 2,
        col1: "Nigel Mansell",
      },
      {
        key: "HenryCooperSPOTY",
        col2: 2,
        col1: "Henry Cooper",
      },
      {
        key: "DamonHillSPOTY",
        col2: 2,
        col1: "Damon Hill",
      },
    ],
  },
  cricket: {
    testRunsAllTimeHeadings: [
      {
        key: "testRunsAllTime",
        col1: "Player",
        col2: "Runs",
      },
    ],
    testRunsAllTime: [
      {
        key: "AlastairCookATruns",
        col1: "Alastair Cook",
        col2: 12472,
      },
      {
        key: "JoeRootATruns",
        col1: "Joe Root",
        col2: 10285,
      },
      {
        key: "GrahamGoochATruns",
        col1: "Graham Gooch",
        col2: 8900,
      },
      {
        key: "AlecStewartATruns",
        col1: "Alec Stewart",
        col2: 8231,
      },
      {
        key: "DavidGowerATruns",
        col1: "David Gower",
        col2: 8231,
      },
    ],
    testWicketsAllTimeHeadings: [
      {
        key: "testWicketsAllTime",
        col1: "Player",
        col2: "Wickets",
      },
    ],
    testWicketsAllTime: [
      {
        key: "JamesAndersonATwickets",
        col1: "James Anderson",
        col2: 651,
      },
      {
        key: "StuartBroadATwickets",
        col1: "Stuart Broad",
        col2: 549,
      },
      {
        key: "IanBothamATwickets",
        col1: "Ian Botham",
        col2: 383,
      },
      {
        key: "BobWillisATwickets",
        col1: "Bob Willis",
        col2: 325,
      },
      {
        key: "FredTruemanATwickets",
        col1: "Fred Trueman",
        col2: 307,
      },
    ],
    testsPlayedAllTimeHeadings: [
      {
        key: "testsPlayedAllTime",
        col1: "Player",
        col2: "Matches",
      },
    ],
    testsPlayedAllTime: [
      {
        key: "JamesAndersonATmatches",
        col1: "James Anderson",
        col2: 171,
      },
      {
        key: "AlastairCookATmatches",
        col1: "Alastair Cook",
        col2: 161,
      },
      {
        key: "StuartBroadATmatches",
        col1: "Stuart Broad",
        col2: 155,
      },
      {
        key: "AlecStewartATmatches",
        col1: "Alec Stewart",
        col2: 133,
      },
      {
        key: "JoeRootATmatches",
        col1: "Joe Root",
        col2: 120,
      },
    ],
  },
};

const topFivesEntertainment = {
  mercuryPrizeHeadings: [
    {
      key: "mercuryPrize",
      col1: "Year",
      col2: "Artist",
      col3: "Album",
    },
  ],
  mercuryPrize: [
    {
      key: "LittleSimz2022",
      col1: 2022,
      col2: "Little Simz",
      col3: "Sometimes I Might Be Introvert",
    },
    {
      key: "ArloParks2021",
      col1: 2021,
      col2: "Arlo Parks",
      col3: "Collapsed In Sunbeams",
    },
    {
      key: "MichaelKiwanuka2020",
      col1: 2020,
      col2: "Michael Kiwanuka",
      col3: "Kiwanuka",
    },
    {
      key: "Dave2019",
      col1: 2019,
      col2: "Dave",
      col3: "Psychodrama",
    },
    {
      key: "WolfAlice2018",
      col1: 2018,
      col2: "Wolf ALice",
      col3: "Visions of a Life",
    },
  ],
  bestPictureOscarHeadings: [
    {
      key: "bestPictureOscar",
      col1: "Year",
      col2: "Film",
    },
  ],
  bestPictureOscar: [
    {
      key: "CODA2022",
      col1: 2022,
      col2: "CODA",
    },
    {
      key: "Nomadland2021",
      col1: 2021,
      col2: "Nomadland",
    },
    {
      key: "Parasite2020",
      col1: 2020,
      col2: "Parasite",
    },
    {
      key: "GreenBook2019",
      col1: 2019,
      col2: "Green Book",
    },
    {
      key: "ShapeOfWater2018",
      col1: 2018,
      col2: "Shape of Water",
    },
  ],
  EGOTwinnersHeadings: [
    {
      key: "EGOTwinners",
      col1: "Year",
      col2: "Winner",
    },
  ],
  EGOTwinners: [
    {
      key: "JenniferHudson2021",
      col1: 2021,
      col2: "Jennifer Hudson",
    },
    {
      key: "AlanMenken2020",
      col1: 2020,
      col2: "Alan Menken",
    },
    {
      key: "JohnLegend2018",
      col1: 2018,
      col2: "John Legend",
    },
    {
      key: "TimRice2018",
      col1: 2018,
      col2: "Time Rice",
    },
    {
      key: "AndrewLloydWebber2018",
      col1: 2018,
      col2: "Andrew Lloyd Webber",
    },
  ],
  lastTonyBestMusicalHeadings: [
    {
      key: "lastTonyBestMusical",
      col1: "Year",
      col2: "Film",
    },
  ],
  lastTonyBestMusical: [
    {
      key: "AStrangeLoop2022TBM",
      col1: 2022,
      col2: "A Strange Loop",
    },
    {
      key: "MoulinRouge2020TBM",
      col1: 2020,
      col2: "Moulin Rouge!",
    },
    {
      key: "Hadestown2019TBM",
      col1: 2019,
      col2: "Hadestown",
    },
    {
      key: "TheBandsVisit2018TBM",
      col1: 2018,
      col2: "The Band's Visit",
    },
    {
      key: "DearEvanHansen2017TBM",
      col1: 2017,
      col2: "Dear Evan Hansen",
    },
  ],
  grammyBestNewArtistHeadings: [
    {
      key: "grammyBestNewArtist",
      col1: "Year",
      col2: "Winner",
    },
  ],
  grammyBestNewArtist: [
    {
      key: "OliviaRodrigo2022BNA",
      col1: 2022,
      col2: "Olivia Rodrigo",
    },
    {
      key: "Megantheestallion2021BNA",
      col1: 2021,
      col2: "Megan Thee Stallion",
    },
    {
      key: "BillieEilish2020BNA",
      col1: 2020,
      col2: "Billie Eilish",
    },
    {
      key: "Dualipa2019BNA",
      col1: 2019,
      col2: "Dua Lipa",
    },
    {
      key: "AlessiaCara2018BNA",
      col1: 2018,
      col2: "Alessia Cara",
    },
  ],
  grammyAlbumOfTheYearHeadings: [
    {
      key: "grammyAlbumOfTheYear",
      col1: "Year",
      col2: "Album",
      col3: "Artist",
    },
  ],
  grammyAlbumOfTheYear: [
    {
      key: "WeAreAOTY2022",
      col1: 2022,
      col2: "We Are",
      col3: "Jon Batiste",
    },
    {
      key: "FolkloreAOTY2021",
      col1: 2021,
      col2: "Folklore",
      col3: "Taylor Swift",
    },
    {
      key: "WhenWeAllFallAsleepAOTY2020",
      col1: 2020,
      col2: "When We All Fall Asleep, Where Do We Go?",
      col3: "Billie Eilish",
    },
    {
      key: "GoldenHourAOTY2019",
      col1: 2019,
      col2: "Golden Hour",
      col3: "Kacey Musgraves",
    },
    {
      key: "24kMagicAOTY2018",
      col1: 2018,
      col2: "24k Magic",
      col3: "Bruno Mars",
    },
  ],
  grammyRecordOfTheYearHeadings: [
    {
      key: "grammyRecordOfTheYear",
      col1: "Year",
      col2: "Record",
      col3: "Artist",
    },
  ],
  grammyRecordOfTheYear: [
    {
      key: "LeavetheDoorOpenROTY2022",
      col1: 2022,
      col2: "Leave the Door Open",
      col3: "Silk Sonic",
    },
    {
      key: "EverythingIWantedROTY2021",
      col1: 2021,
      col2: "Everything I Wanted",
      col3: "Billie Eilish",
    },
    {
      key: "BadGuyROTY2020",
      col1: 2020,
      col2: "Bad Guy",
      col3: "Billie Eilish",
    },
    {
      key: "ThisIsAmericaROTY2019",
      col1: 2019,
      col2: "This is America",
      col3: "Childish Gambino",
    },
    {
      key: "24kMagicROTY2018",
      col1: 2018,
      col2: "24k Magic",
      col3: "Bruno Mars",
    },
  ],
  grammySongOfTheYearHeadings: [
    {
      key: "grammySongOfTheYear",
      col1: "Year",
      col2: "Record",
      col3: "Artist",
    },
  ],
  grammySongOfTheYear: [
    {
      key: "LeavetheDoorOpenSOTY2022",
      col1: 2022,
      col2: "Leave the Door Open",
      col3: "Silk Sonic",
    },
    {
      key: "ICantBreatheSOTY2021",
      col1: 2021,
      col2: "I Can't Breathe",
      col3: "H.E.R.",
    },
    {
      key: "BadGuySOTY2020",
      col1: 2020,
      col2: "Bad Guy",
      col3: "Billie Eilish",
    },
    {
      key: "ThisIsAmericaSOTY2019",
      col1: 2019,
      col2: "This is America",
      col3: "Childish Gambino",
    },
    {
      key: "ThatsWhatILikeSOTY2018",
      col1: 2018,
      col2: "That's What I Like",
      col3: "Bruno Mars",
    },
  ],
  britAwardsAlbumOfYearHeadings: [
    {
      key: "britAwardsAlbumOfYear",
      col1: "Year",
      col2: "Album",
      col3: "Artist",
    },
  ],
  britAwardsAlbumOfYear: [
    {
      key: "AdeleBritAOY2022",
      col1: 2022,
      col2: "30",
      col3: "Adele",
    },
    {
      key: "DuaLipaBritAOY2021",
      col1: 2021,
      col2: "Future Nostalgia",
      col3: "Dua Lipa",
    },
    {
      key: "DaveBritAOY2020",
      col1: 2020,
      col2: "Psychodrama",
      col3: "Dave",
    },
    {
      key: "AdeleBritAOY2019",
      col1: 2019,
      col2: "A Brief Inquiry into Online Relationships",
      col3: "The 1975",
    },
    {
      key: "StormzyBritAOY2018",
      col1: 2018,
      col2: "Gang Signs & Prayer",
      col3: "Stormzy",
    },
  ],
  baftaTV: {
    lastFiveLeadingActressHeadings: [
      {
        key: "lastFiveLeadingActress",
        col1: "Year",
        col2: "Winner",
      },
    ],
    lastFiveLeadingActress: [
      {
        key: "jodieComer2022",
        col1: 2022,
        col2: "Jodie Comer",
      },
      {
        key: "michaelaCoel2021",
        col1: 2021,
        col2: "Michaela Coel",
      },
      {
        key: "glendaJackson2020",
        col1: 2020,
        col2: "Glenda Jackson",
      },
      {
        key: "jodieComer2019",
        col1: 2019,
        col2: "Jodie Comer",
      },
      {
        key: "mollyWindsor2018",
        col1: 2018,
        col2: "Molly Windsor",
      },
    ],
    lastFiveLeadingActorHeadings: [
      {
        key: "lastFiveLeadingActor",
        col1: "Year",
        col2: "Winner",
      },
    ],
    lastFiveLeadingActor: [
      {
        key: "seanBean2022",
        col1: 2022,
        col2: "Sean Bean",
      },
      {
        key: "paulMescal2021",
        col1: 2021,
        col2: "Paul Mescal",
      },
      {
        key: "jaredHarris2020",
        col1: 2020,
        col2: "Jared Harris",
      },
      {
        key: "benedictCumberbatch2019",
        col1: 2019,
        col2: "Benedict Cumberbatch",
      },
      {
        key: "seanBean2018",
        col1: 2018,
        col2: "Sean Bean",
      },
    ],
    lastFiveDramaSeriesHeadings: [
      {
        key: "lastFiveDramaSeries",
        col1: "Year",
        col2: "Winner",
      },
    ],
    lastFiveDramaSeries: [
      {
        key: "inMySkin2022",
        col1: 2022,
        col2: "In My Skin",
      },
      {
        key: "saveMeToo2021",
        col1: 2021,
        col2: "Save Me Too",
      },
      {
        key: "theEndOfTheFingWorld2020",
        col1: 2020,
        col2: "The End Of The F...ing World",
      },
      {
        key: "killingEve2019",
        col1: 2019,
        col2: "Killing Eve",
      },
      {
        key: "peakyBlinders2018",
        col1: 2018,
        col2: "Peaky Blinders",
      },
    ],
    lastFiveEntertainmentPerformanceHeadings: [
      {
        key: "lastFiveEnterainmentPerformance",
        col1: "Year",
        col2: "Winner",
      },
    ],
    lastFiveEntertainmentPerformance: [
      {
        key: "bigZuu2022",
        col1: 2022,
        col2: "Big Zuu",
      },
      {
        key: "RomeshRanganathan2021",
        col1: 2021,
        col2: "Romesh Ranganathan",
      },
      {
        key: "moGilligan2020",
        col1: 2020,
        col2: "Mo Gilligan",
      },
      {
        key: "leeMack2019",
        col1: 2019,
        col2: "Lee Mack",
      },
      {
        key: "grahamNorton2018",
        col1: 2018,
        col2: "Graham Norton",
      },
    ],
  },
  baftaFilm: {
    lastFiveBaftaFilmHeadings: [
      {
        key: "lastFiveBaftaFilm",
        col1: "Year",
        col2: "Best Film",
        col3: "Leading Actor",
        col4: "Leading Actress",
      },
    ],
    lastFiveBaftaFilm: [
      {
        key: "baftaFilms2022",
        col1: 2022,
        col2: "The Power of the Dog",
        col3: "Will Smith",
        col4: "Joanna Scanlan",
      },
      {
        key: "baftaFilms2021",
        col1: 2021,
        col2: "Nomadland",
        col3: "Anthony Hopkins",
        col4: "Frances McDormand",
      },
      {
        key: "baftaFilms2020",
        col1: 2020,
        col2: "1917",
        col3: "Joaquin Phoenix",
        col4: "Renee Zellweger",
      },
      {
        key: "baftaFilms2019",
        col1: 2019,
        col2: "Roma",
        col3: "Rami Malek",
        col4: "Olivia Colman",
      },
      {
        key: "baftaFilms2018",
        col1: 2018,
        col2: "Three Billboards Outside Ebbing, Missouri",
        col3: "Gary Oldman",
        col4: "Frances McDormand",
      },
    ],
  },
  Eurovision: {
    lastFiveWinnersHeadings: [
      {
        key: "lastFiveWinners",
        col1: "Year",
        col2: "Winner",
        col3: "Host",
      },
    ],
    lastFiveWinners: [
      {
        key: "2022UkraineE",
        col1: 2022,
        col2: "Ukraine",
        col3: "Turin",
      },
      {
        key: "2021ItalyE",
        col1: 2021,
        col2: "Italy",
        col3: "Rotterdam",
      },
      {
        key: "2019NetherlandsE",
        col1: 2019,
        col2: "Netherlands",
        col3: "Tel Aviv",
      },
      {
        key: "2018IsraelE",
        col1: 2018,
        col2: "Israel",
        col3: "Lisbon",
      },
      {
        key: "2017PortugalE",
        col1: 2017,
        col2: "Portugal",
        col3: "Kyiv",
      },
    ],
    firstFiveWinnersHeadings: [
      {
        key: "firstFiveWinners",
        col1: "Year",
        col2: "Winner",
        col3: "Host",
      },
    ],
    firstFiveWinners: [
      {
        key: "1956SwissE",
        col1: 1956,
        col2: "Switzerland",
        col3: "Lugano",
      },
      {
        key: "1957NetherlandsE",
        col1: 1957,
        col2: "Netherlands",
        col3: "Frankfurt",
      },
      {
        key: "1958FrnaceE",
        col1: 1958,
        col2: "France",
        col3: "Hilversum",
      },
      {
        key: "1959Netherlands",
        col1: 1959,
        col2: "Netherlands",
        col3: "Cannes",
      },
      {
        key: "1960FranceE",
        col1: 1960,
        col2: "France",
        col3: "London",
      },
    ],
    winsByCountryHeadings: [
      {
        key: "winsByCountry",
        col1: "Ireland",
        col2: 7,
      },
    ],
    winsByCountry: [
      {
        key: "EurovisionIreland7",
        col1: "Ireland",
        col2: 7,
      },
      {
        key: "EurovisionSweden6",
        col1: "Sweden",
        col2: 6,
      },
      {
        key: "EurovisionFrance5",
        col1: "France",
        col2: 5,
      },
      {
        key: "EurovisionLuxembourg5",
        col1: "Luxembourg",
        col2: 5,
      },
      {
        key: "EurovisionNetherlands5",
        col1: "Netherlands",
        col2: 5,
      },
      {
        key: "EurovisionUK5",
        col1: "UK",
        col2: 5,
      },
    ],
  },
};

const topFiveSocialMedia = {
  mostWatchedYoutubeHeadings: [
    {
      key: "mostWatchedYoutube",
      col1: "Video",
      col2: "Views",
    },
  ],
  mostWatchedYoutube: [
    {
      key: "Babysharkdance",
      col1: "Baby Shark Dance",
      col2: "10.87b",
    },
    {
      key: "Despacitio,Luis Fonsi",
      col1: "Despacitio - Luis Fonsi",
      col2: "7.89b",
    },
    {
      key: "Johny Johny Yes Papa",
      col1: "Johny Johny Yes Papa",
      col2: "6.38b",
    },
    {
      key: "Shape of You,Ed Sheeran",
      col1: "Shape of You - Ed Sheeran",
      col2: "5.75b",
    },
    {
      key: "See You Again,Wiz Khalifa",
      col1: "See You Again - Wiz Khalifae",
      col2: "5.55b",
    },
  ],
  mostTwitterFollowersHeadings: [
    {
      key: "mostTwitterFollowers",
      col1: "Handle",
      col2: "Owner",
      col3: "Followers",
    },
  ],
  mostTwitterFollowers: [
    {
      key: "@barackobama",
      col1: "@barackobama",
      col2: "Barack Obama",
      col3: "132.1m",
    },
    {
      key: "@justinbieber",
      col1: "@justinbieber",
      col2: "Justin Bieber",
      col3: "114.1m",
    },
    {
      key: "@katyperry",
      col1: "@katyperry",
      col2: "Katy Perry",
      col3: "108.8m",
    },
    {
      key: "@rihanna",
      col1: "@rihanna",
      col2: "Rihanna",
      col3: "106.9m",
    },
    {
      key: "@Cristiano",
      col1: "@Cristiano",
      col2: "Cristiano Ronaldo",
      col3: "101.4m",
    },
  ],
  mostTikTokFollowersHeadings: [
    {
      key: "mostTikTokFollowers",
      col1: "Handle",
      col2: "Owner",
      col3: "Followers",
    },
  ],
  mostTikTokFollowers: [
    {
      key: "@khaby.lame",
      col1: "@khaby.lame",
      col2: "Khabane Lame",
      col3: "144.6m",
    },
    {
      key: "@charlidamelio",
      col1: "@charlidamelio",
      col2: "Charli D'Amelio",
      col3: "142.3m",
    },
    {
      key: "@bellapoarch",
      col1: "@bellapoarch",
      col2: "Bella Poarch",
      col3: "90.1m",
    },
    {
      key: "@addisonre",
      col1: "@addisonre",
      col2: "Addison Rae",
      col3: "87.9m",
    },
    {
      key: "@willsmith,",
      col1: "@willsmith",
      col2: "Will Smith",
      col3: "72m",
    },
  ],
  mostInstagramFollowersHeadings: [
    {
      key: "mostInstagramFollowers",
      col1: "Handle",
      col2: "Owner",
      col3: "Followers",
    },
  ],
  mostInstagramFollowers: [
    {
      key: "@cristiano459",
      col1: "@cristiano",
      col2: "Cristiano Ronaldo",
      col3: "459m",
    },
    {
      key: "@kyliejenner353",
      col1: "@kyliejenner",
      col2: "Kylie Jenner",
      col3: "353m",
    },
    {
      key: "@leomessi342",
      col1: "@leomessi",
      col2: "Lionel Messi",
      col3: "342m",
    },
    {
      key: "@selenagomez",
      col1: "@selenagomez",
      col2: "Selena Gomez",
      col3: "331m",
    },
    {
      key: "@therock324",
      col1: "@therock",
      col2: "Dwayne Johnson",
      col3: "324m",
    },
  ],
  moststreamedOnSpotifyJuly1st2022Headings: [
    {
      key: "moststreamedOnSpotifyJuly1st2022",
      col1: "Song",
      col2: "Artist",
      col3: "Listens",
    },
  ],
  moststreamedOnSpotifyJuly1st2022: [
    {
      key: "spotifyShapeOfYou",
      col1: "Shape Of You",
      col2: "Ed Sheeran",
      col3: "3.169m",
    },
    {
      key: "spotifyBlindingLights",
      col1: "Blinding Lights",
      col2: "The Weeknd",
      col3: "3.023m",
    },
    {
      key: "spotifyDanceMonkey",
      col1: "Dance Monkey",
      col2: "Tones and I",
      col3: "2.595m",
    },
    {
      key: "spotifyRockstar",
      col1: "Rockstar",
      col2: "Post Malone featuring 21 Savage",
      col3: "2.449m",
    },
    {
      key: "spotifySomeoneYouLoved",
      col1: "Someone You Loved",
      col2: "Lewis Capaldi",
      col3: "2.391m",
    },
  ],
};

const celebrities = {
  richestHeadings: [
    {
      key: "richest",
      col1: "Name",
      col2: "Net Worth",
    },
  ],
  richest: [
    {
      key: "elonMusk224b",
      col1: "Elon Musk",
      col2: "$224b",
    },
    {
      key: "jeffBezos144b",
      col1: "Jeff Bexos",
      col2: "$144b",
    },
    {
      key: "bernardArnault136b",
      col1: "Bernard Arnault",
      col2: "$136b",
    },
    {
      key: "billGates136b",
      col1: "Bill Gates",
      col2: "$123b",
    },
    {
      key: "warrenBuffet114b",
      col1: "Warren Buffet",
      col2: "$114b",
    },
  ],
};

const TVmoviesvideogames = {
  cinemaTopGrossingHeadings: [
    {
      key: "cinemaTopGrossing",
      col1: "Title",
      col2: "Year",
      col3: "Gross",
    },
  ],
  cinemaTopGrossing: [
    {
      key: "Avatar2009",
      col1: "Avatar",
      col2: 2009,
      col3: "$2.847b",
    },
    {
      key: "Avengers:Endgame2019",
      col1: "Avengers: Endgame",
      col2: 2019,
      col3: "$2.798b",
    },
    {
      key: "Titanic1997",
      col1: "Titanic",
      col2: 1997,
      col3: "$2.187b",
    },
    {
      key: "Star Wars:TheForceAwakens",
      col1: "Star Wars: The Force Awakens",
      col2: 2015,
      col3: "$2.068b",
    },
    {
      key: "Avengers:InfinityWar2018",
      col1: "Avengers: Infinity War",
      col2: 2018,
      col3: "$2.048b",
    },
  ],
  videoGamesTopSellingHeadings: [
    {
      key: "videoGamesTopSelling",
      col1: "Game",
      col2: "Units Sold",
    },
  ],
  videoGamesTopSelling: [
    {
      key: "Minecraft238",
      col1: "Minecraft",
      col2: "238m",
    },
    {
      key: "GrandTheftAutoV165",
      col1: "Grand Theft Auto V",
      col2: "165m",
    },
    {
      key: "Tetris(EA)100",
      col1: "Tetris",
      col2: "100m",
    },
    {
      key: "WiiSports82.9",
      col1: "Wii Sports",
      col2: "82.9m",
    },
    {
      key: "PUBG:Battlegrounds75",
      col1: "PUBG: Battlegrounds",
      col2: "75m",
    },
  ],
  TVlongestRunningHeadings: [
    {
      key: "TVlongestRunning",
      col1: "Title",
      col2: "Years",
      col3: "Began",
    },
  ],
  TVlongestRunning: [
    {
      key: "TonightShow67",
      col1: "The Tonight Show",
      col2: 67,
      col3: "1954 - present",
    },
    {
      key: "CoronationStreet62",
      col1: "Coronation Street",
      col2: 62,
      col3: "1960 - present",
    },
    {
      key: "General Hospital59",
      col1: "General Hospital",
      col2: 59,
      col3: "1963 - present",
    },
    {
      key: "Guiding Light57",
      col1: "Guiding Light",
      col2: 57,
      col3: "1952 - 2009",
    },
    {
      key: "DaysofOurLives56",
      col1: "Days of Our Lives",
      col2: 56,
      col3: "1965 - present",
    },
  ],
  ukSoapsByNumOfEpsHeading: [
    {
      key: "ukSoapsByNumOfEps",
      col1: "Title",
      col2: "Episodes",
    },
  ],
  ukSoapsByNumOfEps: [
    {
      key: "CoronationStreet10603",
      col1: "Coronation Street",
      col2: 10603,
    },
    {
      key: "Emmerdale9321",
      col1: "Emmerdale",
      col2: 9321,
    },
    {
      key: "PobolyCwm8000",
      col1: "Pobol y Cwm",
      col2: 8000,
    },
    {
      key: "Eastenders6460",
      col1: "Eastenders",
      col2: 6460,
    },
    {
      key: "Hollyoaks5810",
      col1: "Hollyoaks",
      col2: 5810,
    },
  ],
};

const foodAndDrink = {
  coffeeProducersHeadings: [
    { key: "coffeeProducers", col1: "Rank", col2: "Country" },
  ],
  coffeeProducers: [
    { key: "BrazilCoffee1", col1: 1, col2: "Brazil" },
    { key: "VietnamCoffee2", col1: 2, col2: "Vietnam" },
    { key: "ColombiaCoffee3", col1: 3, col2: "Colombia" },
    { key: "IndonesiaCoffee4", col1: 4, col2: "Indonesia" },
    { key: "EthiopiaCoffee5", col1: 5, col2: "Ethiopia" },
  ],
  teaProducersHeadings: [
    { key: "teaProducers", col1: "Rank", col2: "Country" },
  ],
  teaProducers: [
    { key: "ChinaTea1", col1: 1, col2: "China" },
    { key: "IndiaTea2", col1: 2, col2: "Vietnam" },
    { key: "KenyaTea3", col1: 3, col2: "Kenya" },
    { key: "SriLankaTea4", col1: 4, col2: "Sri Lanka" },
    { key: "VietnamTea5", col1: 5, col2: "Vietnam" },
  ],
  chocolateProducersHeadings: [
    { key: "chocolateProducers", col1: "Rank", col2: "Country" },
  ],
  chocolateProducers: [
    { key: "GermanyChocolate1", col1: 1, col2: "Germany" },
    { key: "BelgiumChocolate2", col1: 2, col2: "Belgium" },
    { key: "ItalyChocolate3", col1: 3, col2: "Italy" },
    { key: "PolandChocolate", col1: 4, col2: "Poland" },
    { key: "NetherlandsChocolate5", col1: 5, col2: "Netherlands" },
  ],
  chocolateConsumersHeadings: [
    { key: "chocolateConsumers", col1: "Rank", col2: "Country" },
  ],
  chocolateConsumers: [
    { key: "SwissChocolate1", col1: 1, col2: "Switzerland" },
    { key: "USAChocolate2", col1: 2, col2: "United States" },
    { key: "GermanyChocolate3", col1: 3, col2: "Germany" },
    { key: "FranceChocolate", col1: 4, col2: "France" },
    { key: "UKChocolate5", col1: 5, col2: "UK" },
  ],
  cocoaBeanProducersHeadings: [
    { key: "cocoaBeanProducers", col1: "Rank", col2: "Country" },
  ],
  cocoaBeanProducers: [
    { key: "CoteD'IvoireCocoa1", col1: 1, col2: "Cote D'Ivoire" },
    { key: "GhanaCocoa2", col1: 2, col2: "Ghana" },
    { key: "IndonesiaCocoa3", col1: 3, col2: "Indonesia" },
    { key: "NigeriaCocoa", col1: 4, col2: "Nigeria" },
    { key: "CameroonCocoa5", col1: 5, col2: "Cameroon" },
  ],
};

const lyrics = {
  weDidntStartTheFireHeadings: [
    { key: "WeDidntStartTheFire", col1: "#", col2: "List Item" },
  ],
  weDidntStartTheFire: [
    { key: "WDSTF1", col1: 1, col2: "Harry Truman" },
    { key: "WDSTF2", col1: 2, col2: "Doris Day" },
    { key: "WDSTF3", col1: 3, col2: "Red China" },
    { key: "WDSTF4", col1: 4, col2: "Johnnie Ray" },
    { key: "WDSTF5", col1: 5, col2: "South Pacific" },
  ],
};

const bible = {
  firstFiveBooksHeadings: [
    {
      key: "firstFiveBooks",
      col1: "#",
      col2: "Title",
    },
  ],
  firstFiveBooks: [
    {
      key: "BibleBook1",
      col1: 1,
      col2: "Genesis",
    },
    {
      key: "BibleBook2",
      col1: 2,
      col2: "Exodus",
    },
    {
      key: "BibleBook3",
      col1: 3,
      col2: "Leviticus",
    },
    {
      key: "BibleBook4",
      col1: 4,
      col2: "Numbers",
    },
    {
      key: "BibleBook5",
      col1: 5,
      col2: "Deuteronomy",
    },
  ],
};

const poker = {
  horseHeadings: [
    {
      key: "horse",
      col1: "Game number",
      col2: "Game",
    },
  ],
  horse: [
    {
      key: "pokerHorse1",
      col1: 1,
      col2: "Hold 'em",
    },
    {
      key: "pokerHorse2",
      col1: 2,
      col2: "Omaha hi-low split-eight or better",
    },
    {
      key: "pokerHorse3",
      col1: 3,
      col2: "Razz",
    },
    {
      key: "pokerHorse4",
      col1: 4,
      col2: "Seven Card Stud",
    },
    {
      key: "pokerHorse1",
      col1: 5,
      col2: "Seven card stud hi-low split Eight or better",
    },
  ],
};

const historyAndCivilisation = {
  politics: {
    lastFivePMsHeadings: [
      {
        key: "lastFivePMs",
        col1: "Name",
        col2: "Dates",
        col3: "MP For",
      },
    ],
    lastFivePMs: [
      {
        key: "Sunak2022",
        col1: "Rishi Sunak",
        col2: "2022-",
        col3: "Richmond (Yorks)",
      },
      {
        key: "Truss2022",
        col1: "Liz Truss",
        col2: "Sept 2022 - Oct 2022",
        col3: "South West Norfolk",
      },
      {
        key: "Boris20192022",
        col1: "Boris Johnson",
        col2: "2019-2022",
        col3: "Uxbridge and South Ruislip",
      },
      {
        key: "Theresa20162019",
        col1: "Theresa May",
        col2: "2016-2019",
        col3: "Maidenhead",
      },
      {
        key: "David20102016",
        col1: "David Cameron",
        col2: "2010-2016",
        col3: "Witney",
      },
    ],
    lastFiveTorysHeadings: [
      {
        key: "lastFiveTorys",
        col1: "Name",
        col2: "Dates",
        col3: "MP For",
      },
    ],
    lastFiveTorys: [
      {
        key: "Sunak2022Tory",
        col1: "Rishi Sunak",
        col2: "2022-",
        col3: "Richmond (Yorks)",
      },
      {
        key: "Truss2022Tory",
        col1: "Liz Truss",
        col2: "2022-2022 (44 days)",
        col3: "South West Norfolk",
      },
      {
        key: "Boris20192022Tory",
        col1: "Boris Johnson",
        col2: "2019-2022",
        col3: "Uxbridge and South Ruislip",
      },
      {
        key: "Theresa20162019Tory",
        col1: "Theresa May",
        col2: "2016-2019",
        col3: "Maidenhead",
      },
      {
        key: "David20102016Tory",
        col1: "David Cameron",
        col2: "2010-2016",
        col3: "Witney",
      },
    ],
    lastFiveLabourHeadings: [
      {
        key: "lastFiveLabour",
        col1: "Name",
        col2: "Dates",
        col3: "MP For",
      },
    ],
    lastFiveLabour: [
      {
        key: "Gordon20072010Labour",
        col1: "Gordon Brown",
        col2: "2007-2010",
        col3: "Kirkcaldy and Cowdenbeath",
      },
      {
        key: "Tony19972007Labour",
        col1: "Tony Blair",
        col2: "1997-2007",
        col3: "Sedgefield",
      },
      {
        key: "James19761979Labour",
        col1: "James Callaghan",
        col2: "1976-1979",
        col3: "Cardiff South East",
      },
      {
        key: "Harold19741976Labour",
        col1: "Harold Wilson",
        col2: "1974-1976 & 1964-1970",
        col3: "Huyton",
      },
      {
        key: "Clement19451951Labour",
        col1: "Clement Attlee",
        col2: "1945-1951",
        col3: "Limehouse",
      },
    ],
  },
  americanPolitics: {
    firstFivePresidentsHeadings: [
      {
        key: "firstFivePresidents",
        col1: "President",
        col2: "Years in Office",
      },
    ],
    firstFivePresidents: [
      {
        key: "GeorgeWashington1789",
        col1: "George Washington",
        col2: "1789-1797",
      },
      {
        key: "JohnAdams1797",
        col1: "John Adams",
        col2: "1797-1801",
      },
      {
        key: "ThomasJefferson1801",
        col1: "Thomas Jefferson",
        col2: "1801-1809",
      },
      {
        key: "JamesMadison1809",
        col1: "James Madison",
        col2: "1809-1817",
      },
      {
        key: "JamesMonroe1817",
        col1: "James Monroe",
        col2: "1817-1825",
      },
    ],
    shortestServingPresidentsHeadings: [
      {
        key: "shortestServingPresidents",
        col1: "President",
        col2: "Days in Office",
      },
    ],
    shortestServingPresidents: [
      {
        key: "WilliamHenryHarrison31",
        col1: "William Henry Harrison",
        col2: 31,
      },
      {
        key: "JamesGarfield199",
        col1: "James Garfield",
        col2: 199,
      },
      {
        key: "ZacharyTaylor492",
        col1: "Zachary Taylor",
        col2: 492,
      },
      {
        key: "JoeBiden(current)",
        col1: "Joe Biden (current)",
        col2: 598,
      },
      {
        key: "WarrenHarding881",
        col1: "Warren Harding",
        col2: 881,
      },
    ],
    lastFivePresidentsHeadings: [
      {
        key: "lastFivePresidents",
        col1: "President",
        col2: "Years in Office",
      },
    ],
    lastFivePresidents: [
      {
        key: "JoeBidenCurrent2021",
        col1: "Joe Biden",
        col2: "2021-",
      },
      {
        key: "DonaldTrump2017",
        col1: "Donald Trump",
        col2: "2017-2021",
      },
      {
        key: "BarackObama2009",
        col1: "Barack Obama",
        col2: "2009-2017",
      },
      {
        key: "GeorgeWBush2001",
        col1: "George W Bush",
        col2: "2001-2009",
      },
      {
        key: "BillClinton1993",
        col1: "Bill Clinton",
        col2: "1993-2001",
      },
    ],
  },
  monarchy: {
    longestReigningHeadings: [
      {
        key: "longestReigning",
        col1: "Monarch",
        col2: "Reign length",
        col3: "Country Ruled",
      },
    ],
    longestReigning: [
      {
        key: "LouisXIVreign",
        col1: "Louis XIV",
        col2: "72y 110d",
        col3: "France",
      },
      {
        key: "ElizabethIIreign",
        col1: "Elizabeth II",
        col2: "70y 144d",
        col3: "UK & Commonwealth",
      },
      {
        key: "Ramareign",
        col1: "Rama IX",
        col2: "70y 126d",
        col3: "Thailand",
      },
      {
        key: "JohannIIreign",
        col1: "Johann II",
        col2: "70y 91d",
        col3: "Liechtenstein",
      },
      {
        key: "K'inichreign",
        col1: "K'inich Janaab' Pakal I",
        col2: "68y 33d",
        col3: "Palenque",
      },
    ],
    longestBritishMonarchsHeadings: [
      {
        key: "longestBritishMonarchs",
        col1: "Rank",
        col2: "Monarch",
      },
    ],
    longestBritishMonarchs: [
      {
        key: "1ElizabethII",
        col1: "1",
        col2: "Elizabeth II",
      },
      {
        key: "2Victoria",
        col1: "2",
        col2: "Victoria",
      },
      {
        key: "3GeorgeIII",
        col1: "3",
        col2: "George III",
      },
      {
        key: "4GeorgeII",
        col1: "4",
        col2: "George II",
      },
      {
        key: "5GeorgeV",
        col1: "5",
        col2: "George V",
      },
    ],
  },
  languages: {
    mostSpokenHeadings: [
      {
        key: "mostSpoken",
        col1: "Language",
        col2: "Speakers",
      },
    ],
    mostSpoken: [
      {
        key: "English1132m",
        col1: "English",
        col2: "1132m",
      },
      {
        key: "Mandarin1117m",
        col1: "Mandarin",
        col2: "1117m",
      },
      {
        key: "Hindi615m",
        col1: "Hindi",
        col2: "615m",
      },
      {
        key: "Spanish534m",
        col1: "Spanish",
        col2: "534m",
      },
      {
        key: "French280m",
        col1: "French",
        col2: "280m",
      },
    ],
    nativeSpeakersHeadings: [
      {
        key: "nativeSpeakers",
        col1: "Language",
        col2: "Speakers",
      },
    ],
    nativeSpeakers: [
      {
        key: "Mandarin918",
        col1: "Mandarin",
        col2: "918m",
      },
      {
        key: "Spanish471",
        col1: "Spanish",
        col2: "471m",
      },
      {
        key: "English370",
        col1: "English",
        col2: "370m",
      },
      {
        key: "Hindi342",
        col1: "Hindi",
        col2: "342m",
      },
      {
        key: "Arabic",
        col1: "Arabic",
        col2: "245m",
      },
    ],
    mostWordsInDictionaryHeadings: [
      {
        key: "mostWordsInDictionary",
        col1: "Rank",
        col2: "Language",
      },
    ],
    mostWordsInDictionary: [
      {
        key: "koreanDictionary",
        col1: 1,
        col2: "Korean",
      },
      {
        key: "JapaneseDictionary",
        col1: 2,
        col2: "Japanese",
      },
      {
        key: "ItalianDictionary",
        col1: 3,
        col2: "Italian",
      },
      {
        key: "EnglishDictionary",
        col1: 4,
        col2: "English",
      },
      {
        key: "RussianDictionary",
        col1: 5,
        col2: "Russian",
      },
    ],
  },
};

export {
  topFivesGeography,
  topFivesSport,
  topFivesEntertainment,
  topFiveSocialMedia,
  celebrities,
  TVmoviesvideogames,
  foodAndDrink,
  lyrics,
  bible,
  poker,
  historyAndCivilisation,
};
