const data = [
  {
    id: 1,
    question: 'The opera "Carmen" was written by which French composer?',
    answer: "Bizet",
    category: "Opera",
  },
  {
    id: 2,
    question: '"The Spiders From Mars" were a backing band for which singer?',
    answer: "David Bowie",
    category: "Music",
  },
  {
    id: 3,
    question: 'Which British band released the album "Rio" in 1982?',
    answer: "Duran Duran",
    category: "Music",
  },
  {
    id: 4,
    question: '"The Blue Moon Boys" were a backing band for which singer?',
    answer: "Elvis Presley",
    category: "Music",
  },
  {
    id: 5,
    question: 'Which British band released the album "OK Computer" in 1997?',
    answer: "Radiohead",
    category: "Music",
  },
  {
    id: 6,
    question:
      'The opera "Don Giovanni" was written by which Austrian composer?',
    answer: "Mozart",
    category: "Opera",
  },
  {
    id: 7,
    question: '"New Power Generation" were a backing band for which singer?',
    answer: "Prince",
    category: "Music",
  },
  {
    id: 8,
    question: "In which city was the British band Elbow formed?",
    answer: "Bury",
    category: "Music",
  },
  {
    id: 9,
    question:
      'Which British band released the album "West Ryder Pauper Lunatic Asylum" in 2009?',
    answer: "Kasabian",
    category: "Music",
  },
  {
    id: 10,
    question: 'Which British band released the album "Urban Hymns" in 1997?',
    answer: "The Verve",
    category: "Music",
  },
  {
    id: 11,
    question: 'Which British band released the album "Up The Bracket" in 2002?',
    answer: "The Libertines",
    category: "Music",
  },
  {
    id: 12,
    question: 'The opera "La Boheme" was written by which Italian composer?',
    answer: "Puccini",
    category: "Opera",
  },
  {
    id: 13,
    question: '"The Wailers" were a backing band for which singer?',
    answer: "Bob Marley",
    category: "Music",
  },
  {
    id: 14,
    question: 'Which British band released the album "The Man Who" in 2000?',
    answer: "Travis",
    category: "Music",
  },
  {
    id: 15,
    question: '"The Blackhearts" were a backing band for which singer?',
    answer: "Joan Jett",
    category: "Music",
  },

  {
    id: 16,
    question: "In which county is Leeds castle?",
    answer: "Kent",
    category: "Classic",
  },
  {
    id: 17,
    question: "Which year did England win the FIFA World Cup?",
    answer: "1966",
    category: "Sport",
  },
  {
    id: 18,
    question: "Who wrote War and Peace?",
    answer: "Leo Tolstoy",
    category: "Literature",
  },
  {
    id: 19,
    question: "Which football team won the first FA Cup?",
    answer: "Wanderers",
    category: "Classic",
  },
  {
    id: 20,
    question: "In which country is Lake Baikal?",
    answer: "Russia",
    category: "Geography",
  },
  {
    id: 21,
    question: "Where is the Sea Of Tranquility?",
    answer: "The Moon",
    category: "Classic",
  },
  {
    id: 22,
    question: "Robert Galbraith is a pseudonym of which author?",
    answer: "J.K Rowling",
    category: "Literature",
  },
  {
    id: 23,
    question: "How many make up a baker's dozen?",
    answer: "13",
    category: "Classic",
  },
  {
    id: 24,
    question: "Who is the UK's longest reigning monarch?",
    answer: "Queen Elizabeth II",
    category: "Classic",
  },
  {
    id: 25,
    question: "Who does the 'Truffle Shuffle' in the film The Goonies?",
    answer: "Chunk",
    category: "Film",
  },
  {
    id: 26,
    question: "Who were the first British team to win the European Cup?",
    answer: "Celtic",
    category: "Sport",
  },
  {
    id: 27,
    question: "Who wrote To Kill a Mockingbird?",
    answer: "Harper Lee",
    category: "Literature",
  },
  {
    id: 28,
    question: "Who won the first FIFA World Cup?",
    answer: "Uruguay",
    category: "Classic",
  },
  {
    id: 29,
    question: "What is the official currency of Denmark?",
    answer: "Danish Krone",
    category: "Classic",
  },
  {
    id: 30,
    question: "Who is the main antagonist in the Shakespeare play Othello?",
    answer: "Iago",
    category: "Classic",
  },
  {
    id: 31,
    question: "Which artist is famous for painting melting clocks?",
    answer: "Salvador Dali",
    category: "Arts",
  },
  {
    id: 32,
    question: "In which year did Tony Blair become Prime Minister?",
    answer: "1997",
    category: "Britain",
  },
  {
    id: 33,
    question: "What is the name of the coffee shop in the sitcom Friends?",
    answer: "Central Perk",
    category: "TV",
  },
  {
    id: 34,
    question: "What is the capital city of Australia?",
    answer: "Canberra",
    category: "Classic",
  },
  {
    id: 35,
    question: "Who was the first footballer to score 50 goals for England?",
    answer: "Wayne Rooney",
    category: "Sport",
  },
  {
    id: 36,
    question: "Who is the only UK prime minister to be assassinated?",
    answer: "Spencer Perceval",
    category: "Classic",
  },
  {
    id: 37,
    question: "Nintendo was founded in 1889 and originally made what?",
    answer: "Playing Cards",
    category: "VideoGames",
  },
  {
    id: 38,
    question:
      "The Dreamcast was a console released by which video game company?",
    answer: "Sega",
    category: "VideoGames",
  },
  {
    id: 39,
    question: "What is Elton John's real name?",
    answer: "Reginald Dwight",
    category: "Classic",
  },
  {
    id: 40,
    question: "What year was Nelson Mandela released from prison?",
    answer: "1990",
    category: "Classic",
  },
  {
    id: 41,
    question: "Castle Drogo is in which English county?",
    answer: "Devon",
    category: "Britain",
  },
  {
    id: 42,
    question: "Gordon Sumner is better known as who?",
    answer: "Sting",
    category: "Classic",
  },
  {
    id: 43,
    question: "MLXVI is what number written in Roman numerals?",
    answer: "1066",
    category: "Numbers",
  },
  {
    id: 44,
    question: "In which country was Che Guevara born?",
    answer: "Argentina",
    category: "Classic",
  },
  {
    id: 45,
    question: "Which band was Lionel Richie a member of?",
    answer: "The Commodores",
    category: "Music",
  },
  {
    id: 46,
    question:
      "To the nearest degree what temperature is absolute zero in celsius?",
    answer: "-273",
    category: "Classic",
  },
  {
    id: 47,
    question: "What gets wetter, the more it drys?",
    answer: "A Towel",
    category: "Riddles",
  },
  {
    id: 48,
    question:
      "What can you hold in your right hand, but never in your left hand?",
    answer: "Your Left Hand",
    category: "Riddles",
  },
  {
    id: 49,
    question: "What belongs to you but everyone else uses it?",
    answer: "Your Name",
    category: "Riddles",
  },
  {
    id: 50,
    question:
      "What 5 letter word becomes shorter when you add 2 letters to it?",
    answer: "Short",
    category: "Riddles",
  },
  {
    id: 51,
    question: "What is the title of the 2nd James Bond film?",
    answer: "From Russia With Love",
    category: "Film",
  },
  {
    id: 52,
    question:
      "What was the name of the dog who found the stolen FIFA World Cup trophy in 1966?",
    answer: "Pickles",
    category: "Classic",
  },
  {
    id: 53,
    question: "Which James Bond film was the first to star Pierce Brosnan?",
    answer: "Goldeneye",
    category: "Film",
  },
  {
    id: 54,
    question: "Who were the first European team to win the FIFA World Cup?",
    answer: "Italy",
    category: "Football",
  },
  {
    id: 55,
    question:
      "Who holds the record for most goals scored in a single football World Cup tournament?",
    answer: "Just Fontaine",
    category: "Football",
  },
  {
    id: 56,
    question:
      "Raised pressure in the eye is a sign of which medical condition?",
    answer: "Glaucoma",
    category: "HumanBody",
  },
  {
    id: 57,
    question: "In which town the UK version of the sitcom The Office set?",
    answer: "Slough",
    category: "TV",
  },
  {
    id: 58,
    question:
      "Which football team did Wayne Rooney make his first professional league appearance for at the age of 16?",
    answer: "Everton",
    category: "Football",
  },
  {
    id: 59,
    question: "What does DNA stand for?",
    answer: "Deoxyribonucleic acid",
    category: "Classic",
  },
  {
    id: 60,
    question: "How many bones are in the adult human body?",
    answer: "206",
    category: "Classic",
  },
  {
    id: 61,
    question: "At what temperature are Celsius and Fahrenheit equal?",
    answer: "-40",
    category: "Classic",
  },
  {
    id: 62,
    question: "On which continent is the country of Guyana?",
    answer: "South America",
    category: "Geography",
  },
  {
    id: 63,
    question: "In which year did Margaret Thatcher become Prime Minister?",
    answer: "1979",
    category: "Classic",
  },
  {
    id: 64,
    question: "In which year did the Titanic sink?",
    answer: "1912",
    category: "Classic",
  },
  {
    id: 65,
    question: "In which year was the Falklands War?",
    answer: "1982",
    category: "History",
  },
  {
    id: 66,
    question:
      "How many children did Queen Elizabeth II and Prince Philip have?",
    answer: "4",
    category: "History",
  },
  {
    id: 67,
    question: "Who was British Prime Minister when World War I began?",
    answer: "Herbert Asquith",
    category: "Classic",
  },
  {
    id: 68,
    question: "Where is the National Library of Wales?",
    answer: "Aberystwyth",
    category: "Britain",
  },
  {
    id: 69,
    question: "How many cities are in Wales?",
    answer: "6",
    category: "Britain",
  },
  {
    id: 70,
    question: "What is the name of the highest mountain in the UK?",
    answer: "Ben Nevis",
    category: "Classic",
  },
  {
    id: 71,
    question:
      "What is the name of the most southerly point on the UK mainland?",
    answer: "Lizards Point",
    category: "Classic",
  },
  {
    id: 72,
    question: "In which year was the first NFL Super Bowl?",
    answer: "1967",
    category: "Sport",
  },
  {
    id: 73,
    question: "Which band were originally called The New Yardbirds?",
    answer: "Led Zeppelin",
    category: "Music",
  },
  {
    id: 74,
    question: "Sodium chloride is the chemical name for what?",
    answer: "Salt",
    category: "Classic",
  },
  {
    id: 75,
    question: "Who wrote 'Of Mice and Men'?",
    answer: "John Steinbeck",
    category: "Literature",
  },
  {
    id: 76,
    question: "The Great Orme is a landmark in which UK coastal town?",
    answer: "Llandudno",
    category: "Britain",
  },
  {
    id: 77,
    question:
      "Who plays the vampire Laszlo in the TV series What We Do in the Shadows?",
    answer: "Matt Berry",
    category: "TV",
  },
  {
    id: 78,
    question: "Which English football team are known as The Cherries?",
    answer: "Bournemouth",
    category: "Football",
  },
  {
    id: 79,
    question: "Buxton is a spa town in which English County?",
    answer: "Derbyshire",
    category: "Britain",
  },
  {
    id: 80,
    question: "Which horror writer wrote the books The Strand and The Shining?",
    answer: "Stephen King",
    category: "Literature",
  },
  {
    id: 81,
    question: "What is the name of the longest river in Ireland?",
    answer: "River Shannon",
    category: "Geography",
  },
  {
    id: 82,
    question: "Samsung are a technology company founded in which country?",
    answer: "South Korea",
    category: "Technology",
  },
  {
    id: 83,
    question: "In which city do Everton play their home games?",
    answer: "Liverpool",
    category: "Sport",
  },
  {
    id: 84,
    question: "What do the numbers on roulette wheel add up to?",
    answer: "666",
    category: "Classic",
  },
  {
    id: 85,
    question:
      "What 3 digit page number was used to show subtitles on Teletext?",
    answer: "888",
    category: "TV",
  },
  {
    id: 86,
    question:
      "Jim Carrey's films 'Ace Ventura', 'Dumb and Dumber' and 'The Mask' were all released in which year?",
    answer: "1994",
    category: "Film",
  },
  {
    id: 87,
    question:
      "In 2010 who became the first African country to host the FIFA World Cup?",
    answer: "2010",
    category: "Sport",
  },
  {
    id: 88,
    question:
      "What word can go before 'back', 'weight' and 'boy' to make new words?",
    answer: "Paper",
    category: "Language",
  },
  {
    id: 89,
    question:
      "Which footballer represented the Ivory Coast national team after previously earning 2 caps playing for England?",
    answer: "Wilfred Zaha",
    category: "Sport",
  },
  {
    id: 90,
    question:
      "Which country was the France international footballer Patrick Vieira born?",
    answer: "Senegal",
    category: "Sport",
  },
  {
    id: 91,
    question: "What are fans of Lady Gaga referred to as?",
    answer: "Little Monsters",
    category: "Music",
  },
  {
    id: 92,
    question:
      "The juice of which house plant is commonly used to soothe minor burns?",
    answer: "Aloe Vera",
    category: "Nature",
  },
  {
    id: 93,
    question: "How long was the hundred years war?",
    answer: "116",
    category: "Classic",
  },
  {
    id: 94,
    question: "Who was Erich Weiss better known as?",
    answer: "Harry Houdini",
    category: "History",
  },
  {
    id: 95,
    question: "Triskaidekaphobia is the fear of what?",
    answer: "The Number 13",
    category: "Language",
  },
  {
    id: 96,
    question: "How many legs does a spider have",
    answer: "8",
    category: "Nature",
  },
  {
    id: 97,
    question: "On what date was John F Kennedy assassinated?",
    answer: "22nd November 1963",
    category: "History",
  },
  {
    id: 98,
    question: "Three strikes in a row in Ten-Pin Bowling is known as a what?",
    answer: "Turkey",
    category: "Classic",
  },
  {
    id: 99,
    question: "What name did scientists give to the first cloned sheep?",
    answer: "Dolly",
    category: "Nature",
  },
  {
    id: 100,
    question: "Which mammal lives in a holt?",
    answer: "Otter",
    category: "Nature",
  },
  {
    id: 101,
    question: "In which year did decimalisation happen in the UK?",
    answer: "1971",
    category: "Classic",
  },
  {
    id: 102,
    question:
      "First airing in 1980, who was the original host of Family Fortunes?",
    answer: "Bob Monkhouse",
    category: "TV",
  },
  {
    id: 103,
    question: "In which year did Queen Victoria die?",
    answer: "1901",
    category: "History",
  },
  {
    id: 104,
    question: "How many hearts does an Octopus have?",
    answer: "3",
    category: "Nature",
  },
  {
    id: 105,
    question:
      "With a top speed of over 60mph, what is the fastest land mammal?",
    answer: "Cheetah",
    category: "Classic",
  },
  {
    id: 106,
    question: "What is the only mammal that can fly?",
    answer: "Bat",
    category: "Nature",
  },
  {
    id: 107,
    question: "Where are a cricket's ears located?",
    answer: "Legs",
    category: "Nature",
  },
  {
    id: 108,
    question: "What is a Rhino's horn made of?",
    answer: "Keratin",
    category: "Nature",
  },
  {
    id: 109,
    question: "Eating what causes a Flamingo to be pink?",
    answer: "Algae",
    category: "Nature",
  },
  {
    id: 110,
    question: "What type of Penguin is the largest?",
    answer: "Emperor",
    category: "Nature",
  },
  {
    id: 111,
    question: "What is the largest lizard in the world?",
    answer: "Komodo Dragon",
    category: "Nature",
  },
  {
    id: 112,
    question: "What is the collective noun for a group of crows?",
    answer: "A Murder",
    category: "Nature",
  },
  {
    id: 113,
    question: "Which type of bird can fly backwards?",
    answer: "Hummingbird",
    category: "Classic",
  },
  {
    id: 114,
    question:
      "Responsible for over 700,000 human deaths every year, what is the world's deadliest animal?",
    answer: "Mosquitos",
    category: "Nature",
  },
  {
    id: 115,
    question:
      "Which 18th century botanist formalised the modern system of naming organisms?",
    answer: "Carl Linnaeus",
    category: "History",
  },
  {
    id: 116,
    question: "A baby goat is called a what?",
    answer: "Kid",
    category: "Nature",
  },
  {
    id: 117,
    question: "The term Vulpine relates to what animal species?",
    answer: "Fox",
    category: "Nature",
  },
  {
    id: 118,
    question: "What is the national animal of Scotland?",
    answer: "Unicorn",
    category: "Nature",
  },
  {
    id: 119,
    question: "What type of creature is a Barramundi?",
    answer: "Fish",
    category: "Nature",
  },
  {
    id: 120,
    question: "What is the national animal of New Zealand?",
    answer: "Kiwi",
    category: "Nature",
  },
  {
    id: 121,
    question: "Malbec is a variety of which fruit?",
    answer: "Grape",
    category: "Nature",
  },
  {
    id: 122,
    question: "What scale is used to measure the strength of earthquakes?",
    answer: "Richter Scale",
    category: "Nature",
  },
  {
    id: 123,
    question: "How many legs does a fly have?",
    answer: "6",
    category: "Nature",
  },
  {
    id: 124,
    question: "The Lion's Mane is the largest known species of what?",
    answer: "Jellyfish",
    category: "Nature",
  },
  {
    id: 125,
    question: "What is the largest land mammal?",
    answer: "Elephant",
    category: "Nature",
  },
  {
    id: 126,
    question: "Which dog breed is also known as a 'Sausage Dog'?",
    answer: "Dachshund",
    category: "Nature",
  },
  {
    id: 127,
    question: "What term refers to a female fox?",
    answer: "Vixen",
    category: "Nature",
  },
  {
    id: 128,
    question: "What are the male bees in a bee colony called?",
    answer: "Drones",
    category: "Nature",
  },
  {
    id: 129,
    question: "Which animal did Harry Houdini famously make disappear in 1918?",
    answer: "Elephant",
    category: "Nature",
  },
  {
    id: 130,
    question: "What is the world's largest Rodent?",
    answer: "Capybara",
    category: "Nature",
  },
  {
    id: 131,
    question: "Which animal is sometimes known as a sea cow?",
    answer: "Manatee",
    category: "Nature",
  },
  {
    id: 132,
    question: "What is a baby Rabbit called?",
    answer: "Kitten",
    category: "Nature",
  },
  {
    id: 133,
    question: "Which bird lays the largest eggs?",
    answer: "Ostrich",
    category: "Nature",
  },
  {
    id: 134,
    question: "What is the term for a female alligator?",
    answer: "Cow",
    category: "Nature",
  },
  {
    id: 135,
    question: "What do Vampire Bats eat?",
    answer: "Blood",
    category: "Nature",
  },
  {
    id: 136,
    question: "Craneflies are more commonly referred to as what?",
    answer: "Daddy Long Legs",
    category: "Nature",
  },
  {
    id: 137,
    question: "How many chambers are in a ruminant animal's stomach?",
    answer: "4",
    category: "Nature",
  },
  {
    id: 138,
    question: "What body part does a Manx Cat not have?",
    answer: "Tail",
    category: "Nature",
  },
  {
    id: 139,
    question: 'A "Sidewinder" is what type of creature?',
    answer: "Snake",
    category: "Nature",
  },
  {
    id: 140,
    question: 'How many legs does a "Tick" have?',
    answer: "8",
    category: "Nature",
  },
  {
    id: 141,
    question: "What makes up the majority of a Koala's diet?",
    answer: "Eucalyptus Leaves",
    category: "Nature",
  },
  {
    id: 142,
    question: "A Wobbegong is what type of animal?",
    answer: "Shark",
    category: "Nature",
  },
  {
    id: 143,
    question: "Equus is the Latin word for which animal?",
    answer: "Horse",
    category: "Nature",
  },
  {
    id: 144,
    question: "What does a Carnivore eat?",
    answer: "Meat",
    category: "Nature",
  },
  {
    id: 145,
    question: "What is the largest spider in the world (by mass)?",
    answer: "Goliath Birdeater",
    category: "Classic",
  },
  {
    id: 146,
    question: "Laika was the name of the first dog to do what?",
    answer: "Go To Space",
    category: "Nature",
  },
  {
    id: 147,
    question: "Which is the world's largest breed of horse?",
    answer: "Shire",
    category: "Nature",
  },
  {
    id: 148,
    question: "What fruit was originally known as a Love Apple?",
    answer: "Tomato",
    category: "Nature",
  },
  {
    id: 149,
    question: "Saffron comes from which flower?",
    answer: "Crocus",
    category: "Nature",
  },
  {
    id: 150,
    question: "The Alligator Pear is another name for which fruit?",
    answer: "Avocado",
    category: "Nature",
  },
  {
    id: 151,
    question: "What berries are used in the making of Gin?",
    answer: "Juniper Berries",
    category: "Nature",
  },
  {
    id: 152,
    question: "Opium is derived from which plant?",
    answer: "Poppy",
    category: "Nature",
  },
  {
    id: 153,
    question: "Vanilla is derived from which plant family?",
    answer: "Orchid",
    category: "Nature",
  },
  {
    id: 154,
    question: "What is the name for the scientific study of plant life?",
    answer: "Botany",
    category: "Nature",
  },
  {
    id: 155,
    question: "Who played Grandad in Only Fools and Horses?",
    answer: "Lennard Pearce",
    category: "TV",
  },
  {
    id: 156,
    question: "Who co-created The Office with Ricky Gervais?",
    answer: "Stephen Merchant",
    category: "TV",
  },
  {
    id: 157,
    question: "What year did the first episode of Coronation Street air?",
    answer: "1960",
    category: "TV",
  },
  {
    id: 158,
    question: "What year did the first episode of Doctor Who air?",
    answer: "1955",
    category: "TV",
  },
  {
    id: 159,
    question:
      "Who was murdered in American the day before the first ever episode of Dr Who was broadcast?",
    answer: "President Kennedy",
    category: "TV",
  },
  {
    id: 160,
    question: "Who shot J.R. Ewing in the American TV show Dallas in 1980?",
    answer: "Kristin Shepard",
    category: "TV",
  },
  {
    id: 161,
    question: "What is Scooby Doo's nephew called?",
    answer: "Scrappy Doo",
    category: "TV",
  },
  {
    id: 162,
    question: "What does KITT stand for in the 80s TV series Knight Rider?",
    answer: "Knight Industries Two Thousand",
    category: "TV",
  },
  {
    id: 163,
    question:
      "On which fictional island is the TV series Death In Paradise set?",
    answer: "Saint-Marie",
    category: "TV",
  },
  {
    id: 164,
    question: "Which city is the American TV series ER set?",
    answer: "Chicago",
    category: "TV",
  },
  {
    id: 165,
    question: "What was the title of the first ever episode of The Simpsons?",
    answer: "Simpsons Roasting On An Open Fire",
    category: "TV",
  },
  {
    id: 166,
    question:
      "Trey Parker and Matt Stone are the creators of which animated TV series?",
    answer: "South Park",
    category: "TV",
  },
  {
    id: 167,
    question:
      "Who is the creator of the animated shows Family Guy and American Dad?",
    answer: "Seth MacFarlane",
    category: "TV",
  },
  {
    id: 168,
    question: "Who shot Mr Burns in The Simpsons?",
    answer: "Maggie Simpson",
    category: "TV",
  },
  {
    id: 169,
    question:
      "Which Hollywood actor voiced Sparky The Gay Dog in an early episode of South Park?",
    answer: "George Clooney",
    category: "TV",
  },
  {
    id: 170,
    question: "What is the name of the school bus driver in The Simpsons?",
    answer: "Otto Mann",
    category: "TV",
  },
  {
    id: 171,
    question:
      "What colour trousers does Peter Griffin usually wear in Family Guy?",
    answer: "Green",
    category: "TV",
  },
  {
    id: 172,
    question:
      "Which animated TV series is set in which the fictional city of Quahog?",
    answer: "Family Guy",
    category: "TV",
  },
  {
    id: 173,
    question:
      "What was the name the alien who appeared in 11 episodes of The Flintstones?",
    answer: "The Great Gazoo",
    category: "TV",
  },
  {
    id: 174,
    question: "What is the name of the dog that Garfield The Cat lives with?",
    answer: "Odie",
    category: "TV",
  },
  {
    id: 175,
    question:
      "Mordecai & Rigby are the main characters in which animated TV series?",
    answer: "Regular Show",
    category: "TV",
  },
  {
    id: 176,
    question: "Who is the creator of the TV show Black Mirror?",
    answer: "Charlie Brooker",
    category: "TV",
  },
  {
    id: 177,
    question:
      "Who played George Dawes on the comedy panel show Shooting Stars?",
    answer: "Matt Lucas",
    category: "TV",
  },
  {
    id: 178,
    question: "How many contestants are on each team in University Challenge?",
    answer: "4",
    category: "TV",
  },
  {
    id: 179,
    question: "Fawlty Towers was set in which English seaside town?",
    answer: "Torquay",
    category: "TV",
  },
  {
    id: 180,
    question: "Who played Phoebe Buffay in Friends?",
    answer: "Lisa Kudrow",
    category: "TV",
  },
  {
    id: 181,
    question: "Who played Leonard Hofstadter in The Big Bang Theory?",
    answer: "Johnny Galecki",
    category: "TV",
  },
  {
    id: 182,
    question:
      "Tracey Stubbs, Sharon Theodopolopodous and Dorien Green were characters in which British TV series?",
    answer: "Birds of a Feather",
    category: "TV",
  },
  {
    id: 183,
    question: "What was the sequel to the British TV series Porridge?",
    answer: "Going Straight",
    category: "TV",
  },
  {
    id: 184,
    question:
      "What is the name of the dog in the TV series The Magic Roundabout?",
    answer: "Dougal",
    category: "TV",
  },
  {
    id: 185,
    question:
      "Who played Isobel Crawley in the British TV series Downton Abbey?",
    answer: "Penelope Wilton",
    category: "TV",
  },
  {
    id: 186,
    question: "What make of car does Mr Bean drive?",
    answer: "Mini",
    category: "TV",
  },
  {
    id: 187,
    question: "What is the name of the Cat's alter ego in Red Dwarf?",
    answer: "Duayne Dibbley",
    category: "TV",
  },
  {
    id: 188,
    question:
      "The US sitcom Frasier was a spin-off from which popular TV series?",
    answer: "Cheers",
    category: "TV",
  },
  {
    id: 189,
    question:
      "Which actors films include The Hustler, Cool Hand Luke and Cars?",
    answer: "Paul Newman",
    category: "Film",
  },
  {
    id: 190,
    question: "The soap opera Brookside was set in which UK city?",
    answer: "Liverpool",
    category: "TV",
  },
  {
    id: 191,
    question: "In The Fresh Prince of Bel Air, where was Will born?",
    answer: "Philadelphia",
    category: "TV",
  },
  {
    id: 192,
    question: "What is the name of Ross's son in Friends?",
    answer: "Ben",
    category: "TV",
  },
  {
    id: 193,
    question:
      "Who was the original host of the British darts game show Bullseye?",
    answer: "Jim Bowen",
    category: "TV",
  },
  {
    id: 194,
    question: "What year did the first episode of Eastenders air?",
    answer: "1985",
    category: "TV",
  },
  {
    id: 195,
    question: "What is the name of Rene's wife in 'Allo 'Allo?",
    answer: "Edith",
    category: "TV",
  },
  {
    id: 196,
    question: "What is Rick's surname in the animated TV show Rick and Morty?",
    answer: "Sanchez",
    category: "TV",
  },
  {
    id: 197,
    question: "Which British actor voiced Danger Mouse in the 1980s?",
    answer: "David Jason",
    category: "TV",
  },
  {
    id: 198,
    question: "Count Duckula was a spinoff of which animated TV show?",
    answer: "Danger Mouse",
    category: "TV",
  },
  {
    id: 199,
    question:
      "Geordie Racer and Badger Girl were series of which educational children's programme?",
    answer: "Look And Read",
    category: "TV",
  },
  {
    id: 200,
    question: "Which pop-rock group performed the Friends theme tune?",
    answer: "The Rembrandts",
    category: "Classic",
  },
  {
    id: 201,
    question: "Which actor played Dr Dick Solomon in 3rd Rock From the Sun?",
    answer: "John Lithgow",
    category: "TV",
  },
  {
    id: 202,
    question:
      "Which 1960's Batman later regularly appeared as the Mayor in Family Guy?",
    answer: "Adam West",
    category: "TV",
  },
  {
    id: 203,
    question: "The American sitcom Joey was a spin off from which TV show?",
    answer: "Friends",
    category: "TV",
  },
  {
    id: 204,
    question:
      "Which comedian played the school headmaster Mr Gilbert in The Inbetweeners?",
    answer: "Greg Davies",
    category: "TV",
  },
  {
    id: 205,
    question:
      "Which UK comedian had a number 1 single with the song Dizzy in 1991?",
    answer: "Vic Reeves",
    category: "Music",
  },
  {
    id: 206,
    question:
      "Which Oasis song was the theme music for the British sitcom The Royle Family?",
    answer: "Half The World Away",
    category: "Music",
  },
  {
    id: 207,
    question:
      "Which British comedians alter-egos include Borat, Ali G and Bruno?",
    answer: "Sacha Baron Cohen",
    category: "TV",
  },
  {
    id: 208,
    question:
      "Which former Doctor Who actor narrated the TV show Little Britain?",
    answer: "Tom Baker",
    category: "TV",
  },
  {
    id: 209,
    question: "Hi-de-Hi! was set in which fictional holiday camp?",
    answer: "Maplins",
    category: "TV",
  },
  {
    id: 210,
    question:
      "Which major Hollywood actor appears in the BBC sitcom The Outlaws?",
    answer: "Christopher Walken",
    category: "TV",
  },
  {
    id: 211,
    question:
      "What was the name of Boycie and Marlene's Great Dane in Only Fools and Horses?",
    answer: "Duke",
    category: "TV",
  },
  {
    id: 212,
    question:
      "Who played Captain Mainwaring in the original Dad's Army TV sitcom?",
    answer: "Arthur Lowe",
    category: "TV",
  },
  {
    id: 213,
    question:
      "Which stand-up comedian ran 43 marathons in 51 days for Sport Relief in 2009?",
    answer: "Eddie Izzard",
    category: "TV",
  },
  {
    id: 214,
    question: "What is the Breaking Bad spin off show called?",
    answer: "Better Call Saul",
    category: "TV",
  },
  {
    id: 215,
    question: "What is Rodney Trotter's middle name in Only Fools and Horses?",
    answer: "Charlton",
    category: "TV",
  },
  {
    id: 216,
    question: "Elton John was the chairman of which English football team?",
    answer: "Watford",
    category: "Music",
  },
  {
    id: 217,
    question: "Who won the first series of Big Brother (UK)?",
    answer: "Craig Phillips",
    category: "TV",
  },
  {
    id: 218,
    question: "Who was the original host of Never Mind The Buzzcocks?",
    answer: "Mark Lamarr",
    category: "TV",
  },
  {
    id: 219,
    question:
      "What does 'QI' stand for in the long running British panel show?",
    answer: "Quite Interesting",
    category: "TV",
  },
  {
    id: 220,
    question: "Which radio DJ hosted the 90's kids TV show Fun House?",
    answer: "Pat Sharp",
    category: "TV",
  },
  {
    id: 221,
    question: "Mr Blobby originally featured on which 90's TV programme?",
    answer: "Noel's House Party",
    category: "TV",
  },
  {
    id: 222,
    question: "What was the name of the actor who played Lovejoy?",
    answer: "Ian McShane",
    category: "TV",
  },
  {
    id: 223,
    question:
      "The TV show Big Brother got its name from which work of fiction?",
    answer: "1984",
    category: "TV",
  },
  {
    id: 224,
    question: "What is the name of the pub in the soap Emmerdale?",
    answer: "The Woolpack",
    category: "TV",
  },
  {
    id: 225,
    question: "Which year did the TV show The Last Leg first air?",
    answer: "2012",
    category: "TV",
  },
  {
    id: 226,
    question:
      "What is the name of the character played by David Jason in Open All Hours?",
    answer: "Granville",
    category: "TV",
  },
  {
    id: 227,
    question: "Which actor played Bob Fossil in the Mighty Boosh?",
    answer: "Rich Fulcher",
    category: "TV",
  },
  {
    id: 228,
    question:
      "The 1st Premier League goal by a player born after 1st January 2000 was scored by who?",
    answer: "Ryan Sessegnon",
    category: "Sport",
  },
  {
    id: 229,
    question: "In which decade was the first episode of Blue Peter?",
    answer: "1950's",
    category: "TV",
  },
  {
    id: 230,
    question:
      "Iggle Piggle & Upsy Daisy are characters in which childrens TV program?",
    answer: "In The Night Garden",
    category: "TV",
  },
  {
    id: 231,
    question:
      '"As if by magic the shopkeeper appeared." Was a common line in which children\'s TV Programme?',
    answer: "Mr Benn",
    category: "TV",
  },
  {
    id: 232,
    question: "Which Irish actor played Roy Trenneman in the I.T. Crowd?",
    answer: "Chris O'Dowd",
    category: "TV",
  },
  {
    id: 233,
    question:
      "Which comedy sitcom is set during 'The Troubles' in Northern Ireland?",
    answer: "Derry Girls",
    category: "TV",
  },
  {
    id: 234,
    question:
      "The animated 'Ricky Gervais Show' featured Ricky Gervais, Stephen Merchant and who else?",
    answer: "Karl Pilkington",
    category: "TV",
  },
  {
    id: 235,
    question: "What was the name of the ship's computer in Red Dwarf?",
    answer: "Holly",
    category: "TV",
  },
  {
    id: 236,
    question:
      "What is the name of the actor who played Manuel in Fawlty Towers?",
    answer: "Andrew Sachs",
    category: "TV",
  },
  {
    id: 237,
    question:
      "In The Young Ones, what is the name of the college that the main characters attend?",
    answer: "Scumbag College",
    category: "TV",
  },
  {
    id: 238,
    question: "In which English county was The Vicar of Dibley set?",
    answer: "Oxfordshire",
    category: "TV",
  },
  {
    id: 239,
    question: "Who has been the voice of Family Guy's Meg Griffin since 1999?",
    answer: "Mila Cunis",
    category: "TV",
  },
  {
    id: 240,
    question:
      "In which fictional village does Postman Pat work, in the original series?",
    answer: "Greendale",
    category: "TV",
  },
  {
    id: 241,
    question: "In which fictional Welsh rural village is Fireman Sam based?",
    answer: "Pontypandy",
    category: "TV",
  },
  {
    id: 242,
    question: "What was the name of Captain Pugwash's ship?",
    answer: "The Black Pig",
    category: "TV",
  },
  {
    id: 243,
    question: "What is the parallel universe in Stranger Things known as?",
    answer: "The Upside Down",
    category: "TV",
  },
  {
    id: 244,
    question: "In the TV series LOST, where did the plane depart from?",
    answer: "Sydney",
    category: "TV",
  },
  {
    id: 245,
    question:
      "In the TV series LOST, what is the flight number of the Oceanic Airways plane which crashes?",
    answer: "815",
    category: "TV",
  },
  {
    id: 246,
    question: "What is the name of Sabrina the Teenage Witch's cat?",
    answer: "Salem",
    category: "TV",
  },
  {
    id: 247,
    question: "What does Arya Stark name her small sword in Game of Thrones?",
    answer: "Needle",
    category: "TV",
  },
  {
    id: 248,
    question: "What is the name of Frodo's sword in The Lord of the Rings?",
    answer: "Sting",
    category: "Classic",
  },
  {
    id: 249,
    question:
      "In The Royle Family, what did Denise and David name their first baby?",
    answer: "David",
    category: "TV",
  },
  {
    id: 250,
    question: "Who played Rodney Trotter in Only Fools and Horses?",
    answer: "Nicholas Lyndhurst",
    category: "TV",
  },
  {
    id: 251,
    question: "In which fictional town was the sitcom Dad's Army set?",
    answer: "Walmington-on-sea",
    category: "TV",
  },
  {
    id: 252,
    question: 'In the TV show Blackadder, who often had "a cunning plan"?',
    answer: "Baldrick",
    category: "TV",
  },
  {
    id: 253,
    question: "What do the Peaky Blinders have sewn into their caps?",
    answer: "Razor Blades",
    category: "TV",
  },
  {
    id: 254,
    question: "Who was the original presenter of University Challenge?",
    answer: "Bamber Gascoigne",
    category: "TV",
  },
  {
    id: 255,
    question: "What is the name of 'The Beast' in the quiz show The Chase?",
    answer: "Mark Labbett",
    category: "TV",
  },
  {
    id: 256,
    question: "What are the last words of Charles Foster Kane in Citizen Kane?",
    answer: "Rosebud",
    category: "Classic",
  },
  {
    id: 257,
    question:
      "Who played goalkeeper Robert Hatch in the 1981 film Escape To Victory?",
    answer: "Sylvester Stallone",
    category: "Film",
  },
  {
    id: 258,
    question:
      '"You can\'t handle the truth" Is a famous line from which Hollywood film?',
    answer: "A Few Good Men",
    category: "Film",
  },
  {
    id: 259,
    question: "Who played Golum in the Lord Of The Rings films?",
    answer: "Andy Serkis",
    category: "Film",
  },
  {
    id: 260,
    question: "What happens to Springfield in The Simpsons Movie (2007)?",
    answer: "It is placed under a dome",
    category: "Film",
  },
  {
    id: 261,
    question: "In Charmed which sister is the eldest?",
    answer: "Prue",
    category: "TV",
  },
  {
    id: 262,
    question:
      'Which author wrote "A Song Of Ice And Fire" the series of novels that Game Of Thrones is based on?',
    answer: "George R R Martin",
    category: "TV",
  },
  {
    id: 263,
    question: "Which actor played Columbo?",
    answer: "Peter Falk",
    category: "TV",
  },
  {
    id: 264,
    question: "What is the name of Keifer Sutherland's character in 24?",
    answer: "Jack Bauer",
    category: "TV",
  },
  {
    id: 265,
    question: "On what street do the 'Desperate Housewives' live?",
    answer: "Wisteria Lane",
    category: "TV",
  },
  {
    id: 266,
    question: "In which US state is Stranger Things set?",
    answer: "Indiana",
    category: "TV",
  },
  {
    id: 267,
    question: "Who is the 'Taskmaster' in the TV series of the same name?",
    answer: "Greg Davies",
    category: "TV",
  },
  {
    id: 268,
    question: "Which actress plays Villanelle in Killing Eve?",
    answer: "Jodi Comer",
    category: "TV",
  },
  {
    id: 269,
    question: "Which former child actor plays Roman Roy in 'Succession'?",
    answer: "Kieran Culkin",
    category: "TV",
  },
  {
    id: 270,
    question: "The TV series Narcos was set and filmed in which country?",
    answer: "Columbia",
    category: "TV",
  },
  {
    id: 271,
    question:
      "Who received the Best Actor Oscar for their role in The African Queen (1951)?",
    answer: "Humphrey Bogart",
    category: "Film",
  },
  {
    id: 272,
    question: "John Wayne won an Oscar in 1969 for his role in which film?",
    answer: "True Grit",
    category: "Film",
  },
  {
    id: 273,
    question:
      "For which film did Ben Kingsley win the Best Actor Oscar in 1982?",
    answer: "Gandhi",
    category: "Film",
  },
  {
    id: 274,
    question: "In the Buster Keaton film of 1926, what was 'The General'?",
    answer: "A Train",
    category: "Film",
  },
  {
    id: 275,
    question:
      "In which 1999 film does Michael Clarke Jordan play 'John Coffey'?",
    answer: "The Green Mile",
    category: "Film",
  },
  {
    id: 276,
    question: "Which actor played 'The Wedding Singer'?",
    answer: "Adam Sandler",
    category: "Film",
  },
  {
    id: 277,
    question: "What song plays each morning on the film Groundhog Day?",
    answer: "I Got You Babe",
    category: "Film",
  },
  {
    id: 278,
    question:
      "Which singer and actor played Eddie in The Rocky Horror Picture Show film?",
    answer: "Meat Loaf",
    category: "Film",
  },
  {
    id: 279,
    question:
      "In the original 'Speed' film, which type of vehicle is the bomb planted on? ",
    answer: "Bus",
    category: "Film",
  },
  {
    id: 280,
    question: "What is Ace Ventura's job title?",
    answer: "Pet Detective",
    category: "Film",
  },
  {
    id: 281,
    question:
      "Harvey Keitel plays which problem solving character in Pulp Fiction?",
    answer: "Winston Wolf",
    category: "Film",
  },
  {
    id: 282,
    question: "Who played Lara Croft in the 2001 film Tomb Raider?",
    answer: "Angelina Jolie",
    category: "Film",
  },
  {
    id: 283,
    question:
      "Cool Runnings is the story of which country competing in the bobsleigh event at the 1988 winter olympics?",
    answer: "Jamaica",
    category: "Film",
  },
  {
    id: 284,
    question: "Which character did Heath Ledger play in The Dark Night?",
    answer: "The Joker",
    category: "Film",
  },
  {
    id: 285,
    question:
      "The films 'The Green Mile' and 'The Shawshank Redemption' were based on stories by which author?",
    answer: "Stephen King",
    category: "Film",
  },
  {
    id: 286,
    question: "Who played Don Corleone in the first Godfather film?",
    answer: "Marlon Brando",
    category: "Film",
  },
  {
    id: 287,
    question:
      'How many "Angry Men" are in the title of the 1957 classic film starring Henry Fonda?',
    answer: "12",
    category: "Film",
  },
  {
    id: 288,
    question:
      '"Your mind is the scene of the crime" is the tagline of which 2010 Christopher Nolan film?',
    answer: "Inception",
    category: "Film",
  },
  {
    id: 289,
    question:
      'In which film does Detective David Mills cry "What\'s in the box?"?',
    answer: "Se7en",
    category: "Film",
  },
  {
    id: 290,
    question: "Who plays the titular character in Ferris Bueller's Day Off?",
    answer: "Matthew Broderick",
    category: "Film",
  },
  {
    id: 291,
    question:
      "Which football team is the focus of Nick Hornby's book 'Fever Pitch' and the original film of the same name?",
    answer: "Arsenal",
    category: "Film",
  },
  {
    id: 292,
    question:
      "What nickname was given to the group of young actors who frequently appeared together in coming-of-age films in the 1980s?",
    answer: "The Brat Pack",
    category: "Film",
  },
  {
    id: 293,
    question:
      "What name did Tom Hanks call the volleyball in the film Cast Away?",
    answer: "Wilson",
    category: "Film",
  },
  {
    id: 294,
    question: "The Dam Busters is set during which war?",
    answer: "World War 2",
    category: "Film",
  },
  {
    id: 295,
    question: "In what year was the first Bond film, Dr No, released?",
    answer: "1962",
    category: "Film",
  },
  {
    id: 296,
    question: "Truly Scrumptious is a character from which 1968 film?",
    answer: "Chitty Chitty Bang Bang",
    category: "Film",
  },
  {
    id: 297,
    question:
      "Who was original host of the British panel show 'Would I Lie To You?'?",
    answer: "Angus Deayton",
    category: "TV",
  },
  {
    id: 298,
    question: "Marlin is the leading character in which 2003 film?",
    answer: "Finding Nemo",
    category: "Film",
  },
  {
    id: 299,
    question: "In what year was the first Harry Potter film released?",
    answer: "2001",
    category: "Film",
  },
  {
    id: 300,
    question: '"Walk the Line" is a biography of which singer?',
    answer: "Johnny Cash",
    category: "Film",
  },
  {
    id: 301,
    question: "In the Sacha Baron Cohen film, Borat is from which country?",
    answer: "Kazakhstan",
    category: "Film",
  },
  {
    id: 302,
    question:
      "What was the name of the character played by Sigourney Weaver in the Alien films?",
    answer: "Ripley",
    category: "Film",
  },
  {
    id: 303,
    question:
      "Who played the Sheriff of Nottingham in Robin Hood: Prince of Thieves?",
    answer: "Alan Rickman",
    category: "Film",
  },
  {
    id: 304,
    question: "In darts, how high off the floor must the bull’s-eye measure?",
    answer: "5 feet 8 inches (1.73m)",
    category: "Sport",
  },
  {
    id: 305,
    question:
      "How many points does a driver get for winning a Formula One Grand Prix?",
    answer: "25",
    category: "Sport",
  },
  {
    id: 306,
    question: "Who became Britain's first £1 million footballer in 1979?",
    answer: "Trevor Francis",
    category: "Sport",
  },
  {
    id: 307,
    question: "What wood are cricket bats traditionally made from?",
    answer: "Willow",
    category: "Sport",
  },
  {
    id: 308,
    question: "What is the nickname of darts player Phil Taylor?",
    answer: "The Power",
    category: "Sport",
  },
  {
    id: 309,
    question: "In darts, how many points is the outer-bullseye worth?",
    answer: "25",
    category: "Sport",
  },
  {
    id: 310,
    question:
      "In darts, what is the name of the line you must stand behind when throwing?",
    answer: "The Oche",
    category: "Sport",
  },
  {
    id: 311,
    question: "How many points is the pink ball worth in Snooker?",
    answer: "6",
    category: "Sport",
  },
  {
    id: 312,
    question: "How many pockets are on a standard Snooker Table?",
    answer: "6",
    category: "Sport",
  },
  {
    id: 313,
    question: "Which Snooker player was known as 'The Wizard of Wishaw'?",
    answer: "John Higgins",
    category: "Sport",
  },
  {
    id: 314,
    question: "The Stanley Cup is contested in which sport?",
    answer: "Ice Hockey",
    category: "Sport",
  },
  {
    id: 315,
    question: "At which ground was the first FA Cup Final held in 1872?",
    answer: "The Kennington Oval",
    category: "Sport",
  },
  {
    id: 316,
    question: "Who was known as 'The Great One' in Ice Hockey?",
    answer: "Wayne Gretzky",
    category: "Sport",
  },
  {
    id: 317,
    question:
      "Michael Jordan played the majority of his games for which NBA Basketball Team?",
    answer: "Chicago Bulls",
    category: "Sport",
  },
  {
    id: 318,
    question: "The Queensbury Rules are associated with which sport?",
    answer: "Boxing",
    category: "Sport",
  },
  {
    id: 319,
    question: "Which is the only horse to have won 3 Grand National races?",
    answer: "Red Rum",
    category: "Sport",
  },
  {
    id: 320,
    question: "In which sport did England win the 2019 World Cup?",
    answer: "Cricket",
    category: "Sport",
  },
  {
    id: 321,
    question: "What are the New Zealand Rugby team known as?",
    answer: "The All Blacks",
    category: "Sport",
  },
  {
    id: 322,
    question: "What are the New Zealand Football team known as?",
    answer: "The All Whites",
    category: "Sport",
  },
  {
    id: 323,
    question: "What is 1 stroke over par known as in Golf?",
    answer: "Bogey",
    category: "Sport",
  },
  {
    id: 324,
    question: "The 'Golden Bear' was the nickname of which golfer?",
    answer: "Jack Nicklaus",
    category: "Sport",
  },
  {
    id: 325,
    question: "Carnoustie is a golf course in which country?",
    answer: "Scotland",
    category: "Sport",
  },
  {
    id: 326,
    question:
      "How many points is considered a perfect game in Ten-Pin Bowling?",
    answer: "300",
    category: "Sport",
  },
  {
    id: 327,
    question:
      "How many consecutive strikes are needed for a perfect game in Ten-Pin Bowling?",
    answer: "12",
    category: "Sport",
  },
  {
    id: 328,
    question:
      "In 1974 who did Muhammed Ali defeat in 'The Rumble in the Jungle'?",
    answer: "George Foreman",
    category: "Sport",
  },
  {
    id: 329,
    question: "Who was the first Boxer to defeat Mike Tyson?",
    answer: "James 'Buster' Douglas",
    category: "Sport",
  },
  {
    id: 330,
    question: "The 1992 Olympics were hosted in which city?",
    answer: "Barcelona",
    category: "Sport",
  },
];

export default data;
