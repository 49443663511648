import React from "react";
import "../../css/top5.css";

function LearnTopFive(props) {
  return (
    <div className="top5flex">
      <h2
        className={
          "top5-two-cols top5col-margin-right " +
          (props.col3 === "No" ? "retired" : undefined)
        }
      >
        {props.col1}
      </h2>
      <h2
        className={
          "top5-two-cols top5align-right " +
          (props.col3 === "No" ? "retired" : undefined)
        }
      >
        {props.col2}
      </h2>
    </div>
  );
}

export default LearnTopFive;
