import React from "react";
import Tile from "./Tile";
import "../css/hangman.css";

function Keyboard(props) {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  return (
    <div className="keyboard">
      {letters.split("").map((l, i) => (
        <Tile key={l} id={l} value={l} onClick={props.handleGuess}></Tile>
      ))}
    </div>
  );
}

export default Keyboard;
