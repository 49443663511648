import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveThreeColsNarrowLeftHeading from "./LearnTopFiveThreeColsNarrowLeftHeading";
import LearnTopFiveThreeColsNarrowLeft from "./LearnTopFiveThreeColsNarrowLeft";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import { wimbledon } from "../../LearnSpecificCategoryDetails";
import { topFivesSport } from "../../topFivesInfo";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/top5.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive key={topFive.key} col2={topFive.col2} col1={topFive.col1} />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}
function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeColsNarrowLeft
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col2country={topFive.col2country}
      col3country={topFive.col3country}
    />
  );
}
function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsNarrowLeftHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}

function LearnSportWimbledon() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={wimbledon.mainCategory}
          subCategory={wimbledon.subCategory}
          iconLrg={wimbledon.iconLrg}
          iconSml={wimbledon.iconSml}
          bigPeep={wimbledon.bigPeep}
          intro={wimbledon.intro}
          introt2={wimbledon.introt2}
          introt3={wimbledon.introt3}
          introt4={wimbledon.introt4}
          introt5={wimbledon.introt5}
        />
        <div className="top5s-container">
          <div className="top5TwoCols">
            <h2>Wimbledon Top 5 Open Era Men's Singles Wins</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.tennis.totalMensOpenEraSinglesWinsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.tennis.totalMensOpenEraSinglesWins.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Wimbledon Top 5 Open Era Ladies' Singles Wins</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.tennis.totalLadiesOpenEraSinglesWinsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.tennis.totalLadiesOpenEraSinglesWins.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Wimbledon Top 5 Amateur Era Men's Singles Wins</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.tennis.totalMensAmateurEraSinglesWinsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.tennis.totalMensAmateurEraSinglesWins.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Wimbledon Top 5 Amateur Era Ladies' Singles Wins</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.tennis.totalLadiesAmateurEraSinglesWinsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.tennis.totalLadiesAmateurEraSinglesWins.map(
              ArrangeTopFive
            )}
          </div>
        </div>
        {/* <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Number</th>
                <th>Year</th>
                <th>Gentlemens Singles</th>
                <th>Ladies Singles</th>
                <th>Gentlemens Doubles</th>
                <th>Ladies Doubles</th>
                <th>Mixed Doubles</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Number">1</td>
                <td data-label="Year">1877</td>
                <td data-label="Gentlemens Singles">Spencer Gore</td>
                <td data-label="Winners"></td>
                <td data-label="Runners Up"></td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Guillermo Stábile /8/Argentina
                </td>
                <td data-label="Number Of Teams">13</td>
                <td data-label="Result In The Final">4-2</td>
              </tr>
            </tbody>
          </table>
        </div> */}
        <LearnSpecificCategoryBottom
          importantFacts={wimbledon.importantFacts}
          classicQuestions={wimbledon.classicQuestions}
          exampleQuestions={wimbledon.exampleQuestions}
          thoughtsTipsIdeas={wimbledon.thoughtsTipsAndIdeas}
          smallPeep1={wimbledon.smallPeep1}
          smallPeep2={wimbledon.smallPeep2}
          disclaimer={wimbledon.disclaimer}
        />
        <AsideNav />
      </section>
      <div className="top5s-container">
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Australian Open Men's Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.australianOpenMensSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.australianOpenMensSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Australian Open Women's Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.australianOpenWomensSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.australianOpenWomensSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 French Open Men's Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.frenchOpenMensSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.frenchOpenMensSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 French Open Women's Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.frenchOpenWomensSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.frenchOpenWomensSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Wimbledon Men's Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.wimbledonMensSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.wimbledonMensSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Wimbledon Ladies' Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.wimbledonLadiesSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.wimbledonLadiesSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 US Open Men's Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.usOpenMensSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.usOpenMensSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 US Open Women's Singles Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.tennis.usOpenWomensSinglesWinnersHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}
          {topFivesSport.tennis.usOpenWomensSinglesWinners.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnSportWimbledon;
