import React, { useState } from "react";
import FirstLine from "./FirstLine";
import firstLines from "../firstLinesInfo";
import Header from "./Header";
import Footer from "./Footer";
import AsideNav from "./AsideNav";

let toggleOff = false;
for (let i = firstLines.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * i);
  const temp = firstLines[i];
  firstLines[i] = firstLines[j];
  firstLines[j] = temp;
}

function FirstLinesGame() {
  const [allRevealed, setAllRevealed] = useState(false);

  function handleRevealAll(event) {
    setAllRevealed(event.target.checked);
    toggleOff = !allRevealed;
  }

  function arrangeFirstLines(fL) {
    if (toggleOff) {
      return (
        <FirstLine
          key={fL.id}
          id={fL.id}
          author={fL.author}
          book={fL.book}
          year={fL.year}
          bookImg={fL.bookImg}
          firstLine={fL.firstLine}
          linkToBuy={fL.linkToBuy}
          tog={false}
        />
      );
    } else {
      return (
        <FirstLine
          key={fL.id}
          id={fL.id}
          author={fL.author}
          book={fL.book}
          year={fL.year}
          bookImg={fL.bookImg}
          firstLine={fL.firstLine}
          linkToBuy={fL.linkToBuy}
          tog={true}
        />
      );
    }
  }

  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Opening Lines</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <img
              className="peep peep-flip peep-wrap"
              src={require("../images/peep-small11.png")}
              alt="cartoon character with black hair"
            />
            {/* <div className="center-align"> */}
            <p className="body-text">
              How well do you know literature? You will be presented with the
              opening line of a book, and your task is to guess the title and
              author.
              <br />
              Most of these are classic books with well known opening lines that
              may come up as quiz questions.
              <br />
              <span className="orange-text">
                Where an opening line is an English translation of the original
                work, the words used are open to interpretation and so may be
                written differently in different versions.
              </span>
              {/* <br />
              <br />

              If you would like to buy the book, you can select the revealed
              book image and you will be directed to Amazon. */}
            </p>
            {/* </div> */}
          </div>
          <div className="line-subtitle"></div>
          <div className="checkbox-flex">
            <label className="checkbox-container">
              REVEAL ALL
              <input
                type="checkbox"
                name="allRevealed"
                id="allRevealed"
                onChange={handleRevealAll}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="arrange-first-lines">
            {firstLines.map(arrangeFirstLines)}
          </div>
        </div>
      </section>
      <AsideNav />
      <Footer />
    </div>
  );
}

export default FirstLinesGame;
