import HeaderApp from "../HeaderApp";
import "../../css/wordsearch.css";
import "../../css/style.css";
import Footer from "../Footer";
import WordsearchPeriodicTableElements from "./WordsearchPeriodicTableElements";

function WordsearchPTElements() {
  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section>
        <WordsearchPeriodicTableElements />
      </section>
      <Footer />
    </div>
  );
}

export default WordsearchPTElements;
