import WordsearchEuropeanCapitals from "./WordsearchEuropeanCapitals";
import HeaderApp from "../HeaderApp";
import "../../css/wordsearch.css";
import "../../css/style.css";
import Footer from "../Footer";

function WordsearchEUCaps() {
  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section>
        <WordsearchEuropeanCapitals />
      </section>
      <Footer />
    </div>
  );
}

export default WordsearchEUCaps;
