import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnImportantDates() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Important Historical Events</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                These historical events are likely to be the subject of quiz
                questions.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small28.png")}
              alt="cartoon character old man orange glasses"
            />
          </div>
        </div>
        <div className="table2-container">
          <table className="table2">
            <thead>
              <tr>
                <th>Year</th>
                <th>What Happened</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Year">1066</td>
                <td data-label="What Happened">Battle Of Hastings</td>
              </tr>
              <tr>
                <td data-label="Year">1140</td>
                <td data-label="What Happened">
                  Angkor Wat is built in Cambodia
                </td>
              </tr>
              <tr>
                <td data-label="Year">1149</td>
                <td data-label="What Happened">
                  Oxford University is founded in England
                </td>
              </tr>
              <tr>
                <td data-label="Year">1215</td>
                <td data-label="What Happened">
                  Magna Carta sealed at Runnymede
                </td>
              </tr>
              <tr>
                <td data-label="Year">1314</td>
                <td data-label="What Happened">Battle of Bannockburn</td>
              </tr>
              <tr>
                <td data-label="Year">1337-1453</td>
                <td data-label="What Happened">
                  The Hundred Years' War between England and France. 116 Years
                  long
                </td>
              </tr>
              <tr>
                <td data-label="Year">1415</td>
                <td data-label="What Happened">Battle of Agincourt</td>
              </tr>
              <tr>
                <td data-label="Year">1431</td>
                <td data-label="What Happened">
                  Joan of Arc burned at the stake at age 19
                </td>
              </tr>
              <tr>
                <td data-label="Year">1492</td>
                <td data-label="What Happened">
                  Christopher Columbus discovers the Americas
                </td>
              </tr>
              <tr>
                <td data-label="Year">1564</td>
                <td data-label="What Happened">William Shakespeare is born</td>
              </tr>
              <tr>
                <td data-label="Year">1588</td>
                <td data-label="What Happened">
                  Spanish Armada attacks England
                </td>
              </tr>
              <tr>
                <td data-label="Year">1620</td>
                <td data-label="What Happened">
                  English settlers known as the Pilgrims arrive at Plymouth Rock
                  (Massachusetts) on the ship the Mayflower
                </td>
              </tr>
              <tr>
                <td data-label="Year">1653</td>
                <td data-label="What Happened">
                  Oliver Cromwell becomes Lord Protector
                </td>
              </tr>
              <tr>
                <td data-label="Year">1666</td>
                <td data-label="What Happened">Great Fire of London</td>
              </tr>
              <tr>
                <td data-label="Year">1776</td>
                <td data-label="What Happened">America Gains Independence</td>
              </tr>
              <tr>
                <td data-label="Year">1804</td>
                <td data-label="What Happened">
                  Napoleon crowns himself Emperor of France
                </td>
              </tr>
              <tr>
                <td data-label="Year">1805</td>
                <td data-label="What Happened">Battle of Trafalgar</td>
              </tr>
              <tr>
                <td data-label="Year">1815</td>
                <td data-label="What Happened">
                  Napoleon defeated at the Battle of Waterloo
                </td>
              </tr>
              <tr>
                <td data-label="Year">1845-1848</td>
                <td data-label="What Happened">The Irish Potato Famine</td>
              </tr>
              <tr>
                <td data-label="Year">1848</td>
                <td data-label="What Happened">
                  Karl Marx and Freidrich Engels publish The Communist Manifesto
                </td>
              </tr>
              <tr>
                <td data-label="Year">1859</td>
                <td data-label="What Happened">
                  Charles Darwin publishes 'On the Origin of Species'
                </td>
              </tr>
              <tr>
                <td data-label="Year">1863</td>
                <td data-label="What Happened">
                  Abraham Lincoln gives the Gettysburg Address
                </td>
              </tr>
              <tr>
                <td data-label="Year">1865</td>
                <td data-label="What Happened">
                  Abraham Lincoln is assassinated by John Wilkes Booth in the
                  Ford's Theatre, Washington
                </td>
              </tr>
              <tr>
                <td data-label="Year">1876</td>
                <td data-label="What Happened">
                  Alexander Graham Bell submits his patent for the telephone
                </td>
              </tr>
              <tr>
                <td data-label="Year">1880-1881</td>
                <td data-label="What Happened">The First Boer War</td>
              </tr>
              <tr>
                <td data-label="Year">1883</td>
                <td data-label="What Happened">
                  The volcano Krakatoa explodes in Indonesia killing over 36000
                  people
                </td>
              </tr>
              <tr>
                <td data-label="Year">1895</td>
                <td data-label="What Happened">
                  Wilhelm Roentgen discovers X-Rays
                </td>
              </tr>
              <tr>
                <td data-label="Year">1899-1902</td>
                <td data-label="What Happened">The Second Boer War</td>
              </tr>
              <tr>
                <td data-label="Year">1901</td>
                <td data-label="What Happened">Queen Victoria dies</td>
              </tr>
              <tr>
                <td data-label="Year">1912</td>
                <td data-label="What Happened">
                  The Titanic sinks on its maiden voyage
                </td>
              </tr>
              <tr>
                <td data-label="Year">1914-1918</td>
                <td data-label="What Happened">World War I</td>
              </tr>
              <tr>
                <td data-label="Year">1918</td>
                <td data-label="What Happened">
                  Spanish Flu causes global pandemic
                </td>
              </tr>
              <tr>
                <td data-label="Year">1928</td>
                <td data-label="What Happened">
                  Alexander Fleming discovers Penicillin
                </td>
              </tr>
              <tr>
                <td data-label="Year">1929</td>
                <td data-label="What Happened">The Wall Street Crash</td>
              </tr>
              <tr>
                <td data-label="Year">1939-1945</td>
                <td data-label="What Happened">World War II</td>
              </tr>
              <tr>
                <td data-label="Year">1941</td>
                <td data-label="What Happened">
                  Japan attacks Pearl Harbor, which prompts America to join WW2
                </td>
              </tr>
              <tr>
                <td data-label="Year">1945</td>
                <td data-label="What Happened">
                  First 2 Nuclear bombs dropped on civilians by America on Japan
                  (Hiroshima & Nagasaki)
                </td>
              </tr>
              <tr>
                <td data-label="Year">1947</td>
                <td data-label="What Happened">
                  India gains independence from the United Kingdom
                </td>
              </tr>
              <tr>
                <td data-label="Year">1949</td>
                <td data-label="What Happened">George Orwell releases 1984</td>
              </tr>
              <tr>
                <td data-label="Year">1950</td>
                <td data-label="What Happened">
                  The Korean war begins (it technically has never ended), the
                  fighting stopped in 1953
                </td>
              </tr>
              <tr>
                <td data-label="Year">1952</td>
                <td data-label="What Happened">
                  Elizabeth II Becomes Queen of England
                </td>
              </tr>
              <tr>
                <td data-label="Year">1953</td>
                <td data-label="What Happened">
                  Edmund Hillary and Sherpa Tenzing Norgay become the first
                  people to climb Everest, Elizabeth II's coronation
                </td>
              </tr>
              <tr>
                <td data-label="Year">1955-1975</td>
                <td data-label="What Happened">Vietnam War</td>
              </tr>
              <tr>
                <td data-label="Year">1957</td>
                <td data-label="What Happened">
                  Russia launches the world's first satellite, Sputnik 1
                </td>
              </tr>
              <tr>
                <td data-label="Year">1961</td>
                <td data-label="What Happened">Berlin Wall Goes Up</td>
              </tr>
              <tr>
                <td data-label="Year">1961</td>
                <td data-label="What Happened">
                  Yuri Gagarin becomes the first human in space
                </td>
              </tr>
              <tr>
                <td data-label="Year">1962</td>
                <td data-label="What Happened">
                  Cuban Missile Crisis. The world is on the brink of nuclear war
                </td>
              </tr>
              <tr>
                <td data-label="Year">1963</td>
                <td data-label="What Happened">
                  John F Kennedy is assassinated on November 22nd. Lee Harvey
                  Oswald is the suspected assassin, who is then murdered by Jack
                  Ruby on live TV 2 days later
                </td>
              </tr>
              <tr>
                <td data-label="Year">1966</td>
                <td data-label="What Happened">
                  England Host and win the FIFA World Cup
                </td>
              </tr>
              <tr>
                <td data-label="Year">1969</td>
                <td data-label="What Happened">
                  Apollo 11 lands Neil Armstrong and Buzz Aldrin on the moon
                </td>
              </tr>
              <tr>
                <td data-label="Year">1971</td>
                <td data-label="What Happened">Britain goes decimal</td>
              </tr>
              <tr>
                <td data-label="Year">1982</td>
                <td data-label="What Happened">The Falklands War</td>
              </tr>
              <tr>
                <td data-label="Year">1986</td>
                <td data-label="What Happened">Chernobyl Disaster</td>
              </tr>
              <tr>
                <td data-label="Year">1989</td>
                <td data-label="What Happened">
                  Berlin Wall Comes Down. Tim Berners-Lee invents the World Wide
                  Web at CERN in Switzerland
                </td>
              </tr>
              <tr>
                <td data-label="Year">1990</td>
                <td data-label="What Happened">
                  Nelson Mandela released from prison after 27 years
                </td>
              </tr>
              <tr>
                <td data-label="Year">1994</td>
                <td data-label="What Happened">
                  Rwandan Genocide, over 500,000 people murdered. Nelson Mandela
                  becomes President of South Africa
                </td>
              </tr>
              <tr>
                <td data-label="Year">1996</td>
                <td data-label="What Happened">
                  Dolly the Sheep becomes the first mammal cloned from an adult
                  cell
                </td>
              </tr>
              <tr>
                <td data-label="Year">2001</td>
                <td data-label="What Happened">
                  9/11 Twin Towers in New York are destroyed in terror attack.
                  America invades Afghanistan
                </td>
              </tr>
              <tr>
                <td data-label="Year">2005</td>
                <td data-label="What Happened">
                  Youtube Launched. Hurricane Katrina hits America
                </td>
              </tr>
              <tr>
                <td data-label="Year">2010</td>
                <td data-label="What Happened">
                  The 'Arab Spring' protests begin in Tunisia
                </td>
              </tr>
              <tr>
                <td data-label="Year">2012</td>
                <td data-label="What Happened">
                  The Higgs boson particle is discovered at CERN
                </td>
              </tr>
              <tr>
                <td data-label="Year">2016</td>
                <td data-label="What Happened">
                  Brexit. Donald Trump Elected President. Leicester Win the
                  Premier League
                </td>
              </tr>
              <tr>
                <td data-label="Year">2019</td>
                <td data-label="What Happened">First Case of Covid 19</td>
              </tr>
            </tbody>
          </table>
        </div>

        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnImportantDates;
