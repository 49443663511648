import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { shakespeare } from "../../LearnSpecificCategoryDetails";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
function LearnArtAndLiteratureShakespeare() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={shakespeare.mainCategory}
          subCategory={shakespeare.subCategory}
          iconLrg={shakespeare.iconLrg}
          iconSml={shakespeare.iconSml}
          bigPeep={shakespeare.bigPeep}
          intro={shakespeare.intro}
        />
        <div className="container">
          <div className="line-xs-margin"></div>

          <h2 className="category-subtitle">Comedies</h2>
          <h3 className="subtitle-for-list">The Comedy of Errors</h3>
          <p className="body-text">
            One of the earliest plays and the shortest. The play is set in the
            city of Ephesus. Two sets of identical twins have been accidentally
            separated at birth. Antipholus of Syracuse and his servant, Dromio
            of Syracuse, have a chance encouter with Antipholus of Ephesus and
            his servant, Dromio of Ephesus. The Syracusans are mistaken for the
            Ephesians, and all sorts of comic errors and confusion ensue.
          </p>
          <h3 className="subtitle-for-list">The Taming of the Shrew</h3>
          <p className="body-text">
            Set in the fictional country of Padua in Italy. The story revolves
            around two young men, Lucentio and Hortensio, who are in love with
            the beautiful Bianca. However, Bianca's father will not allow them
            to marry her until his eldest daughter, the ill-tempered Katherine,
            is wed. The film '10 things I hate about you' is a modernization of
            this play.
          </p>

          <h3 className="subtitle-for-list">The Two Gentlemen of Verona</h3>
          <p className="body-text">
            Two close friends, Proteus and Valentine, turn on each other when
            they both fall in love with the same woman, Silvia. It tells how
            friends can act stupidy because they are in love. Thought to be one
            of the first plays Shakespeare wrote, it is also considered one of
            the worth.
          </p>
          <h3 className="subtitle-for-list">Love's Labour's Lost</h3>
          <p className="body-text">
            The King of Navarre and his three companions take an oath to spend
            three years in study and avoid contact with women. However, the
            Princess of France and her three ladies arrive, causing the men to
            fall in love and jeopardize their oath.
          </p>
          <h3 className="subtitle-for-list">A Midsummer Night's Dream</h3>
          <p className="body-text">
            Set in Athens where preparations are being made for the marriage of
            Duke Theseus to Hippolyta, the Amazon queen. Hermia & Lysander run
            away together, and Helena & Demetrius follow, and they all get lost
            in the forest. Meanwhile a group of amateur actors are also in the
            forest preparing a play to perform for the duke's wedding. They are
            led by Peter Quince. Oberon, the king of the fairies, and his queen,
            Titania manipulate the characters in the forest to ammuse
            themselves. The main play consists of five interconnecting plots.
          </p>
          <h3 className="subtitle-for-list">The Merchant of Venice</h3>
          <p className="body-text">
            Set in Venice, Italy, the play is about a man named Antonio who is a
            merchant. Antonio borrows money from a man named Shylock, a Jew.
            Antonio does not have the money to pay back Shylock and Antonio must
            battle to save his life.
          </p>
          <h3 className="subtitle-for-list">Much Ado About Nothing</h3>
          <p className="body-text">
            Set in Messina, Sicily, two pairs of lovers are tricked and
            manipulated by those close to them. Benedick and Beatrice are
            engaged in a 'merry war' of wits, while Claudio and Hero are madly
            in love. When Don John plots to destroy the happiness of the latter
            couple, all four lovers must confront the deceptions surrounding
            them.
          </p>
          <h3 className="subtitle-for-list">As You Like It</h3>
          <p className="body-text">
            Rosalind is banished from the court by her uncle Duke Frederick.
            Accompanied by her cousin Celia and the jester Touchstone, Rosalind
            finds refuge in the Forest of Arden, there they encounter Orlando,
            Audrey and Oliver. The plot is full of gender disguise and
            confusion. Rosalind has one quarter of the lines in the play, more
            than any other female character in Shakespeare. The famous quote
            'All the world's a stage, and all the men and women merely players'
            is from the 'Seven Ages of Man' monologue spoken by Jaques.
          </p>
          <h3 className="subtitle-for-list">Twelfth Night</h3>
          <p className="body-text">
            A young woman named Viola is shipwrecked on the coast of Illyria and
            believes her twin brother, Sebastian, has drowned. There she meets
            Duke Orsino, Lady Olivia and Malvolio. Gender diguise, confusion and
            a love triangle provide the main themes of this comedy.
          </p>
          <h3 className="subtitle-for-list">The Merry Wives of Windsor</h3>
          <p className="body-text">
            The play follows the story of Sir John Falstaff, a fat and
            unsuccessful knight, who attempts to seduce two married women,
            Mistress Ford and Mistress Page. It is said Queen Elizabeth enjoyed
            Falstaff's character in the Henry IV plays and ordered a play be
            written showing him in love, Shakespeare was given a deadline of
            fourteen days to do this.
          </p>
          <h3 className="subtitle-for-list">All's Well That Ends Well</h3>
          <p className="body-text">
            Helena is in love with Bertram. She is of a lower social class than
            he is so she cannot marry him. This play contains the 'bed trick'.
            It is one of Shakespeare's 3 'problem plays' as it doesn't fit the
            other comedies and has complex ethical dilemmas.
          </p>
          <h3 className="subtitle-for-list">Measure for Measure</h3>
          <p className="body-text">
            Set in Vienna, where the Duke has left in order to take part in a
            war, leaving Angelo as the temporary leader, but Angelo is not a
            good ruler, he immediately begins to enforce the laws more strictly
            than before, and must be stopped. This is one of Shakespeare's 3
            'problem plays', the tone is complex, both light comedy and dark
            tragedy.
          </p>
          <h3 className="subtitle-for-list">Pericles, Prince of Tyre</h3>
          <p className="body-text">
            The story of Pericles, a prince from the city of Tyre, who goes on a
            journey in search of his long-lost daughter. Along the way, he faces
            many challenges and meets many interesting people. It is sometimes
            identified as a tragicomedy and is written in part by George
            Wilkins.
          </p>
          <h3 className="subtitle-for-list">The Winter's Tale</h3>
          <p className="body-text">
            King Leontes accuses his queen, Hermione, of infidelity and loses
            everyone close to him. He later comes to regret his actions. It is
            one of Shakespeare's 3 'problem plays', the first 3 acts are
            psychological drama, the last 2 acts are comic. It includes the
            famous stage direction 'Exit, pursued by a bear'.
          </p>
          <h3 className="subtitle-for-list">The Tempest</h3>
          <p className="body-text">
            One of the shortest plays. The story of Prospero, the rightful Duke
            of Milan, who was overthrown and exiled by his treacherous brother
            Antonio. Prospero, his daughter Miranda, a savage called Caliban,
            and a spirit called Ariel, live on an island where Prospero uses his
            magic powers to conjure up a storm (tempest). Throughout the play
            there are betrayals, reconciliations, love and lessons in the
            importance of mercy and forgiveness. It has more music than any
            other Shakespeare play. It is the last solo play written by
            Shakepeare.
          </p>
          <h3 className="subtitle-for-list">The Two Noble Kinsmen</h3>
          <p className="body-text">
            A tragicomedy written in collaboration with John Fletcher. The plot
            was based on "The Knight's Tale" in Geoffrey Chaucer's The
            Canterbury Tales. Two close friends, Palamon and Arcite, are
            captured and imprisoned by Theseus, the Duke of Athens, and see the
            Princess Emilia from their prison window and fall in love with her.
            Their friendship is tested as they fight for her affections.
          </p>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle">Histories</h2>
          <h3 className="subtitle-for-list">Henry VI, Part 1</h3>
          <p className="body-text">
            Set during the lifetime of King Henry VI of England focussing on the
            lead up to the Wars of the Roses, and the beginnings of the civil
            war, with the murder of Henry's father by Richard of York, and the
            claim to the throne by York and his descendants. Possibly written
            with Christopher Marlowe and Thomas Nashe. The Henry VI plays and
            Richard III are sometimes grouped into a tetralogy.
          </p>

          <h3 className="subtitle-for-list">Henry VI, Part 2</h3>
          <p className="body-text">
            Details the arguments amongst the King's nobles, focusing on the
            King's inability to quell the bickering between the Houses of York
            and Lancaster. The Henry VI plays and Richard III are sometimes
            grouped into a tetralogy.
          </p>
          <h3 className="subtitle-for-list">Henry VI, Part 3</h3>
          <p className="body-text">
            Describes the horrors of conflict and war, dealing with the triumph
            of York over Lancaster. The Henry VI plays and Richard III are
            sometimes grouped into a tetralogy.
          </p>
          <h3 className="subtitle-for-list">Richard III</h3>
          <p className="body-text">
            Details the short reign of King Richard III of England. The play
            ends with a prophecy that the Plantagenets will rule England for
            many years to come. The Henry VI plays and Richard III are sometimes
            grouped into a tetralogy.
          </p>
          <h3 className="subtitle-for-list">Richard II</h3>
          <p className="body-text">
            King Richard II ruled England from 1377 to 1399, the play tells how
            King Richard II is a unpopular monarch, and what is done to try and
            replace him. It is the first play of the tetralogy which included
            which included Richard II the Henry IV plays and Henry V.
          </p>
          <h3 className="subtitle-for-list">Edward III</h3>
          <p className="body-text">
            The story of Edward the Black Prince, son of King Edward III, and
            his campaign in France during the Hundred Years' War. It was printed
            anonymously but is likely by Shakespeare and Thomas Kyd. It wasn't
            included in Shakespeare's works until the late 1990s
          </p>
          <h3 className="subtitle-for-list">King John</h3>
          <p className="body-text">
            King John ruled England from 1199 to 1216. The play tells of King
            John's battles, and short lived alliances, with the French.
          </p>
          <h3 className="subtitle-for-list">Henry IV, Part 1</h3>
          <p className="body-text">
            First performed in 1597. The play centers on King Henry IV of
            England, who is struggling to keep his throne while dealing with
            rebellion from his own family and allies. This play features Sir
            John Falstaff, a favourite character of Queen Elizabeth I. It is the
            second play of the tetralogy which included Richard II the Henry IV
            plays and Henry V. It covers Henry IVs reign between late 1402, to
            the middle of 1403.
          </p>
          <h3 className="subtitle-for-list">Henry IV, Part 2</h3>
          <p className="body-text">
            The play begins following the battle in Shrewsbury, and tells the
            preparations to battle the French. It is the third part of the
            tetralogy which included Richard II the Henry IV plays and Henry V.
          </p>
          <h3 className="subtitle-for-list">Henry V</h3>
          <p className="body-text">
            The final part of the tetralogy which included Richard II the Henry
            IV plays and Henry V. The English king Henry V embarks on a conquest
            of France.
          </p>
          <h3 className="subtitle-for-list">Henry VIII</h3>
          <p className="body-text">
            Co written with John Fletcher, it focuses on Henry VIIIs first 2
            marriages. King Henry VIII is encouragd by Cardinal Wolsey to
            divorce his wife Katharine of Aragon. The divorce, and he marries
            Anne Boleyn. The play ends with Elizabeth's christening and the
            Archbishop prophesies that Elizabeth will be great ruler.
          </p>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle">Tragedies</h2>

          <h3 className="subtitle-for-list">Titus Andronicus</h3>
          <p className="body-text">
            Probably written with George Peele. A brutal play full of violence
            and murder. Titus Andronicus takes 4 people prisoner who then plot
            revenge on him. Two of these prisoners are evenually cooked into a
            pie, which Titus serves to their mother, Tamora.
          </p>
          <h3 className="subtitle-for-list">Romeo and Juliet</h3>
          <p className="body-text">
            Set in Verona, Italy. The tragic love of two young Italians from the
            feuding families of Montague and Capulet.
          </p>
          <h3 className="subtitle-for-list">Julius Caesar</h3>
          <p className="body-text">
            A play about the assassination of Julius Caesar. The conspirators,
            led by Brutus, kill Caesar on the Ides of March because they fear he
            is becoming a tyrant. Main characters include: Brutus, Julius
            Caesar, Antony, Cassius, Calpurnia and Portia.
          </p>
          <h3 className="subtitle-for-list">Hamlet</h3>
          <p className="body-text">
            It is Shakespeare's longest play. Set in Denmark, Prince Hamlet's
            father appears to him as a ghost saying Hamlet's uncle, Claudius,
            killed him and Hamlet seeks revenge.
          </p>
          <h3 className="subtitle-for-list">Troilus and Cressida</h3>
          <p className="body-text">
            Set during the later years of the Trojan War. Achilles and Troilus
            refuse to fight in the war, and must be convinced. It is one of
            Shakespeare's 3 problem plays as the tones fluctuates between risque
            comedy and tragic gloom.
          </p>
          <h3 className="subtitle-for-list">Othello</h3>
          <p className="body-text">
            The play is set in Venice and Cyprus. Othello, a Moorish general in
            the service of Venice, is tricked into believing that his wife,
            Desdemona, has been unfaithful to him with one of his lieutenants
            The name 'Othello' means wealth. It features an interracial
            marriage, which was rare. The term 'Moor' means someone who is not
            white skinned.
          </p>
          <h3 className="subtitle-for-list">King Lear</h3>
          <p className="body-text">
            Set in Britain. King Lear is an aging king who decides to divide his
            kingdom among his three daughters Goneril, Regan and Cordelia. It is
            a play about power, politics and family.
          </p>
          <h3 className="subtitle-for-list">Macbeth</h3>
          <p className="body-text">
            The story of Macbeth, a Scottish general who becomes a king after a
            series of events that lead to the death of the previous king.
            Macbeth's rule is marked by violence and tyranny. The play is said
            to be cursed. The main characters include: Macbeth, Lady Macbeth,
            Macduff, The Three witches (or Three Weird Sisters), Malcolm and
            Banquo.
          </p>
          <h3 className="subtitle-for-list">Antony and Cleopatra</h3>
          <p className="body-text">
            The play is set in Rome and Egypt in the time of the Roman Empire.
            Antony is one of the three rulers of the empire, and he is in love
            with Cleopatra, the queen of Egypt, his actions lead to war between
            Rome and Egypt. A tale of jealousy, betrayal and enduring love. A
            tragic ending much like Romeo and Juliet.
          </p>
          <h3 className="subtitle-for-list">Coriolanus</h3>
          <p className="body-text">
            The play is based on the life of the legendary Roman leader Caius
            Marcius Coriolanus. The play opens with a prologue in which the
            Chorus describes the background of the story.
          </p>
          <h3 className="subtitle-for-list">Timon of Athens</h3>
          <p className="body-text">
            Timon is a wealthy Athenian who is generous to a fault. His friends
            take advantage of him and spend his money recklessly. Probably
            written with Thomas Middleton.
          </p>
          <h3 className="subtitle-for-list">Cymbeline</h3>
          <p className="body-text">
            Set in Ancient Britain, the play tells the story of King Cymbeline,
            who is tricked by his wicked stepmother into exiling his only
            daughter, Imogen. It is believed to be one of Shakespeare's final
            plays. The character Cymbeline is based on the historical King of
            Britain, Cunobeline.
          </p>
          <div className="line-subtitle"></div>
        </div>

        <LearnSpecificCategoryBottom
          importantFacts={shakespeare.importantFacts}
          classicQuestions={shakespeare.classicQuestions}
          exampleQuestions={shakespeare.exampleQuestions}
          thoughtsTipsIdeas={shakespeare.thoughtsTipsAndIdeas}
          // smallPeep1={shakespeare.smallPeep1}
          smallPeep2={shakespeare.smallPeep2}
          disclaimer={shakespeare.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnArtAndLiteratureShakespeare;
