const navCategories = [
  // {
  //   id: "home",
  //   path: "../home",
  //   name: "Home",
  // },
  {
    id: "learning",
    path: "../learn",
    name: "Learn",
  },
  {
    id: "practice",
    path: "../practice",
    name: "Practice",
  },
  {
    id: "find-a-quiz",
    path: "../find-a-quiz-near-me",
    name: "Find A Quiz",
  },

  {
    id: "puzzles-and-games",
    path: "../puzzles-and-games",
    name: "Puzzles & Games",
  },

  {
    id: "shop",
    path: "../shop",
    name: "Shop",
  },
  {
    id: "your-quiz-night",
    path: "../your-quiz-night",
    name: "Your Quiz Night",
  },
  {
    id: "contact",
    path: "../contact-us",
    name: "Contact",
  },
  {
    id: "about-us",
    path: "../about-us",
    name: "About Us",
  },
];
const sidebarCategories = [
  {
    id: "sidebarhome",
    path: "../home",
    name: "Home",
  },
  {
    id: "sidebarlearning",
    path: "../learn",
    name: "Learn",
  },
  {
    id: "sidebarpractice",
    path: "../practice",
    name: "Practice",
  },
  {
    id: "sidebarfind-a-quiz",
    path: "../find-a-quiz-near-me",
    name: "Find A Quiz",
  },

  {
    id: "sidebarpuzzles-and-games",
    path: "../puzzles-and-games",
    name: "Puzzles & Games",
  },

  {
    id: "sidebarshop",
    path: "../shop",
    name: "Shop",
  },
  {
    id: "sidebaryour-quiz-night",
    path: "../your-quiz-night",
    name: "Your Quiz Night",
  },
  {
    id: "sidebarcontact",
    path: "../contact-us",
    name: "Contact",
  },
  {
    id: "sidebarabout-us",
    path: "../about-us",
    name: "About Us",
  },
];

const mainCategories = [
  {
    id: "random-learning-page",
    path: [
      "./sport-and-games/football-world-cup",
      "./sport-and-games/sporting-nicknames",
      "./sport-and-games/football-home-grounds",
      "./sport-and-games/football-records",
      "./sport-and-games/football-team-nicknames",
      "./sport-and-games/tennis",
      "./sport-and-games/football-euros",
      "./sport-and-games/grand-national",
      "./art-and-literature/shakespeare",
      "./art-and-literature/artists",
      "./art-and-literature/dickens",
      "./art-and-literature/opening-lines",
      "./entertainment/james-bond",
      "./entertainment/american-entertainment-awards",
      "./entertainment/films-by-decade",
      "./entertainment/british-entertainment-awards",
      "./entertainment/eurovision",
      "./entertainment/top-film-games-tv",
      "./general-quizzing/alter-egos",
      "./general-quizzing/classic-questions",
      "./general-quizzing/twelve-days-of-christmas",
      "./general-quizzing/acronyms",
      "./general-quizzing/cocktails",
      "./general-quizzing/food-and-drink-producers",
      "./general-quizzing/hobby-and-job-words",
      "./general-quizzing/ologies",
      "./general-quizzing/social-media",
      "./geography/flags",
      "./geography/capital-cities",
      "./geography/former-place-names",
      "./geography/records",
      "./geography/us-states",
      "./history-and-civilization/american-presidents",
      "./history-and-civilization/the-bible",
      "./history-and-civilization/discoveries-and-inventions",
      "./history-and-civilization/important-historical-events",
      "./history-and-civilization/kings-and-queens",
      "./history-and-civilization/latin-words-and-phrases",
      "./history-and-civilization/language-records",
      "./history-and-civilization/occupational-surnames",
      "./history-and-civilization/uk-politics",
      "./science-and-nature/the-periodic-table",
      "./science-and-nature/mohs-hardness-scale",
      "./science-and-nature/nature-records",
      "./science-and-nature/vitamins-and-minerals",
    ],
    name: "Random Learning Page",
    bgImg: require("./images/LearningRandom.jpg"),
    altText: "orange background with floating question marks",
  },
  {
    id: "newest-learning-page",
    path: "./general-quizzing/acronyms",
    name: "Newest Learning Page",
    bgImg: require("./images/LearningNewest.jpg"),
    altText: "stacks of books in a circle with woman reading in the middle",
  },
  {
    id: "generalQuizzing",
    path: "general-quizzing",
    name: "General Quizzing",
    bgImg: require("./images/QuizDogs2.jpg"),
    altText: "skeleton thinking next to a questionmark",
  },
  {
    id: "art-and-literature",
    path: "art-and-literature",
    name: "Art & Literature",
    bgImg: require("./images/LearningArtAndLit.jpg"),
    altText: "bookshelves with colourful books on",
  },
  {
    id: "entertainment",
    path: "entertainment",
    name: "Entertainment",
    bgImg: require("./images/LearningEntertainment.jpg"),
    altText: "woman points a gun at a man",
  },
  {
    id: "geography",
    path: "geography",
    name: "Geography",
    bgImg: require("./images/LearningGeography.jpg"),
    altText: "The Gate of Heaven in Bali, Indonesia",
  },
  {
    id: "history-and-civilization",
    path: "history-and-civilization",
    name: "History & Civilization",
    bgImg: require("./images/LearningHistory.jpg"),
    altText: "Egyptian style painting",
  },
  {
    id: "science-and-nature",
    path: "science-and-nature",
    name: "Science & Nature",
    bgImg: require("./images/LearningScience.jpg"),
    altText: "periodic table of elements shining blue and orange",
  },
  {
    id: "sport-and-games",
    path: "sport-and-games",
    name: "Sport & Games",
    bgImg: require("./images/LearningSport.jpg"),
    altText: "golfer taking golf shot at sunset",
  },
];

const generalQuizzingCategories = [
  {
    id: "twelveDaysOfChristmas",
    path: "twelve-days-of-christmas",
    name: "12 Days Of Christmas",
  },
  {
    id: "acronyms",
    path: "acronyms",
    name: "Acronyms",
  },
  {
    id: "alterEgos",
    path: "alter-egos",
    name: "Alter Egos",
  },
  {
    id: "classicQuestions",
    path: "classic-questions",
    name: "Classic Questions",
  },
  {
    id: "cocktails",
    path: "cocktails",
    name: "Cocktails",
  },
  {
    id: "food-and-drink-producers",
    path: "food-and-drink-producers",
    name: "Food & Drink Producers",
  },
  {
    id: "hobbyAndJobWords",
    path: "hobby-and-job-words",
    name: "Hobby & Job Words",
  },
  {
    id: "militaryRanks",
    path: "military-ranks",
    name: "Military Ranks",
  },
  {
    id: "ologies",
    path: "ologies",
    name: "Ologies",
  },
  {
    id: "plygons",
    path: "polygons",
    name: "Polygons",
  },
  {
    id: "pre-decimalization-money",
    path: "pre-decimalisation-money",
    name: "Pre-decimalisation money (UK)",
  },
  {
    id: "social-media",
    path: "social-media",
    name: "Social Media",
  },
  {
    id: "traditional-anniversary-gifts",
    path: "traditional-anniversary-gifts",
    name: "Traditional Anniversary Gifts",
  },
];
const filmAndTVCategories = [
  {
    id: "american-entertainment-awards",
    path: "american-entertainment-awards",
    name: "American Entertainment Awards",
  },
  {
    id: "films-by-decade",
    path: "films-by-decade",
    name: "Best Films By Decade",
  },
  {
    id: "british-entertainment-awards",
    path: "british-entertainment-awards",
    name: "British Entertainment Awards",
  },
  {
    id: "eurovision",
    path: "eurovision",
    name: "Eurovision Song Contest",
  },
  {
    id: "james-bond",
    path: "james-bond",
    name: "James Bond",
  },
  {
    id: "top-film-games-tv",
    path: "top-film-games-tv",
    name: "Top Films, Video Games & TV",
  },
];

const sportCategories = [
  {
    id: "world-cup",
    path: "football-world-cup",
    name: "FIFA World Cup",
  },
  {
    id: "footballHomeGrounds",
    path: "football-home-grounds",
    name: "Football Home Grounds",
  },
  {
    id: "footballRecords",
    path: "football-records",
    name: "Football Records",
  },
  {
    id: "footballTeamNicknames",
    path: "football-team-nicknames",
    name: "Football Team Nicknames",
  },
  {
    id: "grand-national",
    path: "grand-national",
    name: "The Grand National",
  },
  {
    id: "sportingNicknames",
    path: "sporting-nicknames",
    name: "Sporting Nicknames",
  },
  {
    id: "tennis",
    path: "tennis",
    name: "Tennis",
  },
  {
    id: "euros",
    path: "football-euros",
    name: "UEFA European Championships (Euros)",
  },
];

const historyCategories = [
  {
    id: "americanPresidents",
    path: "american-presidents",
    name: "American Presidents",
  },
  {
    id: "theBible",
    path: "the-bible",
    name: "The Bible",
  },
  {
    id: "inventors",
    path: "discoveries-and-inventions",
    name: "Discoveries & Inventions",
  },
  {
    id: "historicalEvents",
    path: "important-historical-events",
    name: "Important Historical Events",
  },
  {
    id: "kingsAndQueens",
    path: "kings-and-queens",
    name: "Kings & Queens",
  },
  {
    id: "language-records",
    path: "language-records",
    name: "Language Records",
  },
  {
    id: "latinWordsAndPhrases",
    path: "latin-words-and-phrases",
    name: "Latin Words & Phrases",
  },
  {
    id: "occupationalSurnames",
    path: "occupational-surnames",
    name: "Occupational Surnames",
  },
  {
    id: "ukPolitics",
    path: "uk-politics",
    name: "UK Politics",
  },
];
const scienceCategories = [
  {
    id: "mohsHardnessScale",
    path: "mohs-hardness-scale",
    name: "Mohs Hardness Scale",
  },
  {
    id: "natureRecords",
    path: "nature-records",
    name: "Nature Records",
  },
  {
    id: "periodicTable",
    path: "the-periodic-table",
    name: "The Periodic Table",
  },
  {
    id: "vitaminsAndMinerals",
    path: "vitamins-and-minerals",
    name: "Vitamins & Minerals",
  },
];

const artAndLiteratureCategories = [
  {
    id: "artists",
    path: "artists",
    name: "Artists",
  },
  {
    id: "dickens",
    path: "dickens",
    name: "Charles Dickens",
  },
  {
    id: "opening-lines",
    path: "opening-lines",
    name: "Opening Lines",
  },
  {
    id: "shakespeare",
    path: "shakespeare",
    name: "William Shakespeare",
  },
];
const geographyCategories = [
  {
    id: "learntheafricanCountries",
    path: "african-countries",
    name: "African Countries",
  },
  {
    id: "capitalsandflagsandtheircountry",
    path: "capital-cities",
    name: "Capital Cities",
  },
  {
    id: "englishCounties",
    path: "english-counties",
    name: "English Counties",
  },
  {
    id: "formerPlaceNames",
    path: "former-place-names",
    name: "Former Place Names",
  },
  {
    id: "geographyRecords",
    path: "records",
    name: "Records",
  },
  {
    id: "usstatesinfo",
    path: "us-states",
    name: "US States",
  },
  {
    id: "flagsandtheircountry",
    path: "flags",
    name: "World Flags",
  },
];
const puzzleCategories = [
  {
    id: "hangman",
    path: "hangman",
    name: "Hangman",
  },
  { id: "pairs-game", path: "pairs", name: "Matching Pairs" },
  {
    id: "wordsearches",
    path: "wordsearches",
    name: "Wordsearches",
  },
  {
    id: "five-letter-word-guess",
    path: "word-guess",
    name: "Word Guess Games",
  },
  {
    id: "minesweeper",
    path: "minesweeper",
    name: "Minesweeper",
  },
];
const wordsearchCategories = [
  {
    id: "african-countries-ws",
    path: "african-countries",
    name: "African Countries",
  },
  {
    id: "european-capital-cities-ws",
    path: "european-capital-cities",
    name: "European Capital Cities",
  },
  {
    id: "bond-villains-ws",
    path: "bond-villains",
    name: "James Bond - Villains",
  },
  {
    id: "latin-words-and-phrases-ws",
    path: "latin-words-and-phrases",
    name: "Latin Words & Phrases",
  },
  {
    id: "periodic-table-ws",
    path: "periodic-table",
    name: "Periodic Table",
  },
  {
    id: "shakespeare-characters-ws",
    path: "shakespeare-characters",
    name: "Shakespeare Characters",
  },

  {
    id: "us-presidents-ws",
    path: "us-presidents",
    name: "US Presidents",
  },
  {
    id: "us-states-ws",
    path: "us-states",
    name: "US States",
  },
  {
    id: "world-cup-winners-ws",
    path: "world-cup",
    name: "World Cup Hosts, Winners & Runners Up",
  },
];

const footballCategories = [];
const filmCategories = [];

export {
  mainCategories,
  sportCategories,
  footballCategories,
  generalQuizzingCategories,
  navCategories,
  sidebarCategories,
  filmAndTVCategories,
  filmCategories,
  artAndLiteratureCategories,
  historyCategories,
  scienceCategories,
  geographyCategories,
  puzzleCategories,
  wordsearchCategories,
};
