import React, { useState } from "react";
import Covered from "./Covered";

function Toggler(props) {
  const [isRevealed, setIsRevealed] = useState(false);
  const toggle = () => {
    setIsRevealed(!isRevealed);
  };

  return (
    <div>
      <div onClick={toggle}>
        {isRevealed ? <h3 className="answer">{props.answer}</h3> : <Covered />}
      </div>
    </div>
  );
}

export default Toggler;
