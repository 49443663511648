import React from "react";
import { Link } from "react-router-dom";

function SubLearnChoice(props) {
  return (
    <Link
      className="sub-category-flex-item"
      to={
        typeof props.path === "string"
          ? props.path
          : props.path[Math.floor(Math.random() * props.path.length)]
      }
    >
      <h3>{props.name}</h3>
    </Link>
  );
}

export default SubLearnChoice;
