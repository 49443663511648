import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import { grandNational } from "../../LearnSpecificCategoryDetailsFive";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import "../../css/style.css";

function LearnGrandNational() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={grandNational.mainCategory}
          subCategory={grandNational.subCategory}
          iconLrg={grandNational.iconLrg}
          iconSml={grandNational.iconSml}
          bigPeep={grandNational.bigPeep}
          intro={grandNational.intro}
          intro2={grandNational.intro2}
        />
        <div className="table2-container">
          <table className="table2">
            <thead>
              <tr>
                <th>Year</th>
                <th>Horse</th>
                <th>Jockey</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1839</td>
                <td>Lottery</td>
                <td>Jem Mason</td>
              </tr>
              <tr>
                <td>1840</td>
                <td>Jerry</td>
                <td>Bartholomew Bretherton</td>
              </tr>
              <tr>
                <td>1841</td>
                <td>Charity</td>
                <td>Mr A Powell</td>
              </tr>
              <tr>
                <td>1842</td>
                <td>Gaylad</td>
                <td>Tom Olliver</td>
              </tr>
              <tr>
                <td>1843</td>
                <td>Vanguard</td>
                <td>Tom Olliver</td>
              </tr>
              <tr>
                <td>1844</td>
                <td>Discount</td>
                <td>John Crickmere</td>
              </tr>
              <tr>
                <td>1845</td>
                <td>Cure-All</td>
                <td>William Loft</td>
              </tr>
              <tr>
                <td>1846</td>
                <td>Pioneer</td>
                <td>William Taylor</td>
              </tr>
              <tr>
                <td>1847</td>
                <td>Mathew</td>
                <td>Denny Wynne</td>
              </tr>
              <tr>
                <td>1848</td>
                <td>Chandler</td>
                <td>Josey Little</td>
              </tr>
              <tr>
                <td>1849</td>
                <td>Peter Simple</td>
                <td>Tom Cunningham</td>
              </tr>
              <tr>
                <td>1850</td>
                <td>Adb-El-Kader</td>
                <td>Chris Green</td>
              </tr>
              <tr>
                <td>1851</td>
                <td>Adb-El-Kader</td>
                <td>Tom Abbott</td>
              </tr>
              <tr>
                <td>1852</td>
                <td>Miss Mowbray</td>
                <td>Alec Goodman</td>
              </tr>
              <tr>
                <td>1853</td>
                <td>Peter Simple</td>
                <td>Tom Olliver</td>
              </tr>
              <tr>
                <td>1854</td>
                <td>Bourton</td>
                <td>John Tasker</td>
              </tr>
              <tr>
                <td>1855</td>
                <td>Wanderer</td>
                <td>John Hanlon</td>
              </tr>
              <tr>
                <td>1856</td>
                <td>Freetrader</td>
                <td>George Stevens</td>
              </tr>
              <tr>
                <td>1857</td>
                <td>Emigrant</td>
                <td>Charlie Boyce</td>
              </tr>
              <tr>
                <td>1858</td>
                <td>Little Charley</td>
                <td>William Archer</td>
              </tr>
              <tr>
                <td>1859</td>
                <td>Half Caste</td>
                <td>Chris Green</td>
              </tr>
              <tr>
                <td>1860</td>
                <td>Anatis</td>
                <td>Tommy Pickernell</td>
              </tr>
              <tr>
                <td>1861</td>
                <td>Jealousy</td>
                <td>Joseph Kendall</td>
              </tr>
              <tr>
                <td>1862</td>
                <td>The Huntsman</td>
                <td>Harry Lamplugh</td>
              </tr>
              <tr>
                <td>1863</td>
                <td>Emblem</td>
                <td>George Stevens</td>
              </tr>
              <tr>
                <td>1864</td>
                <td>Emblematic</td>
                <td>George Stevens</td>
              </tr>
              <tr>
                <td>1865</td>
                <td>Alcibiade</td>
                <td>Henry Coventry</td>
              </tr>
              <tr>
                <td>1866</td>
                <td>Salamander</td>
                <td>Alec Goodmanl</td>
              </tr>
              <tr>
                <td>1867</td>
                <td>Cortolvin</td>
                <td>John Page</td>
              </tr>
              <tr>
                <td>1868</td>
                <td>The Lamb</td>
                <td>George Ede</td>
              </tr>
              <tr>
                <td>1869</td>
                <td>The Colonel</td>
                <td>George Stevens</td>
              </tr>
              <tr>
                <td>1870</td>
                <td>The Colonel</td>
                <td>George Stevens</td>
              </tr>
              <tr>
                <td>1871</td>
                <td>The Lamb</td>
                <td>Tommy Pickernell</td>
              </tr>
              <tr>
                <td>1872</td>
                <td>Casse Tete</td>
                <td>John Page</td>
              </tr>
              <tr>
                <td>1873</td>
                <td>Disturbance</td>
                <td>J. M. Richardson</td>
              </tr>
              <tr>
                <td>1874</td>
                <td>Reugny</td>
                <td>J. M. Richardson</td>
              </tr>
              <tr>
                <td>1875</td>
                <td>Pathfinder</td>
                <td>Tommy Pickernell</td>
              </tr>
              <tr>
                <td>1876</td>
                <td>Regal</td>
                <td>Joe Cannon</td>
              </tr>
              <tr>
                <td>1877</td>
                <td>Austerlitz</td>
                <td>Fred Hobson</td>
              </tr>
              <tr>
                <td>1878</td>
                <td>Shifnal</td>
                <td>J. Jones</td>
              </tr>
              <tr>
                <td>1879</td>
                <td>The Liberator</td>
                <td>Garrett Moore</td>
              </tr>
              <tr>
                <td>1880</td>
                <td>Empress</td>
                <td>Tommy Beasley</td>
              </tr>
              <tr>
                <td>1881</td>
                <td>Woodbrook</td>
                <td>Tommy Beasley</td>
              </tr>
              <tr>
                <td>1882</td>
                <td>Seaman</td>
                <td>Lord Manners</td>
              </tr>
              <tr>
                <td>1883</td>
                <td>Zoedone</td>
                <td>Count Karel Kinsky</td>
              </tr>
              <tr>
                <td>1884</td>
                <td>Voluptary</td>
                <td>Ted Wilson</td>
              </tr>
              <tr>
                <td>1885</td>
                <td>Roquefort</td>
                <td>Ted Wilson</td>
              </tr>
              <tr>
                <td>1886</td>
                <td>Old Joe</td>
                <td>Tommy Skelton</td>
              </tr>
              <tr>
                <td>1887</td>
                <td>Gamecock</td>
                <td>Bill Daniels</td>
              </tr>
              <tr>
                <td>1888</td>
                <td>Playfair</td>
                <td>George Mawson</td>
              </tr>
              <tr>
                <td>1889</td>
                <td>Frigate</td>
                <td>Tommy Beasley</td>
              </tr>
              <tr>
                <td>1890</td>
                <td>Ilex</td>
                <td>Arthur Nightingall</td>
              </tr>
              <tr>
                <td>1891</td>
                <td>Come Away</td>
                <td>Harry Beasley</td>
              </tr>
              <tr>
                <td>1892</td>
                <td>Father O'Flynn</td>
                <td>Roddy Owen</td>
              </tr>
              <tr>
                <td>1893</td>
                <td>Cloister</td>
                <td>Bill Dollery</td>
              </tr>
              <tr>
                <td>1894</td>
                <td>Why Not</td>
                <td>Arthur Nightingall</td>
              </tr>
              <tr>
                <td>1895</td>
                <td>Wild Man From Borneo</td>
                <td>Joe Widger</td>
              </tr>
              <tr>
                <td>1896</td>
                <td>The Soarer</td>
                <td>David Campbell</td>
              </tr>
              <tr>
                <td>1897</td>
                <td>Manifesto</td>
                <td>Terry Kavanagh</td>
              </tr>
              <tr>
                <td>1898</td>
                <td>Drogheda</td>
                <td>John Gourley</td>
              </tr>
              <tr>
                <td>1899</td>
                <td>Manifesto</td>
                <td>George Williamson</td>
              </tr>
              <tr>
                <td>1900</td>
                <td>Ambush II</td>
                <td>Algy Anthony</td>
              </tr>
              <tr>
                <td>1901</td>
                <td>Grudon</td>
                <td>Arthur Nightingall</td>
              </tr>
              <tr>
                <td>1902</td>
                <td>Shannon Lass</td>
                <td>David Read</td>
              </tr>
              <tr>
                <td>1903</td>
                <td>Drumcree</td>
                <td>Percy Woodland</td>
              </tr>
              <tr>
                <td>1904</td>
                <td>Moifaa</td>
                <td>Arthur Birch</td>
              </tr>
              <tr>
                <td>1905</td>
                <td>Kirkland</td>
                <td>Frank Mason</td>
              </tr>
              <tr>
                <td>1906</td>
                <td>Ascetic's Silver</td>
                <td>Aubrey Hastings</td>
              </tr>
              <tr>
                <td>1907</td>
                <td>Eremon</td>
                <td>Alf Newey</td>
              </tr>
              <tr>
                <td>1908</td>
                <td>Rubio</td>
                <td>Henry Bletsoe</td>
              </tr>
              <tr>
                <td>1909</td>
                <td>Lutteur III</td>
                <td>Georges Parfrement</td>
              </tr>
              <tr>
                <td>1910</td>
                <td>Jenkinstown</td>
                <td>Robert Chadwick</td>
              </tr>
              <tr>
                <td>1911</td>
                <td>Glenside</td>
                <td>Jack Anthony</td>
              </tr>
              <tr>
                <td>1912</td>
                <td>Jerry M</td>
                <td>Ernie Piggott</td>
              </tr>
              <tr>
                <td>1913</td>
                <td>Covertcoat</td>
                <td>Percy Woodland</td>
              </tr>
              <tr>
                <td>1914</td>
                <td>Sunloch</td>
                <td>Bill Smith</td>
              </tr>
              <tr>
                <td>1915</td>
                <td>Ally Sloper</td>
                <td>Jack Anthony</td>
              </tr>
              <tr>
                <td>1916</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1917</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1918</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1919</td>
                <td>Poethlyn</td>
                <td>Ernie Piggott</td>
              </tr>
              <tr>
                <td>1920</td>
                <td>Troytown</td>
                <td>Jack Anthony</td>
              </tr>
              <tr>
                <td>1921</td>
                <td>Shaun Spadah</td>
                <td>Fred Rees</td>
              </tr>
              <tr>
                <td>1922</td>
                <td>Music Hall</td>
                <td>Lewis Rees</td>
              </tr>
              <tr>
                <td>1923</td>
                <td>Sergeant Murphy</td>
                <td>Tuppy Bennett</td>
              </tr>
              <tr>
                <td>1924</td>
                <td>Master Robert</td>
                <td>Bob Trudgill</td>
              </tr>
              <tr>
                <td>1925</td>
                <td>Double Chance</td>
                <td>John Wilson</td>
              </tr>
              <tr>
                <td>1926</td>
                <td>Jack Horner</td>
                <td>William Watkinson</td>
              </tr>
              <tr>
                <td>1927</td>
                <td>Sprig</td>
                <td>Ted Leader</td>
              </tr>
              <tr>
                <td>1928</td>
                <td>Tipperary Tim</td>
                <td>Bill Dutton</td>
              </tr>
              <tr>
                <td>1929</td>
                <td>Gregalach</td>
                <td>Robert Everett</td>
              </tr>
              <tr>
                <td>1930</td>
                <td>Shaun Goilin</td>
                <td>Tommy Cullinan</td>
              </tr>
              <tr>
                <td>1931</td>
                <td>Grakle</td>
                <td>Bob Lyall</td>
              </tr>
              <tr>
                <td>1932</td>
                <td>Forbra</td>
                <td>Tim Hamey</td>
              </tr>
              <tr>
                <td>1933</td>
                <td>Kellsboro' Jack</td>
                <td>Dedley Williams</td>
              </tr>
              <tr>
                <td>1934</td>
                <td>Golden Miller</td>
                <td>Gerry Wilson</td>
              </tr>
              <tr>
                <td>1935</td>
                <td>Reynoldstown</td>
                <td>Frank Furlong</td>
              </tr>
              <tr>
                <td>1936</td>
                <td>Reynoldstown</td>
                <td>Fulke Walwyn</td>
              </tr>
              <tr>
                <td>1937</td>
                <td>Royal Mail</td>
                <td>Evenn Williams</td>
              </tr>
              <tr>
                <td>1938</td>
                <td>Battleship</td>
                <td>Bruce Hobbs</td>
              </tr>
              <tr>
                <td>1939</td>
                <td>Workman</td>
                <td>Tim Hyde</td>
              </tr>
              <tr>
                <td>1940</td>
                <td>Bogskar</td>
                <td>Mervyn Jones</td>
              </tr>
              <tr>
                <td>1941</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1942</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1943</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1944</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1945</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>1946</td>
                <td>Lovely Cottage</td>
                <td>Bobby Petre</td>
              </tr>
              <tr>
                <td>1947</td>
                <td>Caughoo</td>
                <td>Eddie Dempsey</td>
              </tr>
              <tr>
                <td>1948</td>
                <td>Sheila's Cottage</td>
                <td>Arthur Thompson</td>
              </tr>
              <tr>
                <td>1949</td>
                <td>Russian Hero</td>
                <td>Leo McMorrow</td>
              </tr>
              <tr>
                <td>1950</td>
                <td>Freebooter</td>
                <td>Jimmy Power</td>
              </tr>
              <tr>
                <td>1951</td>
                <td>Nickel Coin</td>
                <td>John Bullock</td>
              </tr>
              <tr>
                <td>1940</td>
                <td>Bogskar</td>
                <td>Mervyn Jones</td>
              </tr>
              <tr>
                <td>1952</td>
                <td>Teal</td>
                <td>Arthur Thompson</td>
              </tr>
              <tr>
                <td>1953</td>
                <td>Early Mist</td>
                <td>Bryan Marshall</td>
              </tr>
              <tr>
                <td>1954</td>
                <td>Royal Tan</td>
                <td>Bryan Marshall</td>
              </tr>
              <tr>
                <td>1955</td>
                <td>Quare Times</td>
                <td>Pat Taaffe</td>
              </tr>
              <tr>
                <td>1956</td>
                <td>E.S.B.</td>
                <td>David Dick</td>
              </tr>
              <tr>
                <td>1957</td>
                <td>Sundew</td>
                <td>Fred Winter</td>
              </tr>
              <tr>
                <td>1958</td>
                <td>Mr. What</td>
                <td>Arthur Freeman</td>
              </tr>
              <tr>
                <td>1959</td>
                <td>Oxo</td>
                <td>Michael Scudamore</td>
              </tr>
              <tr>
                <td>1960</td>
                <td>Merryman II</td>
                <td>Gerry Scott</td>
              </tr>
              <tr>
                <td>1961</td>
                <td>Nicolaus Silver</td>
                <td>Bobby Beasley</td>
              </tr>
              <tr>
                <td>1962</td>
                <td>Kilmore</td>
                <td>Fred Winter</td>
              </tr>
              <tr>
                <td>1963</td>
                <td>Ayala</td>
                <td>Pat Buckley</td>
              </tr>
              <tr>
                <td>1964</td>
                <td>Team Spirit</td>
                <td>Willie Robinson</td>
              </tr>
              <tr>
                <td>1965</td>
                <td>Jay Trump</td>
                <td>Tommy Smith</td>
              </tr>
              <tr>
                <td>1966</td>
                <td>Anglo</td>
                <td>Tim Norman</td>
              </tr>
              <tr>
                <td>1967</td>
                <td>Foinavon</td>
                <td>John Buckingham</td>
              </tr>
              <tr>
                <td>1968</td>
                <td>Red Alligator</td>
                <td>Brian Fletcher</td>
              </tr>
              <tr>
                <td>1969</td>
                <td>Highland Wedding</td>
                <td>Eddie Harty Sr.</td>
              </tr>
              <tr>
                <td>1970</td>
                <td>Gay Trip</td>
                <td>Pat Taaffe</td>
              </tr>
              <tr>
                <td>1971</td>
                <td>Specify</td>
                <td>John Cook</td>
              </tr>
              <tr>
                <td>1972</td>
                <td>Well To Do</td>
                <td>Gaham Thorner</td>
              </tr>
              <tr>
                <td>1973</td>
                <td>Red Rum</td>
                <td>Brian Fletcher</td>
              </tr>
              <tr>
                <td>1974</td>
                <td>Red Rum</td>
                <td>Brian Fletcher</td>
              </tr>
              <tr>
                <td>1975</td>
                <td>L'Escargot</td>
                <td>Tommy Carbery</td>
              </tr>
              <tr>
                <td>1976</td>
                <td>Rag Trade</td>
                <td>John Burke</td>
              </tr>
              <tr>
                <td>1977</td>
                <td>Red Rum</td>
                <td>Tommy Stack</td>
              </tr>
              <tr>
                <td>1978</td>
                <td>Lucius</td>
                <td>Bob Davies</td>
              </tr>
              <tr>
                <td>1979</td>
                <td>Rubstic</td>
                <td>Maurice Barnes</td>
              </tr>
              <tr>
                <td>1980</td>
                <td>Ben Nevis</td>
                <td>Charlie Fenwick</td>
              </tr>
              <tr>
                <td>1981</td>
                <td>Aldaniti</td>
                <td>Bob Champion</td>
              </tr>
              <tr>
                <td>1982</td>
                <td>Grittar</td>
                <td>Dick Saunders</td>
              </tr>
              <tr>
                <td>1983</td>
                <td>Corbiere</td>
                <td>Ben de Haan</td>
              </tr>
              <tr>
                <td>1984</td>
                <td>Hallo Dandy</td>
                <td>Neale Doughty</td>
              </tr>
              <tr>
                <td>1985</td>
                <td>Last Suspect</td>
                <td>Hywel Davies</td>
              </tr>
              <tr>
                <td>1986</td>
                <td>West Tip</td>
                <td>Richard Dunwoody</td>
              </tr>
              <tr>
                <td>1987</td>
                <td>Moari Venture</td>
                <td>Steve Knight</td>
              </tr>
              <tr>
                <td>1988</td>
                <td>Ryme 'n' Reason</td>
                <td>Brendan Powell</td>
              </tr>
              <tr>
                <td>1989</td>
                <td>Little Polveir</td>
                <td>Jimmy Frost</td>
              </tr>
              <tr>
                <td>1990</td>
                <td>Mr Frisk</td>
                <td>Marcus Armytage</td>
              </tr>
              <tr>
                <td>1991</td>
                <td>Seagram</td>
                <td>Nigel Hawke</td>
              </tr>
              <tr>
                <td>1992</td>
                <td>Party Politics</td>
                <td>Carl Llewellyn</td>
              </tr>
              <tr>
                <td className="faded-data">1993</td>
                <td className="faded-data">Race Declared Void</td>
                <td className="faded-data">Due To False Starts</td>
              </tr>
              <tr>
                <td>1994</td>
                <td>Miinnehoma</td>
                <td>Richard Dunwoody</td>
              </tr>
              <tr>
                <td>1995</td>
                <td>Royal Athlete</td>
                <td>Jason Titley</td>
              </tr>
              <tr>
                <td>1996</td>
                <td>Rough Quest</td>
                <td>Mick Fitzgerald</td>
              </tr>
              <tr>
                <td>1997</td>
                <td>Lord Gyllene</td>
                <td>Tony Dobbin</td>
              </tr>
              <tr>
                <td>1998</td>
                <td>Earth Summit</td>
                <td>Car Llewellyn</td>
              </tr>
              <tr>
                <td>1999</td>
                <td>Bobbyjo</td>
                <td>Paul Carberry</td>
              </tr>
              <tr>
                <td>2000</td>
                <td>Papillon</td>
                <td>Ruby Walsh</td>
              </tr>
              <tr>
                <td>2001</td>
                <td>Red Marauder</td>
                <td>Richard Guest</td>
              </tr>
              <tr>
                <td>2002</td>
                <td>Bindaree</td>
                <td>Jim Culloty</td>
              </tr>
              <tr>
                <td>2003</td>
                <td>Monty's Pass</td>
                <td>Barry Geraghty</td>
              </tr>
              <tr>
                <td>2004</td>
                <td>Amberleigh House</td>
                <td>Graham Lee</td>
              </tr>
              <tr>
                <td>2005</td>
                <td>Hedgehuter</td>
                <td>Ruby Walsh</td>
              </tr>
              <tr>
                <td>2006</td>
                <td>Numbersixvalverde</td>
                <td>Niall Madden</td>
              </tr>
              <tr>
                <td>2007</td>
                <td>Silver Birch</td>
                <td>Robbie Power</td>
              </tr>
              <tr>
                <td>2008</td>
                <td>Comply or Die</td>
                <td>Timmy Murphy</td>
              </tr>
              <tr>
                <td>2009</td>
                <td>Mon mome</td>
                <td>Liam Treadwell</td>
              </tr>
              <tr>
                <td>2010</td>
                <td>Don't Push It</td>
                <td>Tony McCoy</td>
              </tr>
              <tr>
                <td>2011</td>
                <td>Ballabriggs</td>
                <td>Jason Maguire</td>
              </tr>
              <tr>
                <td>2012</td>
                <td>Neptunes Collonges</td>
                <td>Daryl Jacob</td>
              </tr>
              <tr>
                <td>2013</td>
                <td>Auroras Encore</td>
                <td>Ryan Mania</td>
              </tr>
              <tr>
                <td>2014</td>
                <td>Pineau De Re</td>
                <td>Leighton Aspell</td>
              </tr>
              <tr>
                <td>2015</td>
                <td>Many Clouds</td>
                <td>Leighton Aspell</td>
              </tr>
              <tr>
                <td>2016</td>
                <td>Rule The World</td>
                <td>David Mullins</td>
              </tr>
              <tr>
                <td>2017</td>
                <td>One For Arthur</td>
                <td>Derek Fox</td>
              </tr>
              <tr>
                <td>2018</td>
                <td>Tiger Roll</td>
                <td>Davy Russell</td>
              </tr>
              <tr>
                <td>2019</td>
                <td>Tiger Roll</td>
                <td>Davy Russell</td>
              </tr>
              <tr>
                <td>2020</td>
                <td>-</td>
                <td>-</td>
              </tr>
              <tr>
                <td>2021</td>
                <td>Minella Times</td>
                <td>Rachael Blackmore</td>
              </tr>
              <tr>
                <td>2022</td>
                <td>Noble Yeats</td>
                <td>Sam Waley-Cohen</td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={grandNational.importantFacts}
          classicQuestions={grandNational.classicQuestions}
          exampleQuestions={grandNational.exampleQuestions}
          thoughtsTipsIdeas={grandNational.thoughtsTipsAndIdeas}
          // smallPeep1={grandNational.smallPeep1}
          smallPeep2={grandNational.smallPeep2}
          disclaimer={grandNational.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnGrandNational;
