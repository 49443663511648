import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import WordleTile from "./WordleTile";
import "../css/wordle.css";
import HeaderApp from "./HeaderApp";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import wordListDictSix from "../WordListDictSix";

const randomNum = Math.floor(Math.random() * wordListDictSix.length);

function Wordle() {
  const letter1Ref = useRef();
  const letter2Ref = useRef();
  const letter3Ref = useRef();
  const letter4Ref = useRef();
  const letter5Ref = useRef();
  const letter6Ref = useRef();
  const letter7Ref = useRef();
  const letter8Ref = useRef();
  const letter9Ref = useRef();
  const letter10Ref = useRef();
  const letter11Ref = useRef();
  const letter12Ref = useRef();
  const letter13Ref = useRef();
  const letter14Ref = useRef();
  const letter15Ref = useRef();
  const letter16Ref = useRef();
  const letter17Ref = useRef();
  const letter18Ref = useRef();
  const letter19Ref = useRef();
  const letter20Ref = useRef();
  const letter21Ref = useRef();
  const letter22Ref = useRef();
  const letter23Ref = useRef();
  const letter24Ref = useRef();
  const letter25Ref = useRef();
  const letter26Ref = useRef();
  const letter27Ref = useRef();
  const letter28Ref = useRef();
  const letter29Ref = useRef();
  const letter30Ref = useRef();
  const letter31Ref = useRef();
  const letter32Ref = useRef();
  const letter33Ref = useRef();
  const letter34Ref = useRef();
  const letter35Ref = useRef();
  const letter36Ref = useRef();

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const [letterRefs, setLetterRefs] = useState([
    letter1Ref,
    letter2Ref,
    letter3Ref,
    letter4Ref,
    letter5Ref,
    letter6Ref,
  ]);

  const [guessedWord, setGuessedWord] = useState("");
  const [word, setWord] = useState(wordListDictSix[randomNum].toUpperCase());
  const [guessNumber, setGuessNumber] = useState(1);
  const [pastGuesses, setPastGuesses] = useState([]);
  const [gameIsFinished, setGameIsFinished] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const [allGreen, setAllGreen] = useState(false);
  const [greenLetterArray, setGreenLetterArray] = useState([]);
  const [greyLetterArray, setGreyLetterArray] = useState([]);
  const [yellowLetterArray, setYellowLetterArray] = useState([]);

  let wordArray = [...word];
  let guessedWordArray = [...guessedWord];
  let guessedWordArrayObj = guessedWordArray.map((l) => ({
    letter: l,
    isGreen: false,
    isYellow: false,
    isGrey: false,
  }));

  const wordleLength = 6;
  const maxGuesses = 6;

  function checkAnswer() {
    if (guessedWordArray.length < wordleLength) {
      return;
    }
    letterRefs.map((l) => {
      l.current.classList.add("move-tiles");
    });
    setPastGuesses([...pastGuesses, guessedWord]);
    setGuessNumber((guessNumber) => guessNumber + 1);
    checkAnswerForGreen();
    checkAnswerForYellow();
    checkAnswerForGrey();
    setGuessedWord("");
    finishGame();
  }

  useEffect(() => {
    if (guessNumber === 2) {
      setLetterRefs([
        letter7Ref,
        letter8Ref,
        letter9Ref,
        letter10Ref,
        letter11Ref,
        letter12Ref,
      ]);
    } else if (guessNumber === 3) {
      setLetterRefs([
        letter13Ref,
        letter14Ref,
        letter15Ref,
        letter16Ref,
        letter17Ref,
        letter18Ref,
      ]);
    } else if (guessNumber === 4) {
      setLetterRefs([
        letter19Ref,
        letter20Ref,
        letter21Ref,
        letter22Ref,
        letter23Ref,
        letter24Ref,
      ]);
    } else if (guessNumber === 5) {
      setLetterRefs([
        letter25Ref,
        letter26Ref,
        letter27Ref,
        letter28Ref,
        letter29Ref,
        letter30Ref,
      ]);
    } else if (guessNumber === 6) {
      setLetterRefs([
        letter31Ref,
        letter32Ref,
        letter33Ref,
        letter34Ref,
        letter35Ref,
        letter36Ref,
      ]);
    }
  }, [guessNumber]);

  function checkAnswerForGreen() {
    let greenLetters = "";
    if (!guessedWordArray.length === wordleLength) {
      return;
    } else if (guessedWordArray.length === wordleLength) {
      for (let i = 0; i < letterRefs.length; i++) {
        if (letterRefs[i].current.innerText === wordArray[i]) {
          letterRefs[i].current.classList.add("correct-letter");
          guessedWordArrayObj[i].isGreen = true;
          greenLetters += letterRefs[i].current.innerText;
          wordArray[i] = "";
        }
      }
    }
    setGreenLetterArray([...greenLetterArray, greenLetters]);
  }

  function checkAnswerForYellow() {
    let yellowLetters = "";
    if (!guessedWordArray.length === wordleLength) {
      return;
    } else if (guessedWordArray.length === wordleLength) {
      for (let i = 0; i < letterRefs.length; i++) {
        if (
          wordArray.includes(guessedWordArrayObj[i].letter) &&
          !guessedWordArrayObj[i].isGreen
        ) {
          letterRefs[i].current.classList.add("correct-letter-wrong-pos");
          guessedWordArrayObj[i].isYellow = true;
          let idx = wordArray.indexOf(guessedWordArrayObj[i].letter);
          yellowLetters += wordArray[idx];
          wordArray[idx] = "";
        }
      }
    }
    setYellowLetterArray([...yellowLetterArray, yellowLetters]);
  }

  function checkAnswerForGrey() {
    let greyLetters = "";
    if (!guessedWordArray.length === wordleLength) {
      return;
    } else if (guessedWordArray.length === wordleLength) {
      for (let i = 0; i < letterRefs.length; i++) {
        if (
          !wordArray.includes(letterRefs[i].current.innerText) &&
          !guessedWordArrayObj[i].isGreen &&
          !guessedWordArrayObj[i].isYellow
        ) {
          letterRefs[i].current.classList.add("wrong-letter");
          greyLetters += letterRefs[i].current.innerText;
          guessedWordArrayObj[i].isGrey = true;
        }
      }
    }
    setGreyLetterArray([...greyLetterArray, greyLetters]);
  }

  function deleteLetter() {
    for (let w of wordArray) {
      if (w !== "") {
        setGuessedWord(guessedWord.slice(0, -1));
        guessedWordArray = [...guessedWord];
        guessedWordArrayObj = guessedWordArray.map((l) => ({
          letter: l,
          isGreen: false,
          isYellow: false,
          isGrey: false,
        }));
      }
    }
  }

  function finishGame() {
    let greenCount = 0;
    for (let gwao of guessedWordArrayObj) {
      gwao.isGreen ? greenCount++ : (greenCount = greenCount);
    }
    if (greenCount >= wordleLength) {
      setGameIsFinished(true);
      setAllGreen(true);
    } else if (guessNumber >= maxGuesses) {
      setGameIsFinished(true);
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const keyDownHandler = (event) => {
    if (guessedWord.length > wordleLength) {
      return;
    } else if (event.key === "Backspace") {
      deleteLetter();
    } else if (event.key === "Enter") {
      checkAnswer();
    } else if (/^[A-Za-z]$/.test(event.key)) {
      if (guessedWord.length < wordleLength) {
        setGuessedWord((prevWord) => prevWord + event.key.toUpperCase());
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [guessedWord]);

  let keyboardMap = letters
    .split("")
    .map((l, i) => (
      <WordleTile
        key={l}
        id={l}
        value={l}
        letterRefs={letterRefs}
        guessedWord={guessedWord}
        setGuessedWord={setGuessedWord}
        word={word}
        guessedWordArrayObj={guessedWordArrayObj}
        notIncluded={greyLetterArray.join("").includes(l)}
        yellowIncluded={yellowLetterArray.join("").includes(l)}
        greenIncluded={greenLetterArray.join("").includes(l)}
        wordleLength={wordleLength}
      ></WordleTile>
    ));

  return (
    <div className="push-footer hero">
      <HeaderApp />
      {rulesOpen ? (
        <div className="rules-container">
          <div className="rules">
            <h2>RULES</h2>
            <span
              className="key-corner key-corner-x"
              onClick={() => setRulesOpen(false)}
            >
              X
            </span>
            <p>
              The rules of this game are slightly different to the The New York
              Times Wordle.
            </p>
            <ul>
              <li>You can enter any combination of letters</li>
              <li>
                All words can be found in the English dictionary, and there are
                no plural words
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="key">
        <FontAwesomeIcon
          className="key-corner"
          icon={faCaretRight}
          size="4x"
          onClick={() => setRulesOpen(true)}
        />
        <p className="key-title">
          KEY<span className="key-span">Rules</span>
        </p>

        <div className="key-items">
          <p className="key-item">
            <span className="key-box-red"></span>
            <span>Letter is not in the word</span>
          </p>
          <p className="key-item">
            <span className="key-box-yellow"></span>
            <span>Letter is in the wrong position</span>
          </p>
          <p className="key-item">
            <span className="key-box-green"></span>
            <span>Letter is correct!</span>
          </p>
        </div>
      </div>
      <div className="wordle-rules"></div>
      <div className="wordle-container">
        <div className="word-tile-div-container">
          <div className="word-tile-div">
            <div className="word-tile" ref={letter1Ref}>
              {pastGuesses[0] && pastGuesses[0].length
                ? pastGuesses[0][0]
                : guessNumber === 1 && guessedWord[0]}
            </div>
            <div className="word-tile" ref={letter2Ref}>
              {pastGuesses[0] && pastGuesses[0].length
                ? pastGuesses[0][1]
                : guessNumber === 1 && guessedWord[1]}
            </div>
            <div className="word-tile" ref={letter3Ref}>
              {pastGuesses[0] && pastGuesses[0].length
                ? pastGuesses[0][2]
                : guessNumber === 1 && guessedWord[2]}
            </div>
            <div className="word-tile" ref={letter4Ref}>
              {pastGuesses[0] && pastGuesses[0].length
                ? pastGuesses[0][3]
                : guessNumber === 1 && guessedWord[3]}
            </div>
            <div className="word-tile" ref={letter5Ref}>
              {pastGuesses[0] && pastGuesses[0].length
                ? pastGuesses[0][4]
                : guessNumber === 1 && guessedWord[4]}
            </div>
            <div className="word-tile" ref={letter6Ref}>
              {pastGuesses[0] && pastGuesses[0].length
                ? pastGuesses[0][5]
                : guessNumber === 1 && guessedWord[5]}
            </div>
          </div>
          <div className="word-tile-div">
            <div className="word-tile" ref={letter7Ref}>
              {pastGuesses[1] && pastGuesses[1].length
                ? pastGuesses[1][0]
                : guessNumber === 2 && guessedWord[0]}
            </div>
            <div className="word-tile" ref={letter8Ref}>
              {pastGuesses[1] && pastGuesses[1].length
                ? pastGuesses[1][1]
                : guessNumber === 2 && guessedWord[1]}
            </div>
            <div className="word-tile" ref={letter9Ref}>
              {pastGuesses[1] && pastGuesses[1].length
                ? pastGuesses[1][2]
                : guessNumber === 2 && guessedWord[2]}
            </div>
            <div className="word-tile" ref={letter10Ref}>
              {pastGuesses[1] && pastGuesses[1].length
                ? pastGuesses[1][3]
                : guessNumber === 2 && guessedWord[3]}
            </div>
            <div className="word-tile" ref={letter11Ref}>
              {pastGuesses[1] && pastGuesses[1].length
                ? pastGuesses[1][4]
                : guessNumber === 2 && guessedWord[4]}
            </div>
            <div className="word-tile" ref={letter12Ref}>
              {pastGuesses[1] && pastGuesses[1].length
                ? pastGuesses[1][5]
                : guessNumber === 2 && guessedWord[5]}
            </div>
          </div>
          <div className="word-tile-div">
            <div className="word-tile" ref={letter13Ref}>
              {pastGuesses[2] && pastGuesses[2].length
                ? pastGuesses[2][0]
                : guessNumber === 3 && guessedWord[0]}
            </div>
            <div className="word-tile" ref={letter14Ref}>
              {pastGuesses[2] && pastGuesses[2].length
                ? pastGuesses[2][1]
                : guessNumber === 3 && guessedWord[1]}
            </div>
            <div className="word-tile" ref={letter15Ref}>
              {pastGuesses[2] && pastGuesses[2].length
                ? pastGuesses[2][2]
                : guessNumber === 3 && guessedWord[2]}
            </div>
            <div className="word-tile" ref={letter16Ref}>
              {pastGuesses[2] && pastGuesses[2].length
                ? pastGuesses[2][3]
                : guessNumber === 3 && guessedWord[3]}
            </div>
            <div className="word-tile" ref={letter17Ref}>
              {pastGuesses[2] && pastGuesses[2].length
                ? pastGuesses[2][4]
                : guessNumber === 3 && guessedWord[4]}
            </div>
            <div className="word-tile" ref={letter18Ref}>
              {pastGuesses[2] && pastGuesses[2].length
                ? pastGuesses[2][5]
                : guessNumber === 3 && guessedWord[5]}
            </div>
          </div>
          <div className="word-tile-div">
            <div className="word-tile" ref={letter19Ref}>
              {pastGuesses[3] && pastGuesses[3].length
                ? pastGuesses[3][0]
                : guessNumber === 4 && guessedWord[0]}
            </div>
            <div className="word-tile" ref={letter20Ref}>
              {pastGuesses[3] && pastGuesses[3].length
                ? pastGuesses[3][1]
                : guessNumber === 4 && guessedWord[1]}
            </div>
            <div className="word-tile" ref={letter21Ref}>
              {pastGuesses[3] && pastGuesses[3].length
                ? pastGuesses[3][2]
                : guessNumber === 4 && guessedWord[2]}
            </div>
            <div className="word-tile" ref={letter22Ref}>
              {pastGuesses[3] && pastGuesses[3].length
                ? pastGuesses[3][3]
                : guessNumber === 4 && guessedWord[3]}
            </div>
            <div className="word-tile" ref={letter23Ref}>
              {pastGuesses[3] && pastGuesses[3].length
                ? pastGuesses[3][4]
                : guessNumber === 4 && guessedWord[4]}
            </div>
            <div className="word-tile" ref={letter24Ref}>
              {pastGuesses[3] && pastGuesses[3].length
                ? pastGuesses[3][5]
                : guessNumber === 4 && guessedWord[5]}
            </div>
          </div>
          <div className="word-tile-div">
            <div className="word-tile" ref={letter25Ref}>
              {pastGuesses[4] && pastGuesses[4].length
                ? pastGuesses[4][0]
                : guessNumber === 5 && guessedWord[0]}
            </div>
            <div className="word-tile" ref={letter26Ref}>
              {pastGuesses[4] && pastGuesses[4].length
                ? pastGuesses[4][1]
                : guessNumber === 5 && guessedWord[1]}
            </div>
            <div className="word-tile" ref={letter27Ref}>
              {pastGuesses[4] && pastGuesses[4].length
                ? pastGuesses[4][2]
                : guessNumber === 5 && guessedWord[2]}
            </div>
            <div className="word-tile" ref={letter28Ref}>
              {pastGuesses[4] && pastGuesses[4].length
                ? pastGuesses[4][3]
                : guessNumber === 5 && guessedWord[3]}
            </div>
            <div className="word-tile" ref={letter29Ref}>
              {pastGuesses[4] && pastGuesses[4].length
                ? pastGuesses[4][4]
                : guessNumber === 5 && guessedWord[4]}
            </div>
            <div className="word-tile" ref={letter30Ref}>
              {pastGuesses[4] && pastGuesses[4].length
                ? pastGuesses[4][5]
                : guessNumber === 5 && guessedWord[5]}
            </div>
          </div>
          <div className="word-tile-div">
            <div className="word-tile" ref={letter31Ref}>
              {pastGuesses[5] && pastGuesses[5].length
                ? pastGuesses[5][0]
                : guessNumber === 6 && guessedWord[0]}
            </div>
            <div className="word-tile" ref={letter32Ref}>
              {pastGuesses[5] && pastGuesses[5].length
                ? pastGuesses[5][1]
                : guessNumber === 6 && guessedWord[1]}
            </div>
            <div className="word-tile" ref={letter33Ref}>
              {pastGuesses[5] && pastGuesses[5].length
                ? pastGuesses[5][2]
                : guessNumber === 6 && guessedWord[2]}
            </div>
            <div className="word-tile" ref={letter34Ref}>
              {pastGuesses[5] && pastGuesses[5].length
                ? pastGuesses[5][3]
                : guessNumber === 6 && guessedWord[3]}
            </div>
            <div className="word-tile" ref={letter35Ref}>
              {pastGuesses[5] && pastGuesses[5].length
                ? pastGuesses[5][4]
                : guessNumber === 6 && guessedWord[4]}
            </div>
            <div className="word-tile" ref={letter36Ref}>
              {pastGuesses[5] && pastGuesses[5].length
                ? pastGuesses[5][5]
                : guessNumber === 6 && guessedWord[5]}
            </div>
          </div>
        </div>
        {gameIsFinished && allGreen ? (
          <div className="wordle-end-msg">
            <div className="wordle-correct-msg">CORRECT</div>
            <button className="wordle-enter-del" onClick={refreshPage}>
              Try Another Word
            </button>
            <Link className="wordle-enter-del" to={"../puzzles-and-games"}>
              Back to puzzles
            </Link>
          </div>
        ) : gameIsFinished && !allGreen ? (
          <div className="wordle-end-msg">
            <div className="wordle-correct-msg">The word was {word}</div>
            <button className="wordle-enter-del" onClick={refreshPage}>
              Try Another Word
            </button>
            <Link className="wordle-enter-del" to={"../puzzles-and-games"}>
              Back to puzzles
            </Link>
          </div>
        ) : (
          <>
            <div className="wordle-keyboard">{keyboardMap}</div>
            <div className="wordle-btns">
              <button className="wordle-enter-del" onClick={deleteLetter}>
                DEL
              </button>
              <button className="wordle-enter-del" onClick={checkAnswer}>
                ENTER
              </button>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Wordle;
