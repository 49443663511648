import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { twelveDaysOfChristmas } from "../../LearnSpecificCategoryDetails";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnTwelveDaysOfChristmas() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={twelveDaysOfChristmas.mainCategory}
          subCategory={twelveDaysOfChristmas.subCategory}
          iconLrg={twelveDaysOfChristmas.iconLrg}
          iconSml={twelveDaysOfChristmas.iconSml}
          bigPeep={twelveDaysOfChristmas.bigPeep}
          intro={twelveDaysOfChristmas.intro}
          intro2={twelveDaysOfChristmas.intro2}
          intro3={twelveDaysOfChristmas.intro3}
          intro4={twelveDaysOfChristmas.intro4}
        />
        {/* <div className="container">
          Contents
          <ul>
            <li></li>
          </ul>
        </div> */}
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Day</th>
                <th>Gift</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Day">1</td>
                <td data-label="Gift">Partridge in a pear tree</td>
                <td data-label="Date">25th December</td>
              </tr>
              <tr>
                <td data-label="Day">2</td>
                <td data-label="Gift">Turtle doves</td>
                <td data-label="Date">26th December</td>
              </tr>
              <tr>
                <td data-label="Day">3</td>
                <td data-label="Gift">French hens</td>
                <td data-label="Date">27th December</td>
              </tr>
              <tr>
                <td data-label="Day">4</td>
                <td data-label="Gift">Calling birds</td>
                <td data-label="Date">28th December</td>
              </tr>
              <tr>
                <td data-label="Day">5</td>
                <td data-label="Gift">Gold(en) rings</td>
                <td data-label="Date">29th December</td>
              </tr>
              <tr>
                <td data-label="Day">6</td>
                <td data-label="Gift">Geese a-laying</td>
                <td data-label="Date">30th December</td>
              </tr>
              <tr>
                <td data-label="Day">7</td>
                <td data-label="Gift">Swans a-swimming</td>
                <td data-label="Date">31st December</td>
              </tr>
              <tr>
                <td data-label="Day">8</td>
                <td data-label="Gift">Maids a-milking</td>
                <td data-label="Date">1st January</td>
              </tr>
              <tr>
                <td data-label="Day">9</td>
                <td data-label="Gift">Ladies dancing</td>
                <td data-label="Date">2nd January</td>
              </tr>
              <tr>
                <td data-label="Day">10</td>
                <td data-label="Gift">Lords a-leaping</td>
                <td data-label="Date">3rd January</td>
              </tr>
              <tr>
                <td data-label="Day">11</td>
                <td data-label="Gift">Pipers piping</td>
                <td data-label="Date">4th January</td>
              </tr>
              <tr>
                <td data-label="Day">12</td>
                <td data-label="Gift">Drummers drumming</td>
                <td data-label="Date">5th January</td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={twelveDaysOfChristmas.importantFacts}
          classicQuestions={twelveDaysOfChristmas.classicQuestions}
          exampleQuestions={twelveDaysOfChristmas.exampleQuestions}
          thoughtsTipsIdeas={twelveDaysOfChristmas.thoughtsTipsAndIdeas}
          smallPeep1={twelveDaysOfChristmas.smallPeep1}
          smallPeep2={twelveDaysOfChristmas.smallPeep2}
          disclaimer={twelveDaysOfChristmas.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnTwelveDaysOfChristmas;
