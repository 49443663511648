import React from "react";

function Word(props) {
  return (
    <div className="each-word">
      {props.word.split(" ").map((eachWord, i) => {
        return (
          <div className="word" key={i}>
            {eachWord.split("").map((letter, i) => {
              return (
                <span
                  //if the character is not going to be a letter add the "space" class so the character is not underlined
                  className={
                    letter === " " ||
                    letter === "." ||
                    letter === "," ||
                    letter === "-" ||
                    letter === "'"
                      ? "space"
                      : "letter"
                  }
                  key={i}
                >
                  {letter === "." ||
                  letter === "," ||
                  letter === "'" ||
                  letter === "-"
                    ? letter
                    : props.correctLetters.includes(letter)
                    ? letter
                    : ""}
                </span>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Word;
