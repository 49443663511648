import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import LearnTopFiveThreeCols from "./LearnTopFiveThreeCols";
import LearnTopFiveThreeColsHeading from "./LearnTopFiveThreeColsHeading";
import LearnTopFiveFourCols from "./LearnTopFiveFourCols";
import LearnTopFiveHeadingFourCols from "./LearnTopFiveHeadingFourCols";
import { topFivesGeography } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive
      key={topFive.key}
      col1={topFive.col1}
      col2={topFive.col2}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}
function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col2country={topFive.col2country}
      col3country={topFive.col3country}
      col4={topFive.col4}
    />
  );
}
function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveFourCols(topFive) {
  return (
    <LearnTopFiveFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
      col5={topFive.col5}
    />
  );
}
function ArrangeTopFiveFourColsHeading(topFive) {
  return (
    <LearnTopFiveHeadingFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
    />
  );
}

function LearnGeographyRecords() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Geography Records</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The longest rivers, tallest mountains and other records in
                geography are often the subject of quiz questions, so they make
                a great addition to your general knowledge.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small1.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Longest Rivers</h2>
          <div className="football-records">
            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 by Continent</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topRiversHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topRivers.map(ArrangeTopFiveFourCols)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Top 5 in the World</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topRiversWorldHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topRiversWorld.map(ArrangeTopFive)}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Tallest Mountains</h2>
          <div className="football-records">
            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 Antarctica</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topMountains.topMountainsAntarctica.map(
                ArrangeTopFiveFourCols
              )}
            </div>
            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 Africa</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topMountains.topMountainsAfrica.map(
                ArrangeTopFiveFourCols
              )}
            </div>

            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 Asia</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topMountains.topMountainsAsia.map(
                ArrangeTopFiveFourCols
              )}
            </div>

            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 Australia</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topMountains.topMountainsAustralia.map(
                ArrangeTopFiveFourCols
              )}
            </div>

            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 Europe</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topMountains.topMountainsEurope.map(
                ArrangeTopFiveFourCols
              )}
            </div>

            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 North America</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topMountains.topMountainsNorthAmerica.map(
                ArrangeTopFiveFourCols
              )}
            </div>

            <div className="geography-record-wide">
              <h2 className="football-record-title">Top 3 South America</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsHeadings.map(
                ArrangeTopFiveFourColsHeading
              )}
              {topFivesGeography.topMountains.topMountainsSouthAmerica.map(
                ArrangeTopFiveFourCols
              )}
            </div>

            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 in the World (all in Asia)
              </h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topMountains.topMountainsWorldHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topMountains.topMountainsWorld.map(
                ArrangeTopFive
              )}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Largest Lakes</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 in the world by surface area
              </h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topLakes.topLakesWorldHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesGeography.topLakes.topLakesWorld.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Top Cities</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Densley Populated
              </h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topCities.cityPopulatedHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesGeography.topCities.densleyPopulated.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Largest Populations
              </h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topCities.cityPopulationHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesGeography.topCities.populationSize.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Largest Urban Areas by Area
              </h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topCities.largestAreaHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {topFivesGeography.topCities.largestArea.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">American States</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">Last 5 Added</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topCities.newestStatesHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topCities.newestStates.map(ArrangeTopFive)}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnGeographyRecords;
