import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { dickens } from "../../LearnSpecificCategoryDetailsFour";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnArtAndLiteratureDickens() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={dickens.mainCategory}
          subCategory={dickens.subCategory}
          iconLrg={dickens.iconLrg}
          iconSml={dickens.iconSml}
          bigPeep={dickens.bigPeep}
          intro={dickens.intro}
        />
        <div className="container">
          <h2 className="category-subtitle">The Novels of Charles Dickens</h2>
          <p className="body-text">
            A Christmas Carol has been included with the novels below, as
            although it is not technically a novel as it is too short, it is one
            of Dickens' best known stories.
          </p>
          <div className="line-subtitle"></div>

          <h3 className="subtitle-for-list">The Pickwick Papers (1836)</h3>
          <p className="body-text">
            Also known as{" "}
            <span className="italics-blue">
              Posthumous Papers of the Pickwick Club
            </span>
            , it is the story of a group of friends, led by Mr. Pickwick, who
            travel around England observing the people and customs of the
            country. Along the way, they have many adventures, the story is full
            of humour and satire.
            <br />
            Main characters include Samuel Pickwick, Nathaniel Winkle, Augustus
            Snodgrass, Tracy Tupman, Sam Weller, Tony Weller and Alfred Jingle.
          </p>
          <h3 className="subtitle-for-list">Oliver Twist (1837)</h3>
          <p className="body-text">
            Also known as the{" "}
            <span className="italics-blue">Parish Boy's Progress</span>, Oliver
            Twist is a young orphan who is sold into a life of crime and poverty
            in London. He befriends a group of young boys who are also trying to
            survive on the streets. Together they get involved in a series of
            adventures, including pickpocketing, running away from the law, and
            living in a workhouse.
            <br />
            Main characters include Oliver Twist, Mr Brownlow, Mr Bumble, Fagin,
            Bill Sykes, The Artful Dodger and Nancy.
          </p>
          <h3 className="subtitle-for-list">Nicholas Nickleby (1838)</h3>
          <p className="body-text">
            Also known as{" "}
            <span className="italics-blue">
              The Life and Adventures of Nicholas Nickleby
            </span>
            , it is the story of Nicholas Nickleby, a young man who is forced to
            find work to support his mother and sister after his father dies.
            The novel follows Nicholas as he works as a low-paid schoolmaster
            for a boss who mistreats the children in his care.
            <br />
            The characters in the novel are numerous, some main characters
            include the Nickleby family, Madeline Bray, Miss La Creevy, the
            Crummles family, Newman Noggs, Smike and Wackford Squeers.
          </p>
          <h3 className="subtitle-for-list">The Old Curiosity Shop (1840)</h3>
          <p className="body-text">
            One of two novels (the other being Barnaby Rudge) published in
            serialized form in the magazine{" "}
            <span className="italics-blue">Master Humphrey's Clock</span>, the
            story follows the life of Nell, a young girl who lives with her
            grandfather in his shop of curiosities. After her grandfather falls
            into debt, they are forced to leave the shop and go on a journey to
            London to find a new home.
            <br />
            Main characters include Nell Trent, Nell's Grandfather, Christopher
            "Kit" Nubbles, Daniel Quilp, Richard "Dick" Swiveller and The Single
            Gentleman.
          </p>
          <h3 className="subtitle-for-list">Barnaby Rudge (1841)</h3>
          <p className="body-text">
            One of two novels (the other being The Old Curiosity Shop) published
            in serialized form in the magazine{" "}
            <span className="italics-blue">Master Humphrey's Clock</span>. Also
            known as{" "}
            <span className="italics-blue">
              Barnaby Rudge: A Tale of the Riots of Eighty
            </span>
            , it is set in London during the Gordon Riots of 1780, a period of
            civil unrest. Barnaby Rudge is a simple-minded man who is caught up
            in the riots in a case of mistaken identity.
            <br />
            Main characters include The Rudges, Tom Cobb, The Chesters, Solomon
            Daisy, Ned Dennis, Grip,The Haredales, Hugh, Lord George Gordon, Mr
            Langdale, 'Long' Phil Parkes, Stagg, Simon Tappertit, The Vardens
            and The Willets.
          </p>
          <h3 className="subtitle-for-list">A Christmas Carol (1843)</h3>
          <p className="body-text">
            Also known as{" "}
            <span className="italics-blue">
              A Christmas Carol. In Prose. Being a Ghost Story of Christmas
            </span>
            , it tells the story of Ebenezer Scrooge, a bitter old man who is
            visited by the ghosts of Christmas past, present, and future, and
            shown the error of his ways. The novel has become a holiday classic,
            and has been adapted for film and stage many times.
            <br />
            Main characters include Ebenezer Scrooge, Bob Cratchit, Mrs Cratchit
            and Martha Cratchit, Fezziwig, Fred, Ghosts of christmas past,
            christmas present, and christmas yet to come, Jacob Marley and Tiny
            Tim.
          </p>
          <h3 className="subtitle-for-list">Martin Chuzzlewit (1843)</h3>
          <p className="body-text">
            Also known as{" "}
            <span className="italics-blue">
              The Life and Adventures of Martin Chuzzlewit
            </span>
            , it is set in both England and America and follows the adventures
            of the titular character, Martin Chuzzlewit, whose grandfather 'Old
            Martin Chuzzlewit' tries to escape his greedy relatives who want him
            to die so they can inherit his money.
            <br />
            Main characters include the Chuzzlewit family (Charity, Mercy, Old
            Martin, Young Martin, Anthony, George & Jonas), Bailey, Mr Bevan,
            Jefferson Brick, Mr Chuffey, Sarch Gamp, Mary Graham, Tigg Montague,
            Mr Nadgett, Seth Pecksniff, Tom & Ruth Pinch, Mr & Mrs Spottletoe,
            Mark Tapley, Mrs Todgers and John Westlock.
          </p>
          <h3 className="subtitle-for-list">Dombey and Son (1846)</h3>
          <p className="body-text">
            Also known as{" "}
            <span className="italics-blue">
              Dealings with the Firm of Dombey and Son: Wholesale, Retail and
              for Exportation
            </span>
            , is the story of Paul Dombey, a wealthy and mean man, who is the
            owner of a shipping company in Victorian England. He only cares
            about his son because he wants him to take over the business, but he
            is a sickly child. He also has a daughter who he resents and
            neglects.
            <br />
            Main characters include Mrs. Blockitt, Harriet, James and John
            Carker, Louisa Chick, Captain Edward (Ned) Cuttle, The Dombeys
            (Edith (née Granger), Fanny, Florence,Paul, Paul Jr.), Walter Gay,
            Solomon Gills (Uncle Sol), Miss Susan Nipper, Mrs. Pipchin, Mrs.
            Skewton (Cleopatra), Mrs. Skewton, Miss Lucretia Tox
          </p>
          <h3 className="subtitle-for-list">David Copperfield (1849)</h3>
          <p className="body-text">
            Also known by the very long title{" "}
            <span className="italics-blue">
              The Personal History, Adventures, Experience and Observation of
              David Copperfield the Younger of Blunderstone Rookery (Which He
              Never Meant to Publish on Any Account)
            </span>
            , the story of David Copperfield's life from his happy young
            childhood, then to sadness and mistreatment by his step-family and
            boarding school, and finally into adulthood.
            <br />
            Main characters include Richard Babley (Mr. Dick), Barkis, Clara
            Copperfield, David Copperfield, Mr. Creakle, Little Em'ly, Mrs.
            Grummidge, Uriah Heep, Littimer, Wilkins Micawber, Edward & Jane
            Murdstone, Clara, Daniel & Ham Peggotty, Dora Spenlow, James
            Steerforth, Dr. Strong, Tommy Traddles, Betsey Trotwood, Mr
            Wickfield & Agnes Wickfield.
          </p>
          <h3 className="subtitle-for-list">Bleak House (1852)</h3>
          <p className="body-text">
            The story of the Jarndyce family, and the lawsuit of Jarndyce and
            Jarndyce which will determine who will inherit the fortunes it
            details.
            <br />
            Main characters include Bayham Badger, Mrs. Badger, Miss Barbary,
            Inspector Bucket, Richard Carstone, Ada Clare, Lady Honoria Dedlock,
            Sir Leicester Dedlock, Dame Durden, Miss Flite, William Guppy,
            Captain Hawdon, Hortense, John Jarndyce, Caddy Jellyby, Krook, Nemo,
            Mrs. Rouncewell, George Rouncewell, Esther Summerson, Tulkinghorn,
            Allen Woodcourt
          </p>
          <h3 className="subtitle-for-list">Hard Times (1854)</h3>
          <p className="body-text">
            Also known as{" "}
            <span className="italics-blue">Hard Times: For These Times</span>,
            the story of the inhabitants of Coketown, a mid 19th-century
            industrial town, and the lives they lead based on decisions they
            make. The novel is a social commentary on industrialization and its
            dangers/affects.
            <br />
            Main characters include Bitzer, Stephen “Old Stephen” Blackpool,
            Josiah & Louisa Bounderby, The Gradgrinds (Adam Smith, Jane, Louisa,
            Malthus, Thomas & Tom “the whelp”), James Harthouse, Cecilia “Sissy”
            & Signor Jupe, Mr McChoakumchild, Mrs. Pegler, Slackbridge, Mr.
            Sleary, Mrs. Sparsit.
          </p>
          <h3 className="subtitle-for-list">Little Dorrit (1855)</h3>
          <p className="body-text">
            The story of Amy 'little' Dorrit, who is born at and lives in a
            debters prison with her Mother, Father and sister (due to her
            fathers debts). It follows the change in circumstances for the
            Dorrits and how their lives change.
            <br />
            Main characters include Amy Dorrit (Little Dorrit), Mr. Tite
            Barnacle, Harriet Beadle, Blandois, Mr. Casby, John Baptist
            Cavalletto, John Chivery, Arthur Clennam, Mrs. Clennam, The Dorrits
            (Edward “Tip”, Fanny, Frederick, William) Daniel Doyce, Flora
            Finching, The Flintwinchs (Affery, Ephraim, Jeremiah), Mrs. General,
            Henry & Mrs. Gowan, Maggy, Mr. & Minnie “Pet” Meagles, Mr. & Mrs.
            Merdle, Mr. Pancks, Mr. Plornish, Rigaud, Mr. Rugg, Edmund Sparkler,
            Tattycoram, Miss Wade.
          </p>
          <h3 className="subtitle-for-list">A Tale of Two Cities (1859)</h3>
          <p className="body-text">
            Set in 1775 London and Paris in the lead up to and during the French
            Revolution, it is the story of Dr Manette, who is held in Bastille
            prison for 18 years, upon his release he wishes to move to London to
            be with his daughter.
            <br />
            Main characters include Sydney Carton, Roger Cly, Jeremy (Jerry)
            Cruncher, Charles Darnay, Ernest Defarge, Therese Defarge, Jarvis
            Lorry, Doctor Alexandre Manette & his daughter Lucie, Miss Pross,
            The Seamstress, Marquis St. Evrémonde, C. J. Stryver, The Vengeance
          </p>
          <h3 className="subtitle-for-list">Great Expectations (1860)</h3>
          <p className="body-text">
            The novel follows the life of Pip, a blacksmith's apprentice, who
            comes into a large ammount of money from a mysterious benefactor
            <br />
            Main characters include Compeyson, Joe & Mrs. Joe (Georgiana Mary)
            Gargery, Estella Havisham, Miss Havisham, Mr. Jaggers, Abel
            Magwitch, Philip Pirrip (Pip), Herbert Pocket, John Wemmick.
          </p>
          <h3 className="subtitle-for-list">Our Mutual Friend (1864)</h3>
          <p className="body-text">
            When old Mr. Harmon dies, his large estate is left to his son John,
            on the condition he must have an arranged marriage to Bella Wilfer
            whom he has never met. John travels back from abroad and his body is
            found in the Thames, the story follows the lives of those who
            inherit the money and a case of false identity.
            <br />
            Main characters include Mr. Nicodemus “Noddy” & Henrietta Boffin,
            Fanny Cleaver aka Jenny Wren, Julius Hanford aka John Harmon aka
            John Rokesmith, Bradley Headstone, The Hexams (Charley, Jesse
            “Gaffer” & Lizzie), Mortimer Lightwood, Pleasant & Roger “Rogue”
            Riderhood, Eugene Wrayburn, Silas Wegg, Bella & Reginald “Rumty”
            Wilfer
          </p>
          <h3 className="subtitle-for-list">
            The Mystery of Edwin Drood (1870)
          </h3>
          <p className="body-text">
            This novel was unfinished at the time of Dicken' death, many
            continuations have been made by various writers in an attempt to
            conclude the novel.
            <br />
            The story takes place in Cloisterham and is about two orphans, Edwin
            Drood and Rosa Bud, who are supposed to marry each other when they
            grow up, a decision made by their late parents. However other people
            are more interested in Rosa than Edwin is, including Edwin's opium
            addicted uncle.
            <br />
            Main characters include Edwin Drood, Bazard, Rosa Bud, Septimus &
            Mrs Crisparkle, Deputy, 'Stoney' Durdles, Hiram Grewgious, John
            Jasper, Helena & Neville Landless, Thomas Sapsea
          </p>
          <div className="line-subtitle"></div>
        </div>

        <LearnSpecificCategoryBottom
          importantFacts={dickens.importantFacts}
          classicQuestions={dickens.classicQuestions}
          exampleQuestions={dickens.exampleQuestions}
          thoughtsTipsIdeas={dickens.thoughtsTipsAndIdeas}
          // smallPeep1={dickens.smallPeep1}
          smallPeep2={dickens.smallPeep2}
          disclaimer={dickens.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnArtAndLiteratureDickens;
