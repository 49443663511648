import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Alterego from "./Alterego";
import { ologys } from "../../LearnSpecificCategoryDetailsThree";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeAlterEgos(ology) {
  return <Alterego key={ology.id} original={ology.ology} new={ology.studyOf} />;
}

function LearnHobbyAndJobWords() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Hobby & Job Words</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Hobbies and jobs often have fancier official names than those we
                would use in day to day speech. They are sometimes used as
                questions in a general knowledge round of a quiz so if you
                remember the few listed here, you may bag yourself a point!
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small19.png")}
              alt="cartoon character with black hair and orange shirt"
            />
          </div>
          <div className="line-even-margin"></div>
          <div className="table-flex-4">
            <div className="table-flex-row-3">
              <p className="row-subject">Arctophile</p>
              <p className="row-explanation">
                A person who collects teddy bears
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Brolliologist</p>
              <p className="row-explanation">A person who collects umbrellas</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Campanologist</p>
              <p className="row-explanation">A Bellringer</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Cartophile</p>
              <p className="row-explanation">
                (Ambiguous) A person with an interest in maps, or a person who
                collects trading cards/cigarette cards etc
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Cruciverbalist</p>
              <p className="row-explanation">
                A person skilled at creating or solving crosswords
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Deltiologists</p>
              <p className="row-explanation">
                A person who studies or collects postcards
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Funambulist</p>
              <p className="row-explanation">Tightrope walker</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Fuselatelist</p>
              <p className="row-explanation">
                A person who collects phone cards
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Helixophile</p>
              <p className="row-explanation">
                A person who studies and collects corkscrews
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Herpetologist</p>
              <p className="row-explanation">
                A scientist that studies amphibians and reptiles
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Labeorphilists</p>
              <p className="row-explanation">Collectors of beer bottles</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Lepidopterist</p>
              <p className="row-explanation">
                A person who studies or collects butterflies and moths
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Oenophile</p>
              <p className="row-explanation">A connoiseur of wines</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Mycophile</p>
              <p className="row-explanation">
                A person who likes to gather wild mushrooms
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Numismatist</p>
              <p className="row-explanation">
                A person who studies or collects coins/medals
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Pannapictagraphist</p>
              <p className="row-explanation">A comic book collector</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Philatelist</p>
              <p className="row-explanation">
                A person who collects or studies stamps
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Phillumenist</p>
              <p className="row-explanation">
                A person who collects matchbox or matchbook labels
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Plangonologist</p>
              <p className="row-explanation">A person who collects dolls</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Pugilist</p>
              <p className="row-explanation">A Boxer</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Spelunker</p>
              <p className="row-explanation">
                A person who studies or expores caves (US & Canada)(aka cavers
                or potholers (UK & Ireland))
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Tegestologist</p>
              <p className="row-explanation">
                A person who collects coasters or beermats
              </p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Vexillologist</p>
              <p className="row-explanation">A person who studies flags</p>
            </div>
            <div className="table-flex-row-3">
              <p className="row-subject">Velologist</p>
              <p className="row-explanation">
                A person who study or collect British tax discs
              </p>
            </div>
          </div>
        </div>
      </section>
      <AsideNav />
      <Footer />
    </div>
  );
}

export default LearnHobbyAndJobWords;
