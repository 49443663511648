import "./css/us-states.css";

function USState(props) {
  return (
    <div className="us-state-card">
      <p className="us-state-card-name">{props.stateName}</p>
      <img className="state-flag-img" src={props.stateFlag} />
      <p className="us-state-card-code">{props.stateCode}</p>
      <p>
        <span className="us-state-card-heading">capital:</span>{" "}
        {props.stateCapital}
      </p>
      <p>
        {" "}
        <span className="us-state-card-heading">most populous city:</span>{" "}
        {props.mostPopulousCity}
      </p>
    </div>
  );
}

export default USState;
