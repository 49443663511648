import "./css/us-states.css";

function EnglishCounty2(props) {
  return (
    // <div className="us-state-card-2">
    <div className="county-card-wrapper-2">
      <div className="county-card-inner">
        <p className="english-county-card-name">{props.countyName}</p>
        <div className="flag-and-arms">
          {props.countyFlag && (
            <img className="county-flag-img" src={props.countyFlag} />
          )}
          {props.countyArms && (
            <img className="county-arms-img" src={props.countyArms} />
          )}
        </div>
        <p className="english-county">
          <span className="us-state-card-heading">County Town:</span>{" "}
          {props.countyTown}
        </p>
      </div>
    </div>
  );
}

export default EnglishCounty2;
