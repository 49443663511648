import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import AsideNav from "./AsideNav";
import "../css/style.css";
import "../css/peeps.css";

function Practice() {
  return (
    <div className="hero push-footer">
      <Header />
      <AsideNav />
      <section className="drop-section-content">
        <h1 className="category-title">PRACTICE</h1>

        <div className="container-3-thirds">
          <div className="container-third">
            <h2 className="container-third-subtitle">Quickfire Questions</h2>
            <p className="container-third-text">
              Quickfire quiz questions from our database. <br />
              No input required - the cards simply flip to reveal the answer
              (pause if you need more time).
            </p>
            <Link to="/practice/quickfire-questions" className="card-button">
              Start Quickfire Questions
            </Link>
          </div>

          <div className="container-third">
            <h2 className="container-third-subtitle">Question Bank</h2>
            <p className="container-third-text">
              Select a category and amount of questions, and a list of questions
              will appear with their answers hidden until you click to reveal
              them.
            </p>
            <Link to="/practice/question-bank" className="card-button">
              Go To The Question Bank
            </Link>
          </div>
          <div className="container-third">
            <h2 className="container-third-subtitle">Flags Quiz</h2>
            <p className="container-third-text">
              Test your knowledge of the flags of the world, and learn the ones
              you don't know.
            </p>
            <Link to="/practice/flags-quiz" className="card-button">
              Start Flags Quiz
            </Link>
          </div>
          <div className="container-third">
            <h2 className="container-third-subtitle">Capital Cities Quiz</h2>
            <p className="container-third-text">
              How many capital cities can you remember? Try this quiz and find
              out.
            </p>
            <Link to="/practice/capitalcities-quiz" className="card-button">
              Start Capital Cities Quiz
            </Link>
          </div>
          <div className="container-third">
            <h2 className="container-third-subtitle">English Counties Quiz</h2>
            <p className="container-third-text">
              See if you can remember all 47 of the English ceremonial counties
              featured on the map of England, and find out a bit more about them
              too!
            </p>
            <Link to="/practice/english-counties-quiz" className="card-button">
              Start English Counties Quiz
            </Link>
          </div>
          <div className="container-third">
            <h2 className="container-third-subtitle">US States Quiz</h2>
            <p className="container-third-text">
              See if you can remember all 50 US states, and find out a bit more
              about them too!
            </p>
            <Link to="/practice/us-states-quiz" className="card-button">
              Start US States Quiz
            </Link>
          </div>
          <div className="container-third">
            <h2 className="container-third-subtitle">African Countries Quiz</h2>
            <p className="container-third-text">
              See if you can remember all 49 mainland African countries and its
              largest island country which are featured on the map, and find out
              a bit more about them too!
            </p>
            <Link to="/practice/african-countries-quiz" className="card-button">
              Start African Countries Quiz
            </Link>
          </div>
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-standing5.png")}
            alt="cartoon character standing with hand on hip"
            className="peep-learning-right-wider"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Practice;
