import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Alterego from "./Alterego";
import { formerPlaceNames } from "../../LearnSpecificCategoryDetailsFive";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeAlterEgos(fpn) {
  return (
    <Alterego
      key={fpn.id}
      original={fpn.now}
      new={fpn.previous}
      other={fpn.other}
    />
  );
}

function LearnFormerPlaceNames() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Former Place Names</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The names of places, countries and cities sometimes change for
                various reasons. These could be to reflect their native people's
                culture or language, or to celebrate independence, amongst other
                reasons.
                <br />
                There will sometimes be a question in a quiz such as 'What
                country was formally known as ...' so learning these current and
                former place names could mean an extra point or two for you and
                your team.
                <br />
                The current name is written first in white, the previously used
                name is below it in <span className="orange-text">orange</span>,
                and any other names used between is in{" "}
                <span className="retired">blue</span> beneath them.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small16.png")}
              alt="cartoon character with glasses and afro hair"
            />
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Countries</h2>
          <div className="alter-ego-list-container">
            {formerPlaceNames.countries.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Cities</h2>
          <div className="alter-ego-list-container">
            {formerPlaceNames.cities.map(ArrangeAlterEgos)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnFormerPlaceNames;
