import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import AsideNav from "../AsideNav";
import AfricanCountry2 from "../../AfricanCountry2";
import { africanCountries } from "../../LearnSpecificCategoryDetailsFive";

function arrangeAfricanCountries(ac) {
  return (
    <AfricanCountry2
      key={ac.id}
      countryName={ac.countryName}
      capitalCity={ac.capitalCity}
      countryFlag={ac.countryFlag}
      countryFlag2={ac.countryFlag2}
    />
  );
}

function LearnAfricanCountries() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">African Countries</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                All 49 mainland African countries, plus the island country of
                Madagascar, along with a little information about them. See if
                you can remember them by testing yourself with the African
                Countries Quiz.
              </p>

              <Link to="../practice/african-countries-quiz" className="next">
                Go to African Countries quiz
              </Link>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small22.png")}
              alt="cartoon character with black tshirt and orange hairband"
            />
          </div>
        </div>
        <div className="us-state-outer-container">
          <div className="state-cards-container">
            {africanCountries.map(arrangeAfricanCountries)}
          </div>
        </div>

        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnAfricanCountries;
