import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import HeaderApp from "./HeaderApp";
import "../css/style.css";
import "../css/flashcard.css";
import "../css/flag.css";
import "../css/wordle.css";

function WordleMenu() {
  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section className="drop-section-content">
        <div className="select-difficulty-flex">
          <div>
            <h2 className="learn-flags-subtitle">Choose difficulty:</h2>
            <div className="wordle-difficulties-links">
              <Link
                className="easy-btn"
                to="../puzzles-and-games/five-letter-word-guess"
              >
                5 Letters
              </Link>
              <Link
                className="medium-btn"
                to="../puzzles-and-games/six-letter-word-guess"
              >
                6 Letters
              </Link>
              <Link
                className="ridiculous-btn"
                to="../puzzles-and-games/seven-letter-word-guess"
              >
                7 Letters
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default WordleMenu;
