import react from "react";
import "../../css/artists.css";

function Artist(props) {
  return (
    <div className="artist-container">
      <h2 className="margin-bottom-artist">{props.artist}</h2>
      <div className="small-flag">{props.flag}</div>

      <p className="margin-bottom-artist centre-text-artist">
        {props.lifespan}
      </p>
      <p className="margin-bottom-artist centre-text-artist">
        <span className="birthplace">Birthplace:</span>
        {props.birthplace}
      </p>

      <h3 className="margin-bottom-artist centre-text-orange-artist">
        {props.artMovement}
      </h3>
      <h4>Famous works include: </h4>
      <div className="art-list-container">
        {props.famousPieces.map((fp) => (
          <a
            href={fp.link}
            target="_blank"
            className="artworks-list-item"
            key={fp.piece}
          >
            {fp.piece}
          </a>
        ))}
      </div>
      <h4 className="margin-bottom-artist">Notes:</h4>
      <p>{props.notes}</p>
    </div>
  );
}

export default Artist;
