import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import "../css/style.css";
import { puzzleCategories } from "../categories";
import SubLearnChoice from "../SubLearnChoice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../css/peeps.css";
import FooterLearning from "./FooterLearning";

function SetLearnChoices(choice) {
  return (
    <SubLearnChoice key={choice.id} name={choice.name} path={choice.path} />
  );
}

function PuzzlesAndGames() {
  return (
    <div className="learning-page-hero push-footer">
      <Header />

      <section className="drop-section-content">
        <div className="menu-page-container">
          <div className="category-flex-container">
            <div className="sub-menu-page-title-div">
              <h1 className="menu-page-title">PUZZLES & GAMES</h1>
              <p className="menu-page-explanation">A bit of fun</p>
            </div>
            {puzzleCategories.map(SetLearnChoices)}
            <Link to="/home" className="answer-orange learn-go-back">
              <FontAwesomeIcon
                className="fontawesome-margin-right"
                icon={faArrowLeft}
              />
              GO BACK
            </Link>
          </div>
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-small15.png")}
            alt="cartoon character with spiky orange hair and game control pad"
            className="peep-learning"
          />
        </div>
      </section>
      {/* <img
        src={require("../images/thinker.png")}
        alt="The Thinker bronze statue by Auguste Rodin"
        className="thinker thinker1"
      />
      <img
        src={require("../images/thinker.png")}
        alt="The Thinker bronze statue by Auguste Rodin"
        className="thinker thinker2"
      /> */}

      <FooterLearning />
    </div>
  );
}

export default PuzzlesAndGames;
