const footballWorldCup = {
  mainCategory: "FIFA World Cup",
  bigPeep: require("./images/peep-big1.png"),
  intro:
    "The FIFA World Cup is an international competition which has taken place every four years since 1930 (apart from 1942 and 1946 because of World War II). Men's national football teams compete for the trophy, until 1970 this was the Jules Rimet trophy, now simply The FIFA World Cup Trophy.",
  // smallPeep1: require("./images/peep-small7.png"),
  smallPeep2: require("./images/peep-small3.png"),
  disclaimer:
    "Note * after a statement is a disclaimer that this information could change as new records are broken and may not have yet been updated. All facts are true as of 2022",
  importantFacts: [
    "The 2022 World Cup will be held in Qatar, with the final held in Lusail at the Lusail Iconic Stadium",
    "The 2026 World Cup tournament will be joint hosted by 3 countries: The USA, Canada and Mexico",
    "The leading goalscorer in World Cup finals history is Miroslav Klose of Germany with 16 goals*",
    "The most matches played by a player over multiple World Cup tournaments is 25, for Lothar Matthaus of Germany*",
    "Norman Whiteside of Northern Ireland is the youngest player to play at a World Cup Finals at 17 years and 41 days old*",
    "The most goals scored in one game at a World Cup was 5 by Oleg Salenko in 1994 for Russia*",
    "Geoff Hurst of England is the only player to score a hat-trick in a World Cup final (1966)*",
    "Lionel Messi was the first (and so far only) player to score in his teens, twenties and thirties at a World Cup",
  ],
  classicQuestions: [
    {
      id: "FWC-cQ1",
      question:
        "What was the name of the dog that found the stolen World Cup trophy in 1966?",
      answer: "Pickles - A mixed breed collie dog",
    },
    {
      id: "FWC-cQ2",
      question:
        "Other than Geoff Hurst, who scored for England in the 1966 4-2 final win vs West Germany?",
      answer: "Martin Peters",
    },
    {
      id: "FWC-cQ3",
      question:
        "Which player has scored the the most World cup goals, with 16?",
      answer: "Miroslav Klose",
    },
    {
      id: "FWC-cQ4",
      question: "Which player scored the infamous 'Hand of God' goal?",
      answer: "Diego Maradona",
    },
    {
      id: "FWC-cQ5",
      question: "Which country hosted the World Cup in 1966?",
      answer: "England",
    },
  ],
  exampleQuestions: [
    {
      id: "FWC-eQ1",
      question:
        "Which team has never won the World Cup but has lost in 3 finals?",
      answer: "The Netherlands",
    },
    {
      id: "FWC-eQ2",
      question: "Who were the first European team to win the World Cup?",
      answer: "Italy",
    },
    {
      id: "FWC-eQ3",
      question: "Which country has won the most World Cup finals?",
      answer: "Brazil",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "FWC-tTAI1",
      text: "The World Cup questions often have a focus on the country where the quiz is taking place, or the first and most recent tournaments. You can quickly learn some facts and figures that are commonly referred to by looking at the last 5s, and top 5s, 6s and 7s information on this page.",
    },
  ],
};
const footballEuros = {
  mainCategory: "UEFA European Championships (Euros)",
  bigPeep: require("./images/peep-standing1.png"),
  intro:
    "The UEFA European Championship (EUROS) is an international football competition which has taken place every four years since 1960 (the 2020 tournament was delayed until 2021 because of the Covid-19 pandemic). The trophy is competed for by the Men's national football teams from European countries who have qualified during matches in the intermediate years - and the host nation which automatically qualifies. The trophy is called the Henri Delaunay Cup, named after the man who had the idea of the European championship tounament.",
  // smallPeep1: require("./images/peep-small7.png"),
  smallPeep2: require("./images/peep-small7.png"),
  disclaimer:
    "Note * after a statement is a disclaimer that this information could change as new records are broken and may not have yet been updated. All facts are true as of 2022",
  importantFacts: [
    "Euro 2024 will be held in Germany, with the final taking place at the Olympiastadion, Berlin on 14th July",
    "The leading goalscorer in Euro championship finals history is Cristiano Ronaldo of Portugal with 14*",
    "The most matches played by a player over multiple Euros tournaments is 21, also for Cristiano Ronaldo*",
    "The most goals scored in one tournament at the Euros is 9 by Michel Platini of France in 1984, his only appearance at the Euros*",
    "Platini also scored the fastest Euros hat-trick in just 18 minutes!*",
    "German Berti Vogts is the only person to win the Euros as a manager and again as a player*",
    "Spain are the only country to have successfully defended the European Championship title - in 2012*",
    "Cristiano Ronaldo has appeared at the most Euros and is the only player to feature at the tournament 5 times*",
    "The highest ever scoring game of the tournaments was during Euro 1960, in the semi-final between France and Yugoslavia, it finished 4-5*",
  ],
  classicQuestions: [
    {
      id: "FEuros-cQ1",
      question:
        "In what year did the first UEFA European Championship tournamnet take place?",
      answer: "1960",
    },
    {
      id: "FEuros-cQ2",
      question:
        "Which player has scored the most goals in a single European Championship tournament?",
      answer: "Michel Platini",
    },
  ],
  exampleQuestions: [
    {
      id: "FEuros-eQ1",
      question:
        "Who has won the European Championships as a manager and as a player?",
      answer: "Berti Vogts",
    },
    {
      id: "FEuros-eQ2",
      question: "Which Englishman was the top scorer at Euro 1996?",
      answer: "Alan Shearer",
    },
    {
      id: "FEuros-eQ3",
      question: "Where will the 2024 tournament be held?",
      answer: "Germany",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "FEuros-tTAI1",
      text: "Like with The World Cup, the Euros questions often have a focus on the country where the quiz is taking place, the first and most recent tournaments, or significant events & records. You can quickly learn some facts and figures that are commonly referred to by looking at the last 5s & top 5s information on this page.",
    },
  ],
};

const wimbledon = {
  mainCategory: "Tennis",
  bigPeep: require("./images/peep-big13.png"),
  intro:
    "The Austrailian Open began in 1905 and takes place at Melbourne Park, in Melbourne, Victoria, Australia. It has been held at the stadium (formally called Flinders Park) since 1988, the competition begins in mid-January. The Australian open was played on grass courts until 1988, now it is played on hardcourt.",
  introt2:
    "The French Open (also known as Roland Garros) started in 1891 and is held at the Stade Roland Garros in Paris, France. It begins in late May and is played on clay surfaces.",
  introt3:
    "The Wimbledon tennis tournament was founded in 1877 and takes place at the All England Lawn Tennis and Croquet Club in London, UK. The outdoor grass courts competition begins at the end of June/beginning of July.",
  introt4:
    "The US Open began 1881 and since 1978 has taken place at the USTA Billie Jean King National Tennis Center in Flushing Meadows-Corona Park, Queens, New York City. It is played on hard court surface.",
  introt5:
    "Each of the Grand Slam Tournaments have main events of Men's Singles, Women's Singles, Men's Doubles, Women's Doubles and Mixed Doubles and also other exhibition, junior and invitation matches. They each occur annually and last for two weeks.",
  smallPeep2: require("./images/peep-small7.png"),
  // smallPeep1: require("./images/peep-small3.png"),
  // disclaimer:
  //   "Note * after a statement is a disclaimer that this information could change as new records are broken and may not have yet been updated. All facts are true as of 2022",
  importantFacts: [
    "As of 2022 there have been 142 US Open, 135 Wimbledon championships, 126 French Open and 110 Australian Open editions",
    "Wimbledon is now the only major tennis tournament played on grass",
    "The Grand Slam itinerary is as follows:",
    "Australian Open in mid January, played on blue coloured cushion acrylic hard courts",
    "French Open (also known as Roland Garros) from around late May to early June, played on a brick red coloured clay surface",
    "Wimbledon in June - July, played on grass courts",
    "US Open in August - September, played on a blue coloured acrylic hard court, the shade of blue is patented as 'US Open Blue'",
    "The French Open currently does not use an electronic line monitoring system as the ball leaves a mark in the dust of the clay surface so it is unnecesary",
  ],
  classicQuestions: [
    {
      id: "Wim-cQ1",
      question: "In what year did the first Wimbledon competition take place?",
      answer: "1877",
    },
    {
      id: "Wim-cQ2",
      question: "On what surface is the French Open played?",
      answer: "Clay",
    },
    {
      id: "Wim-cQ5",
      question:
        "What is the name of the electronic line monitoring system used at the Wimbledon Championships, the US Open and the Australian Open?",
      answer: "Hawk-Eye",
    },
    {
      id: "Wim-cQ6",
      question: "Who holds the most Wimbledon Ladies' Singles titles?",
      answer: "Martina Navratilova",
    },
    {
      id: "Wim-cQ7",
      question: "Which tennis player has a namesake 'hill' at Wimbledon?",
      answer: "Tim Henman",
    },
    {
      id: "Wim-cQ8",
      question: "On what surface are the games at Wimbledon played?",
      answer: "Grass",
    },
    {
      id: "Wim-cQ9",
      question:
        "What fruit is found at the top of the Wimbledon championships Gentlemen's Singles Trophy?",
      answer: "A Pineapple",
    },
  ],
  exampleQuestions: [
    {
      id: "Wim-eQ1",
      question: "Who won the Wimbledon Gentlemen's Singles title in 2022?",
      answer: "Novak Djokovic",
    },
    {
      id: "Wim-eQ2",
      question: "As of 2022 how many Wimbledon championships have taken place?",
      answer: "135",
    },
    {
      id: "Wim-eQ3 ",
      question:
        "In what year did the first French Open competition take place?",
      answer: "1891",
    },
    {
      id: "Wim-eQ4 ",
      question:
        "In what year did the first Australian Open competition take place?",
      answer: "1905",
    },
    {
      id: "Wim-eQ5 ",
      question: "In what year did the first US Open competition take place?",
      answer: "1881",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "Wim-tTAI1",
      text: "As Wimbledon has taken place for over 130 years, the main questions asked will likely be about the biggest winners, records broken and most recent competitions. Also it is advisable to learn the year the tournament began, and its location.",
    },
  ],
};

const jamesBond = {
  mainCategory: "James Bond",
  bigPeep: require("./images/peep-big2.png"),
  intro:
    "James Bond is a fictional character of the British Secret Intelligence Service, created by Ian Fleming. He is known by his designated agent code number 007. Bond is known for his impressive vehicles, guns and gadgets, and for being a masculine icon and serial womaniser.",
  smallPeep1: require("./images/peep-small6.png"),
  smallPeep2: require("./images/peep-small5.png"),
  disclaimer:
    "These films are split into 2 tables; the first for EON and the second non EON. EON is the production company that has produced the James Bond movies since 1962. It's actually an acronym that stands for 'Everything Or Nothing'.",
  importantFacts: [
    "Ian Fleming invented James Bond in 1953. His first novel was Casino Royale.",
    "You Only Live Twice was scripted by Roald Dahl.",
  ],
  classicQuestions: [
    {
      id: "JB-cQ1",
      question:
        "What was the name of James Bond author Ian Fleming's Jamaican estate?",
      answer: "GoldenEye",
    },
    {
      id: "JB-cQ2",
      question: "Who wrote the first novels that featured James Bond?",
      answer: "Ian Fleming",
    },
    {
      id: "JB-cQ3",
      question:
        "Which film series starring Mike Myers is spoof of the Bond genre?",
      answer: "Austin Powers",
    },
    {
      id: "JB-cQ4",
      question:
        "James Bond works with two people, each known by only one letter. Who are they?",
      answer: "M & Q",
    },
    {
      id: "JB-cQ5",
      question: "What does Spectre stand for in the James Bond films?",
      answer:
        "Special Executive for Counter-intelligence, Terrorism, Revenge and Extortion",
    },
  ],
  exampleQuestions: [
    {
      id: "JB-eQ1",
      question: "What was the first James Bond film?",
      answer: "Dr No",
    },
    {
      id: "JB-eQ2",
      question: "What was the first film starring Daniel Craig as Bond?",
      answer: "Casino Royale",
    },
    {
      id: "JB-eQ3",
      question:
        "Which James Bond film starring Sean Connery is considered non-canonical?",
      answer: "Never Say Never Again",
    },
    {
      id: "JB-eQ4",
      question: "Christopher Lee starred as which James Bond villain?",
      answer: "Francisco Scaramanga",
    },
    {
      id: "JB-eQ5",
      question:
        "Donald Pleasance, Telly Savalas and Christopher Waltz have all played which Bond villain?",
      answer: "Blofeld",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "JB-tTAI1",
      text: "Unless you are a James Bond superfan and have watched every movie multiple times, it seems the most efficient way to be able to answer these questions is to learn the actors who played Bond, their first and last films, and the actors who played the villains. The singer/songwriter is also important.",
    },
  ],
  // //Probably need a famous bond girls addition to this
};

const shakespeare = {
  mainCategory: "William Shakespeare",
  bigPeep: require("./images/peep-big5.png"),
  intro:
    // "Born in Stratford-upon-Avon, England in 1564, William Shakespeare is one of the world's greatest and best known writers and playwrights.",
    "Born in Stratford-upon-Avon, England in 1564, William Shakespeare is one of the most famous playwrights and poets in history. He wrote over 38 plays and 154 sonnets. His work has been translated into over 80 languages. He is considered one of the greatest writers of all time.",
  // smallPeep1: require("./images/peep-small30.png"),
  smallPeep2: require("./images/peep-small10.png"),
  importantFacts: [
    "Shakespeare was married to Anne Hathaway and they had 3 children",
    "William and Anne's children were called Susanna, Hamnet and Judith. Hamnet and Judith were twins, Hamnet died aged 11",
    "Shakespeare's plays have been translated into every major living language and are performed more often than those of any other playwright",
    "Shakespeare's major works include over 38 plays, 154 sonnets and 3 long narrative poems",
    "The Globe Theatre was was built in 1599 by Shakespeare's playing company and destroyed by fire on 29 June 1613",
    "Actors avoid saying Macbeth when in the theatre, as the play is said to be cursed, they tend to say 'The Scottish Play' instead",
  ],
  classicQuestions: [
    {
      id: "WS-cQ1",
      question: "Which of Shakespeare's plays is the shortest?",
      answer: "The Comedy of Errors",
    },
    {
      id: "WS-cQ2",
      question: "Where was Shakespeare born?",
      answer: "Stratford-upon-Avon",
    },
    {
      id: "WS-cQ3",
      question:
        "What is the name of the theatre in London associated with William Shakespeare?",
      answer: "The Globe",
    },
    {
      id: "WS-cQ4",
      question: "What was the name of Shakespeare's wife?",
      answer: "Anne Hathaway",
    },
    {
      id: "WS-cQ5",
      question: "Which of Shakespeare's plays is the longest?",
      answer: "Hamlet",
    },
    {
      id: "WS-cQ6",
      question:
        "Which planet in the Solar System has several moons named after Shakespearean characters?",
      answer: "Uranus",
    },
  ],
  exampleQuestions: [
    {
      id: "WS-eQ1",
      question:
        "'All the world's a stage, and all the men and women merely players' is a line from which Shakespeare play?",
      answer: "As You Like It",
    },
    {
      id: "WS-eQ2",
      question:
        "In which Shakespeare play is Tamora served a pie containing two of hers sons?",
      answer: "Titus Andronicus",
    },
    {
      id: "WS-eQ3",
      question:
        "Which film adaptation is a modernization of Shakespeare's The Taming of the Shrew?",
      answer: "10 Things I Hate About You",
    },
    {
      id: "WS-eQ4",
      question:
        "'Exit, pursued by a bear', is a famous stage direction in which play?",
      answer: "The Winter's Tale",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "WS-tTAI1",
      text: "The order of Shakespeare's plays is often disputed and is difficult to prove.",
    },
  ],
};

const twelveDaysOfChristmas = {
  mainCategory: "12 Days Of Christmas",

  bigPeep: require("./images/peep-big3.png"),
  intro:
    "This always comes up on a Christmas quiz. Here are the amounts and order of the gifts in the Christmas song the 12 Days of Christmas. Published around 1780.",
  intro2:
    "Here's a quick mnemonic I just made up (let us know via the contact us form if you come up with a better one!):",
  intro3:
    "Party Time For Christmas, Gold Gifts, Snow Men, Ladies Love Pretty Diamonds.",
  // intro4:
  //   "If anyone can think of a better one, please let me know. (I am going to have to try to create an AI powered mnemonic generator!).",
  // smallPeep1: require("./images/peep-small30.png"),
  smallPeep2: require("./images/peep-small29.png"),
  importantFacts: [
    "The 12 Days of Christmas begin on Christmas Day (December 25th) and ends on January 5th, the day before Epiphany (January 6th)",
    "Epiphany is also known as Twelfth Night, this is celebrated on January 5th or 6th",
    "In the Shakespeare play, Twelfth Night is the night before Epiphany (January 5th)",
    "The Church of England says Twelfth Night falls on January 5th (exactly 12 days from December 25th - including Christmas day itself). However, some people believe the Twelfth Night is January 6th, as they count the 12 days following Christmas Day",
  ],
  classicQuestions: [
    {
      id: "TDOC-cQ1",
      question:
        "How many gifts are given in total in the song The 12 Days of Christmas?",
      answer:
        "78 or 364 (See 'Thoughts, Tips and Ideas' at the bottom of the page)",
    },
  ],
  exampleQuestions: [
    {
      id: "TDOC-eQ1",
      question:
        "What gift is introduced on the eighth day, in the 12 Days of Christmas song?",
      answer: "Maids a-milking",
    },
    {
      id: "TDOC-eQ2",
      question:
        "How many different types of birds are included in the 12 Days of Christmas song?",
      answer:
        "6 (partridge, turtle dove, french hen, calling bird, goose and swan)",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "TDOC-TTAI1",
      text: "Try and think of a mnemonic for the gifts, or see if you can use our example in the introduction of this page.",
    },
    {
      id: "TDOC-TTAI2",
      text: "I hate the question 'How many gifts are given in total in the song the 12 Days of Christmas?', but I have been asked this before so I thought it should be included. The problem is with the ambiguity and wording of the question meaning that there are 2 possible answers.",
    },
    {
      id: "TDOC-TTAI3",
      text: "If (like you would expect) each gift is given on each day and only that day eg: on day 3 only 3 French hens are given. Then the answer is 1+2+3+4+5+6+7+8+9+10+11+12 which comes to 78..",
    },
    {
      id: "TDOC-TTAI4",
      text: "However, if the person asking the question wants the total where say on the third day 3 French hens, 2 Turtle doves and a Partridge in a pear tree are gifted (6 in total), then we either have to do some ridiculously quick maths or just remember 364 (it might help to remember it's the number of days in a year - 1). The maths would be 1+3+6+10+15+21+28+36+45+55+66+78.",
    },
    {
      id: "TDOC-TTAI5",
      text: "If you're interested in the maths, you add a triangle number to the full total each day or even more advanced, it is the 12th tetrahedral number.",
    },
  ],
};
const vitaminsAndMinerals = {
  mainCategory: "Vitamins & Minerals",
  bigPeep: require("./images/peep-big9.png"),
  intro:
    "Vitamins and minerals are used by the human body to function properly and stay healthy. Healthy people who have a balanced diet should get all the nutrients they need from the food they eat, but some people may need supplements. Please note: We are not medically trained, this information is taken from reputable online sources, such as the NHS, but you should always contact your Doctor or a trained medical professional for advice.",
  // smallPeep1: require("./images/peep-small30.png"),
  smallPeep2: require("./images/peep-small1.png"),
  importantFacts: [
    "Vitamin G is now classed as Vitamin B2, Riboflavin",
    "Fat-soluble vitamins - are stored in fatty tissue and the liver (for days or months)",
    "Water soluble vitamins - cannot be stored in the body so a regular supply is needed, they are expelled via urine.",
  ],
  classicQuestions: [
    {
      id: "VAM-cQ1",
      question:
        "A deficiency of which vitamin is the leading cause of blindness in children worldwide?",
      answer: "Vitamin A",
    },
    {
      id: "VAM-cQ2",
      question:
        "Which mineral is added to toothpaste to help keep teeth healthy and strong?",
      answer: "Fluoride",
    },
    {
      id: "VAM-cQ3",
      question:
        "A lack of iron in the body can lead to which health condition?",
      answer: "Anaemia",
    },
  ],
  exampleQuestions: [
    {
      id: "VAM-eQ1",
      question: "Which vitamin is also known as Ascorbic Acid?",
      answer: "Vitamin C",
    },
    {
      id: "VAM-eQ2",
      question:
        "What childhood health condition is caused by a lack of calcium and Vitamin D?",
      answer: "Rickets",
    },
    {
      id: "VAM-eQ3",
      question: "Which vitamin is important for blood clotting?",
      answer: "Vitamin K",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "VAM-TTAI1",
      text: "The information on this table is useful for everyday life, not just quizzing",
    },
  ],
};

const americanPresidents = {
  mainCategory: "American Presidents",
  bigPeep: require("./images/peep-big6.png"),
  intro:
    "The president is the head of state and government in the United States. To qualify to become president the candidate must be at least 35 years of age, be a natural born citizen of the United States, and must have lived in the United States for at least 14 years. Each presidential term lasts 4 years, and a president can only serve a maximum of two terms.",

  // smallPeep1: require("./images/peep-small30.png"),
  smallPeep2: require("./images/peep-small13.png"),
  importantFacts: [
    "Past presidents continue to be addressed as 'Mr President' after their presidential term is over",
    "Since 1852 the president has either been a member of the Democratic Party or Republican Party",
    "Before 1852 there were also presidents from the Federalist, Democratic-Republican, Whig and National Republican parties",
  ],
  classicQuestions: [
    {
      id: "aP-cQ1",
      question:
        "Name the four American presidents that appear on Mount Rushmore",
      answer:
        "George Washington, Thomas Jefferson, Theodore Roosevelt, Abraham Lincoln",
    },
    {
      id: "aP-cQ2",
      question: "Which president of the United States had the nickname Dubya?",
      answer:
        "George Walker Bush (From the W initial of his middle name, used to distinguish him from his father)",
    },
    {
      id: "aP-cQ3",
      question: "In which American state is Mount Rushmore?",
      answer: "South Dakota",
    },
    {
      id: "aP-cQ4",
      question: "How many US presidents have been assasinated?",
      answer: "4",
    },
  ],
  exampleQuestions: [
    {
      id: "aP-eQ1",
      question: "Who was the 22nd and 24th president of the U.S.A?",
      answer: "Grover Cleveland",
    },
    {
      id: "aP-eQ2",
      question:
        "Who was the first American president to be impeached (in 1868)?",
      answer: "Andrew Johnson",
    },
    {
      id: "aP-eQ3",
      question: "Who was the first president of the U.S.A?",
      answer: "George Washington",
    },
    {
      id: "aP-eQ4",
      question:
        "Which US president was in office for the longest amount of time? (12 years)",
      answer: "Franklin D. Roosevelt",
    },
    {
      id: "aP-eQ5",
      question:
        "Which US president was in office for the shortest amount of time? (31 days)",
      answer: "William Henry Harrison",
    },
    {
      id: "aP-eQ6",
      question:
        "Name the four presidents of the United States that have been assassinated",
      answer:
        "Abraham Lincoln (1865), James Garfield (1881), William Mckinley (1901), John F. Kennedy (1963) (All were shot)",
    },
    {
      id: "aP-eQ7",
      question:
        "Name the four presidents whose first name and surname have the same initial",
      answer: "Woodrow Wilson, Calvin Coolidge, Herbert Hoover, Ronald Reagan",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "aP-TTAI1",
      text: "It would be hard work to remember all of the presidents in order, it's more important to know who was the president when certain events took place",
    },
    {
      id: "aP-TTAI2",
      text: "eg. The president at the start of World War 2 (Franklin D. Roosevelt) or knowing which presidents served for the longest and shortest time (Franklin D. Roosevelt and William Henry Harrison respectively).",
    },
  ],
};
const theBible = {
  mainCategory: "The Bible",
  bigPeep: require("./images/peep-big4.png"),
  intro:
    "The Bible is a religious book in Christianity, Judaism and many other religions. It is made up of religious texts, rules and stories.",

  // smallPeep1: require("./images/peep-small4.png"),
  smallPeep2: require("./images/peep-small2.png"),
  importantFacts: [
    "The books of the Old Testament are believed to have been written between 2100-430BC, with The Book of Job believed to be the oldest, written between 350-790 years before Genesis",
    "The books of the New Testament are all believed to have been written between 49-95AD",
  ],
  exampleQuestions: [
    {
      id: "Bible-eQ1",
      question: "What is the 1st/2nd/3rd book of the Bible/Old Testament?",
      answer: "Genesis/Exodus/Leviticus",
    },
    {
      id: "Bible-eQ2",
      question: "What is the last book of the Old Testament?",
      answer: "Malachi",
    },
    {
      id: "Bible-eQ3",
      question: "What is the 1st book of the New Testament?",
      answer: "Matthew",
    },
    {
      id: "Bible-eQ4",
      question: "What is the last book of the Bible/New Testament?",
      answer: "Revelation",
    },
    {
      id: "Bible-eQ5",
      question: "How many books are in the Bible?",
      answer: "66",
    },
    {
      id: "Bible-eQ6",
      question: "How many books are in the Old Testament?",
      answer: "39",
    },
    {
      id: "Bible-eQ7",
      question: "How many Books are in the New Testament?",
      answer: "27",
    },
    {
      id: "Bible-eQ8",
      question: "In the Bible how many plagues did God send down on Egypt?",
      answer: "10",
    },
  ],
  classicQuestions: [
    {
      id: "Bible-cQ1",
      question: "Who was swallowed by a Whale in the Bible?",
      answer: "Jonah",
    },
    {
      id: "Bible-cQ2",
      question: "Which book has sold the most copies in history?",
      answer: "The Bible",
    },
    {
      id: "Bible-cQ3",
      question: "Who was the first man in the Bible?",
      answer: "Adam",
    },
    {
      id: "Bible-cQ4",
      question: "Who was the first woman in the Bible?",
      answer: "Eve",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "Bible-TTAI1",
      text: "Nobody expects you to remember the entire Bible for a pub quiz, (unless, maybe if it's a Church quiz!). The best way to remember the important things are:",
    },
    {
      id: "Bible-TTAI2",
      text: "For the first 5 books of the Bible/Old Testament: God Equals Light Not Darkness  (Genesis, Exodus, Leviticus, Numbers, Deuteronomy)",
    },
    {
      id: "Bible-TTAI3",
      text: "For the first 6 books of the New Testament: The rhyme - Matthew, Mark, Luke and John, Acts and Romans follow on (Matthew, Mark, Luke, John, Acts, Romans)",
    },

    {
      id: "Bible-TTAI5",
      text: "Sometimes you may need to know what happens in one of the books, here are the common ones:",
    },
    {
      id: "Bible-TTAI6",
      text: "Genesis - Creation, Adam & Eve, Cain & Abel, Noah's Ark (Genesis means generation/creation/origin)",
    },
    {
      id: "Bible-TTAI7",
      text: "Exodus - Moses, Egypt, Plagues, Leaving Egypt for Canaan, Parting of the Red Sea, The 10 Commandments (Exodus means a mass departure of people)",
    },
    {
      id: "Bible-TTAI8",
      text: "Leviticus - Living in harmony with God's rules",
    },
    {
      id: "Bible-TTAI9",
      text: "Jews still celebrate passover to this day which was part of the last plague, the killing of all first born sons in Egypt.The Angel of Death was sent to kill all firstborn sons, but leave alone those who had marked above their doorways with Lamb's blood",
    },
    {
      id: "Bible-TTAI11",
      text: "The 10 Commandments are also known as the Decalogue. They first appear in Book 2 (Exodus) and also in Book 5 (Deuteronomy). They were revealed to Moses at Mount Sinai and inscribed by the finger of God on 2 stone tablets. They were kept in the Ark of the Covenant",
    },
  ],
};
const periodicTable = {
  mainCategory: "The Periodic Table",
  bigPeep: require("./images/peep-big7.png"),
  intro:
    "The Periodic Table of Elements is a table which displays all of the known elements. It was created by Dmitri Mendeleyev and presented in 1869",

  // smallPeep1: require("./images/peep-small4.png"),
  smallPeep2: require("./images/peep-small21.png"),
  importantFacts: [
    "The Periodic Table is organised into periods (horizontally) and groups (vertically)",
    "The elements are grouped together with other elements into families that have similar properties and behaviours: alkali metals, alkaline earth metals, transition metals, basic metals, metalloids, nonmetals, halogens, noble gases, lanthanides and actinides",
    "Elements at opposite sides of the periodic table attract, eg. Na in group 1 reacts readily with Cl in group 17 to form common salt",
  ],
  exampleQuestions: [
    {
      id: "perTable-eQ1",
      question:
        "Which element in the Periodic Table is represented by the symbol Pb?",
      answer: "Lead",
    },
  ],
  classicQuestions: [
    {
      id: "perTable-cQ1",
      question: "Who was the inventor of the Periodic Table",
      answer: "Dmitri Mendeleyev",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "perTable-TTAI1",
      text: "Try not to make the common mistake of confusing Mendeleyev with Mendel (another scientist)",
    },
  ],
};
const inventors = {
  mainCategory: "Discoveries & Inventions",

  bigPeep: require("./images/peep-big11.png"),
  intro:
    "Important discoveries and inventions, and the dates they occured. Often you will have questions about these in quizzes, there are some discoveries and inventions that are more famous than others eg the telephone, electricity, the world wide web. A more fiendish quiz master will choose a less known invention/discovery!",

  smallPeep1: require("./images/peep-small17.png"),
  smallPeep2: require("./images/peep-small23.png"),
  // disclaimer:
  //   "Sometimes people are mistakenly credited with the discovery or invention of something, where this is a well known case it has been written alongside the actual inventor/discoverer. It may be worth learning both, as questions could be 'who invented ...', or 'who is often credited with inventing ...'",
  importantFacts: [
    "The discovery and use of Penicillin reduced the number of deaths and amputations of troops during World War II",
    "The invention of the Internal Combustion Engine brought about the Industrial Age, and allowed many other inventions to occur eg, aircraft",
    "The invention of the lightbulb allowed people to work, day or night, without the previous need of natural light to see by",
    "Penicillin was discovered by accident, Alexander Fleming noticed a Petri dish containing bacteria had become contaminated with mold, and where the mold was, the bacteria had died",
  ],
  exampleQuestions: [
    {
      id: "inventors-eQ1",
      question: "Who invented the telephone?",
      answer: "Alexander Graham Bell",
    },
    {
      id: "inventors-eQ2",
      question: "In what decade was the refrigerator invented?",
      answer: "1920s",
    },
    {
      id: "inventors-eQ3",
      question: "What was invented by Karl Benz in 1885?",
      answer: "The Automobile",
    },
    {
      id: "inventors-eQ4",
      question:
        "In which century did Johannes Gutenburg invent the Printing Press?",
      answer: "The 15th Century",
    },
  ],
  classicQuestions: [
    {
      id: "inventors-cQ1",
      question:
        "In which American state did the Wright brothers make their first sustained aircraft flight?",
      answer: "North Carolina",
    },
    {
      id: "inventors-cQ2",
      question:
        "What did Swiss engineer George de Mestral invent after seeing burrs clinging to his dog after a walk?",
      answer: "Velcro",
    },
    {
      id: "inventors-cQ3",
      question:
        "The inventor of dynamite set forward plans in his will to found which prize (named after him)?",
      answer: "Nobel Prize",
    },
    {
      id: "inventors-cQ4",
      question:
        "Levi Strauss is responsible for the invention of which clothing item?",
      answer: "Jeans",
    },
    {
      id: "inventors-cQ5",
      question: "Will Keith Kellogg invented which breakfast food in 1898?",
      answer: "Cornflakes",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "inventors-TTAI1",
      text: "It would be almost impossible to learn all of the inventions ever, the lists on this page are just some well known or important discoveries and inventions.",
    },
    {
      id: "inventors-TTAI12",
      text: "If it is a big anniversary of an invention/discovery, and the quiz likes to use topical questions, you could try and remember those just incase they are used, eg. 2027 will be 100 years since the invention of the electronic television by Philo Taylor Farnsworth.",
    },
  ],
};

export {
  footballWorldCup,
  footballEuros,
  wimbledon,
  jamesBond,
  shakespeare,
  twelveDaysOfChristmas,
  vitaminsAndMinerals,
  americanPresidents,
  theBible,
  periodicTable,
  inventors,
};
