const preDecimalMoney = [
  {
    id: "farthing",
    moneyName: "Farthing",
    moneyDetails: "Worth 1/4 of an old penny",
    moneyImg: require("./images/coinImages/farthing-front.png"),
    moneyImgBack: require("./images/coinImages/farthing-reverse.png"),
  },
  {
    id: "halfpenny",
    moneyName: "Halfpenny",
    moneyDetails:
      "Often pronounced ha'penny, as it's name suggests it was worth half an old penny, or 2 farthings",
    moneyImg: require("./images/coinImages/halfpenny-front.png"),
    moneyImgBack: require("./images/coinImages/halfpenny-reverse.png"),
  },
  {
    id: "penny",
    moneyName: "Penny",
    moneyDetails:
      "In the pre-decimalised system there were 240 pennies in a pound",
    moneyImg: require("./images/coinImages/penny-front.png"),
    moneyImgBack: require("./images/coinImages/penny-reverse.png"),
  },
  {
    id: "twopence",
    moneyName: "Twopence",
    moneyDetails:
      "Also known as a 'half-groat', it was worth 1/120th of a pound",
    moneyImg: require("./images/coinImages/twopence-front.png"),
    moneyImgBack: require("./images/coinImages/twopence-reverse.png"),
  },
  {
    id: "threepenny",
    moneyName: "Threepenny",
    moneyDetails:
      "Also known as a  'thruppence' or 'thruppenny bit' it was the firsth non-circular coin and was worth 3 pennies, 6 ha'pennies or 12 farthings",
    moneyImg: require("./images/coinImages/threepence-front.png"),
    moneyImgBack: require("./images/coinImages/threepence-reverse.png"),
  },
  {
    id: "sixpence",
    moneyName: "Sixpence",
    moneyDetails:
      "Also called a 'tanner', it was equal to half a shilling, it was traditionally the coin hidden in a christmas pudding for good luck to the person who found it",
    moneyImg: require("./images/coinImages/sixpence-front.png"),
    moneyImgBack: require("./images/coinImages/sixpence-reverse.png"),
  },
  {
    id: "shilling",
    moneyName: "Shilling",
    moneyDetails: "Worth 12 old pennies. 20 shillings made up a pound sterling",
    moneyImg: require("./images/coinImages/shilling-front.png"),
    moneyImgBack: require("./images/coinImages/shilling-reverse.png"),
  },
  {
    id: "florin",
    moneyName: "Florin",
    moneyDetails:
      "The florin was introducted in the Victorian era, it was worth 2 shillings and was 1/10 of a pre-decimal pound",
    moneyImg: require("./images/coinImages/florin-front.png"),
    moneyImgBack: require("./images/coinImages/florin-reverse.png"),
  },
  {
    id: "half-crown",
    moneyName: "Half Crown",
    moneyDetails:
      "Written as 2/6 expressed as '2 and 6', it was worth 2 shillings and 6 pence, a total of 30 old pence",
    moneyImg: require("./images/coinImages/half-crown-front.png"),
    moneyImgBack: require("./images/coinImages/half-crown-reverse.png"),
  },
  {
    id: "crown",
    moneyName: "Crown",
    moneyDetails:
      "A crown was worth 60 pre-decimal pence or 1/4 of a pound sterling. £5 pound coins made to specifications of a crown can be purchased from the royal mint to mark special anniversaries, birthdays or other celebration",
    moneyImg: require("./images/coinImages/crown-front.png"),
    moneyImgBack: require("./images/coinImages/crown-reverse.png"),
  },
  {
    id: "half-sovereign",
    moneyName: "Half Sovereign",
    moneyDetails:
      "Worth 10 shillings or 1/2 pound. Made from 22ct Gold so are not in ciculation due to their value compared to other coins. ",
    moneyImg: require("./images/coinImages/half-sovereign-front.png"),
    moneyImgBack: require("./images/coinImages/half-sovereign-reverse.png"),
  },
  {
    id: "sovereign",
    moneyName: "Sovereign",
    moneyDetails:
      "Worth 20 shillings or £1. Made from 22ct Gold so are not in ciculation due to their value compared to other coins. Soverigns can be purchased fom the Royal mint but are reserved for flagship royal or historical anniversaries. They are sometimes mounted in jewellery",
    moneyImg: require("./images/coinImages/sovereign-front.png"),
    moneyImgBack: require("./images/coinImages/sovereign-reverse.png"),
  },
];
const preDecimalNotes = [
  {
    id: "10/-",
    moneyName: "10/- (10 Shilllings) note",
    moneyDetails:
      "Known coloqually as '10 bob' or a '10 bob note'. It was the smallest money denomination in note form and was worth 1/2 an old pound.",
    moneyImg: require("./images/coinImages/ten-shillings-note.jpg"),
  },
  {
    id: "pound-note",
    moneyName: "£1 note",
    moneyDetails:
      "Worth 1 pound sterling, it was removed from circulation in 1988 and replaced by the £1 coin, due to inflation",
    moneyImg: require("./images/coinImages/pound-note.jpg"),
  },
];

const militaryRanks = {
  armyOfficerRanks: [
    {
      key: "Officer Cadet",
      col1: "Officer Cadet",
    },
    {
      key: "Second Lieutenant",
      col1: "Second Lieutenant",
    },
    {
      key: "Lieutenant",
      col1: "Lieutenant",
    },
    {
      key: "Captain",
      col1: "Captain",
    },
    {
      key: "Major",
      col1: "Major",
    },
    {
      key: "Lieutenant Colonal",
      col1: "Lieutenant Colonal",
    },
    {
      key: "Colonel",
      col1: "Colonel",
    },
    {
      key: "Brigadier (aka 1 star)",
      col1: "Brigadier (aka 1 star)",
    },
    {
      key: "Major General (aka 2 star)",
      col1: "Major General (aka 2 star)",
    },
    {
      key: "Lietenant General (aka 3 star)",
      col1: "Lietenant General (aka 3 star)",
    },
    {
      key: "General (aka 4 star)",
      col1: "General (aka 4 star)",
    },
    {
      key: "Field Marshal",
      col1: "Field Marshal",
    },
  ],
  armySoldierRanks: [
    {
      key: "Private",
      col1: "Private",
    },
    {
      key: "Lance Corporal",
      col1: "Lance Corporal",
    },
    {
      key: "Corporal",
      col1: "Corporal",
    },
    {
      key: "Sergeant",
      col1: "Sergeant",
    },
    {
      key: "Staff Sergeant",
      col1: "Staff Sergeant",
    },
    {
      key: "Warrant Officer Class 2",
      col1: "Warrant Officer Class 2",
    },
    {
      key: "Warrant Officer Class 1",
      col1: "Warrant Officer Class 1",
    },
  ],
  navyOfficerRanks: [
    {
      key: "Midshipman",
      col1: "Midshipman",
    },
    {
      key: "Lieutenant",
      col1: "Lieutenant",
    },
    {
      key: "Lieutenant Commander",
      col1: "Lieutenant Commander",
    },
    {
      key: "Commander",
      col1: "Commander",
    },
    {
      key: "Captain",
      col1: "Captain",
    },
    {
      key: "Commodore",
      col1: "Commodore",
    },
    {
      key: "Rear Admiral",
      col1: "Rear Admiral",
    },
    {
      key: "Vice Admiral",
      col1: "Vice Admiral",
    },
    {
      key: "Admiral",
      col1: "Admiral",
    },
  ],
  navyRatingsRanks: [
    {
      key: "Able Rate",
      col1: "Able Rate",
    },
    {
      key: "Leading Hand",
      col1: "Leading Hand",
    },
    {
      key: "Petty Officer",
      col1: "Petty Officer",
    },
    {
      key: "Cheif Petty Officer",
      col1: "Cheif Petty Officer",
    },
    {
      key: "Warrant Officer 2",
      col1: "Warrant Officer 2",
    },
    {
      key: "Warrant Officer 1",
      col1: "Warrant Officer 1",
    },
  ],
  rafCommisionedRanks: [
    {
      key: "Pilot Officer",
      col1: "Pilot Officer",
    },
    {
      key: "Flying Officer",
      col1: "Flying Officer",
    },
    {
      key: "Flight Lieutenant",
      col1: "Flight Lieutenant",
    },
    {
      key: "Squadron Leader",
      col1: "Squadron Leader",
    },
    {
      key: "Wing Commander",
      col1: "Wing Commander",
    },
    {
      key: "Group Captain",
      col1: "Group Captain",
    },
    {
      key: "Air Commordore",
      col1: "Air Commordore",
    },
    {
      key: "Air Vice-Marshal",
      col1: "Air Vice-Marshal",
    },
    {
      key: "Air Marshal",
      col1: "Air Marshal",
    },
    {
      key: "Air Chief Marshal",
      col1: "Air Chief Marshal",
    },
    {
      key: "Marshal of the Royal Air Force",
      col1: "Marshal of the Royal Air Force",
    },
  ],
  rafNonCommisionedAirCrewRanks: [
    {
      key: "RAF Sergeant Aircrew",
      col1: "RAF Sergeant Aircrew",
    },
    {
      key: "RAF Flight Sergeant Aircrew",
      col1: "RAF Flight Sergeant Aircrew",
    },
    {
      key: "RAF Master Aircrew",
      col1: "RAF Master Aircrew",
    },
  ],
  rafNonCommisionedRanks: [
    {
      key: "Air Specialist (Class 2)",
      col1: "Air Specialist (Class 2)",
    },
    {
      key: "Air Specialist (Class 1)",
      col1: "Air Specialist (Class 1)",
    },
    {
      key: "Air Specialist (Class 1) Technician",
      col1: "Air Specialist (Class 1) Technician",
    },
    {
      key: "Lance Corporal RAF Regiment",
      col1: "Lance Corporal RAF Regiment",
    },
    {
      key: "Corporal",
      col1: "Corporal",
    },
    {
      key: "Sergeant",
      col1: "Sergeant",
    },
    {
      key: "Chief Technician",
      col1: "Chief Technician",
    },
    {
      key: "Flight Sergeant",
      col1: "Flight Sergeant",
    },
    {
      key: "Warrant Officer",
      col1: "Warrant Officer",
    },
  ],
  royalMarinesRanks: [
    {
      key: "Private",
      col1: "Private",
    },
    {
      key: "Lance-Corporal",
      col1: "Lance-Corporal",
    },
    {
      key: "Corporal",
      col1: "Corporal",
    },
    {
      key: "Sergeant",
      col1: "Sergeant",
    },
    {
      key: "Sergeant-Major",
      col1: "Sergeant-Major",
    },
    {
      key: "Colour-Sergeant",
      col1: "Colour-Sergeant",
    },
    {
      key: "Second-Lieutenant (Ensign)",
      col1: "Second-Lieutenant (Ensign)",
    },
    {
      key: "Lieutenant",
      col1: "Lieutenant",
    },
    {
      key: "Captain",
      col1: "Captain",
    },
    {
      key: "Major",
      col1: "Major",
    },
    {
      key: "Lieutenant-Colonel of the Royal Marines",
      col1: "Lieutenant-Colonel of the Royal Marines",
    },
  ],
};

export { preDecimalMoney, preDecimalNotes, militaryRanks };
