import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { vitaminsAndMinerals } from "../../LearnSpecificCategoryDetails";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnTwelveDaysOfChristmas() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={vitaminsAndMinerals.mainCategory}
          subCategory={vitaminsAndMinerals.subCategory}
          iconLrg={vitaminsAndMinerals.iconLrg}
          iconSml={vitaminsAndMinerals.iconSml}
          bigPeep={vitaminsAndMinerals.bigPeep}
          intro={vitaminsAndMinerals.intro}
          intro2={vitaminsAndMinerals.intro2}
          intro3={vitaminsAndMinerals.intro3}
          intro4={vitaminsAndMinerals.intro4}
        />
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Vitamins</h2>
        </div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Vitamin</th>
                <th>Also Called</th>
                <th>Fat or Water Soluble</th>
                <th>Needed For</th>
                <th>Deficiency Symptoms</th>
                <th>Overdose Symptoms</th>
                <th>Sources</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Vitamin">A</td>
                <td data-label="Also Called">Retinol and Beta-carotene</td>
                <td data-label="Fat or Water Soluble">Fat</td>
                <td data-label="Needed For">Eye health</td>
                <td data-label="Deficiency Symptoms">Loss of vision</td>
                <td data-label="Overdose Symptoms">Liver damage</td>
                <td data-label="Sources">
                  carrots, liver, eggs, spinach, kale, sweet potato etc
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B1</td>
                <td data-label="Also Called">Thiamin</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">
                  Produces enzymes that help break down blood sugar
                </td>
                <td data-label="Deficiency Symptoms">
                  Wet Beriberi - shortness of breath, rapid heart rate, swollen
                  lower legs. Dry Beriberi - decreased muscle function,
                  tingling/loss of feeling in the feet and hands, pain, mental
                  confusion, difficulty speaking, vomiting, involuntary eye
                  movement, paralysis. Extreme cases - Wernicke-Korsakoff
                  syndrome - brain damage
                </td>
                <td data-label="Overdose Symptoms">
                  No established toxic level
                </td>
                <td data-label="Sources">
                  Brewer's yeast, pork, seeds and grains, asparagus, potatoes,
                  oranges
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B2</td>
                <td data-label="Also Called">Riboflavin</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">
                  Growth and development of body cells
                </td>
                <td data-label="Deficiency Symptoms">
                  Bad skin/Sore tongue lips and mouth fissures
                </td>
                <td data-label="Overdose Symptoms">
                  None - the gut only absorbs a limited amount
                </td>
                <td data-label="Sources">
                  Bananas, okra, cottage cheese, yogurt, fish, meat
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B3</td>
                <td data-label="Also Called">Niacin</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">Cells to grow and function</td>
                <td data-label="Deficiency Symptoms">
                  Pellagra - diarrheoa & intestinal upset, fatigue
                </td>
                <td data-label="Overdose Symptoms">
                  Very high doses cause diarrhoea easy bruising increased
                  bleeding from wounds sickness and liver damage
                </td>
                <td data-label="Sources">
                  chicken, tuna, salmon, tomatoes, milk, leafy green veg etc
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B5</td>
                <td data-label="Also Called">Pantothenic acid</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">Producing energy and hormones</td>
                <td data-label="Deficiency Symptoms">
                  Muscle cramps/anxiety pins and needles
                </td>
                <td data-label="Overdose Symptoms">
                  Diarrhoea heartburn nausea dehydration oedema joint pain
                  calcification in blood vessels or depression
                </td>
                <td data-label="Sources">
                  meats, whole grains, broccoli, avocados, yogurt
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B6</td>
                <td data-label="Also Called">Pyridoxine</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">Red blood cell formation</td>
                <td data-label="Deficiency Symptoms">
                  Depression/Lack of energy anaemia
                </td>
                <td data-label="Overdose Symptoms">
                  High doses for over a year can cause severe nerve damage
                </td>
                <td data-label="Sources">
                  chickpeas, beef, liver, bananas, nuts
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B7</td>
                <td data-label="Also Called">Biotin</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">
                  Metabolization of fats proteins and carbohydrates
                </td>
                <td data-label="Deficiency Symptoms">
                  Dermatitis intestinal inflamation
                </td>
                <td data-label="Overdose Symptoms">
                  None - excess is excreted through urine
                </td>
                <td data-label="Sources">
                  egg yolks, liver, broccoli, spinach, cheese
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B9</td>
                <td data-label="Also Called">Folic acid</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">Makes RNA and DNA</td>
                <td data-label="Deficiency Symptoms">
                  Fatigue, weakness, mouth sores and neurological issues
                </td>
                <td data-label="Overdose Symptoms">
                  Nausea, diarrhoea, irritability, confusion, seizures, can mask
                  vitamin B-12 deficiency
                </td>
                <td data-label="Sources">
                  Baked beans, marmite, legumes, sunflower seeds, leafy
                  vegetables
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">B12</td>
                <td data-label="Also Called">Cyanocobalamin</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">Healthy nervous system</td>
                <td data-label="Deficiency Symptoms">
                  Nerve problems, muscle weakness, vision loss, mental health
                  problems
                </td>
                <td data-label="Overdose Symptoms">Acne and rosacea</td>
                <td data-label="Sources">
                  Shellfish, poultry, dairy products, fortified cereals
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">C</td>
                <td data-label="Also Called">Ascorbic Acid</td>
                <td data-label="Fat or Water Soluble">Water</td>
                <td data-label="Needed For">
                  Immune system, iron absorbtion, wound healing
                </td>
                <td data-label="Deficiency Symptoms">
                  Scurvy - exhaustion, spontaneous bleeding, ulceration of the
                  gums and tooth loss
                </td>
                <td data-label="Overdose Symptoms">Diarrhoea and nausea</td>
                <td data-label="Sources">
                  Fruit and vegetables (raw is best as cooking destroys vitamin
                  C)
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">D</td>
                <td data-label="Also Called">Calciferol</td>
                <td data-label="Fat or Water Soluble">Fat</td>
                <td data-label="Needed For">Healthy bones</td>
                <td data-label="Deficiency Symptoms">
                  Rickets and bone softening
                </td>
                <td data-label="Overdose Symptoms">
                  Dizziness, thirst, fatigue, nausea and diarrhoea
                </td>
                <td data-label="Sources">
                  Fatty fish, mushrooms, eggs (Sunlight makes body produce
                  vitamin D)
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">E</td>
                <td data-label="Also Called">Tocopherol</td>
                <td data-label="Fat or Water Soluble">Fat</td>
                <td data-label="Needed For">
                  Reduces inflammation and helps combat some diseases
                </td>
                <td data-label="Deficiency Symptoms">
                  Nerve Problems - Deficiency is rare - could cause anemia in
                  newborns
                </td>
                <td data-label="Overdose Symptoms">
                  Rare - risk of bleeding, muscle weakness, fatigue nausea and
                  diarrhoea
                </td>
                <td data-label="Sources">
                  Kiwis, almonds, eggs, nuts, leafy greens, vegetable oils
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">F</td>
                <td data-label="Also Called">
                  Linoleic Acid - Not really a vitamin - actually just 2
                  essential fatty acids (Omega 3 and Omega 6)
                </td>
                <td data-label="Fat or Water Soluble">Fat</td>
                <td data-label="Needed For">
                  Brain and heart health, lowers high blood sugar
                </td>
                <td data-label="Deficiency Symptoms">
                  Rarely - dry skin and hair, slow wound healing, poor growth in
                  children
                </td>
                <td data-label="Overdose Symptoms">
                  Too much omega-6 stops the body using the omega-3 and can lead
                  to heart disease
                </td>
                <td data-label="Sources">
                  Vegetable oils, avacoados, seeds, nuts, soybeans, tofu, fish,
                  eggs
                </td>
              </tr>
              <tr>
                <td data-label="Vitamin">K</td>
                <td data-label="Also Called">Phylloquinone</td>
                <td data-label="Fat or Water Soluble">Fat</td>
                <td data-label="Needed For">Blood clotting</td>
                <td data-label="Deficiency Symptoms">
                  Haemorrhage (easy bleeding)
                </td>
                <td data-label="Overdose Symptoms">Blood clots</td>
                <td data-label="Sources">
                  Spinach, broccoli, pumpkin, figs, parsley
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Minerals</h2>
        </div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Mineral</th>
                <th>RDA</th>
                <th>Needed For</th>
                <th>Deficiency Symptoms</th>
                <th>Overdose Symptoms</th>
                <th>Sources</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Mineral">Calcium</td>
                <td data-label="RDA">700mg</td>
                <td data-label="Needed For">
                  Strong bones & teeth, helps muscles & blood vessels contract &
                  expand, sends messages through the nervous system, helps
                  release hormones & enzymes
                </td>
                <td data-label="Deficiency Symptoms">
                  Rickets in children, osteomalacia or osteoporosis in later
                  life
                </td>
                <td data-label="Overdose Symptoms">
                  Stomach pain and diarrhoea
                </td>
                <td data-label="Sources">
                  Milk, cheese, curly kale, okra, anything made with fortified
                  flour, fish such as sardines where you eat the bones
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Chloride</td>
                <td data-label="RDA">800mg (most people consume way more)</td>
                <td data-label="Needed For">Balance of body fluids</td>
                <td data-label="Deficiency Symptoms">
                  Hypochloremia - sickness and dehydration
                </td>
                <td data-label="Overdose Symptoms">
                  Fluid retention, high blood pressure, muscle weakness, spasms
                  or twitches, irregular heart rate, confusion, difficulty
                  concentrating, personality changes, numbness or tingling,
                  seizures and convulsions
                </td>
                <td data-label="Sources">
                  Many vegetables, there are higher amounts in seaweed, rye,
                  tomatoes, lettuce, celery and olives. Very high in processed
                  foods.
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Chromium</td>
                <td data-label="RDA">25μg</td>
                <td data-label="Needed For">
                  Breakdown of fats and carbohydrates, stimulates fatty acid and
                  cholesterol synthesis, important for brain function, aids in
                  insulin action and glucose breakdown
                </td>
                <td data-label="Deficiency Symptoms">
                  Weight loss, confusion, impaired coordination, increased risk
                  of diabetes
                </td>
                <td data-label="Overdose Symptoms">
                  Not enough evidence to know
                </td>
                <td data-label="Sources">Meat, nuts, cereal, grains</td>
              </tr>
              <tr>
                <td data-label="Mineral">Copper</td>
                <td data-label="RDA">1.2mg</td>
                <td data-label="Needed For">
                  Red and white blood cell production, triggers the release of
                  iron to form haemoglobin
                </td>
                <td data-label="Deficiency Symptoms">
                  Anemia, low body temperature, bone fractures and osteoporosis,
                  low white blood cell count, irregular heartbeat, loss of
                  pigment from the skin and thyroid problems
                </td>
                <td data-label="Overdose Symptoms">
                  Stomach pain, sickness, diarrhoea, overdose for a long time =
                  damage to the liver and kidneys
                </td>
                <td data-label="Sources">Nuts, shellfish, offal</td>
              </tr>
              <tr>
                <td data-label="Mineral">Fluoride</td>
                <td data-label="RDA">2.9-3.4 mg</td>
                <td data-label="Needed For">
                  Hardens teeth enamel, stabalises bone structure
                </td>
                <td data-label="Deficiency Symptoms">
                  Tooth and bone weakness, tooth decay
                </td>
                <td data-label="Overdose Symptoms">
                  Abdominal pain, excessive saliva, nausea and sickness,
                  seizures and muscle spasms
                </td>
                <td data-label="Sources">
                  Grapes, raisins, wine, marine fish, tea and coffee, foods made
                  with fluoridated water such as soup
                </td>
              </tr>

              <tr>
                <td data-label="Mineral">Iodine</td>
                <td data-label="RDA">140μg</td>
                <td data-label="Needed For">
                  Helps make thyroid hormones and maintain a good metabolic rate
                </td>
                <td data-label="Deficiency Symptoms">Thyroid goiter</td>
                <td data-label="Overdose Symptoms">Weight gain</td>
                <td data-label="Sources">
                  Sea fish, shellfish, cereals and grains - levels vary
                  depending on amount of iodine in the soil
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Iron</td>
                <td data-label="RDA">Men 8.7mg / Women 14.8mg</td>
                <td data-label="Needed For">Red blood cell production</td>
                <td data-label="Deficiency Symptoms">Anaemia</td>
                <td data-label="Overdose Symptoms">
                  Constipation, nausea, sickness, stomach pain
                </td>
                <td data-label="Sources">
                  Liver, red meat, beans, nuts, dried fruit, fortified breakfast
                  cereals
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Magnesium</td>
                <td data-label="RDA">300mg</td>
                <td data-label="Needed For">
                  Converts food to energy, produces hormones, is important for
                  bone health
                </td>
                <td data-label="Deficiency Symptoms">
                  Weakness, irritability, abnormal heart rhythm, nausea,
                  diarrhoea, abnormal calcium or potassium levels
                </td>
                <td data-label="Overdose Symptoms">
                  Diarrhoea, nausea, sickness, lethargy
                </td>
                <td data-label="Sources">
                  Whole grains, dark-green leafy vegetables, milk, yogurt, dried
                  beans, legumes and nuts
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Manganese</td>
                <td data-label="RDA">1.8 – 2.3 mg</td>
                <td data-label="Needed For">
                  Makes and activate some of the enzymes in the body
                </td>
                <td data-label="Deficiency Symptoms">
                  Poor bone growth, skeletal defects , low fertility, impaired
                  glucose tolerance, abnormal metabolism of carbohydrate and fat
                </td>
                <td data-label="Overdose Symptoms">
                  Overdose over a long time - muscle pain, nerve damage,
                  fatigue, depression
                </td>
                <td data-label="Sources">
                  Bread, nuts, breakfast cereals (especially wholegrain), green
                  vegetables
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Molybdenum</td>
                <td data-label="RDA">45μg</td>
                <td data-label="Needed For">
                  Molybdenum helps make and activate enzymes for production and
                  repair of genetic material
                </td>
                <td data-label="Deficiency Symptoms">
                  (Deficiency is very very rare) Fast heart rate and breathing,
                  sickness, disorientation and eventually coma. Also possibly
                  linked to an increased risk of esophageal cancer
                </td>
                <td data-label="Overdose Symptoms">might cause joint pain</td>
                <td data-label="Sources">
                  Found in a wide variety of foods, those that grow above ground
                  tend to have higher levels
                </td>
              </tr>

              <tr>
                <td data-label="Mineral">Phosphorus</td>
                <td data-label="RDA">550mg</td>
                <td data-label="Needed For">
                  Building strong bones and teeth, helps release energy from
                  food
                </td>
                <td data-label="Deficiency Symptoms">
                  Poor appetite, anemia, muscle weakness, bone pain,bone disease
                  (osteomalacia or rickets), confusion, increased susceptibility
                  to infections
                </td>
                <td data-label="Overdose Symptoms">
                  Short term - diarrhoea or stomach pain. Long term - can reduce
                  the amount of calcium in the body makig bone fracture more
                  likely
                </td>
                <td data-label="Sources">
                  Red meat, dairy foods, fish, poultry, bread, brown rice, oats
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Potassium</td>
                <td data-label="RDA">3500mg</td>
                <td data-label="Needed For">
                  Controls fluid balance of the body and helps the heart muscle
                  work properly
                </td>
                <td data-label="Deficiency Symptoms">
                  (Hypokalemia) High blood pressure, constipation, muscle
                  weakness and fatigue
                </td>
                <td data-label="Overdose Symptoms">
                  Stomach pain, nausea and diarrhoea
                </td>
                <td data-label="Sources">
                  Bananas, broccoli, parsnips, brussels sprouts, beans and
                  pulses, nuts and seeds, fish, meat
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Selenium</td>
                <td data-label="RDA">Men 75μg / Women 60μg</td>
                <td data-label="Needed For">
                  Helps the immune system work properly, reproduction, helps
                  prevent cell and tissue damage
                </td>
                <td data-label="Deficiency Symptoms">
                  Fatigue, weakened immune system, hair loss, muscle weakness,
                  infertility
                </td>
                <td data-label="Overdose Symptoms">
                  (Selenosis) mild symptoms - loss of hair and nails
                </td>
                <td data-label="Sources">Brazil nuts, fish, meat, eggs</td>
              </tr>
              <tr>
                <td data-label="Mineral">Sodium</td>
                <td data-label="RDA">
                  200 - 500mg (most people consume way more)
                </td>
                <td data-label="Needed For">
                  Body fluid balance, helps the body digest food
                </td>
                <td data-label="Deficiency Symptoms">
                  (Hyponatremia) Altered personality, lethargy and confusion.
                  Severe hyponatremia - seizures, coma and even death
                </td>
                <td data-label="Overdose Symptoms">
                  High blood pressure, stroke and heart attack
                </td>
                <td data-label="Sources">
                  Found naturally at low levels in all foods, high amounts are
                  added to many process foods
                </td>
              </tr>
              <tr>
                <td data-label="Mineral">Zinc</td>
                <td data-label="RDA">Men 9.5mg / Women 7mg </td>
                <td data-label="Needed For">
                  Making new cells and enzymes, processing carbohydrate fat and
                  protein, wound healing
                </td>
                <td data-label="Deficiency Symptoms">
                  Hair loss, lack of alertness, reduced sense of taste and smell
                </td>
                <td data-label="Overdose Symptoms">
                  Reduces the amount of copper the body can absorb leading to
                  anaemia and bone weakening
                </td>
                <td data-label="Sources">
                  Meat, shellfish, dairy foods, bread, cereal products
                </td>
              </tr>
            </tbody>
          </table>
          <p className="small-text">
            RDA ammounts are taken from NHS online and other reputable sources,
            however they may not be appropriate for everyone, always consult
            your Doctor for advice.
          </p>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={vitaminsAndMinerals.importantFacts}
          classicQuestions={vitaminsAndMinerals.classicQuestions}
          exampleQuestions={vitaminsAndMinerals.exampleQuestions}
          thoughtsTipsIdeas={vitaminsAndMinerals.thoughtsTipsAndIdeas}
          smallPeep1={vitaminsAndMinerals.smallPeep1}
          smallPeep2={vitaminsAndMinerals.smallPeep2}
          disclaimer={vitaminsAndMinerals.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnTwelveDaysOfChristmas;
