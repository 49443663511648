import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Link } from "react-router-dom";
import "../css/style.css";
import "../css/homepage.css";
import "../css/peeps.css";

function Home() {
  const pages = [
    "./learn/sport-and-games/football-world-cup",
    "./learn/art-and-literature/shakespeare",
    "./learn/general-quizzing/alter-egos",
    "./learn/entertainment/james-bond",
    "./learn/general-quizzing/classic-questions",
  ];
  const page = pages[Math.floor(Math.random() * pages.length)];

  return (
    <div className="homepage-hero push-footer ">
      <Header />
      <div className="container-homepage">
        <div className="tile-top-row-homepage tile-large-homepage tile-border-homepage">
          <h1 className="title-homepage">
            THE ULTIMATE TRIVIA AND PUB QUIZ RESOURCE
          </h1>
        </div>
        <div className="tile-top-row-homepage tile-border-homepage tile-orangebg-homepage">
          <img
            src={require("../images/Pointy.png")}
            alt="cartoon character pointing to Waddauno video"
            className="peep-homepage pointing-peep-and-questionmark-homepage"
          />
          <p>Unsure where to begin? Check out this video</p>
        </div>
        <div className="tile-top-row-homepage tile-extralarge-homepage  tile-border-homepage ">
          <iframe
            width="448"
            height="330"
            src="https://www.youtube.com/embed/ScMzIvxBSi4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          ></iframe>
        </div>
        <Link
          to="/learn"
          className="tile-homepage tile-medium-homepage tile-border-homepage learn-tile"
        >
          <div className="tile-homepage-button">Learn</div>
        </Link>
        <Link
          to="/practice"
          className="tile-homepage tile-medium-homepage tile-border-homepage practice-tile"
        >
          <div className="tile-homepage-button">Practice</div>
        </Link>

        <Link
          to="/find-a-quiz-near-me"
          className="tile-homepage tile-small-homepage tile-border-homepage map-tile"
        >
          <div className="tile-homepage-button">Find a quiz near me</div>
        </Link>
        <Link
          to="/puzzles-and-games"
          className="tile-homepage tile-small-homepage tile-border-homepage puzzles-and-games-tile"
        >
          <div className="tile-homepage-button">Puzzles & Games</div>
        </Link>
        <div className="tile-homepage tile-medium-homepage tile-longbuttons-homepage">
          <Link
            to="/practice/quickfire-questions"
            className="tile-longbutton-homepage"
          >
            Quickfire Questions
          </Link>
          <a href={page} className="tile-longbutton-homepage">
            Learn Something New
          </a>
          <Link to="#" className="tile-longbutton-homepage">
            Buy A Quiz
          </Link>
        </div>
        {/* <div className="tile-homepage tile-small-homepage tile-squarebuttons-homepage">
          <a
            href="https://www.youtube.com/channel/UChqhBqUeYpJRs2TWMSsiERg"
            target="_blank"
            className="tile-squarebutton-homepage newest-quiz-tile"
          >
            <p className="absolute-text-tile">Newest Quiz Video</p>
          </a>
          <Link to="#" className="tile-squarebutton-homepage articles-tile">
            <p className="absolute-text-tile">Quiz Articles</p>
          </Link>
          <Link
            to="/in-the-news-june"
            className="tile-squarebutton-homepage news-tile"
          >
            <p className="absolute-text-tile">In The News</p>
          </Link>
          <Link
            to="/puzzles-and-games"
            className="tile-squarebutton-homepage feature-tile"
          >
            <p className="absolute-text-tile">New Feature</p>
          </Link>
        </div> */}

        <Link
          to="/puzzles-and-games"
          className="tile-homepage tile-small-homepage tile-border-homepage feature-tile"
        >
          <div className="tile-homepage-button">New Feature</div>
        </Link>
        <Link
          to="/shop"
          className="tile-homepage tile-small-homepage tile-border-homepage shop-tile"
        >
          <div className="tile-homepage-button">Shop</div>
        </Link>
        <Link
          to="/your-quiz-night"
          className="tile-homepage tile-medium-homepage tile-border-homepage tell-us-tile"
        >
          <div className="tile-homepage-button">Tell us about your quiz</div>
        </Link>
      </div>
      <img
        src={require("../images/thinker.png")}
        alt="The Thinker bronze statue by Auguste Rodin"
        className="thinker thinker1"
      />
      <img
        src={require("../images/thinker.png")}
        alt="The Thinker bronze statue by Auguste Rodin"
        className="thinker thinker2"
      />
      <Footer />
    </div>
  );
}

export default Home;
