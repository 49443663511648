import React from "react";
import OldMoney from "../OldMoney";
import OldMoneyNotes from "../OldMoneyNotes";
import {
  preDecimalMoney,
  preDecimalNotes,
} from "../../LearnSpecificCategoryDetailsSix";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";

function LearnPreDecimalMoney() {
  function arrangeOldMoney(om) {
    return (
      <OldMoney
        key={om.id}
        moneyName={om.moneyName}
        moneyDetails={om.moneyDetails}
        moneyImg={om.moneyImg}
        moneyImgBack={om.moneyImgBack}
      />
    );
  }
  function arrangeOldMoneyNotes(om) {
    return (
      <OldMoneyNotes
        key={om.id}
        moneyName={om.moneyName}
        moneyDetails={om.moneyDetails}
        moneyImg={om.moneyImg}
      />
    );
  }

  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Decimalisation of Money UK</h1>
        <div className="container">
          {/* <h2 className="category-subtitle centre">Acronyms & Initialisms</h2> */}
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Decimalisation of money in the UK occurred on Monday 15 February
                1971, this meant that it was changed to a system based on units
                of 10, bringing the system more in line with other countries and
                making international trade more simple.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small28.png")}
              alt="cartoon character old man with orange glasses"
            />
          </div>
          <p className="body-text">
            In the old money system, there were 240 pennies in a pound, and they
            were abbreviated to 'd', not 'p' as they are today. This 'd' comes
            from the Latin word 'denarius'.
            <br />
            <br />
            <span className="orange-text">
              The word 'old' will be used interchangeably with the word
              'pre-decimalisation' in the information on this page.
            </span>
          </p>
          <div className="line-even-margin"></div>
          <div className="arrange-first-lines">
            {preDecimalMoney.map(arrangeOldMoney)}
            {preDecimalNotes.map(arrangeOldMoneyNotes)}
          </div>
        </div>
      </section>
      <AsideNav />
      <Footer />
    </div>
  );
}

export default LearnPreDecimalMoney;
