import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { jamesBond } from "../../LearnSpecificCategoryDetails";

function LearnFilmAndTVJamesBond() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={jamesBond.mainCategory}
          subCategory={jamesBond.subCategory}
          iconLrg={jamesBond.iconLrg}
          iconSml={jamesBond.iconSml}
          bigPeep={jamesBond.bigPeep}
          intro={jamesBond.intro}
        />
        <div className="container-xs-padding">
          <h2 className="category-subtitle">EON Bond Films</h2>
        </div>

        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Year</th>
                <th>Bond Actor</th>
                <th>Main Villain</th>
                <th>Villain Actor</th>
                <th>Theme Music</th>
                <th>Music Artist</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="#">1</td>
                <td data-label="Title"> Dr No</td>
                <td data-label="Year"> 1962</td>
                <td data-label="Bond Actor"> Sean Connery</td>
                <td data-label="Main Villain"> Dr No</td>
                <td data-label="Villain Actor"> Joseph Wiseman</td>
                <td data-label="Theme Music"> The James Bond Theme</td>
                <td data-label="Music Artist"> John Barry</td>
              </tr>
              <tr>
                <td data-label="#">2</td>
                <td data-label="Title"> From Russia With Love</td>
                <td data-label="Year"> 1963</td>
                <td data-label="Bond Actor"> Sean Connery</td>
                <td data-label="Main Villain"> Rosa Klebb</td>
                <td data-label="Villain Actor"> Lotte Lenya</td>
                <td data-label="Theme Music"> From Russia With Love</td>
                <td data-label="Music Artist"> Matt Monro</td>
              </tr>
              <tr>
                <td data-label="#">3</td>
                <td data-label="Title"> Goldfinger</td>
                <td data-label="Year"> 1964</td>
                <td data-label="Bond Actor"> Sean Connery</td>
                <td data-label="Main Villain"> Auric Goldfinger</td>
                <td data-label="Villain Actor"> Gert Frobe</td>
                <td data-label="Theme Music"> Goldfinger</td>
                <td data-label="Music Artist"> Shirley Bassey</td>
              </tr>
              <tr>
                <td data-label="#">4</td>
                <td data-label="Title"> Thunderball</td>
                <td data-label="Year"> 1965</td>
                <td data-label="Bond Actor"> Sean Connery</td>
                <td data-label="Main Villain"> Emilio Largo</td>
                <td data-label="Villain Actor"> Adolfo Celi</td>
                <td data-label="Theme Music"> Thunderball</td>
                <td data-label="Music Artist"> Tom Jones</td>
              </tr>
              <tr>
                <td data-label="#">5</td>
                <td data-label="Title"> You Only Live Twice</td>
                <td data-label="Year"> 1967</td>
                <td data-label="Bond Actor"> Sean Connery</td>
                <td data-label="Main Villain"> Ernst Stavro Blofeld</td>
                <td data-label="Villain Actor"> Donald Pleasence</td>
                <td data-label="Theme Music"> You Only Live Twice</td>
                <td data-label="Music Artist"> Nancy Sinatra</td>
              </tr>
              <tr>
                <td data-label="#">6</td>
                <td data-label="Title"> On Her Majesty's Secret Service</td>
                <td data-label="Year"> 1969</td>
                <td data-label="Bond Actor"> George Lazenby</td>
                <td data-label="Main Villain"> Ernst Stavro Blofeld</td>
                <td data-label="Villain Actor"> Telly Savalas</td>
                <td data-label="Theme Music">
                  On Her Majesty's Secret Service
                </td>
                <td data-label="Music Artist"> John Barry</td>
              </tr>
              <tr>
                <td data-label="#">7</td>
                <td data-label="Title"> Diamonds Are Forever</td>
                <td data-label="Year"> 1971</td>
                <td data-label="Bond Actor"> Sean Connery</td>
                <td data-label="Main Villain"> Ernst Stavro Blofeld</td>
                <td data-label="Villain Actor"> Charles Gray</td>
                <td data-label="Theme Music"> Diamonds Are Forever</td>
                <td data-label="Music Artist"> Shirley Bassey</td>
              </tr>
              <tr>
                <td data-label="#">8</td>
                <td data-label="Title"> Live And Let Die</td>
                <td data-label="Year"> 1973</td>
                <td data-label="Bond Actor"> Roger Moore</td>
                <td data-label="Main Villain"> Dr Kananga (Mr Big)</td>
                <td data-label="Villain Actor"> Yaphett Kotto</td>
                <td data-label="Theme Music"> Live and Let Die</td>
                <td data-label="Music Artist"> Paul McCartney and Wings</td>
              </tr>
              <tr>
                <td data-label="#">9</td>
                <td data-label="Title"> The Man With The Golden Gun</td>
                <td data-label="Year"> 1974</td>
                <td data-label="Bond Actor"> Roger Moore</td>
                <td data-label="Main Villain"> Francisco Scaramanga</td>
                <td data-label="Villain Actor"> Christopher Lee</td>
                <td data-label="Theme Music"> The Man With The Golden Gun</td>
                <td data-label="Music Artist"> Lulu</td>
              </tr>
              <tr>
                <td data-label="#">10</td>
                <td data-label="Title"> The Spy Who Loved Me</td>
                <td data-label="Year"> 1977</td>
                <td data-label="Bond Actor"> Roger Moore</td>
                <td data-label="Main Villain"> Karl Sigmund Stromberg</td>
                <td data-label="Villain Actor"> Curd Jurgens</td>
                <td data-label="Theme Music"> Nobody Does It Better</td>
                <td data-label="Music Artist"> Carly Simon</td>
              </tr>
              <tr>
                <td data-label="#">11</td>
                <td data-label="Title"> Moonraker</td>
                <td data-label="Year"> 1979</td>
                <td data-label="Bond Actor"> Roger Moore</td>
                <td data-label="Main Villain"> Sir Hugo Drax</td>
                <td data-label="Villain Actor"> Michael Lonsdale</td>
                <td data-label="Theme Music"> Moonraker</td>
                <td data-label="Music Artist"> Shirley Bassey</td>
              </tr>
              <tr>
                <td data-label="#">12</td>
                <td data-label="Title"> For Your Eyes Only</td>
                <td data-label="Year"> 1981</td>
                <td data-label="Bond Actor"> Roger Moore</td>
                <td data-label="Main Villain"> Aristotle Kristatos</td>
                <td data-label="Villain Actor"> Julian Glover</td>
                <td data-label="Theme Music"> For Your Eyes Only</td>
                <td data-label="Music Artist"> Sheena Easton</td>
              </tr>
              <tr>
                <td data-label="#">13</td>
                <td data-label="Title"> Octopussy</td>
                <td data-label="Year"> 1983</td>
                <td data-label="Bond Actor"> Roger Moore</td>
                <td data-label="Main Villain"> Kamal Khan</td>
                <td data-label="Villain Actor"> Louis Jourdan</td>
                <td data-label="Theme Music"> All Time High</td>
                <td data-label="Music Artist"> Rita Coolidge</td>
              </tr>
              <tr>
                <td data-label="#">14</td>
                <td data-label="Title"> A View To A Kill</td>
                <td data-label="Year"> 1985</td>
                <td data-label="Bond Actor"> Roger Moore</td>
                <td data-label="Main Villain"> Max Zorin</td>
                <td data-label="Villain Actor"> Christopher Walken</td>
                <td data-label="Theme Music"> A View To A Kill</td>
                <td data-label="Music Artist"> Duran Duran</td>
              </tr>
              <tr>
                <td data-label="#">15</td>
                <td data-label="Title"> The Living Daylights</td>
                <td data-label="Year"> 1987</td>
                <td data-label="Bond Actor"> Timothy Dalton</td>
                <td data-label="Main Villain">
                  General Georgi Koskov or Brad Whitaker
                </td>
                <td data-label="Villain Actor">
                  Jeroen Krabbe or Joe Don Baker
                </td>
                <td data-label="Theme Music"> The Living Daylights</td>
                <td data-label="Music Artist"> a-ha</td>
              </tr>
              <tr>
                <td data-label="#">16</td>
                <td data-label="Title"> Licence To Kill</td>
                <td data-label="Year"> 1989</td>
                <td data-label="Bond Actor"> Timothy Dalton</td>
                <td data-label="Main Villain"> Franz Sanchez</td>
                <td data-label="Villain Actor"> Robert Davi</td>
                <td data-label="Theme Music"> Licence to Kill</td>
                <td data-label="Music Artist"> Gladys Knight</td>
              </tr>
              <tr>
                <td data-label="#">17</td>
                <td data-label="Title"> Goldeneye</td>
                <td data-label="Year"> 1995</td>
                <td data-label="Bond Actor"> Pierce Brosnan</td>
                <td data-label="Main Villain"> Alec Trevelyan (006)</td>
                <td data-label="Villain Actor"> Sean Bean</td>
                <td data-label="Theme Music"> Goldeneye</td>
                <td data-label="Music Artist"> Tina Turner</td>
              </tr>
              <tr>
                <td data-label="#">18</td>
                <td data-label="Title"> Tomorrow Never Dies</td>
                <td data-label="Year"> 1997</td>
                <td data-label="Bond Actor"> Pierce Brosnan</td>
                <td data-label="Main Villain"> Elliot Carver</td>
                <td data-label="Villain Actor"> Jonathon Pryce</td>
                <td data-label="Theme Music"> Tomorrow Never Dies</td>
                <td data-label="Music Artist"> Sheryl Crow</td>
              </tr>
              <tr>
                <td data-label="#">19</td>
                <td data-label="Title"> The World Is Not Enough</td>
                <td data-label="Year"> 1999</td>
                <td data-label="Bond Actor"> Pierce Brosnan</td>
                <td data-label="Main Villain"> Viktor Zokas (Renard)</td>
                <td data-label="Villain Actor"> Robert Carlyle</td>
                <td data-label="Theme Music"> The World Is Not Enough</td>
                <td data-label="Music Artist"> Garbage</td>
              </tr>
              <tr>
                <td data-label="#">20</td>
                <td data-label="Title"> Die Another Day</td>
                <td data-label="Year"> 2002</td>
                <td data-label="Bond Actor"> Pierce Brosnan</td>
                <td data-label="Main Villain">
                  Gustav Graves/Colonel Tan-Sun Moon
                </td>
                <td data-label="Villain Actor"> Toby Stephens/Will Yun Lee</td>
                <td data-label="Theme Music"> Die Another Day</td>
                <td data-label="Music Artist"> Madonna</td>
              </tr>
              <tr>
                <td data-label="#">21</td>
                <td data-label="Title"> Casino Royale</td>
                <td data-label="Year"> 2006</td>
                <td data-label="Bond Actor"> Daniel Craig</td>
                <td data-label="Main Villain"> Le Chiffre</td>
                <td data-label="Villain Actor"> Mads Mikkelsen</td>
                <td data-label="Theme Music"> You Know My Name</td>
                <td data-label="Music Artist"> Chris Cornell</td>
              </tr>
              <tr>
                <td data-label="#">22</td>
                <td data-label="Title"> Quantum Of Solace</td>
                <td data-label="Year"> 2008</td>
                <td data-label="Bond Actor"> Daniel Craig</td>
                <td data-label="Main Villain"> Dominic Greene</td>
                <td data-label="Villain Actor"> Mathieu Amalric</td>
                <td data-label="Theme Music"> Another Way To Die</td>
                <td data-label="Music Artist"> Jack White &amp; Alicia Keys</td>
              </tr>
              <tr>
                <td data-label="#">23</td>
                <td data-label="Title"> Skyfall</td>
                <td data-label="Year"> 2012</td>
                <td data-label="Bond Actor"> Daniel Craig</td>
                <td data-label="Main Villain"> Tiago Rodriguez/Raoul Silva</td>
                <td data-label="Villain Actor"> Javier Bardem</td>
                <td data-label="Theme Music"> Skyfall</td>
                <td data-label="Music Artist"> Adele</td>
              </tr>
              <tr>
                <td data-label="#">24</td>
                <td data-label="Title"> Spectre</td>
                <td data-label="Year"> 2015</td>
                <td data-label="Bond Actor"> Daniel Craig</td>
                <td data-label="Main Villain">
                  Franz Oberhauser/Ernst Stavro Blofeld
                </td>
                <td data-label="Villain Actor"> Christopher Waltz</td>
                <td data-label="Theme Music"> Writing&#39;s On The Wall</td>
                <td data-label="Music Artist"> Sam Smith</td>
              </tr>
              <tr>
                <td data-label="#">25</td>
                <td data-label="Title"> No Time To Die</td>
                <td data-label="Year"> 2021</td>
                <td data-label="Bond Actor"> Daniel Craig</td>
                <td data-label="Main Villain"> Lyutsifer Safin</td>
                <td data-label="Villain Actor"> Rami Malek</td>
                <td data-label="Theme Music"> No Time To Die</td>
                <td data-label="Music Artist"> Billie Eilish</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Non EON Bond Films</h2>
        </div>

        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Year</th>
                <th>Bond Actor</th>
                <th>Main Villain</th>
                <th>Villain Actor</th>
                <th>Theme Music</th>
                <th>Music Artist</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="#">1</td>
                <td data-label="Title">Casino Royale</td>
                <td data-label="Year"> 1967</td>
                <td data-label="Bond Actor"> David Niven</td>
                <td data-label="Main Villain"> Dr Noah (Jimmy Bond)</td>
                <td data-label="Villain Actor"> Woody Allen</td>
                <td data-label="Theme Music"> Casino Royale</td>
                <td data-label="Music Artist">
                  Herb Alpert &amp; the Tijuana Brass
                </td>
              </tr>
              <tr>
                <td data-label="#">2</td>
                <td data-label="Title">Never Say Never Again</td>
                <td data-label="Year"> 1983</td>
                <td data-label="Bond Actor"> Sean Connery</td>
                <td data-label="Main Villain"> Maximillian Largo</td>
                <td data-label="Villain Actor"> Klaus Maria Brandauer</td>
                <td data-label="Theme Music"> Never Say Never Again</td>
                <td data-label="Music Artist"> Lani Hall</td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={jamesBond.importantFacts}
          classicQuestions={jamesBond.classicQuestions}
          exampleQuestions={jamesBond.exampleQuestions}
          thoughtsTipsIdeas={jamesBond.thoughtsTipsAndIdeas}
          smallPeep1={jamesBond.smallPeep1}
          smallPeep2={jamesBond.smallPeep2}
          disclaimer={jamesBond.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnFilmAndTVJamesBond;
