import React from "react";
import "../css/hangman.css";
import hangman0 from "../images/blank1.gif";
import hangman1 from "../images/hangman-1.gif";
import hangman2 from "../images/hangman-2.gif";
import hangman3 from "../images/hangman-3.gif";
import hangman4 from "../images/hangman-4.gif";
import hangman5 from "../images/hangman-5.gif";
import hangman6 from "../images/hangman-6.gif";

function HangingMan(props) {
  // errors is wrong guesses and check conditionally and display hanging man parts
  const errors = props.wrongGuesses;
  const hangmanGifs = [
    hangman0,
    hangman1,
    hangman2,
    hangman3,
    hangman4,
    hangman5,
    hangman6,
  ];
  return (
    <img
      className="figure-container"
      src={hangmanGifs[errors]}
      alt="hangman drawing"
    />
  );
}

export default HangingMan;
