import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { latinWords } from "../../LearnSpecificCategoryDetailsTwo";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnLatin() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={latinWords.mainCategory}
          subCategory={latinWords.subCategory}
          iconLrg={latinWords.iconLrg}
          iconSml={latinWords.iconSml}
          bigPeep={latinWords.bigPeep}
          intro={latinWords.intro}
        />
        <div className="table-container"></div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Well Known Phrases</h2>
        </div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Word/Phrase</th>
                <th>Meaning</th>
                <th>Explanation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Word/Phrase">Alibi</td>
                <td data-label="Meaning">Elsewhere</td>
                <td data-label="Explanation"></td>
              </tr>
              <tr>
                <td data-label="Word/Phrase">Audio</td>
                <td data-label="Meaning">I hear</td>
                <td data-label="Explanation"></td>
              </tr>
              <tr>
                <td data-label="Word/Phrase">Ergo</td>
                <td data-label="Meaning">Therefore</td>
                <td data-label="Explanation">
                  Used in maths and science when people try to sound
                  intelligent. Most famously Cogito Ergo Sum by Descartes - I
                  think therefore I am.
                </td>
              </tr>
              <tr>
                <td data-label="Word/Phrase">Post</td>
                <td data-label="Meaning">After</td>
                <td data-label="Explanation">
                  Often used as a prefix, still used quite often eg. Post mortem
                  - After death, p.m. - Post Meridiem (After midday/Afternoon).
                </td>
              </tr>
              <tr>
                <td data-label="Word/Phrase">Quid pro Quo</td>
                <td data-label="Meaning">This for that</td>
                <td data-label="Explanation">
                  This line is famously repeatedly said by Hannibal Lecter in
                  The Silence of the Lambs.
                </td>
              </tr>
              <tr>
                <td data-label="Word/Phrase">Quis custodiet ipsos custodes</td>
                <td data-label="Meaning">Who guards the guards?</td>
                <td data-label="Explanation">
                  Often used to this day, meaning who is keeping an eye on the
                  people that make our rules.
                </td>
              </tr>
              <tr>
                <td data-label="Word/Phrase">Veni, Vidi, Vici</td>
                <td data-label="Meaning">I came, I saw, I conquered</td>
                <td data-label="Explanation"></td>
              </tr>
              <tr>
                <td data-label="Word/Phrase">Veto</td>
                <td data-label="Meaning">I forbid</td>
                <td data-label="Explanation">
                  The power of veto is a term still used today in organizations,
                  if just one member of the organization has the power to veto,
                  even if everybody else votes for something, the veto vote will
                  override all others
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Animals</h2>
        </div>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Meaning</th>
                <th>Explanation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Name">Apis</td>
                <td data-label="Meaning">Bee</td>
                <td data-label="Explanation">
                  An Apiary is a bee-house/bee-hive, an Apiarist is a peron who
                  studies or keeps bees, Apiculture is the rearing of bees and
                  beekeeping.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Avis</td>
                <td data-label="Meaning">Bird</td>
                <td data-label="Explanation">
                  Avian means relating to birds, eg. Avian flu (bird flu), an
                  Aviary is a large enclosure for confining birds,
                  Aviator/Aviation relating to the flying industry.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Canis</td>
                <td data-label="Meaning">Dog</td>
                <td data-label="Explanation">
                  Canine means relating to dogs. Canis canem edit = "Dog eat
                  dog". Canary as in Canary Islands means "Islands of dogs",
                  Canine teeth.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Equus</td>
                <td data-label="Meaning">Horse</td>
                <td data-label="Explanation">
                  Equine means relating to horses. Equestrianism is horseriding.
                  Nothing to do with mathematical 'equals' which comes from
                  aequus.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Felis</td>
                <td data-label="Meaning">Cat</td>
                <td data-label="Explanation">
                  Feline means relating to cats, there is a cartoon cat called
                  "Felix", Felix also means lucky, like the liquid luck potion
                  in Harry Potter 'Felix Felicis'. Cats are associated with good
                  or bad luck in many cultures.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Formica</td>
                <td data-label="Meaning">Ant</td>
                <td data-label="Explanation">
                  A Formiciary is an ant nest. Formication is the sensation of
                  ants crawling on the skin.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Musca</td>
                <td data-label="Meaning">Fly</td>
                <td data-label="Explanation">
                  Mosquito is the Spanish/Portugese for 'little fly'. No link to
                  similar words like muscavado sugar (fortunately!)
                </td>
              </tr>
              <tr>
                <td data-label="Name">Ovis</td>
                <td data-label="Meaning">Sheep</td>
                <td data-label="Explanation">
                  Ovine means relating to sheep. Not to be confused with the
                  Latin for egg, Ova, which can also have Ovis as one of its
                  forms - pertaining to eggs
                </td>
              </tr>
              <tr>
                <td data-label="Name">Piscis</td>
                <td data-label="Meaning">Fish</td>
                <td data-label="Explanation">
                  Piscine means relating to fish. Piscine is the french for
                  swimming pool. Pisces the Zodiac sign and constellation means
                  fishes. Porpoise has roots in the Latin meaning 'Pigfish'.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Simia</td>
                <td data-label="Meaning">Monkey/Ape</td>
                <td data-label="Explanation">
                  Simian means relating to monkeys/apes
                </td>
              </tr>
              <tr>
                <td data-label="Name">Ursa</td>
                <td data-label="Meaning">Bear</td>
                <td data-label="Explanation">
                  Ursine means relating to bears. The contellations Ursa Major
                  and Ursa Minor mean the Great Bear and Lesser Bear,
                  respectfully.
                </td>
              </tr>
              <tr>
                <td data-label="Name">Vulpes</td>
                <td data-label="Meaning">Fox</td>
                <td data-label="Explanation">
                  Vulpine means relating to foxes. The constellation Vulpecula
                  means Little Fox/The Fox. Vulpix is a small, foxlike Pokemon.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={latinWords.importantFacts}
          classicQuestions={latinWords.classicQuestions}
          exampleQuestions={latinWords.exampleQuestions}
          thoughtsTipsIdeas={latinWords.thoughtsTipsAndIdeas}
          // smallPeep1={latinWords.smallPeep1}
          smallPeep2={latinWords.smallPeep2}
          disclaimer={latinWords.disclaimer}
        />

        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnLatin;
