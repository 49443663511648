import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import { topFivesGeography } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive
      key={topFive.key}
      col1={topFive.col1}
      col2={topFive.col2}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}

function LearnNatureRecords() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Nature Records</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The fastest and biggest animals are often the subject of quiz
                questions, so they make a great addition to your general
                knowledge.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small2.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Fastest Animals</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">Top 5 Land</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topAnimals.fastestAnimalHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topAnimals.fastestLand.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Top 5 Flying</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topAnimals.fastestAnimalHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topAnimals.fastestFlying.map(ArrangeTopFive)}
            </div>
          </div>

          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Heaviest Animals</h2>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">Top 5 World</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topHeaviest.heaviestAnimalHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topHeaviest.heaviestWorld.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Top 5 Land</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topHeaviest.heaviestAnimalHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topHeaviest.heaviestLand.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Top 5 Fish</h2>
              <div className="sidebar-line"></div>
              {topFivesGeography.topHeaviest.heaviestAnimalHeadings.map(
                ArrangeTopFiveHeading
              )}
              {topFivesGeography.topHeaviest.heaviestFish.map(ArrangeTopFive)}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}
export default LearnNatureRecords;
