import React from "react";
import "../css/hangman.css";

function EndMessage(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  // NEED TO FILTER IN HERE TOO!!!
  return (
    <div>
      <div className="end-message-container">
        <h2 className="win-or-lose-text">
          {!props.playable && props.wrongGuesses >= 6
            ? "TOO BAD!"
            : !props.playable &&
              props.correctLetters.filter((l) => l !== " ").join("").length <=
                props.word
                  .split("")
                  .filter((l) => l !== " ")
                  .join("").length
            ? "YOU WIN!"
            : ""}
        </h2>
        <h3 className="reveal-correct-word-text">
          {!props.playable &&
            props.wrongGuesses >= 6 &&
            `The Answer is ${props.word}`}
        </h3>

        {((!props.playable && props.wrongGuesses >= 6) ||
          (!props.playable &&
            props.correctLetters.filter((l) => l !== " ").join("").length <=
              props.word
                .split("")
                .filter((l) => l !== " ")
                .join("").length)) && (
          <button
            onClick={refreshPage}
            className="play-again-btn chalk-writing"
            id="play-again-btn"
          >
            Play Again?
          </button>
        )}
      </div>
    </div>
  );
}

export default EndMessage;
