import React from "react";
import { Link } from "react-router-dom";

function WordsearchCongratsMsg(props) {
  return (
    <>
      {props.timerOn === false ? (
        <div className="congrats-msg-cover-bg">
          <div className="congrats-msg">
            <p>🏆CONGRATULATIONS🏆</p>
            <p>
              YOU WON IN
              <span className="time">
                {("0" + Math.floor(props.time / 3600000)).slice(-2)}:
                {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
                {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}
              </span>
            </p>
            <p className="reveal-count-msg">
              {props.revealCount === 0
                ? "(and you didn't even cheat!)"
                : props.revealCount === 1
                ? `(and you only cheated once!)`
                : `(and you only cheated ${props.revealCount} times!)`}
            </p>
            <div className="wordsearch-congrats-msg-buttons-container">
              <button
                onClick={() => window.location.reload(false)}
                className="wordsearch-congrats-msg-button wordsearch-button-try-again"
              >
                Try Again
              </button>
              <Link
                to="../puzzles-and-games/wordsearches"
                className="wordsearch-congrats-msg-button wordsearch-button-try-another"
              >
                Try Another Wordsearch
              </Link>
              <Link
                to="../puzzles-and-games"
                className="wordsearch-congrats-msg-button wordsearch-button-try-different"
              >
                Try A Different Game
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="wordsearch-timer">
          <span>TIME:</span>
          <span className="time">
            {("0" + Math.floor(props.time / 3600000)).slice(-2)}
          </span>
          :
          <span>{("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}</span>
          :<span>{("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}</span>
        </div>
      )}
    </>
  );
}

export default WordsearchCongratsMsg;
