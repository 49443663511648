import React, { useEffect } from "react";
import "../css/minesweeper.css";
import "../css/style.css";
import { mineSprite } from "../images/MinesweeperWaddauno-svg.png";
import Footer from "./Footer";
import HeaderApp from "./HeaderApp";

function Minesweeeper() {
  // Display
  // Flag button Funcionality
  useEffect(() => {
    const gameCanvas = document.querySelector("#gameCanvas");
    document
      .getElementById("toggleFlagModeButton")
      .addEventListener("click", () => {
        game.toggleFlagMode();
        const button = document.getElementById("toggleFlagModeButton");
        if (game.isFlagMode) {
          button.classList.remove("off");
          button.classList.add("on");
          button.textContent = "Flag Mode: ON";
          gameCanvas.classList.add("flag-cursor");
        } else {
          button.classList.remove("on");
          button.classList.add("off");
          button.textContent = "Flag Mode: OFF";
          gameCanvas.classList.remove("flag-cursor");
        }
      });
    /////

    // Restart Button
    // Set up event listener for the "Restart" button
    document.getElementById("restartButton").addEventListener("click", () => {
      game.startNewGame();
    });
    ////////
    function showWinModal() {
      let winTime = game.timer.element.textContent;
      let modal = document.getElementById("modal");
      let modalHeader = document.getElementById("modal-header");
      modalHeader.textContent = "You Win! Your time: " + winTime;
      modalHeader.classList.add("win");
      modal.classList.add("show-modal");
    }

    function showLoseModal() {
      let modal = document.getElementById("modal");
      let modalHeader = document.getElementById("modal-header");
      modalHeader.textContent = "You Lose!";
      modalHeader.classList.add("lose");
      modal.classList.add("show-modal");
    }

    ////////////////
    const canvas = document.getElementById("gameCanvas");
    const ctx = canvas.getContext("2d");
    const CELLCOLOUR = "#ef5533";
    const REVEALEDCELLCOLOUR = "beige";
    const GRIDLINECOLOUR = "blue";
    const CELLSACROSS = 9;
    const CELLSDOWN = 9;
    const NUMBEROFBOMBS = 10;
    const mineSprite = require("../images/MinesweeperWaddauno-svg.png");

    // Colour Constants for numbers
    const Blue = "#0000FF";
    const Green = "#008000";
    const Red = "#FF0000";
    const Purple = "#800080";
    const Maroon = "#800000";
    const Turquoise = "#40E0D0";
    const Black = "#000000";
    const Gray = "#808080";

    class Grid {
      constructor(ctx, canvas, cellsAcross, cellsDown, numBombs) {
        this.ctx = ctx;
        this.canvas = canvas;
        this.cellsAcross = cellsAcross;
        this.cellsDown = cellsDown;
        this.numBombs = numBombs;
        this.cellWidth = this.canvas.width / this.cellsAcross;
        this.cellHeight = this.canvas.height / this.cellsDown;
        this.cells = this.createGrid();
        this.placeBombs();
        this.initializeNeighbors();
        this.updateBombCounts();
      }

      // ---------------------
      // DRAWING METHODS
      // ---------------------

      drawGridlinesAcross(lineColour) {
        this.ctx.strokeStyle = lineColour;
        for (let i = 0; i <= this.cellsDown; i++) {
          this.drawLine(
            0,
            i * this.cellHeight,
            this.canvas.width,
            i * this.cellHeight
          );
        }
      }

      drawGridlinesDown(lineColour) {
        this.ctx.strokeStyle = lineColour;
        for (let i = 0; i <= this.cellsAcross; i++) {
          this.drawLine(
            i * this.cellWidth,
            0,
            i * this.cellWidth,
            this.canvas.height
          );
        }
      }

      drawLine(x1, y1, x2, y2) {
        this.ctx.beginPath();
        this.ctx.moveTo(x1, y1);
        this.ctx.lineTo(x2, y2);
        this.ctx.stroke();
      }

      drawGridLines(lineColour) {
        this.drawGridlinesAcross(lineColour);
        this.drawGridlinesDown(lineColour);
      }

      drawCells() {
        for (let i = 0; i < this.cellsAcross; i++) {
          for (let j = 0; j < this.cellsDown; j++) {
            this.cells[i][j].drawCell();
          }
        }
      }

      // ---------------------
      // GRID LOGIC METHODS
      // ---------------------

      createGrid(defaultColour = CELLCOLOUR) {
        const grid = [];
        for (let x = 0; x < this.cellsAcross; x++) {
          let row = [];
          for (let y = 0; y < this.cellsDown; y++) {
            const cell = new Cell(
              ctx,
              x,
              y,
              this.cellWidth,
              this.cellHeight,
              defaultColour
            );
            row.push(cell);
          }
          grid.push(row);
        }
        return grid;
      }

      initializeNeighbors() {
        for (let x = 0; x < this.cellsAcross; x++) {
          for (let y = 0; y < this.cellsDown; y++) {
            this.cells[x][y].findNeighbors(this);
          }
        }
      }

      updateBombCounts() {
        for (let x = 0; x < this.cellsAcross; x++) {
          for (let y = 0; y < this.cellsDown; y++) {
            this.cells[x][y].countNeighboringBombs();
          }
        }
      }

      placeBombs() {
        let allPositions = [];

        // Create a list of all positions
        for (let i = 0; i < this.cellsAcross; i++) {
          for (let j = 0; j < this.cellsDown; j++) {
            allPositions.push([i, j]);
          }
        }

        // Shuffle the array
        allPositions.sort(() => Math.random() - 0.5);

        // Take the first NUMBEROFBOMBS positions and place bombs
        for (let i = 0; i < this.numBombs; i++) {
          let [x, y] = allPositions[i];
          this.cells[x][y].setHasBomb();
        }
      }

      revealAllBombs() {
        for (let i = 0; i < this.cellsAcross; i++) {
          for (let j = 0; j < this.cellsDown; j++) {
            if (this.cells[i][j].containsBomb) {
              this.cells[i][j].isRevealed = true;
              this.cells[i][j].drawCell();
            }
          }
        }
      }

      getFlagCount() {
        let flagCount = 0;
        for (let i = 0; i < this.cellsAcross; i++) {
          for (let j = 0; j < this.cellsDown; j++) {
            if (this.cells[i][j].hasFlag) {
              flagCount++;
            }
          }
        }
        return flagCount;
      }

      checkWin() {
        for (let i = 0; i < this.cellsAcross; i++) {
          for (let j = 0; j < this.cellsDown; j++) {
            let cell = this.cells[i][j];
            // If the cell doesn't contain a bomb and isn't revealed, return false
            if (!cell.containsBomb && !cell.isRevealed) {
              return false;
            }
          }
        }
        // If all non-bomb cells are revealed, return true
        game.timer.stop();
        return true;
      }
    }

    class Cell {
      constructor(ctx, x, y, cellWidth, cellHeight, cellColour = CELLCOLOUR) {
        this.ctx = ctx;
        this.x = x; // x-coordinate of the top-left corner of the cell
        this.y = y; // y-coordinate of the top-left corner of the cell
        this.cellWidth = cellWidth;
        this.cellHeight = cellHeight;
        this.cellColour = cellColour;
        this.originalCellColour = this.cellColour;
        this.revealedCellColour = REVEALEDCELLCOLOUR;
        this.neighbors = [];
        this.containsBomb = false;
        this.isRevealed = false;
        this.hasFlag = false;
        this.neighboringBombCount = 0;
        this.triggered = false;
        // Image loading
        this.mineImage = new Image();
        this.mineImage.src = mineSprite;
        this.mineImage.onload = () => {
          this.imageLoaded = true;
        };
      }

      findNeighbors(grid) {
        for (let dx = -1; dx <= 1; dx++) {
          for (let dy = -1; dy <= 1; dy++) {
            // Skip the cell itself
            if (dx === 0 && dy === 0) continue;

            let neighborX = this.x + dx;
            let neighborY = this.y + dy;

            // Check bounds
            if (
              neighborX >= 0 &&
              neighborX < grid.cellsAcross &&
              neighborY >= 0 &&
              neighborY < grid.cellsDown
            ) {
              this.neighbors.push(grid.cells[neighborX][neighborY]);
            }
          }
        }
      }

      countNeighboringBombs() {
        this.neighboringBombCount = 0; // Reset the count
        for (let i = 0; i < this.neighbors.length; i++) {
          if (this.neighbors[i].containsBomb) {
            this.neighboringBombCount++;
          }
        }
      }
      drawCell() {
        if (this.hasFlag) {
          this.ctx.font = "bold 32px Arial";
          this.ctx.fillStyle = "black";
          this.ctx.textAlign = "center";
          this.ctx.textBaseline = "middle";
          this.ctx.fillText(
            "🚩",
            (this.x + 0.5) * this.cellWidth,
            (this.y + 0.5) * this.cellHeight
          );
        } else if (this.isRevealed && this.containsBomb && this.triggered) {
          this.ctx.fillStyle = "red"; // for triggered bomb cell
          this.ctx.fillRect(
            this.x * this.cellWidth,
            this.y * this.cellHeight,
            this.cellWidth,
            this.cellHeight
          );
          if (this.imageLoaded) {
            this.ctx.drawImage(
              this.mineImage,
              this.x * this.cellWidth,
              this.y * this.cellHeight,
              this.cellWidth,
              this.cellHeight
            );
          }
        } else if (this.isRevealed && this.containsBomb) {
          const img = new Image();
          if (this.imageLoaded) {
            this.ctx.drawImage(
              this.mineImage,
              this.x * this.cellWidth,
              this.y * this.cellHeight,
              this.cellWidth,
              this.cellHeight
            );
          }
        } else if (this.isRevealed) {
          this.ctx.fillStyle = this.revealedCellColour; // for revealed empty cells
          this.ctx.fillRect(
            this.x * this.cellWidth,
            this.y * this.cellHeight,
            this.cellWidth,
            this.cellHeight
          );
        } else {
          this.ctx.fillStyle = this.cellColour;
          this.ctx.fillRect(
            this.x * this.cellWidth,
            this.y * this.cellHeight,
            this.cellWidth,
            this.cellHeight
          );
        }

        if (
          this.isRevealed &&
          !this.containsBomb &&
          this.neighboringBombCount > 0
        ) {
          const numberColors = [
            Blue,
            Green,
            Red,
            Purple,
            Maroon,
            Turquoise,
            Black,
            Gray,
          ];
          this.ctx.font = "20px Arial";
          this.ctx.fillStyle = numberColors[this.neighboringBombCount - 1];
          this.ctx.textAlign = "center";
          this.ctx.textBaseline = "middle";
          this.ctx.fillText(
            this.neighboringBombCount.toString(),
            (this.x + 0.5) * this.cellWidth,
            (this.y + 0.5) * this.cellHeight
          );
        }
      }

      revealCell(grid) {
        // If the cell is already revealed, do nothing
        if (this.isRevealed) {
          return;
        }

        // If the cell is flagged, do nothing and return early
        if (this.hasFlag) {
          return;
        }
        // Mark the cell as revealed
        this.isRevealed = true;

        // If the cell contains a bomb, reveal all bombs and end the game
        if (this.containsBomb) {
          this.triggered = true;
          grid.revealAllBombs();
          game.endGame();
          showLoseModal(); // show loss message
          return;
        }

        // If the cell has no neighboring bombs, recursively reveal its neighbors
        if (this.neighboringBombCount === 0) {
          for (let i = 0; i < this.neighbors.length; i++) {
            // Pass the grid object to the recursive call
            this.neighbors[i].revealCell(grid);
          }
        }

        // Optionally, you can redraw the grid here or in the calling function
        grid.drawCells();
        grid.drawGridLines(GRIDLINECOLOUR);
      }

      toggleFlag() {
        // Only toggle flag if the cell is not already revealed
        if (!this.isRevealed) {
          this.hasFlag = !this.hasFlag;
          game.updateBombCounter();
        }
      }
      setHasBomb() {
        this.containsBomb = true;
      }
    }

    class Game {
      constructor() {
        this.isGameOver = false;
        this.isFlagMode = false; // false by default
        this.grid = new Grid(ctx, canvas, CELLSACROSS, CELLSDOWN);
      }

      endGame() {
        this.timer.stop();
        this.isGameOver = true;
        // Optionally, you can display a game-over message here.
      }

      startNewGame() {
        if (this.timer) {
          this.timer.stop();
          this.timer.reset();
        }
        this.timer = new Timer("timer");

        this.timer.start();
        this.isGameOver = false;
        this.isFlagMode = false; // Reset flag mode as well, if needed
        this.grid = new Grid(
          ctx,
          canvas,
          this.cellsAcross,
          this.cellsDown,
          this.numBombs
        ); // Reset the grid
        this.grid.drawCells();
        this.grid.drawGridLines(GRIDLINECOLOUR);
        let modal = document.getElementById("modal");
        modal.classList.remove("show-modal"); // Hide the modal
        this.updateBombCounter();
      }
      setDifficulty(difficulty) {
        document.getElementById("warning").className = "warning-hidden"; // Hide warning

        if (difficulty === "hard") {
          if (window.screen.width < 920 || window.screen.height < 720) {
            document
              .getElementById("warning")
              .classList.remove("warning-hidden");
            document.getElementById("warning").classList.add("warning-visible");
            return;
          } else {
            canvas.width = 1280; // Doubling the width for hard mode
          }
        } else {
          canvas.width = 640; // Set to default width for other modes
        }
        switch (difficulty) {
          case "easy":
            this.cellsDown = 9;
            this.cellsAcross = 9;
            this.numBombs = 10;
            break;
          case "medium":
            this.cellsDown = 16;
            this.cellsAcross = 16;
            this.numBombs = 40;
            break;
          case "hard":
            this.cellsDown = 16;
            this.cellsAcross = 30;
            this.numBombs = 99;
            break;
          default:
            console.error("Unknown difficulty level:", difficulty);
            return;
        }
        this.startNewGame(this.numBombs); // Start a new game with the updated settings
        this.updateBombCounter();
      }

      toggleFlagMode() {
        this.isFlagMode = !this.isFlagMode;
      }

      updateBombCounter() {
        const bombCounterElement = document.getElementById("bombCounter");
        bombCounterElement.textContent =
          this.numBombs - this.grid.getFlagCount();
      }
    }

    class Timer {
      constructor(elementId) {
        this.element = document.getElementById(elementId);
        this.intervalId = null;
        this.minutes = 0;
        this.seconds = 0;
      }

      start() {
        this.intervalId = setInterval(this.update.bind(this), 1000);
      }

      stop() {
        clearInterval(this.intervalId);
      }

      reset() {
        this.minutes = 0;
        this.seconds = 0;
        this.render();
      }

      update() {
        this.seconds++;
        if (this.seconds === 60) {
          this.minutes++;
          this.seconds = 0;
        }
        this.render();
      }

      render() {
        this.element.textContent = `${this.minutes
          .toString()
          .padStart(2, "0")}:${this.seconds.toString().padStart(2, "0")}`;
      }
    }

    let game; // Declare game variable globally

    game = new Game(); // Initialize game object once the window has loaded
    game.setDifficulty("easy"); // Set default difficulty to easy

    document
      .getElementById("difficulty")
      .addEventListener("click", function (event) {
        const target = event.target;
        if (target.tagName.toLowerCase() === "button") {
          game.setDifficulty(target.textContent.toLowerCase());
        }
      });

    // Draw the entire grid with a single function call:

    canvas.addEventListener("click", handleMouseClick);

    function handleMouseClick(event) {
      let rect = canvas.getBoundingClientRect();
      let scalingFactor = canvas.width / rect.width;
      let x = (event.clientX - rect.left) * scalingFactor;
      let y = (event.clientY - rect.top) * scalingFactor;
      let clickedCellX = Math.floor(x / game.grid.cellWidth);
      let clickedCellY = Math.floor(y / game.grid.cellHeight);
      if (!game.isGameOver) {
        if (game.isFlagMode) {
          // Only toggle flag if the cell is not already revealed
          if (!game.grid.cells[clickedCellX][clickedCellY].isRevealed) {
            game.grid.cells[clickedCellX][clickedCellY].toggleFlag();
          }
        } else {
          game.grid.cells[clickedCellX][clickedCellY].revealCell(game.grid);
        }
        game.grid.drawCells();
        game.grid.drawGridLines(GRIDLINECOLOUR);
      }
      if (game.grid.checkWin()) {
        showWinModal();
      }
      //   console.log(clickedCellX, clickedCellY);
      //   console.log(
      //     game.grid.cells[clickedCellX][clickedCellY].neighboringBombCount
      //   );

      // Now, you can use x and y to determine which cell was clicked.
    }

    // grid.populateGridWithCells();
    // These lines reset the modal header class list whenever a new game starts or the grid is redrawn
    let modalHeader = document.getElementById("modal-header");
    modalHeader.classList.remove("win", "lose");
    game.grid.drawCells();
    game.grid.drawGridLines(GRIDLINECOLOUR);

    // console.log(game.grid.cells);
  }, []);

  return (
    <div className="push-footer hero">
      <HeaderApp />
      <div className="minesweeper-container">
        <div id="infoDivs">
          <div className="infoDiv">
            <h3>Timer</h3>
            <div id="timer"></div>
          </div>
          <div className="infoDiv">
            <h3>Bombs Left</h3>
            <div id="bombCounter"></div>
          </div>
        </div>

        <canvas id="gameCanvas" width="640" height="640"></canvas>
        <button id="toggleFlagModeButton" className="off">
          Flag Mode: OFF
        </button>
        <br></br>
        <label htmlFor="difficulty">Select Difficulty:</label>
        <div id="difficulty" className="pairs-btn-container">
          <button className="pairs-easy-btn">Easy</button>
          <button className="pairs-medium-btn">Medium</button>
          <button className="pairs-hard-btn">Hard</button>
        </div>
        <div id="warning" className="warning-hidden">
          Hard level requires a larger screen. Mobile devices are too small for
          a good experience.
        </div>

        <div id="modal" className="modal">
          <div id="modal-header" className="modal-header"></div>
          <button id="restartButton" className="restart-button">
            Restart
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Minesweeeper;
