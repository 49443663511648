import React from "react";
import "./css/covered.css";
import "./css/questionbank.css";

function Covered2() {
  return (
    <div>
      <div className="covered2">Reveal</div>
    </div>
  );
}

export default Covered2;
