import React from "react";

function PairCard(props) {
  function handleClick() {
    if (!props.disabled) {
      props.handleChoice(props.card);
    }
  }
  return (
    <div className="pairs-card">
      <div className={props.flipped ? "flipped" : ""}>
        <img
          src={props.src}
          className="pairs-card-front"
          alt="pairs game card front"
        />
        <img
          src={require("../images/cardImages/CardBack.png")}
          className="pairs-card-back"
          alt="pairs game card back"
          onClick={handleClick}
        />
      </div>
    </div>
  );
}

export default PairCard;
