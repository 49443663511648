import React from "react";

function SportingNickname(props) {
  return (
    <section>
      <section className="alter-ego-list">
        <p className="alter-ego-original">{props.realname}</p>
        <p className="alter-ego-new">{props.nickname}</p>
      </section>
    </section>
  );
}

export default SportingNickname;
