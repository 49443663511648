import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnFootballGrounds() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Football Home Grounds</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The home grounds of the English Football League (EFL) teams, and
                some teams from The Bundesliga, La Liga, Ligue 1, and Serie A.
                If the stadium is known by a different name for sponsorship
                purposes this is included, and also previous names for the
                stadium or their previous home ground are listed if relevant.
                <br />
                They are grouped into their current league, correct as of the
                2022/23 season, and all information is correct as of the start
                of that season.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small18.png")}
              alt="cartoon character with black hair and orange tshirt"
            />
          </div>
        </div>

        <div className="container-xs-padding">
          <h2 className="category-subtitle">The Premiership</h2>
        </div>

        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">Arsenal</td>
                <td data-label="Current Home Ground">Ashburton Grove</td>
                <td data-label="Current Commercial Name">
                  The Emirates Stadium
                </td>
                <td data-label="Previous Ground">Highbury</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Aston Villa</td>
                <td data-label="Current Home Ground">Villa Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Bournemouth</td>
                <td data-label="Current Home Ground">Dean Court</td>
                <td data-label="Current Commercial Name">Vitality Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">
                  Goldsands Stadium / Fitness First Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Brentford</td>
                <td data-label="Current Home Ground">
                  Brentford Community Stadium
                </td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Griffin Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Brighton & Hove Albion</td>
                <td data-label="Current Home Ground">Falmer Stadium</td>
                <td data-label="Current Commercial Name">Amex Stadium</td>
                <td data-label="Previous Ground">
                  Withdean Stadium / Goldstone Ground
                </td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Chelsea</td>
                <td data-label="Current Home Ground">Stamford Bridge</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Crystal Palace</td>
                <td data-label="Current Home Ground">Selhurst Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Everton</td>
                <td data-label="Current Home Ground">Goodison Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Anfield (until 1892)</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Fulham</td>
                <td data-label="Current Home Ground">Craven Cottage</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Leeds Utd</td>
                <td data-label="Current Home Ground">Elland Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Leicester City</td>
                <td data-label="Current Home Ground">King Power Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td>Filbert Street</td>
                <td data-label="Previous Commercial Name(s)">
                  Walkers Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Liverpool</td>
                <td data-label="Current Home Ground">Anfield</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Manchester City</td>
                <td data-label="Current Home Ground">
                  City of Manchester Stadium
                </td>
                <td data-label="Current Commercial Name">The Etihad Stadium</td>
                <td data-label="Previous Ground">Maine Road</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Manchester Utd</td>
                <td data-label="Current Home Ground">Old Trafford</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Newcastle Utd</td>
                <td data-label="Current Home Ground">St James&#39; Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">
                  Sports Direct Arena
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Nottingham Forest</td>
                <td data-label="Current Home Ground">City Ground</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Southampton</td>
                <td data-label="Current Home Ground">St Mary&#39;s</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">The Dell</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Tottenham Hotspur</td>
                <td data-label="Current Home Ground">
                  Tottenham Hotspur Stadium
                </td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">White Hart Lane</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">West Ham Utd</td>
                <td data-label="Current Home Ground">
                  London Stadium (2012 Olympic Stadium)
                </td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">
                  Boleyn Ground (aka Upton Park)
                </td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Wolverhampton Wanderers</td>
                <td data-label="Current Home Ground">Molineux</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">The Championship</h2>
        </div>

        <div className="table-container">
          <table class="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">Birmingham City</td>
                <td data-label="Current Home Ground">St Andrew&#39;s</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">
                  St Andrew&#39;s Trillion Trophy Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Blackburn Rovers</td>
                <td data-label="Current Home Ground">Ewood Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Blackpool</td>
                <td data-label="Current Home Ground">Bloomfield Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Bristol City</td>
                <td data-label="Current Home Ground">Ashton Gate</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Burnley</td>
                <td data-label="Current Home Ground">Turf Moor</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Cardiff City</td>
                <td data-label="Current Home Ground">Cardiff City Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Ninian Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Coventry City</td>
                <td data-label="Current Home Ground">Coventry Arena</td>
                <td data-label="Current Commercial Name">
                  Coventry Building Society Arena
                </td>
                <td data-label="Previous Ground">Highfield Road</td>
                <td data-label="Previous Commercial Name(s)">Ricoh Arena</td>
              </tr>
              <tr>
                <td data-label="Club Name">Huddesfield Town</td>
                <td data-label="Current Home Ground">Kirklees Stadium</td>
                <td data-label="Current Commercial Name">
                  John Smith&#39;s Stadium
                </td>
                <td data-label="Previous Ground">Leeds Road</td>
                <td data-label="Previous Commercial Name(s)">
                  Galpharm Stadium / Alfred McAlpine Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Hull City</td>
                <td data-label="Current Home Ground">Hull City Stadium</td>
                <td data-label="Current Commercial Name">MKM Stadium</td>
                <td data-label="Previous Ground">Boothferry Park</td>
                <td data-label="Previous Commercial Name(s)">KC Stadium</td>
              </tr>
              <tr>
                <td data-label="Club Name">Luton Town</td>
                <td data-label="Current Home Ground">Kenilworth Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Middlesborough</td>
                <td data-label="Current Home Ground">Riverside</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Ayresome Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Millwall</td>
                <td data-label="Current Home Ground">The Den</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Norwich City</td>
                <td data-label="Current Home Ground">Carrow Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Preston North End</td>
                <td data-label="Current Home Ground">Deepdale</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Queens Park Rangers</td>
                <td data-label="Current Home Ground">Loftus Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">White City Stadium</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Reading</td>
                <td data-label="Current Home Ground">Madejski Stadium</td>
                <td data-label="Current Commercial Name">
                  Select Car Leasing Stadium
                </td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">Elm Park</td>
              </tr>
              <tr>
                <td data-label="Club Name">Rotherham Utd</td>
                <td data-label="Current Home Ground">New York Stadium</td>
                <td data-label="Current Commercial Name">
                  AESSEAL New York Stadium
                </td>
                <td data-label="Previous Ground">Millmoor</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Sheffield Utd</td>
                <td data-label="Current Home Ground">Bramall Lane</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Stoke City</td>
                <td data-label="Current Home Ground">Britannia Stadium</td>
                <td data-label="Current Commercial Name">bet365 Stadium</td>
                <td data-label="Previous Ground">Victoria Ground</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Sunderland</td>
                <td data-label="Current Home Ground">Stadium of Light</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Roker Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Swansea</td>
                <td data-label="Current Home Ground">Liberty Stadium</td>
                <td data-label="Current Commercial Name">
                  Swansea.com Stadium
                </td>
                <td data-label="Previous Ground">Vetch Field</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Watford</td>
                <td data-label="Current Home Ground">Vicarage Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">West Bromwich Albion</td>
                <td data-label="Current Home Ground">The Hawthorns</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Wigan Athletic</td>
                <td data-label="Current Home Ground">Wigan Athletic Stadium</td>
                <td data-label="Current Commercial Name">DW Stadium</td>
                <td data-label="Previous Ground">Springfield Park</td>
                <td data-label="Previous Commercial Name(s)">JJB Stadium</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">League One</h2>
        </div>
        <div className="table-container">
          <table class="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">Accrington Stanley</td>
                <td data-label="Current Home Ground">Crown Ground</td>
                <td data-label="Current Commercial Name">Wham Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">
                  Fraser Eagle Stadium / Interlink Express Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Barnsley</td>
                <td data-label="Current Home Ground">Oakwell</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Bolton Wanderers</td>
                <td data-label="Current Home Ground">
                  Bolton Wanderers Stadium
                </td>
                <td data-label="Current Commercial Name">
                  University of Bolton Stadium
                </td>
                <td data-label="Previous Ground">Burnden Park</td>
                <td data-label="Previous Commercial Name(s)">
                  Reebok Stadium / Macron Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Bristol Rovers</td>
                <td data-label="Current Home Ground">Memorial Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">
                  Eastville Stadium / Twerton Park
                </td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Burton Albion</td>
                <td data-label="Current Home Ground">Pirelli Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Eton Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Cambridge Utd</td>
                <td data-label="Current Home Ground">Abbey Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">
                  R. Costings Abbey Stadium / Cambs Glass Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Charlton Athletic</td>
                <td data-label="Current Home Ground">The Valley</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Cheltenham Town</td>
                <td data-label="Current Home Ground">Whaddon Road</td>
                <td data-label="Current Commercial Name">
                  Completely-Suzuki Stadium
                </td>
                <td data-label="Previous Ground">Victory Sports Ground</td>
                <td data-label="Previous Commercial Name(s)">
                  Abbey Business Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Derby County</td>
                <td data-label="Current Home Ground">Pride Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">The Baseball Ground</td>
                <td data-label="Previous Commercial Name(s)">iPro Stadium</td>
              </tr>
              <tr>
                <td data-label="Club Name">Exeter City</td>
                <td data-label="Current Home Ground">St James Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Fleetwood Town</td>
                <td data-label="Current Home Ground">Highbury Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Forest Green Rovers</td>
                <td data-label="Current Home Ground">The New Lawn</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">The Lawn Ground</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Ipswich Town</td>
                <td data-label="Current Home Ground">Portman Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Lincoln City</td>
                <td data-label="Current Home Ground">Sincil Bank</td>
                <td data-label="Current Commercial Name">LNER Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Milton Keynes Dons</td>
                <td data-label="Current Home Ground">Stadium MK</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">National Hockey Stadium</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Morecambe</td>
                <td data-label="Current Home Ground">The Mazuma Stadium</td>
                <td data-label="Current Commercial Name">Globe Arena</td>
                <td data-label="Previous Ground">Christie Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Oxford Utd</td>
                <td data-label="Current Home Ground">Kassam Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Manor Ground</td>
                <td data-label="Previous Commercial Name(s)">-THE U&#39;S</td>
              </tr>
              <tr>
                <td data-label="Club Name">Peterborough Utd</td>
                <td data-label="Current Home Ground">London Road Stadium</td>
                <td data-label="Current Commercial Name">
                  Weston Homes Stadium
                </td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Plymouth Argyle</td>
                <td data-label="Current Home Ground">Home Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Port Vale</td>
                <td data-label="Current Home Ground">Vale Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">The Old Recreation Ground</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Portsmouth</td>
                <td data-label="Current Home Ground">Fratton Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Sheffield Wednesday</td>
                <td data-label="Current Home Ground">Hillsborough</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Shrewsbury Town</td>
                <td data-label="Current Home Ground">New Meadow</td>
                <td data-label="Current Commercial Name">
                  Montgomery Waters Meadow
                </td>
                <td data-label="Previous Ground">Gay Meadow</td>
                <td data-label="Previous Commercial Name(s)">
                  Greenhous Meadow
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Wycombe Wanderers</td>
                <td data-label="Current Home Ground">Adams Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Loakes Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="container-xs-padding">
          <h2 className="category-subtitle">League Two</h2>
        </div>
        <div className="table-container">
          <table class="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">Barrow</td>
                <td data-label="Current Home Ground">Holker Street</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Bradford City</td>
                <td data-label="Current Home Ground">Valley Parade</td>
                <td data-label="Current Commercial Name">
                  Utilita Energy Stadium
                </td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">
                  Northern Commercials Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Carlisle Utd</td>
                <td data-label="Current Home Ground">Brunton Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Colchester Utd</td>
                <td data-label="Current Home Ground">
                  Colchester Community Stadium
                </td>
                <td data-label="Current Commercial Name">
                  JobServe Community Stadium
                </td>
                <td data-label="Previous Ground">Layer Road</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Crawley Town</td>
                <td data-label="Current Home Ground">Broadfield Stadium</td>
                <td data-label="Current Commercial Name">
                  The People’s Pension Stadium
                </td>
                <td data-label="Previous Ground">Town Mead Stadium</td>
                <td data-label="Previous Commercial Name(s)">
                  The Checkatrade.com Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Crewe Alexandra</td>
                <td data-label="Current Home Ground">
                  Gresty Road AKA Alexandra Stadium
                </td>
                <td data-label="Current Commercial Name">Mornflake Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Doncaster Rovers</td>
                <td data-label="Current Home Ground">Keepmoat Stadium</td>
                <td data-label="Current Commercial Name">Eco-Power Stadium</td>
                <td data-label="Previous Ground">Belle Vue</td>
                <td data-label="Previous Commercial Name(s)">Earth Stadium</td>
              </tr>
              <tr>
                <td data-label="Club Name">Gillingham</td>
                <td data-label="Current Home Ground">Priestfield Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Grimsby Town</td>
                <td data-label="Current Home Ground">Blundell Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Harrogate Town</td>
                <td data-label="Current Home Ground">Wetherby Road</td>
                <td data-label="Current Commercial Name">EnviroVent Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Hartlepool Utd</td>
                <td data-label="Current Home Ground">Victoria Park</td>
                <td data-label="Current Commercial Name">
                  The Suit Direct Stadium
                </td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">
                  Northern Gas and Power Stadium / Super 6 Stadium
                </td>
              </tr>
              <tr>
                <td data-label="Club Name">Leyton Orient</td>
                <td data-label="Current Home Ground">Brisbane Road</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Mansfield Town</td>
                <td data-label="Current Home Ground">Field Mill</td>
                <td data-label="Current Commercial Name">One Call Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Newport County</td>
                <td data-label="Current Home Ground">Rodney Parade</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Northampton Town</td>
                <td data-label="Current Home Ground">Sixfields Stadium</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">County Ground</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Rochdale</td>
                <td data-label="Current Home Ground">Spotland Stadium</td>
                <td data-label="Current Commercial Name">Crown Oil Arena</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Salford City</td>
                <td data-label="Current Home Ground">Moor Lane</td>
                <td data-label="Current Commercial Name">Peninsula Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Stevenage</td>
                <td data-label="Current Home Ground">Broadhall Way</td>
                <td data-label="Current Commercial Name">The Lamex Stadium</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Stockport County</td>
                <td data-label="Current Home Ground">Edgeley Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Sutton Utd</td>
                <td data-label="Current Home Ground">Gander Green Lane</td>
                <td data-label="Current Commercial Name">
                  VBS Community Stadium
                </td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Swindon Town</td>
                <td data-label="Current Home Ground">The County Ground</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Tranmere Rovers</td>
                <td data-label="Current Home Ground">Prenton Park</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Walsall</td>
                <td data-label="Current Home Ground">Bescot Stadium</td>
                <td data-label="Current Commercial Name">
                  Poundland Bescot Stadium
                </td>
                <td data-label="Previous Ground">Fellows Park</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">AFC Wimbledon</td>
                <td data-label="Current Home Ground">Plough Lane</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Kingsmeadow</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">The Bundesliga - Germany</h2>
        </div>
        <div className="table-container">
          <table class="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">Bayer 04 Leverkusen</td>
                <td data-label="Current Home Ground">BayArena</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Ulrich-Haberland-Stadion</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Bayern Munich</td>
                <td data-label="Current Home Ground">Fußball Arena München</td>
                <td data-label="Current Commercial Name">Allianz Arena</td>
                <td data-label="Previous Ground">Olympiastadion</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Borussia Dortmund</td>
                <td data-label="Current Home Ground">Westfalenstadion</td>
                <td data-label="Current Commercial Name">Signal Iduna Park</td>
                <td data-label="Previous Ground">Stadion Rote Erde</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Schalke 04</td>
                <td data-label="Current Home Ground">Arena AufSchalke</td>
                <td data-label="Current Commercial Name">Veltins-Arena</td>
                <td data-label="Previous Ground">Parkstadion</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="container-xs-padding">
          <h2 className="category-subtitle">La Liga - Spain</h2>
        </div>
        <div className="table-container">
          <table class="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">Atletico Madrid</td>
                <td data-label="Current Home Ground">Estadio Metropolitano</td>
                <td data-label="Current Commercial Name">
                  Cívitas Metropolitano
                </td>
                <td data-label="Previous Ground">Vicente Calderón stadium</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Barcelona</td>
                <td data-label="Current Home Ground">Camp Nou</td>
                <td data-label="Current Commercial Name">Spotify Camp Nou</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Real Madrid</td>
                <td data-label="Current Home Ground">Santiago Bernabéu</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Sevilla</td>
                <td data-label="Current Home Ground">
                  Estadio Ramon Sanchez-Pizjuan
                </td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Valencia</td>
                <td data-label="Current Home Ground">Mestalla</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Villarreal</td>
                <td data-label="Current Home Ground">Estadio de la Cerámica</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">El Madrigal</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Ligue 1 - France</h2>
        </div>
        <div className="table-container">
          <table class="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">AS Monaco</td>
                <td data-label="Current Home Ground">Stade Louis II</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Olympique de Marseille</td>
                <td data-label="Current Home Ground">Stade Vélodrome</td>
                <td data-label="Current Commercial Name">Orange Vélodrome</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Olympique Lyonnais (Lyon)</td>
                <td data-label="Current Home Ground">Parc OL</td>
                <td data-label="Current Commercial Name">Groupama Stadium</td>
                <td data-label="Previous Ground">Stade de Gerland</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Paris Saint-Germain</td>
                <td data-label="Current Home Ground">Parc des Princes</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container-xs-padding">
          <h2 className="category-subtitle">Serie A - Italy</h2>
        </div>
        <div className="table-container">
          <table class="table">
            <thead>
              <tr>
                <th>Club Name</th>
                <th>Current Home Ground</th>
                <th>Current Commercial Name</th>
                <th>Previous Ground</th>
                <th>Previous Commercial Name(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Club Name">A.C. Milan</td>
                <td data-label="Current Home Ground">
                  Giuseppe Meazza Stadium / San Siro
                </td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Inter Milan</td>
                <td data-label="Current Home Ground">
                  Giuseppe Meazza Stadium / San Siro
                </td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">S.S.C. Napoli</td>
                <td data-label="Current Home Ground">
                  Stadio Diego Armando Maradona
                </td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">Stadio San Paolo</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">Juventus</td>
                <td data-label="Current Home Ground">Juventus Stadium</td>
                <td data-label="Current Commercial Name">Allianz Stadium</td>
                <td data-label="Previous Ground">Stadio delle Alpi</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">S.S. Lazio</td>
                <td data-label="Current Home Ground">Stadio Olimpico</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">A.S. Roma</td>
                <td data-label="Current Home Ground">Stadio Olimpico</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
              <tr>
                <td data-label="Club Name">ACF Fiorentina</td>
                <td data-label="Current Home Ground">Stadio Artemio Franchi</td>
                <td data-label="Current Commercial Name">-</td>
                <td data-label="Previous Ground">-</td>
                <td data-label="Previous Commercial Name(s)">-</td>
              </tr>
            </tbody>
          </table>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnFootballGrounds;
