import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Alterego from "./Alterego";
import { acronymsAndInitialisms } from "../../LearnSpecificCategoryDetailsFour";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeAlterEgos(ego) {
  return <Alterego key={ego.id} original={ego.aOri} new={ego.meaning} />;
}

function LearnAcronyms() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Acronyms, Initialisms & Mnemonics</h1>
        <div className="container">
          {/* <h2 className="category-subtitle centre">Acronyms & Initialisms</h2> */}
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">{acronymsAndInitialisms.intro}</p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small18.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">
            Computer, internet and technology
          </h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.computerInternetTechnology.map(
              ArrangeAlterEgos
            )}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Band Names</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.popCulture.map(ArrangeAlterEgos)}
            <div className="line-subtitle"></div>
          </div>
          <h2 className="category-subtitle centre">Chat & Gaming</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.chatAndGaming.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Commonly Used</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.commonlyUsed.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Brands & Organisations</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.brandsAndOrganisations.map(
              ArrangeAlterEgos
            )}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Medical</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.medical.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Military & Government</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.militaryAndGovernment.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Science & Maths</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.scienceAndMaths.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Sport</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.sport.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Other</h2>
          <div className="alter-ego-list-container">
            {acronymsAndInitialisms.other.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Poems</h2>
          <p className="c-and-e-subtitle centre">
            As well as the acronyms and initialisms there are some poems to help
            remember dates, patterns and facts.
          </p>
          {acronymsAndInitialisms.poems.map((p, i) => (
            <p key={i + p.slice(0, 10)} className="body-text">
              {p}
            </p>
          ))}

          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Mnemonics</h2>
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                A mnemonic is a pattern of words or letters which assists in
                remembering something. Mnemonic is pronounced with a silent M,
                to rhyme with 'demonic'.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small16.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">PLANETS</h2>
          <p className="body-text">
            In 2006 Pluto was reclassified as a dwarf planet reducing the count
            of known planets in our Solar System to 8 and thus ruining the
            mnemonic
            <br />
            <br />
            <span className="mnemonic">
              My Very Easy Method Just Speeds Up Naming Planets
            </span>
            <br />
            <br />
            which represented 'Mercury Venus Earth Mars Jupiter Saturn Uranus
            Neptune Pluto' - the names of the planets in order, however, a new
            mnemonic to account for this change is
            <br />
            <br />
            <span className="mnemonic">
              My Very Educated Mother Just Served Us Noodles
            </span>
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">AMERICAN BANKNOTES</h2>

          <p className="body-text">
            $1 Washington, $2 Jefferson, $5 Lincoln, $10 Hamilton, $20 Jackson,
            $50 Grant, $100 Franklin
            <br />
            <br />
            <span className="mnemonic">When Jeff Left Home Jack Got Fat</span>
          </p>

          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">
            BOOKS OF THE BIBLE - OLD TESTAMENT
          </h2>

          <p className="body-text">
            The first 5 books of the Bible are Genesis, Exodus, Leviticus,
            Numbers, Deuteronomy
            <br />
            <br />
            <span className="mnemonic">God Equals Light, Not Darkness</span>
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">
            BOOKS OF THE BIBLE - NEW TESTAMENT
          </h2>

          <p className="body-text">
            The first 6 books of the New Testament are Matthew, Mark, Luke,
            John, Acts, Romans. This one is not exactly a mnemomic, it's more a
            rhyme, however it has been put in this section so that is next to
            the old testament mnemonic
            <br />
            <br />
            <span className="mnemonic">
              Matthew, Mark, Luke and John, Acts and Romans follow on
            </span>
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">
            THE BIBLE - PLAGUES OF EGYPT
          </h2>

          <p className="body-text">
            The 10 plagues appear in the book of Exodus. They are: Water to
            Blood, Frogs, Gnats, Flies, Livestock, Boils, Hail, Locust,
            Darkness, Death of the Firstborn
            <br />
            <br />
            <span className="mnemonic">
              What Funny Granny Fries Livers But Hates Livers Done Dryly
            </span>
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">
            THE GREAT LAKES (Canada/US Border)
          </h2>

          <p className="body-text">
            In this case the mnemonic should only be 3 words long, however to
            make it clear that there are 5 lakes in the list, the words
            'Superman' and 'Everyone' are split, the mnemonic below represents
            the great lakes in size order from largest to smallest Superior,
            Michigan, Huron, Erie, Ontario
            <br />
            <br />
            <span className="mnemonic">Super Man Helps Every One</span>
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">ORDER OF TAXONOMY</h2>

          <p className="body-text">
            The order of taxonomy is the way living things are organised
            scientifically. Kingdom, Phylum, Class, Order, Family, Genus,
            Species. The list gets more specific as you go along. For example
            Kingdom could be Animalia (animals) through the levels to the
            Species Homo sapiens (modern human)
            <br />
            <br />
            <span className="mnemonic">
              Kids Prefer Cheese Over Fried Green Spinach
            </span>
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">POINTS ON A COMPASS</h2>

          <p className="body-text">
            <span className="mnemonic">Never Eat Shredded Wheat</span>
            <br />
            <br />
            <span className="mnemonic">Naughty Elephants Squirt Water</span>
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">NOTES ON A STAVE</h2>

          <p className="body-text">
            On sheet music, the notes on the stave (the lines) with a treble
            clef are E G B D F
            <br />
            <br />
            <span className="mnemonic">Every Good Boy Deserves Fun</span>
            <br />
            <br />
          </p>
          <h2 className="c-and-e-subtitle centre">NOTES ON THE SPACES</h2>
          <p className="body-text">
            For the notes on the spaces of the stave F A C E a mnemonic is not
            really necessary, as the notes spell out the word 'Face' which is
            simple to remember. It has been included in this section to keep the
            information on music notes together
          </p>
          <div className="line-subtitle"></div>
          <br />
          <h2 className="c-and-e-subtitle centre">COLOURS OF THE RAINBOW</h2>

          <p className="body-text">
            Red, Orange, Yellow, Green, Blue, Indigo, Violet. Although I have
            always used the mnemonic below to remember these, some people just
            remember ROY G. BIV as if it's someone's name
            <br />
            <br />
            <span className="mnemonic">
              Richard Of York Gave Battle In Vain
            </span>
            <br />
            <br />
          </p>

          <div className="line-subtitle"></div>
          <br />

          <h2 className="c-and-e-subtitle centre">PI</h2>
          <p className="body-text">
            Pi to 6 decimal places (3.141592), the number of letters in each
            word represents the digit in the overall number eg. the word
            'calculate' represents the digit '9'
            <br />
            <br />
            <span className="mnemonic">How I Wish I Could Calculate Pi</span>
            <br />
            <br />
          </p>
          <h2 className="c-and-e-subtitle centre">SQUARE ROOT OF 2</h2>
          <p className="body-text">
            As with the mnemonic for Pi, the number of letters in 'I Wish I
            Knew' represent 1.414, and that rhymed with 'the root of two' helps
            you remember what number the square root belongs to
            <br />
            <br />
            <span className="mnemonic">I Wish I Knew - the root of two</span>
            <br />
            <br />
          </p>
          <h2 className="c-and-e-subtitle centre">SQUARE ROOT OF 3</h2>
          <p className="body-text">
            As above 'O Charmed Was He' represents 1.732, and that rhymes with
            'the root of three' so you remember it is for root 3
            <br />
            <br />
            <span className="mnemonic">
              O Charmed Was He - to know root three
            </span>
            <br />
            <br />
          </p>
          <h2 className="c-and-e-subtitle centre">SQUARE ROOT OF 5</h2>
          <p className="body-text">
            The 'So Now We Strive' part of the mnemonic represents 2.326 and
            strive rhymes with five
            <br />
            <br />
            <span className="mnemonic">
              So Now We Strive - to find root five
            </span>
            <br />
            <br />
          </p>
        </div>

        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnAcronyms;
