import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import AsideNav from "../AsideNav";
import flagsAll from "../../FlagsJson";
import FlagAndCountry from "../FlagAndCountry";

function ArrangeFlags(flag) {
  return <FlagAndCountry key={flag.code} flag={flag.svg} name={flag.name} />;
}

function LearnFlags() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Flags Of The World</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Flags of the world and the country/entity
                they belong to. You can see how well you've remembered them
                using the flags quiz.
              </p>

              <Link to="../practice/flags-quiz" className="next">
                Go to flags quiz
              </Link>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small27.png")}
              alt="cartoon character with black hair and orange tshirt"
            />
          </div>

          <div className="alter-ego-list-container">
            {flagsAll.map(ArrangeFlags)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnFlags;
