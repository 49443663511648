import HeaderApp from "../HeaderApp";
import "../../css/wordsearch.css";
import "../../css/style.css";
import Footer from "../Footer";
import WordsearchLatinWordsAndPhrases from "./WordsearchLatinWordsAndPhrases";

function WordsearchWsAndPs() {
  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section>
        <WordsearchLatinWordsAndPhrases />
      </section>
      <Footer />
    </div>
  );
}

export default WordsearchWsAndPs;
