import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import AsideNav from "../AsideNav";
import USState2 from "../../USState2";
import { americanStates } from "../../LearnSpecificCategoryDetailsFive";

function arrangeUSStates(us) {
  return (
    <USState2
      key={us.id}
      stateCode={us.stateCode}
      stateName={us.stateName}
      stateCapital={us.stateCapital}
      mostPopulousCity={us.mostPopulousCity}
      stateFlag={us.stateFlag}
    />
  );
}

function LearnUSStates() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">US States</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                All 50 States of the United States, along with a little
                information about them. See if you can remember them by testing
                yourself with the US States Quiz.
              </p>

              <Link to="../practice/us-states-quiz" className="next">
                Go to US States quiz
              </Link>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small21.png")}
              alt="cartoon character with black hair and orange shirt"
            />
          </div>
        </div>
        <div className="us-state-outer-container">
          <div className="state-cards-container">
            {americanStates.map(arrangeUSStates)}
          </div>
        </div>

        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnUSStates;
