import React, { useEffect, useRef, useState } from "react";
import WordsearchCongratsMsg from "./WordsearchCongratsMsg";
import "../../css/wordsearch.css";
import "../../css/style.css";

const ALLTHEWORDS = [
  "ALABAMA",
  "ALASKA",
  "ARIZONA",
  "ARKANSAS",
  "CALIFORNIA",
  "COLORADO",
  "CONNECTICUT",
  "DELAWARE",
  "FLORIDA",
  "GEORGIA",
  "HAWAII",
  "IDAHO",
  "ILLINOIS",
  "INDIANA",
  "IOWA",
  "KANSAS",
  "KENTUCKY",
  "LOUISIANA",
  "MAINE",
  "MARYLAND",
  "MICHIGAN",
  "MINNESOTA",
  "MISSISSIPPI",
  "MISSOURI",
  "MONTANA",
  "NEBRASKA",
  "NEVADA",
  "NEWJERSEY",
  "NEWMEXICO",
  "NEWYORK",
  "OHIO",
  "OKLAHOMA",
  "OREGON",
  "TENNESSEE",
  "TEXAS",
  "UTAH",
  "VERMONT",
  "VIRGINIA",
  "WASHINGTON",
  "WISCONSIN",
  "WYOMING",
];

for (let i = ALLTHEWORDS.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * i);
  const temp = ALLTHEWORDS[i];
  ALLTHEWORDS[i] = ALLTHEWORDS[j];
  ALLTHEWORDS[j] = temp;
}

const WORDS = ALLTHEWORDS.slice(0, 12).sort();

function WordsearchUSStates() {
  //   const canvas = document.querySelector(".wordsearch-canvas");
  //   const ctxRef.current = canvas.getContext("2d");
  // const wordContainer = document.querySelector(".words");
  // const btnReveal = document.querySelector(".btn-reveal");
  const SQUARES_ACROSS = 12;
  const SQUARES_DOWN = 15;
  const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  //   wordContainer.innerHTML = `<ul>${WORDS.map((w) => "<li>" + w + "</li>").join(
  //     ""
  //   )}</ul>`;
  // const [found, setFound] = useState([]);
  const [time, setTime] = useState(0);
  const [revealCount, setRevealCount] = useState(0);
  const [timerOn, setTimerOn] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false)
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const wordContainerRef = useRef();
  const btnRef = useRef();

  useEffect(() => {
    const render = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      if (window.innerHeight > window.innerWidth) {
        canvas.width = window.innerWidth * 0.9;
        canvas.height = canvas.width * 1.25;
        canvas.style.width = `${window.innerWidth * 0.9}px`;
      } else {
        canvas.height = window.innerHeight * 0.85;
        canvas.width = canvas.height * 0.8;
        canvas.style.width = `${canvas.height * 0.8}px`;
      }

      // canvas.style.height = `${canvas.style.heigh * 1.25}px`;
      //Our first draw
      // ctx.scale(2, 2);
      ctx.lineCap = "round";
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;

      ctxRef.current = ctx;

      //   const wordContainer = wordContainerRef.current;

      // setCellWidth(canvas.width / squaresAcross / 2);
      // setCellHeight(canvas.height / squaresDown / 2);
      //   requestAnimationFrame(render);

      //******************** Start of Grid Class ********************/
      class Grid {
        constructor(
          squaresAcross = SQUARES_ACROSS,
          squaresDown = SQUARES_DOWN
        ) {
          this.squaresAcross = squaresAcross;
          this.squaresDown = squaresDown;

          this.cellWidth = canvasRef.current.width / this.squaresAcross;
          this.cellHeight = canvasRef.current.height / this.squaresDown;
        }

        drawGrid() {
          // Draws temporary outline on canvasRef.current which will be removed at the end
          let xPos = 0;
          let yPos = 0;
          ctxRef.current.fillStyle = "rgb(200, 200, 200)";
          ctxRef.current.strokeStyle = "black";
          for (let i = 0; i < this.squaresDown; i++) {
            for (let j = 0; j < this.squaresAcross; j++) {
              ctxRef.current.strokeRect(
                xPos,
                yPos,
                this.cellWidth,
                this.cellHeight
              );
              xPos += this.cellWidth;
            }
            xPos = 0;
            yPos += this.cellHeight;
          }
        }

        drawCell(xStart, yStart, drawColor = "rgba(50, 50,50, 0.2)") {
          // const cellX = Math.floor(mousePos.x / this.cellWidth);
          // const cellY = Math.floor(mousePos.y / this.cellHeight);
          // Temp debug method to check mouse input correct
          ctxRef.current.fillStyle = drawColor;
          // ctxRef.current.beginPath();
          // ctxRef.current.arc(
          //   xStart * this.cellWidth + this.cellWidth / 2,
          //   yStart * this.cellHeight + this.cellHeight / 2,
          //   this.cellWidth / 1.87,
          //   0,
          //   2 * Math.PI
          // );
          // ctxRef.current.fill();
          ctxRef.current.fillRect(
            // cellX * this.cellWidth,
            // cellY * this.cellHeight,
            xStart * this.cellWidth,
            yStart * this.cellHeight,
            this.cellWidth,
            this.cellHeight
          );
        }
        drawText(x, y, letter, txtColor = "black") {
          ctxRef.current.font = `${this.cellWidth * 0.9}px serif`;
          ctxRef.current.fillStyle = txtColor;
          ctxRef.current.textAlign = "center";
          ctxRef.current.textBaseline = "middle";

          ctxRef.current.fillText(
            letter,
            x * this.cellWidth + this.cellWidth / 2,
            y * this.cellHeight + this.cellHeight / 2
          );
        }
        renderTempLine(
          xStart,
          yStart,
          xEnd,
          yEnd,
          color = "firebrick",
          lineWidth = 30
        ) {
          ctxRef.current.strokeStyle = color;
          ctxRef.current.lineWidth = lineWidth;
          ctxRef.current.lineCap = "round";
          ctxRef.current.beginPath();
          ctxRef.current.moveTo(xStart, yStart);
          ctxRef.current.lineTo(xEnd, yEnd);
          ctxRef.current.stroke();
        }
      }
      //******************** End of Grid Class ********************/

      //***************** Start of Word Grid Class********************/
      class LetterGrid {
        constructor(
          squaresAcross = SQUARES_ACROSS,
          squaresDown = SQUARES_DOWN
        ) {
          this.squaresAcross = squaresAcross;
          this.squaresDown = squaresDown;
          this.grid = [];
          this.directionsToCheck = [];
          this.letters = [];
          this.foundLettersCells = new Set();
          this.allCorrectLetterCells = new Set();
          this.foundWords = [];

          for (let i = -1; i < 2; i++) {
            for (let j = -1; j < 2; j++) {
              if (i === 0 && j === 0) {
                continue;
              }
              this.directionsToCheck.push([i, j]);
            }
          }
        }

        clearLetters() {
          this.letters = [];
        }

        getRandomLetter() {
          return ALPHABET[Math.floor(Math.random() * ALPHABET.length)];
        }

        createLetterGrid() {
          // Creates a seperate grid full of objects, with letters
          for (let i = 0; i < this.squaresDown; i++) {
            this.grid.push([]);

            for (const row of this.grid) {
              for (let j = 0; j < this.squaresAcross; j++) {
                const currentCell = new Cell(i, j);
                row.push(currentCell);
              }
            }
          }
        }
        placeWordInGrid() {
          let word;
          const totalWords = WORDS.length;
          let wordCount = 0;
          while (wordCount < totalWords) {
            word = WORDS[wordCount];
            let directionsChecked = 0;
            let coords = this.pickRandomGridSquare();
            let count;

            let directionNum = Math.floor(
              Math.random() * this.directionsToCheck.length
            );
            // console.log(
            //   `${this.grid[coords.row][coords.col].letter} are we null?`
            // );
            if (
              word[0] === this.grid[coords.row][coords.col].letter ||
              this.grid[coords.row][coords.col].letter === null
            ) {
              // going to hardcode the first param here for testing, replacing directionNum, i'll do it in the actual function
              if (this.checkDirectionForWordFit(directionNum, word, coords)) {
                // console.log("Place word");
                count = 0;
                for (const letter of word) {
                  this.grid[
                    coords.row + count * this.directionsToCheck[directionNum][0]
                  ][
                    coords.col + count * this.directionsToCheck[directionNum][1]
                  ].setLetter(letter);
                  this.allCorrectLetterCells.add(
                    this.grid[
                      coords.row +
                        count * this.directionsToCheck[directionNum][0]
                    ][
                      coords.col +
                        count * this.directionsToCheck[directionNum][1]
                    ]
                  );
                  count++;
                }
                wordCount++;
                // console.log(this.grid);
              } else {
                while (directionsChecked < 8) {
                  ++directionNum;
                  directionsChecked++;
                  // console.log(`${directionsChecked} directions checked`);
                  if (directionNum >= this.directionsToCheck.length) {
                    directionNum = 0;
                  }
                  if (
                    this.checkDirectionForWordFit(directionNum, word, coords)
                  ) {
                    count = 0;
                    for (const letter of word) {
                      this.grid[
                        coords.row +
                          count * this.directionsToCheck[directionNum][0]
                      ][
                        coords.col +
                          count * this.directionsToCheck[directionNum][1]
                      ].setLetter(letter);
                      this.allCorrectLetterCells.add(
                        this.grid[
                          coords.row +
                            count * this.directionsToCheck[directionNum][0]
                        ][
                          coords.col +
                            count * this.directionsToCheck[directionNum][1]
                        ]
                      );
                      count++;
                    }
                    wordCount++;
                    // Update the word, passing the correct params
                    break;
                  }
                }
                // console.log("no fit");
              }
            } else {
              // console.log(`no fit in first square ${word}`); THIS MIGHT BE A PROBLEM TO COMMENT OUT
              // Need to split up function and make this call recursively, as I'm going infinite here
            }
          }
        }

        pickRandomGridSquare() {
          const rowNum = Math.floor(Math.random() * SQUARES_DOWN);
          const colNum = Math.floor(Math.random() * SQUARES_ACROSS);
          return { row: rowNum, col: colNum };
        }

        checkDirectionForWordFit(direction, currentWord, coords) {
          // start cell this.grid[coords.row][coords.col];
          const xDir = this.directionsToCheck[direction][1];
          const yDir = this.directionsToCheck[direction][0];
          // console.log(xDir, yDir, coords.col, coords.row);
          for (let i = 1; i < currentWord.length; i++) {
            // No need to check 0 again
            try {
              // console.log(
              //   this.grid[coords.row + yDir * i][coords.col + xDir * i].letter,
              //   coords.col + xDir * i,
              //   coords.row + yDir * i
              // );
              if (
                this.grid[coords.row + yDir * i][coords.col + xDir * i] ===
                  undefined ||
                coords.col + xDir * i >= 12 ||
                (this.grid[coords.row + yDir * i][coords.col + xDir * i]
                  .letter &&
                  this.grid[coords.row + yDir * i][coords.col + xDir * i]
                    .letter !== currentWord[i])
              ) {
                // console.log(false);
                return false;
              }
            } catch (err) {
              // console.log(err);
              return false;
            }
          }
          // console.log(true);
          return true;
        }

        fillEmptySpaces() {
          for (let i = 0; i < this.squaresAcross; i++) {
            for (let j = 0; j < this.squaresDown; j++) {
              if (this.grid[j][i].letter === null) {
                this.grid[j][i].setLetter(this.getRandomLetter());
              }
            }
          }
        }

        addToFoundLetters(obj) {
          for (const item of obj) {
            this.foundLettersCells.add(item);
          }
          // console.log(this.foundLettersCells);
        }

        revealAllWords() {
          // console.log(this.allCorrectLetterCells);
          // console.log(allCorrectLetterCells);
          for (const cell of this.allCorrectLetterCells) {
            grid.drawCell(cell.col, cell.row, "rgba(239, 85, 51, 0.2)");
          }
        }
      }

      //****************** End of Word Grid Class ********************/

      //****************** Start of Cell Class ********************/

      class Cell {
        constructor(row, col) {
          this.row = row;
          this.col = col;
          this.letter = null;
          this.isSelected = false;
        }

        setSelected(bool) {
          this.isSelected = bool;
        }
        setLetter(letter) {
          this.letter = letter;
        }
      }
      //****************** End of Cell Class ********************/

      //****************** Main ******************* */
      let isDrawing = false;

      let mouseStartPos;
      let mouseEndPos;

      let firstLetterCell;
      let lastLetterCell;

      const grid = new Grid();
      const letterGrid = new LetterGrid();
      // grid.drawGrid();
      letterGrid.createLetterGrid();
      // console.log(letterGrid.grid);
      letterGrid.placeWordInGrid();
      letterGrid.fillEmptySpaces();
      drawUpdatedGameState();

      function displayHighlightedCells() {
        if (isDrawing) {
          for (let i = 0; i < SQUARES_ACROSS; i++) {
            for (let j = 0; j < SQUARES_DOWN; j++) {
              if (letterGrid.grid[j][i].isSelected) {
                grid.drawCell(i, j, "rgba(60,60,60,0.3)");
              }
            }
          }
        }
        for (const cell of letterGrid.foundLettersCells) {
          grid.drawCell(cell.col, cell.row, "rgba(239, 85, 51, .6)");
        }
      }

      function drawUpdatedGameState() {
        // Background first
        ctxRef.current.fillStyle = "rgb(255, 254, 245)";
        ctxRef.current.fillRect(
          0,
          0,
          canvasRef.current.clientWidth,
          // canvasRef.current.width,
          canvasRef.current.height
        );
        ctxRef.current.lineWidth = 1;
        ctxRef.current.lineCap = "round";
        // grid.drawGrid();

        for (let i = 0; i < SQUARES_ACROSS; i++) {
          for (let j = 0; j < SQUARES_DOWN; j++) {
            if (letterGrid.grid[j][i]) {
              grid.drawText(i, j, letterGrid.grid[j][i].letter, "black");
            }
          }
        }
        displayHighlightedCells();
      }

      function getBetweenCells() {
        const currentlySelectedLetters = [];
        if (firstLetterCell && lastLetterCell) {
          // Check up/down
          if (firstLetterCell.col === lastLetterCell.col) {
            // Check down
            if (firstLetterCell.row < lastLetterCell.row) {
              for (
                let i = firstLetterCell.row;
                i < lastLetterCell.row + 1;
                i++
              ) {
                currentlySelectedLetters.push(
                  letterGrid.grid[i][firstLetterCell.col]
                );
              }
            } else {
              // Check up
              for (
                let i = lastLetterCell.row;
                i < firstLetterCell.row + 1;
                i++
              ) {
                currentlySelectedLetters.unshift(
                  letterGrid.grid[i][firstLetterCell.col]
                );
              }
            }
            return currentlySelectedLetters;

            // Check left/right
          } else if (firstLetterCell.row === lastLetterCell.row) {
            // Check right
            if (firstLetterCell.col < lastLetterCell.col) {
              for (
                let i = firstLetterCell.col;
                i < lastLetterCell.col + 1;
                i++
              ) {
                currentlySelectedLetters.push(
                  letterGrid.grid[firstLetterCell.row][i]
                );
              }
            } else {
              // check left
              for (
                let i = lastLetterCell.col;
                i < firstLetterCell.col + 1;
                i++
              ) {
                currentlySelectedLetters.unshift(
                  letterGrid.grid[firstLetterCell.row][i]
                );
              }
            }
            return currentlySelectedLetters;
          }
          //  Check diagonals
          else if (
            // Check down/right - up/left
            lastLetterCell.row - firstLetterCell.row ===
            lastLetterCell.col - firstLetterCell.col
          ) {
            // Check down/right
            if (firstLetterCell.col < lastLetterCell.col) {
              for (
                let i = 0;
                i < lastLetterCell.col - firstLetterCell.col + 1;
                i++
              ) {
                currentlySelectedLetters.push(
                  letterGrid.grid[firstLetterCell.row + i][
                    firstLetterCell.col + i
                  ]
                );
              }
            } else {
              // Check up/left
              for (
                let i = 0;
                i < firstLetterCell.col - lastLetterCell.col + 1;
                i++
              ) {
                currentlySelectedLetters.push(
                  letterGrid.grid[firstLetterCell.row - i][
                    firstLetterCell.col - i
                  ]
                );
              }
            }
            return currentlySelectedLetters;
          } else if (
            // Check up/right - down/left
            lastLetterCell.row + lastLetterCell.col ===
            firstLetterCell.row + firstLetterCell.col
          ) {
            const cellTotal = lastLetterCell.row + lastLetterCell.col;
            // Check up/right
            // console.log(`last: ${lastLetterCell.row}, first:${firstLetterCell.row}`);
            if (lastLetterCell.row < firstLetterCell.row) {
              // for (let i = firstLetterCell.row; i < lastLetterCell.row + 1; i++) {
              //   currentlySelectedLetters.push(
              //     letterGrid.grid[firstLetterCell.row + i][firstLetterCell.row - i]
              //   );
              // }
              for (
                let i = firstLetterCell.col;
                i < lastLetterCell.col + 1;
                i++
              ) {
                currentlySelectedLetters.push(
                  letterGrid.grid[cellTotal - i][i]
                );
              }
            } else {
              // Check down/left
              for (
                let i = firstLetterCell.row;
                i < lastLetterCell.row + 1;
                i++
              ) {
                currentlySelectedLetters.push(
                  letterGrid.grid[i][cellTotal - i]
                );
              }
            }
            return currentlySelectedLetters;
          }
        }
        return;
      }

      //***************** Main ********************/
      drawUpdatedGameState();

      //***************** Main ********************/

      //**************** Drawing Lines Code ************** */

      canvasRef.current.addEventListener("mousedown", handleMouseDown);
      canvasRef.current.addEventListener("mousemove", handleMouseMove);
      canvasRef.current.addEventListener("mouseup", handleMouseUp);
      canvasRef.current.addEventListener("touchstart", handleMouseDown);
      canvasRef.current.addEventListener("touchmove", handleMouseMove);
      canvasRef.current.addEventListener("touchend", handleMouseUp);
      btnRef.current.addEventListener("click", () =>
        letterGrid.revealAllWords()
      );
      // wordContainerRef.current.addEventListener("mouseup", FoundOrNot);
      function calculateMousePos(evt) {
        const clientX = evt.clientX || evt.touches[0].clientX;
        const clientY = evt.clientY || evt.touches[0].clientY;
        const rect = canvasRef.current.getBoundingClientRect();
        // const root = document.documentElement;
        // const { offsetLeft, offsetTop } = evt.target;
        const canvasX = clientX - rect.left;
        const canvasY = clientY - rect.top;

        return { x: canvasX, y: canvasY };
      }

      function handleMouseDown(evt) {
        evt.preventDefault();
        const mousePos = calculateMousePos(evt);
        isDrawing = true;
        mouseStartPos = mousePos;
        // console.log(mouseStartPos.x, mouseStartPos.y);
        // grid.drawCell(mouseStartPos);
        const cellX = Math.floor(mousePos.x / grid.cellWidth);
        const cellY = Math.floor(mousePos.y / grid.cellHeight);
        // console.log(cellX, cellY);
        // console.log(letterGrid.grid[cellY][cellX]);
        // letterGrid.grid[cellY][cellX].setSelected(true);
        firstLetterCell = letterGrid.grid[cellY][cellX];
        firstLetterCell.setSelected(true);
        // console.log(firstLetterCell);
        displayHighlightedCells();
      }

      function handleMouseMove(evt) {
        evt.preventDefault();
        let currentCells;
        const mousePos = calculateMousePos(evt);
        // console.log(mousePos.x, mousePos.y);
        if (
          mousePos.x <= 1 ||
          mousePos.x >= canvasRef.current.width - 1 ||
          mousePos.y <= 1 ||
          mousePos.y >= canvasRef.current.height - 1
        ) {
          isDrawing = false;
          for (let i = 0; i < SQUARES_ACROSS; i++) {
            for (let j = 0; j < SQUARES_DOWN; j++) {
              letterGrid.grid[j][i].setSelected(false);
            }
          }
          drawUpdatedGameState();
          return;
        }
        if (isDrawing) {
          if (
            mousePos.x <= 1 ||
            mousePos.x >= canvasRef.current.width - 1 ||
            mousePos.y <= 1 ||
            mousePos.y >= canvasRef.current.height - 1
          ) {
            isDrawing = false;
            drawUpdatedGameState();
            return;
          }
          mouseEndPos = mousePos;
          const cellX = Math.floor(mousePos.x / grid.cellWidth);
          const cellY = Math.floor(mousePos.y / grid.cellHeight);

          // This checks first if the last letter has been assigned (first time through it wont have been) and then it makes sure that the last letter is not also the first letter. Which causes problems
          if (lastLetterCell) {
            if (lastLetterCell !== firstLetterCell) {
              lastLetterCell.setSelected(false);
            }
          }
          lastLetterCell = letterGrid.grid[cellY][cellX];
          lastLetterCell.setSelected(true);
          currentCells = getBetweenCells(); //Will need an if and to check truthy
          if (currentCells) {
            for (const cell of currentCells) {
              cell.setSelected(true);
            }
            // console.log(currentCells);
            letterGrid.clearLetters();
            letterGrid.letters = currentCells;
          } else {
            for (let i = 0; i < SQUARES_ACROSS; i++) {
              for (let j = 0; j < SQUARES_DOWN; j++) {
                letterGrid.grid[j][i].setSelected(false);
              }
            }
            firstLetterCell.setSelected(true);
            lastLetterCell.setSelected(true);
          }
          drawUpdatedGameState();
          grid.renderTempLine(
            mouseStartPos.x,
            mouseStartPos.y,
            mouseEndPos.x,
            mouseEndPos.y,
            "rgba(110, 164, 191, .4)"
          );
        }
      }
      function handleMouseUp(evt) {
        evt.preventDefault();
        let selectedWord = "";
        for (const cell of letterGrid.letters) {
          // console.log(cell.letter);
          selectedWord += cell.letter;
        }
        if (selectedWord.length > 0) {
          // console.log(selectedWord);

          if (
            WORDS.includes(selectedWord) ||
            WORDS.includes(reverse(selectedWord))
          ) {
            // console.log(`We have a match! ${letterGrid.letters}`);
            letterGrid.addToFoundLetters(letterGrid.letters);
            letterGrid.foundWords.push(selectedWord);
            let foundWordsSet = new Set(letterGrid.foundWords);
            if (WORDS.length <= foundWordsSet.size) {
              setTimerOn(false);
            }

            // console.log(letterGrid.foundWords);
            wordContainerRef.current.innerHTML = WORDS.map((w) =>
              letterGrid.foundWords.includes(w) ||
              letterGrid.foundWords.includes(w.split("").reverse().join(""))
                ? `<p style="color: rgba(239, 85, 51, .75); text-decoration:line-through rgba(239, 85, 51, .75) 4px; -webkit-text-decoration-line: line-through rgba(239, 85, 51, .75) 4px">${w}</p>`
                : `<p>${w}</p>`
            ).join("");
          }
        }
        // console.log(wordContainerRef);
        isDrawing = false;
        for (let i = 0; i < SQUARES_ACROSS; i++) {
          for (let j = 0; j < SQUARES_DOWN; j++) {
            letterGrid.grid[j][i].setSelected(false);
          }
        }
        // Check here for correct words

        firstLetterCell = null;
        lastLetterCell = null;
        // setFound(...found, letterGrid.selectedWord);
        drawUpdatedGameState();
      }
      function reverse(str) {
        return str.split("").reverse().join("");
      }

      // Might change the highlighting to circles rather than squares

      // Touch controls

      // Cross Words out

      // Message when all words found

      // Timer);

      // console.log(found);
      // console.log(wordContainerRef);
      // function FoundOrNot() {
      //   console.log(wordContainerRef);
      // }

      // console.log(letterGrid.foundWords);
    };
    render();
  }, []);

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
      btnRef.current.classList.add("reveal-disabled");
      setIsDisabled(true)
      // stop button working here too!
    }

    return () => clearInterval(interval);
  }, [timerOn]);

  // useEffect(() => {
  //   setCount((count) => count + 1);
  // }, [count]);

  useEffect(() => {
    if (timerOn === false) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [timerOn]);
  return (
    <div>
      <h1 className="wordsearch-title">US States</h1>
      <div className="wordsearch-game-container">
        <div className="canvas-container">
          <WordsearchCongratsMsg
            revealCount={revealCount}
            timerOn={timerOn}
            time={time}
          />
          <canvas className="canvas" ref={canvasRef} />
        </div>
        <div className="words-and-reveal-btn">
          <div className="word-c" ref={wordContainerRef}>
            {WORDS.map((w, i) => (
              <p key={`w${i}`}>{w}</p>
            ))}
          </div>
          <button
            className="next"
            ref={btnRef}
            onClick={() => setRevealCount((revealCount) => revealCount + 1)}
            disabled={isDisabled}
          >
            REVEAL
          </button>
        </div>
      </div>
    </div>
  );
}

export default WordsearchUSStates;

// width="640" height="800"
