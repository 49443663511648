import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import QuestionBankQandA from "./QuestionBankQandA";
import AsideNav from "./AsideNav";
import data from "../data";
import "../css/style.css";
import "../css/peeps.css";
import "../css/questionbank.css";
import "../css/covered.css";

function SetQuestions(qAndA) {
  if (toggleOff) {
    return (
      <QuestionBankQandA
        key={qAndA.id}
        id={qAndA.id}
        question={qAndA.question}
        answer={qAndA.answer}
        tog={false}
      />
    );
  } else {
    return (
      <QuestionBankQandA
        key={qAndA.id}
        id={qAndA.id}
        question={qAndA.question}
        answer={qAndA.answer}
        tog={true}
      />
    );
  }
}

let toggleOff = false;
for (let i = data.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * i);
  const temp = data[i];
  data[i] = data[j];
  data[j] = temp;
}
function QuestionBank() {
  const [cat, setCategory] = useState("");
  const [allRevealed, setAllRevealed] = useState(false);
  const [numQ, setNumQ] = useState(0);

  function handleNumOfQs(event) {
    // for (let i = data.length - 1; i > 0; i--) {
    //   const j = Math.floor(Math.random() * i);
    //   const temp = data[i];
    //   data[i] = data[j];
    //   data[j] = temp;
    // }
    setNumQ(event.target.value);
  }

  function handleRevealAll(event) {
    setAllRevealed(event.target.checked);
    toggleOff = !allRevealed;
  }

  function handleChange(event) {
    for (let i = data.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const temp = data[i];
      data[i] = data[j];
      data[j] = temp;
    }
    setCategory(event.target.value);
    // event.preventDefault();
  }
  // randomise the questions (using Fisher-Yates Algorithm)

  const filteredData = data.filter(function (x) {
    if (cat === "All") {
      return data;
    } else {
      return x.category === cat;
    }
  });

  return (
    <div className="hero push-footer">
      <Header />
      <AsideNav />
      <section className="drop-section-content">
        <div className="container">
          <h1 className="category-subtitle-lrg centre-text">
            The Question Bank
          </h1>
          <div className="select-category">
            <div className="select-category-flex">
              <label className="category-label" htmlFor="numOfQs">
                How many questions:
              </label>
              <select
                className="select-num"
                required
                id="numOfQs"
                onChange={handleNumOfQs}
              >
                <optgroup>
                  <option value="0" defaultValue hidden>
                    ...
                  </option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </optgroup>
              </select>
            </div>
            {/* <div className="select-category-flex">
            <p className="category-label">
              Amount: <span className="category-chosen">{numQ}</span>
            </p>
          </div> */}
            <div className="select-category-flex">
              <label className="category-label" htmlFor="category">
                Select a category:
              </label>
              <select
                className="select-category"
                required
                id="category"
                onChange={handleChange}
              >
                <optgroup>
                  <option value="" defaultValue hidden>
                    ...
                  </option>
                  <option value="All">All categories</option>
                  <option value="Arts">Arts</option>
                  <option value="Britain">Britain</option>
                  <option value="Classic">Classic</option>
                  <option value="Film">Film</option>
                  <option value="Football">Football</option>
                  <option value="Geography">Geography</option>
                  <option value="History">History</option>
                  <option value="HumanBody">Human Body</option>
                  <option value="Language">Language</option>
                  <option value="Music">Music</option>
                  <option value="Nature">Nature</option>
                  <option value="Numbers">Numbers</option>
                  <option value="Opera">Opera</option>
                  <option value="Riddles">Riddles</option>
                  <option value="Sport">Sport</option>
                  <option value="Technology">Technology</option>
                  <option value="TV">TV</option>
                  <option value="VideoGames">Video Games</option>
                </optgroup>
              </select>
            </div>
            {/* <div className="select-category-flex">
            <p className="category-label">
              Selected: <span className="category-chosen">{cat}</span>
            </p>
          </div> */}

            <div className="select-category-flex">
              {/* <label className="checkbox-label" htmlFor="allRevealed">
              Reveal All Answers?
            </label>
            <input
              type="checkbox"
              name="allRevealed"
              id="allRevealed"
              onChange={handleRevealAll}
            /> */}
              <label className="checkbox-container checkbox-label">
                Reveal All Answers?
                <input
                  type="checkbox"
                  name="allRevealed"
                  id="allRevealed"
                  onChange={handleRevealAll}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="line-subtitle"></div>

          {filteredData.slice(0, numQ).map(SetQuestions)}
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-standing1.png")}
            alt="cartoon character standing with arms folded"
            className="peep-learning-right-wider"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default QuestionBank;
