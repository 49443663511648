import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { mohsHardness } from "../../LearnSpecificCategoryDetailsFour";
import "../../css/style.css";
import "../../css/not-tables.css";

function LearnMohsHardness() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={mohsHardness.mainCategory}
          subCategory={mohsHardness.subCategory}
          iconLrg={mohsHardness.iconLrg}
          iconSml={mohsHardness.iconSml}
          bigPeep={mohsHardness.bigPeep}
          intro={mohsHardness.intro}
        />

        <div className="container">
          <div className="table-flex-6">
            <div className="table-flex-6-row">
              <p>Mineral</p>
              <p>Hardness</p>
            </div>
            <div className="line-xs-margin"></div>
            <div className="table-flex-6-row">
              <p>Talc</p>
              <p>1</p>
            </div>
            <div className="table-flex-6-row">
              <p>Gypsum</p>
              <p>2</p>
            </div>
            <div className="table-flex-6-row">
              <p>Calcite</p>
              <p>3</p>
            </div>
            <div className="table-flex-6-row">
              <p>Fluorite</p>
              <p>4</p>
            </div>
            <div className="table-flex-6-row">
              <p>Apatite</p>
              <p>5</p>
            </div>
            <div className="table-flex-6-row">
              <p>Orthoclase</p>
              <p>6</p>
            </div>
            <div className="table-flex-6-row">
              <p>Quartz</p>
              <p>7</p>
            </div>
            <div className="table-flex-6-row">
              <p>Topaz</p>
              <p>8</p>
            </div>
            <div className="table-flex-6-row">
              <p>Corundum</p>
              <p>9</p>
            </div>
            <div className="table-flex-6-row">
              <p>Diamond</p>
              <p>10</p>
            </div>
          </div>
          <div className="line-subtitle"></div>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={mohsHardness.importantFacts}
          classicQuestions={mohsHardness.classicQuestions}
          exampleQuestions={mohsHardness.exampleQuestions}
          thoughtsTipsIdeas={mohsHardness.thoughtsTipsAndIdeas}
          // smallPeep1={latinWords.smallPeep1}
          smallPeep2={mohsHardness.smallPeep2}
          disclaimer={mohsHardness.disclaimer}
        />

        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnMohsHardness;
