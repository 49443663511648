import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import AsideNav from "./AsideNav";
import {
  englishCounties,
  englishCountiesAttributionsArms,
  englishCountiesAttributionsFlags,
} from "../LearnSpecificCategoryDetailsFive";
import EnglishCounty2 from "../EnglishCounty2";
import Attributions from "../Attributions";

function arrangeEnglishCounties(ec) {
  return (
    <EnglishCounty2
      key={ec.id}
      countyName={ec.countyName}
      countyTown={ec.countyTown}
      mostPopulousCity={ec.mostPopulousCity}
      countyFlag={ec.countyFlag}
      countyArms={ec.countyArms}
    />
  );
}
function arrangeAttributions(ec) {
  return (
    <Attributions
      key={ec.id}
      countyName={ec.countyName}
      imageAtt={ec.imageAtt}
      ccLink={ec.ccLink}
      ccArtist={ec.ccArtist}
      ccNum={ec.ccNum}
      ccFileName={ec.ccFileName}
    />
  );
}

function EnglishCounties() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">English Ceremonial Counties</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                All 47 English ceremonial counties, along with a little
                information about them. See if you can remember them by testing
                yourself with the English Counties Quiz.
              </p>
              <Link to="../practice/english-counties-quiz" className="next">
                Go to English Counties quiz
              </Link>
            </div>
            <img
              className="peep peep-flip"
              src={require("../images/peep-small23.png")}
              alt="cartoon character with black hair and orange shirt"
            />
          </div>
        </div>
        <div className="us-state-outer-container">
          <div className="state-cards-container">
            {englishCounties.map(arrangeEnglishCounties)}
          </div>
        </div>
        <div className="us-state-outer-container">
          <p className="container-third-subtitle">IMAGE ATTRIBUTIONS:</p>
          <div className="attributions-container">
            {englishCountiesAttributionsFlags.map(arrangeAttributions)}
            {englishCountiesAttributionsArms.map(arrangeAttributions)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default EnglishCounties;
