const firstLines = [
  {
    id: "MobyDick1851",
    book: "Moby Dick",
    author: "Herman Melville",
    year: 1851,
    firstLine: "Call me Ishmael.",
    linkToBuy:
      "https://www.amazon.co.uk/Moby-Dick-Herman-Melville/dp/1514649748/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1663066588&sr=8-2-spons",
    bookImg: require("./images/mobyDick.jpg"),
  },
  {
    id: "NineteenEighty-Four1984",
    book: "Nineteen Eighty-Four",
    author: "George Orwell",
    year: 1949,
    firstLine:
      "It was a bright cold day in April, and the clocks were striking thirteen.",
    linkToBuy:
      "https://www.amazon.co.uk/1984-Nineteen-Eighty-Four-Twentieth-Masterpiece/dp/184697576X/ref=sr_1_7?crid=294RFXSAP7GA1&keywords=nineteen+eighty+four+george+orwell&qid=1663066495&sprefix=nineteen%2Caps%2C78&sr=8-7",
    bookImg: require("./images/nineteen84.jpg"),
  },
  {
    id: "PrideandPrejudice1813",
    book: "Pride and Prejudice",
    author: "Jane Austen",
    year: 1813,
    firstLine:
      "It is a truth universally acknowledged, that a single man in possession of a good fortune, must be in want of a wife.",
    linkToBuy:
      "https://www.amazon.co.uk/Pride-Prejudice-Alma-Classics-Evergreens/dp/1847493696/ref=sr_1_9?crid=1MZ7FOJFQE103&keywords=pride+and+prejudice+book&qid=1663066408&sprefix=pride%2Caps%2C82&sr=8-9",
    bookImg: require("./images/prideAndPrejudice.jpg"),
  },
  {
    id: "Beloved1987",
    book: "Beloved",
    author: "Tony Morrison",
    year: 1987,
    firstLine: "124 was spiteful. Full of a baby's venom.",
    linkToBuy:
      "https://www.amazon.co.uk/Beloved-Toni-Morrison/dp/0099760118/ref=sr_1_1?crid=337UT7O1V42SM&keywords=beloved&qid=1663066180&sprefix=beloved%2Caps%2C72&sr=8-1",
    bookImg: require("./images/beloved.jpg"),
  },
  {
    id: "TheBookThief2005",
    book: "The Book Thief",
    author: "Markus Zusak",
    year: 2005,
    firstLine:
      "First the colours. Then the humans. That's usually how I see things. Or at least, how I try. Here is a small fact: You are going to die.",
    linkToBuy:
      "https://www.amazon.co.uk/Book-Thief-Definitions-Young-Adult/dp/1909531618/ref=sr_1_1?crid=CB8HTU9MKC0I&keywords=the+book+thief+markus+zusak+paperback&qid=1663066939&sprefix=markus+zusak%2Caps%2C57&sr=8-1",
    bookImg: require("./images/theBookThief.jpg"),
  },
  {
    id: "SlaughterhouseFive1969",
    book: "Slaughterhouse Five",
    author: "Kurt Vonnegut",
    year: 1969,
    firstLine: "All this happened, more or less.",
    linkToBuy:
      "https://www.amazon.co.uk/Slaughterhouse-Childrens-Crusade-Duty-dance-Death/dp/0099800209/ref=sr_1_1?crid=1VJL5GMFAFC02&keywords=slaughterhouse+5&qid=1663067120&sprefix=slaughterhouse%2Caps%2C83&sr=8-1",
    bookImg: require("./images/slaughterhouse5.jpg"),
  },
  {
    id: "TheBellJar1963",
    book: "The Bell Jar",
    author: "Sylvia Plath",
    year: 1963,
    firstLine:
      "It was a queer, sultry summer, the summer they electrocuted the Rosenbergs, and I didn't know what I was doing in New York.",
    linkToBuy:
      "https://www.amazon.co.uk/Bell-Jar-Sylvia-Plath/dp/0571226167/ref=sr_1_4?crid=3RZ22P5FYBBP2&keywords=the+bell+jar&qid=1663067207&sprefix=the+bell+jar%2Caps%2C78&sr=8-4",
    bookImg: require("./images/theBellJar.jpg"),
  },
  {
    id: "AnnaK1878",
    book: "Anna Karenina",
    author: "Leo Tolstoy",
    year: 1878,
    firstLine:
      "All happy families resemble one another, but each unhappy family is unhappy in its own way.",
    linkToBuy:
      "https://www.amazon.co.uk/Anna-Karenina-Vintage-Classic-Russians/dp/1784871958/ref=sr_1_6?crid=2K8BTUSAW7ROU&keywords=anna+karenina&qid=1663073956&sprefix=anna+karenina%2Caps%2C74&sr=8-6",
    bookImg: require("./images/annaK.jpg"),
  },
  {
    id: "TheGunslinger1982",
    book: "The Gunslinger",
    author: "Stephen King",
    year: 1982,
    firstLine:
      "The man in black fled across the desert, and the gunslinger followed.",
    linkToBuy:
      "https://www.amazon.co.uk/Dark-Tower-Gunslinger-Stephen-King/dp/1444723448/ref=sr_1_1?crid=RZPB95YINK4Q&keywords=the+gunslinger&qid=1663067402&sprefix=the+gunslinge%2Caps%2C79&sr=8-1",
    bookImg: require("./images/gunslinger.jpg"),
  },
  {
    id: "TheColorPurple1982",
    book: "The Color Purple",
    author: "Alice Walker",
    year: 1982,
    firstLine:
      "You better not never tell nobody but God. It'd kill your mammy.",
    linkToBuy:
      "https://www.amazon.co.uk/Color-Purple-Alice-Walker/dp/147460725X/ref=sr_1_1?crid=3SIMCPGOLXVME&keywords=the+color+purple&qid=1663067465&sprefix=the+color+purple%2Caps%2C75&sr=8-1",
    bookImg: require("./images/colorPurple.jpg"),
  },
  {
    id: "HarryPandthePS1997",
    book: "Harry Potter and the Philosopher's Stone",
    author: "J.K. Rowling",
    year: 1997,
    firstLine:
      "Mr and Mrs Dursley, of number four, Privet Drive, were proud to say that they were perfectly normal, thank you very much.",
    linkToBuy:
      "https://www.amazon.co.uk/Harry-Potter-Philosophers-Stone/dp/1408855658/ref=sr_1_3?crid=UALH8L1SP9OY&keywords=harry+potter&qid=1663067512&sprefix=harry+potter%2Caps%2C78&sr=8-3",
    bookImg: require("./images/harryPPhil.jpg"),
  },
  {
    id: "ToKillAMockingbird1960",
    book: "To Kill a Mockingbird",
    author: "Harper Lee",
    year: 1960,
    firstLine:
      "When he was nearly thirteen my brother Jem got his arm badly broken at the elbow.",
    linkToBuy:
      "https://www.amazon.co.uk/Kill-Mockingbird-Harper-Lee/dp/0099549484/ref=sr_1_1?crid=3EUIJNGW82MC3&keywords=to+kill+a+mockingbird&qid=1663067622&sprefix=to+kill+a+mocking%2Caps%2C77&sr=8-1",
    bookImg: require("./images/killAMockingbird.jpg"),
  },
  {
    id: "TheGreatGatsby1925",
    book: "The Great Gatsby",
    author: "F. Scott Fitzgerald",
    year: 1925,
    firstLine:
      "In my younger and more vulnerable years my father gave me some advice that I've been turning over in my mind ever since.",
    linkToBuy:
      "https://www.amazon.co.uk/Great-Gatsby-Classic-1925-Novel/dp/B09QP698F3/ref=sr_1_10?crid=1JY9IR8PI6CW9&keywords=great+gatsby&qid=1663067714&sprefix=great+%2Caps%2C84&sr=8-10",
    bookImg: require("./images/greatGatsby.jpg"),
  },
  {
    id: "AnimalFarm1945",
    book: "Animal Farm",
    author: "George Orwell",
    year: 1945,
    firstLine:
      "Mr. Jones, of the Manor Farm, had locked the hen-houses for the night, but was too drunk to remember to shut the pop-holes.",
    linkToBuy:
      "https://www.amazon.co.uk/Animal-Farm-Internationally-selling-Classics/dp/0008322058/ref=sr_1_3?crid=2893RYVZHPA2B&keywords=animal+farm&qid=1663067843&sprefix=animal+farm%2Caps%2C90&sr=8-3",
    bookImg: require("./images/animalFarm.jpg"),
  },
  {
    id: "Fahrenheight4511953",
    book: "Fahrenheit 451",
    author: "Ray Bradbury",
    year: 1953,
    firstLine: "It was a pleasure to burn.",
    linkToBuy:
      "https://www.amazon.co.uk/Fahrenheit-451-Flamingo-Modern-Classics/dp/0006546064/ref=sr_1_1?keywords=fahrenheit+451&qid=1663067903&sprefix=fahre%2Caps%2C85&sr=8-1",
    bookImg: require("./images/fahrenheit451.jpg"),
  },
  {
    id: "TheCatcherintheRye1951",
    book: "The Catcher in the Rye",
    author: "J.D. Salinger",
    year: 1951,
    firstLine:
      "If you really want to hear about it, the first thing you'll probably want to know is where I was born, and what my lousy childhood was like, and how my parents were occupied and all before they had me, and all that David Copperfield kind of crap, but I don't feel like going into it, if you want to know the truth.",
    linkToBuy:
      "https://www.amazon.co.uk/Catcher-Rye-J-D-Salinger/dp/0241950430/ref=sr_1_1?crid=1Z6VHAVTIQKJ0&keywords=catcher+in+the+rye&qid=1663067954&sprefix=catcger+%2Caps%2C79&sr=8-1",
    bookImg: require("./images/catcherintheRye.jpg"),
  },
  {
    id: "OfMiceAndMen1937",
    book: "Of Mice and Men",
    author: "John Steinbeck",
    year: 1937,
    firstLine:
      "A few miles south of Soledad, the Salinas River drops in close to the hillside bank and runs deep and green.",
    linkToBuy:
      "https://www.amazon.co.uk/Mice-Men-Penguin-Red-Classics/dp/0141023570/ref=sr_1_3?crid=158N1JMF7H2MN&keywords=of+mice+and+men&qid=1663068019&sprefix=of+mice+and+men%2Caps%2C77&sr=8-3",
    bookImg: require("./images/ofMiceAndMen.jpg"),
  },
  {
    id: "LordOfTHeFlies1954",
    book: "Lord of the Flies",
    author: "Wiliam Golding",
    year: 1954,
    firstLine:
      "The boy with fair hair lowered himself down the last few feet of rock and began to pick his way towards the lagoon.",
    linkToBuy:
      "https://www.amazon.co.uk/Lord-Flies-William-Golding/dp/0571191479/ref=sr_1_1?crid=2HN7T7LK8TBMA&keywords=lord+of+the+flies&qid=1663068090&sprefix=lord+of+the+flie%2Caps%2C75&sr=8-1",
    bookImg: require("./images/lordOfTheFlies.jpg"),
  },
  {
    id: "TheHitchhikersguide1979",
    book: "The Hitchhiker's Guide to the Galaxy",
    author: "Douglas Adams",
    year: 1979,
    firstLine:
      "Far out in the uncharted backwaters of the unfashionable end of the Western Spiral Arm of the Galaxy lies a small unregarded yellow sun.",
    linkToBuy:
      "https://www.amazon.co.uk/Hitchhikers-Guide-Galaxy-42nd-Anniversary/dp/1529034523/ref=sr_1_1_sspa?crid=2NOIMY13C9SG7&keywords=hitchhikers+guide+to+the+galaxy&qid=1663071542&sprefix=hitch%2Caps%2C113&sr=8-1-spons&psc=1",
    bookImg: require("./images/hitchhikersGuide.jpg"),
  },
  {
    id: "thestranger1942",
    book: "The Stranger",
    author: "Albert Camus",
    year: 1942,
    firstLine: "Today, Maman died. Or perhaps yesterday, I don't know.",
    linkToBuy:
      "https://www.amazon.co.uk/Stranger-Albert-Camus-Paperback/dp/B01181UBSU/ref=sr_1_1?crid=3616UUGSVUKVE&keywords=the+stranger+camus&qid=1663071738&sprefix=the+stranger+camus%2Caps%2C70&sr=8-1",
    bookImg: require("./images/theStranger.jpg"),
  },
  {
    id: "100yearsofSolitude1967",
    book: "One Hundred Years of Solitude",
    author: "Gabriel Garcia Marquez",
    year: 1967,
    firstLine:
      "Many years later, as he faced the firing squad, Colonel Aureliano Buendía was to remember that distant afternoon when his father took him to discover ice.",
    linkToBuy:
      "https://www.amazon.co.uk/Hundred-Solitude-Gabriel-Garcia-Marquez/dp/0241968585/ref=sr_1_2_sspa?crid=3MT188625NISE&keywords=100+years+of+solitude&qid=1663071984&sprefix=100+years%2Caps%2C88&sr=8-2-spons&psc=1",
    bookImg: require("./images/OneHundredYearsOfSolitude.jpg"),
  },
  {
    id: "TheCrowRoad1992",
    book: "The Crow Road",
    author: "Iain M. Banks",
    year: 1992,
    firstLine: "It was the day my grandmother exploded.",
    linkToBuy:
      "https://www.amazon.co.uk/Crow-Road-Iain-Banks/dp/0349139156/ref=sr_1_1?crid=M7WXNTJ8HCBE&keywords=the+crow+road&qid=1663072048&sprefix=the+crow+r%2Caps%2C85&sr=8-1",
    bookImg: require("./images/crowRoad.jpg"),
  },
  {
    id: "FightClub1996",
    book: "Fight Club",
    author: "Chuck Palahniuk",
    year: 1996,
    firstLine:
      "Tyler gets me a job as a waiter, after that Tyler's pushing a gun in my mouth and saying, the first step to eternal life is you have to die.",
    linkToBuy:
      "https://www.amazon.co.uk/Fight-Club-Chuck-Palahniuk/dp/0099765217/ref=sr_1_4?crid=3DGV6VMINZHGA&keywords=fight+club&qid=1663072103&sprefix=fight+club%2Caps%2C72&sr=8-4",
    bookImg: require("./images/fightClub.jpg"),
  },
  {
    id: "ICaptureTheCastle1948",
    book: "I Capture the Castle",
    author: "Dodie Smith",
    year: 1948,
    firstLine: "I write this sitting in the kitchen sink.",
    linkToBuy:
      "https://www.amazon.co.uk/I-Capture-Castle-Vintage-Classics/dp/0099460874/ref=sr_1_1?crid=3FILQOI3DMMTL&keywords=i+capture+the+castle&qid=1663072156&sprefix=i+capture+the+%2Caps%2C69&sr=8-1",
    bookImg: require("./images/iCaptureTheCastle.jpg"),
  },
  {
    id: "NotesFromtheUInbderground1864",
    book: "Notes from the Underground",
    author: "Fyodor Dostoyevsky",
    year: 1864,
    firstLine:
      "I am a sick person… I am a vindictive man. I am an ugly man. My liver, I think, is infected.",
    linkToBuy:
      "https://www.amazon.co.uk/Notes-Underground-Fyodor-Dostoyevsky/dp/1936594676/ref=sr_1_1_sspa?crid=3CJTKXNB8YMSI&keywords=notes+from+the+underground&qid=1663072272&sprefix=notes+fr%2Caps%2C73&sr=8-1-spons&psc=1",
    bookImg: require("./images/notesFromtheUnderground.jpg"),
  },
  {
    id: "FearandLoathingLA1971",
    book: "Fear and Loathing in Las Vegas",
    author: "Hunter S. Thompson",
    year: 1971,
    firstLine:
      "We were somewhere around Barstow on the edge of the desert when the drugs began to take hold.",
    linkToBuy:
      "https://www.amazon.co.uk/Fear-Loathing-Las-Vegas-Thompson/dp/0008557543/ref=sr_1_1?crid=XGK9HQHA6DHC&keywords=fear+and+loathing+in+las+vegas+book&qid=1663072368&sprefix=fear+and+loathing+in+%2Caps%2C76&sr=8-1",
    bookImg: require("./images/fearAndLoathing.jpg"),
  },
  {
    id: "TheMArtian2011",
    book: "The Martian",
    author: "Andy Weir",
    year: 2011,
    firstLine: "I'm pretty much f*cked",
    linkToBuy:
      "https://www.amazon.co.uk/Martian-Andy-Weir/dp/0091956145/ref=sr_1_2?crid=38SK4CFQRNPZ5&keywords=the+martian&qid=1663072419&sprefix=the+martian%2Caps%2C78&sr=8-2",
    bookImg: require("./images/theMartian.jpg"),
  },
  {
    id: "TheGoBetween1953",
    book: "The Go-Between",
    author: "L.P. Hartley",
    year: 1953,
    firstLine:
      "The past is a foreign country; they do things differently there.",
    linkToBuy:
      "https://www.amazon.co.uk/Go-Between-Penguin-Modern-Classics/dp/0141187786/ref=sr_1_1?crid=1KW3VSVJHHYT1&keywords=the+go+between&qid=1663072495&sprefix=the+go+between%2Caps%2C72&sr=8-1",
    bookImg: require("./images/theGoBetween.jpg"),
  },
  {
    id: "ThePrincessBride1973",
    book: "The Princess Bride",
    author: "William Goldman",
    year: 1973,
    firstLine:
      "This is my favorite book in all the world, though I have never read it.",
    linkToBuy:
      "https://www.amazon.co.uk/Princess-Bride-William-Goldman/dp/0747590583/ref=sr_1_2?crid=X2LBEYG0MZE2&keywords=the+princess+bride&qid=1663072542&sprefix=the+princess+bride%2Caps%2C65&sr=8-2",
    bookImg: require("./images/thePrincessBride.jpg"),
  },
  {
    id: "ATaleOf2Cities1859",
    book: "A Tale of Two Cities",
    author: "Charles Dickens",
    year: 1859,
    firstLine:
      "It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way ...",
    linkToBuy:
      "https://www.amazon.co.uk/Tale-Two-Cities-Penguin-Classics/dp/0141439602/ref=sr_1_3?crid=1BMWA983ZTOCM&keywords=a+tale+of+two+cities&qid=1663072598&sprefix=a+tale+of+t%2Caps%2C77&sr=8-3",
    bookImg: require("./images/taleOfTwoCities.jpg"),
  },
  {
    id: "TheMetamorphosis1915",
    book: "The Metamorphosis",
    author: "Franz Kafka",
    year: 1915,
    firstLine:
      "When Gregor Samsa woke one morning from troubled dreams, he found himself transformed right there in his bed into some sort of monstrous insect.",
    linkToBuy:
      "https://www.amazon.co.uk/Metamorphosis-Franz-Kafka/dp/1519224273/ref=sr_1_4?crid=QL8TVQ4X2NG5&keywords=the+metamorphosis&qid=1663072693&sprefix=the+metamorphosis%2Caps%2C70&sr=8-4",
    bookImg: require("./images/theMetamorphosis.jpg"),
  },
  {
    id: "AdventuresofHuckFinn1884",
    book: "The Adventures of Huckleberry Finn",
    author: "Mark Twain",
    year: 1884,
    firstLine:
      "You don't know about me without you have read a book by the name of The Adventures of Tom Sawyer; but that ain't no matter. That book was made by Mr. Mark Twain, and he told the truth, mainly.",
    linkToBuy:
      "https://www.amazon.co.uk/Adventures-Huckleberry-Finn-Penguin-Classics/dp/0143107321/ref=sr_1_3?crid=2J8LGNCKSCO0Y&keywords=the+adventures+of+huckleberry+finn&qid=1663072840&sprefix=the+adventureso%2Caps%2C77&sr=8-3",
    bookImg: require("./images/huckFinn.jpg"),
  },
  {
    id: "CharlottesWeb1952",
    book: "Charlotte's Web",
    author: "E.B. White",
    year: 1952,
    firstLine:
      "'Where's Papa going with that ax?' said Fern to her mother as they were setting the table for breakfast.",
    linkToBuy:
      "https://www.amazon.co.uk/Charlottes-Web-Puffin-Book-White/dp/0141354828/ref=sr_1_1?keywords=charlottes+web&qid=1663072902&sprefix=charlottes+w%2Caps%2C80&sr=8-1",
    bookImg: require("./images/charlottesWeb.jpg"),
  },
  {
    id: "BrakfastChamps1973",
    book: "Breakfast of Champions",
    author: "Kurt Vonnegut",
    year: 1973,
    firstLine:
      "This is a tale of a meeting of two lonesome, skinny, fairly old white men on a planet which was dying fast.",
    linkToBuy:
      "https://www.amazon.co.uk/Breakfast-Champions-Vintage-Classics-Vonnegut/dp/0099842602/ref=sr_1_1?crid=1KH5YVGQXY5HN&keywords=breakfast+of+champions&qid=1663073027&sprefix=breakfast+of+champions%2Caps%2C75&sr=8-1",
    bookImg: require("./images/breakfastOfChamps.jpg"),
  },
  {
    id: "JaneEyre1847",
    book: "Jane Eyre",
    author: "Charlotte Bronte",
    year: 1847,
    firstLine: "There was no possibility of taking a walk that day.",
    linkToBuy:
      "https://www.amazon.co.uk/Jane-Eyre-Charlotte-Bronte/dp/1420951262/ref=sr_1_2_sspa?crid=1W5U2XIQY40PG&keywords=jane+eyre&qid=1663073143&sprefix=jane+eyre%2Caps%2C72&sr=8-2-spons&psc=1",
    bookImg: require("./images/janeEyre.jpg"),
  },
  {
    id: "ClockworkOrange1962",
    book: "A Clockwork Orange",
    author: "Anthony Burgess",
    year: 1962,
    firstLine:
      "'What's it going to be then, eh?' There was me, that is Alex, and my three droogs, that is Pete, Georgie and Dim, Dim being really dim, and we sat in the Korova Milkbar making up our rassoodocks what to do with the evening, a flip dark chill winter bastard though dry.",
    linkToBuy:
      "https://www.amazon.co.uk/Clockwork-Orange-Penguin-Essentials/dp/0241951445/ref=sr_1_1_sspa?crid=3766WE37GXRJZ&keywords=clockwork+orange&qid=1663073304&sprefix=clockwork+orange%2Caps%2C77&sr=8-1-spons&psc=1",
    bookImg: require("./images/aClockworkOrange.jpg"),
  },
  {
    id: "HungerGames2008",
    book: "The Hunger Games",
    author: "Suzanne Collins",
    year: 2008,
    firstLine: "When I wake up, the other side of the bed is cold.",
    linkToBuy:
      "https://www.amazon.co.uk/Hunger-Games-Trilogy-Book-one/dp/1407132083/ref=sr_1_3?crid=1TFTMXG5L6SD8&keywords=the+hunger+games&qid=1663073375&sprefix=the+hunger+game%2Caps%2C78&sr=8-3",
    bookImg: require("./images/hungerGames.jpg"),
  },
  {
    id: "LifeOfPi2001",
    book: "Life of Pi",
    author: "Yann Martel",
    year: 2001,
    firstLine: "My suffering left me sad and gloomy.",
    linkToBuy:
      "https://www.amazon.co.uk/Life-Pi-Canons-Yann-Martel/dp/1786891689/ref=sr_1_3?crid=2IAVKYCEF291L&keywords=life+of+pi&qid=1663073424&sprefix=life+of+pi%2Caps%2C84&sr=8-3",
    bookImg: require("./images/lifeOfPi.jpg"),
  },
  {
    id: "Catch-221961",
    book: "Catch-22",
    author: "Joseph Heller",
    year: 1961,
    firstLine: "It was love at first sight.",
    linkToBuy:
      "https://www.amazon.co.uk/Catch-22-50th-Anniversary-Joseph-Heller/dp/0099529122/ref=sr_1_1?crid=1IFQS8ZHYOPOI&keywords=catch+22&qid=1663073459&sprefix=catch+2%2Caps%2C80&sr=8-1",
    bookImg: require("./images/catch22.jpg"),
  },
  {
    id: "Rebecca1938",
    book: "Rebecca",
    author: "Daphne Du Maurier",
    year: 1938,
    firstLine: "Last night I dreamed I went to Manderley again.",
    linkToBuy:
      "https://www.amazon.co.uk/Rebecca-Virago-Modern-Classics-Maurier/dp/0349006571/ref=sr_1_2?crid=3P9VCYJ978VAB&keywords=rebecca&qid=1663073489&sprefix=rebecc%2Caps%2C86&sr=8-2",
    bookImg: require("./images/rebecca.jpg"),
  },
  {
    id: "WutheringHeights1847",
    book: "Wuthering Heights",
    author: "Emily Bronte",
    year: 1847,
    firstLine:
      "1801 - I have just returned from a visit to my landlord - the solitary neighbour that I shall be troubled with.",
    linkToBuy:
      "https://www.amazon.co.uk/Wuthering-Heights-Emily-Bront%C3%AB/dp/B09X3TWGZ1/ref=sr_1_3?crid=1ZO2VRO7W7QZV&keywords=wuthering+heights&qid=1663073604&sprefix=wuthering+height%2Caps%2C85&sr=8-3",
    bookImg: require("./images/wutheringHeights.jpg"),
  },
  {
    id: "LittleWomen1868",
    book: "Little Women",
    author: "Louisa May Alcott",
    year: 1868,
    firstLine:
      "'Christmas won't be Christmas without any presents,' grumbled Jo, lying on the rug.",
    linkToBuy:
      "https://www.amazon.co.uk/Little-Women-Penguin-English-Library/dp/0241335132/ref=sr_1_3?crid=1CLLP3GXIRAR5&keywords=little+women&qid=1663073682&sprefix=little+women%2Caps%2C81&sr=8-3&asin=0241335132&revisionId=&format=4&depth=1",
    bookImg: require("./images/littleWomen.jpg"),
  },
  {
    id: "Matilda1988",
    book: "Matilda",
    author: "Roald Dahl",
    year: 1988,
    firstLine:
      "It's a funny thing about mothers and fathers. Even when their own child is the most disgusting little blister you could ever imagine, they still think that he or she is wonderful.",
    linkToBuy:
      "https://www.amazon.co.uk/Matilda-Special-Roald-Dahl/dp/024155831X/ref=sr_1_3?crid=3QA8X8RSVUAU&keywords=matilda&qid=1663073745&sprefix=matilda%2Caps%2C71&sr=8-3",
    bookImg: require("./images/matilda.jpg"),
  },
  {
    id: "GoneWithTheWind1936",
    book: "Gone With the Wind",
    author: "Margaret Mitchell",
    year: 1936,
    firstLine:
      "Scarlett O'Hara was not beautiful, but men seldom realized it when caught by her charm as the Tarleton twins were.",
    linkToBuy:
      "https://www.amazon.co.uk/Gone-Vintage-Classics-Margaret-Mitchell/dp/1784876119/ref=sr_1_4?crid=1W2PWBFYJBPEH&keywords=gone+with+the+wind&qid=1663073781&sprefix=gone+with+the+wind%2Caps%2C79&sr=8-4",
    bookImg: require("./images/goneWithTheWind.jpg"),
  },
  {
    id: "Middlemarch1871",
    book: "Middlemarch",
    author: "George Eliot",
    year: 1871,
    firstLine:
      "Miss Brooke had that kind of beauty which seems to be thrown into relief by poor dress.",
    linkToBuy:
      "https://www.amazon.co.uk/Middlemarch-Penguin-Classics-George-Eliot/dp/0141439548/ref=sr_1_1_sspa?crid=15VMMLWJVXI87&keywords=middlemarch&qid=1663073843&sprefix=middlemarch%2Caps%2C80&sr=8-1-spons&psc=1",
    bookImg: require("./images/middlemarch.jpg"),
  },
  {
    id: "TheHandmaidsTale1985",
    book: "The Handmaid's Tale",
    author: "Margaret Atwood",
    year: 1985,
    firstLine: "We slept in what had once been the gymnasium.",
    linkToBuy:
      "https://www.amazon.co.uk/Handmaids-Tale-Vintage-Classics/dp/1784873187/ref=sr_1_3?crid=ET60R9YQT455&keywords=the+handmaids+tale&qid=1663073900&sprefix=the+handmaids+tale%2Caps%2C82&sr=8-3",
    bookImg: require("./images/theHandmaidsTale.jpg"),
  },
];

export default firstLines;
