import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import { kingsAndQueens } from "../../LearnSpecificCategoryDetailsThree";
// import { historyAndCivilisation } from "../../topFivesInfo";
import "../../css/style.css";
import "../../css/peeps.css";

function LearnKingAndQueens() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Kings & Queens</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <img
              className="peep"
              src={require("../../images/peep-small28.png")}
              alt="cartoon character wearing orange shirt and white blazer"
            />
            <div className="center-align">
              <p className="body-text">Kings of Queens of England since 871</p>
            </div>
          </div>

          <div className="line-even-margin"></div>
          <h2 className="category-subtitle centre">Monarchs Of England</h2>
          <h3 className="container-third-subtitle">
            {kingsAndQueens.monarchsOfEngland.houseOfWessex.kingsAndQueensName}
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.houseOfWessex.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {kingsAndQueens.monarchsOfEngland.danishLine.kingsAndQueensName}
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.danishLine.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.monarchsOfEngland.houseOfWessexRestored
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.houseOfWessexRestored.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {kingsAndQueens.monarchsOfEngland.normanLine.kingsAndQueensName}
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.normanLine.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.monarchsOfEngland.plantagenetAnjouLine
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.plantagenetAnjouLine.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {kingsAndQueens.monarchsOfEngland.lancasterLine.kingsAndQueensName}
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.lancasterLine.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {kingsAndQueens.monarchsOfEngland.yorkLine.kingsAndQueensName}
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.yorkLine.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {kingsAndQueens.monarchsOfEngland.houseOfTudor.kingsAndQueensName}
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfEngland.houseOfTudor.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h2 className="category-subtitle centre">
            Monarchs Of Great Britain
          </h2>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.monarchsOfGreatBritain.houseofStuart
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfGreatBritain.houseofStuart.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.monarchsOfGreatBritain.theCommonwealth
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfGreatBritain.theCommonwealth.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.monarchsOfGreatBritain.houseOfStuartRestored
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfGreatBritain.houseOfStuartRestored.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.monarchsOfGreatBritain.houseOfOrangeAndStuart
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfGreatBritain.houseOfOrangeAndStuart.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.monarchsOfGreatBritain.houseOfStuart
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.monarchsOfGreatBritain.houseOfStuart.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h2 className="category-subtitle centre">
            British Monarchs After Union Of England And Scotland
          </h2>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.britishMonarchsAfterUnionEandS.houseOfBrunswick
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.britishMonarchsAfterUnionEandS.houseOfBrunswick.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.britishMonarchsAfterUnionEandS
                .houseOfSaxeCoburgGotha.kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.britishMonarchsAfterUnionEandS.houseOfSaxeCoburgGotha.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
          <div className="line-even-margin"></div>
          <h3 className="container-third-subtitle">
            {
              kingsAndQueens.britishMonarchsAfterUnionEandS.houseOfWindsor
                .kingsAndQueensName
            }
          </h3>
          <div className="two-column-flex">
            {kingsAndQueens.britishMonarchsAfterUnionEandS.houseOfWindsor.kingsAndQueensmonarchs.map(
              (kq) => (
                <p key={kq} className="lineheight-dbl">
                  {kq}
                </p>
              )
            )}
          </div>
        </div>
      </section>

      <AsideNav />
      <Footer />
    </div>
  );
}

export default LearnKingAndQueens;
