import React from "react";
import Toggler2 from "../Toggler2";
import "../css/QuestionBankQandA.css";
import "../css/questionbank.css";
import "../css/covered.css";
import "../css/artists.css";
import "../css/firstLine.css";

function FirstLine(props) {
  return (
    <div className="first-line-container">
      <p className="first-line">"{props.firstLine}"</p>
      <div>
        {props.tog ? (
          <Toggler2
            key={props.id}
            author={props.author}
            year={props.year}
            book={props.book}
            bookImg={props.bookImg}
            linkToBuy={props.linkToBuy}
          />
        ) : (
          <div className="first-line-answers">
            <p className="first-line-book">
              {props.book}
              <span className="first-line-year">({props.year})</span>
            </p>
            <p className="first-line-author">by {props.author}</p>
            <div className="book-img-container">
              {/* <a href={props.linkToBuy} target="_blank"> */}
              <img className="book-img" src={props.bookImg} />
              {/* </a> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FirstLine;
