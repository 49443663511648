import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Shop() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Shop</h1>
        <div className="container category-flex-container">
          <p className="body-text">
            We plan to run a shop selling quizzy items. You can see some of our
            artwork on our youtube channel, where we do a quiz using word art to
            make famous people's portraits. Prints of these pictures will be for
            sale, framed or unframed.
            <br />
            There will also be calendars, and maybe a quiz book containing
            questions and answers from our question bank.
          </p>
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-small17.png")}
            className="peep-learning-right"
            alt="smiling cartoon character"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Shop;
