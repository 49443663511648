import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import { historyAndCivilisation } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive
      key={topFive.key}
      col1={topFive.col1}
      col2={topFive.col2}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}

function LearnLanguageRecords() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Language Records</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                The most widely spoken languages in the world.
              </p>
              <p className="body-text">All correct as of August 2022.</p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small23.png")}
              alt="cartoon character with orange tshirt and brown hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Spoken Languages
              </h2>
              <div className="sidebar-line"></div>
              {historyAndCivilisation.languages.mostSpokenHeadings.map(
                ArrangeTopFiveHeading
              )}
              {historyAndCivilisation.languages.mostSpoken.map(ArrangeTopFive)}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most Native Speakers
              </h2>
              <div className="sidebar-line"></div>
              {historyAndCivilisation.languages.nativeSpeakersHeadings.map(
                ArrangeTopFiveHeading
              )}
              {historyAndCivilisation.languages.nativeSpeakers.map(
                ArrangeTopFive
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">
                Top 5 Most words in dictionary
              </h2>
              <div className="sidebar-line"></div>
              {historyAndCivilisation.languages.mostWordsInDictionaryHeadings.map(
                ArrangeTopFiveHeading
              )}
              {historyAndCivilisation.languages.mostWordsInDictionary.map(
                ArrangeTopFive
              )}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnLanguageRecords;
