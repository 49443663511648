import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Alterego from "./Alterego";
import { ologys } from "../../LearnSpecificCategoryDetailsThree";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeAlterEgos(ology) {
  return <Alterego key={ology.id} original={ology.ology} new={ology.studyOf} />;
}

function LearnOlogys() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Ologies</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Words that end in 'ology' that relate to fields of study. <br />
                The ology word is in white and the orange text below it says
                what it is the the study of.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small2.png")}
              alt="cartoon character with black hair and orange shirt"
            />
          </div>
          <div className="line-subtitle"></div>

          <div className="alter-ego-list-container">
            {ologys.map(ArrangeAlterEgos)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnOlogys;
