import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import AsideNav from "../AsideNav";
import LearnTopFiveOneCol from "./LearnTopFiveOneCol";
import { militaryRanks } from "../../LearnSpecificCategoryDetailsSix";

function ArrangeTopFive(topFive) {
  return <LearnTopFiveOneCol key={topFive.key} col1={topFive.col1} />;
}

function LearnMilitaryRanks() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Military Ranks</h1>
        <div className="container">
          {/* <h2 className="category-subtitle centre">Acronyms & Initialisms</h2> */}
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                Military Ranks of the British Armed Forces, in order from lowest
                rank at the top, to highest rank at the bottom of each list.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small10.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-even-margin"></div>
          <div className="top5s-container">
            <div className="top5OneCol">
              <h2>British Army Officer Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.armyOfficerRanks.map(ArrangeTopFive)}
            </div>
            <div className="top5OneCol">
              <h2>British Army Soldier Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.armySoldierRanks.map(ArrangeTopFive)}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="top5s-container">
            <div className="top5OneCol">
              <h2>British Royal Navy Officer Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.navyOfficerRanks.map(ArrangeTopFive)}
            </div>
            <div className="top5OneCol">
              <h2>British Royal Navy Ratings Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.navyRatingsRanks.map(ArrangeTopFive)}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="top5s-container">
            <div className="top5OneCol">
              <h2>RAF Commisioned Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.rafCommisionedRanks.map(ArrangeTopFive)}
            </div>
            <div className="top5OneCol">
              <h2>RAF Non-Commisioned Aircrew Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.rafNonCommisionedAirCrewRanks.map(ArrangeTopFive)}
            </div>
            <div className="top5OneCol">
              <h2>RAF Non-commissioned Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.rafNonCommisionedRanks.map(ArrangeTopFive)}
            </div>
          </div>
          <div className="line-even-margin"></div>
          <div className="top5s-container">
            <div className="top5OneCol">
              <h2>Royal Marines Ranks</h2>
              <div className="sidebar-line"></div>
              {militaryRanks.royalMarinesRanks.map(ArrangeTopFive)}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LearnMilitaryRanks;
