import React from "react";
import "../../css/top5.css";

function LearnTopFiveFourCols(props) {
  return (
    <div className="top5flex">
      <h2
        className={
          "top5-four-cols " +
          (props.col5 === "No" || (props.col4 === "No" ? "retired" : undefined))
        }
      >
        {props.col1}
      </h2>
      <h2
        className={
          "top5-four-cols " +
          (props.col5 === "No" || (props.col4 === "No" ? "retired" : undefined))
        }
      >
        {props.col2}
      </h2>
      <h2
        className={
          "top5-four-cols " +
          (props.col5 === "No" || (props.col4 === "No" ? "retired" : undefined))
        }
      >
        {props.col3}
      </h2>
      <h2
        className={
          "top5-four-cols " +
          (props.col5 === "No" || (props.col4 === "No" ? "retired" : undefined))
        }
      >
        {props.col4}
      </h2>
    </div>
  );
}

export default LearnTopFiveFourCols;
