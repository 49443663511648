import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import USState from "../USState";
import { americanStates } from "../LearnSpecificCategoryDetailsFive";
import "../css/us-states-map.css";
import "../css/us-states.css";
import "../css/style.css";

function arrangeUSStates(us) {
  return (
    <USState
      key={us.id}
      stateCode={us.stateCode}
      stateName={us.stateName}
      stateCapital={us.stateCapital}
      mostPopulousCity={us.mostPopulousCity}
      stateFlag={us.stateFlag}
    />
  );
}

function USStates() {
  const [stateNames, setStateNames] = useState([
    "alabama",
    "alaska",
    "arizona",
    "arkansas",
    "california",
    "colorado",
    "connecticut",
    "delaware",
    "florida",
    "georgia",
    "hawaii",
    "idaho",
    "illinois",
    "indiana",
    "iowa",
    "kansas",
    "kentucky",
    "louisiana",
    "maine",
    "maryland",
    "massachusetts",
    "michigan",
    "minnesota",
    "mississippi",
    "missouri",
    "montana",
    "nebraska",
    "nevada",
    "new hampshire",
    "new jersey",
    "new mexico",
    "new york",
    "north carolina",
    "north dakota",
    "ohio",
    "oklahoma",
    "oregon",
    "pennsylvania",
    "rhode island",
    "south carolina",
    "south dakota",
    "tennessee",
    "texas",
    "utah",
    "vermont",
    "virginia",
    "washington",
    "west virginia",
    "wisconsin",
    "wyoming",
  ]);
  const [answers, setAnswers] = useState([]);
  const [correctGuesses, setCorrectGuesses] = useState([]);
  const [missed, setMissed] = [];
  const [answer, setAnswer] = useState("");
  const [count, setCount] = useState(0);
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [giveUp, setGiveUp] = useState(false);
  const inputRef = useRef();
  const correctAnswerMapRef = useRef();

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerOn]);

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      correctAnswerMapRef.current.classList.remove("correct-answer-map");
    }, 1500);
  }, [count]);

  function focus() {
    inputRef.current && inputRef.current.focus();
  }

  function handleChange(e) {
    if (!giveUp) {
      setAnswer(e.target.value.trim());
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    inputRef.current.value = "";
    if (!giveUp) {
      checkAnswer();
      focus();
    }
  }

  function handleKeyDown(e) {
    if (e.code === "Enter" && !giveUp) {
      handleSubmit(e);
    }
  }

  function giveUpAndRevealAnswers() {
    setGiveUp(true);
    inputRef.current.disabled = true;
    for (let x = 0; x < stateNames.length; x++) {
      if (
        !answers.includes(
          stateNames[x].toLocaleLowerCase().split(" ").join("-")
        )
      ) {
        setTimerOn(false);
        setAnswers((current) => [
          ...current,
          stateNames[x].toLocaleLowerCase().split(" ").join("-"),
        ]);
      }
    }
  }

  function playAgain() {
    inputRef.current.disabled = false;
    inputRef.current.value = "";
    setAnswers([]);
    setCount(0);
    setGiveUp(false);
    setTime(0);
    setTimerOn(false);
    setCorrectGuesses([]);
    focus();
  }

  function checkAnswer() {
    if (
      stateNames.includes(answer.toLowerCase()) &&
      !answers.includes(answer.toLowerCase().split(" ").join("-"))
    ) {
      setAnswers((current) => [
        ...current,
        answer.toLowerCase().split(" ").join("-"),
      ]);

      correctAnswerMapRef.current.classList.add("correct-answer-map");
      setCount((count) => count + 1);
      let answerLower = answer.toLowerCase().split(" ");
      for (let x = 0; x < answerLower.length; x++) {
        answerLower[x] =
          answerLower[x].charAt(0).toUpperCase() + answerLower[x].substring(1);
      }
      let answerCapitalized = answerLower.join(" ");
      setCorrectGuesses((current) => [...current, answerCapitalized]);
    }
    if (!timerOn) {
      setTimerOn(true);
    }
    if (count >= 49) {
      setTimerOn(false);
    }
  }

  return (
    <div className="hero push-footer">
      <Header />

      <section className="drop-section-content">
        <div className="us-map-game-flex">
          <div className="your-category us-states-count">Correct: {count}</div>
          <div className="us-states-timer">
            <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          </div>
          <form className="us-states-form" onKeyDown={handleKeyDown}>
            <input
              type="text"
              ref={inputRef}
              className="us-states-input"
              onChange={handleChange}
            />

            <button onClick={handleSubmit} className="us-states-btn">
              Submit
            </button>
          </form>
        </div>

        <div className="us-states-game-container">
          <div ref={correctAnswerMapRef} className="div-hidden">
            {correctGuesses[count - 1]}
          </div>
          <img
            className="us-states-map"
            src={require("../images/map/usa2.jpg")}
          />

          {answers &&
            answers.map((a) => (
              <img
                key={a}
                className={`us-state-${a}`}
                src={require(`../images/map/${a}1.png`)}
              />
            ))}
        </div>

        <div className="container">
          <div className="us-states-buttons-container">
            <button
              className="us-states-give-up-btn"
              onClick={giveUpAndRevealAnswers}
            >
              Give Up
            </button>
            <button className="us-states-play-again-btn" onClick={playAgain}>
              Play Again
            </button>
          </div>
        </div>
      </section>
      {/* <div ref={correctAnswerMapRef} className="div-hidden">
        {correctGuesses[count - 1]}
      </div> */}
      {answers.length >= 50 || giveUp ? (
        <section className="us-states-results">
          <div className="count-result-container">
            <span>YOU GOT</span>
            <span className="count-result">
              {count !== 50 ? count : "ALL 50"}
            </span>
            <span>STATES IN</span>
          </div>
          <div className="us-states-timer">
            <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:
            <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* <div>
        Correct:
        {correctGuesses.sort().map((cG) => (
          <p key={cG}>{cG}</p>
        ))}
      </div> */}
      {correctGuesses.length ? <div className="line-even-margin"></div> : ""}
      {correctGuesses.length ? (
        <>
          <p className="state-cards-missed-correct">Correct:</p>
          <div className="state-cards-container">
            {americanStates
              .filter((x) => correctGuesses.includes(x.stateName))
              .sort()
              .map(arrangeUSStates)}
          </div>
        </>
      ) : (
        ""
      )}
      {giveUp && <div className="line-even-margin"></div>}
      {giveUp && (
        <>
          <p className="state-cards-missed-correct">Missed:</p>
          <div className="state-cards-container">
            {americanStates
              .filter((x) => !correctGuesses.includes(x.stateName))
              .sort()
              .map(arrangeUSStates)}
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default USStates;
