import fr from "./flags-svg/fr.svg";
import es from "./flags-svg/es.svg";
import nl from "./flags-svg/nl.svg";
import it from "./flags-svg/it.svg";
import gb from "./flags-svg/gb.svg";
import us from "./flags-svg/us.svg";

const acronymsAndInitialisms = {
  intro:
    "The difference between acronyms and initialisms lies in how you say them. Acronyms are formed by using the first letter of each word in a phrase to form a new word. For example, 'buy one get one free' is shortened to the acronym 'BOGOF', which you'd say as 'bog off'. Whereas, 'AA' - alcoholics anonymous - is an initialism because is not pronounced 'aaah'.",
  computerInternetTechnology: [
    {
      id: "BUMP",
      aOri: "BUMP",
      meaning: "Bring Up My Post (Used in forums etc)",
    },
    {
      id: "CAPTCHA",
      aOri: "CAPTCHA",
      meaning:
        "Completely Automated Public Turing Test to tell Computers and Humans Apart",
    },
    {
      id: "CD-ROM",
      aOri: "CD-ROM",
      meaning: "Compact Disc Read-Only Memory",
    },
    {
      id: "DVD",
      aOri: "DVD",
      meaning:
        "Digital Versatile Disc or Digital Video Disc or perhaps no meaning at all just literally DVD",
    },

    {
      id: "GIF",
      aOri: "GIF",
      meaning: "Graphics Interchange Format",
    },
    {
      id: "JPEG",
      aOri: "JPEG",
      meaning: "Joint Photographic Experts Group",
    },
    {
      id: "LASER",
      aOri: "LASER",
      meaning: "Light Amplification by Stimulated Emission of Radiation",
    },
    {
      id: "PIN",
      aOri: "PIN",
      meaning: "Personal Identification Number",
    },
    {
      id: "PNG",
      aOri: "PNG",
      meaning: "Portable Network Graphics",
    },
    {
      id: "RADAR",
      aOri: "RADAR",
      meaning: "RAdio Detection And Ranging",
    },
    {
      id: "RAM",
      aOri: "RAM",
      meaning: "Random Access Memory",
    },
    {
      id: "SCUBA",
      aOri: "SCUBA",
      meaning: "Self-contained underwater breathing apparatus",
    },
    {
      id: "SIM",
      aOri: "SIM (in SIM card)",
      meaning: "Subscriber Identity Module",
    },
    {
      id: "SOHCAHTOA",
      aOri: "SOHCAHTOA",
      meaning:
        "Sine = Opposite / Adjacent, Cosine = Adjacent / Hypotenuse, Tangent = Opposite / Adjacent (Trigonometry)",
    },
    {
      id: "SONAR",
      aOri: "SONAR",
      meaning: "Sound navigation and ranging",
    },
    {
      id: "URL",
      aOri: "URL",
      meaning: "Uniform Resource Locator",
    },
    {
      id: "WiFi",
      aOri: "WiFi",
      meaning:
        "Often thought to stand for 'Wireless Fidelity', but actually doesn't stand for anything",
    },
    {
      id: "YAHOO",
      aOri: "YAHOO (search engine)",
      meaning: "Yet Another Hierarchical Officious Oracle",
    },
  ],
  popCulture: [
    {
      id: "ABBA",
      aOri: "ABBA",
      meaning:
        "Agnetha, Björn, Benny, Anni-Frid (the band members' first names)",
    },
    {
      id: "ELO",
      aOri: "ELO",
      meaning: "Electric Light Orchestra",
    },

    {
      id: "NERD",
      aOri: "NERD",
      meaning: "No-one Ever Really Dies",
    },
    {
      id: "*N-SYNC",
      aOri: "*N-SYNC",
      meaning:
        "The last letters of the members names: JustiN, ChriS, JoeY, JasoN, and JC.",
    },
    {
      id: "TLC",
      aOri: "TLC",
      meaning:
        "The first initial of the members names: T-Boz, Left Eye, and Chilli",
    },
  ],
  chatAndGaming: [
    {
      id: "AFK",
      aOri: "AFK",
      meaning: "Away From idboard",
    },
    {
      id: "BBL",
      aOri: "BBL",
      meaning: "Be Back Later",
    },
    {
      id: "BBS",
      aOri: "BBS",
      meaning: "Be Back Soon",
    },
    {
      id: "BEG",
      aOri: "BEG",
      meaning: "Big Evil Grin",
    },
    {
      id: "BOBFOC",
      aOri: "BOBFOC",
      meaning: "Body Off Baywatch, Face Off Crimewatch",
    },
    {
      id: "BRB",
      aOri: "BRB",
      meaning: "Be Right Back",
    },
    {
      id: "BTW",
      aOri: "BTW",
      meaning: "By the Way",
    },
    {
      id: "FOMO",
      aOri: "FOMO",
      meaning: "Fear Of Missing Out",
    },
    {
      id: "FPS",
      aOri: "FPS",
      meaning: "First Person Shooter",
    },
    {
      id: "FTW",
      aOri: "FTW",
      meaning: "For The Win",
    },
    {
      id: "GG",
      aOri: "GG",
      meaning: "Good Game",
    },
    {
      id: "GWTP",
      aOri: "GWTP",
      meaning: "Get With The Program",
    },
    {
      id: "HORSE",
      aOri: "HORSE (poker game)",
      meaning:
        "Texas Hold 'em, Omaha hi-low split-eight or better, Razz, Seven card Stud, Seven card stud hi-low split Eight or better",
    },
    {
      id: "HUD",
      aOri: "HUD",
      meaning: "Heads-Up Display",
    },
    {
      id: "IDK",
      aOri: "IDK",
      meaning: "I Don't Know",
    },
    {
      id: "IKR",
      aOri: "IKR",
      meaning: "I Know, Right",
    },
    {
      id: "IMHO",
      aOri: "IMHO",
      meaning: "In My Humble Opinion",
    },
    {
      id: "IMO",
      aOri: "IMO",
      meaning: "In My Opinion",
    },
    {
      id: "IRL",
      aOri: "IRL",
      meaning: "In Real Life",
    },
    {
      id: "LMAO",
      aOri: "LMAO",
      meaning: "Laughing My Ass Off",
    },
    {
      id: "LMK",
      aOri: "LMK",
      meaning: "Let Me Know",
    },
    {
      id: "LOL",
      aOri: "LOL",
      meaning: "Laughing Out Loud",
    },
    {
      id: "MMORPG",
      aOri: "MMORPG",
      meaning: "Massively Multiplayer Online Role Playing Game",
    },
    {
      id: "OMG",
      aOri: "OMG",
      meaning: "Oh My God/Goodness",
    },
    {
      id: "ROFL",
      aOri: "ROFL",
      meaning: "Rolling On the Floor Laughing",
    },
    {
      id: "RPG",
      aOri: "RPG",
      meaning: "Role Playing Game",
    },
    {
      id: "TTFN",
      aOri: "TTFN",
      meaning: "Ta Ta For Now",
    },
    {
      id: "TTYL",
      aOri: "TTYL",
      meaning: "Talk To You Later",
    },
    {
      id: "WTF",
      aOri: "WTF",
      meaning: "What the F**k",
    },
    {
      id: "WTH",
      aOri: "WTH",
      meaning: "What the Hell/Heck",
    },
    {
      id: "YOLO",
      aOri: "YOLO",
      meaning: "You Only Live Once",
    },
  ],
  commonlyUsed: [
    {
      id: "AKA",
      aOri: "AKA",
      meaning: "Also Known As",
    },
    {
      id: "AM/PM",
      aOri: "AM/PM",
      meaning: "Ante meridiem and post meridiem",
    },
    {
      id: "ASAP",
      aOri: "ASAP",
      meaning: "As Soon As Possible",
    },
    {
      id: "BOGOF",
      aOri: "BOGOF",
      meaning: "Buy One Get One Free",
    },
    {
      id: "DOB",
      aOri: "DOB",
      meaning: "Date Of Birth",
    },
    {
      id: "DIY",
      aOri: "DIY",
      meaning: "Do It Yourself",
    },
    {
      id: "ETA",
      aOri: "ETA",
      meaning: "Estimated Time of Arrival",
    },
    {
      id: "FAQ",
      aOri: "FAQ",
      meaning: "Frequently Asked Question",
    },
    {
      id: "FYI",
      aOri: "FYI",
      meaning: "For Your Information",
    },
    {
      id: "KISS",
      aOri: "KISS",
      meaning: "Keep It Simple Stupid",
    },
    {
      id: "NIMBY",
      aOri: "NIMBY",
      meaning: "Not In My Back Yard",
    },
    {
      id: "P.S.",
      aOri: "P.S.",
      meaning: "Postscript",
    },
    {
      id: "RIP",
      aOri: "RIP",
      meaning: "Rest In Peace",
    },
    {
      id: "SOS",
      aOri: "SOS",
      meaning:
        "Often said to mean 'Save Our Ship/Souls', but technically doesn't stand for anything",
    },
    {
      id: "STAT",
      aOri: "STAT",
      meaning: "From the Latin word 'statim' which means 'immediately'",
    },
    {
      id: "TBA",
      aOri: "TBA",
      meaning: "To Be Announced",
    },
    {
      id: "UFO",
      aOri: "UFO",
      meaning: "Unidentified Flying Object",
    },
    {
      id: "WYSIWYG",
      aOri: "WYSIWYG",
      meaning: "(pronounced 'wizzywig') What You See Is What You Get",
    },
  ],
  scienceAndMaths: [
    {
      id: "BIDMAS",
      aOri: "BIDMAS (Order of mathematical operations)",
      meaning:
        "Brackets, Indices, Division, Multiplication, Addition, Subtraction",
    },
    {
      id: "MRSGREN",
      aOri: "MRS GREN (7 characteristics of living things)",
      meaning:
        "Movement, Respiration, Sensitivity, Growth, Reproduction, Excretion and Nutrition",
    },
    {
      id: "PEMDAS",
      aOri: "PEMDAS (US version Order of mathematical operations)",
      meaning:
        "Parentheses, Exponents, Multiplication and Division (from left to right), Addition and Subtraction (from left to right)",
    },
  ],
  brandsAndOrganisations: [
    {
      id: "BAFTA",
      aOri: "BAFTA",
      meaning: "British Academy of Film and Television Arts",
    },
    {
      id: "CERN",
      aOri: "CERN",
      meaning:
        "Conseil Européen pour la Recherche Nucléaire (European Council for Nuclear Research)",
    },
    {
      id: "FIFA",
      aOri: "FIFA",
      meaning: "Fédération Internationale de Football Association",
    },
    {
      id: "IKEA",
      aOri: "IKEA",
      meaning:
        "The founder's name (Ingvar Kamprad), his family farm (Elmtaryd), and his hometown (Agunnary)",
    },
    {
      id: "NASA",
      aOri: "NASA",
      meaning: "National Aeronautics and Space Administration",
    },
    {
      id: "UNICEF",
      aOri: "UNICEF",
      meaning:
        "Originally: United Nations International Children's Emergency Fund, now officially: United Nations Children's Fund",
    },
  ],
  medical: [
    {
      id: "ADD",
      aOri: "ADD",
      meaning: "Attention Deficit Disorder",
    },
    {
      id: "ADHD",
      aOri: "ADHD",
      meaning: "Attention Deficit Hyperactivity Disorder",
    },
    {
      id: "AIDS",
      aOri: "AIDS",
      meaning: "Acquired Immuno Deficiency Syndrome",
    },
    {
      id: "BMI",
      aOri: "BMI",
      meaning: "Body Mass Index",
    },
    {
      id: "COVID-19",
      aOri: "COVID-19",
      meaning: "COronaVIrus Disease of 2019",
    },
    {
      id: "CPAP",
      aOri: "CPAP",
      meaning: "Continuous Positive Airway Pressure",
    },
    {
      id: "DNR",
      aOri: "DNR",
      meaning: "Do Not Resuscitate",
    },
    {
      id: "DOA",
      aOri: "DOA",
      meaning: "Dead On Arrival",
    },
    {
      id: "HIV",
      aOri: "HIV",
      meaning: "Human Immuno deficiency Virus",
    },
    {
      id: "ICU",
      aOri: "ICU",
      meaning: "Intensive Care Unit",
    },
    {
      id: "MERS",
      aOri: "MERS",
      meaning: "Middle East Respiratory Syndrome",
    },
    {
      id: "OTC",
      aOri: "OTC",
      meaning: "Over The Counter",
    },
    {
      id: "SARS",
      aOri: "SARS",
      meaning: "Severe Acute Respiratory Syndrome",
    },
    {
      id: "RICE",
      aOri: "RICE",
      meaning: "Rest, Ice, Compress, Elevate",
    },
  ],
  militaryAndGovernment: [
    {
      id: "AWOL",
      aOri: "AWOL",
      meaning: "Absent WithOut Leave",
    },
    {
      id: "CIA",
      aOri: "CIA",
      meaning: "Central Intelligence Agency",
    },
    {
      id: "FBI",
      aOri: "FBI",
      meaning: "Federal Bureau of Investigation",
    },
    {
      id: "FLOTUS",
      aOri: "FLOTUS",
      meaning: "First Lady Of The United States",
    },
    {
      id: "FUBAR",
      aOri: "FUBAR",
      meaning: "F***ed Up Beyond All Recognition",
    },
    {
      id: "MIA",
      aOri: "MIA",
      meaning: "Missing In Action",
    },
    {
      id: "NATO",
      aOri: "NATO",
      meaning: "North Atlantic Treaty Organization",
    },
    {
      id: "POTUS",
      aOri: "POTUS",
      meaning: "President Of The United States",
    },
    {
      id: "POW",
      aOri: "POW",
      meaning: "Prisoner Of War",
    },
    {
      id: "SWAT",
      aOri: "SWAT",
      meaning: "Special Weapons And Tactics",
    },
    {
      id: "UN",
      aOri: "UN",
      meaning: "United Nations",
    },
  ],
  sport: [
    {
      id: "AET",
      aOri: "AET",
      meaning: "After Extra Time",
    },
    {
      id: "DNF",
      aOri: "DNF",
      meaning: "Did Not Finish",
    },
    {
      id: "DNS",
      aOri: "DNS",
      meaning: "Did Not Start",
    },
    {
      id: "VAR",
      aOri: "VAR",
      meaning: "Video Assistant Referee",
    },
  ],
  other: [
    {
      id: "PALE GAS",
      aOri: "PALE GAS",
      meaning:
        "Seven deadly sins: Pride, Anger, Lust, Envy, Gluttony, Avarice, Sloth",
    },
  ],
  poems: [
    "In fourteen hundred ninety-two, Columbus sailed the ocean blue",
    "I before E except after C or when sounding like A as in neighbour and weigh",
    "Red sky at night Shepherd's delight, Red sky in the morning Shepherd's warning",
  ],
};

const artists = {
  frenchArtists: [
    {
      id: "EUGÈNE DELACROIX",
      artistName: "EUGÈNE DELACROIX",
      flag: <img src={fr} />,
      lifespan: "1798 - 1863",
      birthplace: "Saint-Maurice",
      artMovement: "Romanticism",
      famousPieces: [
        {
          piece: "Liberty Leading the People (1830)",
          link: "https://commons.wikimedia.org/wiki/File:Eug%C3%A8ne_Delacroix_-_Liberty_Leading_the_People_(28th_July_1830)_-_WGA6177.jpg",
        },
      ],
      notes:
        "He was a id influencer in the Impressionism art movement because of his style of expressive brushstrokes and brilliant use of colour",
    },
    {
      id: "ÉDOUARD MANET",
      artistName: "ÉDOUARD MANET",
      flag: <img src={fr} />,
      lifespan: "1832 - 1883",
      birthplace: "Paris",
      artMovement: "Realism, Impressionism",
      famousPieces: [
        {
          piece: "Déjeuner sur l'herbe (The Luncheon on the Grass) (1863)",
          link: "https://commons.wikimedia.org/wiki/File:%C3%89douard_Manet_-_Le_D%C3%A9jeuner_sur_l%27herbe.jpg",
        },
        {
          piece: "Olympia (1863)",
          link: "https://commons.wikimedia.org/wiki/File:Olympia-manet.jpg",
        },
        {
          piece: "Bullfight (1865-1866)",
          link: "https://commons.wikimedia.org/wiki/File:%C3%89douard_Manet_-_Bullfight_-_1937.1019_-_Art_Institute_of_Chicago.jpg",
        },
      ],
      notes:
        "Some of his early works caused great controversy and criticism at the time as they depicted nudes",
    },
    {
      id: "EDGAR DEGAS",
      artistName: "EDGAR DEGAS",
      flag: <img src={fr} />,
      lifespan: "1834 - 1917",
      birthplace: "Paris",
      artMovement: "Impressionism",
      famousPieces: [
        {
          piece: "In a cafe (1879) - aka The Absinthe Drinker",
          link: "https://commons.wikimedia.org/wiki/File:%22L%27Absinthe%22,_par_Edgar_Degas_(1876).jpg",
        },
        {
          piece: "Little Dancer Aged Fourteen (1881) - sculpture",
          link: "https://commons.wikimedia.org/wiki/File:Danseuse2_degas_Musee_Orsay.jpg",
        },
      ],
      notes:
        "Often painted ballerinas, prefered painting them in rehearsal rather than performing on stage",
    },
    {
      id: "PAUL CÉZANNE",
      artistName: "PAUL CÉZANNE",
      flag: <img src={fr} />,
      lifespan: "1839 - 1906",
      birthplace: "Aix-en-Provence",
      artMovement: "Post-Impressionism",
      famousPieces: [
        {
          piece: "The Large Bathers (1905)",
          link: "https://commons.wikimedia.org/wiki/File:Paul_C%C3%A9zanne,_French_-_The_Large_Bathers_-_Google_Art_Project.jpg",
        },
      ],
      notes:
        "In some early works he used a palette knife, he simplified structures from realistic to more basic shapes, eg spheres and cylinders",
    },
    {
      id: "CLAUDE MONET",
      artistName: "CLAUDE MONET",
      flag: <img src={fr} />,
      lifespan: "1840 - 1926",
      birthplace: "Rue Laffitte, Paris",
      artMovement: "Impressionism",
      famousPieces: [
        {
          piece: "Impression, Sunrise (1872)",
          link: "https://commons.wikimedia.org/wiki/File:Monet_-_Impression,_Sunrise.jpg",
        },
        {
          piece: "Water Lilies series (1896 - 1926)",
          link: "https://commons.wikimedia.org/wiki/File:Water-Lily_Pond_1919_Claude_Monet_Metropolitan.jpg",
        },
      ],
      notes:
        "Known as the founder of impressionism, the term 'impressionism' literally comes from the title of his painting 'Impression, Sunrise'",
    },
    {
      id: "AUGUSTE RODIN",
      artistName: "AUGUSTE RODIN",
      flag: <img src={fr} />,
      lifespan: "1840 - 1917",
      birthplace: "Paris",
      artMovement: "Sculpture - realism",
      famousPieces: [
        {
          piece: "The Kiss (1889)",
          link: "https://commons.wikimedia.org/wiki/File:Rodin_TheKiss_20050609.JPG",
        },
        {
          piece: "The Thinker (1902)",
          link: "https://commons.wikimedia.org/wiki/File:The_Thinker,_Rodin.jpg",
        },
      ],
      notes: "Is referred to as the 'Father of modern sculpture'",
    },
    {
      id: "PIERRE-AUGUSTE RENOIR",
      artistName: "PIERRE-AUGUSTE RENOIR",
      flag: <img src={fr} />,
      lifespan: "1841 - 1919",
      birthplace: "Limoges",
      artMovement: "Impressionism",
      famousPieces: [
        {
          piece: "Dance at Le Moulin de la Galette (1876)",
          link: "https://commons.wikimedia.org/wiki/File:Dance_at_Le_Moulin_de_la_Galette,_Mus%C3%A9e_d%27Orsay,_3_July_2007.jpg",
        },
      ],
      notes:
        "Paintings by Renoir can be distinguished from others of the time by the vibrant light and saturated colour, his works often had a focus on people",
    },
    {
      id: "PAUL GAUGUIN",
      artistName: "PAUL GAUGUIN",
      flag: <img src={fr} />,
      lifespan: "1848 - 1903",
      birthplace: "Paris",
      artMovement: "Post-Impressionism",
      famousPieces: [
        {
          piece:
            "Where Do We Come From, What Are We, Where Are We Going (1898)",
          link: "https://commons.wikimedia.org/wiki/File:Paul_Gauguin_-_D%27ou_venons-nous.jpg",
        },
      ],
      notes:
        "Moved to Tahiti and his experiences and observations there inspired his work",
    },
    {
      id: "HENRI DE TOULOUSE-LAUTREC",
      artistName: "HENRI DE TOULOUSE-LAUTREC",
      flag: <img src={fr} />,
      lifespan: "1864 - 1901",
      birthplace: "Albi",
      artMovement: "Post-Impressionism",
      famousPieces: [
        {
          piece: "At the Moulin Rouge, The Dance (1890)",
          link: "https://commons.wikimedia.org/wiki/File:Henri_de_Toulouse-Lautrec,_French_-_At_the_Moulin_Rouge-_The_Dance_-_Google_Art_Project.jpg",
        },
        {
          piece: "Le Divan Japonais (1892)",
          link: "https://commons.wikimedia.org/wiki/File:(Albi)_Le_Divan_japonais_1893_lithographie_-_Toulouse-Lautrec_MTH.A3.jpg",
        },
      ],
      notes:
        "He enjoyed the nightlife in Paris in late 19th century and had a strong connection to the Moulin Rouge where he would paint portraits of the women, he died aged 36",
    },
    {
      id: "HENRI MATISSE",
      artistName: "HENRI MATISSE",
      flag: <img src={fr} />,
      lifespan: "1869 - 1954",
      birthplace: "Le Cateau-Cambrésis",
      artMovement: "Fauvism",
      famousPieces: [
        {
          piece: "Dance (1910)",
          link: "https://commons.wikimedia.org/wiki/File:Henri_Matisse,_Dance_(50393383138).jpg",
        },
      ],
      notes:
        "Matisse invented a new medium called the paper cut-out, where he made collages from coloured paper he had cut into shapes",
    },
    {
      id: "MARCEL DUCHAMP",
      artistName: "MARCEL DUCHAMP",
      flag: <img src={fr} />,
      lifespan: "1887 - 1968",
      birthplace: "Blainville-Crevon",
      artMovement: "Cubism/ Dada/ Surrealism/ Conceptual",
      famousPieces: [
        {
          piece: "Nude Descending a Staircase, No. 2 (1912)",
          link: "https://en.wikipedia.org/wiki/Nude_Descending_a_Staircase,_No._2#/media/File:Duchamp_-_Nude_Descending_a_Staircase.jpg",
        },
        {
          piece: "Fountain (1917)",
          link: "https://commons.wikimedia.org/wiki/File:Duchamp_Fountaine.jpg",
        },
      ],
      notes:
        "He was a competitive chess player. Some of his art is known as 'Readymades' - found objects that are presented as art, sometimes with modifications",
    },
  ],
  spanishArtists: [
    {
      id: "EL GRECO",
      artistName: "EL GRECO",
      flag: <img src={es} />,
      lifespan: "1541 - 1614",
      birthplace: "Heraklion, Greece",
      artMovement: "Spanish Renaissance",
      famousPieces: [
        {
          piece: "Adoration of the Magi (1565-1567)",
          link: "https://commons.wikimedia.org/wiki/File:Gerard_David_-_Adoration_of_the_Kings_-_Google_Art_Project.jpg",
        },
        {
          piece: "The Burial of the Count of Orgaz (1588)",
          link: "https://commons.wikimedia.org/wiki/File:El_Greco_-_The_Burial_of_the_Count_of_Orgaz.JPG",
        },
      ],
      notes:
        "He was a student of Titian, and influenced the work of Picasso. He criticised Michelangelo's art and offered to paint over the 'Last Judgement' in the Sistine Chapel",
    },
    {
      id: "DIEGO VELÁZQUEZ",
      artistName: "DIEGO VELÁZQUEZ",
      flag: <img src={es} />,
      lifespan: "1599 - 1660",
      birthplace: "Seville",
      artMovement: "Baroque",
      famousPieces: [
        {
          piece: "Retrato de Inocencio (Portrait of Innocent X) (1650)",
          link: "https://commons.wikimedia.org/wiki/File:Retrato_del_Papa_Inocencio_X._Roma,_by_Diego_Vel%C3%A1zquezFXD.jpg",
        },
        {
          piece: "Las Meninas (The Maids of Honour) (1656)",
          link: "https://commons.wikimedia.org/wiki/File:Las_Meninas,_by_Diego_Vel%C3%A1zquez,_from_Prado_in_Google_Earth.jpg",
        },
      ],
      notes:
        "He was a favourite artist of King Philip VI of Spain and Portugal, and painted several portraits of King Philip IV and Mariana of Austria's daughter, Margarita Teresa",
    },
    {
      id: "FRANCISCO GOYA",
      artistName: "FRANCISCO GOYA",
      flag: <img src={es} />,
      lifespan: "1746 - 1828",
      birthplace: "Fuendetodos",
      artMovement: "Romanticism",
      famousPieces: [
        {
          piece: "La Maja Desnuda (The Nude Maja) (1797 - 1800)",
          link: "https://commons.wikimedia.org/wiki/File:Maja_desnuda_(museo_del_Prado).jpg",
        },
        {
          piece:
            "El tres de mayo de 1808 en Madrid (The Third of May 1808) (1814)",
          link: "https://commons.wikimedia.org/wiki/File:El_Tres_de_Mayo,_by_Francisco_de_Goya,_from_Prado_thin_black_margin.jpg",
        },
      ],
      notes:
        "La Maja Desnuda has an almost identical counterpart where the same woman lies in the same reclined pose but this time she is fully dressed, entitled La Maja Vestida (The clothed Maja)",
    },
    {
      id: "PABLO PICASSO",
      artistName: "PABLO PICASSO",
      flag: <img src={es} />,
      lifespan: "1881 - 1973",
      birthplace: "Málaga",
      artMovement: "Cubism, Surrealism",
      famousPieces: [
        {
          piece: "The Old Guitarist (1903)",
          link: "https://en.wikipedia.org/wiki/The_Old_Guitarist#/media/File:Old_guitarist_chicago.jpg",
        },
        {
          piece: "Les Demoiselles d'Avignon (1907)",
          link: "https://en.wikipedia.org/wiki/Les_Demoiselles_d'Avignon#/media/File:Les_Demoiselles_d'Avignon.jpg",
        },
        {
          piece: "Guernica (1937)",
          link: "https://en.wikipedia.org/wiki/Guernica_(Picasso)#/media/File:PicassoGuernica.jpg",
        },
      ],
      notes:
        "His full name was Pablo Diego José Francisco de Paula Juan Nepomuceno María de los Remedios Cipriano de la Santísima Trinidad Martyr Patricio Clito Ruíz y Picasso",
    },
    {
      id: "JOAN MIRO",
      artistName: "JOAN MIRO",
      flag: <img src={es} />,
      lifespan: "1893 - 1983",
      birthplace: "Barcelona",
      artMovement: "Surrealism and precursor to Abstract Expressionism",
      famousPieces: [
        {
          piece: "The Farm (1922)",
          link: "https://en.wikipedia.org/wiki/The_Farm_(Mir%C3%B3)#/media/File:TheFarmMiro21to22.jpg",
        },
        {
          piece: "The Harlequin's Carnival (1925)",
          link: "https://en.wikipedia.org/wiki/The_Harlequin%27s_Carnival#/media/File:The_Harlequin's_Carnival.jpg",
        },
      ],
      notes:
        "His first exhibition in Spain was ridiculed, a couple of years after that he moved to Paris where his style was appreciated",
    },
    {
      id: "SALVADOR DALÍ",
      artistName: "SALVADOR DALÍ",
      flag: <img src={es} />,
      lifespan: "1904 - 1989",
      birthplace: "Figueres",
      artMovement: "Surrealism",
      famousPieces: [
        {
          piece: "The Persistence of Memory (1931)",
          link: "https://en.wikipedia.org/wiki/The_Persistence_of_Memory#/media/File:The_Persistence_of_Memory.jpg",
        },
      ],
      notes:
        "The Dalí Museum is situated in St. Petersburg, Florida. Salvador Dalí designed the Chupa Chups logo in 1969",
    },
  ],
  dutchArtists: [
    {
      id: "HIERONYMUS BOSCH",
      artistName: "HIERONYMUS BOSCH",
      flag: <img src={nl} />,
      lifespan: "1450 - 1516",
      birthplace: "'s-Hertogenbosch",
      artMovement: "Renaissance",
      famousPieces: [
        {
          piece: "The Garden of Earthly Delights (1503-1515) - triptych",
          link: "https://commons.wikimedia.org/wiki/File:The_Garden_of_Earthly_Delights_by_Bosch_High_Resolution.jpg",
        },
      ],
      notes:
        "As his works were often copied and his style was so influential, it is difficult to know which work is his, his signature is found on only 7 surviving pieces",
    },
    {
      id: "FRANS HALS",
      artistName: "FRANS HALS",
      flag: <img src={nl} />,
      lifespan: "1582 - 1666",
      birthplace: "Antwerp",
      artMovement: "Portraiture, Baroque",
      famousPieces: [
        {
          piece: "Laughing Cavalier (1624)",
          link: "https://commons.wikimedia.org/wiki/File:Cavalier_soldier_Hals-1624x.jpg",
        },
      ],
      notes:
        " Dutch Golden Age, most famous for portraiture and group portraiture",
    },
    {
      id: "REMBRANDT",
      artistName: "REMBRANDT",
      flag: <img src={nl} />,
      lifespan: "1606 - 1669",
      birthplace: "Leiden",
      artMovement: "Dutch Golden Age",
      famousPieces: [
        {
          piece: "The Storm on the Sea of Galilee (1633)",
          link: "https://commons.wikimedia.org/wiki/File:Rembrandt_Christ_in_the_Storm_on_the_Lake_of_Galilee.jpg",
        },
        {
          piece: "The Night Watch (1642)",
          link: "https://commons.wikimedia.org/wiki/File:The_Night_Watch_-_HD.jpg",
        },
      ],
      notes:
        "He is known as the master of light and shadow and his work showed amazing realism, painted various subject matter: self-portraits, portraits, landscapes, biblical scenes etc",
    },
    {
      id: "JOHANNES VERMEER",
      artistName: "JOHANNES VERMEER",
      flag: <img src={nl} />,
      lifespan: "1632 - 1675",
      birthplace: "Delft",
      artMovement: "Dutch Golden Age, Baroque",
      famousPieces: [
        {
          piece: "Girl with a Pearl Earring (1665)",
          link: "https://commons.wikimedia.org/wiki/File:1665_Girl_with_a_Pearl_Earring.jpg",
        },
      ],
      notes:
        "Although he was by no means a wealthy man, he would use expensive pigments such as lapis lazuli in his paintings",
    },
    {
      id: "VINCENT VAN GOGH",
      artistName: "VINCENT VAN GOGH",
      flag: <img src={nl} />,
      lifespan: "1853 - 1890",
      birthplace: "Zundert",
      artMovement: "Post-Impressionism",
      famousPieces: [
        {
          piece: "Vase with Fifteen Sunflowers (1888)",
          link: "https://commons.wikimedia.org/wiki/File:Van_Gogh_Vase_with_Fifteen_Sunflowers.jpg",
        },
        {
          piece: "The Starry Night (1889)",
          link: "https://commons.wikimedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg",
        },
      ],
      notes:
        "He cut off his left ear after an argument with fellow artist Gauguin, the story follows that he gifted the ear to a lady at a local brothel",
    },
    {
      id: "PIET MONDRIAN",
      artistName: "PIET MONDRIAN",
      flag: <img src={nl} />,
      lifespan: "1872 - 1944",
      birthplace: "Amersfoort",
      artMovement: "Cubism, Expressionism, Impressionism, De Stijl",
      famousPieces: [
        {
          piece: "Composition with Red, Yellow, and Blue (1930)",
          link: "https://en.wikipedia.org/wiki/Composition_with_Red_Blue_and_Yellow#/media/File:Piet_Mondriaan,_1930_-_Mondrian_Composition_II_in_Red,_Blue,_and_Yellow.jpg",
        },
        {
          piece: "Broadway Boogie-Woogie (1943)",
          link: "https://commons.wikimedia.org/wiki/File:Piet_Mondrian,_1942_-_Broadway_Boogie_Woogie.jpg",
        },
      ],
      notes:
        "De Stijl translates as 'The Style' was a Dutch artistic movement, the paintings of this style consist of simple shapes and primary colours",
    },
    {
      id: "M. C. ESCHER",
      artistName: "M. C. ESCHER",
      flag: <img src={nl} />,
      lifespan: "1898 - 1972",
      birthplace: "Leeuwarden",
      artMovement: "Graphic art, Modern art",
      famousPieces: [
        {
          piece: "Metamorphosis I (1937)",
          link: "https://en.wikipedia.org/wiki/Metamorphosis_I#/media/File:Escher,_Metamorphosis_I.jpg",
        },
        {
          piece: "Day and Night (1938)",
          link: "https://en.wikipedia.org/wiki/Day_and_Night_(M._C._Escher)#/media/File:Day_and_Night_by_M._C._Escher.jpg",
        },
        {
          piece: "Relativity (1953)",
          link: "https://en.wikipedia.org/wiki/Relativity_(M._C._Escher)#/media/File:Escher's_Relativity.jpg",
        },
      ],
      notes:
        "He was fond of maths and science and used this in his 'impossible art' work with strange angles and dimensions",
    },
    {
      id: "WILLEM DE KOONING",
      artistName: "WILLEM DE KOONING",
      flag: <img src={nl} />,
      lifespan: "1904 - 1997",
      birthplace: "Rotterdam",
      artMovement: "Abstract Expressionism",
      famousPieces: [
        {
          piece: "Woman I (1952)",
          link: "https://en.wikipedia.org/wiki/Woman_I#/media/File:Woman_I-Willem_de_Kooning.jpg",
        },
        {
          piece: "Woman III (1953)",
          link: "https://en.wikipedia.org/wiki/Willem_de_Kooning#/media/File:Woman3.jpg",
        },
      ],
      notes:
        "He moved to America as an illegal immigrant in 1926 and didn't become an American citizen until 1962",
    },
  ],
  italianArtists: [
    {
      id: "GIOTTO DI BONDONE",
      artistName: "GIOTTO",
      flag: <img src={it} />,
      lifespan: "c1267 - 1337",
      birthplace: "Vicchio",
      artMovement: "Gothic / Proto-Renaissance",
      famousPieces: [
        {
          piece: "Fresco cycle on the Scrovegni Chapel (1305)",
          link: "https://en.wikipedia.org/wiki/Scrovegni_Chapel#/media/File:Padova_Cappella_degli_Scrovegni_Innen_Langhaus_West_5.jpg",
        },
        {
          piece: "The Kiss of Judas (1306)",
          link: "https://commons.wikimedia.org/wiki/File:Giotto_di_Bondone_-_No._31_Scenes_from_the_Life_of_Christ_-_15._The_Arrest_of_Christ_(Kiss_of_Judas)_-_WGA09216.jpg",
        },
        {
          piece: "Ognissanti Madonna (1310)",
          link: "https://commons.wikimedia.org/wiki/File:Giotto,_1267_Around-1337_-_Maest%C3%A0_-_Google_Art_Project.jpg",
        },
      ],
      notes: "First genius of European painting, some say unrivalled",
    },
    {
      id: "SANDRO BOTTICELLI",
      artistName: "SANDRO BOTTICELLI",
      flag: <img src={it} />,
      lifespan: "c1445 - 1510",
      birthplace: "Florence",
      artMovement: "Early Renaissance",
      famousPieces: [
        {
          piece: "Adoration of the Magi (1476)",
          link: "https://en.wikipedia.org/wiki/Adoration_of_the_Magi_%28Botticelli,_1475%29#/media/File:Botticelli_-_Adoration_of_the_Magi_(Zanobi_Altar)_-_Uffizi.jpg",
        },
        {
          piece: "Primavera (1482)",
          link: "https://commons.wikimedia.org/wiki/File:Sandro_Botticelli_-_La_Primavera_-_Google_Art_Project.jpg",
        },
        {
          piece: "The Birth of Venus (1486)",
          link: "https://commons.wikimedia.org/wiki/File:Sandro_Botticelli_-_La_nascita_di_Venere_-_Google_Art_Project_-_edited.jpg",
        },
      ],
      notes:
        "Use of colour is an important part of his art. Botticelli is a knickname used by his brother and adopted by Sandro Botticelli, it means 'little barrel'",
    },
    {
      id: "LEONARDO DA VINCI",
      artistName: "LEONARDO DA VINCI",
      flag: <img src={it} />,
      lifespan: "1452 - 1519",
      birthplace: "Anchiano",
      artMovement: "Renaissance",
      famousPieces: [
        {
          piece: "The Last Supper (1498)",
          link: "https://commons.wikimedia.org/wiki/File:The_Last_Supper_-_Leonardo_Da_Vinci_-_High_Resolution_32x16.jpg",
        },
        {
          piece: "Mona Lisa (1517)",
          link: "https://commons.wikimedia.org/wiki/File:Mona_Lisa,_by_Leonardo_da_Vinci,_from_C2RMF_retouched.jpg",
        },
      ],
      notes:
        "Also a mathematician and inventor amongst other things and designed machines that were ahead of his time",
    },
    {
      id: "MICHELANGELO",
      artistName: "MICHELANGELO",
      flag: <img src={it} />,
      lifespan: "1475 - 1564",
      birthplace: "Caprese Michelangelo",
      artMovement: "High Renaissance",
      famousPieces: [
        { piece: "David (sculpture) (1501 - 1504)", link: "" },
        {
          piece:
            "fresco on the ceiling of the Sistine Chapel in the Vatican (1508 - 1512)",
          link: "https://en.wikipedia.org/wiki/Sistine_Chapel_ceiling#/media/File:Sistine_Chapel_ceiling_02_(brightened).jpg",
        },
        {
          piece: "The Creation of Adam (1512)",
          link: "https://commons.wikimedia.org/wiki/File:Creaci%C3%B3n_de_Ad%C3%A1n.jpg",
        },
      ],
      notes:
        "He considered sculpture as superior to painting and so described himself as a sculptor, not a painter",
    },
    {
      id: "TITIAN",
      artistName: "TITIAN",
      flag: <img src={it} />,
      lifespan: "c1488/90 - 1576",
      birthplace: "Pieve di Cadore",
      artMovement: "Renaissance",
      famousPieces: [
        {
          piece: "Assumption of the Virgin (1518)",
          link: "https://commons.wikimedia.org/wiki/File:Tizian_041.jpg",
        },
      ],
      notes:
        "Titian painted one of his final unfinished pieces 'Pietà' during the plague in Venice, he died of fever in 1576 and his son Orazio died soon after of the plague",
    },
    {
      id: "RAPHAEL",
      artistName: "RAPHAEL",
      flag: <img src={it} />,
      lifespan: "1483 - 1520",
      birthplace: "Urbino",
      artMovement: "High Renaissance",
      famousPieces: [
        {
          piece: "The School of Athens (1509)",
          link: "https://commons.wikimedia.org/wiki/File:%22The_School_of_Athens%22_by_Raffaello_Sanzio_da_Urbino.jpg",
        },
        {
          piece: "The Triumph of Galatea (c1512)",
          link: "https://en.wikipedia.org/wiki/Galatea_(Raphael)#/media/File:Galatea_Raphael.jpg",
        },
      ],
      notes: "He died on his 37th birthday",
    },
    {
      id: "CARAVAGGIO",
      artistName: "CARAVAGGIO",
      flag: <img src={it} />,
      lifespan: "1571 - 1610",
      birthplace: "Milan",
      artMovement: "Baroque, Renaissance",
      famousPieces: [
        {
          piece: "The Calling of St Matthew (1600)",
          link: "https://commons.wikimedia.org/wiki/File:The_Calling_of_Saint_Matthew-Caravaggo_(1599-1600).jpg",
        },
        {
          piece: "The Beheading of Saint John the Baptist (1608)",
          link: "https://commons.wikimedia.org/wiki/File:The_Beheading_of_Saint_John-Caravaggio_(1608).jpg",
        },
      ],
      notes:
        "Had a notoriously short temper, once killed a man over an argument about a tennis game!",
    },
    {
      id: "GIAN LORENZO BERNINI",
      artistName: "GIAN LORENZO BERNINI",
      flag: <img src={it} />,
      lifespan: "1598 - 1680",
      birthplace: "Naples",
      artMovement: "Sculpture",
      famousPieces: [
        {
          piece: "The Rape of Prosperina (1621-22)",
          link: "https://en.wikipedia.org/wiki/The_Rape_of_Proserpina#/media/File:The_Rape_of_Proserpina_(Rome).jpg",
        },
        {
          piece: "Apollo & Daphne (1622-1625)",
          link: "https://en.wikipedia.org/wiki/Apollo_and_Daphne_(Bernini)#/media/File:Apollo_and_Daphne_(Bernini)_(cropped).jpg",
        },
      ],
      notes:
        "Bernini was depicted on the Banca d'Italia 50,000 lira banknote in the 1980s and 90s",
    },
  ],
  americanArtists: [
    {
      id: "JAMES ABBOTT MCNEILL WHISTLER",
      artistName: "JAMES ABBOTT MCNEILL WHISTLER",
      flag: <img src={us} />,
      lifespan: "1834 - 1903",
      birthplace: "Lowell, Massachusetts",
      artMovement: "Tonalism",
      famousPieces: [
        {
          piece:
            "Arrangement in Grey and Black No. 1 (1871) - Better known as Whistler's Mother",
          link: "https://commons.wikimedia.org/wiki/File:Whistlers_Mother_high_res.jpg",
        },
      ],
      notes:
        "He followed the philosophy of 'Art for Art's Sake', the painting 'Whistler's Mother' was featured on a US postage stamp in 1934 due to the painting's popularity",
    },
    {
      id: "EDWARD HOPPER",
      artistName: "EDWARD HOPPER",
      flag: <img src={us} />,
      lifespan: "1882 - 1967",
      birthplace: "Upper Nyack, New York",
      artMovement: "Realism",
      famousPieces: [
        {
          piece: "Automat (1927)",
          link: "https://commons.wikimedia.org/wiki/File:Automat-edward-hopper-1927.jpg",
        },
        {
          piece: "Chop Suey (1929)",
          link: "https://commons.wikimedia.org/wiki/File:Edward_hopper_chop_suey.jpg",
        },
        {
          piece: "Nighthawks (1942)",
          link: "https://commons.wikimedia.org/wiki/File:Nighthawks_by_Edward_Hopper_1942.jpg",
        },
      ],
      notes: "The most famous American realist painter",
    },
    {
      id: "GEORGIA O'KEEFFE",
      artistName: "GEORGIA O'KEEFFE",
      flag: <img src={us} />,
      lifespan: "1887 - 1986",
      birthplace: ", Sun Prairie, Wisconsin",
      artMovement: "American modernist",
      famousPieces: [
        {
          piece: "Black Iris III (1926)",
          link: "https://en.wikipedia.org/wiki/Black_Iris_(painting)#/media/File:Georgia_O'Keeffe,_Black_Iris,_1926,_Metropolitan_Museum_of_Art.jpg",
        },
      ],
      notes:
        "Critics of her work say that many of her paintings look like vaginas, however O'Keeffe rejected this interpretation",
    },
    {
      id: "MARK ROTHKO",
      artistName: "MARK ROTHKO",
      flag: <img src={us} />,
      lifespan: "1903 - 1970",
      birthplace: "Daugavpils, Latvia",
      artMovement: "American Abstract Expressionist movement - modern art",
      famousPieces: [
        {
          piece: "Orange, Red, Yellow (1961)",
          link: "https://en.wikipedia.org/wiki/Orange,_Red,_Yellow#/media/File:Orange,_Red,_Yellow.jpg",
        },
      ],
      notes: "One of the most influential abstract artists of all time",
    },
    {
      id: "JACKSON POLLOCK",
      artistName: "JACKSON POLLOCK",
      flag: <img src={us} />,
      lifespan: "1912 - 1956",
      birthplace: "Cody, Wyoming",
      artMovement: "Drip painting, Abstract",
      famousPieces: [
        {
          piece: "Number 5 (1948)",
          link: "https://en.wikipedia.org/wiki/No._5,_1948#/media/File:No._5,_1948.jpg",
        },
        {
          piece: "Number 11/Blue Poles (1952)",
          link: "https://en.wikipedia.org/wiki/Blue_Poles#/media/File:Blue_Poles_(Jackson_Pollock_painting).jpg",
        },
      ],
      notes:
        "The Oscar-winning film 'Pollock (2000)' was made about his life, it was directed by and starred Ed Harris",
    },
    {
      id: "ROY LICHTENSTEIN",
      artistName: "ROY LICHTENSTEIN",
      flag: <img src={us} />,
      lifespan: "1923 - 1997",
      birthplace: "Manhattan, New York",
      artMovement: "Pop art",
      famousPieces: [
        {
          piece: "Whaam! (1963)",
          link: "https://commons.wikimedia.org/wiki/File:Whaam!_-_Roy_Lichenstein.jpg",
        },
        {
          piece: "Drowning Girl (1963)",
          link: "https://en.wikipedia.org/wiki/Drowning_Girl#/media/File:Roy_Lichtenstein_Drowning_Girl.jpg",
        },
      ],
      notes: "One of the leading artists of the Pop Art movement",
    },
    {
      id: "ANDY WARHOL",
      artistName: "ANDY WARHOL",
      flag: <img src={us} />,
      lifespan: "1928 - 1987",
      birthplace: "Pittsburgh, Pennsylvania",
      artMovement: "Pop Art",
      famousPieces: [
        {
          piece: "Marilyn Diptych (1962)",
          link: "https://en.wikipedia.org/wiki/Marilyn_Diptych#/media/File:Marilyndiptych.jpg",
        },
        {
          piece: "Campbell's Soup Cans (1962)",
          link: "https://en.wikipedia.org/wiki/Campbell%27s_Soup_Cans#/media/File:Campbells_Soup_Cans_MOMA.jpg",
        },
        {
          piece: "Eight Elvises (1963)",
          link: "https://en.wikipedia.org/wiki/Eight_Elvises#/media/File:Eight_Elvises.jpg",
        },
      ],
      notes:
        "The most famous American artist of all time. His New York studio was called 'The Factory', it was there that he was shot by Valerie Solanas in 1968",
    },
    {
      id: "KEITH HARING",
      artistName: "KEITH HARING",
      flag: <img src={us} />,
      lifespan: "1958 - 1990",
      birthplace: "Reading, Pennsylvania",
      artMovement: "Graffiti art",
      famousPieces: [
        {
          piece: "Crack is Wack (1986)",
          link: "https://en.wikipedia.org/wiki/Crack_Is_Wack#/media/File:Keith_Harring_Crack_is_Wack_Mural.jpg",
        },
        {
          piece: "Tuttomondo (1989)",
          link: "https://en.wikipedia.org/wiki/Tuttomondo#/media/File:Tuttomondo_-_Keith_Haring_1.jpg",
        },
      ],
      notes: "His works often conveyed political and social themes",
    },
  ],
  britishArtists: [
    {
      id: "WILLIAM BLAKE",
      artistName: "WILLIAM BLAKE",
      flag: <img src={gb} />,
      lifespan: "1757 - 1827",
      birthplace: "Soho, London",
      artMovement: "Romanticism",
      famousPieces: [
        {
          piece: "The Ancient of Days (1794)",
          link: "https://commons.wikimedia.org/wiki/File:Europe_a_Prophecy_copy_K_plate_01.jpg",
        },
        {
          piece: "Newton (1795)",
          link: "https://commons.wikimedia.org/wiki/File:Newton-WilliamBlake.jpg",
        },
        {
          piece:
            "The Angels Hovering Over the Body of Christ in the Sepulchre (c1805)",
          link: "https://commons.wikimedia.org/wiki/File:William_Blake_-_Christ_in_the_Sepulchre,_Guarded_by_Angels.jpg",
        },
      ],
      notes:
        "One of the greatest and best known poets, wrote the poem 'And did those feet in ancient time' best known in modern day as the hymn 'Jerusalem'",
    },
    {
      id: "J.M.W. TURNER",
      artistName: "J.M.W. TURNER",
      flag: <img src={gb} />,
      lifespan: "1775 - 1851",
      birthplace: "Covent Garden, London",
      artMovement: "Romanticism",
      famousPieces: [
        {
          piece: "The Fighting Temeraire (1839)",
          link: "https://commons.wikimedia.org/wiki/File:Turner,_J._M._W._-_The_Fighting_T%C3%A9m%C3%A9raire_tugged_to_her_last_Berth_to_be_broken.jpg",
        },
        {
          piece: "Rain, Steam and Speed (1844)",
          link: "https://commons.wikimedia.org/wiki/File:Rain_Steam_and_Speed_the_Great_Western_Railway.jpg",
        },
      ],
      notes: "The Turner Prize, arts award, is named after him",
    },
    {
      id: "JOHN CONSTABLE",
      artistName: "JOHN CONSTABLE",
      flag: <img src={gb} />,
      lifespan: "1776 - 1837",
      birthplace: "East Bergholt, Suffolk",
      artMovement: "Romanticism",
      famousPieces: [
        {
          piece: "The Hay Wain (1821)",
          link: "https://commons.wikimedia.org/wiki/File:John_Constable_-_The_Hay_Wain_(1821).jpg",
        },
        {
          piece: "The Vale of Dedham (1828)",
          link: "https://commons.wikimedia.org/wiki/File:John_Constable_-_The_Vale_of_Dedham_-_Google_Art_Project.jpg",
        },
      ],
      notes:
        "Painted nature and landscapes of where he was born, Essex-Suffolk border",
    },
    {
      id: "HENRY MOORE",
      artistName: "HENRY MOORE",
      flag: <img src={gb} />,
      lifespan: "1898 - 1986",
      birthplace: "Castleford, West Yorkshire",
      artMovement: "Modernism",
      famousPieces: [
        {
          piece: "Reclining Figures (1930s-1980s)",
          link: "https://en.wikipedia.org/wiki/Henry_Moore#/media/File:Moore_Reclining_Woman_Ottawa_2015.JPG",
        },
        {
          piece: "King and Queen (1957)",
          link: "https://commons.wikimedia.org/wiki/File:%27King_and_Queen%27_by_Henry_Moore,_Glenkiln_-_geograph.org.uk_-_36115.jpg",
        },
      ],
      notes:
        "Best known for bronze sculptures that can be seen around the globe in public spaces",
    },
    {
      id: "FRANCIS BACON",
      artistName: "FRANCIS BACON",
      flag: <img src={gb} />,
      lifespan: "1909 - 1992",
      birthplace: "Dublin, Ireland",
      artMovement: "Expressionism",
      famousPieces: [
        {
          piece:
            "Three Studies for Figures at the Base of a Crucifixion (1944)",
          link: "https://en.wikipedia.org/wiki/Three_Studies_for_Figures_at_the_Base_of_a_Crucifixion#/media/File:Three_Studies_for_Figures_at_the_Base_of_a_Crucifixion.jpg",
        },
        {
          piece: "Three Studies of Lucian Freud (1969)",
          link: "https://en.wikipedia.org/wiki/Three_Studies_of_Lucian_Freud#/media/File:Three_Studies_of_Lucian_Freud.jpg",
        },
      ],
      notes:
        "Bacon is portrayed in the 1998 film 'Love Is the Devil: Study for a Portrait of Francis Bacon', Daniel Craig played Bacon's lover, George Dyer, in the film",
    },
    {
      id: "LUCIAN FREUD",
      artistName: "LUCIAN FREUD",
      flag: <img src={gb} />,
      lifespan: "1922 - 2011",
      birthplace: "Berlin, Germany",
      artMovement: "Expressionism, Figurative Realism",
      famousPieces: [
        {
          piece: "Girl with a White Dog (1951)",
          link: "https://en.wikipedia.org/wiki/Lucian_Freud#/media/File:Freud,_girl-white-dog.jpg",
        },
        {
          piece: "Benefits Supervisor Sleeping (1995)",
          link: "https://en.wikipedia.org/wiki/Lucian_Freud#/media/File:Benefits_Supervisor_Sleeping.jpg",
        },
      ],
      notes: "Grandson of Sigmund Freud",
    },
    {
      id: "TRACEY EMIN",
      artistName: "TRACEY EMIN",
      flag: <img src={gb} />,
      lifespan: "1963 - ",
      birthplace: "Croydon, London",
      artMovement: "Young British Artists (YBAs)",
      famousPieces: [
        {
          piece: "Everyone I Have Ever Slept With 1963-1995 (1995)",
          link: "https://en.wikipedia.org/wiki/Everyone_I_Have_Ever_Slept_With_1963%E2%80%931995#/media/File:Emin-Tent-Exterior.jpg",
        },
        {
          piece: "My Bed (1998)",
          link: "https://en.wikipedia.org/wiki/My_Bed#/media/File:Emin-My-Bed.jpg",
        },
      ],
      notes: "In 2015, Tracey Emin married a large rock in her garden",
    },
    {
      id: "DAMIEN HIRST",
      artistName: "DAMIEN HIRST",
      flag: <img src={gb} />,
      lifespan: "1965 - ",
      birthplace: "Bristol",
      artMovement: "Young British Artists (YBAs)",
      famousPieces: [
        {
          piece:
            "The Physical Impossibility of Death in the Mind of Someone Living (1991)",
          link: "https://en.wikipedia.org/wiki/The_Physical_Impossibility_of_Death_in_the_Mind_of_Someone_Living#/media/File:Hirst-Shark.jpg",
        },
        {
          piece: "Mother and Child Divided (1993)",
          link: "https://www.tate.org.uk/art/artworks/hirst-mother-and-child-divided-t12751",
        },
      ],
      notes: "He is the wealthiest living artist (in 2022)",
    },
    {
      id: "DAVID HOCKNEY",
      artistName: "DAVID HOCKNEY",
      flag: <img src={gb} />,
      lifespan: "1965 - ",
      birthplace: "Bradford, West Yorkshire",
      artMovement: "Pop Art",
      famousPieces: [
        {
          piece: "A Bigger Splash (1967)",
          link: "https://en.wikipedia.org/wiki/A_Bigger_Splash#/media/File:Hockney,_A_Bigger_Splash.jpg",
        },
        {
          piece: "Mr and Mrs Clark and Percy (1971)",
          link: "https://en.wikipedia.org/wiki/Mr_and_Mrs_Clark_and_Percy#/media/File:Hockney.clark-percy.jpg",
        },
      ],
      notes: "Percy is a cat in the painting with Mr and Mrs Clark",
    },
    {
      id: "BANKSY",
      artistName: "BANKSY",
      flag: <img src={gb} />,
      lifespan: "1974 - ",
      birthplace: "Bristol",
      artMovement: "Graffiti art",
      famousPieces: [
        {
          piece: "Balloon Girl (2002)",
          link: "https://commons.wikimedia.org/wiki/File:Banksy_Girl_and_Heart_Balloon_(2840632113).jpg",
        },
        {
          piece: "Love Is In The Air (2003)",
          link: "https://banksyexplained.com/love-is-in-the-air-2003-3/",
        },
        {
          piece: "Kissing Coppers (2004)",
          link: "https://en.wikipedia.org/wiki/Kissing_Coppers#/media/File:Banksy_Kissing_Policemen-cropped.jpg",
        },
      ],
      notes:
        "A print of 'Balloon Girl' spontaneously began shredding in a stunt at an auction at Sotheby's in 2018. The partially-shredded print became a new piece entitled 'Love is in the Bin'",
    },
  ],
};
// not exhaustive list but you'll be here forever if you try and watch every single film so these are mostly the highest rated each year according to IMDB
const pre1930sFilms = [
  {
    id: "filmsPre1930s",
    year: "Pre 1930",
    films: [
      "The Kid (1921)",
      "Sherlock Junior (1924)",
      "The Gold Rush (1925)",
      "The General (1926)",
      "Metropolis (1927)",
      "The Passion of Joan of Arc (1928)",
    ],
    bestPictureWinner: "Wings (1928)",
    bestPictureWinner2: "The Broadway Melody (1929)",
  },
];

const notableFilmsStarting1928 = {
  "1920s": [
    {
      id: "1928Films",
      year: 1928,
      bestPictureWinner: "Wings",
    },
    { id: "1929Films", year: 1929, bestPictureWinner: "The BroadwayMelody" },
  ],
  "1930s": [
    {
      id: "1930Films",
      year: 1930,
      films: [
        "All Quiet on the Western Front",
        "The Blue Angel",
        "Animal Crackers",
        "Hell's Angels",
      ],
      bestPictureWinner: "All Quiet on the Western Front",
    },
    {
      id: "1931Films",
      year: 1931,
      films: [
        "Frankenstein",
        "City Lights",
        "Dracula",
        "Dr. Jekyll and Mr Hyde",
      ],
      bestPictureWinner: "Cimarron",
    },
    {
      id: "1932Films",
      year: 1932,
      films: ["Scarface", "Freaks", "Trouble in Paradise", "Horse Feathers"],
      bestPictureWinner: "Grand Hotel",
    },
    {
      id: "1933Films",
      year: 1933,
      films: ["King Kong", "Duck Soup", "Little Women", "Sons of the Desert"],
      bestPictureWinner: "Cavalcade",
    },
    {
      id: "1934Films",
      year: 1934,
      films: [
        "It It Happened One Night",
        "The Thin Man",
        "Imitation of Life",
        "You're Telling Me",
      ],
      bestPictureWinner: "It It Happened One Night",
    },
    {
      id: "1935Films",
      year: 1935,
      films: [
        "Top Hat",
        "Bride of Frankenstein",
        "A Night at the Opera",
        "Anna Karenina",
      ],
      bestPictureWinner: "Mutiny on the Bounty",
    },
    {
      id: "1936Films",
      year: 1936,
      films: [
        "Modern Times",
        "My Man Godfrey",
        "Mr Deeds Goes To Town",
        "Libeled Lady",
      ],
      bestPictureWinner: "The Great Ziegfeld",
    },
    {
      id: "1937Films",
      year: 1937,
      films: [
        "Snow White and the Seven Dwarfs",
        "The Awful Truth",
        "Stage Door",
        "The Prince and the Pauper",
      ],
      bestPictureWinner: "The Life of Emile Zola",
    },
    {
      id: "1938Films",
      year: 1938,
      films: [
        "Bringing Up Baby",
        "The Adventures of Robin Hood",
        "Angels with Dirty Faces",
        "Holiday",
        "A Christmas Carol",
      ],
      bestPictureWinner: "You Can't Take it with You",
    },
    {
      id: "1939Films",
      year: 1939,
      films: [
        "Gone with the Wind",
        "The Wizard of Oz",
        "Mr. Smith Goes to Washington",
        "Stagecoach",
        "Goodbye Mr. Chips",
        "Only Angels Have Wings",
      ],
      bestPictureWinner: "Gone with the Wind",
    },
  ],
  "1940s": [
    {
      id: "1940Films",
      year: 1940,
      films: [
        "The Shop Around the Corner",
        "The Grapes of Wrath",
        "Pinocchio",
        "Rebecca",
        "The Great Dictator",
      ],
      bestPictureWinner: "Rebecca",
    },
    {
      id: "1941Films",
      year: 1941,
      films: [
        "Citizen Kane",
        "The Maltese Falcon",
        "Dumbo",
        "How Green Was My Valley",
      ],
      bestPictureWinner: "How Green Was My Valley",
    },
    {
      id: "1942Films",
      year: 1942,
      films: ["The Magnificent Ambersons", "Now Voyager", "To Be or Not to Be"],
      bestPictureWinner: "Mrs Miniver",
    },
    {
      id: "1943Films",
      year: 1943,
      films: ["Shadow of a Doubt", "Casablanca", "The Ox-Bow Incident"],
      bestPictureWinner: "Casablanca",
    },
    {
      id: "1944Films",
      year: 1944,
      films: ["Double Indemnity", "Gaslight", "Meet Me In St. Louis", "Laura"],
      bestPictureWinner: "Going My Way",
    },
    {
      id: "1945Films",
      year: 1945,
      films: [
        "Mildred Pierce",
        "Brief Encounter",
        "Scarlet Street",
        "The Lost Weekend",
      ],
      bestPictureWinner: "The Lost Weekend",
    },
    {
      id: "1946Films",
      year: 1946,
      films: [
        "Notorious",
        "The Big Sleep",
        "A Matter of Life and Death",
        "It's a Wonderful Life",
        "The Killers",
        "The Best Years of Our Lives",
      ],
      bestPictureWinner: "The Best Years of Our Lives",
    },
    {
      id: "1947Films",
      year: 1947,
      films: [
        "Out of the Past",
        "Dark Passage",
        "Monsieur Verdoux",
        "Odd Man Out",
      ],
      bestPictureWinner: "Gentleman's Agreement",
    },
    {
      id: "1948Films",
      year: 1948,
      films: [
        "The Treasure of Sierra Madre",
        "The Red Shoes",
        "Bicycle Thieves",
        "Rope",
        "Key Largo",
      ],
      bestPictureWinner: "Hamlet",
    },
    {
      id: "1949Films",
      year: 1949,
      films: ["Kind Hearts and Coronets", "White Heat", "The Third Man"],
      bestPictureWinner: "All the King's Men",
    },
  ],
  "1950s": [
    {
      id: "1950Films",
      year: 1950,
      films: [
        "Sunset Blvd.",
        "All About Eve",
        "Night and the City",
        "Rashomon",
        "Cinderella",
      ],
      bestPictureWinner: "All About Eve",
    },
    {
      id: "1951Films",
      year: 1951,
      films: [
        "Strangers on a Train",
        "Ace in the Hole",
        "A Streetcar Named Desire",
        "The Browning Version",
      ],
      bestPictureWinner: "An American in Paris",
    },
    {
      id: "1952Films",
      year: 1952,
      films: [
        "High Noon",
        "Singin' in the Rain",
        "Limelight",
        "The Bad and the Beautiful",
      ],
      bestPictureWinner: "The Greatest Show on Earth",
    },
    {
      id: "1953Films",
      year: 1953,
      films: ["The Wages of Fear", "The Big Heat", "The War of the Worlds"],
      bestPictureWinner: "From Here to Eternity",
    },
    {
      id: "1954Films",
      year: 1954,
      films: [
        "Rear Window",
        "Dial M for Murder",
        "La Strada",
        "The Caine Mutiny",
        "On the Waterfront",
        "Seven Samurai",
      ],
      bestPictureWinner: "On the Waterfront",
    },
    {
      id: "1955Films",
      year: 1955,
      films: [
        "The Night of the Hunter",
        "Rebel Without a Cause",
        "The Court Jester",
        "Rififi",
      ],
      bestPictureWinner: "Marty",
    },
    {
      id: "1956Films",
      year: 1956,
      films: [
        "Invasion of the Body Snatchers",
        "The Killing",
        "The Man Who Knew Too Much",
        "The Harder They Fall",
      ],
      bestPictureWinner: "Around the World in 80 Days",
    },
    {
      id: "1957Films",
      year: 1957,
      films: [
        "12 Angry Men",
        "Witness for the Prosecution",
        "The Bridge on the River Kwai",
        "A Face in the Crowd",
        "Paths of Glory",
        "The Seventh Seal",
      ],
      bestPictureWinner: "The Bridge on the River Kwai",
    },
    {
      id: "1958Films",
      year: 1958,
      films: [
        "Touch of Evil",
        "Cat on a Hot Tin Roof",
        "A Night to Remember",
        "The Big Country",
        "Auntie Mame",
        "Vertigo",
      ],
      bestPictureWinner: "Gigi",
    },
    {
      id: "1959Films",
      year: 1959,
      films: [
        "Anatomy of a Murder",
        "North by Northwest",
        "Some Like It Hot",
        "Ben-Hur",
      ],
      bestPictureWinner: "Ben-Hur",
    },
  ],
  "1960s": [
    {
      id: "1960Films",
      year: 1960,
      films: [
        "Psycho",
        "Spartacus",
        "The Magnificent Seven",
        "The Apartment",
        "Inherit the Wind",
      ],
      bestPictureWinner: "The Apartment",
    },
    {
      id: "1961Films",
      year: 1961,
      films: [
        "The Hustler",
        "A Raisin in the Sun",
        "The Innocents",
        "One, Two, Three",
        "West Side Story",
      ],
      bestPictureWinner: "West Side Story",
    },
    {
      id: "1962Films",
      year: 1962,
      films: [
        "Lawrence of Arabia",
        "The Manchurian Candidate",
        "What Ever Happened to Baby Jane",
        "Birdman of Alcatraz",
        "The Man Who Shot Liberty Valance",
        "The Miracle Worker",
        "To Kill a Mockingbird",
      ],
      bestPictureWinner: "Lawrence of Arabia",
    },
    {
      id: "1963Films",
      year: 1963,
      films: [
        "The Great Escape",
        "Charade",
        "The Birds",
        "From Russia With Love",
      ],
      bestPictureWinner: "Tom Jones",
    },
    {
      id: "1964Films",
      year: 1964,
      films: [
        "Goldfinger",
        "My Fair Lady",
        "Fail-Safe",
        "Becket",
        "Dr Strangelove",
      ],
      bestPictureWinner: "My Fair Lady",
    },
    {
      id: "1965Films",
      year: 1965,
      films: [
        "Doctor Zhivago",
        "The Sound of Music",
        "For a Few Dollars More",
        "The Flight of the Phoenix",
        "Dr. Terror's House of Horrors",
      ],
      bestPictureWinner: "The Sound of Music",
    },
    {
      id: "1966Films",
      year: 1966,
      films: [
        "The Good, the Bad and the Ugly",
        "Who's Afraid of Virginia Woolf?",
        "Seconds",
        "Fahrenheit 451",
      ],
      bestPictureWinner: "A Man for All Seasons",
    },
    {
      id: "1967Films",
      year: 1967,
      films: [
        "Cool Hand Luke",
        "Bonnie and Clyde",
        "In the Heat of the Night",
        "The Graduate",
        "In Cold Blood",
      ],
      bestPictureWinner: "In the Heat of the Night",
    },
    {
      id: "1968Films",
      year: 1968,
      films: [
        "2001: A Space Odyssey",
        "Planet of the Apes",
        "Night of the Living Dead",
        "Once Upon a Time in the West",
        "Rosemary's Baby",
        "The Odd Couple",
      ],
      bestPictureWinner: "Oliver!",
    },
    {
      id: "1969Films",
      year: 1969,
      films: [
        "The Wild Bunch",
        "Butch Cassidy and the Sundance Kid",
        "Midnight Cowboy",
        "Kes",
        "Easy Rider",
      ],
      bestPictureWinner: "Midnight Cowboy",
    },
  ],
  "1970s": [
    {
      id: "1970Films",
      year: 1970,
      films: ["Patton", "Five Easy Pieces", "M.A.S.H."],
      bestPictureWinner: "Patton",
    },
    {
      id: "1971Films",
      year: 1971,
      films: [
        "The French Connection",
        "The Last Picture Show",
        "Fiddler on the Roof",
        "A Clockwork Orange",
        "Dirty Harry",
      ],
      bestPictureWinner: "The French Connection",
    },
    {
      id: "1972Films",
      year: 1972,
      films: ["The Godfather", "Deliverance", "Cabaret", "Sleuth"],
      bestPictureWinner: "The Godfather",
    },
    {
      id: "1973Films",
      year: 1973,
      films: [
        "Paper Moon",
        "The Day of the Jackal",
        "The Wicker Man",
        "Papillon",
        "The Sting",
        "The Exorcist",
      ],
      bestPictureWinner: "The Sting",
    },
    {
      id: "1974Films",
      year: 1974,
      films: [
        "The Godfather: Part II",
        "Blazing Saddles",
        "The Conversation",
        "A Woman Under the Influence",
        "Young Frankenstein",
        "Chinatown",
      ],
      bestPictureWinner: "The Godfather Part II",
    },
    {
      id: "1975Films",
      year: 1975,
      films: [
        "Monty Python and the Holy Grail",
        "Jaws",
        "One Flew Over the Cuckoo's Nest",
        "Dog Day Afternoon",
        "Barry Lyndon",
      ],
      bestPictureWinner: "One Flew Over the Cuckoo's Nest",
    },
    {
      id: "1976Films",
      year: 1976,
      films: [
        "All the President's Men",
        "Network",
        "Rocky",
        "Carrie",
        "Taxi Driver",
      ],
      bestPictureWinner: "Rocky",
    },
    {
      id: "1977Films",
      year: 1977,
      films: [
        "Star Wars",
        "Annie Hall",
        "Close Encounters of the Third Kind",
        "Sorcerer",
      ],
      bestPictureWinner: "Annie Hall",
    },
    {
      id: "1978Films",
      year: 1978,
      films: [
        "Midnight Express",
        "Halloween",
        "Grease",
        "Dawn of the Dead",
        "Days of Heaven",
        "The Deer Hunter",
      ],
      bestPictureWinner: "The Deer Hunter",
    },
    {
      id: "1979Films",
      year: 1979,
      films: [
        "Apocalypse Now",
        "Alien",
        "Life of Brian",
        "Kramer vs Kramer",
        "Scum",
      ],
      bestPictureWinner: "Kramer vs Kramer",
    },
  ],
  "1980s": [
    {
      id: "1980Films",
      year: 1980,
      films: [
        "Star Wars: The Empire Strikes Back",
        "The Shining",
        "Raging Bull",
        "The Elephant Man",
        "Airplane",
      ],
      bestPictureWinner: "Ordinary People",
    },
    {
      id: "1981Films",
      year: 1981,
      films: ["Raiders of the Lost Ark", "Blow Out", "Mad Max 2"],
      bestPictureWinner: "Chariots of Fire",
    },
    {
      id: "1982Films",
      year: 1982,
      films: [
        "First Blood",
        "Star Trek II: The Wrath of Khan",
        "Gandhi",
        "The King of Comedy",
        "Missing",
        "The Thing",
        "Blade Runner",
      ],
      bestPictureWinner: "Gandhi",
    },
    {
      id: "1983Films",
      year: 1983,
      films: [
        "Star Wars: Return of the Jedi",
        "The Meaning of Life",
        "Educating Rita",
        "The Dead Zone",
        "Scarface",
      ],
      bestPictureWinner: "Terms of Endearment",
    },
    {
      id: "1984Films",
      year: 1984,
      films: [
        "The Terminator",
        "Ghostbusters",
        "Amadeus",
        "Once Upon a Time in America",
        "Threads",
        "This is Spinal Tap",
      ],
      bestPictureWinner: "Amadeus",
    },
    {
      id: "1985Films",
      year: 1985,
      films: [
        "Back to the Future",
        "Brazil",
        "The Breakfast Club",
        "The Goonies",
      ],
      bestPictureWinner: "Out of Africa",
    },
    {
      id: "1986Films",
      year: 1986,
      films: [
        "Aliens",
        "The Fly",
        "Stand by Me",
        "Blue Velvet",
        "Hannah and her Sisters",
        "Platoon",
      ],
      bestPictureWinner: "Platoon",
    },
    {
      id: "1987Films",
      year: 1987,
      films: [
        "Full Metal Jacket",
        "Predator",
        "The Last Emporer",
        "Robocop",
        "Lethal Weapon",
      ],
      bestPictureWinner: "The Last Emporer",
    },
    {
      id: "1988Films",
      year: 1988,
      films: [
        "Rain Man",
        "Who Framed Roger Rabbit",
        "The Naked Gun",
        "Beetlejuice",
        "A Fish Called Wanda",
        "Die Hard",
      ],
      bestPictureWinner: "Rain Man",
    },
    {
      id: "1989Films",
      year: 1989,
      films: [
        "Back to the Future Part II",
        "Batman",
        "Indiana Jones and the Last Crusade",
        "Dead Poets Society",
        "My Left Foot",
        "When Harry Met Sally",
      ],
      bestPictureWinner: "Driving Miss Daisy",
    },
  ],
  "1990s": [
    {
      id: "1990Films",
      year: 1990,
      films: [
        "Goodfellas",
        "Misery",
        "Awakenings",
        "Home Alone",
        "Edward Scissorhands",
      ],
      bestPictureWinner: "Dances with Wolves",
    },
    {
      id: "1991Films",
      year: 1991,
      films: [
        "The Silence of the Lambs",
        "Terminator 2: Judgment Day",
        "Beauty and the Beast",
        "Boyz N the Hood",
        "Fried Green Tomatoes at the Whistle Stop Cafe",
      ],
      bestPictureWinner: "The Silence of the Lambs",
    },
    {
      id: "1992Films",
      year: 1992,
      films: [
        "Unforgiven",
        "Reservoir Dogs",
        "The Player",
        "The Last of the Mohicans",
        "The Muppet Christmas Carol",
        "Aladdin",
      ],
      bestPictureWinner: "Unforgiven",
    },
    {
      id: "1993Films",
      year: 1993,
      films: [
        "Schindler's List",
        "Jurassic Park",
        "Philadelphia",
        "The Nightmare Before Christmas",
        "In the Name of the Father",
        "The Remains of the Day",
        "Groundhog Day",
      ],
      bestPictureWinner: "Schindler's List",
    },
    {
      id: "1994Films",
      year: 1994,
      films: [
        "The Shawshank Redemption",
        "Forrest Gump",
        "Leon",
        "Ed Wood",
        "Pulp Fiction",
        "The Lion King",
      ],
      bestPictureWinner: "Forrest Gump",
    },
    {
      id: "1995Films",
      year: 1995,
      films: [
        "Seven",
        "Twelve Monkeys",
        "Apollo 13",
        "Leaving Las Vegas",
        "Before Sunrise",
        "Braveheart",
        "Toy Story",
        "Heat",
        "Casino",
      ],
      bestPictureWinner: "Braveheart",
    },
    {
      id: "1996Films",
      year: 1996,
      films: [
        "Sling Blade",
        "The English Patient",
        "Hard Eight",
        "Star Trek: First Contact",
        "The Rock",
        "Trainspotting",
        "Fargo",
      ],
      bestPictureWinner: "The English Patient",
    },
    {
      id: "1997Films",
      year: 1997,
      films: [
        "Titanic",
        "The Game",
        "Men in Black",
        "Boogie Nights",
        "Donnie Brasco",
        "Good Will Hunting",
        "The Full Monty",
        "L.A. Confidential",
      ],
      bestPictureWinner: "Titanic",
    },
    {
      id: "1998Films",
      year: 1998,
      films: [
        "Saving Private Ryan",
        "Lock, Stock and Two Smoking Barrels",
        "The Truman Show",
        "Pleasantville",
        "Dark City",
        "American History X",
        "The Big Lebowski",
      ],
      bestPictureWinner: "Shakespeare in Love",
    },
    {
      id: "1999Films",
      year: 1999,
      films: [
        "American Beauty",
        "Fight Club",
        "The Green Mile",
        "The Matrix",
        "The Sixth Sense",
        "Magnolia",
        "The Iron Giant",
      ],
      bestPictureWinner: "American Beauty",
    },
  ],
  "2000s": [
    {
      id: "2000Films",
      year: 2000,
      films: [
        "Gladiator, Cast Away",
        "Crouching Tiger, Hidden Dragon",
        "Traffic",
        "Memento",
        "Snatch",
        "Requiem for a Dream",
      ],
      bestPictureWinner: "Gladiator",
    },
    {
      id: "2001Films",
      year: 2001,
      films: [
        "Shrek",
        "Amelie",
        "Donnie Darko",
        "Spirited Away",
        "The Fellowship of the Ring",
        "A Beautiful Mind",
        "Monsters Inc.",
      ],
      bestPictureWinner: "A Beautiful Mind",
    },
    {
      id: "2002Films",
      year: 2002,
      films: [
        "The Pianist",
        "City of God",
        "The Two Towers",
        "Catch Me If You Can",
        "The Bourne Identity",
      ],
      bestPictureWinner: "Chicago",
    },
    {
      id: "2003Films",
      year: 2003,
      films: [
        "Kill Bill: Vol. 1",
        "The Return of the King",
        "Pirates of the Caribbean: The Curse of the Black Pearl",
        "Finding Nemo",
      ],
      bestPictureWinner: "The Return of the King",
    },
    {
      id: "2004Films",
      year: 2004,
      films: [
        "Eternal Sunshine of the Spotless Mind",
        "Kill Bill: Vol. 2",
        "Before Sunset",
        "Million Dollar Baby",
        "The Incredibles",
        "Hotel Rwanda",
      ],
      bestPictureWinner: "Million Dollar Baby",
    },
    {
      id: "2005Films",
      year: 2005,
      films: [
        "Batman Begins",
        "Sin City",
        "Cinderella Man",
        "Walk the Line",
        "Little Miss Sunshine",
      ],
      bestPictureWinner: "Crash",
    },
    {
      id: "2006Films",
      year: 2006,
      films: [
        "V for Vendetta",
        "Pan's Labyrinth",
        "The Departed",
        "The Prestige",
        "Casino Royale",
        "Blood Diamond",
      ],
      bestPictureWinner: "The Departed",
    },
    {
      id: "2007Films",
      year: 2007,
      films: [
        "No Country for Old Men",
        "Hot Fuzz",
        "There Will Be Blood",
        "The Bourne Ultimatum",
        "Ratatouille",
        "Into the Wild",
      ],
      bestPictureWinner: "No Country for Old Men",
    },
    {
      id: "2008Films",
      year: 2008,
      films: [
        "The Dark Knight",
        "WALL-E",
        "Slumdog Millionaire",
        "Gran Torino",
        "Iron Man",
      ],
      bestPictureWinner: "Slumdog Millionaire",
    },
    {
      id: "2009Films",
      year: 2009,
      films: [
        "Up",
        "District 9",
        "Inglorious Basterds",
        "The Girl with the Dragon Tattoo",
        "Mary and Max",
        "Hachi: A Dog's Tale",
      ],
      bestPictureWinner: "The Hurt Locker",
    },
  ],
  "2010s": [
    {
      id: "2010Films",
      year: 2010,
      films: [
        "Toy Story 3",
        "Inception",
        "The King's Speech",
        "Black Swan",
        "Shutter Island",
        "How to Train Your Dragon",
      ],
      bestPictureWinner: "The King's Speech",
    },
    {
      id: "2011Films",
      year: 2011,
      films: [
        "Source Code",
        "The Artist",
        "The Girl with the Dragon Tattoo",
        "Warrior",
        "Harry Potter and the Deathly Hallows: Part 2",
        "The Help",
      ],
      bestPictureWinner: "The Artist",
    },
    {
      id: "2012Films",
      year: 2012,
      films: [
        "The Dark Knight Rises",
        "Argo",
        "Django Unchained",
        "Zero Dark Thirty",
        "Wreck-It Ralph",
      ],
      bestPictureWinner: "Argo",
    },
    {
      id: "2013Films",
      year: 2013,
      films: [
        "Gravity",
        "Her",
        "Frozen",
        "The Wolf of Wall Street",
        "Prisoners",
        "12 Years a Slave",
        "Rush",
      ],
      bestPictureWinner: "12 Years a Slave",
    },
    {
      id: "2014Films",
      year: 2014,
      films: [
        "Interstellar",
        "Whiplash",
        "The Lego Movie",
        "The Grand Budapest Hotel",
        "Wild Tales",
        "The Imitation Game",
        "Birdman",
        "Predestination",
        "Ex Machina",
        "Gone Girl",
      ],
      bestPictureWinner: "Birdman",
    },
    {
      id: "2015Films",
      year: 2015,
      films: [
        "Inside Out",
        "Spotlight",
        "Star Wars - The Force Awakens",
        "Brooklyn",
        "Room",
        "Mad Max: Fury Road",
      ],
      bestPictureWinner: "Spotlight",
    },
    {
      id: "2016Films",
      year: 2016,
      films: ["La La Land", "Arrival", "Your Name", "Moana", "Hacksaw Ridge"],
      bestPictureWinner: "Moonlight",
    },
    {
      id: "2017Films",
      year: 2017,
      films: [
        "Get Out",
        "Three Billboards Outside Ebbing, Missouri",
        "Blade Runner 2049",
        "Coco",
        "Logan",
      ],
      bestPictureWinner: "The Shape of Water",
    },
    {
      id: "2018Films",
      year: 2018,
      films: [
        "Avengers: Infinity War",
        "Spider-man: Into the Spider-Verse",
        "Green Book",
      ],
      bestPictureWinner: "Green Book",
    },
    {
      id: "2019Films",
      year: 2019,
      films: [
        "Parasite",
        "Joker",
        "Avengers: Endgame",
        "1917",
        "Klaus",
        "Le Mans '66",
      ],
      bestPictureWinner: "Parasite",
    },
  ],
  "2020s": [
    {
      id: "2020Films",
      year: 2020,
      films: ["Hamilton", "The Father"],
      bestPictureWinner: "Nomadland",
    },
    {
      id: "2021Films",
      year: 2021,
      films: ["Spider-Man: No Way Home"],
      bestPictureWinner: "CODA",
    },
  ],
};

const mohsHardness = {
  mainCategory: "Mohs Hardness Scale",
  bigPeep: require("./images/peep-big8.png"),
  intro:
    "The Mohs Hardness scale was created by Friedrich Mohs, the German geologist and mineralogist, in the early 19th century. ",

  // smallPeep1: require("./images/peep-small4.png"),
  smallPeep2: require("./images/peep-small26.png"),
  importantFacts: [
    "Mohs Hardness Scale rates the hardness of minerals based on how resistant they are to being scratched",
  ],
  exampleQuestions: [
    {
      id: "mohsHard-eQ1",
      question:
        "Which Mineral can be found at number 5 on the Mohs Hardness scale?",
      answer: "Apatite",
    },
  ],
  classicQuestions: [
    {
      id: "mohsHard-cQ1",
      question:
        "What is given a value of 10 on the Mohs Hardness scale, at the time of the introduction of the scale, it was the hardest known naturally occuring substance?",
      answer: "Diamond",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "mohsHard-TTAI1",
      text: "All minerals can be given values on the Mohs Hardness Scale, however the classic scale contains just 10 levels of hardness, with an example of a mineral at each level, so concentrate on those 10",
    },
  ],
};

const dickens = {
  mainCategory: "Charles Dickens",
  bigPeep: require("./images/peep-big2.png"),
  intro:
    "Charles John Huffam Dickens was one of the most popular writers of the Victorian era. His novels, which include classics such as Oliver Twist and A Christmas Carol, are known for their social commentary, their portrayal of poverty and crime, and their vivid characters. Dickens was also a tireless campaigner for social reform, and his work helped to raise awareness of the plight of the poor and working class in Victorian Britain.",

  // smallPeep1: require("./images/peep-small30.png"),
  smallPeep2: require("./images/peep-small10.png"),
  importantFacts: [
    "Charles Dickens was married to Catherine Thomson Hogarth and they had 10 children together",
    "Their children comprised of 7 sons and 3 daughters.",
    "The Mystery of Edwin Drood (1870) is an unfinished novel as Dickens died when the book was half finished",
    "The Pickwick Papers (1836) was Dickens' first novel",
    "Most of Dickens' novels were serialised fiction, published in weekly or monthly instalments, this became a popular way of publishing Victorian novels, installments often had cliffhanger endings",
    "Dickens wrote 15 novels (1 of which was unfinished at the time of his death), A Christmas Carol is not included as one of his novels as it is too short and instead considered a novella",
  ],
  classicQuestions: [
    {
      id: "CD-cQ1",
      question: "What was the name of Charles Dickens' wife?",
      answer: "Catherine Hogarth",
    },
    {
      id: "CD-cQ2",
      question:
        "What is the name of the wealthy spinster in Great Expectations, who wears a wedding dress for the rest of her life after being jilted on her wedding day?",
      answer: "Miss Havisham",
    },
    {
      id: "DD-cQ3",
      question: "In which city was Charles Dickens born?",
      answer: "Portsmouth",
    },
    {
      id: "DD-cQ4",
      question:
        "In some of Dickens' early writing he used a pseudonym, what was it?",
      answer: "Boz",
    },
  ],
  exampleQuestions: [
    {
      id: "CD-eQ1",
      question: "In which Dickens story is Tiny Tim a character?",
      answer: "A Christmas Carol",
    },
    {
      id: "CD-eQ2",
      question: "Which Dickens novel was unfinished at the time of his death?",
      answer: "The Mystery of Edwin Drood",
    },
    {
      id: "CD-eQ3",
      question:
        "How many children did Charles Dickens and his wife Catherine have together?",
      answer: "10",
    },
    {
      id: "CD-eQ4",
      question: "Give either of Charles Dickens' middle names?",
      answer: "John or Huffam",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "CD-tTAI1",
      text: "From experience, questions about Charles Dickens are often in the form of a character's name being given and the novel in which the character is found being required. For this reason in the table above the main characters are listed in the notes section.",
    },
    {
      id: "CD-tTAI1",
      text: "However Dickens' novels often had so many characters, some (including the more minor characters) will not be listed.",
    },
  ],
};

export {
  acronymsAndInitialisms,
  artists,
  notableFilmsStarting1928,
  pre1930sFilms,
  mohsHardness,
  dickens,
};
