import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import MapComponent from "./leaflet/data/pubs";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "../css/style.css";
import "../css/peeps.css";
import "../css/map.css";
import LocationComponent from "./LocationComponent";

function Find() {
  const [locationData, setLocationData] = useState(null);
  const [nearestPubs, setNearestPubs] = useState([]);
  const [userLocationSet, setUserLocationSet] = useState(false); // new state
  const [userLocation, setUserLocation] = useState(null); // new state for user's location

  const fetchNearestPubs = (location) => {
    axios
      .get(
        `https://waddauno.com/get-nearest-pubs.php?lat=${location.latitude}&long=${location.longitude}`
      )
      .then((response) => setNearestPubs(response.data))
      .catch((err) => console.log("Error reading data " + err));
  };

  const handleLocationData = (data) => {
    setUserLocation(data); // update user's location
    setLocationData(data); // set clicked location
    fetchNearestPubs(data);
    setUserLocationSet(true);
  };

  const handleMapClick = (location) => {
    setLocationData(location);
    fetchNearestPubs(location);
  };

  function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 3959; // Radius of the earth in miles
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in miles
    return distance.toFixed(2);
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Find A Quiz Near Me</h1>
        <div className="container">
          <p className="body-text">
            This map will display all the venues that have signed up to the
            website, and their details, so you can find your next quiz.
            <br /> For a small monthly fee, your quiz event can appear on the
            quiz map, please use the form below to register your interest and we
            will be in touch when the page is ready.
          </p>
          <br />
          <p className="body-text">
            When you click the button below the map will load possible quiz
            locations within a 8 km / 5 mile radius from your location.
            <br></br>(Please Note: Your location marker on the map may be within
            a couple of miles of your actual location)
          </p>
          <br />
          <LocationComponent onDataReceived={handleLocationData} />
        </div>
        {nearestPubs.length > 0 && (
          <div className="table-container table-top-margin">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Distance (miles)</th>
                </tr>
              </thead>
              <tbody>
                {nearestPubs.map((pub) => (
                  <tr key={pub.pub_id}>
                    <td data-label="Name">{pub.pub_name}</td>
                    <td data-label="Address">
                      {pub.address} {pub.postcode}
                    </td>
                    <td data-label="Distance (miles)">
                      {getDistanceFromLatLonInKm(
                        locationData.latitude,
                        locationData.longitude,
                        pub.latitude,
                        pub.longitude
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div>
          <div>
            <MapComponent
              location={locationData}
              onMapClick={handleMapClick}
              userLocationSet={userLocationSet}
              userLocation={userLocation} // pass userLocation as prop
            />
          </div>
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-standing4.png")}
            alt="cartoon character pointing to quiz map"
            className="peep-learning-left-wider"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Find;
