import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/style.css";
import "../css/peeps.css";

function YourQuizNight() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Tell Us About Your Quiz Event</h1>

        <div className="container">
          <p className="body-text">
            This page is not yet active, once set up it will give you the
            opportunity to have your quiz featured on the "Find A Quiz" map for
            a small monthly fee.
            <br />
            If you would like to register your interest, please fill in the form
            below and we will be in touch when it is ready.
          </p>
          <br />
          <form
            className="contact-form-center"
            action="https://formsubmit.co/findaquiz@waddauno.com"
            method="POST"
            encType="multipart/form-data"
          >
            <label htmlFor="name">Name</label>
            <input
              className="input"
              type="text"
              id="name"
              name="name"
              placeholder="Your name.."
              required
            />
            <input
              type="hidden"
              name="_subject"
              value="new message from 'tell us about your quiz night' page"
            />
            <input
              type="hidden"
              name="_next"
              value="http://waddauno.com/thankyou"
            />

            <label htmlFor="email">Email</label>
            <input
              className="input"
              type="email"
              id="email"
              name="email"
              placeholder="Your email address.."
              required
            />
            <label htmlFor="venue">Venue Name</label>
            <input
              className="input"
              type="text"
              id="venue"
              name="venue"
              placeholder="Your venue name.."
              required
            />

            <button className="submit" type="submit" value="Submit">
              Submit
            </button>
            {/* <input className="submit" type="submit" value="Submit" /> */}
          </form>
          {/* <form className="contact-form" action="/action_page.php">
            <label htmlFor="name">Name</label>
            <input
              className="input"
              type="text"
              id="name"
              name="name"
              placeholder="Your name.."
              required
            />

            <label htmlFor="email">Email</label>
            <input
              className="input"
              type="text"
              id="email"
              name="email"
              placeholder="Your last name.."
              required
            />

            <label htmlFor="pub-name">Event Location</label>
            <input
              className="input"
              type="text"
              id="pub-name"
              name="pub-name"
              placeholder="Pub Name.."
              required
            />

            <label htmlFor="pub-address">Event Address</label>
            <textarea
              type="textarea"
              id="pub-address"
              name="pub-address"
              placeholder="Pub Address.."
              rows="6"
              required
            ></textarea>

            <label htmlFor="details">
              Details of your quiz night (dates, times, frequency, freebies, top
              prize):
            </label>
            <textarea
              type="textarea"
              id="details"
              name="details"
              placeholder="More details.."
              rows="6"
              cols="50"
              required
            ></textarea>

            <input className="submit" type="submit" value="Submit" />
          </form> */}
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-small9.png")}
            alt="cartoon character with mouth open explaining"
            className="peep-learning-left"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default YourQuizNight;
