import React from "react";
import "../../css/top5.css";

function LearnTopFiveHeading(props) {
  return (
    <div className="top5flex top5heading">
      <h2 className="top5-two-cols">{props.col1}</h2>
      <h2 className="top5-two-cols top5align-right">{props.col2}</h2>
    </div>
  );
}

export default LearnTopFiveHeading;
