const hangmanWords = {
  tvAndFilmStars: {
    name: "TV & Film Stars",
    list: [
      "Tom Cruise",
      "Jennifer Aniston",
      "Dwayne 'The rock' Johnson",
      "Leonardo Dicaprio",
      "Nick Minaj",
      "Jamie Foxx",
      "Harrison Ford",
      "Joaquin Phoenix",
      "Vin Diesel",
      "Iggy Azalea",
      "Reese Witherspoon",
      "Whoopi Goldberg",
      "Channing Tatum",
      "Jennifer Lopez",
      "Steve Carell",
      "Arnold Schwarzenegger",
      "Johnny Depp",
      "Jim Carrey",
      "Emma Watson",
      "Robert Downey Jr.",
      "Daniel Radcliffe",
      "Chris Evans",
      "Brad Pitt",
      "Charlie Chaplin",
      "Tom Hanks",
      "Morgan Freeman",
      "Hugh Jackman",
      "Sylvester Stallone",
      "Will Smith",
      "Clint Eastwood",
      "Cameron Diaz",
      "George Clooney",
      "Steven Spielberg",
      "Robert De Niro",
      "Al Pacino",
      "Russell Crowe",
      "Liam Neeson",
      "Kate Winslet",
      "Mark Wahlberg",
      "Natalie Portman",
      "Pierce Brosnan",
      "Orlando Bloom",
      "Sean Connery",
      "Roger Moore",
      "Jackie Chan",
      "Angelina Jolie",
      "Adam Sandler",
      "Scarlett Johansson",
      "Heath Ledger",
      "Keira Knightley",
      "Will Ferrell",
      "Julia Roberts",
      "Nicolas Cage",
      "Daniel Craig",
      "Keanu Reeves",
      "Halle Berry",
      "Bruce Willis",
      "Samuel L. Jackson",
      "Jean-Claude Van Damme",
      "Christian Bale",
      "Bruce Lee",
      "Sandra Bullock",
      "Jack Nicholson",
      "Bill Murray",
      "Rowan Atkinson",
      "Marlon Brando",
      "John Travolta",
      "Emma Stone",
    ],
  },
  landmarks: {
    name: "Landmarks",
    list: [
      "Eiffel Tower",
      "Taj Mahal",
      "Statue of Liberty",
      "Great Wall of China",
      "Colosseum",
      "Leaning Tower of Pisa",
      "Big Ben",
      "Sydney Opera House",
      "La Sagrada Familia",
      "Golden Gate Bridge",
      "Alcatraz Island",
      "Yosemite National Park",
      "Empire State Building",
      "Chrysler Building",
      "Mount Rushmore",
      "Hoover Dam",
      "Space Needle",
      "Everglades National Park",
      "Grand Canyon",
      "The White House",
      "Great Barrier Reef",
      "Uluru",
      "Bondi Beach",
      "Mount Everest",
      "Palace of Versaille",
      "London Eye",
      "Buckingham Palace",
      "Windsor Castle",
      "Moulin Rouge",
      "Sacre-Coeur",
      "Notre Dame Cathedral",
      "Arc de Triomphe",
      "Louvre Museum",
      "Rialto Bridge",
      "Mount Vesuvius",
      "Pompeii",
      "Spanish Steps",
      "Sistine Chapel",
      "Stonehenge",
      "The Shard",
      "Westminster Abbey",
      "Bolshoi Theatre",
      "The Kremlin",
      "Imperial Palace",
      "Mount Fuji",
      "Petronas Towers",
      "Guggenheim Museum",
      "Niagara Falls",
    ],
  },
  singers: {
    name: "Singers",
    list: [
      "Elton John",
      "John Lennon",
      "Cliff Richard",
      "Noel Gallagher",
      "Paul McCartney",
      "Eminem",
      "Bob Dylan",
      "Bruce Springsteen",
      "Madonna",
      "Eric Clapton",
      "Michael Jackson",
      "Taylor Swift",
      "Jimi Hendrix",
      "Prince",
      "Stevie Wonder",
      "Billy Joel",
      "Rihanna",
      "Chuck Berry",
      "Lady Gaga",
      "Neil Young",
      "Amy Winehouse",
      "Ray Charles",
      "Sam Cooke",
      "Axl Rose",
      "David Bowie",
      "Freddie Mercury",
      "Kurt Cobain",
      "Tupac Shakur",
      "Justin Timberlake",
      "Buddy Holly",
      "John Legend",
      "Beyonce",
      "Elvis Presley",
      "Elvis Costello",
      "Neil Sedaka",
      "Miley Cyrus",
      "Ringo Starr",
      "Snoop Dogg",
      "Britney Spears",
      "Carlos Santana",
      "Frank Zappa",
      "Tina Turner",
    ],
  },
  bands: {
    name: "Bands",
    list: [
      "Aerosmith",
      "All Saints",
      "The Beatles",
      "The Beastie Boys",
      "Black Sabbath",
      "The Chemical Brothers",
      "Creedence Clearwater Revival",
      "Def Leppard",
      "Earth, Wind & Fire",
      "Emerson Lake & Palmer",
      "Fleetwood Mac",
      "Iggy and the Stooges",
      "Led Zeppelin",
      "Lynyrd Skynyrd",
      "The Mamas and the Papas",
      "Metallica",
      "Nine Inch Nails",
      "The Pet Shop Boys",
      "Pink Floyd",
      "Public Enemy",
      "Radiohead",
      "The Rolling Stones",
      "Smashing Pumpkins",
      "The Libertines",
      "Sonic Youth",
      "The Temptations",
      "The White Stripes",
      "The Velvet Underground",
      "ZZ Top",
      "Van Halen",
      "The Fratellis",
      "Talking Heads",
      "The Drifters",
    ],
  },
  sayings: {
    name: "Sayings",
    list: [
      "It takes one to know one",
      "Raining cats and dogs",
      "Back to the drawing board",
      "A picture is worth a thousand words",
      "By the skin of your teeth",
      "Don't count your chickens",
      "On a wild goose chase",
      "It's not over until the fat lady sings",
      "Kill two birds with one stone",
      "Once in a blue moon",
      "Let the cat out of the bag",
      "Spill the beans",
      "Take it with a pinch of salt",
      "The elephant in the room",
      "Throw caution to the wind",
      "Have your cake and eat it",
      "Don't judge a book by its cover",
      "Look before you leap",
      "Ignorance is bliss",
      "Silence is golden",
      "Don't put all your eggs in one basket",
      "Costs an arm and a leg",
      "Barking up the wrong tree",
      "Add insult to injury",
      "Rub salt in the wound",
      "Piece of Cake",
      "A storm in a teacup",
      "Curiosity killed the cat",
      "Every dog has his day",
      "Cut the mustard",
      "Fit as a fiddle",
      "Head in the clouds",
      "Sitting on the fence",
      "It takes two to tango",
      "Let sleeping dogs lie",
      "Two peas in a pod",
      "Once bitten, twice shy",
      "On cloud nine",
      "Pot calling the kettle black",
      "Waste not, want not",
      "You can lead a horse to water",
    ],
  },
};

export default hangmanWords;
