import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/style.css";
import "../css/peeps.css";

function About() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">ABOUT US</h1>

        <div className="container">
          <section>
            <img
              src={require("../images/wayne-peep-color.png")}
              alt="Cartoon of Waddauno site creater Wayne"
              className="peep-wrap-text-left"
            />
            <img
              src={require("../images/jo-peep-color.png")}
              alt="Cartoon of Waddauno site creater Jo"
              className="peep-wrap-text-right peep-flip"
            />

            <p className="body-text about-us-text">
              We have always enjoyed quiz shows and trivia games, and love to
              attend pub quizzes with our friends. We usually do quite well,
              however in some places the questions are much harder than in
              others. We know other people must notice this too, but it's hard
              work trawling through books and websites not really knowing what
              you should focus on in order to perform better.
              <br />
              <br />
              From our experience attending quizzes, we have a good idea of what
              sort of questions are often asked. We noticed gaps in ours and our
              friends' knowledge, and thought we would make a site to help
              everyone up their game. <br />
              <br />
              As well as being quiz enthusiasts, we enjoy computer programming
              and web design (and going to the pub!) so what better way of
              putting them all together than a big quiz website?! We hope you
              enjoy it.
            </p>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default About;
