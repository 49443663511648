import axios from "axios";
import { useEffect, useState } from "react";
import React from "react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  useMap,
  Marker,
  Popup,
} from "react-leaflet";
import { MarkerLayerWithTooltipCluster } from "../layers/marker_layer_with_tooltip_cluster";
import { housingIcon } from "../icons/defaultIcon";

import RadiusFilter from "../layers/radius_filter";

function MapComponent({ location, onMapClick, userLocationSet, userLocation }) {
  const [users, setUsers] = useState([]);
  const [radiusFilter, setRadiusFilter] = useState(null);

  const handleClick = (e) => {
    axios
      .get(
        `https://waddauno.com/pubs.php?lat=${e.latlng.lat}&long=${e.latlng.lng}`
      )
      .then((response) => {
        setUsers(response.data);
      })
      .catch((err) => {
        console.log("Error reading data " + err);
      });
  };

  const MapEvents = () => {
    const map = useMapEvents({
      click: (e) => {
        handleClick(e);
        if (onMapClick) {
          onMapClick({ latitude: e.latlng.lat, longitude: e.latlng.lng });
        }
      },
    });
    return null;
  };

  const getRadiusFilter = () => radiusFilter;
  const position = [54.5, -4.5];

  useEffect(() => {
    if (location) {
      getPubs(location);
    }
  }, [location]);

  const getPubs = (loc) => {
    axios
      .get(
        `https://waddauno.com/pubs.php?lat=${loc.latitude}&long=${loc.longitude}`
      )
      .then((response) => setUsers(response.data))
      .catch((err) => console.log("Error reading data " + err));
  };

  const pubsOnMap = users.map((user) => ({
    type: "Feature",
    properties: {
      NAME: user.pub_name,
      ADM0NAME: `${user.address} ${user.postcode}`,
      LATITUDE: user.latitude,
      LONGITUDE: user.longitude,
      ID: user.pub_id,
      VOTES: user.community_votes,
      // PUBIMG: require("../images/the-fox.jpg"),
    },
    geometry: {
      type: "Point",
      coordinates: [user.longitude, user.latitude],
    },
    key: user.pub_id, // Assigning pub_id as the key
  }));

  const pubs = {
    type: "FeatureCollection",
    features: pubsOnMap,
  };

  return (
    <MapContainer center={position} zoom={6} scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerLayerWithTooltipCluster
        data={pubs}
        setRadiusFilter={setRadiusFilter}
        getRadiusFilter={getRadiusFilter}
      />
      <RadiusFilter
        radiusFilter={radiusFilter}
        setRadiusFilter={setRadiusFilter}
      />
      {userLocationSet && userLocation && (
        <UserLocationMarker location={userLocation} />
      )}
      <MapEvents />
      {location && <FlyTo location={location} />}
    </MapContainer>
  );
}
const FlyTo = ({ location }) => {
  const map = useMap();

  useEffect(() => {
    if (location) {
      map.flyTo([location.latitude, location.longitude], 13);
    }
  }, [location, map]);

  return null;
};

const UserLocationMarker = ({ location }) => {
  return (
    <Marker
      position={[location.latitude, location.longitude]}
      icon={housingIcon}
    >
      <Popup>Your Location</Popup>
    </Marker>
  );
};

export default MapComponent;
