import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import { eurovision } from "../../LearnSpecificCategoryDetailsFive";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
// ukraine
import ua from "../../flags-svg/ua.svg";
// italy
import it from "../../flags-svg/it.svg";
// netherland
import nl from "../../flags-svg/nl.svg";
// israel
import il from "../../flags-svg/il.svg";
// portugal
import pt from "../../flags-svg/pt.svg";
// sweden
import se from "../../flags-svg/se.svg";
// austria
import at from "../../flags-svg/at.svg";
// denmark
import dk from "../../flags-svg/dk.svg";
// azerbaijan
import az from "../../flags-svg/az.svg";
// germany
import de from "../../flags-svg/de.svg";
// norway
import no from "../../flags-svg/no.svg";
// russia
import ru from "../../flags-svg/ru.svg";
// serbia
import rs from "../../flags-svg/rs.svg";
// finland
import fi from "../../flags-svg/fi.svg";
// greece
import gr from "../../flags-svg/gr.svg";
// turkey
import tr from "../../flags-svg/tr.svg";
// latvia
import lv from "../../flags-svg/lv.svg";
// estonia
import ee from "../../flags-svg/ee.svg";
// great britain
import gb from "../../flags-svg/gb.svg";
// ireland
import ie from "../../flags-svg/ie.svg";
// yugoslavia
import yu from "../../images/yu.jpg";
// switzerland
import ch from "../../flags-svg/ch.svg";
// belgium
import be from "../../flags-svg/be.svg";
// luxembourg
import lu from "../../flags-svg/lu.svg";
// france
import fr from "../../flags-svg/fr.svg";
// monaco
import mc from "../../flags-svg/mc.svg";
// spain
import es from "../../flags-svg/es.svg";
import "../../css/eurovision.css";
import "../../css/style.css";

function LearnEurovision() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={eurovision.mainCategory}
          subCategory={eurovision.subCategory}
          iconLrg={eurovision.iconLrg}
          iconSml={eurovision.iconSml}
          bigPeep={eurovision.bigPeep}
          intro={eurovision.intro}
        />
        <div className="table3-container">
          <table className="table3">
            <thead>
              <tr>
                <th>Year</th>
                <th>Host</th>
                <th>Winner</th>
                <th>Flag</th>
                <th>Act</th>
                <th>Song</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Year">2022</td>
                <td data-label="Host">Turin, Italy</td>
                <td data-label="Winner">Ukraine</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ua} />
                </td>
                <td data-label="Act">Kalush Orchestra</td>
                <td data-label="Song">Stefania</td>
              </tr>
              <tr>
                <td data-label="Year">2021</td>
                <td data-label="Host">Rotterdam, Netherlands</td>
                <td data-label="Winner">Italy</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={it} />
                </td>
                <td data-label="Act">Maneskin</td>
                <td data-label="Song">Zitti e buoni</td>
              </tr>
              <tr>
                <td data-label="Year">2020</td>
                <td data-label="Host">-</td>
                <td data-label="Winner">-</td>
                <td data-label="Flag">-</td>
                <td data-label="Act">-</td>
                <td data-label="Song">-</td>
              </tr>
              <tr>
                <td data-label="Year">2019</td>
                <td data-label="Host">Tel Aviv, Israel</td>
                <td data-label="Winner">Netherlands</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={nl} />
                </td>
                <td data-label="Act">Duncan Laurence</td>
                <td data-label="Song">Arcade</td>
              </tr>
              <tr>
                <td data-label="Year">2018</td>
                <td data-label="Host">Lisbon, Portugal</td>
                <td data-label="Winner">Israel</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={il} />
                </td>
                <td data-label="Act">Netta</td>
                <td data-label="Song">Toy</td>
              </tr>
              <tr>
                <td data-label="Year">2017</td>
                <td data-label="Host">Kyiv, Ukraine</td>
                <td data-label="Winner">Portugal</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={pt} />
                </td>
                <td data-label="Act">Salvador Sobral</td>
                <td data-label="Song">Amar Pelos Dois</td>
              </tr>
              <tr>
                <td data-label="Year">2016</td>
                <td data-label="Host">Stockholm, Sweden</td>
                <td data-label="Winner">Ukraine</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ua} />
                </td>
                <td data-label="Act">Jamala</td>
                <td data-label="Song">1944</td>
              </tr>
              <tr>
                <td data-label="Year">2015</td>
                <td data-label="Host">Vienna, Austria</td>
                <td data-label="Winner">Sweden</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={se} />
                </td>
                <td data-label="Act">Mans Zelmerlow</td>
                <td data-label="Song">Heroes</td>
              </tr>
              <tr>
                <td data-label="Year">2014</td>
                <td data-label="Host">Copenhagen, Denmark</td>
                <td data-label="Winner">Austria</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={at} />
                </td>
                <td data-label="Act">Conchita Wurst</td>
                <td data-label="Song">Rise Like a Phoenix</td>
              </tr>
              <tr>
                <td data-label="Year">2013</td>
                <td data-label="Host">Malmo, Sweden</td>
                <td data-label="Winner">Denmark</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={dk} />
                </td>
                <td data-label="Act">Emmelie de Forest</td>
                <td data-label="Song">Only Teardrops</td>
              </tr>
              <tr>
                <td data-label="Year">2012</td>
                <td data-label="Host">Baku, Azerbaijan</td>
                <td data-label="Winner">Sweden</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={se} />
                </td>
                <td data-label="Act">Loreen</td>
                <td data-label="Song">Euphoria</td>
              </tr>
              <tr>
                <td data-label="Year">2011</td>
                <td data-label="Host">Dusseldorf, Germany</td>
                <td data-label="Winner">Azerbaijan</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={az} />
                </td>
                <td data-label="Act">Ell/Nikki</td>
                <td data-label="Song">Running Scared</td>
              </tr>
              <tr>
                <td data-label="Year">2010</td>
                <td data-label="Host">Oslo, Norway</td>
                <td data-label="Winner">Germany</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={de} />
                </td>
                <td data-label="Act">Lena</td>
                <td data-label="Song">Satellite</td>
              </tr>
              <tr>
                <td data-label="Year">2009</td>
                <td data-label="Host">Moscow, Russia</td>
                <td data-label="Winner">Norway</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={no} />
                </td>
                <td data-label="Act">Alexander Rybak</td>
                <td data-label="Song">Fairytale</td>
              </tr>
              <tr>
                <td data-label="Year">2008</td>
                <td data-label="Host">Belgrade, Serbia</td>
                <td data-label="Winner">Russia</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ru} />
                </td>
                <td data-label="Act">Dima Bilan</td>
                <td data-label="Song">Believe</td>
              </tr>
              <tr>
                <td data-label="Year">2007</td>
                <td data-label="Host">Helsinki, Finland</td>
                <td data-label="Winner">Serbia</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={se} />
                </td>
                <td data-label="Act">Marija Serifovic</td>
                <td data-label="Song">Molitva</td>
              </tr>
              <tr>
                <td data-label="Year">2006</td>
                <td data-label="Host">Athens, Greece</td>
                <td data-label="Winner">Finland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={fi} />
                </td>
                <td data-label="Act">Lordi</td>
                <td data-label="Song">Hard Rock Hallelujah</td>
              </tr>
              <tr>
                <td data-label="Year">2005</td>
                <td data-label="Host">Kyiv, Ukraine</td>
                <td data-label="Winner">Greece</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={gr} />
                </td>
                <td data-label="Act">Helena Paparizou</td>
                <td data-label="Song">My Number One</td>
              </tr>
              <tr>
                <td data-label="Year">2004</td>
                <td data-label="Host">Istanbul, Turkey</td>
                <td data-label="Winner">Ukraine</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ua} />
                </td>
                <td data-label="Act">Ruslana</td>
                <td data-label="Song">Wild Dances</td>
              </tr>
              <tr>
                <td data-label="Year">2003</td>
                <td data-label="Host">Riga, Latvia</td>
                <td data-label="Winner">Turkey</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={tr} />
                </td>
                <td data-label="Act">Sertab Erener</td>
                <td data-label="Song">Everyway That I Can</td>
              </tr>
              <tr>
                <td data-label="Year">2002</td>
                <td data-label="Host">Tallinn, Estonia</td>
                <td data-label="Winner">Latvia</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={lv} />
                </td>
                <td data-label="Act">Marie N</td>
                <td data-label="Song">I Wanna</td>
              </tr>
              <tr>
                <td data-label="Year">2001</td>
                <td data-label="Host">Copenhagen, Denmark</td>
                <td data-label="Winner">Estonia</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ee} />
                </td>
                <td data-label="Act">Tanel Padar, Dave Benton & 2XL</td>
                <td data-label="Song">Everybody</td>
              </tr>
              <tr>
                <td data-label="Year">2000</td>
                <td data-label="Host">Stockholm, Sweden</td>
                <td data-label="Winner">Denmark</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={dk} />
                </td>
                <td data-label="Act">Olsen Brothers</td>
                <td data-label="Song">Fly On The Wings Of Love</td>
              </tr>
              <tr>
                <td data-label="Year">1999</td>
                <td data-label="Host">Jerusalem, Israel</td>
                <td data-label="Winner">Sweden</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={se} />
                </td>
                <td data-label="Act">Charlotte Nilsson</td>
                <td data-label="Song">Take Me To Your Heaven</td>
              </tr>
              <tr>
                <td data-label="Year">1998</td>
                <td data-label="Host">Birmingham, UK</td>
                <td data-label="Winner">Israel</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={il} />
                </td>
                <td data-label="Act">Dana International</td>
                <td data-label="Song">Diva</td>
              </tr>
              <tr>
                <td data-label="Year">1997</td>
                <td data-label="Host">Dublin, Ireland</td>
                <td data-label="Winner">UK</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={gb} />
                </td>
                <td data-label="Act">Katrina and The Waves</td>
                <td data-label="Song">Love Shine A Light</td>
              </tr>
              <tr>
                <td data-label="Year">1996</td>
                <td data-label="Host">Oslo, Norway</td>
                <td data-label="Winner">Ireland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Eimear Quinn</td>
                <td data-label="Song">The Voice</td>
              </tr>
              <tr>
                <td data-label="Year">1995</td>
                <td data-label="Host">Dublin, Ireland</td>
                <td data-label="Winner">Norway</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={no} />
                </td>
                <td data-label="Act">Secret Garden</td>
                <td data-label="Song">Nocturne</td>
              </tr>
              <tr>
                <td data-label="Year">1994</td>
                <td data-label="Host">Dublin, Ireland</td>
                <td data-label="Winner">Ireland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Paul Harrington & Charlie McGettigan</td>
                <td data-label="Song">Rock 'n' Roll Kids</td>
              </tr>
              <tr>
                <td data-label="Year">1993</td>
                <td data-label="Host">Millstreet, Ireland</td>
                <td data-label="Winner">Ireland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Niamh Kavanagh</td>
                <td data-label="Song">In Your Eyes</td>
              </tr>
              <tr>
                <td data-label="Year">1992</td>
                <td data-label="Host">Malmo, Sweden</td>
                <td data-label="Winner">Ireland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Linda Martin</td>
                <td data-label="Song">Why Me</td>
              </tr>
              <tr>
                <td data-label="Year">1991</td>
                <td data-label="Host">Rome, Italy</td>
                <td data-label="Winner">Sweden</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={se} />
                </td>
                <td data-label="Act">Carola</td>
                <td data-label="Song">Fangad Av En Stormvind</td>
              </tr>
              <tr>
                <td data-label="Year">1990</td>
                <td data-label="Host">Zagreb, Yugoslavia</td>
                <td data-label="Winner">Italy</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={it} />
                </td>
                <td data-label="Act">Toto Cutugno</td>
                <td data-label="Song">Insieme: 1992</td>
              </tr>
              <tr>
                <td data-label="Year">1989</td>
                <td data-label="Host">Lausanne, Switzerland</td>
                <td data-label="Winner">Yugoslavia</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={yu} />
                </td>
                <td data-label="Act">Riva</td>
                <td data-label="Song">Rock Me</td>
              </tr>
              <tr>
                <td data-label="Year">1988</td>
                <td data-label="Host">Dublin, Ireland</td>
                <td data-label="Winner">Switzerland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ch} />
                </td>
                <td data-label="Act">Celine Dion</td>
                <td data-label="Song">Ne Partez Pas Sans Moi</td>
              </tr>
              <tr>
                <td data-label="Year">1987</td>
                <td data-label="Host">Brussels, Belgium</td>
                <td data-label="Winner">Ireland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Johnny Logan</td>
                <td data-label="Song">Hold Me Now</td>
              </tr>
              <tr>
                <td data-label="Year">1986</td>
                <td data-label="Host">Bergen, Norway</td>
                <td data-label="Winner">Belgium</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={be} />
                </td>
                <td data-label="Act">Sandra Kim</td>
                <td data-label="Song">J'aime La Vie</td>
              </tr>
              <tr>
                <td data-label="Year">1985</td>
                <td data-label="Host">Gothenburg, Sweden</td>
                <td data-label="Winner">Norway</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={no} />
                </td>
                <td data-label="Act">Bobbysocks</td>
                <td data-label="Song">La Det Swinge</td>
              </tr>
              <tr>
                <td data-label="Year">1984</td>
                <td data-label="Host">Luxembourg, Luxembourg</td>
                <td data-label="Winner">Sweden</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={se} />
                </td>
                <td data-label="Act">Herry's</td>
                <td data-label="Song">Diggi-loo Diggy-ley</td>
              </tr>
              <tr>
                <td data-label="Year">1983</td>
                <td data-label="Host">Munich, Germany</td>
                <td data-label="Winner">Luxembourg</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={lu} />
                </td>
                <td data-label="Act">Corinne Hermes</td>
                <td data-label="Song">Si La Vie Est Cadeau</td>
              </tr>
              <tr>
                <td data-label="Year">1982</td>
                <td data-label="Host">Harrogate, UK</td>
                <td data-label="Winner">Germany</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={de} />
                </td>
                <td data-label="Act">Nicole</td>
                <td data-label="Song">Ein Bisschen Frieden</td>
              </tr>
              <tr>
                <td data-label="Year">1981</td>
                <td data-label="Host">Dublin, Ireland</td>
                <td data-label="Winner">UK</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={gb} />
                </td>
                <td data-label="Act">Bucks Fizz</td>
                <td data-label="Song">Making Your Mind Up</td>
              </tr>
              <tr>
                <td data-label="Year">1980</td>
                <td data-label="Host">The Hague, Israel</td>
                <td data-label="Winner">Ireland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Johnny Logan</td>
                <td data-label="Song">What's Another Year</td>
              </tr>
              <tr>
                <td data-label="Year">1979</td>
                <td data-label="Host">Jerusalem, Israel</td>
                <td data-label="Winner">Israel</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Milk and Honey</td>
                <td data-label="Song">Hallelujah</td>
              </tr>
              <tr>
                <td data-label="Year">1978</td>
                <td data-label="Host">Paris, France</td>
                <td data-label="Winner">Israel</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Izhar Cohen and the Alphabeta</td>
                <td data-label="Song">A-Ba-Ni-Bi</td>
              </tr>
              <tr>
                <td data-label="Year">1977</td>
                <td data-label="Host">London, UK</td>
                <td data-label="Winner">France</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={fr} />
                </td>
                <td data-label="Act">Marie Myriam</td>
                <td data-label="Song">L'oiseau Et L'enfant</td>
              </tr>
              <tr>
                <td data-label="Year">1976</td>
                <td data-label="Host">The Hague, Israel</td>
                <td data-label="Winner">UK</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={gr} />
                </td>
                <td data-label="Act">Brotherhood of Man</td>
                <td data-label="Song">Save Your Kisses For Me</td>
              </tr>
              <tr>
                <td data-label="Year">1975</td>
                <td data-label="Host">Stockholm, Sweden</td>
                <td data-label="Winner">Netherlands</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={nl} />
                </td>
                <td data-label="Act">Teach-In</td>
                <td data-label="Song">Ding-A-Dong</td>
              </tr>
              <tr>
                <td data-label="Year">1974</td>
                <td data-label="Host">Brighton, UK</td>
                <td data-label="Winner">Sweden</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={se} />
                </td>
                <td data-label="Act">ABBA</td>
                <td data-label="Song">Waterloo</td>
              </tr>
              <tr>
                <td data-label="Year">1973</td>
                <td data-label="Host">Luxembourg, Luxembourg</td>
                <td data-label="Winner">Luxembourg</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={lu} />
                </td>
                <td data-label="Act">Anne-Marie David</td>
                <td data-label="Song">Tu Te Reconnaitras</td>
              </tr>
              <tr>
                <td data-label="Year">1972</td>
                <td data-label="Host">Edinburgh, UK</td>
                <td data-label="Winner">Luxembourg</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={lu} />
                </td>
                <td data-label="Act">Vicky Leandros</td>
                <td data-label="Song">Apres Toi</td>
              </tr>
              <tr>
                <td data-label="Year">1971</td>
                <td data-label="Host">Dublin, Ireland</td>
                <td data-label="Winner">Monaco</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={mc} />
                </td>
                <td data-label="Act">Un Banc, Un Arbre, Une Rue</td>
                <td data-label="Song">Severine</td>
              </tr>
              <tr>
                <td data-label="Year">1970</td>
                <td data-label="Host">Amsterdam, Netherlands</td>
                <td data-label="Winner">Ireland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ie} />
                </td>
                <td data-label="Act">Dana</td>
                <td data-label="Song">All Kinds Of Everything</td>
              </tr>
              <tr>
                <td data-label="Year">1969</td>
                <td data-label="Host">Madrid, Spain</td>
                <td data-label="Winner">
                  France
                  <br /> Netherlands <br />
                  Spain <br />
                  UK
                </td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={fr} />
                  <br />
                  <img className="eurovision-flag" src={nl} />
                  <br />
                  <img className="eurovision-flag" src={es} />
                  <br />
                  <img className="eurovision-flag" src={gb} />
                </td>
                <td data-label="Act">
                  Frida Boccara
                  <br />
                  Lenny Kuhr
                  <br />
                  Salome
                  <br />
                  Lulu
                </td>
                <td data-label="Song">
                  Un Jour, Un Enfant
                  <br />
                  De Troubadour
                  <br />
                  Vivo Cantando
                  <br />
                  Boom Bang-a-bang
                </td>
              </tr>
              <tr>
                <td data-label="Year">1968</td>
                <td data-label="Host">London, UK</td>
                <td data-label="Winner">Spain</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={es} />
                </td>
                <td data-label="Act">Massiel</td>
                <td data-label="Song">La, La, La...</td>
              </tr>
              <tr>
                <td data-label="Year">1967</td>
                <td data-label="Host">Vienna, Austria</td>
                <td data-label="Winner">UK</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={gb} />
                </td>
                <td data-label="Act">Sandie Shaw</td>
                <td data-label="Song">Puppet On A String</td>
              </tr>
              <tr>
                <td data-label="Year">1966</td>
                <td data-label="Host">Luxembourg, Luxembourg</td>
                <td data-label="Winner">Austria</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={at} />
                </td>
                <td data-label="Act">Udo Jurgens</td>
                <td data-label="Song">Merci Cherie</td>
              </tr>
              <tr>
                <td data-label="Year">1965</td>
                <td data-label="Host">Naples, Italy</td>
                <td data-label="Winner">Luxembourg</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={lu} />
                </td>
                <td data-label="Act">France Gall</td>
                <td data-label="Song">Poupee De Cire, Poupee De Son</td>
              </tr>
              <tr>
                <td data-label="Year">1964</td>
                <td data-label="Host">Copenhagen, Denmark</td>
                <td data-label="Winner">Italy</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={it} />
                </td>
                <td data-label="Act">Gigliola Cinquetti</td>
                <td data-label="Song">Non Ho L'eta</td>
              </tr>
              <tr>
                <td data-label="Year">1963</td>
                <td data-label="Host">London, UKy</td>
                <td data-label="Winner">Denmark</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ua} />
                </td>
                <td data-label="Act">Grethe & Jorgen Ingmann</td>
                <td data-label="Song">Dansevise</td>
              </tr>
              <tr>
                <td data-label="Year">1962</td>
                <td data-label="Host">Luxembourg, Luxembourg</td>
                <td data-label="Winner">France</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={fr} />
                </td>
                <td data-label="Act">Isabelle Aubret</td>
                <td data-label="Song">Un Premier Amour</td>
              </tr>
              <tr>
                <td data-label="Year">1961</td>
                <td data-label="Host">Cannes, France</td>
                <td data-label="Winner">Luxembourg</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ua} />
                </td>
                <td data-label="Act">Jean-Claude Pascal</td>
                <td data-label="Song">Nous Les Amoureux</td>
              </tr>
              <tr>
                <td data-label="Year">1960</td>
                <td data-label="Host">London, UK</td>
                <td data-label="Winner">France</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={fr} />
                </td>
                <td data-label="Act">Jacqueline Boyer</td>
                <td data-label="Song">Tom Pillibi</td>
              </tr>
              <tr>
                <td data-label="Year">1959</td>
                <td data-label="Host">Cannnes, France</td>
                <td data-label="Winner">Netherlands</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={nl} />
                </td>
                <td data-label="Act">Teddy Scholten</td>
                <td data-label="Song">Een Beetje</td>
              </tr>
              <tr>
                <td data-label="Year">1958</td>
                <td data-label="Host">Hilversum, Netherlands</td>
                <td data-label="Winner">France</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={fr} />
                </td>
                <td data-label="Act">Andre Claveau</td>
                <td data-label="Song">Dors Mon Amour</td>
              </tr>
              <tr>
                <td data-label="Year">1957</td>
                <td data-label="Host">Frankfurt, Germany</td>
                <td data-label="Winner">Netherlands</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={nl} />
                </td>
                <td data-label="Act">Corry Brokken</td>
                <td data-label="Song">Net Als Toen</td>
              </tr>
              <tr>
                <td data-label="Year">1956</td>
                <td data-label="Host">Lugano, Switzerland</td>
                <td data-label="Winner">Switzerland</td>
                <td data-label="Flag">
                  <img className="eurovision-flag" src={ch} />
                </td>
                <td data-label="Act">Lys Assia</td>
                <td data-label="Song">Refrain</td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={eurovision.importantFacts}
          classicQuestions={eurovision.classicQuestions}
          exampleQuestions={eurovision.exampleQuestions}
          thoughtsTipsIdeas={eurovision.thoughtsTipsAndIdeas}
          // smallPeep1={eurovision.smallPeep1}
          smallPeep2={eurovision.smallPeep2}
          disclaimer={eurovision.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnEurovision;
