import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveThreeCols from "./LearnTopFiveThreeCols";
import LearnTopFiveThreeColsHeading from "./LearnTopFiveThreeColsHeading";
import LearnTopFiveFourCols from "./LearnTopFiveFourCols";
import LearnTopFiveHeadingFourCols from "./LearnTopFiveHeadingFourCols";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import { footballWorldCup } from "../../LearnSpecificCategoryDetails";
import { topFivesSport } from "../../topFivesInfo";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/top5.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive key={topFive.key} col2={topFive.col2} col1={topFive.col1} />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}
function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}
function ArrangeTopFiveFourCols(topFive) {
  return (
    <LearnTopFiveFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
    />
  );
}
function ArrangeTopFiveFourColsHeading(topFive) {
  return (
    <LearnTopFiveHeadingFourCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col4={topFive.col4}
    />
  );
}

function LearnSportFootballWorldCup() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={footballWorldCup.mainCategory}
          subCategory={footballWorldCup.subCategory}
          iconLrg={footballWorldCup.iconLrg}
          iconSml={footballWorldCup.iconSml}
          bigPeep={footballWorldCup.bigPeep}
          intro={footballWorldCup.intro}
        />

        <div className="top5s-container">
          <div className="top5TwoCols">
            <h2>Last 5 World Cup Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topWorldCup.lastWorldCupWinnersHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.football.topWorldCup.lastWorldCupWinners.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 World Cup Hosts</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topWorldCup.lastWorldCupHostsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.football.topWorldCup.lastWorldCupHosts.map(
              ArrangeTopFive
            )}
          </div>
          <div className="top5TwoCols">
            <h2>Top 6 Most World Cup Wins</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topWorldCup.mostWorldCupWinsHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesSport.football.topWorldCup.mostWorldCupWins.map(
              ArrangeTopFive
            )}
          </div>
        </div>

        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Number</th>
                <th>Year</th>
                <th>Hosts</th>
                <th>Winners</th>
                <th>Runners Up</th>
                <th>Top Goalscorer(s) /Goals/Country</th>
                <th>Number Of Teams</th>
                <th>Result In The Final</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Number">1</td>
                <td data-label="Year">1930</td>
                <td data-label="Hosts">Uruguay</td>
                <td data-label="Winners">Uruguay</td>
                <td data-label="Runners Up">Argentina</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Guillermo Stábile /8/Argentina
                </td>
                <td data-label="Number Of Teams">13</td>
                <td data-label="Result In The Final">4-2</td>
              </tr>
              <tr>
                <td data-label="Number">2</td>
                <td data-label="Year">1934</td>
                <td data-label="Hosts">Italy</td>
                <td data-label="Winners">Italy</td>
                <td data-label="Runners Up">Czechoslovakia</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Oldřich Nejedlý /5/Czechoslovakia
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">3</td>
                <td data-label="Year">1938</td>
                <td data-label="Hosts">France</td>
                <td data-label="Winners">Italy</td>
                <td data-label="Runners Up">Hungary</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Leônidas /7/Brazil
                </td>
                <td data-label="Number Of Teams">15</td>
                <td data-label="Result In The Final">4-2</td>
              </tr>
              <tr>
                <td data-label="Number">4</td>
                <td data-label="Year">1950</td>
                <td data-label="Hosts">Brazil</td>
                <td data-label="Winners">Uruguay</td>
                <td data-label="Runners Up">Brazil</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Ademir /8/Brazil
                </td>
                <td data-label="Number Of Teams">13</td>
                <td data-label="Result In The Final">2-1 (Not a real final)</td>
              </tr>
              <tr>
                <td data-label="Number">5</td>
                <td data-label="Year">1954</td>
                <td data-label="Hosts">Switzerland</td>
                <td data-label="Winners">West Germany</td>
                <td data-label="Runners Up">Hungary</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Sándor Kocsis /11/Hungary
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">3-2</td>
              </tr>
              <tr>
                <td data-label="Number">6</td>
                <td data-label="Year">1958</td>
                <td data-label="Hosts">Sweden</td>
                <td data-label="Winners">Brazil</td>
                <td data-label="Runners Up">Sweden</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Just Fontaine /13/France
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">5-2</td>
              </tr>
              <tr>
                <td data-label="Number">7</td>
                <td data-label="Year">1962</td>
                <td data-label="Hosts">Chile</td>
                <td data-label="Winners">Brazil</td>
                <td data-label="Runners Up">Czechoslovakia</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  6 Players /4/Various
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">3-1</td>
              </tr>
              <tr>
                <td data-label="Number">8</td>
                <td data-label="Year">1966</td>
                <td data-label="Hosts">England</td>
                <td data-label="Winners">England</td>
                <td data-label="Runners Up">West Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Eusebio /9/Portugal
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">4-2</td>
              </tr>
              <tr>
                <td data-label="Number">9</td>
                <td data-label="Year">1970</td>
                <td data-label="Hosts">Mexico</td>
                <td data-label="Winners">Brazil</td>
                <td data-label="Runners Up">Italy</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Gerhard (Gerd) Müller /10/West Germany
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">4-1</td>
              </tr>
              <tr>
                <td data-label="Number">10</td>
                <td data-label="Year">1974</td>
                <td data-label="Hosts">West Germany</td>
                <td data-label="Winners">West Germany</td>
                <td data-label="Runners Up">Netherlands</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Grzegorz Lato /7/Poland
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">2-1</td>
              </tr>
              <tr>
                <td data-label="Number">11</td>
                <td data-label="Year">1978</td>
                <td data-label="Hosts">Argentina</td>
                <td data-label="Winners">Argentina</td>
                <td data-label="Runners Up">Netherlands</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Mario Kempes /6/Argentina
                </td>
                <td data-label="Number Of Teams">16</td>
                <td data-label="Result In The Final">3-1</td>
              </tr>
              <tr>
                <td data-label="Number">12</td>
                <td data-label="Year">1982</td>
                <td data-label="Hosts">Spain</td>
                <td data-label="Winners">Italy</td>
                <td data-label="Runners Up">West Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Paulo Rossi /6/Italy
                </td>
                <td data-label="Number Of Teams">24</td>
                <td data-label="Result In The Final">3-1</td>
              </tr>
              <tr>
                <td data-label="Number">13</td>
                <td data-label="Year">1986</td>
                <td data-label="Hosts">Mexico</td>
                <td data-label="Winners">Argentina</td>
                <td data-label="Runners Up">West Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Gary Lineker /6/England
                </td>
                <td data-label="Number Of Teams">24</td>
                <td data-label="Result In The Final">3-2</td>
              </tr>
              <tr>
                <td data-label="Number">14</td>
                <td data-label="Year">1990</td>
                <td data-label="Hosts">Italy</td>
                <td data-label="Winners">West Germany</td>
                <td data-label="Runners Up">Argentina</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Salvatore Schillaci /6/Italy
                </td>
                <td data-label="Number Of Teams">24</td>
                <td data-label="Result In The Final">1-0</td>
              </tr>
              <tr>
                <td data-label="Number">15</td>
                <td data-label="Year">1994</td>
                <td data-label="Hosts">USA</td>
                <td data-label="Winners">Brazil</td>
                <td data-label="Runners Up">Italy</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Hristo Stoichkov /6/Bulgaria & Oleg Salenko /6/Russia
                </td>
                <td data-label="Number Of Teams">24</td>
                <td data-label="Result In The Final">0-0 (3-2 Penalties)</td>
              </tr>
              <tr>
                <td data-label="Number">16</td>
                <td data-label="Year">1998</td>
                <td data-label="Hosts">France</td>
                <td data-label="Winners">France</td>
                <td data-label="Runners Up">Brazil</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Davor Šuker /6/Croatia
                </td>
                <td data-label="Number Of Teams">32</td>
                <td data-label="Result In The Final">3-0</td>
              </tr>
              <tr>
                <td data-label="Number">17</td>
                <td data-label="Year">2002</td>
                <td data-label="Hosts">Japan &amp; South Korea</td>
                <td data-label="Winners">Brazil</td>
                <td data-label="Runners Up">Germany</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Ronaldo /8/Brazil
                </td>
                <td data-label="Number Of Teams">32</td>
                <td data-label="Result In The Final">2-0</td>
              </tr>
              <tr>
                <td data-label="Number">18</td>
                <td data-label="Year">2006</td>
                <td data-label="Hosts">Germany</td>
                <td data-label="Winners">Italy</td>
                <td data-label="Runners Up">France</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Miroslav Klose /5/Germany
                </td>
                <td data-label="Number Of Teams">32</td>
                <td data-label="Result In The Final">1-1 (5-3 Penalties)</td>
              </tr>
              <tr>
                <td data-label="Number">19</td>
                <td data-label="Year">2010</td>
                <td data-label="Hosts">South Africa</td>
                <td data-label="Winners">Spain</td>
                <td data-label="Runners Up">Netherlands</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  4 Players /5/Various
                </td>
                <td data-label="Number Of Teams">32</td>
                <td data-label="Result In The Final">1-0</td>
              </tr>
              <tr>
                <td data-label="Number">20</td>
                <td data-label="Year">2014</td>
                <td data-label="Hosts">Brazil</td>
                <td data-label="Winners">Germany</td>
                <td data-label="Runners Up">Argentina</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  James Rodriguez /6/Columbia
                </td>
                <td data-label="Number Of Teams">32</td>
                <td data-label="Result In The Final">1-0</td>
              </tr>
              <tr>
                <td data-label="Number">21</td>
                <td data-label="Year">2018</td>
                <td data-label="Hosts">Russia</td>
                <td data-label="Winners">France</td>
                <td data-label="Runners Up">Croatia</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Harry Kane /6/England
                </td>
                <td data-label="Number Of Teams">32</td>
                <td data-label="Result In The Final">4-2</td>
              </tr>
              <tr>
                <td data-label="Number">22</td>
                <td data-label="Year">2022</td>
                <td data-label="Hosts">Qatar</td>
                <td data-label="Winners">Argentina</td>
                <td data-label="Runners Up">France</td>
                <td data-label="Top Goalscorer(s) /Goals/Country">
                  Kylian Mbappé /8/France
                </td>
                <td data-label="Number Of Teams">32</td>
                <td data-label="Result In The Final">3-3 (4-2 Penalties)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="top5s-container">
          <div className="top5ThreeOrMoreCols">
            <h2>Top 5 World Cup Youngest Players</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topWorldCup.youngestWorldCupAppHeadings.map(
              ArrangeTopFiveThreeColsHeading
            )}

            {topFivesSport.football.topWorldCup.youngestWorldCupApp.map(
              ArrangeTopFiveThreeCols
            )}
          </div>
          <div className="top5ThreeOrMoreCols">
            <h2>Top 5 World Cup Youngest Goal Scorers</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topWorldCup.youngestWorldCupScorerHeadings.map(
              ArrangeTopFiveThreeColsHeading
            )}

            {topFivesSport.football.topWorldCup.youngestWorldCupScorer.map(
              ArrangeTopFiveThreeCols
            )}
          </div>
          <div className="top5ThreeOrMoreCols">
            <h2>Top 5 World Cup Oldest Players</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topWorldCup.oldestWorldCupAppHeadings.map(
              ArrangeTopFiveThreeColsHeading
            )}

            {topFivesSport.football.topWorldCup.oldestWorldCupApp.map(
              ArrangeTopFiveThreeCols
            )}
          </div>
          <div className="top5ThreeOrMoreCols">
            <h2>Top 5 World Cup Oldest Goal Scorers</h2>
            <div className="sidebar-line"></div>
            {topFivesSport.football.topWorldCup.oldestWorldCupScorerHeadings.map(
              ArrangeTopFiveThreeColsHeading
            )}

            {topFivesSport.football.topWorldCup.oldestWorldCupScorer.map(
              ArrangeTopFiveThreeCols
            )}
          </div>
        </div>

        <LearnSpecificCategoryBottom
          importantFacts={footballWorldCup.importantFacts}
          classicQuestions={footballWorldCup.classicQuestions}
          exampleQuestions={footballWorldCup.exampleQuestions}
          thoughtsTipsIdeas={footballWorldCup.thoughtsTipsAndIdeas}
          smallPeep1={footballWorldCup.smallPeep1}
          smallPeep2={footballWorldCup.smallPeep2}
          disclaimer={footballWorldCup.disclaimer}
        />
        <AsideNav />
      </section>
      <div className="top5s-container">
        <div className="top5ThreeOrMoreCols top5FourCols">
          <h2>Last 5 World Cup Golden Boot Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.football.footballAwards.goldenBootWorldCupLastWinnersHeadings.map(
            ArrangeTopFiveFourColsHeading
          )}

          {topFivesSport.football.footballAwards.goldenBootWorldCupLastWinners.map(
            ArrangeTopFiveFourCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols top5FourCols">
          <h2>Top 7 World Cup Golden Boot Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesSport.football.footballAwards.goldenBootWorldCupTopWinnersHeadings.map(
            ArrangeTopFiveFourColsHeading
          )}

          {topFivesSport.football.footballAwards.goldenBootWorldCupTopWinners.map(
            ArrangeTopFiveFourCols
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnSportFootballWorldCup;
