import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import SportingNickname from "./SportingNickname";
import { sportingNicknames } from "../../LearnSpecificCategoryDetailsThree";
import AsideNav from "../AsideNav";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeSportingNicknames(sn) {
  return (
    <SportingNickname
      key={sn.id}
      realname={sn.realname}
      nickname={sn.nickname}
    />
  );
}

function LearnSportingNicknames() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        {/* <TitleContainer title={alterEgo.title} image={alterEgo.image} /> */}
        <h1 className="category-title">Sporting Nicknames</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                This page is about famous sports people, and their sporting
                nickname. <br />
                Their real name is written in white and their nickname is
                written in orange.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small26.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">American Football</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.americanFootball.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Baseball</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.baseball.map(ArrangeSportingNicknames)}
            <div className="line-subtitle"></div>
          </div>
          <h2 className="category-subtitle centre">Basketball</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.basketball.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Boxing</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.boxing.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Cricket</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.cricket.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Darts</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.darts.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Football</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.football.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Track & Field</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.trackAndField.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">MMA</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.mma.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Ice Hockey</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.iceHockey.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Golf</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.golf.map(ArrangeSportingNicknames)}
          </div>
          <div className="line-subtitle"></div>
          <h2 className="category-subtitle centre">Snooker</h2>
          <div className="alter-ego-list-container">
            {sportingNicknames.snooker.map(ArrangeSportingNicknames)}
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnSportingNicknames;
