import React from "react";
import "../../css/style.css";
import "../../css/peeps.css";
import { Link } from "react-router-dom";

function LearnSpecificCategoryBottom(props) {
  return (
    <div className="container">
      {/* <div className="line"></div> */}
      <h2 className="category-subtitle">Important Facts</h2>
      {props.importantFacts.map((iF, index) => (
        <p key={index} className="body-text">
          {iF}
        </p>
      ))}
      {/* <br />
      <br /> */}
      {props.disclaimer && <p className="small-text">{props.disclaimer}</p>}
      <div className="line"></div>
      <section className="c-and-e-questions">
        <div className="border-separater">
          <h2 className="category-subtitle">Classic Questions</h2>
          <p className="c-and-e-subtitle">
            Questions that are frequently asked in quizzes.
          </p>
          <div className="line-white"></div>
          <div className="c-and-e-questions-flex">
            {props.classicQuestions.map((cQ) => (
              <div className="c-and-e-q-and-a" key={cQ.id}>
                <p>{cQ.question}</p>
                <p className="answer-orange">{cQ.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="c-and-e-questions">
        <div className="border-separater">
          <h2 className="category-subtitle">Example Questions</h2>
          <p className="c-and-e-subtitle">
            These questions relate to the information on this page and will test
            how well you have remembered it.
          </p>
          <div className="line-white"></div>

          <div className="c-and-e-questions-flex">
            {props.exampleQuestions.map((eQ) => (
              <div className="c-and-e-q-and-a" key={eQ.id}>
                <p>{eQ.question}</p>
                <p className="answer-orange">{eQ.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* <div className="line"></div> */}
      <section>
        <h2 className="category-subtitle">Thoughts, Tips and Ideas</h2>
        {props.thoughtsTipsIdeas.map((t) => (
          <p key={t.id} className="body-text">
            {t.text}
          </p>
        ))}
        {props.button1 && (
          <div className="button-flex">
            <Link
              className="art-list"
              to="/learn/entertainment/films-by-decade"
            >
              {props.button1}
            </Link>
            <Link className="art-list" to="#">
              {props.button2}
            </Link>
          </div>
        )}
        <div className="peep-push-right">
          <img
            // className="peep peep-right peep-flip"
            className="peep-flip"
            src={props.smallPeep2}
            alt="cartoon character"
          />
        </div>
      </section>
    </div>
  );
}

export default LearnSpecificCategoryBottom;
