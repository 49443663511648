import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import { periodicTable } from "../../LearnSpecificCategoryDetails";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";

function LearnPeriodicTable() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={periodicTable.mainCategory}
          subCategory={periodicTable.subCategory}
          iconLrg={periodicTable.iconLrg}
          iconSml={periodicTable.iconSml}
          bigPeep={periodicTable.bigPeep}
          intro={periodicTable.intro}
        />
        <div className="table-container">
          <img
            className="periodic-table-img"
            src={require("../../images/PeriodicTable.jpg")}
          />
        </div>
        <div className="table-container">
          <table className="table periodic-table-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Number</th>
                <th>Name Origin</th>
                <th>Notes</th>
                <th>Flame Test</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Name">Hydrogen</td>
                <td data-label="Number">1</td>
                <td data-label="Name Origin">
                  In Greek, hydrogen means 'water-former'
                </td>
                <td data-label="Notes">
                  Makes up more than 90% of all of the atoms on Earth, used as
                  fuel in stars
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Helium</td>
                <td data-label="Number">2</td>
                <td data-label="Name Origin">
                  From the Greek, 'helios' meaning Sun
                </td>
                <td>
                  Helium makes up about 24% of the mass of the universe and is
                  the second most abundant element. It can be used to fill
                  balloons. Liquid helium is used to cool MRI machines
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Lithium</td>
                <td data-label="Number">3</td>
                <td data-label="Name Origin">Lithos (Greek for 'stone')</td>
                <td data-label="Notes">
                  Lithium burns with a bright red colour, it is added to
                  fireworks to make red sparks. Used in rechargeable batteries
                </td>
                <td data-label="Flame Test">red</td>
              </tr>
              <tr>
                <td data-label="Name">Beryllium</td>
                <td data-label="Number">4</td>
                <td data-label="Name Origin">
                  From the Greek name for beryl, a gemstone
                </td>
                <td data-label="Notes">
                  Beryllium is used in the Spitzer Space Telescope - due to how
                  strong and lightweight it is
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Boron</td>
                <td data-label="Number">5</td>
                <td data-label="Name Origin">
                  From the Arabic 'buraq', which was the name for the mineral
                  borax
                </td>
                <td data-label="Notes">
                  An essential nutrient, all plants must have boron for normal,
                  healthy growth
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Carbon</td>
                <td data-label="Number">6</td>
                <td data-label="Name Origin">
                  Latin word carbo, which means 'coal'
                </td>
                <td data-label="Notes">
                  Our bodies are 18.5 percent carbon, by weight. It has a
                  variety of forms, eg. graphite and diamond
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Nitrogen</td>
                <td data-label="Number">7</td>
                <td data-label="Name Origin">
                  The name is derived from the Greek 'nitron' and 'genes'
                  meaning nitre forming
                </td>
                <td data-label="Notes">
                  Nitrogen gas (N2) makes up 78.1% of the Earth's atmosphere
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Oxygen</td>
                <td data-label="Number">8</td>
                <td data-label="Name Origin">
                  From the Greek 'oxy genes', meaning acid forming
                </td>
                <td data-label="Notes">
                  Oxygen makes up 65% of the body's mass and 21% of the air we
                  breathe
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Fluorine</td>
                <td data-label="Number">9</td>
                <td data-label="Name Origin">
                  From the Latin word 'fluere' meaning 'to flow'
                </td>
                <td data-label="Notes">
                  It is found as fluoride in toothpaste and drinking water to
                  strengthen teeth. Fluorine is the most reactive and most
                  electronegative of all the chemical elements
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Neon</td>
                <td data-label="Number">10</td>
                <td data-label="Name Origin">
                  From the Greek 'neos', meaning new
                </td>
                <td data-label="Notes">
                  The most common use of the gas is in neon signs, it glows
                  orange when electrified. Neon is one of the rarest elements on
                  Earth
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Sodium</td>
                <td data-label="Number">11</td>
                <td data-label="Name Origin">
                  From the English word 'soda' (the origin of the symbol Na
                  comes from the Latin word 'natrium')
                </td>
                <td data-label="Notes">
                  It is found joined with chlorine to make common salt. At room
                  temperature, sodium metal is soft enough that you can cut it
                  with a butter knife
                </td>
                <td data-label="Flame Test">strong yellow/orange</td>
              </tr>
              <tr>
                <td data-label="Name">Magnesium</td>
                <td data-label="Number">12</td>
                <td data-label="Name Origin">
                  Derived from Magnesia, a district of Eastern Thessaly in
                  Greece
                </td>
                <td data-label="Notes">
                  Ignites easily in air and burns with a bright white flame, and
                  is used in fireworks. Magnesium is the most lightweight of all
                  the metallic elements, it is used in cars and aircraft
                  manufacture
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Aluminium</td>
                <td data-label="Number">13</td>
                <td data-label="Name Origin">
                  From the Latin 'alumen' meaning bitter salt
                </td>
                <td data-label="Notes">
                  Aluminium is infinitely recyclable. It is very lightweight and
                  so is used in aircraft manufacture
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Silicon</td>
                <td data-label="Number">14</td>
                <td data-label="Name Origin">
                  Derived from the Latin 'silex' or 'silicis', meaning flint
                </td>
                <td data-label="Notes">
                  It is a semiconductor and used in computer transistors, a
                  place in San Francisco, California is named Silicon Valley due
                  to the number of computer and technology companies there
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Phosphorus</td>
                <td data-label="Number">15</td>
                <td data-label="Name Origin">
                  From the Greek phosphorus meaning 'bringing light' because it
                  has the property of glowing in the dark
                </td>
                <td data-label="Notes">
                  Red phosphorus is found on the side of safety matchboxes.
                  Phosphorus is found in bones
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Sulphur</td>
                <td data-label="Number">16</td>
                <td data-label="Name Origin">
                  From the Sanskrit word 'sulvere' meaning 'sulphur' or from the
                  Latin word 'sulphurium' meaning 'sulphur'
                </td>
                <td data-label="Notes">
                  Found near volcanoes, it can give compounds a rotten egg
                  smell. Also known as 'brimstone'
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Chlorine</td>
                <td data-label="Number">17</td>
                <td data-label="Name Origin">
                  From the Greek chloros, meaning "yellowish green"
                </td>
                <td data-label="Notes">
                  Extremely toxic as a gas. A small amount of Chlorine is used
                  in drinking water and swimming pool water to kill bacteria
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Argon</td> <td data-label="Number">18</td>
                <td data-label="Name Origin">
                  From the Greek word 'argos' meaning 'lazy' or 'inactive'
                </td>
                <td data-label="Notes">
                  It is a noble gas so very unreactive. Argon is used in welding
                  and some medical instruments
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Potassium</td>
                <td data-label="Number">19</td>
                <td data-label="Name Origin">
                  Derived from the English word 'potash'
                </td>
                <td data-label="Notes">
                  Found in bananas. It regulates fluid balance, and is needed
                  for muscle contractions and nerve signals
                </td>
                <td data-label="Flame Test">lilac (pink)</td>
              </tr>
              <tr>
                <td data-label="Name">Calcium</td>
                <td data-label="Number">20</td>
                <td data-label="Name Origin">
                  From the Latin term calx meaning lime
                </td>
                <td data-label="Notes">
                  Needed for healthy bones and teeth. Found in dairy products.
                  To absorb calcium, your body also needs vitamin D
                </td>
                <td data-label="Flame Test">orange-red</td>
              </tr>
              <tr>
                <td data-label="Name">Scandium</td>
                <td data-label="Number">21</td>
                <td data-label="Name Origin">
                  From 'Scandia', the Latin name for Scandinavia
                </td>
                <td data-label="Notes">
                  It is mixed with aluminium for aerospace industry components,
                  bicycle frames, fishing rods and baseball bats
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Titanium</td>
                <td data-label="Number">22</td>
                <td data-label="Name Origin">
                  Named after the Titans, the sons of the Earth goddess of Greek
                  mythology
                </td>
                <td data-label="Notes">
                  Used in jewellery, prosthetics, bicycle frames and surgical
                  tools. It is strong as steel but weighs half as much
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Vanadium</td>
                <td data-label="Number">23</td>
                <td data-label="Name Origin">
                  Named after 'Vanadis', the old Norse name for the Scandinavian
                  goddess Freyja
                </td>
                <td data-label="Notes">
                  Vanadium alloys are used to make nuclear reactors because of
                  their low-neutron-absorbing properties
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Chromium</td>
                <td data-label="Number">24</td>
                <td data-label="Name Origin">
                  From the Greek 'chroma', meaning colour
                </td>
                <td data-label="Notes">
                  Chromium is used to harden steel, in the manufacture of
                  stainless steel. Chromium plating has a silver, shiny
                  appearance, and is used to coat cars, bicycles, stoves and
                  other appliances to protect them from corrosion. Rubies get
                  their red colour from chromium, and glass treated with
                  chromium has an emerald green colour. Chromium is an essential
                  trace element for humans because it helps us to use glucose
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Manganese</td>
                <td data-label="Number">25</td>
                <td data-label="Name Origin">
                  From the Latin 'magnes', meaning magnet
                </td>
                <td data-label="Notes">
                  Used for making steel, batteries, alloys, pigments,
                  fertiliser, bricks, glass. Manganese helps the body form
                  connective tissue and bones, blood clots, and sex hormones.
                  Found in mussels, sweet potatoes, nuts, spinach, and
                  pineapples
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Iron</td>
                <td data-label="Number">26</td>
                <td data-label="Name Origin">
                  The Latin name for iron is ferrum, which is the source of its
                  atomic symbol, Fe. The word iron is from an Anglo-Saxon word,
                  'iren'
                </td>
                <td data-label="Notes">
                  Rusts easily in pure form. Used to make steel. The body uses
                  iron to make haemoglobin
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Cobalt</td>
                <td data-label="Number">27</td>
                <td data-label="Name Origin">
                  From 'Kobold,' the name of a mischievous goblin in German
                  mythology
                </td>
                <td data-label="Notes">
                  A primary metal used in lithium-ion batteries
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Nickel</td>
                <td data-label="Number">28</td>
                <td data-label="Name Origin">
                  The shortened form of the German 'kupfernickel' meaning either
                  devil's copper or St. Nicholas's copper
                </td>
                <td data-label="Notes">
                  Used in coins. Strengthens stainless steel, and is used in
                  cooking equipment
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Copper</td>
                <td data-label="Number">29</td>
                <td data-label="Name Origin">
                  From the Latin cuprum for Cyprus, the island where the Romans
                  first obtained copper
                </td>
                <td data-label="Notes">
                  Most copper is used in electrical equipment such as wiring and
                  motors and in plumbing. It is soft and flexible
                </td>
                <td data-label="Flame Test">blue-green (with white flashes)</td>
              </tr>
              <tr>
                <td data-label="Name">Zinc</td>
                <td data-label="Number">30</td>
                <td data-label="Name Origin">
                  From the German word 'zinke' meaning 'spiked' (for the spiked
                  shapes of the zinc crystals)
                </td>
                <td data-label="Notes">
                  It is needed for immune function, wound healing and blood
                  clotting
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Gallium</td>
                <td data-label="Number">31</td>
                <td data-label="Name Origin">
                  Named after the Latin word for France "Gallia"
                </td>
                <td data-label="Notes">
                  Gallium is able to turn electricity into light, it is used to
                  make LEDs and is used in electronic circuits
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Germanium</td>
                <td data-label="Number">32</td>
                <td data-label="Name Origin">
                  Derived from the Latin name for Germany, 'Germania'
                </td>
                <td data-label="Notes">
                  It has been used extensively in optical fibres, camera lenses
                  and microscopy
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Arsenic</td>
                <td data-label="Number">33</td>
                <td data-label="Name Origin">
                  From 'arsenikon', the Greek name for the yellow pigment
                  orpiment
                </td>
                <td data-label="Notes">
                  Highly poisonous, often the poison of choice for murders in
                  literature
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Selenium</td>
                <td data-label="Number">34</td>
                <td data-label="Name Origin">
                  From the Greek word for moon, selene
                </td>
                <td data-label="Notes">
                  Used in the glass industry to decolourise glass and to make
                  red-coloured glasses and enamels. It can help improve
                  cognition, immune system function, and fertility
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Bromine</td>
                <td data-label="Number">35</td>
                <td data-label="Name Origin">
                  Based on the Greek word bromos meaning stench
                </td>
                <td data-label="Notes">
                  An alternative to chlorine for killing bacteria and fungi in
                  swimming pools. Found in some flame retardant materials.
                  Liquid at room temperature
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Krypton</td>
                <td data-label="Number">36</td>
                <td data-label="Name Origin">
                  From the Greek 'kryptos', meaning hidden
                </td>
                <td data-label="Notes">
                  A very rare gas on Earth. It has a blue-white glow when
                  electrified. Used for high-powered lasers
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Rubidium</td>
                <td data-label="Number">37</td>
                <td data-label="Name Origin">
                  From the Latin 'rubidius', meaning deepest red
                </td>
                <td data-label="Notes">
                  Rubidium and its salts have few commercial uses. The metal is
                  used in the manufacture of photocells and in the removal of
                  residual gases from vacuum tubes
                </td>
                <td data-label="Flame Test">red (red-violet)</td>
              </tr>
              <tr>
                <td data-label="Name">Strontium</td>
                <td data-label="Number">38</td>
                <td data-label="Name Origin">
                  Named after the Scottish village of Strontian
                </td>
                <td data-label="Notes">
                  Best known for the brilliant reds its salts give to fireworks
                  and flares
                </td>
                <td data-label="Flame Test">red</td>
              </tr>
              <tr>
                <td data-label="Name">Yttrium</td>
                <td data-label="Number">39</td>
                <td data-label="Name Origin">
                  1 of 4 elements named after Ytterby, Sweden
                </td>
                <td data-label="Notes">
                  The most important uses of yttrium are LEDs and phosphors,
                  particularly the red phosphors in television set cathode ray
                  tube displays. It has no biological roles, and can cause lung
                  disease
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Zirconium</td>
                <td data-label="Number">40</td>
                <td data-label="Name Origin">
                  From the Arabic word zargun which refers to a golden-hued
                  gemstone known since Biblical times called zircon
                </td>
                <td data-label="Notes">
                  Used in jewellery, About 90% of all the zirconium that is
                  produced each year is used in nuclear reactors. It has a very
                  high melting point
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Niobium</td>
                <td data-label="Number">41</td>
                <td data-label="Name Origin">
                  From Niobe in Greek mythology, who was the daughter of king
                  Tantalus
                </td>
                <td data-label="Notes">
                  Used in rockets and jet engines, in advanced cars and in the
                  world's tallest buildings
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Molybdenum</td>
                <td data-label="Number">42</td>
                <td data-label="Name Origin">
                  From the Greek molybdos, “lead”, as it was confused with lead
                  ores
                </td>
                <td data-label="Notes">
                  Used in structural and stainless steel
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Technetium</td>
                <td data-label="Number">43</td>
                <td data-label="Name Origin">
                  From the Greek word for artificial
                </td>
                <td data-label="Notes">
                  Technetium was the very first man-made element
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Ruthenium</td>
                <td data-label="Number">44</td>
                <td data-label="Name Origin">
                  From Ruthenia, the Latin word for Russia
                </td>
                <td data-label="Notes">
                  Used in the electronics industry for chip resistors and
                  electrical contacts
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Rhodium</td>
                <td data-label="Number">45</td>
                <td data-label="Name Origin">
                  From the Greek 'rhodon', meaning rose coloured
                </td>
                <td data-label="Notes">
                  The major use of rhodium is in catalytic converters for cars
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Palladium</td>
                <td data-label="Number">46</td>
                <td data-label="Name Origin">
                  Named after the asteroid Pallas, which was named after the
                  Greek goddess of wisdom, Pallas
                </td>
                <td data-label="Notes">
                  Its largest use is in catalytic converters. Palladium is also
                  used in jewellery, dentistry
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Silver</td>
                <td data-label="Number">47</td>
                <td data-label="Name Origin">
                  From the Latin argentum, meaning 'shiny' or 'white'
                </td>
                <td data-label="Notes">
                  Silver is the most reflective metal. Used in jewellery and for
                  electrical conductivity. Argentina is known as 'The land of
                  silver'
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Cadmium</td>
                <td data-label="Number">48</td>
                <td data-label="Name Origin">
                  From the Latin 'cadmia', the name for the mineral calamine
                </td>
                <td data-label="Notes">Used in batteries and solar cells</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Indium</td>
                <td data-label="Number">49</td>
                <td data-label="Name Origin">
                  From the Latin 'indicium', meaning violet or indigo
                </td>
                <td data-label="Notes">
                  Used to make indium tin oxide (ITO), which is an important
                  part of touch screens, flatscreen TVs and solar panels
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Tin</td>
                <td data-label="Number">50</td>
                <td data-label="Name Origin">Anglo-Saxon</td>
                <td data-label="Notes">
                  It has a high polish look and is used to coat other metals to
                  prevent corrosion
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Antimony</td>
                <td data-label="Number">51</td>
                <td data-label="Name Origin">
                  From the Greek 'anti - monos', meaning not alone
                </td>
                <td data-label="Notes">
                  Mostly used mixed with other elements. A lead-antimony alloy
                  is used in batteries. Also used in flame retardant materials
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Tellurium</td>
                <td data-label="Number">52</td>
                <td data-label="Name Origin">Latin 'tellus', meaning Earth</td>
                <td data-label="Notes">
                  Used to vulcanise rubber, tint glass and ceramics, in solar
                  cells, in rewritable CDs and DVDs and as a catalyst in oil
                  refining. It is one of the rarest elements on Earth, but
                  plentiful in space
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Iodine</td>
                <td data-label="Number">53</td>
                <td data-label="Name Origin">
                  From the Greek 'iodes' meaning violet
                </td>
                <td data-label="Notes">
                  Used in antiseptics and food dyes. Iodine does not melt, it
                  goes straight to vapour, this is called sublimation. Most of
                  our body's iodine intake comes from milk. Radioactive iodine
                  can treat cancer
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Xenon</td>
                <td data-label="Number">54</td>
                <td data-label="Name Origin">
                  From the Greek 'xenos' meaning stranger
                </td>
                <td data-label="Notes">
                  Used in bactericidal lamps (because it produces ultraviolet
                  light). It produces a beautiful blue glow when excited by an
                  electrical discharge
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Caesium</td>
                <td data-label="Number">55</td>
                <td data-label="Name Origin">
                  From the Latin 'caesius', meaning sky blue, derived from its
                  flame colour
                </td>
                <td data-label="Notes">
                  Caesium is one of five elements that are a liquid at room
                  temperature. It is used in, highly accurate, atomic clocks
                </td>
                <td data-label="Flame Test">blue/violet</td>
              </tr>
              <tr>
                <td data-label="Name">Barium</td>
                <td data-label="Number">56</td>
                <td data-label="Name Origin">
                  From the Greek barys, meaning 'heavy'
                </td>
                <td data-label="Notes">
                  Barium sulphate is used with ct or x-ray in some medical
                  examinations. Barium chloride or barium nitrate, give
                  fireworks a green colour
                </td>
                <td data-label="Flame Test">pale green</td>
              </tr>
              <tr>
                <td data-label="Name">Lanthanum</td>
                <td data-label="Number">57</td>
                <td data-label="Name Origin">
                  From the Greek 'lanthanein', meaning to lie hidden
                </td>
                <td data-label="Notes">
                  Used in the film and television industry for studio lighting
                  and projection
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Cerium</td>
                <td data-label="Number">58</td>
                <td data-label="Name Origin">
                  Names after the asteroid, Ceres
                </td>
                <td data-label="Notes">
                  Used as a catalyst in self-cleaning ovens. Cerium is a
                  component of mischmetal, which is used in the manufacture of
                  alloys for cigarette lighters
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Praseodymium</td>
                <td data-label="Number">59</td>
                <td data-label="Name Origin">
                  From the Greek 'prasinos', meaning 'green', and 'didymos',
                  meaning 'twin'
                </td>
                <td data-label="Notes">
                  Mixed with magnesium to create high-strength metals used in
                  aircraft engines
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Neodymium</td>
                <td data-label="Number">60</td>
                <td data-label="Name Origin">
                  From the Greek word 'neos', meaning new and 'didymos',meaning
                  twin
                </td>
                <td data-label="Notes">
                  Neodymium glass is used to make lasers
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Promethium</td>
                <td data-label="Number">61</td>
                <td data-label="Name Origin">
                  Named after Prometheus from Greek mythology, who stole fire
                  from the Gods and gave it to humans
                </td>
                <td data-label="Notes">
                  Most promethium is used for research purposes
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Samarium</td>
                <td data-label="Number">62</td>
                <td data-label="Name Origin">
                  Named after the mineral samarskite, which was named after a
                  Russian mine official, Col. Samarski
                </td>
                <td data-label="Notes">
                  Used to dope calcium chloride crystals for use in optical
                  lasers
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Europium</td>
                <td data-label="Number">63</td>
                <td data-label="Name Origin">Named after Europe</td>
                <td data-label="Notes">
                  Excellent at absorbing neutrons, making it useful in control
                  rods for nuclear reactors
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Gadolinium</td>
                <td data-label="Number">64</td>
                <td data-label="Name Origin">
                  Names after Johan Gadolin, a Finnish scientist
                </td>
                <td data-label="Notes">
                  Used as a magnetic resonance imaging (MRI) contrast agent
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Terbium</td>
                <td data-label="Number">65</td>
                <td data-label="Name Origin">
                  1 of 4 elements named after Ytterby, Sweden
                </td>
                <td data-label="Notes">
                  Works as a crystal stabiliser of fuel cells
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Dysprosium</td>
                <td data-label="Number">66</td>
                <td data-label="Name Origin">
                  From dysprositos, which means 'difficult to get' in Greek
                </td>
                <td data-label="Notes">
                  Its main use is in alloys for neodymium-based magnets
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Holmium</td>
                <td data-label="Number">67</td>
                <td data-label="Name Origin">
                  Derived from the Latin name for Stockholm 'Holmia'
                </td>
                <td data-label="Notes">
                  Used in alloys for the production of magnets and as a flux
                  concentrator for high magnetic fields
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Erbium</td>
                <td data-label="Number">68</td>
                <td data-label="Name Origin">
                  1 of 4 elements named after Ytterby, Sweden
                </td>
                <td data-label="Notes">
                  Used as a pink colouring agent in glazes and glasses
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Thulium</td>
                <td data-label="Number">69</td>
                <td data-label="Name Origin">
                  From Thule, the ancient name for Scandinavia
                </td>
                <td data-label="Notes">
                  So soft, it can be cut with a butter knife, it is malleable
                  and ductile. Thulium has a silvery appearance
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Ytterbium</td>
                <td data-label="Number">70</td>
                <td data-label="Name Origin">
                  1 of 4 elements named after Ytterby, Sweden
                </td>
                <td data-label="Notes">
                  A soft, malleable and ductile element that with a bright
                  silvery lustre. Easily attacked and dissolved by mineral
                  acids, slowly reacts with water, and oxidises in air
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Lutetium</td>
                <td data-label="Number">71</td>
                <td data-label="Name Origin">
                  From Lutetia, the ancient Roman name for Paris, to honour its
                  discoverer Urbain's native city
                </td>
                <td data-label="Notes">
                  A metal of the lanthanide series of the periodic table, it is
                  the densest and the highest-melting rare-earth element
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Hafnium</td>
                <td data-label="Number">72</td>
                <td data-label="Name Origin">
                  From hafnium, the old Latin name for Copenhagen, the city in
                  which it was first isolated in 1922
                </td>
                <td data-label="Notes">It chemically resembles zirconium.</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Tantalum</td>
                <td data-label="Number">73</td>
                <td data-label="Name Origin">
                  Named after the villainous mythological character Tantalus
                  because of the tantalising problem of dissolving the oxide in
                  acids
                </td>
                <td data-label="Notes">
                  Tantalum is a rare, hard, blue-grey, lustrous transition metal
                  it is highly corrosion-resistant
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Tungsten</td>
                <td data-label="Number">74</td>
                <td data-label="Name Origin">
                  From the old Swedish name for 'heavy stone'
                </td>
                <td data-label="Notes">
                  It was identified as a new element in 1781 and first isolated
                  as a metal in 1783
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Rhenium</td>
                <td data-label="Number">75</td>
                <td data-label="Name Origin">
                  Named after the Latin name Rhenus for the river Rhine close to
                  the place where it was discovered
                </td>
                <td data-label="Notes">
                  The last of the natural elements to be discovered, around 50
                  years after the introduction of the periodic table
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Osmium</td>
                <td data-label="Number">76</td>
                <td data-label="Name Origin">
                  From the Greek word 'osme', meaning smell
                </td>
                <td data-label="Notes">
                  Shiny and tough. The densest of the naturally occurring
                  elements. It has a very high melting point 3033oC
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Iridium</td>
                <td data-label="Number">77</td>
                <td data-label="Name Origin">
                  From the Greek word iris ('rainbow'), referring to the various
                  colours of its compounds
                </td>
                <td data-label="Notes">
                  The second-densest naturally occurring metal (after osmium)
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Platinum</td>
                <td data-label="Number">78</td>
                <td data-label="Name Origin">
                  From the Spanish 'platina', meaning little silver
                </td>
                <td data-label="Notes">
                  A very heavy, precious, silver-white metal, platinum is soft
                  and ductile, has a high melting point and good resistance to
                  corrosion and chemical attack
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Gold</td>
                <td data-label="Number">79</td>
                <td data-label="Name Origin">
                  Anglo-Saxon. The symbol comes from the Latin 'aurum'
                </td>
                <td data-label="Notes">
                  Easy to work with, used for jewellery and coins
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Mercury</td>
                <td data-label="Number">80</td>
                <td data-label="Name Origin">
                  Named after the Roman messenger god Mercury
                </td>
                <td data-label="Notes">
                  One of 5 elements that is liquid at room temperature. It is
                  poisonous. Used in old thermometers. Also used to be called
                  quicksilver
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Thallium</td>
                <td data-label="Number">81</td>
                <td data-label="Name Origin">
                  From the Greek 'thallos', meaning a green twig
                </td>
                <td data-label="Notes">Not found free in nature</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Lead</td>
                <td data-label="Number">82</td>
                <td data-label="Name Origin">Anglo-Saxon</td>
                <td data-label="Notes">
                  It is a heavy metal that is denser than most common materials.
                  Soft and malleable, and has a relatively low melting point.
                  When cut, it is silvery with a hint of blue, then tarnishes to
                  a dull grey colour when exposed to air
                </td>
                <td data-label="Flame Test">grey-white</td>
              </tr>
              <tr>
                <td data-label="Name">Bismuth</td>
                <td data-label="Number">83</td>
                <td data-label="Name Origin">
                  Dates from around the 1660s and is of uncertain etymology
                </td>
                <td data-label="Notes">
                  Brittle, used in cosmetics to create a shiny glow. Its pure
                  form reacts with air to create rainbow coloured crystals.When
                  it was discovered it was confused with tin and lead
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Polonium</td>
                <td data-label="Number">84</td>
                <td data-label="Name Origin">
                  Named after Poland, the native country of Marie Curie, who
                  first isolated the element
                </td>
                <td data-label="Notes">
                  Radioactive. It is extremely dangerous to humans
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Astatine</td>
                <td data-label="Number">85</td>
                <td data-label="Name Origin">
                  From the Greek word astatos, meaning 'unstable'
                </td>
                <td data-label="Notes">
                  It is the rarest naturally occurring element in the Earth's
                  crust
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Radon</td>
                <td data-label="Number">86</td>
                <td data-label="Name Origin">
                  Originally named niton after the Latin word for shining
                  'nitens', it has been known as radon since 1923
                </td>
                <td data-label="Notes">
                  It is a radioactive, colourless, odourless, tasteless noble
                  gas. Can be useful in cancer therapy
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Francium</td>
                <td data-label="Number">87</td>
                <td data-label="Name Origin">Named after France</td>
                <td data-label="Notes">
                  Used in diagnostics for treating cancers. The heaviest
                  chemical element of Group 1 in the periodic table, the alkali
                  metal group
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Radium</td>
                <td data-label="Number">88</td>
                <td data-label="Name Origin">
                  Named after the Latin for a ray
                </td>
                <td data-label="Notes">
                  The most radioactive natural substance known. Discovered by
                  Marie and Pierre Curie in 1898 from ore mined at Jáchymov
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Actinium</td>
                <td data-label="Number">89</td>
                <td data-label="Name Origin">
                  From the Greek 'actinos', meaning a ray
                </td>
                <td data-label="Notes">
                  Glows in the dark with a blue light due to its intense
                  radioactivity
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Thorium</td>
                <td data-label="Number">90</td>
                <td data-label="Name Origin">
                  Named after Thor, the Scandinavian god of war
                </td>
                <td data-label="Notes">
                  Silvery appearance that tarnishes black when exposed to air
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Protactinium</td>
                <td data-label="Number">91</td>
                <td data-label="Name Origin">
                  From the Greek 'protos', meaning first, as a prefix to the
                  element actinium, which is produced through the radioactive
                  decay of protactinium
                </td>
                <td data-label="Notes">
                  Predicted by Russian chemist Dmitry Mendeleyev in his 1871
                  periodic table
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Uranium</td>
                <td data-label="Number">92</td>
                <td data-label="Name Origin">
                  From 'uran' after the planet Uranus
                </td>
                <td data-label="Notes">
                  It has the highest atomic weight of all naturally occurring
                  elements. Used as fuel for nuclear power plants, the nuclear
                  reactors that run naval ships and submarines and for nuclear
                  weapons
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Neptunium</td>
                <td data-label="Number">93</td>
                <td data-label="Name Origin">Named after the planet Neptune</td>
                <td data-label="Notes">
                  Its position in the periodic table just after uranium, named
                  after the planet Uranus, led to it being named after Neptune
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Plutonium</td>
                <td data-label="Number">94</td>
                <td data-label="Name Origin">
                  Named after the then planet Pluto, following on from the two
                  previous elements uranium and neptunium
                </td>
                <td data-label="Notes">
                  Plutonium-239 is used to make nuclear weapons
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Americium</td>
                <td data-label="Number">95</td>
                <td data-label="Name Origin">Named after America</td>
                <td data-label="Notes">
                  A man-made radioactive metal that is solid under normal
                  conditions
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Curium</td>
                <td data-label="Number">96</td>
                <td data-label="Name Origin">
                  Named after Marie and Pierre Curie
                </td>
                <td data-label="Notes">
                  It is so intensely radioactive that it boils water, making its
                  chemistry difficult to study. It also glows in the dark
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Berkelium</td>
                <td data-label="Number">97</td>
                <td data-label="Name Origin">
                  Named after Berkeley in California
                </td>
                <td data-label="Notes">
                  Berkelium is artificially produced, and was discovered in 1949
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Californium</td>
                <td data-label="Number">98</td>
                <td data-label="Name Origin">
                  Californium is named after the university and state of
                  California
                </td>
                <td data-label="Notes">
                  Used in portable metal detectors, for identifying gold and
                  silver ores, only a half-gram of Californium is produced each
                  year
                </td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Einsteinium</td>
                <td data-label="Number">99</td>
                <td data-label="Name Origin">Named after Albert Einstein</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Fermium</td>
                <td data-label="Number">100</td>
                <td data-label="Name Origin">Named after Enrico Fermi</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Mendelevium</td>
                <td data-label="Number">101</td>
                <td data-label="Name Origin">Named after Dmitri Mendeleev</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Nobelium</td>
                <td data-label="Number">102</td>
                <td data-label="Name Origin">Named after Alfred Nobel</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Lawrencium</td>
                <td data-label="Number">103</td>
                <td data-label="Name Origin">Named after Ernest Lawrence</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Rutherfordium</td>
                <td data-label="Number">104</td>
                <td data-label="Name Origin">Named after Ernest Rutherford</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Dubnium</td>
                <td data-label="Number">105</td>
                <td data-label="Name Origin">
                  Named after the Russian town Dubna
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Seaborgium</td>
                <td data-label="Number">106</td>
                <td data-label="Name Origin">Named after Glenn T. Seaborg</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Bohrium</td>
                <td data-label="Number">107</td>
                <td data-label="Name Origin">Named after Niels Bohr</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Hassium</td>
                <td data-label="Number">108</td>
                <td data-label="Name Origin">
                  From the German state of Hesse where Hassium was first made
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Meitnerium</td>
                <td data-label="Number">109</td>
                <td data-label="Name Origin">
                  Named after physicist Lise Meitner
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Darmstadtium</td>
                <td data-label="Number">110</td>
                <td data-label="Name Origin">
                  Named after Darmstadt in Germany
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Roentgenium</td>
                <td data-label="Number">111</td>
                <td data-label="Name Origin">
                  Named after the discoverer of x-rays, Wilhelm Conrad Röntgen
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Copernicium</td>
                <td data-label="Number">112</td>
                <td data-label="Name Origin">
                  Named after Nicolaus Copernicus
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Nihonium</td>
                <td data-label="Number">113</td>
                <td data-label="Name Origin">
                  Named after the Japanese word for Japan
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Flerovium</td>
                <td data-label="Number">114</td>
                <td data-label="Name Origin">
                  Named after the Flerov Laboratory of Nuclear Reactions of
                  Joint Institute for Nuclear Research, in Dubna, Russia
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Moscovium</td>
                <td data-label="Number">115</td>
                <td data-label="Name Origin">
                  Named after the Moscow oblast where the Joint Institute for
                  Nuclear Research is located
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Livermorium</td>
                <td data-label="Number">116</td>
                <td data-label="Name Origin">
                  Named after the Lawrence Livermore National Laboratory in
                  California
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Tennessine</td>
                <td data-label="Number">117</td>
                <td data-label="Name Origin">
                  Named after the US state of Tennessee
                </td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
              <tr>
                <td data-label="Name">Oganesson</td>
                <td data-label="Number">118</td>
                <td data-label="Name Origin">Named after Yuri Oganessian</td>
                <td data-label="Notes">Currently no uses outside research</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <LearnSpecificCategoryBottom
          importantFacts={periodicTable.importantFacts}
          classicQuestions={periodicTable.classicQuestions}
          exampleQuestions={periodicTable.exampleQuestions}
          thoughtsTipsIdeas={periodicTable.thoughtsTipsAndIdeas}
          // smallPeep1={periodicTable.smallPeep1}
          smallPeep2={periodicTable.smallPeep2}
          disclaimer={periodicTable.disclaimer}
        />
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnPeriodicTable;
