import L from "leaflet";
import iconUrl from "./../images/marker-icon.png";
import iconShadow from "./../images/marker-shadow.png";

const { iconSize, shadowSize, iconAnchor, popupAnchor, tooltipAnchor } =
  L.Marker.prototype.options.icon.options;

const defaultIcon = L.icon({
  iconUrl,
  iconShadow,
  iconSize,
  shadowSize,
  iconAnchor,
  popupAnchor,
  tooltipAnchor,
});

const housingIcon = L.icon({
  iconUrl: "https://img.icons8.com/plasticine/100/exterior.png",
  iconSize: [40, 40], // size of the icon
  iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});
const redIcon = L.icon({
  iconUrl: "https://img.icons8.com/material/FF0000/marker.png",
  iconSize: [40, 40], // size of the icon
  iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});
const greyIcon = L.icon({
  iconUrl: "https://img.icons8.com/material/808080/marker.png",
  iconSize: [40, 40], // size of the icon
  iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});
const greenIcon = L.icon({
  iconUrl: "https://img.icons8.com/material/00FF00/marker.png",
  iconSize: [40, 40], // size of the icon
  iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
  popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
});

export { defaultIcon, housingIcon, redIcon, greyIcon, greenIcon };
