import "./css/us-states.css";

function AfricanCountry(props) {
  return (
    <div className="us-state-card">
      <p className="africa-card-name">{props.countryName}</p>
      <div className="state-flag-img">{props.countryFlag}</div>
      <p>
        <span className="us-state-card-heading">capital:</span>{" "}
        {props.capitalCity}
      </p>
    </div>
  );
}

export default AfricanCountry;
