const latinWords = {
  mainCategory: "Latin Words & Phrases",
  bigPeep: require("./images/peep-big10.png"),
  intro:
    "As well as being asked regularly as questions by themselves, knowing common Latin names and phrases can help you figure out many other questions that you previously wouldn't have a clue about. A little Latin knowledge can go a long way.",

  // smallPeep1: require("./images/peep-small4.png"),
  smallPeep2: require("./images/peep-small20.png"),
  importantFacts: [
    "There are no longer any native Latin speakers but Latin is spoken in some religious settings, e.g. The Vatican",
    "Latin is often used for naming in taxonomy, medicine and science",
    "In Roman numerals I = 1, V = 5, X = 10, L = 50, C = 100, D = 500 and M = 1000",
  ],
  exampleQuestions: [
    {
      id: "Latin-eQ1",
      question: "What type of animal lives in a formiciary?",
      answer: "Ant",
    },
    {
      id: "Latin-eQ2",
      question:
        "What latin phrase is famously spoken by Hannibal Lecter to Clarice Starling?",
      answer: "Quid pro Quo",
    },
  ],
  classicQuestions: [
    {
      id: "Latin-cQ1",
      question: "What latin phrase means 'seize the day'?",
      answer: "Carpe diem",
    },
    {
      id: "Latin-cQ2",
      question:
        "'Et tu, Brute?' is a Latin phrase spoken by the titular character in which Shakepeare play?",
      answer: "Julius Caesar",
    },
    {
      id: "Latin-cQ3",
      question: "How would write the year 2022 using Roman numerals?",
      answer: "MMXXII",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "Latin-TTAI1",
      text: "The Latin words and phrases can help if you need to translate words from 'Romance languages' the main 5 are: French, Italian, Portuguese, Romanian and Spanish, which developed from vulgar Latin.",
    },
  ],
};
const entertainmentAwards = {
  mainCategory: "American Entertainment Awards",
  bigPeep: require("./images/peep-big8.png"),
  intro:
    "There are 4 major annual American entertainment awards. They are The Emmys for the TV industry, The Grammies for the Music industry, The Oscars (Academy Awards) fot the film industry, and The Tonys for live Broadway theatre. Someone who wins all 4 of these awards is said to have won an EGOT, one person in history (as of 2022) achieved a Double EGOT is Robert Lopez, meaning he has won each award at least twice!",

  // smallPeep1: require("./images/peep-small4.png"),
  smallPeep2: require("./images/peep-small20.png"),
  importantFacts: [
    "The Oscars began in 1929 and is the oldest of the four awards discussed on this page, the award is a golden coloured art deco statuette of a knight",
    "The Tonys began in 1947, they are the US equivalent of the Laurence Olivier Awards in the UK. The trophy is a spinnable medallion, with the comedy and tragedy masks on as decoration, that sits on a black base with a pewter swivel",
    "The Emmys began in 1949, the most sought after award categories are the Daytime Emmy Awards and Primetime Emmy Awards. The trophy is a statuette of a winged woman holding an atom",
    "The Grammys began in 1959, the trophies are gilded gramophones",
  ],
  exampleQuestions: [
    {
      id: "EntAwards-eQ1",
      question: "In what year did the Oscars (Academy Awards) begin?",
      answer: "1929",
    },
    {
      id: "EntAwards-eQ2",
      question:
        "As of 2022, who is the only person ever to have won a double EGOT?",
      answer: "Robert Lopez",
    },
  ],
  classicQuestions: [
    {
      id: "EntAwards-cQ1",
      question:
        "The Oscars are Awarded for excellence in which entertainment industry?",
      answer: "Film",
    },
    {
      id: "EntAwards-cQ2",
      question:
        "The Grammys are Awarded for excellence in which entertainment industry?",
      answer: "Music",
    },
    {
      id: "EntAwards-cQ3",
      question:
        "Those who have won all four major entertainment awards are said to be the winner of what achievement award?",
      answer: "EGOT",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "EntAwards-TTAI1",
      text: "It would be difficult to learn all the awards over the entire time that these awards have taken place, you might be best to look at the top 5s on this page for recent winners, also learn which part of the entertainment industry is covered by which award.",
    },
    {
      id: "EntAwards-TTAI2",
      text: "If you are interested in watching the films which were awarded the Oscar for Best Picture over the years you can find them listed by year by clicking the 'Best Films By Decade' button below. You can also access a copy of our spreadsheet, that lists high rated films over the years that could come in useful for quizzing via the 'Film Spreadsheet' button below. Press the button and then go to 'file', 'make a copy' and then you can tick them off the list as you watch them!",
    },
  ],
  button1: "Best Films By Decade",
  button2: "Film Spreadsheet",
};

const britishEntertainmentAwards = {
  mainCategory: "British Entertainment Awards",
  bigPeep: require("./images/peep-big5.png"),
  intro:
    "The most prestigious entertainment awards in Britain are The BAFTAs which are split into two seperate ceremonies for Film and Television, The Brits and The Mercury Prize for music and The Olivier Awards for theatre. There are obviously many more awards than those that will be discussed on this page, however these are the most well known awards.",
  smallPeep2: require("./images/peep-small8.png"),
  importantFacts: [
    "The Mercury Prize began in 1992 and is awarded to the best new album by a majority UK or Irish act which was released in the preceding calendar year. The winner is awarded a cheque and a trophy. The ceremony usually takes place in September. As of 2022, only PJ Harvey has won the award more than once (2001 and 2011)",
    "The BAFTAs are divided in to Film and Television ceremonies. The British Academy Film Awards began in 1949, and the British Academy Television Awards began in 1955. The Trophies are a bronze mask mounted on a marble base designed by by US sculptor Mitzi Cunliffe in 1955",
    "The BRIT Awards began in 1977, the trophy is a statuette of Britannia, the female personification of Britain",
    "The Olivier Awards began in 1976, the award is a bust of Sir Laurence Olivier dressed as Henry V in his Old Vic performance in 1937, they are the British equivalent of the US's Tony Awards",
  ],
  exampleQuestions: [
    {
      id: "britEntAwards-eQ1",
      question: "In what year was the first Mercury Prize awarded?",
      answer: "1992",
    },
    {
      id: "britEntAwards-eQ2",
      question:
        "As of 2022, who is the only person ever to have won the Mercury Prize more than once?",
      answer: "PJ Harvey",
    },
    {
      id: "britEntAwards-eQ3",
      question:
        "Who won the BAFTA Film Award for leading actor in 2019 (for Bohemian Rhapsody)?",
      answer: "Rami Malek",
    },
    {
      id: "britEntAwards-eQ4",
      question:
        "Who has won the BAFTA TV Award for Leading Actress twice in the last 5 years?",
      answer: "Jodie Comer",
    },
  ],
  classicQuestions: [
    {
      id: "britEntAwards-cQ1",
      question: "What is the US equivalent of the Olivier Awards?",
      answer: "The Tony Awards",
    },
    {
      id: "britEntAwards-cQ2",
      question: "Who famously wore a Union Jack dress at the BRIT Awards?",
      answer: "Geri Halliwell",
    },
    {
      id: "britEntAwards-cQ3",
      question:
        "Who ran on stage at the BRIT Awards and 'mooned' during Michael Jackson's performance of Earth Song?",
      answer: "Jarvis Cocker",
    },
  ],
  thoughtsTipsAndIdeas: [
    {
      id: "britEntAwards-TTAI1",
      text: "The 'last 5s' sections will give you a good boost to your knowledge about recent awards ceremonies.",
    },
  ],
};

const alterEgos = {
  originalBandNames: [
    {
      id: "ae-obn1",
      original: "The Pendletones",
      new: "The Beach Boys",
    },
    {
      id: "ae-obn2",
      original: "The Quarrymen",
      new: "The Beatles",
    },
    {
      id: "ae-obn3",
      original: "Seymour",
      new: "Blur",
    },
    {
      id: "ae-obn4",
      original: "Girl's Tyme",
      new: "Destiny's Child",
    },
    {
      id: "ae-obn5",
      original: "The New Yardbirds",
      new: "Led Zeppelin",
    },
    {
      id: "ae-obn6",
      original: "The Rain",
      new: "Oasis",
    },
    {
      id: "ae-obn7",
      original: "Tom & Jerry",
      new: "Simon & Garfunkel",
    },
    {
      id: "ae-obn8",
      original: "Touch",
      new: "The Spice Girls",
    },
  ],
  musicalArtists: [
    { id: "ae-ma1", original: "Belcalis Almanzar", new: "Cardi B" },
    { id: "ae-ma2", original: "Curtis Jackson III", new: "50 Cent" },
    { id: "ae-ma3", original: "Rita Maria Crudgington", new: "Cheryl Baker" },
    { id: "ae-ma4", original: "Mark Berry", new: "Bez" },
    { id: "ae-ma5", original: "Priscilla White", new: "Cilla Black" },
    {
      id: "ae-ma6",
      original: "Jiles Perry Richardson Jr",
      new: "The Big Bopper",
    },
    { id: "ae-ma7", original: "Damon Gough", new: "Badly Drawn Boy" },
    {
      id: "ae-ma8",
      original: "Aubrey Graham",
      new: "Drake",
    },
    { id: "ae-ma9", original: "Robert Zimmerman", new: "Bob Dylan" },
    { id: "ae-ma10", original: "Stefani Germanotta", new: "Lady Gaga" },
    { id: "ae-ma11", original: "Adam Richard Wiles", new: "Calvin Harris" },
    { id: "ae-ma12", original: "Ernest Evans", new: "Chubby Checker" },
    { id: "ae-ma13", original: "James Chambers", new: "Jimmy Cliff" },
    { id: "ae-ma14", original: "Waldon Robert Cassotto", new: "Bobby Darin" },
    {
      id: "ae-ma15",
      original: "Pauline Matthews",
      new: "Kiki Dee",
    },
    { id: "ae-ma16", original: "Calvin Broadus Jr", new: "Snoop Dogg" },
    { id: "ae-ma17", original: "David Evans", new: "The Edge" },
    { id: "ae-ma18", original: "Ellen Naomi Cohen", new: "Cass Elliot" },
    { id: "ae-ma19", original: "Terence Nelhams Wright", new: "Adam Faith" },
    { id: "ae-ma20", original: "George O'Dowd", new: "Boy George" },
    { id: "ae-ma21", original: "Paul Gadd", new: "Gary Glitter" },
    { id: "ae-ma22", original: "Thomas DeCarlo Callaway", new: "Cee-Lo Green" },
    { id: "ae-ma23", original: "Stanley Burrell", new: "MC Hammer" },
    { id: "ae-ma24", original: "Eleanora Fagan", new: "Billie Holiday" },
    { id: "ae-ma25", original: "Robert Van Winkle", new: "Vanilla Ice" },
    { id: "ae-ma26", original: "William Broad", new: "Billy Idol" },

    {
      id: "ae-ma27",
      original: "Shawn Carter",
      new: "Jay-Z",
    },
    { id: "ae-ma28", original: "Jessica Cornish", new: "Jessie J" },
    {
      id: "ae-ma29",
      original: "Reginald Dwight",
      new: "Sir Elton (Hercules) John",
    },
    { id: "ae-ma30", original: "Bryan Warner", new: "Marilyn Manson" },
    {
      id: "ae-ma31",
      original: "Peter Gene Hernandez",
      new: "Bruno Mars",
    },
    {
      id: "ae-ma32",
      original: "Marvin/Michael Lee Aday",
      new: "Meatloaf",
    },
    { id: "ae-ma33", original: "Farrokh Bulsara", new: "Freddie Mercury" },
    { id: "ae-ma34", original: "Roberta Joan Anderson", new: "Joni Mitchell" },
    { id: "ae-ma35", original: "Richard Melville Hall", new: "Moby" },
    { id: "ae-ma36", original: "Terence Parsons", new: "Matt Monro" },
    { id: "ae-ma37", original: "Leslie Charles", new: "Billy Ocean" },
    { id: "ae-ma38", original: "Harry Webb", new: "Cliff Richard" },
    { id: "ae-ma39", original: "Alecia Moore", new: "Pink" },
    { id: "ae-ma40", original: "Armando Perez", new: "Pitbull" },
    { id: "ae-ma41", original: "Benjamin Ballance-Drew", new: "Plan B" },
    { id: "ae-ma42", original: "James Osterberg Jr", new: "Iggy Pop" },
    { id: "ae-ma43", original: "Jeffrey Hyman", new: "Joey Ramone" },
    { id: "ae-ma44", original: "Dylan Mills", new: "Dizzee Rascal" },
    { id: "ae-ma45", original: "Elizabeth Grant", new: "Lana Del Rey" },
    { id: "ae-ma46", original: "Tramar Dillard", new: "Flo Rida" },
    { id: "ae-ma47", original: "Robyn Fenty", new: "Rhianna" },
    { id: "ae-ma48", original: "Robert Ritchie", new: "Kid Rock" },
    { id: "ae-ma49", original: "Eunice Waymon", new: "Nina Simone" },
    { id: "ae-ma50", original: "Saul Hudson", new: "Slash" },
    { id: "ae-ma51", original: "Norman Cook", new: "Fatboy Slim" },
    { id: "ae-ma52", original: "Mary O'Brien", new: "Dusty Springfield" },
    { id: "ae-ma53", original: "Charles Edwin Hatcher", new: "Edwin Starr" },
    { id: "ae-ma54", original: "Richard Starkey", new: "Ringo Starr" },
    { id: "ae-ma55", original: "Gaynor Hopkins", new: "Bonnie Tyler" },
    { id: "ae-ma56", original: "Francesco Castelluccio", new: "Frankie Valli" },
    { id: "ae-ma57", original: "Melanie Bownds", new: "Rebel Wilson" },
    { id: "ae-ma58", original: "Virginia Pugh", new: "Tammy Wynette" },
  ],
  actorsActresses: [
    { id: "ae-aa1", original: "Allan Konigsberg", new: "Woody Allen" },
    { id: "ae-aa2", original: "Betty Joan Perske", new: "Lauren Bacall" },
    { id: "ae-aa3", original: "Maurice Micklewhite", new: "Michael Caine" },
    {
      id: "ae-aa4",
      original: "Anthony Robert McMillan",
      new: "Robbie Coltrane",
    },
    {
      id: "ae-aa5",
      original: "Issur Demsky/ Danielovitch",
      new: "Kirk Douglas",
    },
    { id: "ae-aa6", original: "Eric Marlon Bishop", new: "Jamie Foxx" },
    { id: "ae-aa7", original: "Frances Gumm", new: "Judy Garland" },
    { id: "ae-aa8", original: "Caryn Johnson", new: "Whoopi Goldberg" },
    { id: "ae-aa9", original: "Archibald Leach", new: "Cary Grant" },
    { id: "ae-aa10", original: "Solomon Cohen", new: "Sid James" },
    { id: "ae-aa11", original: "William Pratt", new: "Boris Karloff" },
    { id: "ae-aa12", original: "Krishna Banji", new: "Ben Kingsley" },
    { id: "ae-aa13", original: "Philip John Clapp", new: "Johnny Knoxville" },
    { id: "ae-aa14", original: "Dana Owens", new: "Queen Latifah" },
    { id: "ae-aa15", original: "Vera Jane Palmer", new: "Jayne Mansfield" },
    { id: "ae-aa16", original: "Norma Jeane Mortenson", new: "Marilyn Monroe" },
    { id: "ae-aa17", original: "Neta-Lee Herschlag", new: "Natalie Portman" },
    { id: "ae-aa18", original: "Joseph Yule Jr", new: "Mickey Rooney" },
    { id: "ae-aa19", original: "Carlos Estevez", new: "Charlie Sheen" },
    { id: "ae-aa20", original: "Mark Vincent", new: "Vin Diesel" },
    { id: "ae-aa21", original: "Marion Morrison", new: "John Wayne" },
    { id: "ae-aa22", original: "Jerome Silberman", new: "Gene Wilder" },
  ],
  authors: [
    { id: "ae-a1", original: "David Cornwell", new: "John Le Carre" },
    { id: "ae-a2", original: "Charles Lutwidge Dodgson", new: "Lewis Carroll" },
    { id: "ae-a3", original: "Mary Ann Evans", new: "George Eliot" },
    { id: "ae-a4", original: "Eric Arthur Blair", new: "George Orwell" },
    { id: "ae-a5", original: "Robert Galbraith", new: "J.K. Rowling" },
    { id: "ae-a6", original: "Samuel Langhorne Clemens", new: "Mark Twain" },
  ],
  superheroesVillains: [
    { id: "ae-shv1", original: "Barbara Gordon", new: "Batgirl" },
    { id: "ae-shv2", original: "Bruce Wayne", new: "Batman" },
    { id: "ae-shv3", original: "King T'Challa", new: "Black Panther" },
    { id: "ae-shv4", original: "Steven Grant Rogers", new: "Captain America" },
    { id: "ae-shv5", original: "Carol Danvers", new: "Captain Marvel" },
    { id: "ae-shv6", original: "Selina Kyle", new: "Catwoman" },
    { id: "ae-shv7", original: "Scott Summers", new: "Cyclops" },
    { id: "ae-shv8", original: "Matt Murdock", new: "Daredevil" },
    { id: "ae-shv9", original: "Wade Winston Wilson", new: "Deadpool" },
    { id: "ae-shv10", original: "Barry Allen", new: "The Flash" },
    { id: "ae-shv11", original: "Clint Barton", new: "Hawkeye" },
    { id: "ae-shv12", original: "Dick Grayson", new: "Robin / Nightwing" },
    {
      id: "ae-shv12",
      original: "Dr Robert Bruce Banner",
      new: "The Incredible Hulk",
    },
    { id: "ae-shv13", original: "Tony Stark", new: "Iron Man" },
    { id: "ae-shv14", original: "Peter Parker", new: "Spider-man" },
    { id: "ae-shv15", original: "Clark Kent", new: "Superman" },
    {
      id: "ae-shv16",
      original: "Princess Diana of Themyscira",
      new: "Wonder Woman",
    },
  ],
  comedians: [
    { id: "ae-c1", original: "Peter Williams", new: "Jim Bowen" },
    { id: "ae-c2", original: "Robert Norman Davis", new: "Jasper Carrott" },
    { id: "ae-c3", original: "Maurice Cole", new: "Kenny Everett" },
    { id: "ae-c4", original: "Matthew Hall", new: "Harry Hill" },
    { id: "ae-c5", original: "John Eric Bartholomew", new: "Eric Morecambe" },
    { id: "ae-c6", original: "James Moir", new: "Vic Reeves" },
    { id: "ae-c7", original: "Christopher Collins", new: "Frank Skinner" },
    { id: "ae-c8", original: "Michael Pennington", new: "Johnny Vegas" },
  ],
  other: [
    {
      id: "ae-o1",
      original: "Israel Baline",
      new: "Irving Berlin (Songwriter)",
    },
    { id: "ae-o2", original: "Henry Lionel Ogus", new: "Lionel Blair" },
    { id: "ae-o3", original: "Newton Daniels", new: "Paul Daniels" },
    { id: "ae-o4", original: "Steven Frayne", new: "Dynamo" },
    { id: "ae-o5", original: "Martin Ruane", new: "Giant Haystacks" },
    { id: "ae-o6", original: "Terry Bollea", new: "Hulk Hogan" },
    { id: "ae-o7", original: "Erik Weisz", new: "Harry Houdini" },
    { id: "ae-o8", original: "Phoebe Ann Moses", new: "Annie Oakley" },
    { id: "ae-o9", original: "Walker Smith Jr", new: "Sugar Ray Robinson" },
  ],
};

const cocktails = [
  {
    id: "cocktail1",
    cocktail: "Bellini",
    ingredients: "Prosecco & Peach purée",
  },
  {
    id: "cocktail2",
    cocktail: "Between the Sheets",
    ingredients: "White Rum, Cognac, Triple sec, Lemon juice",
  },
  {
    id: "cocktail3",
    cocktail: "Black Russian",
    ingredients: "Vodka & Coffee liqueur",
  },
  {
    id: "cocktail4",
    cocktail: "Bloody Mary",
    ingredients:
      "Vodka, Tomato juice, Lemon juice, Worcestershire sauce, Tabasco, Pepper, Salt & Celery",
  },
  {
    id: "cocktail5",
    cocktail: "Blue Lagoon",
    ingredients: "Vodka, Blue curaçao & Lemonade",
  },
  {
    id: "cocktail6",
    cocktail: "Brass Monkey",
    ingredients: "Dark Rum, Vodka & Orange juice",
  },
  {
    id: "cocktail7",
    cocktail: "Caipirinha",
    ingredients: "Cachaça, Sugar & Lime",
  },
  {
    id: "cocktail8",
    cocktail: "Cosmopolitan",
    ingredients: "Vodka, Cranberry juice, Triple sec & Lime juice",
  },
  {
    id: "cocktail9",
    cocktail: "Cuba Libre",
    ingredients: "White Rum, Cola & Lime juice",
  },
  {
    id: "cocktail10",
    cocktail: "Daiquiri",
    ingredients: "Rum, Lime juice & Sugar",
  },
  {
    id: "cocktail11",
    cocktail: "Dark & Stormy",
    ingredients: "Dark Rum & Ginger beer",
  },
  {
    id: "cocktail12",
    cocktail: "Death in the Afternoon",
    ingredients: "Absinthe & Champagne",
  },
  {
    id: "cocktail13",
    cocktail: "Espresso Martini",
    ingredients: "Vodka, Espresso & Coffee liqueur",
  },
  {
    id: "cocktail14",
    cocktail: "Grasshopper",
    ingredients: "Green crème de menthe, White crème de cacao & Cream",
  },
  {
    id: "cocktail15",
    cocktail: "Harvey Wallbanger",
    ingredients: "Vodka, Galliane & Orange juice",
  },
  {
    id: "cocktail16",
    cocktail: "Long Island Iced Tea",
    ingredients: "Vodka, Tequila, Rum, Triple sec, Gin, Lemon juice & Cola",
  },
  {
    id: "cocktail17",
    cocktail: "Mai Tai",
    ingredients: "Rum, Orange curaçao, Orgeat syrup & Lime juice",
  },
  {
    id: "cocktail18",
    cocktail: "Manhattan",
    ingredients: "Whiskey, Sweet vermouth & Angostura bitters",
  },
  {
    id: "cocktail19",
    cocktail: "Margarita",
    ingredients: "Tequila, Cointreau & Lime juice",
  },
  {
    id: "cocktail20",
    cocktail: "Martini",
    ingredients: "Gin & Vermouth",
  },
  {
    id: "cocktail21",
    cocktail: "Mary Pickford",
    ingredients: "White Rum, Pineapple juice, Grenadine & Maraschino liqueur",
  },
  {
    id: "cocktail22",
    cocktail: "Mimosa",
    ingredients: "Champagne (or other sparkling wine) & Orange juice",
  },
  {
    id: "cocktail23",
    cocktail: "Mojito",
    ingredients: "White Rum, Lime juice, Sugar, Soda Water & Mint",
  },
  {
    id: "cocktail24",
    cocktail: "Monkey Gland",
    ingredients: "Gin, Orange juice, Grenadine & Absinthe",
  },
  {
    id: "cocktail25",
    cocktail: "Moscow Mule",
    ingredients: "Vodka, Spicy ginger beer & Lime juice",
  },
  {
    id: "cocktail26",
    cocktail: "Negroni",
    ingredients: "Gin, Sweet Vermouth (rosso) & Campari",
  },
  {
    id: "cocktail27",
    cocktail: "Old Fashioned",
    ingredients: "Whiskey, Sugar, Angostura bitters & Water",
  },
  {
    id: "cocktail28",
    cocktail: "Piña Colada",
    ingredients: "Rum, Cream of coconut/coconut milk & Pineapple juice",
  },
  {
    id: "cocktail29",
    cocktail: "Pornstar Martini",
    ingredients:
      "Vanilla vodka, Passion fruit liqueur (Passoã), Passion fruit puree, Lime juice & Vanilla syrup. With a shot of prosecco on the side",
  },
  {
    id: "cocktail30",
    cocktail: "Rusty Nail",
    ingredients: "Drambuie & Scotch whisky",
  },
  {
    id: "cocktail31",
    cocktail: "Sangria",
    ingredients:
      "Red wine, Brandy (optional), Water, Orange juice, Herbs, Spices & Fruit",
  },
  {
    id: "cocktail32",
    cocktail: "Screwdriver",
    ingredients: "Vodka & Orange juice",
  },
  {
    id: "cocktail33",
    cocktail: "Sex On The Beach",
    ingredients: "Vodka, Peach schnapps, Cranberry juice & Orange juice",
  },
  {
    id: "cocktail34",
    cocktail: "Sidecar",
    ingredients: "Cognac, Cointreau (or other Orange liqueur) & Lemon juice",
  },
  {
    id: "cocktail35",
    cocktail: "Singapore Sling",
    ingredients:
      "Gin, Cherry brandy/liqueur, Cointreau, Bénédictine, Grenadine, Pineapple juice, Lime juice, Angostura bitters & Soda Water",
  },
  {
    id: "cocktail36",
    cocktail: "Tequila Sunrise",
    ingredients: "Tequila, Orange juice & Grenadine syrup",
  },
  {
    id: "cocktail37",
    cocktail: "Virgin Mary",
    ingredients:
      "Tomato juice, Lemon juice, Worcestershire sauce, Tabasco, Pepper, Salt & Celery",
  },
  {
    id: "cocktail38",
    cocktail: "Whiskey Sour",
    ingredients: "Bourbon whiskey, Lemon juice, Sugar syrup & Egg white",
  },
  {
    id: "cocktail39",
    cocktail: "White Russian",
    ingredients: "Vodka, Coffee liqueur & Cream",
  },
];

const footballTeamNicknames = {
  premiership: [
    {
      id: "ftn-p1",
      realname: "Arsenal",
      nickname: "The Gunners",
    },
    {
      id: "ftn-p2",
      realname: "Aston Villa",
      nickname: "Villa / The Villans",
    },
    {
      id: "ftn-p3",
      realname: "Bournemouth",
      nickname: "The Cherries",
    },
    {
      id: "ftn-p4",
      realname: "Brentford",
      nickname: "The Bees",
    },
    {
      id: "ftn-p5",
      realname: "Brighton & Hove Albion",
      nickname: "The Seagulls",
    },
    {
      id: "ftn-p6",
      realname: "Chelsea",
      nickname: "The Blues / The Pensioners",
    },
    {
      id: "ftn-p7",
      realname: "Crystal Palace",
      nickname: "The Eagles / The Glaziers",
    },
    {
      id: "ftn-p8",
      realname: "Everton",
      nickname: "The Toffees",
    },
    {
      id: "ftn-p9",
      realname: "Fulham",
      nickname: "The Cottagers",
    },
    {
      id: "ftn-p10",
      realname: "Leeds Utd",
      nickname: "The Whites / The Peacocks",
    },
    {
      id: "ftn-p11",
      realname: "Leicester City",
      nickname: "The Foxes",
    },
    {
      id: "ftn-p12",
      realname: "Liverpool",
      nickname: "The Reds",
    },
    {
      id: "ftn-p13",
      realname: "Manchester City",
      nickname: "The Citizens / City",
    },
    {
      id: "ftn-p14",
      realname: "Manchester Utd",
      nickname: "The Red Devils",
    },
    {
      id: "ftn-p15",
      realname: "Newcastle Utd",
      nickname: "The Magpies",
    },
    {
      id: "ftn-p16",
      realname: "Nottingham Forest",
      nickname: "Forest / The Tricky Trees",
    },
    {
      id: "ftn-p17",
      realname: "Southampton",
      nickname: "The Saints",
    },
    {
      id: "ftn-p18",
      realname: "Tottenham",
      nickname: "Spurs",
    },
    {
      id: "ftn-p19",
      realname: "West Ham Utd",
      nickname: "The Hammers / The Irons",
    },
    {
      id: "ftn-p20",
      realname: "Wolverhampton Wanderers",
      nickname: "Wolves",
    },
  ],
  championship: [
    {
      id: "ftn-ch1",
      realname: "Birmingham City",
      nickname: "Blues",
    },
    {
      id: "ftn-ch2",
      realname: "Blackburn Rovers",
      nickname: "Rovers",
    },
    {
      id: "ftn-ch3",
      realname: "Blackpool",
      nickname: "The Seasiders",
    },
    {
      id: "ftn-ch4",
      realname: "Bristol City",
      nickname: "The Robins",
    },
    {
      id: "ftn-ch5",
      realname: "Burnley",
      nickname: "The Clarets",
    },
    {
      id: "ftn-ch6",
      realname: "Cardiff City",
      nickname: "The Bluebirds",
    },
    {
      id: "ftn-ch7",
      realname: "Coventry City",
      nickname: "The Sky Blues",
    },
    {
      id: "ftn-ch8",
      realname: "Huddesfield Town",
      nickname: "The Terriers",
    },
    {
      id: "ftn-ch9",
      realname: "Hull City",
      nickname: "The Tigers",
    },
    {
      id: "ftn-ch10",
      realname: "Luton Town",
      nickname: "The Hatters",
    },
    {
      id: "ftn-ch11",
      realname: "Middlesborough",
      nickname: "The Boro",
    },
    {
      id: "ftn-ch12",
      realname: "Millwall",
      nickname: "The Lions",
    },
    {
      id: "ftn-ch13",
      realname: "Norwich City",
      nickname: "The Canaries",
    },
    {
      id: "ftn-ch14",
      realname: "Preston North End",
      nickname: "The Lilywhites",
    },
    {
      id: "ftn-ch15",
      realname: "Queens Park Rangers",
      nickname: "The Hoops",
    },
    {
      id: "ftn-ch16",
      realname: "Reading",
      nickname: "The Royals",
    },
    {
      id: "ftn-ch17",
      realname: "Rotherham Utd",
      nickname: "The Millers",
    },
    {
      id: "ftn-ch18",
      realname: "Sheffield Utd",
      nickname: "The Blades",
    },
    {
      id: "ftn-ch19",
      realname: "Stoke City",
      nickname: "The Potters",
    },
    {
      id: "ftn-ch20",
      realname: "Sunderland",
      nickname: "The Black Cats",
    },
    {
      id: "ftn-ch21",
      realname: "Swansea",
      nickname: "The Swans",
    },
    {
      id: "ftn-ch22",
      realname: "Waford",
      nickname: "The Hornets",
    },
    {
      id: "ftn-ch23",
      realname: "West Bromwich Albion",
      nickname: "The Baggies",
    },
    {
      id: "ftn-ch24",
      realname: "Wigan Athletic",
      nickname: "The Latics",
    },
  ],
  leagueOne: [
    {
      id: "ftn-lo1",
      realname: "Accrington Stanley",
      nickname: "The 'owd Reds / Accy",
    },
    {
      id: "ftn-lo2",
      realname: "Barnsley",
      nickname: "The Tykes",
    },
    {
      id: "ftn-lo3",
      realname: "Bolton Wanderers",
      nickname: "The Trotters",
    },
    {
      id: "ftn-lo4",
      realname: "Bristol Rovers",
      nickname: "The Pirates / The Gas",
    },
    {
      id: "ftn-lo5",
      realname: "Burton Albion",
      nickname: "The Brewers",
    },
    {
      id: "ftn-lo6",
      realname: "Cambridge Utd",
      nickname: "The U's",
    },
    {
      id: "ftn-lo7",
      realname: "Charlton Athletic",
      nickname: "The Addicks",
    },
    {
      id: "ftn-lo8",
      realname: "Cheltenham Town",
      nickname: "The Robins",
    },
    {
      id: "ftn-lo9",
      realname: "Derby County",
      nickname: "The Rams",
    },
    {
      id: "ftn-lo10",
      realname: "Exeter City",
      nickname: "The Grecians",
    },
    {
      id: "ftn-lo11",
      realname: "Fleetwood Town",
      nickname: "The Cod Army",
    },
    {
      id: "ftn-lo12",
      realname: "Forest Green Rovers",
      nickname: "The Green",
    },
    {
      id: "ftn-lo13",
      realname: "Ipswich Town",
      nickname: "The Tractor Boys",
    },
    {
      id: "ftn-lo14",
      realname: "Lincoln City",
      nickname: "The Imps",
    },
    {
      id: "ftn-lo15",
      realname: "Milton Keynes Dons",
      nickname: "The Dons",
    },
    {
      id: "ftn-lo16",
      realname: "Morecambe",
      nickname: "The Shrimps",
    },
    {
      id: "ftn-lo17",
      realname: "Oxford Utd",
      nickname: "The U's",
    },
    {
      id: "ftn-lo18",
      realname: "Peterborough Utd",
      nickname: "The Posh",
    },
    {
      id: "ftn-lo19",
      realname: "Plymouth Argyle",
      nickname: "The Pilgrims",
    },
    {
      id: "ftn-lo20",
      realname: "Port Vale",
      nickname: "The Valiants",
    },
    {
      id: "ftn-l21",
      realname: "Portsmouth",
      nickname: "Pompey",
    },
    {
      id: "ftn-l22",
      realname: "Sheffield Wednesday",
      nickname: "The Owls",
    },
    {
      id: "ftn-lo23",
      realname: "Shrewsbury Town",
      nickname: "Salop / The Shrews",
    },
    {
      id: "ftn-lo24",
      realname: "Wycombe Wanderers",
      nickname: "The Chairboys",
    },
  ],
  leagueTwo: [
    {
      id: "ftn-lt1",
      realname: "Barrow",
      nickname: "The Bluebirds",
    },
    {
      id: "ftn-lt2",
      realname: "Bradford City",
      nickname: "The Bantams",
    },
    {
      id: "ftn-lt3",
      realname: "Carlisle Utd",
      nickname: "The Cumbrians",
    },
    {
      id: "ftn-lt4",
      realname: "Colchester Utd",
      nickname: "The U's",
    },
    {
      id: "ftn-lt5",
      realname: "Crawley Town",
      nickname: "The Reds / The Red Devils",
    },
    {
      id: "ftn-lt6",
      realname: "Crewe Alexandra",
      nickname: "The Railwaymen",
    },
    {
      id: "ftn-lt7",
      realname: "Doncaster Rovers",
      nickname: "Donny",
    },
    {
      id: "ftn-lt8",
      realname: "Gillingham",
      nickname: "The Gills",
    },
    {
      id: "ftn-lt9",
      realname: "Grimsby Town",
      nickname: "The Mariners",
    },
    {
      id: "ftn-lt10",
      realname: "Harrogate Town",
      nickname: "The Sulphurites",
    },
    {
      id: "ftn-lt11",
      realname: "Haltlepool Utd",
      nickname: "The Monkey Hangers",
    },
    {
      id: "ftn-lt12",
      realname: "Leyton Orient",
      nickname: "The O's",
    },
    {
      id: "ftn-lt13",
      realname: "Mansfield Town",
      nickname: "The Stags",
    },
    {
      id: "ftn-lt14",
      realname: "Newport County",
      nickname: "The Exiles",
    },
    {
      id: "ftn-lt15",
      realname: "Northampton Town",
      nickname: "The Cobblers",
    },
    {
      id: "ftn-lt16",
      realname: "Rochdale",
      nickname: "The Dale",
    },
    {
      id: "ftn-lt17",
      realname: "Salford City",
      nickname: "The Ammies",
    },
    {
      id: "ftn-lt18",
      realname: "Stevenage",
      nickname: "The Boro",
    },
    {
      id: "ftn-lt19",
      realname: "Stockport County",
      nickname: "The Hatters",
    },
    {
      id: "ftn-lt20",
      realname: "Sutton Utd",
      nickname: "The U's",
    },
    {
      id: "ftn-lt21",
      realname: "Swindon Town",
      nickname: "The Robins",
    },
    {
      id: "ftn-lt22",
      realname: "Tranmere Rovers",
      nickname: "The Super White Army",
    },
    {
      id: "ftn-lt23",
      realname: "Walsall",
      nickname: "The Saddlers",
    },
    {
      id: "ftn-lt24",
      realname: "Wimbledon",
      nickname: "The Dons / The Wombles",
    },
  ],
  overSeasTeamsFrance: [
    {
      id: "ftn-osf1",
      realname: "AS Monaco",
      nickname: "Les Monégasques / Les Rouges et Blancs (The Red and Whites)",
    },
    {
      id: "ftn-osf2",
      realname: "Olympique Lyonnais (Lyon)",
      nickname: "Les Gones (The Kids) / L'OL",
    },
    {
      id: "ftn-osf3",
      realname: "Olympique de Marseille",
      nickname: "Les Phocéens / Les Olympiens",
    },
    {
      id: "ftn-osf4",
      realname: "Paris Saint-Germain",
      nickname: "Les Parisiens / Les Rouge-et-Bleu (The Red-and-Blues)",
    },
  ],
  overSeasTeamsSpain: [
    {
      id: "ftn-oss1",
      realname: "Atletico Madrid",
      nickname: "Los Colchoneros (The Mattress Makers)",
    },
    {
      id: "ftn-oss2",
      realname: "Barcelona",
      nickname: "Barça / Blaugrana (Blue and reds)",
    },
    {
      id: "ftn-oss3",
      realname: "Real Madrid",
      nickname: "Los Blancos (The Whites) / Los Vikingos (The Vikings)",
    },
    {
      id: "ftn-oss4",
      realname: "Valencia",
      nickname: "Los Che",
    },
    {
      id: "ftn-oss5",
      realname: "Villarreal",
      nickname: "El Submarí Groguet (The Yellow Submarine)",
    },
  ],
  overSeasTeamsGermany: [
    {
      id: "ftn-osg1",
      realname: "Bayer 04 Leverkusen",
      nickname: "Die Werkself (The Company's Eleven)",
    },
    {
      id: "ftn-osg2",
      realname: "Bayern Munich",
      nickname: "Die Roten (The Reds)",
    },
    {
      id: "ftn-osg3",
      realname: "Borussia Dortmund",
      nickname: "Die Schwarzgelben (The Black and Yellow)",
    },
    {
      id: "ftn-osg4",
      realname: "Schalke 04",
      nickname: "Die Königsblauen (The Royal Blues) / Die Knappen (The Miners)",
    },
  ],
  overSeasTeamsItaly: [
    {
      id: "ftn-osi1",
      realname: "A.C. Milan",
      nickname: "I Rossoneri (The Red and Blacks)",
    },
    {
      id: "ftn-osi1",
      realname: "Inter Milan",
      nickname: "I Nerazzurri (The Black and Blues)",
    },
    {
      id: "ftn-osi1",
      realname: "S.S.C. Napoli",
      nickname: "Gli Azzurri (The Blues)",
    },
    {
      id: "ftn-osi1",
      realname: "Juventus",
      nickname: "La Vecchia Signora (The Old Lady)",
    },
    {
      id: "ftn-osi1",
      realname: "S.S. Lazio",
      nickname:
        "I Biancocelesti (The White and Sky Blues) / Le Aquile (The Eagles)",
    },
    {
      id: "ftn-osi1",
      realname: "A.S. Roma",
      nickname: "I Giallorossi (The Yellow and Reds) / La Lupa (The She-Wolf)",
    },
    {
      id: "ftn-osi1",
      realname: "ACF Fiorentina",
      nickname: "I Viola (The Purples) / I Gigliati (The Lilies)",
    },
  ],
};

export {
  latinWords,
  entertainmentAwards,
  britishEntertainmentAwards,
  alterEgos,
  cocktails,
  footballTeamNicknames,
};
