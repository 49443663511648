import React, { useRef, useState } from "react";
import "../css/wordle.css";

function WordleTile(props) {
  const ref = useRef();

  function handleClick() {
    if (props.guessedWord.length >= props.wordleLength) {
      return;
    }
    props.setGuessedWord((prevWord) => prevWord + props.value);
  }

  return (
    <div
      ref={ref}
      className={
        props.greenIncluded
          ? "included-g"
          : props.yellowIncluded
          ? "included-y"
          : props.notIncluded
          ? "not-included"
          : "wordle-alphabet-tile"
      }
      onClick={handleClick}
    >
      {props.value}
    </div>
  );
}

export default WordleTile;
