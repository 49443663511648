import React from "react";

function FlagCountryAndCapital(props) {
  return (
    <div className="flag-and-country-container">
      <h4 className="flag-country-and-capital-country">{props.name}</h4>
      <div className="flag-country-and-capital-flag">{props.flag}</div>

      <h4 className="flag-country-and-capital-capital">{props.capital}</h4>
    </div>
  );
}

export default FlagCountryAndCapital;
