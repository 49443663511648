import React from "react";

function Alterego(props) {
  return (
    <section>
      <section className="alter-ego-list">
        <p className="alter-ego-original">{props.original}</p>
        <p className="alter-ego-new">{props.new}</p>
        {props.other ? <p className="alter-ego-other">{props.other}</p> : ""}
      </section>
    </section>
  );
}

export default Alterego;
