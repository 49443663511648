import React, { useRef } from "react";
import "../css/hangman.css";

function Tile(props) {
  const ref = useRef();

  return (
    <div ref={ref} className="tile" onClick={props.onClick}>
      {props.value}
    </div>
  );
}

export default Tile;
