import ac from "./flags-svg/ac.svg";
import ad from "./flags-svg/ad.svg";
import ae from "./flags-svg/ae.svg";
import af from "./flags-svg/af.svg";
import ag from "./flags-svg/ag.svg";
import ai from "./flags-svg/ai.svg";
import al from "./flags-svg/al.svg";
import am from "./flags-svg/am.svg";
import ao from "./flags-svg/ao.svg";
import aq from "./flags-svg/aq.svg";
import ar from "./flags-svg/ar.svg";
import as from "./flags-svg/as.svg";
import at from "./flags-svg/at.svg";
import au from "./flags-svg/au.svg";
import aw from "./flags-svg/aw.svg";
import ax from "./flags-svg/ax.svg";
import az from "./flags-svg/az.svg";
import ba from "./flags-svg/ba.svg";
import bb from "./flags-svg/bb.svg";
import bd from "./flags-svg/bd.svg";
import be from "./flags-svg/be.svg";
import bf from "./flags-svg/bf.svg";
import bg from "./flags-svg/bg.svg";
import bh from "./flags-svg/bh.svg";
import bi from "./flags-svg/bi.svg";
import bj from "./flags-svg/bj.svg";
import bm from "./flags-svg/bm.svg";
import bn from "./flags-svg/bn.svg";
import bo from "./flags-svg/bo.svg";
import br from "./flags-svg/br.svg";
import bs from "./flags-svg/bs.svg";
import bt from "./flags-svg/bt.svg";
import bw from "./flags-svg/bw.svg";
import by from "./flags-svg/by.svg";
import bz from "./flags-svg/bz.svg";
import ca from "./flags-svg/ca.svg";
import cc from "./flags-svg/cc.svg";
import cd from "./flags-svg/cd.svg";
import cf from "./flags-svg/cf.svg";
import cg from "./flags-svg/cg.svg";
import ch from "./flags-svg/ch.svg";
import ci from "./flags-svg/ci.svg";
import ck from "./flags-svg/ck.svg";
import cl from "./flags-svg/cl.svg";
import cm from "./flags-svg/cm.svg";
import cn from "./flags-svg/cn.svg";
import co from "./flags-svg/co.svg";
import cr from "./flags-svg/cr.svg";
import cu from "./flags-svg/cu.svg";
import cv from "./flags-svg/cv.svg";
import cw from "./flags-svg/cw.svg";
import cx from "./flags-svg/cx.svg";
import cy from "./flags-svg/cy.svg";
import cz from "./flags-svg/cz.svg";
import de from "./flags-svg/de.svg";
import dj from "./flags-svg/dj.svg";
import dk from "./flags-svg/dk.svg";
import dm from "./flags-svg/dm.svg";
import dom from "./flags-svg/do.svg";
import dz from "./flags-svg/dz.svg";
import ec from "./flags-svg/ec.svg";
import ee from "./flags-svg/ee.svg";
import eg from "./flags-svg/eg.svg";
import eh from "./flags-svg/eh.svg";
import er from "./flags-svg/er.svg";
import esCt from "./flags-svg/es-ct.svg";
import esGa from "./flags-svg/es-ga.svg";
import es from "./flags-svg/es.svg";
import et from "./flags-svg/et.svg";
import eu from "./flags-svg/eu.svg";
import fi from "./flags-svg/fi.svg";
import fj from "./flags-svg/fj.svg";
import fk from "./flags-svg/fk.svg";
import fm from "./flags-svg/fm.svg";
import fo from "./flags-svg/fo.svg";
import fr from "./flags-svg/fr.svg";
import ga from "./flags-svg/ga.svg";
import gbEng from "./flags-svg/gb-eng.svg";
import gbNir from "./flags-svg/gb-nir.svg";
import gbSct from "./flags-svg/gb-sct.svg";
import gbWls from "./flags-svg/gb-wls.svg";
import gb from "./flags-svg/gb.svg";
import gd from "./flags-svg/gd.svg";
import ge from "./flags-svg/ge.svg";
import gg from "./flags-svg/gg.svg";
import gh from "./flags-svg/gh.svg";
import gi from "./flags-svg/gi.svg";
import gl from "./flags-svg/gl.svg";
import gm from "./flags-svg/gm.svg";
import gn from "./flags-svg/gn.svg";
import gq from "./flags-svg/gq.svg";
import gr from "./flags-svg/gr.svg";
import gs from "./flags-svg/gs.svg";
import gt from "./flags-svg/gt.svg";
import gu from "./flags-svg/gu.svg";
import gw from "./flags-svg/gw.svg";
import gy from "./flags-svg/gy.svg";
import hk from "./flags-svg/hk.svg";
import hn from "./flags-svg/hn.svg";
import hr from "./flags-svg/hr.svg";
import ht from "./flags-svg/ht.svg";
import hu from "./flags-svg/hu.svg";
import ic from "./flags-svg/ic.svg";
import id from "./flags-svg/id.svg";
import ie from "./flags-svg/ie.svg";
import il from "./flags-svg/il.svg";
import im from "./flags-svg/im.svg";
import ind from "./flags-svg/in.svg";
import io from "./flags-svg/io.svg";
import iq from "./flags-svg/iq.svg";
import ir from "./flags-svg/ir.svg";
import is from "./flags-svg/is.svg";
import it from "./flags-svg/it.svg";
import je from "./flags-svg/je.svg";
import jm from "./flags-svg/jm.svg";
import jo from "./flags-svg/jo.svg";
import jp from "./flags-svg/jp.svg";
import ke from "./flags-svg/ke.svg";
import kg from "./flags-svg/kg.svg";
import kh from "./flags-svg/kh.svg";
import ki from "./flags-svg/ki.svg";
import km from "./flags-svg/km.svg";
import kn from "./flags-svg/kn.svg";
import kp from "./flags-svg/kp.svg";
import kr from "./flags-svg/kr.svg";
import kw from "./flags-svg/kw.svg";
import ky from "./flags-svg/ky.svg";
import kz from "./flags-svg/kz.svg";
import la from "./flags-svg/la.svg";
import lb from "./flags-svg/lb.svg";
import lc from "./flags-svg/lc.svg";
import li from "./flags-svg/li.svg";
import lk from "./flags-svg/lk.svg";
import lr from "./flags-svg/lr.svg";
import ls from "./flags-svg/ls.svg";
import lt from "./flags-svg/lt.svg";
import lu from "./flags-svg/lu.svg";
import lv from "./flags-svg/lv.svg";
import ly from "./flags-svg/ly.svg";
import ma from "./flags-svg/ma.svg";
import mc from "./flags-svg/mc.svg";
import md from "./flags-svg/md.svg";
import me from "./flags-svg/me.svg";
import mg from "./flags-svg/mg.svg";
import mh from "./flags-svg/mh.svg";
import mk from "./flags-svg/mk.svg";
import ml from "./flags-svg/ml.svg";
import mm from "./flags-svg/mm.svg";
import mn from "./flags-svg/mn.svg";
import mo from "./flags-svg/mo.svg";
import mp from "./flags-svg/mp.svg";
import mr from "./flags-svg/mr.svg";
import ms from "./flags-svg/ms.svg";
import mt from "./flags-svg/mt.svg";
import mu from "./flags-svg/mu.svg";
import mv from "./flags-svg/mv.svg";
import mw from "./flags-svg/mw.svg";
import mx from "./flags-svg/mx.svg";
import my from "./flags-svg/my.svg";
import mz from "./flags-svg/mz.svg";
import na from "./flags-svg/na.svg";
import nc from "./flags-svg/nc.svg";
import ne from "./flags-svg/ne.svg";
import nf from "./flags-svg/nf.svg";
import ng from "./flags-svg/ng.svg";
import ni from "./flags-svg/ni.svg";
import nl from "./flags-svg/nl.svg";
import no from "./flags-svg/no.svg";
import np from "./flags-svg/np.svg";
import nr from "./flags-svg/nr.svg";
import nu from "./flags-svg/nu.svg";
import nz from "./flags-svg/nz.svg";
import om from "./flags-svg/om.svg";
import pa from "./flags-svg/pa.svg";
import pe from "./flags-svg/pe.svg";
import pf from "./flags-svg/pf.svg";
import pg from "./flags-svg/pg.svg";
import ph from "./flags-svg/ph.svg";
import pk from "./flags-svg/pk.svg";
import pl from "./flags-svg/pl.svg";
import pn from "./flags-svg/pn.svg";
import pr from "./flags-svg/pr.svg";
import ps from "./flags-svg/ps.svg";
import pt from "./flags-svg/pt.svg";
import pw from "./flags-svg/pw.svg";
import py from "./flags-svg/py.svg";
import qa from "./flags-svg/qa.svg";
import ro from "./flags-svg/ro.svg";
import rs from "./flags-svg/rs.svg";
import ru from "./flags-svg/ru.svg";
import rw from "./flags-svg/rw.svg";
import sa from "./flags-svg/sa.svg";
import sb from "./flags-svg/sb.svg";
import sc from "./flags-svg/sc.svg";
import sd from "./flags-svg/sd.svg";
import se from "./flags-svg/se.svg";
import sg from "./flags-svg/sg.svg";
import si from "./flags-svg/si.svg";
import sk from "./flags-svg/sk.svg";
import sl from "./flags-svg/sl.svg";
import sm from "./flags-svg/sm.svg";
import sn from "./flags-svg/sn.svg";
import so from "./flags-svg/so.svg";
import sr from "./flags-svg/sr.svg";
import ss from "./flags-svg/ss.svg";
import st from "./flags-svg/st.svg";
import sv from "./flags-svg/sv.svg";
import sx from "./flags-svg/sx.svg";
import sy from "./flags-svg/sy.svg";
import sz from "./flags-svg/sz.svg";
import tc from "./flags-svg/tc.svg";
import td from "./flags-svg/td.svg";
import tf from "./flags-svg/tf.svg";
import tg from "./flags-svg/tg.svg";
import th from "./flags-svg/th.svg";
import tj from "./flags-svg/tj.svg";
import tk from "./flags-svg/tk.svg";
import tl from "./flags-svg/tl.svg";
import tm from "./flags-svg/tm.svg";
import tn from "./flags-svg/tn.svg";
import to from "./flags-svg/to.svg";
import tr from "./flags-svg/tr.svg";
import tt from "./flags-svg/tt.svg";
import tv from "./flags-svg/tv.svg";
import tw from "./flags-svg/tw.svg";
import tz from "./flags-svg/tz.svg";
import ua from "./flags-svg/ua.svg";
import ug from "./flags-svg/ug.svg";
import un from "./flags-svg/un.svg";
import us from "./flags-svg/us.svg";
import uy from "./flags-svg/uy.svg";
import uz from "./flags-svg/uz.svg";
import va from "./flags-svg/va.svg";
import vc from "./flags-svg/vc.svg";
import ve from "./flags-svg/ve.svg";
import vg from "./flags-svg/vg.svg";
import vi from "./flags-svg/vi.svg";
import vn from "./flags-svg/vn.svg";
import vu from "./flags-svg/vu.svg";
import ws from "./flags-svg/ws.svg";
import xk from "./flags-svg/xk.svg";
import ye from "./flags-svg/ye.svg";
import za from "./flags-svg/za.svg";
import zm from "./flags-svg/zm.svg";
import zw from "./flags-svg/zw.svg";

const flagsAll = [
  {
    name: "Ascension Island",
    code: "ac",
    capital: "Georgetown",
    svg: <img src={ac} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Andorra",
    code: "ad",
    capital: "Andorra la Vella",
    svg: <img src={ad} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "United Arab Emirates",
    code: "ae",
    capital: "Abu Dhabi",
    svg: <img src={ae} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Afghanistan",
    code: "af",
    capital: "Kabul",
    svg: <img src={af} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Antigua and Barbuda",
    code: "ag",
    capital: "St John's",
    svg: <img src={ag} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Anguilla",
    code: "ai",
    capital: "The Valley",
    svg: <img src={ai} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Albania",
    code: "al",
    capital: "Tirana",
    svg: <img src={al} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Armenia",
    code: "am",
    capital: "Yerevan",
    svg: <img src={am} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Angola",
    code: "ao",
    capital: "Luanda",
    svg: <img src={ao} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Antarctica",
    code: "aq",
    svg: <img src={aq} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Argentina",
    code: "ar",
    capital: "Buenos Aires",
    svg: <img src={ar} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "American Samoa",
    code: "as",
    capital: "Pago Pago",
    svg: <img src={as} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Austria",
    code: "at",
    capital: "Vienna",
    svg: <img src={at} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Australia",
    code: "au",
    capital: "Canberra",
    svg: <img src={au} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Aruba",
    code: "aw",
    capital: "Oranjestad",
    svg: <img src={aw} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Aland Islands",
    code: "ax",
    capital: "Mariehamn",
    svg: <img src={ax} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Azerbaijan",
    code: "az",
    capital: "Baku",
    svg: <img src={az} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Bosnia and Herzegovina",
    code: "ba",
    capital: "Sarajevo",
    svg: <img src={ba} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Barbados",
    code: "bb",
    capital: "Bridgetown",
    svg: <img src={bb} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Bangladesh",
    code: "bd",
    capital: "Dhaka",
    svg: <img src={bd} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Belgium",
    code: "be",
    capital: "Brussels",
    svg: <img src={be} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Burkina Faso",
    code: "bf",
    capital: "Ouagadougou",
    svg: <img src={bf} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Bulgaria",
    code: "bg",
    capital: "Sofia",
    svg: <img src={bg} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Bahrain",
    code: "bh",
    capital: "Manama",
    svg: <img src={bh} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Burundi",
    code: "bi",
    capital: "Gitega",
    svg: <img src={bi} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Benin",
    code: "bj",
    capital: "Porto-Novo",
    svg: <img src={bj} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Bermuda",
    code: "bm",
    capital: "Hamilton",
    svg: <img src={bm} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Brunei Darussalam",
    code: "bn",
    capital: "Bandar Seri Begawan",
    svg: <img src={bn} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Bolivia",
    code: "bo",
    capital: "Sucre/La Paz",
    svg: <img src={bo} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Brazil",
    code: "br",
    capital: "Brasilia",
    svg: <img src={br} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Bahamas",
    code: "bs",
    capital: "Nassau",
    svg: <img src={bs} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Bhutan",
    code: "bt",
    capital: "Thimphu",
    svg: <img src={bt} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Botswana",
    code: "bw",
    capital: "Gaborone",
    svg: <img src={bw} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Belarus",
    code: "by",
    capital: "Minsk",
    svg: <img src={by} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Belize",
    code: "bz",
    capital: "Belmopan",
    svg: <img src={bz} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Canada",
    code: "ca",
    capital: "Ottawa",
    svg: <img src={ca} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "cc",
    capital: "West Island",
    svg: <img src={cc} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Democratic Republic of the Congo",
    code: "cd",
    capital: "Kinshasa",
    svg: <img src={cd} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Central African Republic",
    code: "cf",
    capital: "Bangui",
    svg: <img src={cf} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Republic of the Congo",
    code: "cg",
    capital: "Brazzaville",
    svg: <img src={cg} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Switzerland",
    code: "ch",
    capital: "Bern",
    svg: <img src={ch} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Cote d'Ivore",
    code: "ci",
    capital: "Yamoussoukro",
    svg: <img src={ci} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Cook Islands",
    code: "ck",
    capital: "Avarua",
    svg: <img src={ck} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Chile",
    code: "cl",
    capital: "Santiago",
    svg: <img src={cl} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Cameroon",
    code: "cm",
    capital: "Yaounde",
    svg: <img src={cm} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "China",
    code: "cn",
    capital: "Beijing",
    svg: <img src={cn} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Columbia",
    code: "co",
    capital: "Bogota",
    svg: <img src={co} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Costa Rica",
    code: "cr",
    capital: "San Jose",
    svg: <img src={cr} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Cuba",
    code: "cu",
    capital: "Havana",
    svg: <img src={cu} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Cape Verde",
    code: "cv",
    capital: "Praia",
    svg: <img src={cv} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Curacao",
    code: "cw",
    capital: "Willemstad",
    svg: <img src={cw} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Christmas Island",
    code: "cx",
    capital: "Flying Fish Cove",
    svg: <img src={cx} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Cyprus",
    code: "cy",
    capital: "Nicosia",
    svg: <img src={cy} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Czech Republic",
    code: "cz",
    capital: "Prague",
    svg: <img src={cz} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Germany",
    code: "de",
    capital: "Berlin",
    svg: <img src={de} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Djibouti",
    code: "dj",
    capital: "Djibouti (city)",
    svg: <img src={dj} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Denmark",
    code: "dk",
    capital: "Copenhagen",
    svg: <img src={dk} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Dominica",
    code: "dm",
    capital: "Roseau",
    svg: <img src={dm} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Dominican Republic",
    code: "do",
    capital: "Santo Domingo",
    svg: <img src={dom} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Algeria",
    code: "dz",
    capital: "Algiers",
    svg: <img src={dz} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Ecuador",
    code: "ec",
    capital: "Quito",
    svg: <img src={ec} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Estonia",
    code: "ee",
    capital: "Tallinn",
    svg: <img src={ee} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Egypt",
    code: "eg",
    capital: "Cairo",
    svg: <img src={eg} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Western Sahara",
    code: "eh",
    svg: <img src={eh} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Eritrea",
    code: "er",
    capital: "Asmara",
    svg: <img src={er} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Spain",
    code: "es",
    capital: "Madrid",
    svg: <img src={es} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Catalonia",
    code: "es-ct",
    capital: "Barcelona",
    svg: <img src={esCt} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Galicia",
    code: "es-ga",
    capital: "Santiago de Compostela",
    svg: <img src={esGa} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Ethiopia",
    code: "et",
    capital: "Addis Ababa",
    svg: <img src={et} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "European Union",
    code: "eu",
    svg: <img src={eu} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Finland",
    code: "fi",
    capital: "Helsinki",
    svg: <img src={fi} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Fiji",
    code: "fj",
    capital: "Suva",
    svg: <img src={fj} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Falkland Islands",
    code: "fk",
    capital: "Stanley",
    svg: <img src={fk} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Federated States of Micronesia",
    code: "fm",
    capital: "Palikir",
    svg: <img src={fm} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Faroe Islands",
    code: "fo",
    capital: "Torshavn",
    svg: <img src={fo} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "France",
    code: "fr",
    capital: "Paris",
    svg: <img src={fr} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Gabon",
    code: "ga",
    capital: "Libreville",
    svg: <img src={ga} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Great Britain",
    code: "gb",
    svg: <img src={gb} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "England",
    code: "gb-eng",
    capital: "London",
    svg: <img src={gbEng} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Northern Ireland",
    code: "gb-nir",
    capital: "Belfast",
    svg: <img src={gbNir} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Scotland",
    code: "gb-sct",
    capital: "Edinburgh",
    svg: <img src={gbSct} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Wales",
    code: "gb-wls",
    capital: "Cardiff",
    svg: <img src={gbWls} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Grenada",
    code: "gd",
    capital: "Saint George's",
    svg: <img src={gd} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Georgia",
    code: "ge",
    capital: "Tbilisi",
    svg: <img src={ge} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Guernsey",
    code: "gg",
    capital: "Saint Peter Port",
    svg: <img src={gg} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Ghana",
    code: "gh",
    capital: "Accra",
    svg: <img src={gh} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Gibraltar",
    code: "gi",
    capital: "Gibraltar",
    svg: <img src={gi} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Greenland",
    code: "gl",
    capital: "Nuuk",
    svg: <img src={gl} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Gambia",
    code: "gm",
    capital: "Banjul",
    svg: <img src={gm} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Guinea",
    code: "gn",
    capital: "Conakry",
    svg: <img src={gn} />,
    difficulty: "hard",
    numMistakes: 0,
  },

  {
    name: "Equatorial Guinea",
    code: "gq",
    capital: "Malabo/Oyala",
    svg: <img src={gq} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Greece",
    code: "gr",
    capital: "Athens",
    svg: <img src={gr} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "gs",
    capital: "King Edward Point",
    svg: <img src={gs} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Guatemala",
    code: "gt",
    capital: "Guatemala City",
    svg: <img src={gt} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Guam",
    code: "gu",
    capital: "Hagatna",
    svg: <img src={gu} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Guinea-Bissau",
    code: "gw",
    capital: "Bissau",
    svg: <img src={gw} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Guyana",
    code: "gy",
    capital: "Georgetown",
    svg: <img src={gy} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Hong Kong",
    code: "hk",
    svg: <img src={hk} />,
    difficulty: "hard",
    numMistakes: 0,
  },

  {
    name: "Honduras",
    code: "hn",
    capital: "Tegucigalpa",
    svg: <img src={hn} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Croatia",
    code: "hr",
    capital: "Zagreb",
    svg: <img src={hr} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Haiti",
    code: "ht",
    capital: "Port-au-Prince",
    svg: <img src={ht} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Hungary",
    code: "hu",
    capital: "Budapest",
    svg: <img src={hu} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Canary Islands",
    code: "ic",
    capital: "Las Palmas de Gran Canaria & Santa Cruz de Tenerife",
    svg: <img src={ic} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Indonesia",
    code: "id",
    capital: "Jakarta",
    svg: <img src={id} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Ireland",
    code: "ie",
    capital: "Dublin",
    svg: <img src={ie} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Israel",
    code: "il",
    capital: "Jerusalem",
    svg: <img src={il} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Isle of Man",
    code: "im",
    capital: "Douglas",
    svg: <img src={im} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "India",
    code: "in",
    capital: "New Delhi",
    svg: <img src={ind} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "British Indian Ocean Territory",
    code: "io",
    capital: "Camp Justice",
    svg: <img src={io} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Iraq",
    code: "iq",
    capital: "Baghdad",
    svg: <img src={iq} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Iran",
    code: "ir",
    capital: "Tehran",
    svg: <img src={ir} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Iceland",
    code: "is",
    capital: "Reykjavik",
    svg: <img src={is} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Italy",
    code: "it",
    capital: "Rome",
    svg: <img src={it} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Japan",
    code: "jp",
    capital: "Tokyo",
    svg: <img src={jp} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Jersey",
    code: "je",
    capital: "Saint Helier",
    svg: <img src={je} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Jamaica",
    code: "jm",
    capital: "Kingston",
    svg: <img src={jm} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Jordan",
    code: "jo",
    capital: "Amman",
    svg: <img src={jo} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Kenya",
    code: "ke",
    capital: "Nairobi",
    svg: <img src={ke} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Kyrgyzstan",
    code: "kg",
    capital: "Bishkek",
    svg: <img src={kg} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Cambodia",
    code: "kh",
    capital: "Phnom Penh",
    svg: <img src={kh} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Kiribati",
    code: "ki",
    capital: "Tarawa",
    svg: <img src={ki} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Comoros",
    code: "km",
    capital: "Moroni",
    svg: <img src={km} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Saint Kitts and Nevis",
    code: "kn",
    capital: "Basseterre",
    svg: <img src={kn} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "North Korea",
    code: "kp",
    capital: "Pyongyang",
    svg: <img src={kp} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "South Korea",
    code: "kr",
    capital: "Seoul",
    svg: <img src={kr} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Kuwait",
    code: "kw",
    capital: "Kuwait City",
    svg: <img src={kw} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Cayman Islands",
    code: "ky",
    capital: "George Town",
    svg: <img src={ky} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Kazakhstan",
    code: "kz",
    capital: "Nur-Sultan",
    svg: <img src={kz} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Laos",
    code: "la",
    capital: "Vientiane",
    svg: <img src={la} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Lebanon",
    code: "lb",
    capital: "Beirut",
    svg: <img src={lb} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Saint Lucia",
    code: "lc",
    capital: "Castries",
    svg: <img src={lc} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Liechtenstein",
    code: "li",
    capital: "Vaduz",
    svg: <img src={li} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Sri Lanka",
    code: "lk",
    capital: "Sri Jayawardenepura Kotte",
    svg: <img src={lk} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Liberia",
    code: "lr",
    capital: "Monrovia",
    svg: <img src={lr} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Lesotho",
    code: "ls",
    capital: "Maseru",
    svg: <img src={ls} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Lithuania",
    code: "lt",
    capital: "Vilnius",
    svg: <img src={lt} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Luxembourg",
    code: "lu",
    capital: "Luxembourg (City)",
    svg: <img src={lu} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Latvia",
    code: "lv",
    capital: "Riga",
    svg: <img src={lv} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Libya",
    code: "ly",
    capital: "Tripoli",
    svg: <img src={ly} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Morocco",
    code: "ma",
    capital: "Rabat",
    svg: <img src={ma} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Monaco",
    code: "mc",
    capital: "Monaco",
    svg: <img src={mc} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Moldova",
    code: "md",
    capital: "Chisinau",
    svg: <img src={md} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Montenegro",
    code: "me",
    capital: "Podgorica",
    svg: <img src={me} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Madagascar",
    code: "mg",
    capital: "Antananarivo",
    svg: <img src={mg} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Marshall Islands",
    code: "mh",
    capital: "Majuro",
    svg: <img src={mh} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "North Macedonia",
    code: "mk",
    capital: "Skopje",
    svg: <img src={mk} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Mali",
    code: "ml",
    capital: "Bamako",
    svg: <img src={ml} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Myanmar",
    code: "mm",
    capital: "Naypyidaw",
    svg: <img src={mm} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Mongolia",
    code: "mn",
    capital: "Ulaanbaatar",
    svg: <img src={mn} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Macau",
    code: "mo",
    svg: <img src={mo} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Northern Mariana Islands",
    code: "mp",
    capital: "Saipan",
    svg: <img src={mp} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Mauritania",
    code: "mr",
    capital: "Nouakchott",
    svg: <img src={mr} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Montserrat",
    code: "ms",
    capital: "Plymouth (de jure), Brades (de facto), Little Bay",
    svg: <img src={ms} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Malta",
    code: "mt",
    capital: "Valletta",
    svg: <img src={mt} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Mauritius",
    code: "mu",
    capital: "Port Louis",
    svg: <img src={mu} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Maldives",
    code: "mv",
    capital: "Male",
    svg: <img src={mv} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Malawi",
    code: "mw",
    capital: "Lilongwe",
    svg: <img src={mw} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Mexico",
    code: "mx",
    capital: "Mexico City",
    svg: <img src={mx} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Malaysia",
    code: "my",
    capital: "Kuala Lumpur",
    svg: <img src={my} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Mozambique",
    code: "mz",
    capital: "Maputo",
    svg: <img src={mz} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Namibia",
    code: "na",
    capital: "Windhoek",
    svg: <img src={na} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "New Caledonia",
    code: "nc",
    capital: "Noumea",
    svg: <img src={nc} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Niger",
    code: "ne",
    capital: "Niamey",
    svg: <img src={ne} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Norfolk Island",
    code: "nf",
    capital: "Kingston",
    svg: <img src={nf} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Nigeria",
    code: "ng",
    capital: "Abuja",
    svg: <img src={ng} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Nicaragua",
    code: "ni",
    capital: "Managua",
    svg: <img src={ni} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Netherlands",
    code: "nl",
    capital: "Amsterdam",
    svg: <img src={nl} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Norway",
    code: "no",
    capital: "Oslo",
    svg: <img src={no} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Nepal",
    code: "np",
    capital: "Kathmandu",
    svg: <img src={np} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Nauru",
    code: "nr",
    capital: "Yaren District",
    svg: <img src={nr} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Niue",
    code: "nu",
    capital: "Alofi",
    svg: <img src={nu} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "New Zealand",
    code: "nz",
    capital: "Wellington",
    svg: <img src={nz} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Oman",
    code: "om",
    capital: "Muscat",
    svg: <img src={om} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Panama",
    code: "pa",
    capital: "Panama City",
    svg: <img src={pa} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Peru",
    code: "pe",
    capital: "Lima",
    svg: <img src={pe} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "French Polynesia",
    code: "pf",
    capital: "Papeete",
    svg: <img src={pf} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Papua New Guinea",
    code: "pg",
    capital: "Port Moresby",
    svg: <img src={pg} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Philippines",
    code: "ph",
    capital: "Manila",
    svg: <img src={ph} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Pakistan",
    code: "pk",
    capital: "Islamabad",
    svg: <img src={pk} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Poland",
    code: "pl",
    capital: "Warsaw",
    svg: <img src={pl} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Pitcairn",
    code: "pn",
    capital: "Adamstown",
    svg: <img src={pn} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Puerto Rico",
    code: "pr",
    capital: "San Juan",
    svg: <img src={pr} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "State of Palestine",
    code: "ps",
    capital: "Jerusalem",
    svg: <img src={ps} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Portugal",
    code: "pt",
    capital: "Lisbon",
    svg: <img src={pt} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Palau",
    code: "pw",
    capital: "Ngerulmud",
    svg: <img src={pw} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Paraguay",
    code: "py",
    capital: "Asuncion",
    svg: <img src={py} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Qatar",
    code: "qa",
    capital: "Doha",
    svg: <img src={qa} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Romania",
    code: "ro",
    capital: "Bucharest",
    svg: <img src={ro} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Serbia",
    code: "rs",
    capital: "Belgrade",
    svg: <img src={rs} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Russia",
    code: "ru",
    capital: "Moscow",
    svg: <img src={ru} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Rwanda",
    code: "rw",
    capital: "Kigali",
    svg: <img src={rw} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Saudi Arabia",
    code: "sa",
    capital: "Riyadh",
    svg: <img src={sa} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Soloman Islands",
    code: "sb",
    capital: "Honiara",
    svg: <img src={sb} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Seychelles",
    code: "sc",
    capital: "Victoria",
    svg: <img src={sc} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Sudan",
    code: "sd",
    capital: "Khartoum",
    svg: <img src={sd} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Sweden",
    code: "se",
    capital: "Stockholm",
    svg: <img src={se} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Singapore",
    code: "sg",
    capital: "Singapore",
    svg: <img src={sg} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Slovenia",
    code: "si",
    capital: "Ljubljana",
    svg: <img src={si} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Slovakia",
    code: "sk",
    capital: "Bratislava",
    svg: <img src={sk} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Sierra Leone",
    code: "sl",
    capital: "Freetown",
    svg: <img src={sl} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "San Marino",
    code: "sm",
    capital: "San Marino",
    svg: <img src={sm} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Senegal",
    code: "sn",
    capital: "Dakar",
    svg: <img src={sn} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Somalia",
    code: "so",
    capital: "Mogadishu",
    svg: <img src={so} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Suriname",
    code: "sr",
    capital: "Paramaribo",
    svg: <img src={sr} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "South Sudan",
    code: "ss",
    capital: "Juba",
    svg: <img src={ss} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Sao Tome and Principe",
    code: "st",
    capital: "Sao Tome",
    svg: <img src={st} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "El Salvador",
    code: "sv",
    capital: "San Salvador",
    svg: <img src={sv} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Sint Maarten",
    code: "sx",
    capital: "Philipsburg",
    svg: <img src={sx} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Syrian Arab Republic",
    code: "sy",
    capital: "Damascus",
    svg: <img src={sy} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Eswatini",
    code: "sz",
    capital: "Mbabane (executive), Lobamba (legislative)",
    svg: <img src={sz} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Turks and Caicos Islands",
    code: "tc",
    capital: "Cockburn Town",
    svg: <img src={tc} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Chad",
    code: "td",
    capital: "N'Djamena",
    svg: <img src={td} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "French Southern Territories",
    code: "tf",
    capital: "Saint Pierre, Réunion",
    svg: <img src={tf} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Togo",
    code: "tg",
    capital: "Lome",
    svg: <img src={tg} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Thailand",
    code: "th",
    capital: "Bangkok",
    svg: <img src={th} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Tajikistan",
    code: "tj",
    capital: "Dushanbe",
    svg: <img src={tj} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Tokelau",
    code: "tk",
    svg: <img src={tk} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Timor-Leste",
    code: "tl",
    svg: <img src={tl} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Turkmenistan",
    code: "tm",
    capital: "Ashgabat",
    svg: <img src={tm} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Tunisia",
    code: "tn",
    capital: "Tunis",
    svg: <img src={tn} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Tonga",
    code: "to",
    capital: "Nuku'alofa",
    svg: <img src={to} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Turkey",
    code: "tr",
    capital: "Ankara",
    svg: <img src={tr} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Trinidad and Tobago",
    code: "tt",
    capital: "Port of Spain",
    svg: <img src={tt} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Tuvalu",
    code: "tv",
    capital: "Funafuti",
    svg: <img src={tv} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Taiwan",
    code: "tw",
    capital: "Taipei",
    svg: <img src={tw} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Tanzania",
    code: "tz",
    capital: "Dodoma",
    svg: <img src={tz} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Ukraine",
    code: "ua",
    capital: "Kyiv",
    svg: <img src={ua} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Uganda",
    code: "ug",
    capital: "Kampala",
    svg: <img src={ug} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "United Nations",
    code: "un",
    svg: <img src={un} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "United States of America",
    code: "us",
    capital: "Washington, D.C",
    svg: <img src={us} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Uruguay",
    code: "uy",
    capital: "Montevideo",
    svg: <img src={uy} />,
    difficulty: "easy",
    numMistakes: 0,
  },
  {
    name: "Uzbekistan",
    code: "uz",
    capital: "Tashkent",
    svg: <img src={uz} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Vatican City",
    code: "va",
    capital: "Vatican City",
    svg: <img src={va} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "St Vincent and the Grenadines",
    code: "vc",
    capital: "Kingstown",
    svg: <img src={vc} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Venezuela",
    code: "ve",
    capital: "Caracas",
    svg: <img src={ve} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Virgin Islands (British)",
    code: "vg",
    capital: "Road Town",
    svg: <img src={vg} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Virgin Islands (U.S.)",
    code: "vi",
    capital: "Charlotte Amalie",
    svg: <img src={vi} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Vietnam",
    code: "vn",
    capital: "Hanoi",
    svg: <img src={vn} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Vanuatu",
    code: "vu",
    capital: "Port Vila",
    svg: <img src={vu} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Samoa",
    code: "ws",
    capital: "Apia",
    svg: <img src={ws} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "Kosovo",
    code: "xk",
    capital: "Pristina",
    svg: <img src={xk} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Yemen",
    code: "ye",
    capital: "Sana'a",
    svg: <img src={ye} />,
    difficulty: "ridiculous",
    numMistakes: 0,
  },
  {
    name: "South Africa",
    code: "za",
    capital:
      "Pretoria (administrative), Cape Town (legislative), Bloemfontein (judicial)",
    svg: <img src={za} />,
    difficulty: "medium",
    numMistakes: 0,
  },
  {
    name: "Zambia",
    code: "zm",
    capital: "Lusaka",
    svg: <img src={zm} />,
    difficulty: "hard",
    numMistakes: 0,
  },
  {
    name: "Zimbabwe",
    code: "zw",
    capital: "Harare",
    svg: <img src={zw} />,
    difficulty: "hard",
    numMistakes: 0,
  },
];

export default flagsAll;
