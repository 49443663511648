import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import FooterLearning from "./FooterLearning";
import SubLearnChoice from "../SubLearnChoice";
import AsideNav from "./AsideNav";
import { generalQuizzingCategories } from "../categories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "../css/style.css";
import "../css/peeps.css";

function SetLearnChoices(choice) {
  return (
    <SubLearnChoice key={choice.id} name={choice.name} path={choice.path} />
  );
}

function GeneralQuizzing() {
  return (
    <div className="hero push-footer">
      <Header />
      {/* <AsideNav /> */}
      <section className="drop-section-content">
        <div className="menu-page-container">
          <div className="category-flex-container">
            <div className="sub-menu-page-title-div">
              <h1 className="menu-page-title">LEARN - GENERAL QUIZZING</h1>
            </div>
            {generalQuizzingCategories.map(SetLearnChoices)}
            <Link to="../learn" className="answer-orange learn-go-back">
              <FontAwesomeIcon
                className="fontawesome-margin-right"
                icon={faArrowLeft}
              />
              GO BACK
            </Link>
          </div>
        </div>
        <div className="peep-learning-flex">
          <img
            src={require("../images/peep-small2.png")}
            alt="cartoon character woman standing"
            className="peep-learning"
          />
        </div>
      </section>
      <FooterLearning />
    </div>
  );
}

export default GeneralQuizzing;
