import React from "react";

function WrongGuesses(props) {
  return (
    <div>
      <span>WRONG GUESSES: </span>
      <span>{props.wrongGuesses}</span>
      <span>/6</span>
    </div>
  );
}

export default WrongGuesses;
