import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Footer from "./Footer";
import "../css/style.css";
import "../css/flashcard.css";
import "../css/flag.css";
import Flag from "./Flag";
import HeaderApp from "./HeaderApp";
import flagsAll from "../FlagsJson";

function SetFlags(f) {
  return (
    <Flag
      key={f.code}
      flag={f.svg}
      country={f.name}
      capital={f.capital}
      numMistakes={f.numMistakes}
    />
  );
}

let filteredDifficulty;

function FlagFlashcard() {
  const inputRef = useRef("");
  const submitBtnRef = useRef();
  const skipBtnRef = useRef();
  const graphBar1 = useRef();
  const graphBar2 = useRef();
  const graphBar3 = useRef();
  const graphBar4 = useRef();
  const graphBar5 = useRef();
  const diffSpan = useRef("");
  const [n, setN] = useState(0);
  const [answer, setAnswer] = useState("");
  const [correctFlags, setCorrectFlags] = useState([]);
  const [flagsList, setFlagsList] = useState(flagsAll.slice());
  const [filteredFlagsList, setFilteredFlagsList] = useState(flagsAll);
  const flagCardInnerRef = useRef();
  const flagCardFlagImg = useRef();
  const flagCardFlagAnswer = useRef();
  const flagCardFlagAnswerCapital = useRef();
  const [diff, setDifficulty] = useState("");
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [streak, setStreak] = useState(0);
  const [maxStreak, setMaxStreak] = useState(0);
  const [numFlagsSeen, setNumFlagsSeen] = useState(0);
  const [mistakesInFilteredFlags, setMistakesInFilteredFlags] = useState(0);
  const [numFlagsInDifficulty, setNumFlagsInDifficulty] = useState(0);
  const [count, setCount] = useState(1);

  // useEffect(() => {
  //   focus();
  // }, [diff]);

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [diff, timerOn]);

  useEffect(() => {
    // setNumFlagsSeen(count - 1);
    // setNumFlagsSeen(correctFlags.length + mistakesInFilteredFlags);
    // setMistakesInFilteredFlags(
    //   filteredFlagsList.filter((flag) => flag.numMistakes > 0).length
    // );
    if (!filteredFlagsList.length) setTimerOn(false);
    colorDifficulty();
  });

  useEffect(() => {
    filteredDifficulty = flagsList.filter(function (x) {
      if (diff === "All") {
        return flagsList;
      } else if (diff.length > 3) {
        return x.difficulty === diff.toLowerCase();
      } else {
        return flagsList;
      }
    });
    //randomise the questions (using Fisher-Yates Algorithm)
    for (let i = filteredDifficulty.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * i);
      const temp = filteredDifficulty[i];
      filteredDifficulty[i] = filteredDifficulty[j];
      filteredDifficulty[j] = temp;
    }
    setFilteredFlagsList(filteredDifficulty);
    setNumFlagsInDifficulty(filteredDifficulty.length);
  }, [diff]);

  useEffect(() => {
    streak > maxStreak && setMaxStreak(streak);
  }, [streak]);

  useEffect(() => {
    drawGraphs();
  });

  // useEffect(() => {
  //   if (count < numFlagsSeen) setCount(numFlagsSeen);
  // });

  function focus() {
    inputRef.current && inputRef.current.focus();
  }

  function addFlipClass() {
    flagCardInnerRef.current &&
      flagCardInnerRef.current.classList.add("flip-me");
  }

  function removeFlipClass() {
    flagCardInnerRef.current &&
      flagCardInnerRef.current.classList.remove("flip-me");
  }

  function addFadeIn() {
    flagCardFlagAnswer.current &&
      flagCardFlagAnswer.current.classList.add("fade-in");

    flagCardFlagAnswerCapital.current &&
      flagCardFlagAnswerCapital.current.classList.add("fade-in");
  }

  function removeFadeIn() {
    flagCardFlagAnswer.current &&
      flagCardFlagAnswer.current.classList.remove("fade-in");
    flagCardFlagAnswerCapital.current &&
      flagCardFlagAnswerCapital.current.classList.remove("fade-in");
  }

  function clearInput() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }

  function colorDifficulty() {
    if (diffSpan.current) {
      diffSpan.current.classList.remove("easy");
      diffSpan.current.classList.remove("medium");
      diffSpan.current.classList.remove("hard");
      diffSpan.current.classList.remove("ridiculous");
      diffSpan.current.classList.remove("all");
      diffSpan.current.classList.add(`${diff}`);
    }
  }

  // console.log(diffSpan.current && diffSpan.current.classList);

  function increase() {
    dealWithAnswer();
    // Needed to increase n before, but now because of the dealWithAnswer function, which changes the actual array and puts a new value to check at n = 0. If you increase n like before, you skip an index.
    // setN(n + 1);
    addFadeIn();
    removeFlipClass();
    clearInput();
    focus();
    if (count < numFlagsInDifficulty) setCount((count) => count + 1);
    setNumFlagsSeen(count);
  }

  function dealWithAnswer() {
    if (answer.toLowerCase() === filteredFlagsList[n].name.toLowerCase()) {
      const [first, ...rest] = filteredFlagsList;
      setStreak((streak) => streak + 1);

      setFilteredFlagsList(rest);
    } else if (answer !== filteredFlagsList[n].name) {
      const [first, ...rest] = filteredFlagsList;
      filteredFlagsList[n].numMistakes += 1;
      setStreak(0);

      // setMistakesInFilteredFlags(
      //   filteredFlagsList.filter((flag) => flag.numMistakes > 0).length
      // );
      setFilteredFlagsList(rest.concat(first));
    }
  }

  function checkAnswer() {
    if (
      answer.toLowerCase() === filteredFlagsList[n].name.toLowerCase() &&
      !correctFlags.includes(filteredFlagsList[n])
    ) {
      setCorrectFlags([filteredFlagsList[n], ...correctFlags]);
    }
    addFlipClass();
    removeFadeIn();
  }

  function handleSubmit(e) {
    e.preventDefault();
    setTimerOn(false);
    checkAnswer();
    submitBtnRef.current.disabled = true;
    submitBtnRef.current.classList.add("disabled-btn");
    skipBtnRef.current.disabled = true;
    skipBtnRef.current.classList.add("disabled-btn");

    setTimeout(() => {
      increase();
      setTimerOn(true);
      submitBtnRef.current.disabled = false;
      submitBtnRef.current.classList.remove("disabled-btn");
      skipBtnRef.current.disabled = false;
      skipBtnRef.current.classList.remove("disabled-btn");
    }, 3000);
  }

  function handleKeyDown(e) {
    if (e.code === "Enter") {
      handleSubmit();
    }
  }

  function handleChange(e) {
    setAnswer(e.target.value);
  }

  function giveUp(e) {
    handleSubmit(e);
  }

  const flagsAllNames = flagsAll.map((f) => f.name).sort();

  function handleDifficulty(e) {
    setDifficulty(e.target.value);
    setCorrectFlags([]);
    filteredFlagsList.forEach((x) => {
      x.numMistakes = 0;
    });
    setTime(0);
    setTimerOn(true);
    setAnswer("");
    setMistakesInFilteredFlags(0);
    setNumFlagsInDifficulty(0);
    setCount(1);
  }

  function drawGraphs() {
    if (graphBar1.current !== null && graphBar1.current !== undefined) {
      graphBar1.current.style.width =
        (correctFlags.filter((cf) => cf.numMistakes === 0).length /
          numFlagsSeen) *
          100 +
        "%";
      graphBar1.current.style.opacity = 1;
    }
    if (graphBar2.current !== null && graphBar2.current !== undefined) {
      graphBar2.current.style.width =
        (correctFlags.filter((cf) => cf.numMistakes === 1).length /
          numFlagsSeen) *
          100 +
        "%";
      graphBar2.current.style.opacity = 1;
    }
    if (graphBar3.current !== null && graphBar3.current !== undefined) {
      graphBar3.current.style.width =
        (correctFlags.filter((cf) => cf.numMistakes === 2).length /
          numFlagsSeen) *
          100 +
        "%";
      graphBar3.current.style.opacity = 1;
    }
    if (graphBar4.current !== null && graphBar4.current !== undefined) {
      graphBar4.current.style.width =
        (correctFlags.filter((cf) => cf.numMistakes === 3).length /
          numFlagsSeen) *
          100 +
        "%";
      graphBar4.current.style.opacity = 1;
    }
    if (graphBar5.current !== null && graphBar5.current !== undefined) {
      graphBar5.current.style.width =
        (correctFlags.filter((cf) => cf.numMistakes > 3).length /
          numFlagsSeen) *
          100 +
        "%";
      graphBar5.current.style.opacity = 1;
    }
  }

  function resetRefsAndDiff() {
    setDifficulty("");
    graphBar1.current = null;
    graphBar2.current = null;
    graphBar3.current = null;
    graphBar4.current = null;
    graphBar5.current = null;
    setStreak(0);
    setMaxStreak(0);
    setNumFlagsSeen(0);
  }

  function quitEarly() {
    if (timerOn === false) {
      setTimeout(() => {
        setFilteredFlagsList([]);
        setTimerOn(false);
      }, 3000);
    } else {
      setFilteredFlagsList([]);
      setTimerOn(false);
    }
  }

  return (
    <div className="hero hero-moving push-footer">
      <HeaderApp />
      <section className="drop-section-content">
        {!diff.length ? (
          <div className="select-difficulty-flex">
            {/* <h1 className="learn-flags-title">Learn The Flags Of The World</h1> */}
            <div>
              <h2 className="learn-flags-subtitle">Choose difficulty:</h2>
              <div>
                <button
                  className="easy-btn"
                  value="easy"
                  onClick={handleDifficulty}
                >
                  Easy
                </button>
                <button
                  className="medium-btn"
                  value="medium"
                  onClick={handleDifficulty}
                >
                  Medium
                </button>
                <button
                  className="hard-btn"
                  value="hard"
                  onClick={handleDifficulty}
                >
                  Hard
                </button>
                <button
                  className="ridiculous-btn"
                  value="ridiculous"
                  onClick={handleDifficulty}
                >
                  Ridiculous
                </button>
                <button
                  className="all-btn"
                  value="all"
                  onClick={handleDifficulty}
                >
                  All
                </button>
              </div>
            </div>
          </div>
        ) : filteredFlagsList.length ? (
          <div className="flags-game-flex">
            <div className="difficulty-text">
              <div className="diff-div">
                <h2>Difficulty:</h2>
                <span ref={diffSpan} className="your-category">
                  {diff}
                </span>
                <button className="next" onClick={() => setDifficulty("")}>
                  Change Difficulty
                </button>
              </div>

              <div className="streak-div">
                <h2>
                  Count:
                  <span className="your-category">
                    {count} / {numFlagsInDifficulty}
                  </span>
                </h2>
                <h2>
                  Streak: <span className="your-category">{streak}</span>
                </h2>
                <h2>
                  Max Streak: <span className="your-category">{maxStreak}</span>
                </h2>
                <button className="all-btn" onClick={() => quitEarly()}>
                  QUIT
                </button>
              </div>
            </div>
            <div className="main-flags-game-container">
              <div className="timer">
                <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
                <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>
                :<span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
              </div>

              <div className="flashcard-container">
                <div className="flag-card">
                  <div ref={flagCardInnerRef} className="flag-card-inner">
                    <div ref={flagCardFlagImg} className="flag-card-front">
                      {filteredFlagsList[n].svg}
                    </div>
                    <div className="flag-card-back">
                      <h3 ref={flagCardFlagAnswer} className="flag-name">
                        {filteredFlagsList[n].name}
                      </h3>
                      <h4
                        className="flag-capital"
                        ref={flagCardFlagAnswerCapital}
                      >
                        {filteredFlagsList[n].capital &&
                          `Capital: ${filteredFlagsList[n].capital}`}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="button-div">
                {/* <div>{score}</div> */}

                <form
                  onKeyDown={handleKeyDown}
                  onSubmit={handleSubmit}
                  className="flag-form"
                >
                  <input
                    type="text"
                    ref={inputRef}
                    className="flag-guess"
                    onChange={handleChange}
                    list="countriesDropdown"
                  />

                  <datalist id="countriesDropdown">
                    {flagsAllNames.map((x) => {
                      return (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      );
                    })}
                  </datalist>

                  <input
                    ref={submitBtnRef}
                    type="submit"
                    value="Submit"
                    className="next"
                  />
                </form>
                <button ref={skipBtnRef} className="next" onClick={giveUp}>
                  Skip
                </button>
              </div>
            </div>
            <div className="correct-flags">
              <h2>Correctly Guessed: ({correctFlags.length})</h2>
              <div className="correct-flags-container">
                {correctFlags.map(SetFlags)}
              </div>
            </div>
          </div>
        ) : (
          <div className="final-flag-quiz-stats container">
            <h2 className="category-title">Your Stats</h2>
            <h2 className="category-subtitle">Difficulty: {diff}</h2>
            <div className="timer-heading">
              <span>TIME: </span>
              <span>{("0" + Math.floor(time / 3600000)).slice(-2)}</span>:
              <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}</span>:
              <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
            </div>
            <h2 className="category-subtitle">
              {correctFlags.filter((f) => f.numMistakes > 0).length
                ? "Needs Practice:"
                : ""}
            </h2>
            <div className="final-flags-flex">
              {correctFlags
                .filter((f) => f.numMistakes > 0)
                .sort((a, b) => a.numMistakes - b.numMistakes)
                .slice(-20)
                .sort((a, b) => b.numMistakes - a.numMistakes)
                .map(SetFlags)}
            </div>
            <h2 className="container-third-subtitle best-streak">
              Best Streak: {maxStreak}
            </h2>
            <h2 className="category-subtitle centre-text">
              Out of {numFlagsSeen} here's how you did:
            </h2>
            <div>
              <div className="graph-bar-div">
                <p className="graph-bar-num-tries">Correct 1st try:</p>
                <div className="graph-bar">
                  <div ref={graphBar1} className="graph-bar-amount">
                    {correctFlags.filter((cf) => cf.numMistakes === 0).length
                      ? Math.floor(
                          (correctFlags.filter((cf) => cf.numMistakes === 0)
                            .length /
                            numFlagsSeen) *
                            100
                        ) + "%"
                      : ""}
                  </div>
                </div>
              </div>
              <div className="graph-bar-div">
                <p className="graph-bar-num-tries">Correct 2nd try:</p>
                <div className="graph-bar">
                  <div ref={graphBar2} className="graph-bar-amount">
                    {correctFlags.filter((f) => f.numMistakes === 1).length
                      ? Math.floor(
                          (correctFlags.filter((f) => f.numMistakes === 1)
                            .length /
                            numFlagsSeen) *
                            100
                        ) + "%"
                      : ""}
                  </div>
                </div>
              </div>
              <div className="graph-bar-div">
                <p className="graph-bar-num-tries">Correct 3rd try:</p>
                <div className="graph-bar">
                  <div ref={graphBar3} className="graph-bar-amount">
                    {correctFlags.filter((f) => f.numMistakes === 2).length
                      ? Math.floor(
                          (correctFlags.filter((f) => f.numMistakes === 2)
                            .length /
                            numFlagsSeen) *
                            100
                        ) + "%"
                      : ""}
                  </div>
                </div>
              </div>
              <div className="graph-bar-div">
                <p className="graph-bar-num-tries">Correct 4th try:</p>
                <div className="graph-bar">
                  <div ref={graphBar4} className="graph-bar-amount">
                    {correctFlags.filter((f) => f.numMistakes === 3).length
                      ? Math.floor(
                          (correctFlags.filter((f) => f.numMistakes === 3)
                            .length /
                            numFlagsSeen) *
                            100
                        ) + "%"
                      : ""}
                  </div>
                </div>
              </div>
              <div className="graph-bar-div">
                <p className="graph-bar-num-tries">
                  Correct after more than 5 tries:
                </p>
                <div className="graph-bar">
                  <div ref={graphBar5} className="graph-bar-amount">
                    {correctFlags.filter((f) => f.numMistakes > 3).length
                      ? Math.floor(
                          (correctFlags.filter((f) => f.numMistakes > 3)
                            .length /
                            numFlagsSeen) *
                            100
                        ) + "%"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <button className="next" onClick={resetRefsAndDiff}>
              Try Again?
            </button>

            <Link className="flags-stats-link" to="../practice">
              Go back to practice page
            </Link>
          </div>
        )}
      </section>
      <Footer />
    </div>
  );
}

export default FlagFlashcard;
