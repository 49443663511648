import React from "react";
import "../../css/top5.css";

function LearnTopFiveThreeColsNarrowLeft(props) {
  return (
    <div className="top5flex">
      <h2 className="top5-three-cols-narrow">{props.col1}</h2>
      <div className="top5-three-cols">
        <h2 className="top5-three-cols-white-with-orange">{props.col2}</h2>
        {props.col2country ? (
          <h2 className="orange-text">{props.col2country}</h2>
        ) : (
          ""
        )}
      </div>
      <div className="top5-three-cols">
        <h2 className="top5-three-cols-white-with-orange">{props.col3}</h2>
        {props.col3country ? (
          <h2 className="orange-text">{props.col3country}</h2>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default LearnTopFiveThreeColsNarrowLeft;
