import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnSpecificCategoryTop from "./LearnSpecificCategoryTop";
import LearnSpecificCategoryBottom from "./LearnSpecificCategoryBottom";
import LearnTopFive from "./LearnTopFive";
import LearnTopFiveThreeColsNarrowLeft from "./LearnTopFiveThreeColsNarrowLeft";
import LearnTopFiveThreeColsNarrowLeftHeading from "./LearnTopFiveThreeColsNarrowLeftHeading";
import LearnTopFiveHeading from "./LearnTopFiveHeading";
import { entertainmentAwards } from "../../LearnSpecificCategoryDetailsTwo";
import { topFivesEntertainment } from "../../topFivesInfo";
import "../../css/style.css";
import "../../css/sidebar.css";
import "../../css/peeps.css";
import "../../css/top5.css";

function ArrangeTopFive(topFive) {
  return (
    <LearnTopFive key={topFive.key} col2={topFive.col2} col1={topFive.col1} />
  );
}
function ArrangeTopFiveHeading(topFive) {
  return (
    <LearnTopFiveHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
    />
  );
}
function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeColsNarrowLeft
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}

function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsNarrowLeftHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}

function LearnEntertainmentAwards() {
  return (
    <div className="hero">
      <Header />
      <section className="drop-section-content">
        <LearnSpecificCategoryTop
          mainCategory={entertainmentAwards.mainCategory}
          subCategory={entertainmentAwards.subCategory}
          iconLrg={entertainmentAwards.iconLrg}
          iconSml={entertainmentAwards.iconSml}
          bigPeep={entertainmentAwards.bigPeep}
          intro={entertainmentAwards.intro}
        />
        <div className="top5s-container">
          <div className="top5TwoCols">
            <h2>Last 5 Best Picture Oscar Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.bestPictureOscarHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.bestPictureOscar.map(ArrangeTopFive)}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 EGOT Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.EGOTwinnersHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.EGOTwinners.map(ArrangeTopFive)}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 Best Musical Tony Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.lastTonyBestMusicalHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.lastTonyBestMusical.map(ArrangeTopFive)}
          </div>
          <div className="top5TwoCols">
            <h2>Last 5 Best New Artist Grammy Winners</h2>
            <div className="sidebar-line"></div>
            {topFivesEntertainment.grammyBestNewArtistHeadings.map(
              ArrangeTopFiveHeading
            )}

            {topFivesEntertainment.grammyBestNewArtist.map(ArrangeTopFive)}
          </div>
        </div>

        <LearnSpecificCategoryBottom
          importantFacts={entertainmentAwards.importantFacts}
          classicQuestions={entertainmentAwards.classicQuestions}
          exampleQuestions={entertainmentAwards.exampleQuestions}
          thoughtsTipsIdeas={entertainmentAwards.thoughtsTipsAndIdeas}
          smallPeep1={entertainmentAwards.smallPeep1}
          smallPeep2={entertainmentAwards.smallPeep2}
          disclaimer={entertainmentAwards.disclaimer}
          button1={entertainmentAwards.button1}
          button2={entertainmentAwards.button2}
        />
        <AsideNav />
      </section>
      <div className="top5s-container">
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Album of the Year Grammy Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesEntertainment.grammyAlbumOfTheYearHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}

          {topFivesEntertainment.grammyAlbumOfTheYear.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Record of the Year Grammy Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesEntertainment.grammyRecordOfTheYearHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}

          {topFivesEntertainment.grammyRecordOfTheYear.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
        <div className="top5ThreeOrMoreCols">
          <h2>Last 5 Song of the Year Grammy Winners</h2>
          <div className="sidebar-line"></div>
          {topFivesEntertainment.grammySongOfTheYearHeadings.map(
            ArrangeTopFiveThreeColsHeading
          )}

          {topFivesEntertainment.grammySongOfTheYear.map(
            ArrangeTopFiveThreeCols
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LearnEntertainmentAwards;
