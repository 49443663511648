import React from "react";
import Toggler from "../Toggler";
import "../css/QuestionBankQandA.css";
import "../css/questionbank.css";
import "../css/covered.css";

function QuestionBankQandA(props) {
  return (
    <div className="questionAndAnswer">
      <h2 className="question">{props.question}</h2>
      {props.tog ? (
        <Toggler answer={props.answer} />
      ) : (
        <h3 className="answer">{props.answer}</h3>
      )}
      {/* <h3 className="answer">{props.answer}</h3> */}
    </div>
  );
}

export default QuestionBankQandA;
