import React from "react";
import { Link } from "react-router-dom";
import { sidebarCategories } from "../categories";

function AsideNav() {
  return (
    <nav className="sidebar sidebar2">
      <h2>Menu</h2>
      <div className="sidebar-line"></div>
      <ul>
        {sidebarCategories.map((nC) => (
          <li className="sidebar-li" key={nC.id}>
            <Link className="sidebar-list-link" to={nC.path}>
              {nC.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default AsideNav;
