import { useState, React } from "react";
import L from "leaflet";
import axios from "axios";

import { Marker, Popup, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import {
  defaultIcon,
  redIcon,
  greyIcon,
  greenIcon,
} from "../icons/defaultIcon";
import "../../../css/map.css";

const defaultRadius = 40;

const getIconByVotes = (votes) => {
  const numericVotes = Number(votes);
  if (numericVotes > 0) return greenIcon;
  if (numericVotes < 0) return redIcon;
  return greyIcon;
};

const PopupStatistics = ({ feature, setRadiusFilter }) => {
  const [radius, setRadius] = useState(defaultRadius);
  const { NAME, ADM0NAME, VOTES, ID } = feature.properties;
  const [voteSubmitted, setVoteSubmitted] = useState(false);
  const [votes, setVotes] = useState(VOTES);

  const handleVote = (voteType) => {
    let newVotes = voteType === "yes" ? votes + 1 : votes - 1;
    setVotes(newVotes);
    setVoteSubmitted(true);
    setTimeout(() => setVoteSubmitted(false), 1000); // Reset after 1 second

    if (voteType === "yes") {
      newVotes = 1;
    } else if (voteType === "no") {
      newVotes = -1;
    }

    axios
      .get(`https://waddauno.com/update-vote.php?id=${ID}&votes=${newVotes}`)
      .then((response) => {
        // Handle success if needed
        // console.log(response.data);
      })
      .catch((error) => {
        // Handle error if needed
        console.error("Error updating vote:", error);
      });
  };

  const cardColour = VOTES > 1 ? "green" : VOTES == 0 ? "grey" : "red";

  return (
    <div className={cardColour}>
      <h2 className="card-title">{`${NAME}`}</h2>
      <span className="card-info">{`${ADM0NAME}`}</span>
      <span></span>
      <br />
      <span className="card-info">Is there a quiz here?</span>
      <div className={`button-flex ${voteSubmitted ? "vote-submitted" : ""}`}>
        <button className="yes-button" onClick={() => handleVote("yes")}>
          Yes
        </button>
        <button className="no-button" onClick={() => handleVote("no")}>
          No
        </button>
      </div>
      <span className="card-info">
        Vote result so far: <strong>{`${VOTES}`}</strong>
      </span>
    </div>
  );
};

const createClusterCustomIcon = (cluster) => {
  const markers = cluster.getAllChildMarkers();
  let sumVotes = 0;

  markers.forEach((marker) => {
    sumVotes += Number(marker.options.votes);
  });

  let clusterColor;
  if (sumVotes > 0) {
    clusterColor = "green";
  } else if (sumVotes < 0) {
    clusterColor = "red";
  } else {
    clusterColor = "grey";
  }

  const html = `<div style="background-color: ${clusterColor}; width: 40px; height: 40px; display: flex; justify-content: center; align-items: center; border-radius: 50%; color: white;">
    ${markers.length}
  </div>`;

  return new L.DivIcon({
    html: html,
    className: "marker-cluster-custom",
    iconSize: L.point(40, 40, true),
  });
};

const MarkerLayerWithTooltipCluster = ({
  data,
  setRadiusFilter,
  getRadiusFilter,
}) => {
  const leafletMap = useMap();
  const radiusFilter = getRadiusFilter();

  let centerPoint;
  if (radiusFilter) {
    const { coordinates } = radiusFilter.feature.geometry;
    centerPoint = L.latLng(coordinates[1], coordinates[0]);
  }

  const layer = data.features
    .filter((currentFeature) => {
      let filterByRadius;
      if (centerPoint) {
        const { coordinates } = currentFeature.geometry;
        const currentPoint = L.latLng(coordinates[1], coordinates[0]);
        filterByRadius =
          centerPoint.distanceTo(currentPoint) / 1000 < radiusFilter.radius;
      }
      let doFilter = true;

      if (radiusFilter) {
        doFilter = filterByRadius;
      }
      return doFilter;
    })
    .map((feature) => {
      const { coordinates } = feature.geometry;
      const votes = feature.properties.VOTES;
      const icon = getIconByVotes(votes);

      return (
        <Marker
          key={feature.properties.ID}
          position={[coordinates[1], coordinates[0]]}
          icon={icon}
          doFitToBounds={true}
          eventHandlers={{ click: (e) => leafletMap.panTo(e.latlng) }}
          votes={Number(votes)} // Add this line
        >
          <Popup>
            <PopupStatistics
              feature={feature}
              setRadiusFilter={setRadiusFilter}
            />
          </Popup>
        </Marker>
      );
    });
  return (
    <MarkerClusterGroup
      zoomToBoundsOnClick={true}
      iconCreateFunction={createClusterCustomIcon}
    >
      {layer}
    </MarkerClusterGroup>
  );
};

export { MarkerLayerWithTooltipCluster };
