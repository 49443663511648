import React from "react";
import { Link } from "react-router-dom";

function LearnChoice(props) {
  return (
    <Link
      className="category-flex-item"
      to={
        typeof props.path === "string"
          ? props.path
          : props.path[Math.floor(Math.random() * props.path.length)]
      }
    >
      <img
        className="category-flex-item-img"
        src={props.bgImg}
        alt={props.altText}
      />
      <h3>{props.name}</h3>
    </Link>
  );
}

export default LearnChoice;
