import React, { Fragment } from "react";
import "../../css/film.css";

function Film(props) {
  return (
    <div className="film-container">
      <h2>{props.year}</h2>
      <h3 className="margin-bottom-artist centre-text-orange-artist">
        Best films of the year:
      </h3>
      <div className="films-list-container">
        {props.films.map((f, i) => (
          <Fragment key={f}>
            <p className="">{f}</p>
            <div className="list-line"></div>
          </Fragment>
        ))}
      </div>
      {props.bestPictureWinner && (
        <h3 className="margin-bottom-artist centre-text-orange-artist">
          Best picture winner:
        </h3>
      )}
      <p>{props.bestPictureWinner}</p>
      {props.bestPictureWinner2 && <p>{props.bestPictureWinner2}</p>}
    </div>
  );
}

export default Film;
