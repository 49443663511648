import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import AsideNav from "../AsideNav";
import LearnTopFiveThreeCols from "./LearnTopFiveThreeCols";
import LearnTopFiveThreeColsHeading from "./LearnTopFiveThreeColsHeading";
import { historyAndCivilisation } from "../../topFivesInfo";
import "../../css/records.css";

function ArrangeTopFiveThreeCols(topFive) {
  return (
    <LearnTopFiveThreeCols
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
      col2country={topFive.col2country}
      col3country={topFive.col3country}
      col4={topFive.col4}
    />
  );
}
function ArrangeTopFiveThreeColsHeading(topFive) {
  return (
    <LearnTopFiveThreeColsHeading
      key={topFive.key}
      col2={topFive.col2}
      col1={topFive.col1}
      col3={topFive.col3}
    />
  );
}

function LearnPolitics() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">UK Politics</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <div className="center-align">
              <p className="body-text">
                UK Politics, specifically information relating to the Prime
                Minister in recent years. 'Prime Minister' will be shortened to
                'PM' in the information below.
              </p>
            </div>
            <img
              className="peep peep-flip"
              src={require("../../images/peep-small4.png")}
              alt="cartoon character with black hair"
            />
          </div>
          <div className="line-subtitle"></div>
          <div className="football-records">
            <div className="football-record">
              <h2 className="football-record-title">Last 5 PMs</h2>
              <div className="sidebar-line"></div>
              {historyAndCivilisation.politics.lastFivePMsHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {historyAndCivilisation.politics.lastFivePMs.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Last 5 Conservative PMs</h2>
              <div className="sidebar-line"></div>
              {historyAndCivilisation.politics.lastFiveTorysHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {historyAndCivilisation.politics.lastFiveTorys.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
            <div className="football-record">
              <h2 className="football-record-title">Last 5 Labour PMs</h2>
              <div className="sidebar-line"></div>
              {historyAndCivilisation.politics.lastFiveLabourHeadings.map(
                ArrangeTopFiveThreeColsHeading
              )}
              {historyAndCivilisation.politics.lastFiveLabour.map(
                ArrangeTopFiveThreeCols
              )}
            </div>
          </div>
        </div>
        <AsideNav />
      </section>
      <Footer />
    </div>
  );
}

export default LearnPolitics;
