import React, { useState, useEffect, useRef } from "react";
import Keyboard from "./Keyboard";
import HangingMan from "./HangingMan";
import Word from "./Word";
import WrongGuesses from "./WrongGuesses";
import EndMessage from "./EndMessage";
import Header from "./Header";
import Footer from "./Footer";
import hangmanWords from "../HangmanWords";
import "../css/hangman.css";
import "../css/style.css";

function Hangman() {
  const winOrLoseText = useRef("");

  const [guessLetter, setGuessLetter] = useState("");
  const [playable, setPlayable] = useState(true);
  const [correctLetters, setCorrectLetters] = useState([]);
  const [wrongLetters, setWrongLetters] = useState([]);
  const [wrongGuesses, setWrongGuesses] = useState(0);
  const [allData, setAllData] = useState([hangmanWords]);
  const [catList, setCatList] = useState(Object.keys(hangmanWords));
  const [randomCat, setRandomCat] = useState(
    Object.keys(hangmanWords)[Math.floor(Math.random() * catList.length)]
  );
  const [category, setCategory] = useState(
    hangmanWords[randomCat]["name"].toUpperCase()
  );
  const [wordListToGuess, setWordListToGuess] = useState(
    hangmanWords[randomCat]["list"]
  );

  const [word, setWord] = useState(
    wordListToGuess[
      Math.floor(Math.random() * wordListToGuess.length)
    ].toUpperCase()
  );

  function handleGuess(event) {
    setGuessLetter(event.target.innerText);
    // checkForWin();
    if (
      wrongGuesses <= 5 &&
      correctLetters
        .filter(
          (l) => l !== " " && l !== "." && l !== "," && l !== "'" && l !== "-"
        )
        .join("").length <
        word
          .split("")
          .filter(
            (l) => l !== " " && l !== "." && l !== "," && l !== "'" && l !== "-"
          )
          .join("").length
    )
      event.target.className = "greyed";
  }

  useEffect(() => {
    if (
      guessLetter.toLocaleUpperCase !== "" &&
      word.includes(guessLetter) &&
      playable == true &&
      !correctLetters.includes(guessLetter)
    ) {
      setCorrectLetters([...correctLetters, guessLetter]);
    } else if (
      !word.includes(guessLetter) &&
      !wrongLetters.includes(guessLetter) &&
      playable == true
    ) {
      setWrongGuesses(wrongGuesses + 1);
      setWrongLetters([...wrongLetters, guessLetter]);
    }
  }, [guessLetter]);

  useEffect(() => {
    if (
      correctLetters
        .filter(
          (l) => l !== " " && l !== "." && l !== "," && l !== "'" && l !== "-"
        )
        .join("").length >=
      word
        .split("")
        .filter(
          (l, index, self) =>
            self.indexOf(l) === index &&
            l !== " " &&
            l !== "." &&
            l !== "," &&
            l !== "'" &&
            l !== "-"
        ).length
    ) {
      setPlayable(false);
    } else if (wrongGuesses >= 6) {
      setPlayable(false);
    }
  }, [wrongGuesses, correctLetters]);

  return (
    <div className="hangman push-footer">
      <Header />
      <section className="drop-section-content hangman-font">
        <h1>HANGMAN</h1>
        <h2 className="category-text">
          Category <span className="hangman-category">{category}</span>
        </h2>
        <div className="game-container">
          <HangingMan wrongGuesses={wrongGuesses} />
          <WrongGuesses wrongGuesses={wrongGuesses} />
        </div>

        <Word word={word} correctLetters={correctLetters} />
        {playable ? (
          <Keyboard handleGuess={handleGuess} />
        ) : (
          <EndMessage
            playable={playable}
            wrongGuesses={wrongGuesses}
            correctLetters={correctLetters}
            word={word}
          />
        )}
      </section>
      <Footer />
    </div>
  );
}

export default Hangman;
