import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Alterego from "./Alterego";
import AsideNav from "../AsideNav";
import { classicQuestions } from "../../ClassicQuestions";
import "../../css/style.css";
import "../../css/peeps.css";

function ArrangeAlterEgos(x) {
  return <Alterego key={x.id} original={x.question} new={x.answer} />;
}

function LearnClassicQuestions() {
  return (
    <div className="hero push-footer">
      <Header />
      <section className="drop-section-content">
        <h1 className="category-title">Classic Questions</h1>
        <div className="container">
          <div className="flex-container second-flex">
            <img
              className="peep"
              src={require("../../images/peep-small13.png")}
              alt="cartoon character wearing orange shirt and white blazer"
            />
            <div className="center-align">
              <p className="body-text">{classicQuestions.intro}</p>
            </div>
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Sport Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.sportQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Britain Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.britainQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Geography Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.geographyQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">History Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.historyQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">General Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.generalQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">TV Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.tvQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Movie Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.movieQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Literature Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.literatureQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Nature Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.natureQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Music Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.musicQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Science Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.scienceQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Mythology Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.mythologyQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Food & Drink Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.foodAndDrinkQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Art Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.artQuestions.map(ArrangeAlterEgos)}
          </div>
          <div className="line-subtitle"></div>

          <h2 className="category-subtitle centre">Video Games Questions</h2>
          <div className="alter-ego-list-container">
            {classicQuestions.videogamesQuestions.map(ArrangeAlterEgos)}
          </div>
        </div>
      </section>
      <AsideNav />
      <Footer />
    </div>
  );
}

export default LearnClassicQuestions;
